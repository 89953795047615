import React, { Component } from "react";
import * as Icon from "react-feather";

import WidgetConfigService from "../../../services/WidgetConfigService";
import UserService from "../../../services/UserService";
import ToastService from "../../../services/ToastService";
import LocationService from "../../../services/LocationService";
import GAService from "../../../services/GAService";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";

import MicroSiteConfig from "./MicroSiteConfig";
import WebChatConfig from "./WebchatConfig";
import BookingConfig from "./BookingConfig/BookingConfig";
import ReviewsConfig from "./ReviewsConfig";
import EmbeddedReviewsConfig from "./EmbeddedReviewsConfig.js";

import { WIDGET_TABS } from "../../../constants/WidgetConstants";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

import "../../../styles/css/common.css";

class WidgetConfig extends Component {
	state = {
		selectedTab: null,
		token: null
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = location => {
		this.fetchToken();
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchToken();
		this.resetComponent();
	}

	resetComponent = () => {
		let selectedTab = null;
		if (LocationService.isWebchatPermissible()) {
			selectedTab = WIDGET_TABS.webChat.id;
		} else if (LocationService.isReviewsWidgetPermissible()) {
			selectedTab = WIDGET_TABS.reviewsWidget.id;
		} else if (LocationService.isEmbeddedReviewsWidgetPermissible()) {
			selectedTab = WIDGET_TABS.embeddedReviewsWidget.id;
		} else if (LocationService.isMicroSitePermissible()) {
			selectedTab = WIDGET_TABS.microSite.id;
		} else if (LocationService.isBookingsPermissible()) {
			selectedTab = WIDGET_TABS.bookingWidget.id;
		}

		this.update({
			selectedTab
		});
	};

	async fetchToken() {
		try {
			let locationId = UserService.getActiveLocation().id;

			const token = await WidgetConfigService.fetchNucleusKeyForLocation(locationId);
			await this.update({ token });
		} catch (error) {
			console.log(error);
		}
	}

	copyWidgetCode = () => {
		const { token } = this.state;

		if (!token) {
			return;
		}

		const nucleusScript = WidgetConfigService.getNucleusScript(token);

		if (navigator.clipboard) {
			navigator.clipboard.writeText(nucleusScript);
			ToastService.info("Copied to clipboard!");
		}

		//ie 11 hack
		if (window.clipboardData) {
			window.clipboardData.setData("text", nucleusScript);
			ToastService.info("Copied to clipboard!");
		}
	};

	copyInjectableCode = () => {
		const { token } = this.state;
		if (!token) {
			return;
		}
		const injectableScript = WidgetConfigService.getInjectableScript(token);
		if (navigator.clipboard) {
			navigator.clipboard.writeText(injectableScript);
			ToastService.info("Copied to clipboard!");
		}
		//ie 11 hack
		if (window.clipboardData) {
			window.clipboardData.setData("text", injectableScript);
			ToastService.info("Copied to clipboard!");
		}
	};

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.widgetConfig,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		const { selectedTab } = this.state;

		return (
			<Page>
				<Header title="Widgets">
					{selectedTab !== null && (
						<>
							<Action id="widgets__actions__copy_injectable_code" label="Copy Injectable Code" icon={Icon.Copy} onClick={this.copyInjectableCode} />
							<Action id="widgets__actions__copy_widget_code" label="Copy Widget Code" icon={Icon.Paperclip} onClick={this.copyWidgetCode} />
						</>
					)}
				</Header>
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					{LocationService.isWebchatPermissible() && <Tab id={WIDGET_TABS.webChat.id} value={WIDGET_TABS.webChat.value} />}
					{LocationService.isReviewsWidgetPermissible() && <Tab id={WIDGET_TABS.reviewsWidget.id} value={WIDGET_TABS.reviewsWidget.value} />}
					{LocationService.isEmbeddedReviewsWidgetPermissible() && (
						<Tab id={WIDGET_TABS.embeddedReviewsWidget.id} value={WIDGET_TABS.embeddedReviewsWidget.value} />
					)}
					{LocationService.isMicroSitePermissible() && <Tab id={WIDGET_TABS.microSite.id} value={WIDGET_TABS.microSite.value} />}
					{LocationService.isBookingsPermissible() && <Tab id={WIDGET_TABS.bookingWidget.id} value={WIDGET_TABS.bookingWidget.value} />}
				</Tabs>
				{WIDGET_TABS.microSite.id === selectedTab && <MicroSiteConfig />}
				{WIDGET_TABS.webChat.id === selectedTab && <WebChatConfig />}
				{WIDGET_TABS.reviewsWidget.id === selectedTab && <ReviewsConfig />}
				{WIDGET_TABS.bookingWidget.id === selectedTab && <BookingConfig />}
				{WIDGET_TABS.embeddedReviewsWidget.id === selectedTab && <EmbeddedReviewsConfig />}
				{selectedTab === null && <div style={{ padding: 30 }}>No widget's enabled</div>}
			</Page>
		);
	}
}

export default withLocation(WidgetConfig);

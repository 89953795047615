import React, { PureComponent } from "react";

import ContactSearch from "../../../../components/common/ContactSearch";
import Modal from "../../../../components/common/DHModal";

import "./new-conversation-modal.css";

class NewConversationModal extends PureComponent {
	constructor(props) {
		super(props);

		let { show } = this.props;

		this.state = {
			show: show
		};
	}
	onConversationSelected = async (conversation, createMode) => {
		if (this.props.onCreate) {
			this.props.onCreate(conversation, createMode);
		}
	};

	render() {
		let { show, onClose, title, actionText, showPaymentMethod } = this.props;

		return (
			<Modal show={show} onHide={onClose} title={title} className="ncm">
				<ContactSearch onConversationSelected={this.onConversationSelected} actionText={actionText} showPaymentMethod={showPaymentMethod} />
			</Modal>
		);
	}
}

export default NewConversationModal;

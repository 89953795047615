import React from "react";
import { withTranslation } from "react-i18next";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import A2PBrands from "./A2PBrands";
import A2PCampaigns from "./A2PCampaigns";
import A2PSubmissions from "./A2PSubmissions";
import A2PWebhooks from "./A2PWebhooks";
import A2PNumberAssociations from "./A2PNumberAssociations";

import GAService from "../../../services/GAService";

import { A2P_TABS } from "../../../constants/Settings";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

class A2P extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: A2P_TABS.brands.id
		};
	}

	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });
	};

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.a2p,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("A2P")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={A2P_TABS.brands.id} value={A2P_TABS.brands.value} />
					<Tab id={A2P_TABS.campaigns.id} value={A2P_TABS.campaigns.value} />
					<Tab id={A2P_TABS.submissions.id} value={A2P_TABS.submissions.value} />
					<Tab id={A2P_TABS.webhooks.id} value={A2P_TABS.webhooks.value} />
					<Tab id={A2P_TABS.numberAssociations.id} value={A2P_TABS.numberAssociations.value} />
				</Tabs>
				{A2P_TABS.brands.id === selectedTab && <A2PBrands />}
				{A2P_TABS.campaigns.id === selectedTab && <A2PCampaigns />}
				{A2P_TABS.submissions.id === selectedTab && <A2PSubmissions />}
				{A2P_TABS.webhooks.id === selectedTab && <A2PWebhooks />}
				{A2P_TABS.numberAssociations.id === selectedTab && <A2PNumberAssociations />}
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(A2P);

import React, { Component } from "react";
import Select from "react-select";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import "../../styles/css/components/commons/header.css";

class Header extends Component {
	render() {
		let { title, selectedDataType, dataTypes, onDataTypeChange, backUrl, onBackButtonClick, disclaimer, center } = this.props;

		let hasSelector = selectedDataType && dataTypes && dataTypes.length > 1;

		return (
			<div className={`dh-header ${center ? "dh-header--center" : ""}`}>
				{/* backUrl will soon be defacated */}
				{backUrl && backUrl.length > 0 && (
					<div className="dh-header__back-btn">
						<Link to={backUrl}>
							<Icon.ArrowLeftCircle />
						</Link>
					</div>
				)}
				{onBackButtonClick && (
					<div className="dh-header__back-btn" onClick={onBackButtonClick}>
						<Icon.ArrowLeftCircle />
					</div>
				)}
				<div className="dh-header-title">{title}</div>
				{disclaimer && disclaimer.length > 0 && <div className="dh-header__disclaimer">{disclaimer}</div>}

				{this.props.children || hasSelector ? <div className="dh-header__spacer" /> : null}

				{this.props.children}
				{hasSelector && <Select id="data-type-selector" options={dataTypes} value={selectedDataType} onChange={onDataTypeChange} />}
			</div>
		);
	}
}

export default Header;

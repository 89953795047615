import React, { Component } from "react";
import { CSVLink } from "react-csv";

import CsvService from "../../services/CsvService";

import Modal from "../../components/common/DHModal";

class ExportContactsModal extends Component {
	render() {
		const { show, onHide, exportContacts } = this.props;
		const locationName = CsvService.filterActiveLocationName();

		return (
			<Modal show={show} onHide={onHide} title="Download Contacts">
				<div className="modal__flex-container">
					{exportContacts && exportContacts.length > 0 ? (
						<>
							<p>Are you sure you want to download these contacts?</p>
							<div className="mb-button mb-button--fit">
								<CSVLink data={exportContacts} filename={`${locationName}-contacts.csv`} target="_self">
									<span onClick={onHide}>Yes</span>
								</CSVLink>
							</div>
						</>
					) : (
						<p>No contacts selected.</p>
					)}
				</div>
			</Modal>
		);
	}
}

export default ExportContactsModal;

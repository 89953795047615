// Helper service to store user preferences in local storage
const PreferenceService = {
	/**
	 * Gets the data associated with the specified key from local storage.
	 * @param {String} key
	 * @returns {Object|Boolean|Number|String}
	 */
	get(key) {
		let val = localStorage.getItem("dh_pref_" + key);
		let decodedVal = null;

		if (val !== null) {
			try {
				decodedVal = JSON.parse(val);
			} catch (e) {
				decodedVal = val;
			}
		}

		return decodedVal;
	},
	/**
	 * Check if key exists in local storage
	 * @param {String} key
	 * @returns {boolean}
	 */
	keyExists(key) {
		return localStorage.getItem("dh_pref_" + key) !== null;
	},

	/**
	 * Sets the specified key/value pair into local storage. The value should be an object, but can also be a boolean, number, or string.
	 * @param {String} key
	 * @param {Object|Boolean|Number|String} val
	 */
	set(key, val) {
		let encodedVal = val;

		if (typeof val === "object") {
			encodedVal = JSON.stringify(val);
		}

		localStorage.setItem("dh_pref_" + key, encodedVal);
	},

	/**
	 * Clears the data associated with the specified key from local storage.
	 * @param {String} key
	 */
	clear(key) {
		localStorage.removeItem("dh_pref_" + key);
	}
};

export default PreferenceService;

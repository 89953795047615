import React, { PureComponent } from "react";
import { List } from "react-content-loader";

import _ from "underscore";

import UserService from "../../../../services/UserService";
import UtilityService from "../../../../services/UtilityService";
import MessagesService from "../../../../services/MessagesService";
import RealtimeService from "../../../../services/WebsocketsConnection";
import NotificationService from "../../../../services/NotificationService";
import LightboxService from "../../../../services/LightboxService";

import Message from "../Message/Message";
import ForwardMessage from "../ForwardMessage/ForwardMessage";

import "./message-list.css";

class MessageList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			count: 0,
			messages: [],
			media: [],
			limit: 25,
			mediaIndex: 0,

			forwardMessage: null
		};

		this.bottomOfThread = null;
		this.newLine = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.resetComponent();

		NotificationService.subscribeOnce("internalMessageUpdated", "messageList_componentDidMount", event => {
			this.updateMessage(event);
		});

		NotificationService.subscribeOnce("realtimeConnected", "messageList_componentDidMount", () => {
			this.resetComponent();
		});
	}

	componentDidUpdate(prevProps) {
		let { locationId, contactId, senderId } = this.props;

		if (prevProps.locationId !== locationId || prevProps.contactId !== contactId || prevProps.senderId !== senderId) {
			this.resetComponent();
		}
	}

	scrollToBottom(animated = false, forceBottom = false) {
		let options = animated ? { behavior: "smooth", block: "center", inline: "end" } : !forceBottom;

		setTimeout(() => {
			if (this.newLine && !forceBottom) {
				this.newLine.scrollIntoView(options);
			} else {
				if (this.bottomOfThread) {
					this.bottomOfThread.scrollIntoView(options);
				}
			}
		}, 0);
	}

	async resetComponent(props) {
		let { locationId, contactId, senderId } = this.props;

		await this.update({
			loading: true,
			locationId: locationId,
			contactId: contactId,
			senderId: senderId,
			limit: 25
		});

		await this.fetchMessages(locationId, contactId, senderId);

		this.determineReplySuggestions();
		this.determineFacebookEnabled();

		await this.update({
			loading: false
		});

		this.scrollToBottom();
	}

	markMessagesRead(messages) {
		messages.forEach(message => {
			if (message.unread) {
				RealtimeService.markMessageRead(message);
			}
		});
	}

	async determineFacebookEnabled() {
		let { messages } = this.state;

		let canSend = MessagesService.canSendFacebookMessages(messages);

		if (!canSend) {
			NotificationService.publish("threadUpdate", { fbEnabled: false });
		}
	}

	async fetchMessages(locationId, contactId, senderId) {
		let { limit } = this.state;

		let messages = [];
		let count = 0;

		count = await MessagesService.countMessages(locationId, contactId);
		messages = await MessagesService.fetchMessages(locationId, contactId, limit);
		this.markMessagesRead(messages);

		let media = UtilityService.extractMedia(messages);

		await this.update({
			limit: limit,
			count: count,
			messages: messages,
			media: media
		});
	}

	onLoadMoreMessages = async () => {
		let { locationId, contactId, senderId, limit } = this.state;
		let upperLimit = limit + 25;

		await this.update({
			limit: upperLimit
		});
		await this.fetchMessages(locationId, contactId, senderId);
	};

	onMarkAsUnread = async message => {
		await this.updateMessage({
			message_id: message.id,
			unread: true
		});
	};

	async updateMessage({ message_id, content, unread, status, messageState, deliveryState, deliveryCode, deliveryDescription, statusEvents }) {
		let { messages } = this.state;

		for (let i = 0; i < messages.length; i++) {
			let message = messages[i];

			if (message.id === message_id) {
				// If the status changed
				if (status) {
					message.status = status;
				}

				// If it's now unread
				if (typeof unread !== "undefined") {
					message.unread = unread;
				}

				// Update the state of a message
				if (messageState) {
					message.message_state = messageState;
					message.delivery_state = deliveryState;
					message.delivery_code = deliveryCode;
					message.delivery_description = deliveryDescription;
					message.status_events = statusEvents;
				}

				// If the content changed
				if (content) {
					message.content = content;
				}
			}
		}

		await this.update({ messages });

		this.forceUpdate();
	}

	async appendMessage(message) {
		await this.update(state => {
			let messages = state.messages.concat(message);

			messages = _.uniq(messages, m => m.id);

			return {
				messages: messages
			};
		});

		this.updateMedia();
	}

	async updateMedia() {
		let { messages } = this.state;

		let media = UtilityService.extractMedia(messages);

		await this.update({
			media: media
		});
	}

	async onNewMessage(message) {
		let { messages } = this.state;

		let previousMessage = messages[messages.length - 1];

		// If it's a totally new message (it's id is greater than the very last message in the list)
		if (!previousMessage || previousMessage.id < message.id) {
			await this.appendMessage(message);
			await this.scrollToBottom(true, true);
			this.determineReplySuggestions();
		} else {
			// This is a previous message, most likely an update to it's information
			await this.updateMessage({
				message_id: message.id,
				content: message.content,
				messageState: message.message_state,
				deliveryState: message.delivery_state,
				deliveryCode: message.delivery_code,
				deliveryDescription: message.delivery_description,
				status: message.status,
				unread: message.unread,
				statusEvents: message.status_events
			});
		}
	}

	async onMediaClicked(url) {
		await this.update({
			mediaIndex: this.getMediaIndex(url)
		});
		let { media, mediaIndex } = this.state;
		LightboxService.setMedia({ media, mediaIndex });
		LightboxService.open();
	}

	onForwardMessage = async message => {
		await this.update({
			forwardMessage: message
		});
	};

	onPinMessage = async () => {
		this.fetchMessagePins();
	};

	fetchMessagePins = async () => {
		let { fetchMessagePins } = this.props;

		if (fetchMessagePins) {
			fetchMessagePins();
		}
	};

	/**
	 * Update reply suggestions when a new conversation is targeted or new message comes in
	 */
	determineReplySuggestions = async () => {
		try {
			let user = UserService.get();

			let { locationId, messages } = this.state;
			let { disabled } = this.props;

			let numMessages = messages.length;

			// Check if there is any need show reply suggestions
			if (disabled || !user.messenger_show_reply_suggestions || numMessages < 1 || messages[numMessages - 1].direction === "out") {
				this.props.updateReplySuggestions([]);
			} else {
				// Fetch reply suggestions from deepChat
				let lastInboundMessage = messages[numMessages - 1].content;
				let suggestions = await MessagesService.getReplySuggestions(locationId, lastInboundMessage);
				this.props.updateReplySuggestions(suggestions);
			}
		} catch (err) {
			console.log("Failed to fetch reply suggestions");
			console.log(err);
		}
	};

	getMediaIndex(url) {
		let { media } = this.state;

		for (let i = 0; i < media.length; i++) {
			if (media[i].src === url) {
				return i;
			}
		}
		return 0;
	}

	renderDate(message, previousMessage) {
		if (previousMessage && !MessagesService.shouldAppendDate(previousMessage.created_at, message.created_at)) {
			return null;
		}

		let date = MessagesService.getRelativeDate(message.created_at);

		return (
			<div key={`${message.id}-date`} className="mb-message-list-divider">
				<div className="mb-message-list-divider-line" />
				<div className="mb-message-list-divider-date">{date}</div>
				<div className="mb-message-list-divider-line" />
			</div>
		);
	}

	renderMessageItem(message, index) {
		let { messages } = this.state;
		let { messagePins } = this.props;

		let previousMessage = messages[index - 1];
		let elements = [];

		elements.push(this.renderDate(message, previousMessage));
		elements.push(
			<Message
				key={message.id}
				message={message}
				previousMessage={previousMessage}
				onMediaClicked={url => this.onMediaClicked(url)}
				onMarkAsUnread={message => this.onMarkAsUnread(message)}
				onForwardMessage={message => this.onForwardMessage(message)}
				onPinMessage={this.onPinMessage}
				messagePins={messagePins}
			/>
		);

		return elements;
	}

	renderMessageList() {
		let { messages } = this.state;

		return messages.map((message, index) => {
			return this.renderMessageItem(message, index);
		});
	}

	render() {
		let { messages, loading, count, forwardMessage } = this.state;

		return (
			<div className="mb-message-list">
				<div className="mb-message-list-loader">
					{loading && (
						<>
							<List />
							<List />
							<List />
						</>
					)}
				</div>
				{!loading && messages.length < count && (
					<div className="mb-message-list-load-more-button" onClick={this.onLoadMoreMessages}>
						Load More
					</div>
				)}
				{!loading && this.renderMessageList()}
				{forwardMessage && (
					<ForwardMessage message={forwardMessage} onHide={() => this.update({ forwardMessage: null })} onSend={() => this.update({ forwardMessage: null })} />
				)}
				<div className="mb-message-list-bottom">
					<div ref={ref => (this.bottomOfThread = ref)} />
				</div>
			</div>
		);
	}
}

export default MessageList;

import React from "react";

import "../../styles/css/components/commons/toggle.css";
import ToggleSwitch from "./ToggleSwitch";

const Toggle = ({ checked, id, label, description, onChange, disabled = false }) => {
	return (
		<div key="chatHead" className="toggle">
			<div className="toggle__label">
				<div className="toggle__label__title">{label}</div>
				<div className="toggle__label__description">{description}</div>
			</div>

			<ToggleSwitch id={id} checked={checked} disabled={disabled} onChange={onChange} />
		</div>
	);
};

export default Toggle;

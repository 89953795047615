import React, { Component } from "react";
import c3 from "c3";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";

import withLocation from "../../components/common/WithLocation";

class TotalMessages extends Component {
	componentDidMount() {
		this.getMessagesCount();
	}
	onLocationChanged = () => {
		this.getMessagesCount();
	};

	async getMessagesCount() {
		const locationId = UserService.getActiveLocation().id;
		let data = await AnalyticsService.getMessagesCount({ locationId });

		if (!data) {
			document.getElementById("dashboard__total--messages").innerHTML = "";
			return;
		}

		c3.generate({
			bindto: "#dashboard__total--messages",
			size: {
				height: 300
			},
			data: {
				columns: [
					[`${data.outbound} Sent`, data.outbound],
					[`${data.inbound} Received`, data.inbound]
				],
				type: "donut"
			},
			donut: {
				label: {
					format: value => value
				},
				title: `Total: ${data.outbound + data.inbound}`
			},
			legend: {
				item: {
					tile: {
						width: 30
					}
				}
			}
		});
	}
	render() {
		const { cardName } = this.props;

		return (
			<>
				<div className="dashboard__card--total-messages__header">
					{cardName ? cardName : "Total Messages"}
					<span className="dashboard__card__header__icon">
						<Icon.Info size="20" data-tip data-for="total-messages" />
					</span>
					<ReactTooltip id="total-messages" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
						<div className="text-left">Last 30 days.</div>
					</ReactTooltip>
				</div>
				<div className="dashboard__card--total-messages__chart">
					<div id="dashboard__total--messages" />
				</div>
			</>
		);
	}
}

export default withLocation(TotalMessages);

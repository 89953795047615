import React, { Component } from "react";
import { Radio } from "react-bootstrap";
import moment from "moment";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import Kichiri from "../../services/KichiriService";
import PreferenceService from "../../services/PreferenceService";

import Alert from "./Alert";
import TextArea from "./TextArea";

import { DATE_FORMAT, CLOSEIO } from "../../constants/CommonConstants";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

class ReferralForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			businessName: "",
			contactName: "",
			contactEmail: "",
			contactPhone: "",
			yourName: "",
			notes: "",
			benefitType: "",
			locationName: "",
			locationId: "",
			companyName: "",
			referralSubmitError: false,
			referralSubmitted: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		let { t } = this.props;
		let user = UserService.getActiveLocation();

		await this.update({
			businessName: "",
			contactName: "",
			contactEmail: "",
			contactPhone: "",
			yourName: UserService.getCurrentUserFullName(),
			notes: "",
			benefitType: t("$150 Gift Card"),
			locationName: user.name || "",
			locationId: user.id || "",
			companyName: user.name || "",
			referralSubmitError: false,
			referralSubmitted: false
		});
	}

	async handleNotesChange(e) {
		await this.update({ notes: e.target.value });
	}
	async handleBusinessNameOnChange(e) {
		await this.update({ businessName: e.target.value });
	}
	async handleOwnerNameOnChange(e) {
		await this.update({ contactName: e.target.value });
	}
	async handleOnEmailOnChange(e) {
		await this.update({ contactEmail: e.target.value });
	}
	async handleOnPhoneOnChange(e) {
		await this.update({ contactPhone: e.target.value });
	}
	async handleBenefitTypeOnChange(e) {
		await this.update({ benefitType: e.target.value });
	}
	async handleYourNameOnChange(e) {
		await this.update({ yourName: e.target.value });
	}
	async handleOnReferralSubmit(e) {
		e.preventDefault();

		const { businessName, contactName, contactPhone, contactEmail, benefitType, yourName, notes, locationName, locationId, companyName } = this.state;
		const userId = UserService.get() ? UserService.get().id : 0;
		const data = JSON.stringify({
			name: businessName,
			description: `This business is being referred by ${yourName} from ${locationName} with selected benefit of "${benefitType}". Notes: ${notes}`,
			contacts: [
				{
					name: contactName,
					emails: [
						{
							type: "office",
							email: contactEmail === "" ? "support@demandhub.co" : contactEmail
						}
					],
					phones: [
						{
							type: "office",
							phone: contactPhone
						}
					]
				}
			],
			referredBy: yourName,
			referredByLocationId: locationId,
			referredByLocationName: locationName,
			referredByCompanyName: companyName,
			benefitType: benefitType,
			notes: notes || "",
			[CLOSEIO.LISTNAME_FIELD]: "WebApp Referrals"
		});

		try {
			await CrmIntegrationsService.createReferral(data);

			await this.update({ referralSubmitted: true });

			PreferenceService.set(
				`referral_date_hidden_expiry_${userId}`,
				moment()
					.add(30, "days")
					.format(DATE_FORMAT)
			);
		} catch (e) {
			console.error(e);
			await this.update({ referralSubmitError: true });
		}
	}

	onClose = () => {
		this.update({ referralSubmitted: false });
		if (this.props.handleOnCancel) {
			this.props.handleOnCancel();
		}
	};

	render() {
		const {
			businessName,
			contactName,
			contactEmail,
			contactPhone,
			yourName,
			benefitType,
			notes,
			locationName,
			referralSubmitError,
			referralSubmitted
		} = this.state;

		let { t } = this.props;

		return (
			<form className="form-horizontal" onSubmit={e => this.handleOnReferralSubmit(e)} style={{ width: "100%" }}>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Your Name")}</label>
					<div className="col-sm-9">
						<input
							name="yourName"
							id="yourName"
							type="text"
							onChange={e => this.handleYourNameOnChange(e)}
							value={yourName}
							className="Common__input"
							required
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Your Location")}</label>
					<div className="col-sm-9">
						<input name="yourLocation" id="yourLocation" type="text" value={locationName} className="Common__input" disabled autoComplete="off" />
					</div>
				</div>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Benefit Type")}</label>
					<div className="col-sm-9">
						<Radio name="radioGroup" value="$150 Gift Card" onChange={e => this.handleBenefitTypeOnChange(e)} checked={benefitType === "$150 Gift Card"}>
							{t("$150 Gift Card")}
						</Radio>
						<Radio
							name="radioGroup"
							value="Free Service for a month"
							onChange={e => this.handleBenefitTypeOnChange(e)}
							checked={benefitType === "Free Service for a month"}
						>
							{t("Free Service for a month")}
						</Radio>
					</div>
				</div>
				<br />
				<div className="text-center">
					<h3>{t("Referred Business Details")}</h3>
					<hr />
				</div>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Business Name")}</label>
					<div className="col-sm-9">
						<input
							name="businessName"
							id="businessName"
							type="text"
							onChange={e => this.handleBusinessNameOnChange(e)}
							value={businessName}
							className="Common__input"
							required
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Contact Name")}</label>
					<div className="col-sm-9">
						<input
							name="ownerName"
							id="ownerName"
							type="text"
							onChange={e => this.handleOwnerNameOnChange(e)}
							value={contactName}
							className="Common__input"
							required
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Contact Phone")}</label>
					<div className="col-sm-9">
						<input
							name="phone"
							id="phone"
							type="tel"
							onChange={e => this.handleOnPhoneOnChange(e)}
							value={contactPhone}
							className="Common__input"
							required={contactPhone === "" && contactEmail === "" ? true : false}
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Contact Email")}</label>
					<div className="col-sm-9">
						<input
							name="email"
							id="email"
							type="email"
							onChange={e => this.handleOnEmailOnChange(e)}
							value={contactEmail}
							className="Common__input"
							required={contactPhone === "" && contactEmail === "" ? true : false}
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="form-group">
					<label className="col-sm-3 control-label">{t("Notes")}</label>
					<div className="col-sm-9">
						<TextArea
							name="notes"
							id="notes"
							placeholder={t("(Optional)")}
							onChange={e => this.handleNotesChange(e)}
							value={notes}
							autoComplete="off"
							style={{ resize: "none" }}
							blueBorder={true}
							required={false}
						/>
					</div>
				</div>
				<div className="form-group">
					<div className="col-sm-9 col-sm-offset-3">
						<button className="btn btn-primary" type="submit">
							{t("Submit")}
						</button>
						<button className="btn btn-warning btn-space-between" onClick={() => this.onClose()} type="button">
							{t("Cancel")}
						</button>
					</div>
				</div>
				<Alert
					type="error"
					show={referralSubmitError}
					title={t("Failed to submit referral")}
					confirm="OK"
					onClose={() => {
						this.update({ referralSubmitError: false });
					}}
				>
					<div>{t("Please try again or contact us directly at support@demandhub.co.")}</div>
				</Alert>
				<Alert
					type="success"
					show={referralSubmitted}
					title={t("Referral Submitted Successfully")}
					confirm="OK"
					onClose={() => {
						this.onClose();
					}}
				>
					<div>
						{t(
							"Thank you! Your referral has been submitted successfully. We will be contacting the business you referred shortly and you will receive your reward after they sign up."
						)}
					</div>
				</Alert>
			</form>
		);
	}
}

export default withTranslation(null, { withRef: true })(ReferralForm);

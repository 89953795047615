import React from "react";
import AsyncSelect from "react-select/lib/Async";

import "../../styles/css/components/commons/dh-select.css";

const customStyles = {
	menu: (provided, state) => {
		return {
			...provided,
			fontSize: 14
		};
	},
	control: (provided, state) => {
		return {
			...provided,
			borderRadius: 8,
			borderColor: state.isFocused ? "#60A9FF" : "#e5e6e7",
			minHeight: 40,
			fontSize: 14
		};
	},
	group: (provided, state) => {
		return {
			...provided,
			borderRadius: 8,
			borderColor: state.isFocused ? "#60A9FF" : "#e5e6e7",
			minHeight: 40,
			fontSize: 14
		};
	}
};

const Select = React.forwardRef(
	(
		{ id, name, value, onSearch, onSelect, disabled = false, label, description, placeholder, isMulti = false, required = false, defaultOptions = false },
		ref
	) => {
		return (
			<div className="select">
				{label && (
					<div className="select__label">
						{label} {required && <span>*</span>}
					</div>
				)}
				{description && <div className="select__description">{description}</div>}

				<AsyncSelect
					id={id}
					name={name}
					styles={customStyles}
					placeholder={placeholder}
					loadOptions={onSearch}
					onChange={onSelect}
					value={value}
					isDisabled={disabled}
					isMulti={isMulti}
					defaultOptions={defaultOptions}
				/>
			</div>
		);
	}
);

export default Select;

import React from "react";
import ContentLoader from "react-content-loader";
import Select from "react-select";
import moment from "moment";
import * as Icon from "react-feather";

import TeamChatService from "../../../../services/TeamChatService";
import ToastService from "../../../../services/ToastService";
import UtilityService from "../../../../services/UtilityService";
import UserService from "../../../../services/UserService";
import NotificationService from "../../../../services/NotificationService";

import Alert from "../../../../components/common/Alert";
import TeamChatScheduledMessagesListItem from "./TeamChatScheduledMessagesListItem";

import "./team-chat-scheduled-message-list.css";

class TeamChatScheduledMessagesList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			queuedMessages: [],
			selectedMessage: null,
			conversationId: this.props.conversationId ? this.props.conversationId : null,
			selectedConversation: null,
			conversationOptions: [],
			conversations: [],
			// Modal Flags
			showConfirmDeleteModal: false,
			showConfirmSaveModal: false,
			// Editing related states
			editing: false,
			content: "",
			minSendAfter: UtilityService.getNext15Minutes(),
			sendAfter: UtilityService.getNext15Minutes()
		};
	}

	componentDidMount() {
		let user = UserService.get();
		NotificationService.subscribeOnce("newInternalMessage", "tcsml_componentDidMount", async message => {
			if (message.conversation_id && message.sender_user_id === user.id && message.is_scheduled_message) {
				this.resetComponent();
			}
		});

		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async (showLoading = true) => {
		let { conversationId } = this.state;

		if (showLoading) {
			await this.update({ loading: true });
		}

		let queuedMessages = await TeamChatService.fetchScheduledMessages(conversationId);

		if (!queuedMessages) {
			ToastService.error("Failed to fetch scheduled messages");
			await this.update({
				loading: false,
				selectedConversation: null,
				conversationOptions: [],
				conversations: [],
				minSendAfter: UtilityService.getNext15Minutes(),
				sendAfter: UtilityService.getNext15Minutes(),
				showConfirmSaveModal: false,
				showConfirmDeleteModal: false,
				selectedMessage: null
			});
			return;
		}

		let convos = await TeamChatService.fetchConversationsWithQueuedMessages();

		if (!convos) {
			convos = [];
		}

		let conversationOptions = convos.map(c => {
			return { label: `${c.name} (${c.TeamchatMessageQueues.length})`, value: c.id };
		});

		conversationOptions.unshift({ label: "All", value: null });

		let selectedConversation = conversationOptions.find(option => option.value === conversationId);
		let conversations = convos;

		await this.update({
			loading: false,
			queuedMessages,
			selectedConversation,
			conversationOptions,
			conversations,
			minSendAfter: UtilityService.getNext15Minutes(),
			sendAfter: UtilityService.getNext15Minutes(),
			showConfirmSaveModal: false,
			showConfirmDeleteModal: false,
			selectedMessage: null
		});
	};

	onConversationChange = async option => {
		await this.update({ selectedConversation: option, conversationId: option.value });
		this.resetComponent();
	};

	onEditMessage = queuedMessage => {
		this.update({
			selectedMessage: queuedMessage,
			sendAfter: queuedMessage.send_after,
			content: queuedMessage.content,
			editing: true,
			minSendAfter: UtilityService.getNext15Minutes()
		});
	};

	onDeleteMessage = queuedMessage => {
		this.update({ showConfirmDeleteModal: true, selectedMessage: queuedMessage, editing: false });
	};

	onContentChange = async event => {
		let { content } = this.state;

		content = event.target.value;

		await this.update({
			content
		});
	};

	onDateChange = async value => {
		let { sendAfter } = this.state;

		sendAfter = value;

		await this.update({
			sendAfter
		});
	};

	onCloseConfirmDelete = async confirm => {
		let { selectedMessage } = this.state;

		if (!confirm) {
			this.update({ showConfirmDeleteModal: false, selectedMessage: null });
			return;
		}

		let response = await TeamChatService.updateScheduledMessage({ id: selectedMessage.id, status: "deleted" });
		if (!response) {
			ToastService.error("Failed to delete. Please try again.");
		} else {
			ToastService.info("Scheduled Message Deleted.");
		}

		NotificationService.publish("teamChatScheduledMessageChange");

		this.resetComponent(false);
	};

	onCloseConfirmSave = async confirm => {
		if (!confirm) {
			this.update({ showConfirmSaveModal: false, selectedMessage: null });
			return;
		}

		await this.onSave();
		this.resetComponent(false);
	};

	onSave = async () => {
		let { selectedMessage, content, sendAfter } = this.state;

		let result = await TeamChatService.updateScheduledMessage({
			id: selectedMessage.id,
			content: content,
			sendAfter: sendAfter
		});

		if (!result) {
			ToastService.error("Failed to save. Please try again.");
			this.onCloseConfirmDelete();
			return;
		}

		ToastService.info("Scheduled Message Saved.");
		this.onCloseConfirmDelete();
	};

	renderBackAction = () => {
		let { conversationId, conversations } = this.state;

		// If "All" is selected in the conversation selector
		if (!conversationId) {
			return null;
		}

		let { onClose } = this.props;

		let convo = conversations.find(c => c.id === conversationId);

		return (
			<div className="mb-tcsm__back__message" onClick={() => onClose(convo)}>
				<Icon.ArrowLeftCircle size={24} />
			</div>
		);
	};

	renderSelectConversation = () => {
		let { conversationOptions, selectedConversation } = this.state;
		return (
			<div className="mb-tcsm__back__select">
				<div className="mb-tcsm__back__select__label">Conversation</div>
				<Select
					id={"teamchat-channel"}
					className="mb-tcsm__back__select__select"
					options={conversationOptions}
					value={selectedConversation}
					placeholder="Conversation"
					maxMenuHeight={250}
					onChange={this.onConversationChange}
				/>
			</div>
		);
	};

	render = () => {
		let {
			loading,
			conversationId,
			queuedMessages,
			showConfirmDeleteModal,
			showConfirmSaveModal,
			editing,
			selectedMessage,
			content,
			sendAfter,
			minSendAfter,
			conversations,
			selectedConversation
		} = this.state;

		if (loading) {
			return (
				<div className="mb-tcsm">
					<div className="mb-tcsm__back">
						{this.renderBackAction()}
						Loading Scheduled Messages...
						{this.renderSelectConversation()}
					</div>
					<ContentLoader height={600} width={"100%"}>
						<rect x="20" y="0" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="155" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="310" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="465" rx="5" ry="5" width="98%" height="135" />
					</ContentLoader>
				</div>
			);
		}

		if (!queuedMessages || queuedMessages.length === 0) {
			return (
				<div className="mb-tcsm">
					<div className="mb-tcsm__back">
						{this.renderBackAction()}
						No Scheduled Messages found.
						{this.renderSelectConversation()}
					</div>
				</div>
			);
		}

		return (
			<div className="mb-tcsm">
				<div className="mb-tcsm__back">
					{this.renderBackAction()}
					{conversationId ? "Scheduled Messages for this conversation." : "All Scheduled Messages."}
					{this.renderSelectConversation()}
				</div>
				{queuedMessages.map(queuedMessage => {
					// Don't show any scheduled message that are supposed to be sent already, let's see if this caused some funnies
					if (moment().isAfter(moment(queuedMessage.send_after))) {
						return null;
					}
					return (
						<TeamChatScheduledMessagesListItem
							key={`${queuedMessage.id}-${queuedMessage.updated_at}`}
							conversationId={selectedConversation ? selectedConversation.value : null}
							conversations={conversations}
							queuedMessage={queuedMessage}
							selectedMessage={selectedMessage}
							editing={editing}
							content={content}
							sendAfter={sendAfter}
							minSendAfter={minSendAfter}
							onEditMessage={() => this.onEditMessage(queuedMessage)}
							onDeleteMessage={() => this.onDeleteMessage(queuedMessage)}
							onContentChange={this.onContentChange}
							onDateChange={this.onDateChange}
							onSave={() => this.update({ showConfirmSaveModal: true })}
							onCancel={() => this.update({ editing: false, sendAfter: null, content: "", selectedMessage: null })}
						/>
					);
				})}
				<Alert type="info" show={showConfirmSaveModal} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onCloseConfirmSave}>
					Are you sure you would like to save this scheduled message?
				</Alert>
				<Alert type="info" show={showConfirmDeleteModal} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onCloseConfirmDelete}>
					Are you sure you would like to delete this scheduled message?
				</Alert>
			</div>
		);
	};
}

export default TeamChatScheduledMessagesList;

import React from "react";
import ReactSelect from "react-select";

import "../../styles/css/components/commons/dh-select.css";

const customStyles = {
	menu: (provided, state) => {
		return {
			...provided,
			fontSize: 14
		};
	},
	control: (provided, state) => {
		return {
			...provided,
			borderWidth: 1,
			borderRadius: 8,
			borderColor: state.isFocused ? "#60A9FF" : "#e5e6e7",
			minHeight: 40,
			fontSize: 14
		};
	},
	group: (provided, state) => {
		return {
			...provided,
			borderWidth: 1,
			borderRadius: 8,
			borderColor: state.isFocused ? "#60A9FF" : "#e5e6e7",
			minHeight: 40,
			fontSize: 14
		};
	}
};

const Select = React.forwardRef(
	({ id, name, value, onChange, disabled = false, required = false, label, description, options, isClearable, isMulti = false, className = "" }, ref) => {
		// Currently DHSelect can only support validation if the field has been filled,
		// that's why in the case that an object is passed, it should have at least 1 more keys to be considered a valid input, we use the phantom input below to do the validation
		let valueValidate = value;
		if (typeof value === "object") {
			try {
				valueValidate = Object.keys(value).length > 0 ? value : "";
			} catch (error) {
				valueValidate = "";
			}
		}

		let classes = "select";

		if (className) {
			classes = classes + " " + className;
		}

		return (
			<div className={classes}>
				{label && (
					<div className="select__label">
						{label} {required && <span className="select__label__required">*</span>}
					</div>
				)}
				{description && <div className="select__description">{description}</div>}

				<ReactSelect
					isMulti={isMulti}
					styles={customStyles}
					id={id}
					name={name}
					onChange={onChange}
					value={value}
					isDisabled={disabled}
					isClearable={isClearable}
					options={options}
				/>
				<input
					ref={ref}
					className="select__phantom-input"
					type="text"
					value={valueValidate}
					tabIndex={-1}
					autoComplete="off"
					required={required}
					disabled={disabled}
					onChange={() => {}}
				/>
			</div>
		);
	}
);

export default Select;

import React, { Component } from "react";
import ReactSwitch from "react-switch";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import TemplateService from "../../services/TemplateService";
import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import MessagesService from "../../services/MessagesService";

import Modal from "./DHModal";
import LanguageDropdown from "./LanguageDropdown";
import Spinners from "./Spinners";
import TextArea from "./TextArea";
import Checkbox from "./Checkbox";
import Tabs from "./Tabs";
import Tab from "./Tab";
import AttachmentItem from "./AttachmentItem";

import { CODES_TO_LABEL, LANGUAGES } from "../../constants/LanguageConstants";
import { TEMPLATE_TYPE, EDIT_TEMPLATE_TABS, MESSAGE_VARIABLE_PATTERNS } from "../../constants/TemplateConstants";
import { MODE, MEDIUM } from "../../constants/Messenger";

import "../../styles/css/components/commons/edit-templates.css";

class EditTemplateModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			edited: false,
			types: [],
			type: "general",
			name: "",
			subject: "",
			text: "",
			html: "",
			status: "active",
			defaultType: false,
			defaultTypeTrue: false,
			customizedHtml: false,
			showAdvancedSection: false,
			variables: [],
			isFavourite: false,

			// Backup template
			backupEnabled: false,
			backupText: "",
			backupHtml: "",
			customizedBackupHtml: false,
			safeVariables: [], // message variables that we know we'll have access to

			selectedTab: EDIT_TEMPLATE_TABS.general.id,

			attachmentsChanged: false,
			mediaIds: []
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = async prevProps => {
		if (prevProps.templateId !== this.props.templateId || (this.props.show && prevProps.show !== this.props.show)) {
			await this.update({ loading: true });
			this.resetComponent();

			if (this.props.templateId && this.props.templateId !== 0) {
				await this.fetchTemplateDetails();
				return;
			}

			await this.update({
				name: "",
				subject: "",
				text: "",
				html: "",
				type: this.props.templateType ? this.props.templateType : "general",
				defaultType: false,
				language: LANGUAGES.enCA,
				selectedTab: EDIT_TEMPLATE_TABS.general.id,
				loading: false,

				backupEnabled: false,
				backupText: "",
				backupHtml: "",
				customizedBackupHtml: false,
				safeVariables: [],

				// Reset attachment related states
				attachmentsChanged: false,
				mediaIds: [],
				isFavourite: false
			});
			await this.setVariables();
		}
		return;
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = () => {
		if (!this.props.templateId && !this.isInCreateMode()) {
			return;
		}

		this.fetchTypes();

		let { text, html, backupText, backupHtml } = this.state;
		let { templateId } = this.props;
		this.update({
			customizedHtml: !(templateId === 0 || text.trim() === html.trim()),
			customizedBackupHtml: backupText.trim() !== backupHtml.trim(),
			showAdvancedSection: false,
			edited: false
		});
	};

	onTabSelect = tab => {
		this.setState({ selectedTab: tab.id });
	};

	async fetchTemplateDetails() {
		const { templateId } = this.props;
		try {
			if (!templateId || this.isInCreateMode()) {
				return;
			}

			let data = await TemplateService.getTemplate({ templateId });

			// For now we only have the ability to send one attachment at a time
			let media = null;

			// If this scheduled message has any media attachments
			if (data.Media && data.Media.length > 0) {
				media = data.Media[0].download_url;
			}

			if (media) {
				// For now, we only allow one media
				await this.update({
					mediaIds: [data.Media[0].id]
				});
			}

			await this.update({
				name: data.name || "",
				type: data.type || "",
				subject: data.msg_subject || "",
				text: data.msg_text || "",
				html: data.msg_html || "",
				status: data.status || "",
				defaultType: data.default_for_type,
				defaultTypeTrue: data.default_for_type,

				backupEnabled: data.has_backup,
				backupText: data.backup_text,
				backupHtml: data.backup_html,

				language: data.language,
				loading: false,
				attachmentsChanged: false,
				isFavourite: data.is_favourite
			});

			this.resetComponent();
			await this.setVariables();
		} catch (error) {
			console.log(error);
			this.setState({ loading: false });
		}
	}

	fetchTypes = async () => {
		let types = await TemplateService.getTypes();
		await this.update({ types });
	};

	setVariables = async () => {
		let { type } = this.state;
		let variables = await TemplateService.getMessageVariablesForType(type);

		// Filter variables to only the safe ones
		let safeVariables = [];
		if (variables && variables.length > 0) {
			safeVariables = variables.filter(v => v.id.includes("location") || v.id.includes("company") || v.id.includes("Url"));
		}

		await this.update({ variables, safeVariables });
	};

	isInCreateMode = () => {
		return this.props.templateId === 0;
	};

	getTitle = () => {
		let { t } = this.props;

		return this.isInCreateMode() ? t("Create Template") : t("Update Template");
	};

	isSaveable = () => {
		let { edited } = this.state;
		return edited;
	};

	includesReviewUrl = text => {
		if (!text || text.length < 1) {
			return false;
		}

		if (!text.includes(MESSAGE_VARIABLE_PATTERNS.reviewUrl)) {
			return false;
		}

		return true;
	};

	handleOnSubmit = async () => {
		let { templateId, typeEditable, templateType, t } = this.props;
		let {
			name,
			subject,
			text,
			html,
			backupEnabled,
			backupText,
			backupHtml,
			status,
			defaultType,
			language,
			attachmentsChanged,
			mediaIds,
			isFavourite
		} = this.state;

		if (!this.isSaveable()) {
			return;
		}

		await this.update({ loading: true });

		const type = typeEditable ? this.state.type : templateType;

		if (type === TEMPLATE_TYPE.reviewRequest) {
			if (!this.includesReviewUrl(text)) {
				text = text.trim() + ` ${MESSAGE_VARIABLE_PATTERNS.reviewUrl}`;
			}
			if (!this.includesReviewUrl(html)) {
				html = html.trim() + ` ${MESSAGE_VARIABLE_PATTERNS.reviewUrl}`;
			}
		}

		let locationId = UserService.getActiveLocation().id;
		let companyId = UserService.getActiveCompany().id;

		let newMediaIds = [];
		if (attachmentsChanged && mediaIds && mediaIds.length > 0) {
			// For now, we only support 1 media
			let uploadedMedia = await MessagesService.uploadLocalMedia({
				mediaIds: mediaIds,
				mode: MODE.customer,
				medium: MEDIUM.sms.key,
				limit: 1,
				companyId,
				locationId
			});

			if (!uploadedMedia) {
				ToastService.error(t("Error uploading media."));
				await this.update({ loading: false });
				return;
			}

			newMediaIds = uploadedMedia.map(m => m.id);
			MessagesService.clearLocalMedia();

			// Set the media ids to be the same as the ones we just uploaded
			await this.update({ mediaIds: newMediaIds });
			mediaIds = newMediaIds;
		}

		const templateData = {
			locationId: UserService.getActiveLocation().id,
			name,
			type: type,
			subject,
			text,
			html,
			hasBackup: backupEnabled,
			backupText,
			backupHtml,
			status,
			defaultType,
			templateId,
			language,
			mediaIds,
			isFavourite
		};

		let data = null;
		if (this.isInCreateMode()) {
			data = await TemplateService.create(templateData);
		} else {
			data = await TemplateService.update(templateData);
		}

		if (!data) {
			ToastService.info(t("An error occurred trying to save the template."));
			await this.update({ loading: false });
			return;
		}

		if (this.props.onSubmit) {
			this.props.onSubmit();
		}

		await this.update({ loading: false });
	};

	handleGenericEventHandler = async event => {
		if (event.target.name === "text" || event.target.name === "html" || event.target.name === "backupText" || event.target.name === "backupHtml") {
			this.editTextOrHtml(event);
			return;
		}

		await this.update({ [event.target.name]: event.target.value, edited: true });
	};

	editTextOrHtml = async event => {
		let value = event.target.value;
		if (value.length > 1000) {
			return;
		}

		let updateObj = {
			edited: true
		};

		const { customizedHtml, customizedBackupHtml } = this.state;
		if (event.target.name === "html") {
			updateObj.html = value;
			if (!customizedHtml) {
				updateObj.text = value;
			}
		} else if (event.target.name === "text") {
			updateObj.text = value;
			if (!customizedHtml) {
				updateObj.html = value;
			}
		} else if (event.target.name === "backupHtml") {
			updateObj.backupHtml = value;
			if (!customizedBackupHtml) {
				updateObj.backupText = value;
			}
		} else if (event.target.name === "backupText") {
			updateObj.backupText = value;
			if (!customizedBackupHtml) {
				updateObj.backupHtml = value;
			}
		}

		await this.update(updateObj);
	};

	handleTypeOnChange = async e => {
		await this.update({
			type: e.target.value,
			edited: true
		});
		await this.setVariables();
	};

	handleDefaultTypeOnChange = async () => {
		await this.update({ defaultType: !this.state.defaultType, edited: true });
	};

	handleFavouriteOnChange = async () => {
		await this.update({ isFavourite: !this.state.isFavourite, edited: true });
	};

	onFileAdded = async files => {
		let file = files[0];
		let localMediaId = MessagesService.storeLocalMedia({ file }).id;
		let { mediaIds } = this.state;

		// For now, we only support one media attachment
		mediaIds = [];
		mediaIds.push(localMediaId);

		await this.update({
			mediaIds,
			attachmentsChanged: true,
			edited: true
		});
	};

	onHideAttachment = async () => {
		await this.update({
			attachmentsChanged: true,
			mediaIds: [],
			edited: true
		});
	};

	renderMainForm = () => {
		let { typeEditable, t } = this.props;
		const { name, type, text, types, defaultType, defaultTypeTrue, variables, language, mediaIds, isFavourite } = this.state;
		return (
			<>
				<div className="modal__field edit-templates__body__name">{t("Template Name")}</div>
				<input
					name="name"
					id="template-name"
					type="text"
					onChange={e => this.handleGenericEventHandler(e)}
					value={name}
					className="Common__input"
					required
					autoComplete="off"
				/>

				<div className="modal__field">{t("Type")}</div>
				<select
					id="template-type"
					className="Common__input"
					name="type"
					onChange={e => this.handleTypeOnChange(e)}
					value={type}
					disabled={typeEditable ? false : true}
				>
					{types.map(aType => {
						return (
							<option key={aType} value={aType}>
								{TemplateService.getTypeNameFromType(aType)}
							</option>
						);
					})}
				</select>

				<div className="modal__field">{t("Language")}</div>
				<LanguageDropdown
					name="language"
					id="language"
					onChange={newValue => this.setState({ language: newValue.value, edited: true })}
					value={{ label: CODES_TO_LABEL[language], value: language }}
					required
				/>

				<div className="modal__field">{t("Message Text")}</div>
				<TextArea
					name="text"
					id="msg-text"
					type="text"
					blueBorder={true}
					onChange={e => this.handleGenericEventHandler(e)}
					value={text}
					required
					height={130}
					rows={5}
					style={{ resize: "none" }}
					showFeedbackFaces={true}
					showFeedbackLength={true}
					showScrollbar={false}
					placeholder={t("Message text...")}
					showVariables={variables && variables.length > 0 ? true : false}
					showCustomFields={true}
					varDropdownVisibleY={-15}
					varDropdownVisibleX={-20}
					varDropdownVisibleZ={0}
					variables={variables}
					inviteTypes={types}
					attachments={true}
					onFileAdded={this.onFileAdded}
				/>

				{mediaIds && mediaIds.length > 0 && (
					<div className="modal__attachments">
						<div className="modal__field">{t("Attachment")}</div>
						{mediaIds.map(mediaId => (
							<AttachmentItem key={mediaId} mediaId={mediaId} hide={this.onHideAttachment} />
						))}
					</div>
				)}

				<div className="modal__field">{t("Default For Template Type")}</div>
				<ReactSwitch
					id="default-template"
					onChange={() => this.handleDefaultTypeOnChange()}
					checked={defaultType}
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#4A90E2"
					disabled={defaultTypeTrue}
				/>
				{!this.isInCreateMode() && (
					<>
						<div className="modal__field">{t("Favourite / Pin Template")}</div>
						<ReactSwitch
							id="favourite-template"
							onChange={() => this.handleFavouriteOnChange()}
							checked={isFavourite}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#4A90E2"
							disabled={false}
						/>
					</>
				)}
			</>
		);
	};

	renderEmailForm = () => {
		let { t } = this.props;
		const { type, subject, text, html, variables, customizedHtml } = this.state;
		return (
			<div>
				<>
					<div className="modal__field">{t("Message Subject")}</div>
					<input
						name="subject"
						id="msg-subject"
						type="text"
						onChange={e => this.handleGenericEventHandler(e)}
						value={subject}
						className="Common__input"
						autoComplete="off"
					/>
				</>

				<div className="modal__field">{t("Message Text")}</div>
				<TextArea disabled={true} value={text} style={{ resize: "none" }}>
					{text}
				</TextArea>

				<div className="modal__field__row">
					<div className="modal__field modal__field__row__item" data-tip data-for="view-all-notes">
						{t("Customize HTML")} <Icon.Info size="14" />
						<ReactTooltip id="view-all-notes" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
							{t("For email messages")}
						</ReactTooltip>
					</div>
					<div className="modal__field__row__item edit-templates__body__checkbox">
						<Checkbox
							id="copy_from_message_text"
							name="copy_from_message_text"
							checked={customizedHtml}
							onChange={() => {
								this.setState({ customizedHtml: !this.state.customizedHtml }, () => {
									if (!this.state.customizedHtml) {
										this.setState({ html: this.state.text });
									}
								});
							}}
						/>
					</div>
				</div>

				<div className="modal__field">{t("Message Html")}</div>
				<TextArea
					name="html"
					id="msg-html"
					type="text"
					blueBorder={true}
					onChange={e => this.handleGenericEventHandler(e)}
					value={html}
					required
					height={130}
					rows={5}
					style={{ resize: "none" }}
					showFeedbackFaces={true}
					showFeedbackLength={true}
					showScrollbar={false}
					placeholder={t("Message html...")}
					showVariables={true}
					showCustomFields={true}
					varDropdownVisibleY={-20}
					varDropdownVisibleX={-20}
					varDropdownVisibleZ={0}
					variables={variables}
					disabled={!customizedHtml}
				/>
			</div>
		);
	};

	renderBackupForm = () => {
		let { t } = this.props;
		let { backupEnabled, backupText, backupHtml, safeVariables, types, customizedBackupHtml } = this.state;
		return (
			<div>
				<div className="modal__field">
					{t("The backup template will be used if any message variable can't be accessed.")} <br />{" "}
					{t("For example, if the contact's name or a custom field is empty for a specific contact.")} <br />{" "}
					{t("Only variables sure to be known will be accessible.")}
				</div>
				<div className="modal__field__row">
					<div className="modal__field modal__field__row__item" data-tip data-for="view-all-notes">
						{t("Enable backup template")}
					</div>
					<div className="modal__field__row__item edit-templates__body__checkbox">
						<Checkbox
							id="enable_backup"
							name="enable_backup"
							checked={backupEnabled}
							onChange={() => {
								this.setState({ backupEnabled: !this.state.backupEnabled });
							}}
						/>
					</div>
				</div>
				{backupEnabled && (
					<>
						<div className="modal__field">{t("Message Text")}</div>
						<TextArea
							name="backupText"
							id="backupText"
							type="text"
							blueBorder={true}
							onChange={e => this.handleGenericEventHandler(e)}
							value={backupText}
							required
							height={130}
							rows={5}
							style={{ resize: "none" }}
							showFeedbackFaces={true}
							showFeedbackLength={true}
							showScrollbar={false}
							placeholder={t("Message text...")}
							showVariables={safeVariables && safeVariables.length > 0 ? true : false}
							showCustomFields={true}
							varDropdownVisibleY={-15}
							varDropdownVisibleX={-20}
							varDropdownVisibleZ={0}
							variables={safeVariables}
							hideCustomFields={true}
							inviteTypes={types}
							showMessageTemplates={false}
						/>

						<div className="modal__field__row">
							<div className="modal__field modal__field__row__item" data-tip data-for="view-all-notes">
								{t("Customize HTML")} <Icon.Info size="14" />
								<ReactTooltip id="view-all-notes" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
									{t("For email messages")}
								</ReactTooltip>
							</div>
							<div className="modal__field__row__item edit-templates__body__checkbox">
								<Checkbox
									id="copy_from_backup_message_text"
									name="copy_from_backup_message_text"
									checked={customizedBackupHtml}
									onChange={() => {
										this.setState({ customizedBackupHtml: !this.state.customizedBackupHtml }, () => {
											if (!this.state.customizedBackupHtml) {
												this.setState({ backupHtml: this.state.backupText });
											}
										});
									}}
								/>
							</div>
						</div>

						<div className="modal__field">{t("Backup Html")}</div>
						<TextArea
							name="backupHtml"
							id="backupHtml"
							type="text"
							blueBorder={true}
							onChange={e => this.handleGenericEventHandler(e)}
							value={backupHtml}
							required
							height={130}
							rows={5}
							style={{ resize: "none" }}
							showFeedbackFaces={true}
							showFeedbackLength={true}
							showScrollbar={false}
							placeholder={t("Message html...")}
							showVariables={safeVariables && safeVariables.length > 0 ? true : false}
							showCustomFields={true}
							varDropdownVisibleY={-15}
							varDropdownVisibleX={-20}
							varDropdownVisibleZ={0}
							variables={safeVariables}
							hideCustomFields={true}
							inviteTypes={types}
							showMessageTemplates={false}
							disabled={!customizedBackupHtml}
						/>
					</>
				)}
			</div>
		);
	};

	renderForm = () => {
		let { t } = this.props;
		const { selectedTab, type } = this.state;

		return (
			<div className="edit-templates">
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={EDIT_TEMPLATE_TABS.general.id} value={EDIT_TEMPLATE_TABS.general.value} />
					{type !== TEMPLATE_TYPE.teamChat && <Tab id={EDIT_TEMPLATE_TABS.email.id} value={EDIT_TEMPLATE_TABS.email.value} />}
					<Tab id={EDIT_TEMPLATE_TABS.backup.id} value={EDIT_TEMPLATE_TABS.backup.value} />
				</Tabs>

				<div className="edit-templates__body">
					{EDIT_TEMPLATE_TABS.general.id === selectedTab && this.renderMainForm()}
					{EDIT_TEMPLATE_TABS.email.id === selectedTab && this.renderEmailForm()}
					{EDIT_TEMPLATE_TABS.backup.id === selectedTab && this.renderBackupForm()}
				</div>

				<div className="modal__actions">
					<div className={`mb-button ${this.isSaveable() ? "" : "mb-button--disabled"}`} onClick={() => this.handleOnSubmit()}>
						{t("Save")}
					</div>
				</div>
			</div>
		);
	};

	render() {
		let { show, onHide } = this.props;
		const { loading } = this.state;

		return (
			<Modal show={show} onHide={onHide} title={this.getTitle()}>
				{loading && (
					<div className="Common__spinnerchild--center">
						<Spinners type="tail-fade" color="" size="110px" loading={true} />
					</div>
				)}
				{!loading && this.renderForm()}
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(EditTemplateModal);

import React from "react";
import { withTranslation } from "react-i18next";
import { getServices } from "service-fetch";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import UtilityService from "../../../services/UtilityService";

const { WebhooksService } = getServices();

const INPUT_LIMITS = {
	nameMax: 255
};

const WEBHOOK_CONFIG = {
	headers: {
		Authorization: ""
	}
};

class WebhookManageModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			longUrl: "",
			webhookConfig: JSON.stringify(WEBHOOK_CONFIG),
			lastRan: "",

			saving: false,
			edited: false
		};
	}

	componentDidUpdate = prevProps => {
		let { show } = this.props;

		if (prevProps.show !== show && show) {
			this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onHide = (resetComponent = false) => {
		if (this.props.onHide) {
			this.props.onHide(resetComponent);
		}
	};

	resetComponent = async () => {
		this.fetchData();
	};

	fetchData = async () => {
		await this.update({ loading: true });

		let { id } = this.props;

		await this.update({
			name: "",
			longUrl: "",
			webhookConfig: JSON.stringify(WEBHOOK_CONFIG),
			lastRan: ""
		});

		if (!this.props.id) {
			return;
		}

		let locationId = await UserService.getActiveLocation().id;

		let { data } = await WebhooksService.fetchWebhook({
			params: {
				locationId,
				webhookId: id
			}
		});

		if (!data) {
			ToastService.error("Error fetching the data. Please try again.");
			return;
		}

		await this.update({
			name: data.name,
			longUrl: data.long_url,
			webhookConfig: data.webhook_config ? JSON.stringify(data.webhook_config) : "",
			lastRan: data.last_ran_at,
			loading: false
		});
	};

	onSave = async () => {
		let { id } = this.props;
		let { name, longUrl, webhookConfig } = this.state;
		let locationId = await UserService.getActiveLocation().id;

		if (!this.isValid()) {
			return;
		}

		await this.update({ saving: true });

		let response = null;

		if (this.isCreateMode()) {
			response = await WebhooksService.create({
				params: {
					locationId
				},
				body: {
					name,
					longUrl,
					webhookConfig: JSON.parse(webhookConfig)
				}
			});
		} else {
			response = await WebhooksService.update({
				params: {
					locationId,
					webhookId: id
				},
				body: {
					name,
					longUrl,
					webhookConfig: JSON.parse(webhookConfig)
				}
			});
		}

		if (!response) {
			ToastService.error(t("Error saving webhook. Please try again."));
			await this.update({ saving: false });
			return;
		}

		this.onHide(response);
		await this.update({ saving: false });
	};

	isValid = () => {
		const { name } = this.state;

		if (!name || name.length > INPUT_LIMITS.nameMax) {
			return false;
		}

		if (!this.isLongUrlValidUrl()) {
			return false;
		}

		if (!this.isWebhookConfigValid()) {
			return false;
		}

		return true;
	};

	isCreateMode = () => {
		const { id } = this.props;
		return !id;
	};

	isLongUrlValidUrl = () => {
		const { longUrl } = this.state;
		return UtilityService.isValidHttpsUrl(longUrl);
	};

	isWebhookConfigValid = () => {
		let { webhookConfig } = this.state;
		if (webhookConfig === "") {
			return true;
		}

		try {
			JSON.parse(webhookConfig);
		} catch (error) {
			return false;
		}
		return true;
	};

	handleGenericEventHandler = (event, name) => {
		if (!name) {
			name = event.target.name;
		}
		let value = event.target ? event.target.value : event;
		this.update({ [name]: value, edited: true });
	};

	render() {
		const { show, id } = this.props;
		let { name, longUrl, webhookConfig, saving } = this.state;

		return (
			<Modal show={show} onHide={this.onHide} title={id ? "Update Webhook" : "Create Webhook"}>
				<div style={{ width: "100%", maxWidth: "500px" }}>
					<Input
						id="name"
						name="name"
						label={"Name"}
						type="text"
						onChange={e => {
							this.handleGenericEventHandler(e);
						}}
						value={name}
						maxLength={INPUT_LIMITS.nameMax}
					/>
					<Input
						id="longUrl"
						name="longUrl"
						label={"Long Url"}
						type="text"
						onChange={e => {
							this.handleGenericEventHandler(e);
						}}
						value={longUrl}
						invalid={!this.isLongUrlValidUrl()}
					/>

					<Input
						id="webhookConfig"
						name="webhookConfig"
						label={"Webhook Config"}
						type="textarea"
						placeholder="{...}"
						onChange={e => {
							this.handleGenericEventHandler(e);
						}}
						value={webhookConfig}
						invalid={!this.isWebhookConfigValid()}
					/>

					<div className="modal__actions">
						<div className={`mb-button mb-button--fit ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onSave}>
							{saving ? "Saving" : "Save"}
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(WebhookManageModal);

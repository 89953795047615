import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { CsvService } from "../../services/CsvService";
import { AnalyticsService } from "../../services/AnalyticsService";

import Spinners from "../../components/common/Spinners";
import Alert from "../../components/common/Alert";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import Action from "../../components/common/Action";
import withLocation from "../../components/common/WithLocation";

import { AnalyticsConstants } from "../../constants/AnalyticsConstants";

import "../../App.css";
import "react-day-picker/lib/style.css";
import "../../styles/css/scenes/analytics.css";

class WidgetAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			loading: false,
			loadingError: false,
			start: moment()
				.subtract(30, "days")
				.toDate(),
			end: moment().toDate(),
			csvData: null,

			chartTitle: "Webchat Statistics",
			selectedTab: this.props.selectedTab ? this.props.selectedTab : AnalyticsConstants.APP_NAME.webchat
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	onLocationChanged = async () => {
		this.fetchData();
	};

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	handleUpdateDate() {
		this.fetchData();
	}

	fetchData = async () => {
		try {
			this.setState({ loading: true });

			const { start, end, selectedTab } = this.state;
			let locationId = UserService.getActiveLocation().id;

			let data = await AnalyticsService.getWidgetAnalytics(locationId, selectedTab, start, end);

			if (!data) {
				await this.update({
					loading: false,
					loadingError: true
				});
				return;
			}

			await this.update({
				data: data
			});
			this.updateChart();
		} catch (error) {
			console.log(error);
		}
		this.setState({ loading: false });
	};

	async updateChart() {
		await this.update({ loading: false });
		const xAxis = this.state.data.map(anInbound => anInbound.date);
		xAxis.unshift("x");

		let dateColumns = [...xAxis];
		dateColumns[0] = "Date";

		let y1 = [];
		let y2 = [];

		const { selectedTab } = this.state;

		if (selectedTab === AnalyticsConstants.APP_NAME.webchat) {
			y1 = this.state.data.map(anOpened => anOpened.webchat_loaded);
			y1.unshift("Loaded");

			y2 = this.state.data.map(anOpened => anOpened.webchat_opened);
			y2.unshift("Opened");
		} else if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			y1 = this.state.data.map(anOpened => anOpened.reviews_widget_loaded);
			y1.unshift("Loaded");

			y2 = this.state.data.map(anOpened => anOpened.reviews_widget_opened);
			y2.unshift("Opened");
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			y1 = this.state.data.map(anOpened => anOpened.booking_widget_opened);
			y1.unshift("Opened");

			y2 = this.state.data.map(anOpened => anOpened.booking_widget_submitted);
			y2.unshift("Submitted");
		}

		let csvData = [dateColumns, y1, y2];
		csvData = csvData[0].map((col, i) => csvData.map(row => row[i])); // transpose the data

		await this.update({
			data: [xAxis, y1, y2],
			csvData: csvData
		});

		this.generateChart(`#${this.chartId()}`, this.state.data);
	}

	generateChart(id, columns) {
		try {
			const { start, end } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;
			c3.generate({
				bindto: id,
				data: {
					x: "x",
					columns: columns,
					type: "line"
				},
				axis: {
					x: {
						label: "Day",
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								//return monthString[x.getMonth()];
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
							//format: '%Y' // format string is also available for timeseries data
						}
					},
					y: {
						label: "Counts"
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	onTabSelect = async tab => {
		await this.update({ selectedTab: tab.id });
		const { selectedTab } = this.state;
		if (selectedTab === AnalyticsConstants.APP_NAME.webchat) {
			this.update({ chartTitle: "Webchat Statistics" });
		} else if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			this.update({ chartTitle: "Reviews Widget Statistics" });
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			this.update({ chartTitle: "Booking Widget Statistics" });
		}

		this.fetchData();
	};

	chartId = () => {
		let chartId = `widget-stats-chart`;
		const { selectedTab } = this.state;

		if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			chartId = `reviews-widget-stats-chart`;
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			chartId = `booking-widget-stats-chart`;
		}
		return chartId;
	};

	renderDownloadAction = () => {
		const { selectedTab, csvData } = this.state;
		const locationName = CsvService.filterActiveLocationName();

		let fileName = `${locationName}-webchat_stats.csv`;

		if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			fileName = `${locationName}-reviews_widget_stats.csv`;
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			fileName = `${locationName}-booking_widget_stats.csv`;
		}

		if (!csvData) {
			return null;
		}

		return (
			<CSVLink data={csvData} filename={fileName} target="_self">
				<Action label="Download" icon={Icon.Download} className="Common__csv-link">
					<Icon.Download />
				</Action>
			</CSVLink>
		);
	};

	renderBody = () => {
		const { loading, start, end } = this.state;
		const { isCard, isEnabled, isPermissible } = this.props;

		const modifiers = { start, end };

		if (isEnabled && !isEnabled()) {
			return (
				<>
					<br />
					<div>Messenger is not enabled. Contact support to get started.</div>
				</>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<>
					<br />
					<div>Messenger and widget data is restricted.</div>
				</>
			);
		}

		return (
			<>
				<div className={`form-group ReviewSitesAnalytics__form-datepicker ${isCard ? "dh-page__datepicker--card" : ""}`}>
					<label>Date Range&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder=" From"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder=" To"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						Update Date
					</button>
				</div>

				{loading && (
					<div className="Common__spinnerdiv--center">
						<Spinners type="tail-fade" loading={true} size="120px" />
					</div>
				)}

				{!loading && (
					<div className="Common__chart-container" style={{ flexGrow: 1 }}>
						<div id={this.chartId()} />
					</div>
				)}
			</>
		);
	};

	render() {
		const { selectedTab } = this.state;
		let { isCard, cardName } = this.props;

		if (isCard) {
			return (
				<>
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{cardName}</div>
						<div className="dashboard__card__header__actions">{this.renderDownloadAction()}</div>
					</div>
					<div>{this.renderBody()}</div>
				</>
			);
		}

		return (
			<Page>
				<Header title="Widget Analytics">{this.renderDownloadAction()}</Header>

				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={AnalyticsConstants.APP_NAME.webchat} value={"Webchat"} />
					<Tab id={AnalyticsConstants.APP_NAME.reviewsWidget} value={"Reviews Widget"} />
					<Tab id={AnalyticsConstants.APP_NAME.bookingWidget} value={"Booking Widget"} />
				</Tabs>

				{this.renderBody()}

				<Alert
					type="error"
					show={this.state.loadingError}
					title="Sorry, something went wrong. "
					confirm="OK"
					onClose={() => {
						this.setState({ loadingError: false });
					}}
				>
					<div>Sorry, an error occured trying to fetch the data.</div>
				</Alert>
			</Page>
		);
	}
}

export default withRouter(withLocation(WidgetAnalytics));

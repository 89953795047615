import React from "react";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import * as Icon from "react-feather";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";

import ToastService from "../../../services/ToastService";
import GbmService from "../../../services/GbmService";
import UserService from "../../../services/UserService";
import UtilityService from "../../../services/UtilityService";

import config from "../../../config/app/web-app.config";
import "../../../styles/css/scenes/google-business-messaging.css";

// Our google button with the business.mange scope
const GoogleButton = ({ onSuccess, onError, googleAuthCode }) => {
	let login = useGoogleLogin({
		onSuccess: onSuccess,
		onError: onError,
		flow: "auth-code",
		scope:
			"https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/businesscommunications https://www.googleapis.com/auth/userinfo.email"
	});

	return (
		<div className="mb-button gbmvm__authenticate-button" onClick={() => login()}>
			{googleAuthCode ? (
				<span>
					Authenticated <Icon.Check size={16} className="gbmvm__authenticate-button__icon" />
				</span>
			) : (
				"Authenticate Via Google"
			)}
		</div>
	);
};

class VerifyModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			googleAuthCode: null,
			brandContactName: "",
			brandContactEmail: "",
			brandUrl: ""
		};
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.show !== this.props.show &&
			this.props.integration &&
			this.props.integration.properties &&
			this.props.integration.properties.agentVerification &&
			this.props.integration.properties.agentVerification.agentVerificationContact
		) {
			let contactInfo = this.props.integration.properties.agentVerification.agentVerificationContact;
			this.update({
				brandContactName: contactInfo.brandContactName,
				brandContactEmail: contactInfo.brandContactEmailAddress,
				brandUrl: contactInfo.brandWebsiteUrl
			});
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onHide = (refresh = false) => {
		let { onHide } = this.props;

		if (onHide) {
			onHide(refresh);
		}
	};

	onGooglFailure = response => {
		ToastService.error("Google Sign In Failed.");
		console.log(`Google Sign In Failed.`);
		console.log(response);
	};

	onGoogleSignIn = async response => {
		try {
			await this.update({ googleAuthCode: response.code });
		} catch (error) {
			console.log(error);

			ToastService.error("Error trying to sign in. Please try again.");
		}
	};

	isValid = () => {
		let { googleAuthCode, brandContactName, brandContactEmail, brandUrl, loading } = this.state;

		if (loading) {
			return false;
		}

		if (!brandContactName || !brandContactName.trim()) {
			return false;
		}

		if (!brandContactEmail || !brandContactEmail.trim()) {
			return false;
		}

		if (!brandUrl || !brandUrl.trim() || !UtilityService.isValidHttpsUrl(brandUrl)) {
			return false;
		}

		if (!googleAuthCode || !googleAuthCode.trim()) {
			return false;
		}

		return true;
	};

	onVerify = async () => {
		if (!this.isValid()) {
			return;
		}

		await this.update({
			loading: true
		});

		let { googleAuthCode, brandContactName, brandContactEmail, brandUrl } = this.state;
		let resonse = await GbmService.verifyAgent({
			locationId: UserService.getActiveLocation().id,
			googleAuthCode,
			brandContactName,
			brandContactEmail,
			brandUrl
		});

		if (!resonse) {
			await this.update({
				loading: false
			});
			ToastService.error("Failed to verify. Please try again. Ensure you have access to the GMB Location.");
			this.onHide(true);
			return;
		}

		ToastService.info("Agent and Location Verified!");

		this.onHide(true);
		await this.update({
			loading: false
		});
	};

	handleGenericEventHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	renderInputs = () => {
		let { brandContactName, brandContactEmail, brandUrl } = this.state;

		return (
			<div className="gbmvm__inputs">
				<p>
					Enter the Contact name and email for the brand the agent represents who can verify DemandHub's relationship with your brand. Ensure that you have
					access through your Google account to the associated GMB location.
				</p>

				<Input
					name="brandContactName"
					id="brandContactName"
					type="text"
					placeholder="Name..."
					label="Brand Contact Name"
					onChange={this.handleGenericEventHandler}
					value={brandContactName}
					required={true}
				/>

				<Input
					name="brandContactEmail"
					id="brandContactEmail"
					type="text"
					placeholder="Email..."
					label="Brand Contact Email"
					onChange={this.handleGenericEventHandler}
					value={brandContactEmail}
					required={true}
				/>

				<Input
					name="brandUrl"
					id="brandUrl"
					type="text"
					placeholder="Url..."
					label="Brand Https Url"
					description="Website of the brand the agent represents, as a publicly available URL starting with 'https://'"
					onChange={this.handleGenericEventHandler}
					value={brandUrl}
					required={true}
					invalid={brandUrl && brandUrl.length > 7 && !UtilityService.isValidHttpsUrl(brandUrl)}
				/>
			</div>
		);
	};

	render = () => {
		const { show } = this.props;
		let { googleAuthCode } = this.state;

		return (
			<Modal show={show} title="Verify Agent and Location" onHide={() => this.onHide()}>
				<>
					{this.renderInputs()}

					<GoogleOAuthProvider
						clientId={`${config.GOOGLE.GMB_API_CLIENT.CLIENT_ID}`}
						onScriptLoadError={error => {
							console.log(error);
							this.setState({ showGoogleSignIn: false });
						}}
					>
						<GoogleButton onSuccess={this.onGoogleSignIn} onFailure={this.onGooglFailure} googleAuthCode={googleAuthCode} />
					</GoogleOAuthProvider>

					<div className="modal__actions" onClick={this.onVerify}>
						<div className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`}>Verify Agent and Location</div>
					</div>
				</>
			</Modal>
		);
	};
}

export default VerifyModal;

import React, { Component } from "react";
import * as Icon from "react-feather";

import Media from "./Media";

import { MEDIA_TYPES } from "../../../../constants/Messenger";

import "./thread-media.css";

class ThreadMedia extends Component {
	constructor(props) {
		super(props);

		this.threadMedia = null;
	}

	onMediaClicked = url => {
		if (this.props.onMediaClicked) {
			this.props.onMediaClicked(url);
		}
	};

	renderImage = () => {
		let { media, maxHeightOrWidth } = this.props;

		let id = media.id;

		return (
			<div className="thread-media">
				<div className="thread-media__image">
					<Media key={id} media={media} maxHeightOrWidth={maxHeightOrWidth} onMediaClicked={url => this.onMediaClicked(url)} />
				</div>
			</div>
		);
	};

	renderVideo = () => {
		let { media, maxHeightOrWidth } = this.props;

		let id = media.id;

		return (
			<div className="thread-media">
				<div className="thread-media__video">
					<Media key={id} media={media} maxHeightOrWidth={maxHeightOrWidth} />
				</div>
			</div>
		);
	};

	renderFile = () => {
		let { media, maxHeightOrWidth, readOnly } = this.props;

		let id = media.id;
		let filename = media.file_name;

		return (
			<div className="thread-media">
				<div className="thread-media__file" style={{ maxWidth: maxHeightOrWidth ? `${maxHeightOrWidth}px` : null }}>
					<div className="thread-media__file__container">
						<Media key={id} media={media} readOnly={readOnly} />
					</div>
					<div className="thread-media__file__filename">{filename}</div>
				</div>
			</div>
		);
	};

	renderAudio = () => {
		let { media, maxHeightOrWidth, idPrefix, readOnly } = this.props;

		let id = media.id;
		let filename = media.file_name;

		return (
			<div className="thread-media">
				<div className="thread-media__audio" style={{ maxWidth: maxHeightOrWidth ? `${maxHeightOrWidth}px` : null }}>
					<Media ref={ref => (this.threadMedia = ref)} key={id} media={media} idPrefix={`${idPrefix || "thread"}`} readOnly={readOnly} />
					<div className="thread-media__audio__filename">
						<div className="thread-media__audio__filename__text">{filename}</div>
						{!readOnly && (
							<div className="thread-media__audio__filename__download">
								<Icon.Download size={18} onClick={() => (this.threadMedia ? this.threadMedia.startDownload() : null)} />
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	render = () => {
		let { media } = this.props;

		if (media.type === MEDIA_TYPES.image) {
			return this.renderImage();
		} else if (media.type === MEDIA_TYPES.video) {
			return this.renderVideo();
		} else if (media.type === MEDIA_TYPES.file) {
			return this.renderFile();
		} else if (media.type === MEDIA_TYPES.audio) {
			return this.renderAudio();
		} else {
			return null;
		}
	};
}

export default ThreadMedia;

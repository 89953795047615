import React, { Component } from "react";

import GAService from "../../services/GAService";
import SupportChatService from "../../services/SupportChatService";

import { APP_STOPPAGE_REASONS } from "../../constants/Errors";
import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";

import "./Maintenance.css";

const MAINTENANCE_IMG_URL = "https://cdn.demandhub.co/web-app/assets/maintenance.svg";
const DEGRADED_IMG_URL = "https://cdn.demandhub.co/web-app/assets/degraded.svg";
class Maintenance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			refreshTimer: null
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	// Opens intercom, duh!
	openIntercom = () => {
		SupportChatService.init();
		SupportChatService.showNewMessage();

		GAService.GAEvent({
			category: GA_CATEGORIES.maintenancePage.name,
			action: GA_ACTIONS.generic.intercomOpen,
			label: GA_ACTIONS.generic.intercomOpen
		});
	};

	render() {
		let { stoppageReason } = this.props;

		let imgUrl = stoppageReason === APP_STOPPAGE_REASONS.degraded ? DEGRADED_IMG_URL : MAINTENANCE_IMG_URL;

		return (
			<div className="maintenance">
				<img alt="Maintenance" className="maintenance__img" src={imgUrl} />
				<div className="maintenance__title">Undergoing Maintenance</div>
				<div className="maintenance__subtext">
					DemandHub is currently undergoing maintenance. We will be back shortly. Please contact support for any urgent requests: support@demandhub.co
				</div>
				<div className="maintenance__open-intercom">
					<div className="mb-button" onClick={this.openIntercom}>
						Chat With Support
					</div>
				</div>
			</div>
		);
	}
}

export default Maintenance;

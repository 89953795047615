import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import c3 from "c3";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import { CsvService } from "../../services/CsvService";
import { AnalyticsService } from "../../services/AnalyticsService";

import Spinners from "../../components/common/Spinners";
import withLocation from "../../components/common/WithLocation";
import Action from "../../components/common/Action";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";
import { CHART_COLORS } from "../../constants/CommonConstants";

import "../../App.css";
import "../../styles/css/scenes/analytics.css";
import "react-day-picker/lib/style.css";

class ConversionsBarChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			conversions: [],
			conversionsCsvData: null,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate()
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	onLocationChanged = location => {
		this.resetComponent();
	};

	resetComponent = async () => {
		await this.fetchData();
		this.updateCharts();
	};

	fetchData = async () => {
		await this.update({ loading: true });

		let { start, end } = this.state;

		let result = await AnalyticsService.getConversionsAnalytics({ locationId: UserService.getActiveLocation().id, start, end });

		if (!result) {
			ToastService.error("Error getting converstion data. Please try again.");
			return;
		}

		let csvData = [];
		let conversions = result.conversions;
		let reengagements = result.reengagements;

		for (let i = 0; i < conversions.length; i++) {
			const conversation = conversions[i];

			csvData.push({
				Date: conversation.date,
				["Widget Conversion Count"]: conversation.count,
				["Reengagement Count"]: reengagements[i].count
			});
		}

		await this.update({
			loading: false,
			conversions: conversions,
			conversionsCsvData: csvData,
			reengagements: reengagements
		});
	};

	updateCharts = () => {
		let { conversions, reengagements, start, end } = this.state;

		if (!conversions || conversions.length < 1) {
			return;
		}

		let dates = conversions.map(item => item.date);
		dates.unshift("x");

		let counts = conversions.map(item => item.count);
		counts.unshift("Widget Conversions");

		let reengagementCounts = reengagements.map(item => item.count);
		reengagementCounts.unshift("Reengagements");

		let columns = [dates, counts, reengagementCounts];

		// Generate a color pattern for other charts
		let colors = [...CHART_COLORS];

		const isMonth = moment(end).diff(moment(start), "days") >= 90;

		this.generateChart(
			"#conversion-analytics-chart",
			{
				x: "x",
				columns: columns,
				type: "bar",
				labels: true
			},
			{
				x: {
					label: "Day",
					type: "timeseries",
					tick: {
						format: function(x) {
							if (isMonth) {
								const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
								return "" + x.getFullYear() + " " + monthString[x.getMonth()];
							}
							return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
						},
						rotate: window.innerWidth > 768 ? 0 : 75,
						multiline: false
					}
				},
				y: {
					label: "Counts"
				}
			},
			{
				pattern: colors
			}
		);
	};

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			c3.generate({
				bindto: id,
				data: data,
				axis: axis,
				size: {
					height: 260
				},
				color: color,
				legend: legend
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.update({ start });
	};
	handleToChange = end => {
		this.update({ end }, this.showFromMonth);
	};
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}
	handleUpdateDate(event) {
		event.preventDefault();
		event.stopPropagation();

		this.resetComponent();
	}

	renderHeader = () => {
		const { start, end, conversionsCsvData } = this.state;
		const { cardName } = this.props;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : "Conversion Analytics"}</div>
					<div className="dashboard__card__header__actions">
						{conversionsCsvData && (
							<CSVLink
								data={conversionsCsvData}
								filename={`${locationName}-conversions-${moment(start).format("YYYY-MM-DD")}-${moment(end).format("YYYY-MM-DD")}_analytics.csv`}
								target="_self"
							>
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				<div className="dashboard__card___datepicker">
					<label>Date Range&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder=" From"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder=" To"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						Update Date
					</button>
				</div>
			</>
		);
	};

	renderBody = () => {
		const { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">Conversions is not enabled. Contact support to get started.</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">Widget data is restricted.</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div>
				<div id="conversion-analytics-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.conversionsBarChart.className} height={DASHBOARD_CARDS.conversionsBarChart.h}>
				{this.renderHeader()}

				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withLocation(ConversionsBarChart));

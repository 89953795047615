const BooleanService = {
	getStringFromBoolean
};

/**
 * Convert boolean to string. Returns "Yes" or "No"
 * @param {boolean} bool
 * @returns {String}
 */

function getStringFromBoolean(bool) {
	return bool === true ? "Yes" : "No";
}

export default BooleanService;

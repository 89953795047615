import React, { Component } from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";
import WorkflowService from "../../services/WorkflowService";
import MessagesService from "../../services/MessagesService";
import ToastService from "../../services/ToastService";

import { DAYS_OF_WEEK } from "../../constants/CommonConstants";
import { MODE, MEDIUM } from "../../constants/Messenger";

import Modal from "../../components/common/DHModal";
import Checkbox from "../../components/common/Checkbox";
import TextArea from "../../components/common/TextArea";
import AttachmentItem from "../../components/common/AttachmentItem";
import TimeDropdown from "../../components/common/TimeDropdown";

import "../../styles/css/scenes/auto-replies.css";

class ManageCustomAutoRepliesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			saving: false,
			selectedWorkflow: null,
			manageCustomHoursName: "",
			manageCustomHoursReply: "",
			manageCustomHoursDays: [],
			manageCustomHoursStartTime: moment(UtilityService.getNext15Minutes()).format("HH:mm"),
			manageCustomHoursEndTime: moment(UtilityService.getNext15Minutes()).format("HH:mm"),
			showDeleteWorkflowAlert: false,
			daysOfTheWeek: Object.keys(DAYS_OF_WEEK).map(key => DAYS_OF_WEEK[key].value),
			// Attachment related variables
			attachmentsChanged: false,
			mediaIds: []
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			if (this.props.selectedWorkflow) {
				this.editWorkflowSetup();
				return;
			}
			this.createWorkflowSetup();
		}
		return;
	}

	async createWorkflowSetup() {
		await this.update({
			selectedWorkflow: null,
			manageCustomHoursName: "",
			manageCustomHoursReply: "",
			manageCustomHoursStartTime: moment(UtilityService.getNext15Minutes()).format("HH:mm"),
			manageCustomHoursEndTime: moment(UtilityService.getNext15Minutes()).format("HH:mm"),
			manageCustomHoursDays: [],
			attachmentsChanged: false,
			mediaIds: []
		});
	}

	async editWorkflowSetup() {
		let { selectedWorkflow } = this.props;

		if (!selectedWorkflow) {
			return;
		}

		await this.update({
			loading: true,
			selectedWorkflow
		});

		let workflow = await WorkflowService.fetchWorkFlow(selectedWorkflow.id, true);
		if (!workflow) {
			await this.update({
				loading: false
			});
			return;
		}

		// The media associated to this workflow
		let mediaIds = [];

		// For now we only allow for one attachment at a time
		let media = null;

		// If this scheduled message has any media attachments
		if (workflow.MessageTemplate && workflow.MessageTemplate.Media && workflow.MessageTemplate.Media.length > 0) {
			media = workflow.MessageTemplate.Media[0];
		}

		if (media) {
			// For now, we only allow one media
			mediaIds = [workflow.MessageTemplate.Media[0].id];
		}

		// Workflow config
		let workflowConstant = await WorkflowService.getCachedWorkflowsConfig();

		let days = [];

		let conditions = workflow.conditions;
		let validConditions = [workflowConstant.conditions.isAfterHours, workflowConstant.conditions.isBusinessHours, workflowConstant.conditions.isCustomHours];
		let condition = conditions.find(c => validConditions.includes(c.type));
		if (!condition) {
			await this.update({
				loading: false
			});
			return;
		}

		days = condition.params.days;
		let startTime = condition.params.start_time;
		let endTime = condition.params.end_time;

		let templateMessageText = "";
		if (workflow.MessageTemplate && workflow.MessageTemplate.msg_text) {
			templateMessageText = workflow.MessageTemplate.msg_text;
		}

		await this.update({
			selectedWorkflow: workflow,
			manageCustomHoursName: workflow.name,
			manageCustomHoursReply: templateMessageText,
			manageCustomHoursStartTime: startTime,
			manageCustomHoursEndTime: endTime,
			manageCustomHoursDays: days,
			mediaIds
		});

		// Make the loading not as jarring
		setTimeout(async () => {
			await this.update({
				loading: false
			});
		}, 300);
	}

	saveCustomHours = async () => {
		let {
			selectedWorkflow,
			manageCustomHoursName,
			manageCustomHoursReply,
			manageCustomHoursStartTime,
			manageCustomHoursEndTime,
			manageCustomHoursDays,
			attachmentsChanged,
			mediaIds
		} = this.state;
		let { t } = this.props;

		if (!this.isSavable()) {
			return;
		}

		await this.update({
			saving: true
		});

		let locationId = UserService.getActiveLocation().id;
		let companyId = UserService.getActiveCompany().id;

		let workflowConstant = await WorkflowService.getCachedWorkflowsConfig();

		let newMediaIds = [];
		if (attachmentsChanged && mediaIds) {
			// For now, we only support 1 media
			let uploadedMedia = await MessagesService.uploadLocalMedia({
				mediaIds: mediaIds,
				mode: MODE.customer,
				medium: MEDIUM.sms.key,
				limit: 1,
				companyId,
				locationId
			});

			if (!uploadedMedia) {
				ToastService.error(t("Error uploading media."));
				await this.update({ saving: false });
				return;
			}

			newMediaIds = uploadedMedia.map(m => m.id);
			MessagesService.clearLocalMedia();

			mediaIds = newMediaIds;
		}

		let startTimes = manageCustomHoursStartTime.split(":");
		let startTime = moment().toDate();
		startTime.setHours(startTimes[0]);
		startTime.setMinutes(startTimes[1]);
		startTime = moment(startTime).format("HH:mm");

		// Special case for 24:00
		if (manageCustomHoursStartTime === "24:00") {
			startTime = manageCustomHoursStartTime;
		}

		let endTimes = manageCustomHoursEndTime.split(":");
		let endTime = moment().toDate();
		endTime.setHours(endTimes[0]);
		endTime.setMinutes(endTimes[1]);
		endTime = moment(endTime).format("HH:mm");

		// Special case for 24:00
		if (manageCustomHoursEndTime === "24:00") {
			endTime = manageCustomHoursEndTime;
		}

		if (selectedWorkflow) {
			// update
			await WorkflowService.updateAutoReply({
				workflowId: selectedWorkflow.id,
				name: manageCustomHoursName,
				response: manageCustomHoursReply,
				days: manageCustomHoursDays,
				startTime: startTime,
				endTime: endTime,
				mediaIds
			});
			ToastService.info(t("Auto Reply Updated"));
		} else {
			// create
			await WorkflowService.createCustomAutoReply({
				locationId: UserService.getActiveLocation().id,
				type: workflowConstant.conditions.isCustomHours,
				name: manageCustomHoursName,
				response: manageCustomHoursReply,
				days: manageCustomHoursDays,
				startTime: startTime,
				endTime: endTime,
				mediaIds
			});
			ToastService.info(t("Auto Reply Created"));
		}

		await this.update({
			attachmentsChanged: false,
			mediaIds,
			saving: false
		});

		if (this.props.onSave) {
			this.props.onSave();
		}
	};

	async manageDays(day) {
		let { manageCustomHoursDays } = this.state;
		if (manageCustomHoursDays.includes(day)) {
			const index = manageCustomHoursDays.indexOf(day);
			if (index > -1) {
				manageCustomHoursDays.splice(index, 1);
			}
		} else {
			manageCustomHoursDays.push(day);
		}
		await this.update({ manageCustomHoursDays });
	}

	onTimeChange = async (state, event) => {
		let value = event.target.value;

		this.update({ [state]: value });
	};

	onFileAdded = async files => {
		let file = files[0];
		let localMediaId = MessagesService.storeLocalMedia({ file }).id;
		let { mediaIds } = this.state;

		// For now, we only support one media attachment
		mediaIds = [];
		mediaIds.push(localMediaId);

		await this.update({
			mediaIds,
			attachmentsChanged: true
		});
	};

	onHideAttachment = async () => {
		await this.update({
			attachmentsChanged: true,
			mediaIds: []
		});
	};

	isSavable = () => {
		return !this.state.saving;
	};

	renderModalContent = () => {
		let {
			manageCustomHoursName,
			manageCustomHoursReply,
			manageCustomHoursStartTime,
			manageCustomHoursEndTime,
			manageCustomHoursDays,
			daysOfTheWeek,
			mediaIds,
			saving
		} = this.state;
		let { t } = this.props;

		return (
			<div className="modal__flex-container">
				<div className="modal__field">{t("Name")}</div>
				<div className="auto-replies__custom-hours-modal__input">
					<TextArea
						id="manage-custom-hours-reply-name"
						type="text"
						height={50}
						rows={1}
						style={{ resize: "none" }}
						value={manageCustomHoursName}
						blueBorder={true}
						placeholder={t("Name of Auto Reply")}
						onChange={event => this.setState({ manageCustomHoursName: event.target.value })}
						showMessageTemplates={false}
					/>
				</div>
				<div className="modal__field">{t("Message")}</div>
				<div className="auto-replies__custom-hours-modal__input">
					<TextArea
						id="manage-custom-hours-reply-text"
						type="text"
						height={130}
						rows={4}
						style={{ resize: "none" }}
						value={manageCustomHoursReply}
						showFeedbackFaces={true}
						showFeedbackLength={true}
						showVariables={true}
						blueBorder={true}
						placeholder={t("Type a message")}
						onChange={event => this.setState({ manageCustomHoursReply: event.target.value })}
						attachments={true}
						onFileAdded={this.onFileAdded}
					/>
				</div>

				{mediaIds && mediaIds.length > 0 && (
					<div className="">
						<div className="modal__field">{t("Attachment")}</div>
						{mediaIds.map(mediaId => (
							<AttachmentItem key={mediaId} mediaId={mediaId} hide={this.onHideAttachment} />
						))}
					</div>
				)}

				<div className="modal__field">{t("Days")}</div>
				<div className="auto-replies__custom-hours-modal__days">
					{daysOfTheWeek.map(day => (
						<div key={day} className="auto-replies__custom-hours-modal__day">
							<Checkbox
								id={day}
								name={day}
								checked={manageCustomHoursDays && manageCustomHoursDays.length > 0 && manageCustomHoursDays.includes(day)}
								onChange={() => this.manageDays(day)}
							/>
							<div className="auto-replies__custom-hours-modal__day__text">{day.substring(0, 3)}</div>
						</div>
					))}
				</div>
				<div className="auto-replies__custom-hours-modal__times">
					<div className="auto-replies__custom-hours-modal__times__time">
						<div className="modal__field">{t("Start Time")}</div>
						<TimeDropdown
							id={"start-time"}
							checked={false}
							isDisabled={false}
							handleChange={event => this.onTimeChange("manageCustomHoursStartTime", event)}
							value={manageCustomHoursStartTime}
							allow24thHour={true}
							timeInterval={15}
						/>
					</div>
					<div className="auto-replies__custom-hours-modal__times__to">{t("to")}</div>
					<div className="auto-replies__custom-hours-modal__times__time">
						<div className="modal__field">{t("End Time")}</div>
						<TimeDropdown
							id={"end-time"}
							checked={false}
							isDisabled={false}
							handleChange={event => this.onTimeChange("manageCustomHoursEndTime", event)}
							value={manageCustomHoursEndTime}
							allow24thHour={true}
							timeInterval={15}
						/>
					</div>
				</div>
				<div className="auto-replies__custom-hours-modal__actions">
					<div className={`mb-button ${this.isSavable() ? "" : "mb-button--disabled"}`} onClick={() => this.saveCustomHours()}>
						{saving ? t("Saving...") : t("Save")}
					</div>
				</div>
			</div>
		);
	};

	render() {
		let { show, onHide, t } = this.props;

		let { selectedWorkflow, loading } = this.state;

		return (
			<Modal show={show} onHide={onHide} title={selectedWorkflow ? selectedWorkflow.name : t("New Auto Reply")}>
				{!loading && this.renderModalContent()}
				{loading && (
					<div style={{ width: "100%" }}>
						<ContentLoader height={470} width={"100%"}>
							{/* First row */}
							<rect x="0" y="20" rx="5" ry="5" width="470" height="70" />
							{/* Second row */}
							<rect x="0" y="110" rx="5" ry="5" width="470" height="130" />
							{/* Third row */}
							<rect x="0" y="260" rx="5" ry="5" width="470" height="60" />
							{/* Fourth row */}
							<rect x="0" y="340" rx="5" ry="5" width="470" height="60" />
							{/* Fifth row */}
							<rect x="390" y="420" rx="5" ry="5" width="80" height="40" />
						</ContentLoader>
					</div>
				)}
			</Modal>
		);
	}
}
export default withTranslation(null, { withRef: true })(ManageCustomAutoRepliesModal);

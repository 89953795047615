import UserService from "./UserService";
import Kichiri from "./KichiriService";
import numeral from "numeral";
import AppConfig from "../config/app/web-app.config.js";

import { COUNTRY_CODE, CURRENCY, PAYMENT_TYPES, PAYMENT_STATUS, INVOICE_STATUS } from "../constants/Payments";

const PaymentService = {
	/**
	 * Create a payment request for a contact id, and also creates an invoice if an existing one is not specified
	 *
	 * @param {Number} contactId - the contact that should receive the payment request
	 * @param {Number} invoiceId - invoice id if one already exists, or null to create a new one
	 * @param {Array} lineItems - the line items for the invoice if creating an invoice
	 * @param {String} name - the name of the invoice if creating a new invoice
	 * @param {String} notes - the notes for the invoice ic creating a new one
	 * @param {Boolean} hasTips - whether to show tips on the payment request page
	 * @param {Boolean} chargeLater - whether we just want to capture a card
	 * @param {String} type - the payment type
	 * @param {String} chargeDate - the charge date
	 *
	 * @return {Promise}
	 */
	async createPaymentRequest({
		contactId,
		invoiceId = null,
		lineItems = [],
		name = "",
		notes = "",
		isEstimate = false,
		hasTips = false,
		chargeLater = false,
		type = PAYMENT_TYPES.general.id,
		chargeDate
	}) {
		let user = UserService.get();
		let locationId = UserService.getActiveLocation().id;
		let userId = user.id;
		let templateId = 0;

		try {
			let response = await Kichiri.payment.createPaymentRequest(
				{
					locationId,
					invoiceId,
					contactId,
					userId,
					templateId,
					name,
					notes,
					lineItems,
					isEstimate,
					hasTips,
					chargeLater,
					type,
					chargeDate
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Create an invoice for a contact id
	 *
	 * @param {Number} contactId - the contact id that the invoice is assigned to
	 * @param {Array} lineItems - an array of line item objects
	 * @param {String} name - the name of the invoice
	 * @return {Promise}
	 */
	async createInvoice({ contactId, lineItems, name = "" }) {
		let user = UserService.get();
		let locationId = UserService.getActiveLocation().id;
		let userId = user.id;
		let templateId = 0;

		try {
			let response = await Kichiri.payment.createInvoice(
				{
					locationId,
					contactId,
					userId,
					templateId,
					name,
					lineItems
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Confirms Stripe account for a specific location
	 *
	 * @param {Number} locationId
	 * @param {String} code
	 * @returns {Promise}
	 */
	async confirmStripeAccount(locationId, code) {
		let user = UserService.get();

		try {
			let response = await Kichiri.payment.confirmAccount(
				{
					locationId,
					code
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 *	Fetches a single PaymentRequest object from the backend
	 *
	 * @param {Number} paymentRequestId
	 * @returns {Promise}
	 */
	async fetchPaymentRequest(paymentRequestId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.payment.fetchPaymentRequestId(
				{
					paymentRequestId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches a single invoice object from the backend
	 *
	 * @param {Number} invoiceId
	 * @returns {Promise}
	 */
	async fetchInvoice(invoiceId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.payment.fetchInvoiceId(
				{
					invoiceId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Generates a list of line items in a specific order for display purposes
	 *
	 * @param {Object} invoice
	 * @returns
	 */
	generateDisplayLineItems(invoice) {
		let lineItems = invoice.line_items.slice();

		let displayLineItems = [];

		let subtotal = 0,
			tipAmount = 0,
			taxAmount = 0,
			amountDue = 0;

		for (let li of lineItems) {
			if (li.type === "general") {
				subtotal += li.amount;
				taxAmount += li.amount * li.tax_rate;
				displayLineItems.push(li);
			}
		}

		taxAmount = parseInt(taxAmount);
		amountDue = subtotal + taxAmount;

		// Then push the subtotal line item
		displayLineItems.push({
			name: "Subtotal",
			quantity: "",
			amount: subtotal
		});

		if (taxAmount > 0) {
			// Then push any taxes
			displayLineItems.push({
				name: "Tax",
				amount: taxAmount
			});
		}

		// Deduct any partially paid amount
		if (invoice.customer_paid_amount > 0) {
			amountDue -= invoice.customer_paid_amount;
			displayLineItems.push({
				name: "Previously Paid",
				quantity: "",
				amount: -invoice.customer_paid_amount
			});
		}

		// Deduct any partially paid insurance amount
		if (invoice.insurance_amount > 0) {
			amountDue -= invoice.insurance_amount;
			displayLineItems.push({
				name: "Insurance Covered",
				quantity: "",
				amount: -invoice.insurance_amount
			});
		}

		// Show any tip
		tipAmount = invoice.tip_amount;

		if (tipAmount > 0) {
			displayLineItems.push({
				name: "Tip",
				amount: tipAmount
			});
		}

		displayLineItems.push({
			name: "Amount Due",
			amount: amountDue
		});

		return displayLineItems;
	},

	/**
	 * Fetches a list of Payment Requests for a location
	 *
	 * @param {Number} locationId - the location id
	 * @param {String} status - a status filter for payment requests
	 * @param {String} searchTerm - a search filter
	 * @param {String} sortField - the field to sort by
	 * @param {String} sortOrder - the sort order
	 * @param {Number} limit - a limit on the returned results
	 * @returns {Promise} Returns an array of fetches payment requests
	 */
	async fetchPaymentRequests(params) {
		let user = UserService.get();

		let query = {
			locationId: 0,
			status: Object.keys(PAYMENT_STATUS)[0],
			searchTerm: "",
			sortField: "",
			sortOrder: "",
			limit: 30,
			offset: 0
		};

		query = Object.assign(query, params);

		try {
			let response = await Kichiri.payment.fetchPaymentRequests({}, query, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetches a list of Invoices for a location
	 *
	 * @param {Number} locationId - the location id
	 * @param {String} status - a status filter for invoices invoice
	 * @param {String} searchTerm - a search filter
	 * @param {String} sortField - the field to sort by
	 * @param {String} sortOrder - the sort order
	 * @param {Number} limit - a limit on the returned results
	 * @returns {Promise} Returns an array of fetched invoices
	 */
	async fetchInvoices(params) {
		let user = UserService.get();

		let query = {
			locationId: 0,
			status: Object.keys(INVOICE_STATUS)[0],
			searchTerm: "",
			sortField: "",
			sortOrder: "",
			limit: 30,
			offset: 0
		};

		query = Object.assign(query, params);

		try {
			let response = await Kichiri.payment.fetchInvoices({}, query, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Cancel a payment request
	 *
	 * @param {Number} paymentRequestId
	 * @returns {Promise}
	 */
	async cancelPayment(paymentRequestId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.payment.cancelPaymentRequest(
				{
					paymentRequestId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Process a refund for a payment that has already been collected.
	 *
	 * @param {Number} paymentRequestId
	 * @returns {Promise}
	 */
	async processRefund(paymentRequestId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.payment.refundPaymentRequest(
				{
					paymentRequestId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Charges a customer if that payment request is in a confirmed state
	 *
	 * @param {Number} paymentRequestId
	 */
	async chargeCustomer(paymentRequestId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.payment.chargePayment(
				{
					paymentRequestId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Determines if Payments is enabled for the user and the location
	 *
	 * @returns {Boolean}
	 */
	isPaymentsEnabled() {
		let location = UserService.getActiveLocation();
		return location.LocationFeature && location.LocationFeature.payments;
	},

	/**
	 * Formats a backend charge number
	 *
	 * @param {Float} charge
	 * @returns {String}
	 */
	formatCharge(charge) {
		return numeral(charge / 100).format("$ 0,0[.]00");
	},

	/**
	 * Creates a download link for an Invoice attached to a Payment Reqeust
	 *
	 * @param {Number} paymentRequestId
	 * @returns {String}
	 */
	getInvoiceDownloadLink(paymentRequestId, prPublicId) {
		return `${AppConfig.API_SERVER}/api/payment/request/${paymentRequestId}/invoice/download?paymentRequestPublicId=${prPublicId}`;
	},

	/**
	 * Retrieves the appropriate currency code for the active location
	 * @returns {String}
	 */
	getCurrency() {
		let location = UserService.getActiveLocation();
		return CURRENCY[COUNTRY_CODE[location.country]];
	}
};

export default PaymentService;

import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as Icon from "react-feather";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import GAService from "../../services/GAService";
import { CsvService } from "../../services/CsvService";
import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";

import withLocation from "../../components/common/WithLocation";
import Action from "../../components/common/Action";
import Spinners from "../../components/common/Spinners";
import DashboardCard from "../Dashboard/DashboardCard";

import { CHART_COLORS } from "../../constants/CommonConstants";
import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ScheduledMessageAnalytics extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			csvData: [],

			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			chart: null
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	resetComponent = async () => {
		await this.fetchData();
		this.updateCharts();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	fetchData = async () => {
		let { chart } = this.state;

		if (chart && chart.flush) {
			chart.destroy();
		}

		await this.update({ loading: true });

		let { start, end } = this.state;

		let result = await AnalyticsService.getScheduledMessageAnalytics({ locationId: UserService.getActiveLocation().id, start, end });

		if (!result) {
			ToastService.error("Error getting scheduled message data. Please try again.");
			await this.update({ loading: false });
			return;
		}

		await this.update({ loading: false, data: result, csvData: result });
	};

	updateCharts = () => {
		let { data, start, end } = this.state;

		if (!data || data.length < 1) {
			return;
		}

		let dates = data.map(item => item.date);
		dates.unshift("x");

		let sent = data.map(item => item.sent);
		sent.unshift("Sent");

		let responses = data.map(item => item.responses);
		responses.unshift("Responses");

		let columns = [dates, sent, responses];

		// Generate a color pattern for other charts
		let colors = [...CHART_COLORS];

		const isMonth = moment(end).diff(moment(start), "days") >= 90;

		this.generateChart(
			"#scheduled-message-analytics-chart",
			{
				x: "x",
				columns: columns,
				type: "bar",
				labels: true
			},
			{
				x: {
					label: "Day",
					type: "timeseries",
					tick: {
						format: function(x) {
							if (isMonth) {
								const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
								return "" + x.getFullYear() + " " + monthString[x.getMonth()];
							}
							return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
						},
						rotate: window.innerWidth > 768 ? 0 : 75,
						multiline: false
					}
				},
				y: {
					label: "Counts"
				}
			},
			{
				pattern: colors
			}
		);
	};

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			let { chart } = this.state;

			chart = c3.generate({
				bindto: id,
				data: data,
				size: {
					height: 260
				},
				axis: axis,
				color: color,
				legend: legend
			});

			this.update({ chart });
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.update({ start });
	};
	handleToChange = async end => {
		await this.update({ end });
		this.showFromMonth();
	};
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	renderDatePicker = () => {
		const { start, end } = this.state;
		const modifiers = { start, end };

		return (
			<div className="dashboard__card___datepicker">
				<label>Date Range&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={start}
							placeholder=" From"
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [start, { from: start, to: end }],
								disabledDays: { after: end },
								toMonth: end,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.to.getInput().focus()
							}}
							onDayChange={start => this.handleFromChange(start)}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={end}
								placeholder=" To"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { before: start },
									modifiers,
									month: start,
									fromMonth: start,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<div id="date-update" className="mb-button" onClick={this.resetComponent}>
					Update Date
				</div>
			</div>
		);
	};

	renderBody = () => {
		let { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div>Scheduled Messages is not enabled. Contact support to get started.</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div>Scheduled Message data is restricted.</div>;
		}

		return (
			<>
				{loading && (
					<div className="Common__spinnerdiv--center">
						<Spinners loading={true} type="tail-fade" size="60px" />
					</div>
				)}
				<div>
					<div id="scheduled-message-analytics-chart" />
				</div>
			</>
		);
	};

	renderHeader = () => {
		let { csvData, start, end } = this.state;
		let { cardName } = this.props;

		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : "Campaign Messages Sent"}</div>
					<div className="dashboard__card__header__actions">
						{csvData && (
							<CSVLink
								data={csvData}
								filename={`${locationName}-scheduled-messages-${moment(start).format("YYYY-MM-DD")}-${moment(end).format("YYYY-MM-DD")}_analytics.csv`}
								target="_self"
							>
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>
				{this.renderDatePicker()}
			</>
		);
	};

	render = () => {
		return (
			<DashboardCard className={DASHBOARD_CARDS.scheduledMessageAnalytics.className} height={DASHBOARD_CARDS.scheduledMessageAnalytics.h}>
				{this.renderHeader()}

				{this.renderBody()}
			</DashboardCard>
		);
	};
}

export default withRouter(withLocation(ScheduledMessageAnalytics));

import React from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import ToastService from "../../services/ToastService";
import MessagesService from "../../services/MessagesService";
import UtilityService from "../../services/UtilityService";
import UserService from "../../services/UserService";

import { KEYS, MEDIUM } from "../../constants/Messenger";

import "../../styles/css/components/commons/contact-search.css";

class ContactSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,

			sendToInput: "",
			searchTerm: "",
			searchResults: [0],
			currentIndex: 0,
			createMode: false,
			createType: null,
			secondValue: ""
		};

		this.searchInput = null;
		this.phoneOrEmailInput = null;
		this.currentResult = null;

		this.searchDebounce = _.debounce(
			() => {
				this.fetchSearchResults();
			},
			500,
			{
				leading: false,
				trailing: true
			}
		);
	}

	resetComponent = () => {
		this.update({
			loading: false,

			sendToInput: "",
			searchTerm: "",
			searchResults: [0],
			currentIndex: 0,
			createMode: false,
			createType: null,
			secondValue: ""
		});
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onSearchInput = async event => {
		let value = event.target.value;
		await this.update({
			sendToInput: value,
			searchTerm: value
		});

		if (value.length === 0) {
			this.update({
				createMode: false
			});
			return;
		}

		this.searchDebounce();
	};

	fetchSearchResults = async () => {
		let { searchTerm } = this.state;
		let { hasPaymentMethod } = this.props;

		let contacts = await MessagesService.searchConversations({
			searchTerm,
			locationId: UserService.getActiveLocation().id,
			allowRestrictedViewAll: true,
			hasPaymentMethod
		});

		contacts.unshift(0);

		await this.update({
			searchResults: contacts,
			currentIndex: 0
		});
	};

	onSearchKey = async e => {
		let { currentIndex, searchResults } = this.state;

		if (e.keyCode === KEYS.enter) {
			e.preventDefault();
			if (currentIndex === 0) {
				await this.turnOnCreateMode();
			} else {
				let contact = searchResults[currentIndex];

				this.onOpenExistingConversation(contact);
			}
		} else if (e.keyCode === KEYS.up) {
			e.preventDefault();
			currentIndex = currentIndex === 0 ? searchResults.length - 1 : currentIndex - 1;
		} else if (e.keyCode === KEYS.down) {
			e.preventDefault();
			currentIndex = currentIndex === searchResults.length - 1 ? 0 : currentIndex + 1;
		} else if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			await this.update({
				show: false
			});
		}

		await this.update({
			currentIndex
		});

		if (this.currentResult) {
			this.currentResult.scrollIntoView(false);
		}
	};

	onPhoneOrEmailChange = async event => {
		await this.update({
			[event.target.name]: event.target.value,
			searchTerm: event.target.value,
			currentIndex: 0
		});
		this.searchDebounce();
	};

	onEnter = e => {
		if (e.keyCode === KEYS.enter) {
			this.onStartNewConversationClicked();
		}
	};

	createIndexReference = (index, ref) => {
		let { currentIndex } = this.state;

		if (currentIndex === index) {
			this.currentResult = ref;
		}
	};

	onOpenExistingConversation = async contact => {
		let { loading } = this.state;
		let { t } = this.props;

		if (loading) {
			return;
		}

		await this.update({
			loading: true
		});

		let toastId = ToastService.info(t("Opening conversation ..."));

		let conversation = null;
		let locationId = UserService.getActiveLocation().id;

		// This is kinda gross and requires maitenance, but for now in order to get the start conversation flow working, we are manually setting the parameters for each
		// There are some house keeping items that the start_conversation endpoint does on the backend which makes it necessary to call the createNewConversation function even though we already have the contact
		if (contact.preferred_medium === MEDIUM.sms.key) {
			conversation = await MessagesService.createNewConversation({ locationId, phone: contact.phone });
		} else if (contact.preferred_medium === MEDIUM.email.key) {
			conversation = await MessagesService.createNewConversation({ locationId, email: contact.email });
		} else if (contact.preferred_medium === MEDIUM.facebook.key) {
			let [messengerIntegration] = contact.messengerIntegrations;
			conversation = await MessagesService.createNewConversation({ locationId, facebookId: messengerIntegration.reference_id });
		} else if (contact.preferred_medium === MEDIUM.instagram.key) {
			let [messengerIntegration] = contact.messengerIntegrations;
			conversation = await MessagesService.createNewConversation({ locationId, instagramId: messengerIntegration.reference_id });
		} else if (contact.preferred_medium === MEDIUM.google.key) {
			let [messengerIntegration] = contact.messengerIntegrations;
			conversation = await MessagesService.createNewConversation({ locationId, googleId: messengerIntegration.reference_id });
		} else if (contact.preferred_medium === MEDIUM.secure.key) {
			conversation = await MessagesService.createNewConversation({ locationId, phone: contact.phone });
		}

		if (!conversation) {
			ToastService.info(t("An error occurred trying start a conversation ..."));
			await this.update({ loading: false });

			ToastService.dismiss(toastId);
			return;
		}

		if (this.props.onConversationSelected) {
			this.props.onConversationSelected(conversation, false);
		}

		this.resetComponent();

		ToastService.dismiss(toastId);
	};

	onStartNewConversationClicked = async () => {
		let { sendToInput, secondValue, createType, loading, createMode } = this.state;
		let { t } = this.props;

		if (loading) {
			return;
		}

		await this.update({
			loading: true
		});

		let name = "";
		let phoneOrEmail = "";

		if (createType === "name") {
			name = secondValue;
			phoneOrEmail = sendToInput;
		} else {
			name = sendToInput;
			phoneOrEmail = secondValue;
		}

		if (!this.isValid()) {
			await this.update({ loading: false });
			return;
		}

		let toastId = ToastService.info(t("Opening conversation ..."));

		let conversation = null;
		let locationId = UserService.getActiveLocation().id;

		if (!UtilityService.isEmail(phoneOrEmail)) {
			conversation = await MessagesService.createNewConversation({ locationId, name, phone: phoneOrEmail });
		} else {
			conversation = await MessagesService.createNewConversation({ locationId, name, email: phoneOrEmail });
		}

		if (!conversation) {
			ToastService.info(t("An error occurred trying start a conversation ..."));
			await this.update({ loading: false });

			ToastService.dismiss(toastId);
			return;
		}

		if (this.props.onConversationSelected) {
			this.props.onConversationSelected(conversation, createMode);
		}

		await this.update({ loading: false });

		ToastService.dismiss(toastId);
	};

	isValid() {
		let { sendToInput, secondValue, createType } = this.state;

		let name = "";
		let phoneOrEmail = "";

		if (createType === "name") {
			name = secondValue;
			phoneOrEmail = sendToInput;
		} else {
			name = sendToInput;
			phoneOrEmail = secondValue;
		}

		return name.length > 0 && this.isPhoneOrEmail(phoneOrEmail);
	}

	isPhoneOrEmail(value) {
		let isPhone = UtilityService.isMobilePhoneValid(MessagesService.formatPhoneNumber(value));
		let isEmail = UtilityService.isEmail(value);

		return isPhone || isEmail;
	}

	turnOnCreateMode = async () => {
		let { sendToInput } = this.state;

		await this.update({
			createMode: true,
			secondValue: "",
			createType: this.isPhoneOrEmail(sendToInput) ? "name" : "medium"
		});
		if (this.phoneOrEmailInput) {
			this.phoneOrEmailInput.focus();
		}
	};

	onSearchResultClicked = async (contact, index) => {
		let user = UserService.get();

		if (!contact) {
			return;
		}

		if (contact.assigned_user_id !== user.id && !user.GroupPermission.view_messages_assigned_to_others && contact.id !== "create-new-contact") {
			return;
		}

		if (contact.assigned_user_id === 0 && !user.GroupPermission.view_unassigned_messages && contact.id !== "create-new-contact") {
			return;
		}

		if (contact.assigned_user_id === user.id && !user.GroupPermission.view_customer_messages && contact.id !== "create-new-contact") {
			return;
		}

		if (index === 0) {
			await this.turnOnCreateMode();
			return;
		}

		this.onOpenExistingConversation(contact);
	};

	renderSearchResults() {
		let { showPaymentMethod, disableCreateContact, t } = this.props;
		let { searchResults, currentIndex, createMode } = this.state;
		let user = UserService.get();

		return (
			<div className="contact-search__search-results">
				{searchResults.map((c, index) => {
					if (index === 0 && (createMode || disableCreateContact)) {
						return null;
					}

					if (index === 0) {
						c = {
							preferred_medium: "new-contact",
							name: t("Create a new contact ..."),
							id: "create-new-contact"
						};
					}

					let icon = null;

					if (c.preferred_medium === "new-contact") {
						icon = <Icon.Plus size="20" />;
					} else {
						let MediumIcon = MEDIUM[c.preferred_medium] ? MEDIUM[c.preferred_medium].icon : Icon.HelpCircle;
						icon = <MediumIcon size="20" />;
					}

					return (
						<>
							{index === 1 && <div className="contact-search__search-results__subheader">{t("Previous Conversations")}</div>}
							<div
								id={c.id}
								onClick={() => {
									this.onSearchResultClicked(c, index);
								}}
								key={index}
								className={`contact-search__search-results__item ${currentIndex === index ? "contact-search__search-results__item--hover" : ""} ${
									c.assigned_user_id !== user.id && !user.GroupPermission.view_messages_assigned_to_others && index !== 0
										? "contact-search__search-results__item--disabled"
										: c.assigned_user_id === 0 && !user.GroupPermission.view_unassigned_messages && index !== 0
										? "contact-search__search-results__item--disabled"
										: c.assigned_user_id === user.id && !user.GroupPermission.view_customer_messages && index !== 0
										? "contact-search__search-results__item--disabled"
										: ""
								}`}
								ref={ref => this.createIndexReference(index, ref)}
							>
								<div className="contact-search__search-results__item__icon">{icon}</div>
								<div className="contact-search__search-results__item__name">{c.name || t("Unknown")}</div>
								{c.phone && <div className="contact-search__search-results__item__phone">{c.phone}</div>}
								{c.email && <div className="contact-search__search-results__item__email">{c.email}</div>}
								{showPaymentMethod && c.paymentMethods && c.paymentMethods.length > 0 && (
									<div className="contact-search__search-results__item__payment">
										<Icon.CreditCard size="20" />
									</div>
								)}
								{c.assigned_user_id !== user.id && !user.GroupPermission.view_messages_assigned_to_others && index !== 0 && (
									<div className="contact-search__search-results__item__permission-denied">{t("Permission Denied - You are not assigned to this contact")}</div>
								)}
								{c.assigned_user_id === 0 && !user.GroupPermission.view_unassigned_messages && index !== 0 && (
									<div className="contact-search__search-results__item__permission-denied">{t("Permission Denied - You are not assigned to this contact")}</div>
								)}
								{c.assigned_user_id === user.id && !user.GroupPermission.view_customer_messages && index !== 0 && (
									<div className="contact-search__search-results__item__permission-denied">
										{t("Permission Denied - You are not able to see contacts assigned to you")}
									</div>
								)}
							</div>
						</>
					);
				})}
			</div>
		);
	}

	render = () => {
		let { actionText, t } = this.props;
		let { secondValue, sendToInput, createMode, createType } = this.state;

		return (
			<div className="contact-search">
				<div className="contact-search__input">
					<div>{t("Send To")}</div>
					<input
						id="mb-nc-contact-name"
						autoComplete="disabled"
						ref={ref => (this.searchInput = ref)}
						placeholder={t("Name, Phone, or Email ...")}
						value={sendToInput}
						onChange={this.onSearchInput}
						onKeyDown={this.onSearchKey}
					/>
				</div>

				{createMode && (
					<>
						<div className="contact-search__input">
							<div>{createType === "name" ? t("Name") : t("Phone or Email")}</div>
							<input
								id="mb-nc-phone-or-email"
								autoComplete="disabled"
								ref={ref => (this.phoneOrEmailInput = ref)}
								name="secondValue"
								placeholder={createType === "name" ? t("Name") : t("Phone or Email")}
								value={secondValue}
								onChange={this.onPhoneOrEmailChange}
								onKeyDown={this.onEnter}
							/>
						</div>
						<div className="contact-search__actions">
							<div
								id="contact-search-button-create"
								className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`}
								onClick={this.onStartNewConversationClicked}
							>
								{actionText || t("Create Contact")}
							</div>
						</div>
					</>
				)}

				{sendToInput.length > 0 && this.renderSearchResults()}
				{sendToInput.length === 0 && <img className="contact-search__graphic" src="https://cdn.demandhub.co/web-app/assets/contact_selector.svg" />}
			</div>
		);
	};
}

export default withTranslation(null, { withRef: true })(ContactSearch);

import React, { Component } from "react";
import * as Icon from "react-feather";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import posed, { PoseGroup } from "react-pose";

import { MODE, CUSTOMER_FILTER, CONVERSATION, KEYS, ACTION_CHANNELS } from "../../../constants/Messenger";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";
import MessagesService from "../../../services/MessagesService";
import NotificationService from "../../../services/NotificationService";
import CompanyService from "../../../services/CompanyService";
import InboxService from "../../../services/InboxService";
import TeamChatService from "../../../services/TeamChatService";
import ContactService from "../../../services/ContactService";
import SupportChatService from "../../../services/SupportChatService";
import UtilityService from "../../../services/UtilityService";

import CreateConversation from "./CreateConversation";
import EditInboxModal from "./Inboxes/EditInboxModal";
import NewInboxModal from "./Inboxes/NewInboxModal";
import TeamChatDiscover from "./TeamChatDiscover";
import ManageConversationGroup from "./ManageConversationGroup";

import "./navbar.css";

export const Unread = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 300
		}
	},
	exit: {
		y: 100,
		x: 0,
		opacity: 0,
		transition: {
			duration: 300
		}
	}
});

class Navbar extends Component {
	constructor(props) {
		super(props);

		let { locationId } = props;

		this.state = {
			locationId: locationId,
			dmConversations: [],
			dmGroups: [],
			channelConversations: [],
			channelGroups: [],
			showCreateConversation: false,
			filter: CUSTOMER_FILTER.all,
			updateUnreadCount: false,
			currentConversationId: null,
			openMap: {},
			currentGroup: null,
			hoverType: CONVERSATION.channel,
			hoverConversationIndex: null,
			contactCount: {
				all: 0,
				unassigned: 0,
				assignedToMe: 0
			},
			teamchatEnabled: false,
			smartInboxesEnabled: false,
			createInboxesEnabled: false,
			inboxes: [],
			currentInboxId: null,
			settingsInboxId: null,
			showEditInbox: false,
			showNewInbox: false,
			showTeamChatSearch: false,
			teamChatSearchInput: "",
			teamchatQueuedMessageCount: 0,
			customerQueuedMessageCount: 0,
			safeNumber: "• • • • • • • •",

			loading: true,

			loadingArray: Array.apply(null, Array(30)),

			isUnreadOffscreen: false,

			showCreateConversationGroup: false
		};

		this.teamChatSearch = null;

		this.debouncedSearchConversations = _.debounce(
			value => {
				this.searchConversations(value);
			},
			800,
			{
				leading: true,
				trailing: true
			}
		);

		this.lastUnread = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		let user = UserService.get();

		// Fired when a the admin for a chat has updated the details for a chat.
		// TODO - Requires a little more thought
		// NotificationService.subscribeOnce("internalConversationUpdate", "navbar_componentDidMount", async teamChat => {
		// 	console.log("internalConversationUpdate", teamChat);
		// });

		NotificationService.subscribeOnce("realtimeConnected", "navbar_componentDidMount", async () => {
			UtilityService.gracefullyReset(() => this.resetComponent());
		});

		// Fired when the same user on a different device has read a conversation
		NotificationService.subscribeOnce("markInternalMessageRead", "navbar_componentDidMount", async event => {
			this.setUnreadCount();
		});

		// Fired when a new customer message has been received
		NotificationService.subscribeOnce("newMessage", "navbar_componentDidMount", async message => {
			if (message.is_scheduled_message) {
				this.fetchCustomerQueuedMessageCount();
			}
		});

		// Fired when a new team chat message has been received
		NotificationService.subscribeOnce("newInternalMessage", "navbar_componentDidMount", async message => {
			let { channelConversations, dmConversations, currentConversationId } = this.state;

			if (message.conversation_id !== currentConversationId) {
				this.setUnreadCount();
			}

			if (message.sender_user_id === user.id && message.is_scheduled_message) {
				this.fetchTeamchatQueuedMessageCount();
			}

			let channels = channelConversations.map(convo => {
				return convo.id;
			});

			let conversationName =
				message.Conversation && message.Conversation.name && message.Conversation.type === CONVERSATION.channel ? message.Conversation.name : null;

			if (channels.includes(message.conversation_id)) {
				this.reorder(message.conversation_id, CONVERSATION.channel, conversationName);
				return;
			}

			let dms = dmConversations.map(convo => {
				return convo.id;
			});

			if (dms.includes(message.conversation_id)) {
				this.reorder(message.conversation_id, CONVERSATION.dm, conversationName);
				return;
			}

			await this.fetchConversations();
			await this.fetchChannels();
		});

		NotificationService.subscribeOnce("teamChatScheduledMessageChange", "navbar_componentDidMount", async () => {
			this.fetchTeamchatQueuedMessageCount();
		});

		NotificationService.subscribeOnce("customerScheduledMessageChange", "navbar_componentDidMount", async () => {
			this.fetchCustomerQueuedMessageCount();
		});

		// Fired when the customer conversation open count has changed
		NotificationService.subscribeOnce("contactCountUpdate", "navbar_componentDidMount", contactCount => {
			this.update({
				contactCount: contactCount
			});
		});

		NotificationService.subscribeOnce("contactInboxUpdated", "navbar_componentDidMount", contact => {
			this.onInboxSelect({ id: contact.inbox_id }, contact);
		});

		await this.resetComponent();
	}

	async resetComponent() {
		await this.update({ loading: true });

		await this.fetchConversations();
		await this.fetchChannels();
		await this.fetchInboxes();
		await this.configureSmartInboxes();
		await this.configureTeamchat();
		this.fetchTeamchatQueuedMessageCount();
		this.fetchCustomerQueuedMessageCount();
		this.storeNotificationPreferences();
		this.fetchSafeNumber();
		this.setOpenCount();
		this.triggerNavScroll();

		await this.update({ loading: false });
	}

	triggerNavScroll = () => {
		// We want the React Lifecycle to complete to ensure we have a reference to the last unread element
		setTimeout(() => {
			this.onNavScroll();
		}, 0);
	};

	onNavScroll = event => {
		let lastUnreadElement = this.lastUnread;

		if (!lastUnreadElement) {
			return;
		}

		let { top } = lastUnreadElement.getBoundingClientRect();

		if (top > window.innerHeight) {
			this.update({ isUnreadOffscreen: true });
		} else {
			this.update({ isUnreadOffscreen: false });
		}
	};

	async componentDidUpdate(prevProps) {
		let { locationId } = this.props;

		if (locationId !== prevProps.locationId) {
			this.resetComponent();
		}
	}

	setOpenCount() {
		this.update({
			contactCount: ContactService.getCachedContactCount()
		});
	}

	setUnreadCount() {
		this.update({
			updateUnreadCount: !this.state.updateUnreadCount
		});
	}

	async fetchConversations() {
		let user = UserService.get();
		// let conversations = await TeamChatService.fetchConversations({ userId: user.id });
		let conversationDms = await TeamChatService.fetchTeamChatConversationGroups({ conversationType: CONVERSATION.dm });

		if (!conversationDms) {
			return;
		}

		await this.update({
			dmConversations: conversationDms.otherConversations,
			dmGroups: conversationDms.groups
		});
	}

	async fetchChannels() {
		let user = UserService.get();
		// let conversations = await TeamChatService.fetchConversations({ userId: user.id, type: CONVERSATION.channel });

		let conversationChannels = await TeamChatService.fetchTeamChatConversationGroups({ conversationType: CONVERSATION.channel });

		if (!conversationChannels) {
			return;
		}

		await this.update({
			channelConversations: conversationChannels.otherConversations,
			channelGroups: conversationChannels.groups
		});
	}

	reorder(conversationId, type, name = null) {
		let key = type + "Conversations";

		// Grab either the Channels or DMs from the state
		let items = this.state[key];

		items = items.slice();

		// Find the index of the conversation that just got a new message
		let index = items.findIndex(c => c.id === conversationId);

		// Rip out that conversation from the list
		let pop = items.splice(index, 1);

		// Unhide the conversation manually
		pop[0]["Users.ConversationUser.is_hidden"] = 0;

		// Update the name of the conversation if it is updated
		if (name) {
			pop[0]["name"] = name;
		}

		// And put it on the top of the list
		items.unshift(pop[0]);

		// Update the state and render
		this.update({
			[key]: items
		});
	}

	storeNotificationPreferences() {
		let { channelConversations, dmConversations } = this.state;
		let fullList = channelConversations.concat(dmConversations);
		TeamChatService.storeNotificationPreferences(fullList);
	}

	async fetchInboxes() {
		let { locationId } = this.props;
		let user = UserService.get();
		let inboxes = await InboxService.fetchInboxes(locationId, user.id);

		this.update({
			inboxes
		});
	}

	async configureTeamchat() {
		let { id: userId } = UserService.get();

		// Doing manual fetching since localstorage may not be updated immediately
		let user = await UserService.fetchUser(userId);

		if (!user) {
			return;
		}

		let company = await CompanyService.fetchCompany(user.company_id);

		if (!company) {
			this.update({
				teamchatEnabled: false
			});
			return;
		}

		let teamchatEnabled = user.enable_teamchat && company.enable_teamchat;

		this.update({ teamchatEnabled });
	}

	async configureSmartInboxes() {
		let user = UserService.get();
		let location = UserService.getActiveLocation();

		if (!user) {
			return;
		}

		if (!user.GroupPermission.view_customer_messages) {
			return;
		}

		let smartInboxesEnabled =
			user.GroupPermission && user.GroupPermission.view_inboxes && location.LocationFeature && location.LocationFeature.messenger_smart_inboxes;
		let createInboxesEnabled = user.GroupPermission && user.GroupPermission.create_inboxes;
		await this.update({ smartInboxesEnabled, createInboxesEnabled });
	}

	async fetchSafeNumber() {
		let location = UserService.getActiveLocation();
		let safeNumber = await MessagesService.getSafeNumber(location.id);

		await this.update({
			safeNumber
		});
	}

	async fetchTeamchatQueuedMessageCount() {
		let teamchatQueuedMessageCount = await TeamChatService.countScheduledMessages();
		await this.update({ teamchatQueuedMessageCount });
	}

	async fetchCustomerQueuedMessageCount() {
		let locationId = UserService.getActiveLocation().id;
		let customerQueuedMessageCount = await MessagesService.countCustomerScheduledMessages({ locationId });
		await this.update({ customerQueuedMessageCount });
	}

	onShowCreateConversation = type => {
		this.update({
			showCreateConversation: true,
			conversationType: type
		});

		if (type === CONVERSATION.channel) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.teamchat.openNewChannelModal,
				label: GA_ACTIONS.messenger.navigation.teamchat.openNewChannelModal
			});
		} else if (type === CONVERSATION.dm) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.teamchat.openNewDMModal,
				label: GA_ACTIONS.messenger.navigation.teamchat.openNewDMModal
			});
		}
	};

	onCreateConversationClose = async (users, type, channelName, channelType) => {
		let user = UserService.get();

		if (!users || users.length === 0) {
			await this.update({
				showCreateConversation: false
			});
			return;
		}

		users.push(user);

		let ids = users.map(user => {
			return user.id;
		});

		// DM names are generated on the backend
		let name = type === CONVERSATION.channel ? channelName : "";
		let conversation = await MessagesService.startInternalConversation(ids, name, type, channelType);

		// We might have previously hidden this exact DM, so set it's hidden state to false
		if (type === CONVERSATION.dm) {
			await TeamChatService.unhideConversation(conversation.id, false);
		}

		await this.fetchConversations();
		await this.fetchChannels();

		await this.update({
			showCreateConversation: false,
			currentConversationId: conversation.id
		});

		let typeDescription = type === CONVERSATION.channel ? "channel" : "DM";

		toast.info(`New ${typeDescription} created.`, {
			position: "bottom-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "mb-toast"
		});

		await this.selectInternalConversation(conversation.id);

		if (type === CONVERSATION.channel) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.teamchat.createNewChannelModal,
				label: GA_ACTIONS.messenger.navigation.teamchat.createNewChannelModal
			});
		} else if (type === CONVERSATION.dm) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.teamchat.createNewDMModal,
				label: GA_ACTIONS.messenger.navigation.teamchat.createNewDMModal
			});
		}
	};

	onShowCreateConversationGroup = (type, createNewGroup) => {
		if (createNewGroup) {
			this.update({
				currentGroup: null
			});
		}

		this.update({
			showCreateConversationGroup: true,
			conversationType: type
		});
	};

	onCreateConversationGroupClose = async createdGroup => {
		this.update({
			showCreateConversationGroup: false
		});

		if (createdGroup) {
			let { conversationType } = this.state;

			if (conversationType === CONVERSATION.channel) {
				this.fetchChannels();
			} else if (conversationType === CONVERSATION.dm) {
				this.fetchConversations();
			}
		}
	};

	async selectInternalConversation(conversationId) {
		let { channelConversations, dmConversations } = this.state;

		let convo = channelConversations.find(convo => {
			return convo.id === conversationId;
		});

		if (!convo) {
			convo = dmConversations.find(convo => {
				return convo.id === conversationId;
			});
		}

		if (this.props.onSelect) {
			this.props.onSelect(MODE.team, convo);
		}
	}

	onFilterSelect = async filter => {
		await this.update({
			filter: filter,
			currentConversationId: null,
			currentInboxId: null
		});

		if (this.props.onSelect) {
			this.props.onSelect(MODE.customer, filter);
		}

		if (filter === CUSTOMER_FILTER.all) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.customer.selectedAllFilter,
				label: GA_ACTIONS.messenger.navigation.customer.selectedAllFilter
			});
		} else if (filter === CUSTOMER_FILTER.unassigned) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.customer.selectedUnassignedFilter,
				label: GA_ACTIONS.messenger.navigation.customer.selectedUnassignedFilter
			});
		} else if (filter === CUSTOMER_FILTER.assigned_to_me) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.customer.selectedMyMessagesFilter,
				label: GA_ACTIONS.messenger.navigation.customer.selectedMyMessagesFilter
			});
		}
	};

	async onInboxSelect(inbox, contact) {
		await this.update({
			filter: CUSTOMER_FILTER.all,
			currentConversationId: null,
			currentInboxId: inbox.id
		});

		let contactId = contact ? contact.id : null;

		if (this.props.onSelect) {
			this.props.onSelect(MODE.customer, CUSTOMER_FILTER.all, inbox.id, contactId);
		}

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.navigation.name,
			action: GA_ACTIONS.messenger.navigation.customer.selectInbox,
			label: GA_ACTIONS.messenger.navigation.customer.selectInbox
		});
	}

	async onConversationSelect(mode, convo, group) {
		let { dmConversations, showTeamChatSearch } = this.state;

		if (showTeamChatSearch) {
			this.fetchConversations();
			this.fetchChannels();
		}

		await this.update({
			filter: null,
			currentConversationId: convo.id,
			showTeamChatSearch: false,
			teamChatSearchInput: "",
			currentGroup: group ? group : null
		});

		await TeamChatService.unhideConversation(convo.id, false);

		for (let dm of dmConversations) {
			if (dm.id === convo.id) {
				dm["Users.ConversationUser.is_hidden"] = 0;
			}
		}

		if (this.props.onSelect) {
			this.props.onSelect(MODE.team, convo);
		}

		if (convo.type === CONVERSATION.channel) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.teamchat.selectChannel,
				label: GA_ACTIONS.messenger.navigation.teamchat.selectChannel
			});
		} else if (convo.type === CONVERSATION.dm) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.teamchat.selectDM,
				label: GA_ACTIONS.messenger.navigation.teamchat.selectDM
			});
		}
	}

	toggleConversationGroupOpen = ({ groups, selectedGroup }) => {
		let { openMap } = this.state;

		let selectedGroupId = selectedGroup.group_id;

		// Iterate over channel or DM groups
		for (let group of groups) {
			// If the selected group matches the a group in the list
			if (group.group_id === selectedGroupId) {
				// Set the conversation group to "open" within the map if the entry doesn't exist
				if (typeof openMap[selectedGroupId] === "undefined") {
					openMap[selectedGroupId] = true;
				}
				// Otherwise if the entry is there then toggle it
				else {
					openMap[selectedGroupId] = !openMap[selectedGroupId];
				}
			}
		}
	};

	onConversationGroupSelect = async group => {
		let { channelGroups, dmGroups, openMap } = this.state;

		this.toggleConversationGroupOpen({ selectedGroup: group, groups: channelGroups });
		this.toggleConversationGroupOpen({ selectedGroup: group, groups: dmGroups });

		await this.update({
			openMap,
			currentGroup: group
		});
	};

	async onConversationHide(e, conversationId) {
		e.stopPropagation();

		let { teamChatSearchInput, dmConversations, dmGroups } = this.state;

		TeamChatService.hideConversation(conversationId, true);

		for (let dm of dmConversations) {
			if (dm.id === conversationId) {
				dm["Users.ConversationUser.is_hidden"] = 1;
			}
		}

		for (let group of dmGroups) {
			for (let i = 0; i < group.conversations.length; i++) {
				const dm = group.conversations[i];
				if (dm.id === conversationId) {
					dm["Users.ConversationUser.is_hidden"] = 1;
				}
			}
		}

		await this.update({
			teamChatSearchInput,
			dmConversations
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.navigation.name,
			action: GA_ACTIONS.messenger.navigation.teamchat.hideDM,
			label: GA_ACTIONS.messenger.navigation.teamchat.hideDM
		});
	}

	onShowNewInbox = () => {
		this.update({
			showNewInbox: true
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.navigation.name,
			action: GA_ACTIONS.messenger.navigation.customer.openedCreateInboxModal,
			label: GA_ACTIONS.messenger.navigation.customer.openedCreateInboxModal
		});
	};

	onNewInboxClose = async inbox => {
		this.update({
			showNewInbox: false
		});

		if (inbox) {
			await this.fetchInboxes();
			// After creating a new inbox, lettuce open the EditInboxModal for the new inbox
			await this.update({
				showEditInbox: true,
				settingsInboxId: inbox.id
			});

			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.customer.createdCreateInboxModal,
				label: GA_ACTIONS.messenger.navigation.customer.createdCreateInboxModal
			});
		}
	};

	onShowEditInbox = (event, inboxId) => {
		event.preventDefault();
		event.stopPropagation();

		this.update({
			settingsInboxId: inboxId,
			showEditInbox: true
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.navigation.name,
			action: GA_ACTIONS.messenger.navigation.customer.openedEditInboxModal,
			label: GA_ACTIONS.messenger.navigation.customer.openedEditInboxModal
		});
	};

	onEditInboxClose = async updated => {
		await this.update({
			settingsInboxId: null,
			showEditInbox: false
		});

		if (updated) {
			await this.fetchInboxes();

			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.navigation.name,
				action: GA_ACTIONS.messenger.navigation.customer.savedEditInboxModal,
				label: GA_ACTIONS.messenger.navigation.customer.savedEditInboxModal
			});
		}
	};

	onShowTeamChatSearch = async () => {
		await this.update({
			showTeamChatSearch: true,
			hoverConversationIndex: 0,
			hoverType: CONVERSATION.channel
		});

		if (this.teamChatSearch) {
			this.teamChatSearch.focus();
		}

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.navigation.name,
			action: GA_ACTIONS.messenger.navigation.teamchat.openSearch,
			label: GA_ACTIONS.messenger.navigation.teamchat.openSearch
		});
	};

	onHideTeamChatSearch = async () => {
		this.fetchConversations();
		this.fetchChannels();

		await this.update({
			showTeamChatSearch: false,
			teamChatSearchInput: ""
		});
	};

	searchConversations = async searchTerm => {
		let user = UserService.get();

		let { channelConversations, dmConversations } = this.state;
		let hoverType = null;

		channelConversations = await TeamChatService.fetchConversations({
			userId: user.id,
			type: CONVERSATION.channel,
			searchTerm
		});

		dmConversations = await TeamChatService.fetchConversations({
			userId: user.id,
			type: CONVERSATION.dm,
			searchTerm
		});

		if (channelConversations.length > 0) {
			hoverType = CONVERSATION.channel;
		} else if (dmConversations.length > 0) {
			hoverType = CONVERSATION.dm;
		}

		await this.update({
			channelConversations,
			dmConversations,
			hoverType
		});
	};

	onTeamChatSearchChange = async event => {
		let value = event.target.value;

		this.debouncedSearchConversations(value);

		this.update({
			teamChatSearchInput: value,
			hoverConversationIndex: 0
		});
	};

	onTeamChatSearchKey = async e => {
		let { hoverType, hoverConversationIndex, dmConversations, channelConversations } = this.state;

		let hasChannels = channelConversations.length > 0;
		let hasDMs = dmConversations.length > 0;

		if (!hasChannels && !hasDMs) {
			this.update({ hoverConversationIndex: 0, hoverType: null });
			return;
		}

		// If the user hits the ENTER key, select the current chat the selector is on
		if (e.keyCode === KEYS.enter) {
			e.preventDefault();
			let list = hoverType === CONVERSATION.channel ? channelConversations : dmConversations;
			this.onConversationSelect(MODE.team, list[hoverConversationIndex]);
		}
		// If the user hits the UP arrow while searching
		else if (e.keyCode === KEYS.up) {
			e.preventDefault();
			// If the selector is at the top of the a list
			if (hoverConversationIndex === 0) {
				// If the selector is on the top of the channels list, and the user presses the UP key, it should select the bottom of the DMs.
				if (hoverType === CONVERSATION.channel) {
					hoverConversationIndex = hasDMs ? dmConversations.length - 1 : 0;
					hoverType = hasDMs ? CONVERSATION.dm : CONVERSATION.channel;
				}
				// If the selector is on the top of the DMs list, and the user presses the UP key, it should select the bottom of the channels.
				else {
					hoverConversationIndex = hasChannels ? channelConversations.length - 1 : 0;
					hoverType = hasChannels ? CONVERSATION.channel : CONVERSATION.dm;
				}
			}
			// In all other cases move the selector up by 1 chat
			else {
				hoverConversationIndex--;
			}
		}
		// If the user hits the DOWN arrow while searching
		else if (e.keyCode === KEYS.down) {
			e.preventDefault();

			// If the selector is currently on the channels list
			if (hoverType === CONVERSATION.channel) {
				// And the selector is on the last chat of the channels list, change it to the top of the DMs list
				if (hoverConversationIndex === channelConversations.length - 1) {
					hoverConversationIndex = 0;
					hoverType = hasDMs ? CONVERSATION.dm : CONVERSATION.channel;
				}
				// Otherwise move the selector down by 1 chat
				else {
					hoverConversationIndex++;
				}
			}
			// If the selector is DMs list
			else {
				// And the selector is on the last chat of the DMs list, change it to the top of the DMs list
				if (hoverConversationIndex === dmConversations.length - 1) {
					hoverConversationIndex = 0;
					hoverType = hasChannels ? CONVERSATION.channel : CONVERSATION.dm;
				}
				// Otherwise move the selector down by 1 chat
				else {
					hoverConversationIndex++;
				}
			}
		}
		// If the user hits the ESC key, then simply close the search
		else if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onHideTeamChatSearch();

			return;
		}

		await this.update({ hoverConversationIndex, hoverType });
	};

	onInboxesUpdated = async () => {
		await this.fetchInboxes();
		await this.configureSmartInboxes();
	};

	onInternalMessageUserRemoved = async () => {
		// get the latest teamchat conversations
		await this.fetchConversations();
		await this.fetchChannels();
	};

	showCustomerConversations = () => {
		let { loading } = this.state;
		const user = UserService.get();

		return !loading && user.GroupPermission.view_customer_messages;
	};

	onScheduledCustomerMessageClick = async () => {
		this.update({
			currentConversationId: null
		});

		if (this.props.onSelect) {
			this.props.onSelect(MODE.customer, CUSTOMER_FILTER.all, null, null, {
				action: ACTION_CHANNELS.customer.scheduledCustomerMessages.id,
				count: this.state.customerQueuedMessageCount
			});
		}
	};

	onScheduledTeamMessageClick = async () => {
		let convos = await TeamChatService.fetchConversationsWithQueuedMessages();

		// Sort the conversations
		convos = TeamChatService.sortConversations(convos);

		if (!convos || convos.length < 1) {
			return;
		}

		this.update({
			currentConversationId: convos[0].id
		});

		if (this.props.onSelect) {
			this.props.onSelect(MODE.team, convos[0], null, null, {
				action: ACTION_CHANNELS.team.scheduledTeamMessages.id,
				count: this.state.teamchatQueuedMessageCount
			});
		}
	};

	renderChannelGroups() {
		let { channelConversations, channelGroups, openMap, currentConversationId } = this.state;

		if (!channelGroups || channelGroups.length < 1) {
			return this.renderChannelConversations(channelConversations);
		}

		return (
			<>
				{channelGroups.map((group, index) => {
					let unread = 0;
					group.conversations.map(convo => (unread += TeamChatService.getUnreadCount(convo.id)));

					let isOpen = openMap[group.group_id];
					let isChildSelected = group.conversations.some(convo => convo.id === currentConversationId);

					let unreadText = unread > 9 ? "9+" : unread;

					return (
						<>
							<div
								ref={ref => {
									if (unread > 0) {
										this.lastUnread = ref;
									}
								}}
								key={index}
								className={`mb-navbar-block-item mb-navbar-block-item--teamchat mb-navbar-block-item--teamchat-group ${
									isChildSelected ? "mb-navbar-block-item--group-active" : ""
								}`}
								onClick={() => this.onConversationGroupSelect(group)}
							>
								<div className={`mb-navbar-block-item--text ${unread > 0 ? "mb-navbar-block-item--bold" : ""}`}>
									<Icon.Folder size={16} className={"mb-navbar-block-item-group__icon"} />
									{group.group_name}
								</div>
								{unread > 0 && !isOpen && <div className="mb-navbar-block-item--unread">{unreadText}</div>}

								<div
									data-tip
									id="conversation-group"
									data-for="conversation-group-tooltip"
									className="mb-navbar-block-item--teamchat-group-hide"
									onClick={() => this.onShowCreateConversationGroup(CONVERSATION.channel)}
								>
									<Icon.MoreHorizontal className="mb-navbar-block-item--more" size={14} />
									<ReactTooltip id="conversation-group-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
										Manage Group
									</ReactTooltip>
								</div>
							</div>
							{isOpen && this.renderChannelConversations(group.conversations, group)}
						</>
					);
				})}
				{this.renderChannelConversations(channelConversations)}
			</>
		);
	}

	renderChannelConversations(conversations, group) {
		let { currentConversationId, showTeamChatSearch, hoverConversationIndex, hoverType } = this.state;

		if (conversations.length === 0 && group) {
			return <div className="mb-navbar-block-item mb-navbar-block-item--empty">- No Channels -</div>;
		}

		return conversations.map((convo, index) => {
			let unread = TeamChatService.getUnreadCount(convo.id);

			let isHover = index === hoverConversationIndex && hoverType === CONVERSATION.channel && showTeamChatSearch;

			let unreadText = unread > 9 ? "9+" : unread;

			let classNamesApplied = "mb-navbar-block-item mb-navbar-block-item--teamchat";

			if (convo.id === currentConversationId) {
				classNamesApplied += " mb-navbar-block-item--active";
			}
			if (group) {
				classNamesApplied += " mb-navbar-block-item--group-item";
			}
			if (isHover) {
				classNamesApplied += " mb-navbar-block-item--hover";
			}

			return (
				<div
					ref={ref => {
						if (unread > 0) {
							this.lastUnread = ref;
						}
					}}
					key={index}
					className={classNamesApplied}
					onClick={() => this.onConversationSelect(MODE.team, convo, group)}
				>
					{group && <Icon.CornerDownRight size={12} className={"mb-navbar-block-item-group__convo__icon"} />}
					<div className={`mb-navbar-block-item--text ${unread > 0 ? "mb-navbar-block-item--bold" : ""}`}>{convo.name}</div>
					{unread > 0 && <div className="mb-navbar-block-item--unread">{unreadText}</div>}
				</div>
			);
		});
	}

	renderDmGroups() {
		let { dmGroups, dmConversations, openMap, currentConversationId } = this.state;

		if (!dmGroups || dmGroups.length < 1) {
			return this.renderDmConversations(dmConversations);
		}

		return (
			<>
				{dmGroups.map((group, index) => {
					let unread = 0;
					group.conversations.map(convo => (unread += TeamChatService.getUnreadCount(convo.id)));
					let unreadText = unread > 9 ? "9+" : unread;

					let isOpen = openMap[group.group_id];
					let isChildSelected = group.conversations.some(convo => convo.id === currentConversationId);

					return (
						<>
							<div
								ref={ref => {
									if (unread > 0) {
										this.lastUnread = ref;
									}
								}}
								key={index}
								className={`mb-navbar-block-item mb-navbar-block-item--teamchat mb-navbar-block-item--teamchat-group ${
									isChildSelected ? "mb-navbar-block-item--group-active" : ""
								}`}
								onClick={() => this.onConversationGroupSelect(group)}
							>
								<div className={`mb-navbar-block-item--text ${unread > 0 ? "mb-navbar-block-item--bold" : ""}`}>
									<Icon.Folder size={16} className={"mb-navbar-block-item-group__icon"} />
									{group.group_name}
								</div>
								{unread > 0 && !isOpen && <div className="mb-navbar-block-item--unread">{unreadText}</div>}
								<div
									data-tip
									id="conversation-group"
									data-for="conversation-group-tooltip"
									className="mb-navbar-block-item--teamchat-group-hide"
									onClick={() => this.onShowCreateConversationGroup(CONVERSATION.dm)}
								>
									<Icon.MoreHorizontal className="mb-navbar-block-item--more" size={14} />
									<ReactTooltip id="conversation-group-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
										Manage Group
									</ReactTooltip>
								</div>
							</div>
							{isOpen && this.renderDmConversations(group.conversations, group)}
						</>
					);
				})}
				{this.renderDmConversations(dmConversations)}
			</>
		);
	}

	renderDmConversations(conversations, group) {
		let { currentConversationId, showTeamChatSearch, hoverConversationIndex, hoverType } = this.state;

		if (conversations.length === 0 && group) {
			return <div className="mb-navbar-block-item mb-navbar-block-item--empty">- No Direct Messages -</div>;
		}

		if (conversations.every(convo => convo["Users.ConversationUser.is_hidden"] === 1)) {
			return <div className="mb-navbar-block-item mb-navbar-block-item--empty">- DM's Hidden -</div>;
		}

		return conversations.map((convo, index) => {
			let unread = TeamChatService.getUnreadCount(convo.id);

			let isHover = index === hoverConversationIndex && hoverType === CONVERSATION.dm && showTeamChatSearch;

			if (convo["Users.ConversationUser.is_hidden"] === 1 && !showTeamChatSearch) {
				return null;
			}

			let unreadText = unread > 9 ? "9+" : unread;

			let classNamesApplied = "mb-navbar-block-item mb-navbar-block-item--teamchat";

			if (convo.id === currentConversationId) {
				classNamesApplied += " mb-navbar-block-item--active";
			}
			if (group) {
				classNamesApplied += " mb-navbar-block-item--group-item";
			}
			if (isHover) {
				classNamesApplied += " mb-navbar-block-item--hover";
			}

			return (
				<div
					ref={ref => {
						if (unread > 0) {
							this.lastUnread = ref;
						}
					}}
					key={index}
					className={classNamesApplied}
					onClick={() => this.onConversationSelect(MODE.team, convo, group)}
				>
					{group && <Icon.CornerDownRight size={12} className={"mb-navbar-block-item-group__convo__icon"} />}
					<div className={`mb-navbar-block-item--text ${unread > 0 ? "mb-navbar-block-item--bold" : ""}`}>{convo.name}</div>
					{unread > 0 && <div className="mb-navbar-block-item--unread">{unreadText}</div>}
					{!showTeamChatSearch && (
						<div id="mb-navbar-block-item--teamchat-hide" className="mb-navbar-block-item--teamchat-hide" onClick={e => this.onConversationHide(e, convo.id)}>
							<Icon.X size="18" />
						</div>
					)}
				</div>
			);
		});
	}

	renderInboxes() {
		let { contactCount, inboxes, currentInboxId } = this.state;

		let user = UserService.get();

		if (inboxes.length === 0) {
			return <div className="mb-navbar-block-item mb-navbar-block-item--empty">- No Inboxes -</div>;
		}

		return inboxes.map((inbox, index) => {
			let assigned = inbox.Users.some(iu => iu.id === user.id);

			return (
				<div
					key={index}
					className={`mb-navbar-block-item ${inbox.id === currentInboxId ? "mb-navbar-block-item--active" : ""} ${
						!assigned ? "mb-navbar-block-item--unassigned" : ""
					} mb-navbar-block-item--inbox`}
					onClick={() => this.onInboxSelect(inbox)}
				>
					<div className={`mb-navbar-block-item--text ${contactCount[inbox.id] > 0 ? "mb-navbar-block-item--bold" : ""}`}>{inbox.name}</div>
					{inbox.is_public && (
						<div className="mb-navbar-block-item--inbox-public">
							<Icon.Eye size="14" />
						</div>
					)}
					{contactCount[inbox.id] > 0 && <div className="mb-navbar-block-item--unread">{contactCount[inbox.id]}</div>}
					{user.GroupPermission.update_inboxes && (
						<div className="mb-navbar-block-item--inbox-settings" onClick={event => this.onShowEditInbox(event, inbox.id)}>
							<Icon.Settings size="16" />
						</div>
					)}
				</div>
			);
		});
	}

	renderConversationsByOwner() {
		let { filter, contactCount, currentInboxId } = this.state;
		const user = UserService.get();

		let viewMessagesAssignedToOthers = user.GroupPermission.view_messages_assigned_to_others;
		let viewUnassignedMessages = user.GroupPermission.view_unassigned_messages;
		let viewMyMessages = user.GroupPermission.view_customer_messages;

		return (
			<>
				{viewMessagesAssignedToOthers && (
					<div
						id="mb-all-conversation"
						className={`mb-navbar-block-item ${filter === CUSTOMER_FILTER.all && currentInboxId === null ? "mb-navbar-block-item--active" : ""}`}
						onClick={() => this.onFilterSelect(CUSTOMER_FILTER.all)}
					>
						<div className="mb-navbar-block-item--text">All</div>
						{contactCount.all > 0 && <div className="mb-navbar-block-item--unread">{contactCount.all <= 9 ? contactCount.all : "9+"}</div>}
					</div>
				)}
				{viewUnassignedMessages && (
					<div
						className={`mb-navbar-block-item ${filter === CUSTOMER_FILTER.unassigned && currentInboxId === null ? "mb-navbar-block-item--active" : ""}`}
						onClick={() => this.onFilterSelect(CUSTOMER_FILTER.unassigned)}
					>
						<div className="mb-navbar-block-item--text">Unassigned</div>
						{contactCount.unassigned > 0 && <div className="mb-navbar-block-item--unread">{contactCount.unassigned <= 9 ? contactCount.unassigned : "9+"}</div>}
					</div>
				)}
				{viewMyMessages && (
					<div
						className={`mb-navbar-block-item ${filter === CUSTOMER_FILTER.assigned_to_me && currentInboxId === null ? "mb-navbar-block-item--active" : ""}`}
						onClick={() => this.onFilterSelect(CUSTOMER_FILTER.assigned_to_me)}
					>
						<div className="mb-navbar-block-item--text">My Messages</div>
						{contactCount.assignedToMe > 0 && (
							<div className="mb-navbar-block-item--unread">{contactCount.assignedToMe <= 9 ? contactCount.assignedToMe : "9+"}</div>
						)}
					</div>
				)}
			</>
		);
	}

	renderClassyLoader() {
		let { loading, loadingArray } = this.state;

		if (!loading) {
			return null;
		}

		return (
			<ContentLoader speed={1} width={215} height={2000} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
				{loadingArray.map((item, index) => {
					return <rect key={index} x="20" y={(index * 40).toString()} rx="5" ry="5" width={index % 3 === 0 ? "170" : "140"} height="20" />;
				})}
			</ContentLoader>
		);
	}

	onFloatingUnreadClicked = () => {
		if (!this.lastUnread) {
			return;
		}

		this.lastUnread.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
	};

	render() {
		let {
			channelConversations,
			dmConversations,

			showCreateConversation,
			conversationType,
			teamchatEnabled,
			smartInboxesEnabled,
			createInboxesEnabled,
			showEditInbox,
			showNewInbox,
			settingsInboxId,

			showTeamChatSearch,
			teamChatSearchInput,
			teamchatQueuedMessageCount,
			customerQueuedMessageCount,
			safeNumber,
			loading,

			showCreateConversationGroup,
			currentGroup,

			isUnreadOffscreen
		} = this.state;

		let hideNewMessages = UserService.get().Company.is_test_company;

		return (
			<div className="mb-navbar" onScroll={this.onNavScroll}>
				{this.renderClassyLoader()}
				{this.showCustomerConversations() && (
					<div className="mb-navbar-block mb-tour-3">
						<ReactTooltip id="customer-number" type="info" className="mb-react-tooltip" effect="solid" place="right" arrowColor="#333">
							Messages will be sent from {safeNumber}
						</ReactTooltip>
						<div className="mb-navbar-block-header">
							<div className="mb-navbar-block-header__customers">Customers</div>
							{customerQueuedMessageCount ? (
								<div className="mb-navbar-block-header__scheduled_container">
									<div className="mb-navbar-block-header__scheduled" onClick={this.onScheduledCustomerMessageClick}>
										<Icon.Clock size={16} />
										<div className="mb-navbar-block-header__scheduled__text">{customerQueuedMessageCount > 9 ? "9+" : customerQueuedMessageCount}</div>
									</div>
								</div>
							) : null}
							<div className="mb-navbar-block-header__icon" data-tip data-for="customer-number">
								<Icon.Info size="16" />
							</div>
						</div>

						<div className="mb-navbar-block-items">
							{smartInboxesEnabled && createInboxesEnabled && (
								<div className="mb-navbar-block-subheader">
									<div>BY INBOX</div>
									<div data-tip id="mb-tc-inbox" data-for="new-inbox-tooltip" className="mb-navbar-block-item-action" onClick={() => this.onShowNewInbox()}>
										<Icon.PlusCircle size="16" />
										<ReactTooltip id="new-inbox-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
											New Inbox
										</ReactTooltip>
									</div>
								</div>
							)}
							{smartInboxesEnabled && this.renderInboxes()}
							{smartInboxesEnabled && (
								<div className="mb-navbar-block-subheader">
									<div>BY OWNER</div>
								</div>
							)}
							{this.renderConversationsByOwner()}
						</div>
					</div>
				)}
				{!loading && teamchatEnabled && (
					<div className="mb-navbar-block mb-tour-7">
						{this.showCustomerConversations() && <div className="mb-navbar-block-divider" />}
						<div className="mb-navbar-block-header">
							{!showTeamChatSearch && <div className="mb-navbar-block-header__team">Team</div>}
							{!showTeamChatSearch && teamchatQueuedMessageCount ? (
								<div className="mb-navbar-block-header__scheduled" onClick={this.onScheduledTeamMessageClick}>
									<Icon.Clock size={16} />
									<div className="mb-navbar-block-header__scheduled__text">{teamchatQueuedMessageCount > 9 ? "9+" : teamchatQueuedMessageCount}</div>
								</div>
							) : null}
							{!showTeamChatSearch && (
								<div
									id="teamchat-search"
									data-tip
									data-for="teamchat-search-tooltip"
									className="mb-navbar-block-item-action"
									onClick={this.onShowTeamChatSearch}
								>
									<Icon.Search size="16" />
									<ReactTooltip id="teamchat-search-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
										Search
									</ReactTooltip>
								</div>
							)}
							{showTeamChatSearch && (
								<div className="mb-navbar-search-container">
									<Icon.Search size="16" />
									<input
										id="teamchat-search-input"
										className="mb-navbar-search-input"
										ref={ref => (this.teamChatSearch = ref)}
										value={teamChatSearchInput}
										onChange={this.onTeamChatSearchChange}
										onKeyDown={this.onTeamChatSearchKey}
										placeholder="Search ..."
									/>
									<div className="mb-navbar-block-item-action" id="hide-teamchat-search" onClick={this.onHideTeamChatSearch}>
										<Icon.X size="16" />
									</div>
								</div>
							)}
						</div>
						<div className="mb-navbar-block-items">
							<div className="mb-navbar-block-subheader mb-tour-8">
								<div>CHANNELS</div>
								<div className="mb-navbar-block-item-spacer" />
								<div
									data-tip
									id="create-channel-group"
									data-for="create-channel-group-tooltip"
									className="mb-navbar-block-item-action"
									onClick={() => this.onShowCreateConversationGroup(CONVERSATION.channel, true)}
								>
									<Icon.FolderPlus size="16" />
									<ReactTooltip id="create-channel-group-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
										New Group
									</ReactTooltip>
								</div>
								<div
									data-tip
									id="create-channel"
									data-for="channel-tooltip"
									className="mb-navbar-block-item-action"
									onClick={() => this.onShowCreateConversation(CONVERSATION.channel)}
								>
									<Icon.PlusCircle size="16" />
									<ReactTooltip id="channel-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
										New Channel
									</ReactTooltip>
								</div>
							</div>
							{this.renderChannelGroups()}
							<div className="mb-navbar-block-subheader mb-tour-9">
								<div>DIRECT</div>

								<div className="mb-navbar-block-item-spacer" />
								<div
									data-tip
									id="create-dm-group"
									data-for="create-dm-group-tooltip"
									className="mb-navbar-block-item-action"
									onClick={() => this.onShowCreateConversationGroup(CONVERSATION.dm, true)}
								>
									<Icon.FolderPlus size="16" />
									<ReactTooltip id="create-dm-group-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
										New Group
									</ReactTooltip>
								</div>
								<div
									data-tip
									id="mb-tc-nc-direct"
									data-for="direct-tooltip"
									className="mb-navbar-block-item-action"
									onClick={() => this.onShowCreateConversation(CONVERSATION.dm)}
								>
									<Icon.PlusCircle size="16" />
									<ReactTooltip id="direct-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="right">
										New DM
									</ReactTooltip>
								</div>
							</div>
							{this.renderDmGroups()}
						</div>
					</div>
				)}
				{!loading && !teamchatEnabled && (
					<>
						<div className="mb-navbar-block-divider" />
						<TeamChatDiscover onGetStartedClicked={SupportChatService.showNewMessage} />
					</>
				)}
				<CreateConversation show={showCreateConversation} type={conversationType} onClose={this.onCreateConversationClose} />
				<ManageConversationGroup
					show={showCreateConversationGroup}
					type={conversationType}
					group={currentGroup}
					onClose={this.onCreateConversationGroupClose}
				/>

				<NewInboxModal show={showNewInbox} onClose={this.onNewInboxClose} />
				<EditInboxModal show={showEditInbox} inboxId={settingsInboxId} onClose={this.onEditInboxClose} />

				{!hideNewMessages && (
					<PoseGroup flipMove={false}>
						{isUnreadOffscreen && (
							<Unread key="unread-container" className="mb-navbar__floating-unread" onClick={this.onFloatingUnreadClicked}>
								<Icon.ChevronDown size="20" />
								New Messages
							</Unread>
						)}
					</PoseGroup>
				)}
			</div>
		);
	}
}

export default Navbar;

export const WORKFLOW_RUNS_COLUMNS = {
	id: {
		id: "id",
		value: "RID",
		sortable: true,
		sortField: "id",
		width: 1
	},
	workflow_id: {
		id: "workflow_id",
		value: "WID",
		sortable: false,
		sortField: "workflow_id",
		width: 1
	},
	workflow_name: {
		id: "workflow_name",
		value: "Name",
		sortable: false,
		sortField: "workflow_name",
		width: 2
	},
	workflow_trigger: {
		id: "workflow_trigger",
		value: "Trigger",
		sortable: false,
		sortField: "workflow_trigger",
		width: 3
	},
	workflow_action: {
		id: "workflow_action",
		value: "Action",
		sortable: false,
		sortField: "workflow_action",
		width: 3
	},
	trigger_data: {
		id: "trigger_data",
		value: "Trigger Data",
		sortable: false,
		sortField: "trigger_data",
		width: 3
	},
	action_status: {
		id: "action_status",
		value: "Result",
		sortable: true,
		sortField: "action_status",
		width: 1
	},
	action_result_message: {
		id: "action_result_message",
		value: "Result Message",
		sortable: false,
		sortField: "action_result_message",
		width: 1
	},
	updated_at: {
		id: "updated_at",
		value: "Updated At",
		sortable: true,
		sortField: "updated_at",
		width: 2
	}
};

export const WORKFLOW_TABS = {
	workflows: {
		id: "workflows",
		value: "Workflows"
	},
	workflowRuns: {
		id: "workflowRuns",
		value: "Workflow Runs"
	}
};

export const WORKFLOW_RUN_STATUSES = {
	errored: "errored",
	performed: "performed",
	unperformed: "unperformed"
};

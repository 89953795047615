/**
 * This component returns a dropdrown.
 * Just pass in data, value,  callback, and custom class and enjoy
 * @prop {Array} data - Array of JSON objects (required)
 * @prop {number} value - value of selected item in dropdown (required)
 * @prop {func} onChange - callback function for onChange (required)
 * @prop {string} cClass - css class name (required)
 * @prop {object} cStyle - custom style object
 */
import React from "react";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import UserService from "../../services/UserService";

const AttributorDropdown = ({ data, value, onChange, cClass, cStyle }) => {
	return (
		<select className={cClass} style={cStyle} onChange={e => onChange(e)} value={value}>
			{data.map(aData => {
				var fullName = UserService.createFullName({ firstName: aData.first_name, lastName: aData.last_name });
				return (
					<option key={aData.id} value={aData.id}>
						{fullName}
					</option>
				);
			})}
		</select>
	);
};

Dropdown.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	cClass: PropTypes.string.isRequired,
	sStyle: PropTypes.object
};

export default AttributorDropdown;

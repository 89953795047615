import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import ReactSwitch from "react-switch";
import PropTypes from "prop-types";
import { arrayMove, SortableElement, SortableContainer } from "react-sortable-hoc";
import { withTranslation } from "react-i18next";

import GAService from "../../services/GAService";
import ReviewSiteService from "../../services/ReviewSiteService";
import LocationService from "../../services/LocationService";
import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import ReviewService from "../../services/ReviewService";

import Spinners from "../../components/common/Spinners";
import Dropdown from "../../components/common/Dropdown";
import SketchPicker from "../../components/common/ReactColorPicker/Sketch";
import Action from "../../components/common/Action";
import Modal from "../../components/common/DHModal";
import withLocation from "../../components/common/WithLocation";

import { REVIEW_SITE_IDS_BY_NAME } from "../../constants/ReviewSitesConstants";

import "../../styles/css/scenes/review-sites.css";

class ReviewSites extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			locationId: UserService.getActiveLocation().id,
			locationName: "",
			reviewSiteThreshold: 6,
			locationData: [],
			selectedSiteName: "",
			selectedSiteId: 0,
			selectedSite: {},
			showUpdateForm: false,
			selectedNewSiteId: 0,
			updateViewReviewsUrl: "",
			updatePostReviewsUrl: "",
			updateReplyReviewsUrl: "",
			updatePlaceId: "",
			updateMonitoredSiteOnly: false,
			updateReviewSiteOnly: false,
			updateCustomSiteName: "",
			createCustomSiteName: "",
			createViewReviewsUrl: "",
			createPostReviewsUrl: "",
			createReplyReviewsUrl: "",
			createDomain: "",
			createPlaceId: "",
			createIconUrl: "",
			createMonitoredSiteOnly: false,
			createReviewSiteOnly: false,
			createPageColor: "#FF003F",
			createPageFontColor: "#FFFFFF",
			createFooterColor: "#FF003F",
			createFooterFontColor: "#FF003F",
			createButtonColor: "#FF003F",
			createButtonTextColor: "#000000",
			updateButtonTextColor: "",
			updateButtonColor: "",
			updatePageColor: "",
			updatePageFontColor: "",
			updateFooterColor: "",
			updateFooterFontColor: "",
			updateDomain: "",
			updateIconUrl: "",
			addReviewModalOpen: false,
			addReviewSiteDropdown: [],
			currentReviewSites: [],
			sortedReviewSites: [],
			allReviewSites: [],
			infoMessageForViewOnly: t("Your Review Sites are shown below."),
			infoMessageForSort: t("To reorder your Review Sites, Click and Drag the items below."),
			infoMessageForAdd: t('Click the "Add New Review Site" button to get started.'),
			loading: true,
			updateReviewModalOpen: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.refreshData();
	}

	onLocationChanged = location => {
		this.setState(
			{
				locationId: UserService.getActiveLocation().id
			},
			() => {
				this.refreshData();
			}
		);
	};

	handleViewReviewsUrlOnChange(e, type) {
		this.setState({
			[type + "ViewReviewsUrl"]: e.target.value
		});
	}
	handlePostReviewsUrlOnChange(e, type) {
		this.setState({
			[type + "PostReviewsUrl"]: e.target.value
		});
	}
	handleReplyReviewsUrlOnChange(e, type) {
		this.setState({
			[type + "ReplyReviewsUrl"]: e.target.value
		});
	}
	handlePlaceIdOnChange(e, type) {
		this.setState({
			[type + "PlaceId"]: e.target.value
		});
	}
	handleCustomSiteNameOnChange(e, type) {
		this.setState({ [type + "CustomSiteName"]: e.target.value });
	}
	handleReviewSiteOnClick(value) {
		const selectedSiteName =
			value.site_id > REVIEW_SITE_IDS_BY_NAME.custom ? value.custom_site_name : ReviewSiteService.getSiteNameFromId(value.site_id, value.custom_site_name);
		this.setState(
			{
				selectedSiteName,
				selectedSiteId: value.site_id,
				selectedSite: value,
				updateReviewModalOpen: true
			},
			() => {
				const selectedReview = this.state.currentReviewSites.filter(aCurrentReviewSite => {
					if (aCurrentReviewSite.custom_site_id) {
						return aCurrentReviewSite.custom_site_id === this.state.selectedSiteId;
					}
					return aCurrentReviewSite.site_id === this.state.selectedSiteId;
				});
				if (selectedReview.length > 0) {
					this.setState({
						updateViewReviewsUrl: selectedReview[0].view_reviews_url,
						updatePostReviewsUrl: selectedReview[0].post_reviews_url,
						updateReplyReviewsUrl: selectedReview[0].reply_reviews_url,
						updatePlaceId: selectedReview[0].place_id,
						updateMonitoredSiteOnly: !selectedReview[0].monitored_site ? false : true,
						updateReviewSiteOnly: !selectedReview[0].review_site_only ? false : true,
						updateCustomSiteName: selectedSiteName,
						updatePageColor: selectedReview[0].page_bcolor,
						updatePageFontColor: selectedReview[0].page_color,
						updateFooterColor: selectedReview[0].footer_bcolor,
						updateFooterFontColor: selectedReview[0].footer_color,
						updateButtonColor: selectedReview[0].button_bcolor,
						updateButtonTextColor: selectedReview[0].button_color,
						updateDomain: selectedReview[0].domain,
						updateIconUrl: selectedReview[0].icon_url || ""
					});
				}
			}
		);
	}
	handlePanelOnClose() {
		this.setState({
			selectedSiteId: 0,
			selectedSiteName: "",
			viewReviewsUrl: "",
			postReviewsUrl: "",
			replyReviewsUrl: "",
			placeId: "",
			addReviewModalOpen: false
		});
	}
	cancelAddReviewSite(event) {
		event.preventDefault();
		this.handlePanelOnClose();
	}
	initiateStateValues() {
		this.setState({
			selectedSiteName: "",
			locationName: "",
			selectedSiteId: 0,
			showUpdateForm: false,
			selectedNewSiteId: 0,
			updateViewReviewsUrl: "",
			updatePostReviewsUrl: "",
			updateReplyReviewsUrl: "",
			updatePlaceId: "",
			createViewReviewsUrl: "",
			createPostReviewsUrl: "",
			createReplyReviewsUrl: "",
			createPlaceId: "",
			updateCustomSiteName: "",
			createCustomSiteName: "",
			selectedNewSite: {},
			createMonitoredSiteOnly: false,
			createReviewSiteOnly: false
		});
	}
	refreshData() {
		this.initiateStateValues();
		this.updateReviewDropdownData();
		this.updateAllReviewsData();
		this.getLocationData();
	}

	async updateAllReviewsData() {
		try {
			let data = await ReviewService.getAllReviewSites();

			if (!data) {
				return;
			}

			this.setState({ allReviewSites: data });
		} catch (error) {
			console.log(error);
		}
	}

	async updateReviewDropdownData() {
		const { locationId } = this.state;

		let data = await ReviewService.getReviewSitesNotInLocation({ locationId });

		if (!data) {
			return;
		}

		data = data.filter(aData => aData.company_id === null);

		this.setState({ addReviewSiteDropdown: data });

		if (data.length > 0) {
			this.setState({ selectedNewSiteId: data[0].id });
		}
	}
	async getLocationData() {
		const { locationId } = this.state;

		let data = await LocationService.fetchLocation(locationId);

		if (!data) {
			this.setState({ loading: false });
			return;
		}

		this.setState({ locationData: data });

		const currentReviewSites = data.review_sites === "" ? [] : JSON.parse(data.review_sites);

		this.setState({
			currentReviewSites,
			sortedReviewSites: currentReviewSites,
			locationName: data.name,
			loading: false
		});
	}

	async handleOnLocationUpdateClick(type, event) {
		if (event) {
			event.preventDefault();
		}
		const authToken = UserService.get() ? UserService.get().auth_token : "";

		const { currentReviewSites, locationId } = this.state;
		const newLocationData = {};
		let newCurrentReviewSites = {};

		if (type === "remove") {
			const { selectedSiteId } = this.state;
			newCurrentReviewSites = currentReviewSites.filter(aCurrentReviewSite => {
				if (aCurrentReviewSite.custom_site_id) {
					return aCurrentReviewSite.custom_site_id !== selectedSiteId;
				}
				return aCurrentReviewSite.site_id !== selectedSiteId;
			});
		} else if (type === "update") {
			const {
				updateViewReviewsUrl,
				updatePostReviewsUrl,
				updateReplyReviewsUrl,
				updatePlaceId,
				updateMonitoredSiteOnly,
				updateReviewSiteOnly,
				updateCustomSiteName,
				updateFooterColor,
				updatePageColor,
				updateFooterFontColor,
				updatePageFontColor,
				updateDomain,
				updateIconUrl,
				selectedSite,
				updateButtonColor,
				updateButtonTextColor
			} = this.state;

			newCurrentReviewSites.site_id = selectedSite.site_id;
			newCurrentReviewSites.view_reviews_url = updateViewReviewsUrl || "";
			newCurrentReviewSites.post_reviews_url = updatePostReviewsUrl || "";
			newCurrentReviewSites.reply_reviews_url = updateReplyReviewsUrl || "";
			newCurrentReviewSites.place_id = updatePlaceId || "";
			newCurrentReviewSites.monitored_site = updateMonitoredSiteOnly || false;
			newCurrentReviewSites.review_site_only = updateReviewSiteOnly || false;
			if (selectedSite.site_id > REVIEW_SITE_IDS_BY_NAME.custom) {
				newCurrentReviewSites.custom_site_name = updateCustomSiteName;
				newCurrentReviewSites.is_custom = true;
				newCurrentReviewSites.page_color = updatePageFontColor;
				newCurrentReviewSites.page_bcolor = updatePageColor;
				newCurrentReviewSites.footer_color = updateFooterFontColor;
				newCurrentReviewSites.footer_bcolor = updateFooterColor;
				newCurrentReviewSites.button_color = updateButtonTextColor;
				newCurrentReviewSites.button_bcolor = updateButtonColor;
				newCurrentReviewSites.domain = updateDomain;
				newCurrentReviewSites.icon_url = updateIconUrl;
			}
		} else if (type === "create") {
			const {
				selectedNewSiteId,
				createViewReviewsUrl,
				createPostReviewsUrl,
				createReplyReviewsUrl,
				createPlaceId,
				createMonitoredSiteOnly,
				createReviewSiteOnly,
				createCustomSiteName,
				createFooterColor,
				createFooterFontColor,
				createPageColor,
				createPageFontColor,
				createDomain,
				createIconUrl,
				createButtonColor,
				createButtonTextColor
			} = this.state;
			const data = {
				site_id: selectedNewSiteId,
				view_reviews_url: createViewReviewsUrl || "",
				post_reviews_url: createPostReviewsUrl || "",
				reply_reviews_url: createReplyReviewsUrl || "",
				place_id: createPlaceId || "",
				monitored_site: createMonitoredSiteOnly || false,
				review_site_only: createReviewSiteOnly || false
			};
			if (selectedNewSiteId === REVIEW_SITE_IDS_BY_NAME.custom) {
				data.name = createCustomSiteName;
				data.page_color = createPageFontColor;
				data.page_bcolor = createPageColor;
				data.footer_color = createFooterFontColor;
				data.footer_bcolor = createFooterColor;
				data.button_color = createButtonTextColor;
				data.button_bcolor = createButtonColor;
				data.domain = createDomain;
				data.icon_url = createIconUrl;
				data.is_custom = true;
				delete data.site_id;
				await this.addCustomReviewSite(data);
				return;
			}
			if (selectedNewSiteId && selectedNewSiteId !== 0) {
				newCurrentReviewSites = data;
			}
			type = "update";
		} else if (type === "reorder") {
			const { sortedReviewSites } = this.state;
			newCurrentReviewSites = sortedReviewSites;
		}
		newLocationData.review_sites = JSON.stringify(newCurrentReviewSites);

		// Todo Undo gunda ness
		Kichiri.review_sites[type]({ locationId, review_sites: newLocationData.review_sites }, {}, authToken)
			.then(() => {
				this.setState({ addReviewModalOpen: false, updateReviewModalOpen: false, selectedSiteName: "" }, () => {
					this.refreshData();
				});
			})
			.catch(error => {
				console.log(error);
				this.setState({ addReviewModalOpen: false, updateReviewModalOpen: false }, () => {
					this.refreshData();
				});
			});
	}
	async addCustomReviewSite(reviewSite) {
		const { locationId } = this.state;
		reviewSite.company_id = UserService.getActiveCompany().id;
		reviewSite.locationId = parseInt(locationId, 10);

		let success = await ReviewService.createReviewSite(reviewSite);

		if (!success) {
			return;
		}

		this.setState({ addReviewModalOpen: false, updateReviewModalOpen: false, selectedSiteName: "" }, () => {
			this.refreshData();
		});
	}

	renderCurrentReviewSiteButtons() {
		const { allReviewSites, sortedReviewSites } = this.state;
		let { t } = this.props;

		if (allReviewSites.length > 0) {
			return (
				<SortableGrid
					items={sortedReviewSites}
					onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd({ oldIndex, newIndex })}
					handleCB={e => this.handleReviewSiteOnClick(e)}
					allReviewSites={allReviewSites}
					helperClass="SortableContainer__helperClass"
					lockAxis="y"
					permission={UserService.get().GroupPermission}
					t={t}
				/>
			);
		}
	}
	onSortEnd({ oldIndex, newIndex }) {
		const { sortedReviewSites } = this.state;
		this.setState(
			{
				sortedReviewSites: arrayMove(sortedReviewSites, oldIndex, newIndex)
			},
			() => {
				this.handleOnLocationUpdateClick("reorder");
			}
		);
	}
	handleMonitoredSiteOnlyOnChange(type) {
		this.setState({ [type + "MonitoredSiteOnly"]: !this.state[type + "MonitoredSiteOnly"] }, () => {
			if (this.state[type + "MonitoredSiteOnly"]) {
				this.setState({ [type + "ReviewSiteOnly"]: false });
			}
		});
	}
	handleReviewSiteOnlyOnChange(type) {
		this.setState({ [type + "ReviewSiteOnly"]: !this.state[type + "ReviewSiteOnly"] }, () => {
			if (this.state[type + "ReviewSiteOnly"]) {
				this.setState({ [type + "MonitoredSiteOnly"]: false });
			}
		});
	}
	handlePageColorOnChange(color, type) {
		this.setState({
			[type + "PageColor"]: color
		});
	}
	handlePageFontColorOnChange(color, type) {
		this.setState({
			[type + "PageFontColor"]: color
		});
	}
	handleFooterColorOnChange(color, type) {
		this.setState({
			[type + "FooterColor"]: color
		});
	}
	handleFooterFontColorOnChange(color, type) {
		this.setState({
			[type + "FooterFontColor"]: color
		});
	}
	handleButtonColorOnChange(color, type) {
		this.setState({
			[type + "ButtonColor"]: color
		});
	}
	handleButtonTextColorOnChange(color, type) {
		this.setState({
			[type + "ButtonTextColor"]: color
		});
	}
	handleDomainOnChange(e, type) {
		this.setState({
			[type + "Domain"]: e.target.value
		});
	}
	handleIconUrlOnChange(e, type) {
		this.setState({
			[type + "IconUrl"]: e.target.value
		});
	}
	renderSiteReviewForm(type) {
		const { addReviewSiteDropdown, selectedNewSiteId, selectedSite, addReviewModalOpen, updateReviewModalOpen } = this.state;
		let { t } = this.props;

		return (
			<div className="modal__flex-container">
				{type === "create" && [
					<div className="modal__field">{t("Review Site")}</div>,
					<Dropdown
						id="review_site_select"
						cClass="form-control fnctst-reviewsite-dropdown"
						data={addReviewSiteDropdown}
						value={selectedNewSiteId}
						onChange={e => this.handleNewSiteDropdownOnClick(e)}
					/>
				]}

				{/* For google and facebook, disable all the non required text boxes */}
				{((updateReviewModalOpen &&
					selectedSite.site_id !== REVIEW_SITE_IDS_BY_NAME.google &&
					selectedSite.site_id !== REVIEW_SITE_IDS_BY_NAME.facebook &&
					selectedSite.site_id !== REVIEW_SITE_IDS_BY_NAME.direct) ||
					(addReviewModalOpen &&
						selectedNewSiteId !== REVIEW_SITE_IDS_BY_NAME.google &&
						selectedNewSiteId !== REVIEW_SITE_IDS_BY_NAME.facebook &&
						selectedNewSiteId !== REVIEW_SITE_IDS_BY_NAME.direct)) && (
					<>
						{((addReviewModalOpen && selectedNewSiteId >= REVIEW_SITE_IDS_BY_NAME.custom) ||
							(updateReviewModalOpen && selectedSite.site_id >= REVIEW_SITE_IDS_BY_NAME.custom)) && (
							<>
								<div className="modal__field">{t("Name")}</div>
								<input
									id="custom-site-name"
									type="text"
									value={this.state[type + "CustomSiteName"]}
									onChange={e => {
										this.handleCustomSiteNameOnChange(e, type);
									}}
									className="form-control"
									required="required"
								/>
								<span className="modal__text--faded">({t("For better readability, add a unique name for Custom Review Sites")})</span>
							</>
						)}
						<div className="modal__field">{t("View Reviews URL")}</div>
						<input
							id="view-reviews-url"
							type="url"
							value={this.state[type + "ViewReviewsUrl"]}
							onChange={e => {
								this.handleViewReviewsUrlOnChange(e, type);
							}}
							className="form-control"
							required={
								(addReviewModalOpen &&
									selectedNewSiteId !== REVIEW_SITE_IDS_BY_NAME.google &&
									addReviewModalOpen &&
									selectedNewSiteId !== REVIEW_SITE_IDS_BY_NAME.facebook) ||
								(updateReviewModalOpen && selectedSite.site_id !== REVIEW_SITE_IDS_BY_NAME.facebook && selectedSite.site_id !== REVIEW_SITE_IDS_BY_NAME.google)
							}
						/>
						<div className="modal__field">{t("Post Reviews URL")}</div>
						<input
							id="reviewsUrl"
							type="url"
							value={this.state[type + "PostReviewsUrl"]}
							onChange={e => this.handlePostReviewsUrlOnChange(e, type)}
							className="form-control"
							required={
								(addReviewModalOpen && selectedNewSiteId > REVIEW_SITE_IDS_BY_NAME.custom) ||
								(updateReviewModalOpen && selectedSite.site_id > REVIEW_SITE_IDS_BY_NAME.custom)
							}
						/>
						<div className="modal__field">{t("Reply Reviews URL")}</div>
						<input
							id="replyUrl"
							type="url"
							value={this.state[type + "ReplyReviewsUrl"]}
							onChange={e => this.handleReplyReviewsUrlOnChange(e, type)}
							className="form-control"
						/>
						<div className="modal__field">{t("Place ID")}</div>
						<input
							id="place-id"
							type="text"
							onChange={e => this.handlePlaceIdOnChange(e, type)}
							value={this.state[type + "PlaceId"]}
							className="form-control"
						/>
						{((addReviewModalOpen && selectedNewSiteId >= REVIEW_SITE_IDS_BY_NAME.custom) ||
							(updateReviewModalOpen && selectedSite.site_id >= REVIEW_SITE_IDS_BY_NAME.custom)) && (
							<>
								<div className="modal__field">{t("Configure how this Review Site will look on Share Comments")}</div>
								<div className="modal__field__row">
									<div className="modal__field modal__field__row__item ReviewSites__color-config fnctst-review-site-colour">{t("Page Color")}</div>
									<SketchPicker disableAlpha={true} value={this.state[type + "PageColor"]} handleChange={color => this.handlePageColorOnChange(color, type)} />
								</div>
								<div className="modal__field__row">
									<div className="modal__field__row__item ReviewSites__color-config modal__field fnctst-review-site-colour">{t("Page Font Color")}</div>
									<SketchPicker
										disableAlpha={true}
										value={this.state[type + "PageFontColor"]}
										handleChange={color => this.handlePageFontColorOnChange(color, type)}
									/>
								</div>
								<div className="modal__field__row">
									<div className="modal__field__row__item ReviewSites__color-config modal__field fnctst-review-site-colour">{t("Footer Color")}</div>
									<SketchPicker
										disableAlpha={true}
										value={this.state[type + "FooterColor"]}
										handleChange={color => this.handleFooterColorOnChange(color, type)}
									/>
								</div>
								<div className="modal__field__row">
									<div className="ReviewSites__color-config modal__field modal__field__row__item fnctst-review-site-colour">{t("Footer Font Color")}</div>
									<SketchPicker
										disableAlpha={true}
										value={this.state[type + "FooterFontColor"]}
										handleChange={color => this.handleFooterFontColorOnChange(color, type)}
									/>
								</div>
								<div className="modal__field__row">
									<div className="ReviewSites__color-config modal__field modal__field__row__item fnctst-review-site-colour">{t("Button Color")}</div>
									<SketchPicker
										disableAlpha={true}
										value={this.state[type + "ButtonColor"]}
										handleChange={color => this.handleButtonColorOnChange(color, type)}
									/>
								</div>
								<div className="modal__field__row">
									<div className="ReviewSites__color-config modal__field modal__field__row__item fnctst-review-site-colour">{t("Button Text Color")}</div>
									<SketchPicker
										disableAlpha={true}
										value={this.state[type + "ButtonTextColor"]}
										handleChange={color => this.handleButtonTextColorOnChange(color, type)}
									/>
								</div>
								<div className="modal__field">{t("Domain Name")}</div>
								<input
									id="domain-name"
									type="url"
									onChange={e => this.handleDomainOnChange(e, type)}
									value={this.state[type + "Domain"]}
									className="form-control"
								/>
								<div className="modal__field">{t("Icon URL")}</div>
								<input
									id="icon-url"
									type="url"
									onChange={e => this.handleIconUrlOnChange(e, type)}
									value={this.state[type + "IconUrl"]}
									className="form-control"
								/>
							</>
						)}
					</>
				)}
				<div className="modal__field">{t("Monitored Only")}</div>
				<ReactSwitch
					id="monitor-only"
					onChange={() => this.handleMonitoredSiteOnlyOnChange(type)}
					checked={this.state[type + "MonitoredSiteOnly"]}
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#4A90E2"
					disabled={false}
				/>
				<div className="modal__field">{t("Review Only")}</div>
				<ReactSwitch
					id="review-only"
					onChange={() => this.handleReviewSiteOnlyOnChange(type)}
					checked={this.state[type + "ReviewSiteOnly"]}
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#4A90E2"
					disabled={false}
				/>
				<div className="modal__actions">
					<div id={type === "update" ? "updateSite" : "addSite"} className="mb-button" onClick={e => this.handleOnLocationUpdateClick(type, e)}>
						{type === "update" ? t("Update") : t("Create")}
					</div>
					{type === "update" && (
						<div id="delete" className="mb-button mb-button--cancel" onClick={e => this.handleOnLocationUpdateClick("remove", e)}>
							{t("Delete")}
						</div>
					)}
				</div>
			</div>
		);
	}
	handleNewSiteDropdownOnClick(e) {
		this.setState({
			selectedNewSiteId: parseInt(e.target.value, 10)
		});
	}

	render() {
		const {
			updateReviewModalOpen,
			selectedSiteName,
			infoMessageForSort,
			infoMessageForViewOnly,
			currentReviewSites,
			infoMessageForAdd,
			addReviewModalOpen
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();

		if (this.state.loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners type="tail-fade" color="" size="120px" loading={true} />
				</div>
			);
		}

		return (
			<>
				<div className="ReviewSites__header">
					<div>
						<p className="ReviewSites__header__message">
							<Icon.Info size="16" />
							{user.GroupPermission.reorder_review_sites && currentReviewSites.length >= 2
								? infoMessageForSort
								: user.GroupPermission.update_review_sites && currentReviewSites.length < 2
								? infoMessageForAdd
								: infoMessageForViewOnly}
						</p>
					</div>
					{user.GroupPermission.create_review_sites && (
						<div className="ReviewSites__header__actions">
							<Action id="create-site" label={t("Add Review Site")} icon={Icon.Plus} onClick={() => this.setState({ addReviewModalOpen: true })} />
						</div>
					)}
				</div>
				<div className="Common__flex-grow">
					<div>{this.renderCurrentReviewSiteButtons()}</div>
					<Modal show={updateReviewModalOpen} onHide={() => this.setState({ updateReviewModalOpen: false })} title={t("Update Review Site")}>
						{this.renderSiteReviewForm("update")}
					</Modal>
					<Modal show={addReviewModalOpen} onHide={() => this.setState({ addReviewModalOpen: false })} title={t("Add New Review Site")}>
						{this.renderSiteReviewForm("create")}
					</Modal>
				</div>
			</>
		);
	}
}

const SortableGrid = SortableContainer(({ items, handleCB, permission, t }) => {
	return (
		<ul id="site-list" className="ReviewSites__sites-list">
			{items.map((anItem, index) => (
				<div key={`item-${index}-block`} className="ReviewSites__block">
					<SortableItem key={`item-${index}`} index={index} value={anItem} handleCB={handleCB} permission={permission} t={t} />
				</div>
			))}
		</ul>
	);
});

const SortableItem = SortableElement(({ value, handleCB, permission, t }) => {
	return [
		<li
			key={"sort-buttons-" + value.site_id}
			id={"id_" + value.site_id}
			className={"SortButtons__li--buttons " + (!value.is_custom ? ReviewSiteService.getReviewSiteStyleClass(value.site_id) : "")}
			style={{ backgroundColor: value.page_bcolor, color: value.page_color }}
			onClick={handleCB}
		>
			{value.site_id > REVIEW_SITE_IDS_BY_NAME.custom ? value.custom_site_name : ReviewSiteService.getSiteNameFromId(value.site_id, value.custom_site_name)}{" "}
			<span className="label label-danger pull-right ReviewSites__buttons--condensed">
				{" "}
				{value.monitored_site ? t("Monitored Only") : value.review_site_only ? t("Review Only") : t("Review + Monitor")}
			</span>
		</li>,
		permission.update_review_sites && (
			<span
				key={"edit-button-" + value.site_id}
				role="button"
				id={`editButton-${value.site_id}`}
				className="ReviewSites__block--edit"
				onClick={() => handleCB(value)}
			>
				<i className="fa fa-edit fa-2x" />
			</span>
		)
	];
});

ReviewSites.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			locationId: PropTypes.string
		})
	}),
	location: PropTypes.shape({
		pathname: PropTypes.string
	}),
	handleChangeLocation: PropTypes.func
};
export default withRouter(withLocation(withTranslation(null, { withRef: true })(ReviewSites)));

import React from "react";
import ReactSwitch from "react-switch";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import WorkflowService from "../../services/WorkflowService";
import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";

import { DAYS_OF_WEEK } from "../../constants/CommonConstants";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import Alert from "../../components/common/Alert";
import withLocation from "../../components/common/WithLocation";

import ManageCustomAutoRepliesModal from "./ManageCustomAutoRepliesModal";
import OfficeAutoRepliesModal from "./OfficeAutoRepliesModal";

import "react-datepicker/dist/react-datepicker.css";
import "../../styles/css/scenes/auto-replies.css";

class AutoReplies extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			statusSelected: "all",
			afterHoursWorkflow: null,
			businessHoursWorkflow: null,
			customHourWorkflows: [],
			showManageOfficeAutoRepliesModal: false,
			showManageCustomAutoReplyModal: false,
			selectedCustomAutoResponse: null,
			showDeleteWorkflowAlert: false
		};

		this.workflowConstant = null;
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.setup();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = () => {
		this.setup();
	};

	setup = async () => {
		this.workflowConstant = await WorkflowService.getCachedWorkflowsConfig();
		this.fetchAutoReplies();
	};

	updateAfterHoursStatus = async checked => {
		let { afterHoursWorkflow } = this.state;
		let { t } = this.props;

		afterHoursWorkflow.status = checked ? "active" : "inactive";

		let success = await WorkflowService.updateAutoReply({ workflowId: afterHoursWorkflow.id, status: afterHoursWorkflow.status });

		if (!success) {
			ToastService.info(t("An error occurred, please try again."));
			return;
		}

		await this.update({ afterHoursWorkflow });
	};

	updateBusinessHoursStatus = async checked => {
		let { businessHoursWorkflow } = this.state;
		let { t } = this.props;

		businessHoursWorkflow.status = checked ? "active" : "inactive";

		let success = await WorkflowService.updateAutoReply({ workflowId: businessHoursWorkflow.id, status: businessHoursWorkflow.status });

		if (!success) {
			ToastService.info(t("An error occurred, please try again."));
			return;
		}

		await this.update({ businessHoursWorkflow });
	};

	updateCustomHoursStatus = async (checked, index) => {
		let { customHourWorkflows } = this.state;
		let { t } = this.props;

		let workflow = customHourWorkflows[index];
		workflow.status = checked ? "active" : "inactive";

		let success = await WorkflowService.updateAutoReply({ workflowId: workflow.id, status: workflow.status });

		if (!success) {
			ToastService.info(t("An error occurred, please try again."));
			return;
		}

		await this.update({ workflow });
	};

	fetchAutoReplies = async () => {
		if (!this.workflowConstant) {
			return;
		}
		const { statusSelected } = this.state;
		await this.update({ loading: true });

		let workflows = await WorkflowService.fetchAutoReplies({
			locationId: UserService.getActiveLocation().id,
			status: [statusSelected]
		});

		if (!workflows) {
			return;
		}

		let afterHoursWorkflow = null;
		let businessHoursWorkflow = null;
		let customHourWorkflows = [];

		for (let i = 0; i < workflows.length; i++) {
			const workflow = workflows[i];
			if (workflow.conditions.find(condition => condition.type === this.workflowConstant.conditions.isAfterHours)) {
				afterHoursWorkflow = workflow;
			} else if (workflow.conditions.find(condition => condition.type === this.workflowConstant.conditions.isBusinessHours)) {
				businessHoursWorkflow = workflow;
			} else if (workflow.conditions.find(condition => condition.type === this.workflowConstant.conditions.isCustomHours)) {
				customHourWorkflows.push(workflow);
			}
		}

		await this.update({ data: workflows, afterHoursWorkflow, businessHoursWorkflow, customHourWorkflows, loading: false });
	};

	onHide = () => {
		this.setState({ showManageOfficeAutoRepliesModal: false });
	};

	onAfterHoursResponseChange = async e => {
		let { afterHoursWorkflow } = this.state;
		afterHoursWorkflow.MessageTemplate.msg_text = e.target.value;
		await this.update({ afterHoursWorkflow });
	};

	onDuringHoursResponseChange = async e => {
		let { businessHoursWorkflow } = this.state;
		businessHoursWorkflow.MessageTemplate.msg_text = e.target.value;
		await this.update({ businessHoursWorkflow });
	};

	async updateAutoReplies() {
		let { afterHoursWorkflow, businessHoursWorkflow } = this.state;
		await WorkflowService.updateAutoReply({
			workflowId: afterHoursWorkflow.id,
			response: afterHoursWorkflow.MessageTemplate.msg_text,
			status: afterHoursWorkflow.status
		});
		await WorkflowService.updateAutoReply({
			workflowId: businessHoursWorkflow.id,
			response: businessHoursWorkflow.MessageTemplate.msg_text,
			status: businessHoursWorkflow.status
		});
		await this.update({ showManageOfficeAutoRepliesModal: false });
	}

	saveCustomHours = async () => {
		await this.update({
			selectedCustomAutoResponse: null,
			showManageCustomAutoReplyModal: false
		});
		await this.fetchAutoReplies();
	};

	async showDeleteWorkflowAlert(workflow) {
		await this.update({ showDeleteWorkflowAlert: true, selectedCustomAutoResponse: workflow });
	}

	async deleteWorkflow(confirmed) {
		let { selectedCustomAutoResponse } = this.state;
		let { t } = this.props;

		if (confirmed) {
			await WorkflowService.deleteAutoReply(selectedCustomAutoResponse.id);
			ToastService.info(t(`Auto reply deleted.`));
			await this.fetchAutoReplies();
		}
		await this.update({ showDeleteWorkflowAlert: false, selectedCustomAutoResponse: null });
	}

	renderOfficeAutoReplies() {
		let { afterHoursWorkflow, businessHoursWorkflow, showManageOfficeAutoRepliesModal } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_auto_replies;

		return (
			<div className="auto-replies__box fnctst-office-replies-container">
				<div className="auto-replies__box__text">{t("Office Hours Auto Replies")}</div>
				<p>
					{t(
						"Use this feature to send an auto reply based on when your business is open or closed. Individuals will only receive these messages once every 24 hours to prevent interrupting the flow of conversation."
					)}
				</p>
				{afterHoursWorkflow && (
					<div className="auto-replies__box__info__container">
						<div className="auto-replies__box__info">
							<div className="auto-replies__box__info__toggle">
								<ReactSwitch
									id="after-office-hours"
									className="auto-replies__box__info__toggle__item fnctst-office-after-switch"
									checked={afterHoursWorkflow.status === "active" ? true : false}
									onChange={checked => this.updateAfterHoursStatus(checked)}
									height={25}
									disabled={!allowUpdate}
									onColor="#60A9FF"
									offColor="#c5c5c5"
								/>
								<div className="auto-replies__box__info__toggle__item auto-replies__box__info__toggle__text">
									{afterHoursWorkflow.status === "active" ? t("On") : t("Off")}
								</div>
								<div className="auto-replies__box__info__toggle__item auto-replies__box__info__toggle__text">{t("After Office Hours")}</div>
							</div>
							<div className="auto-replies__box__info__actions">
								{allowUpdate && (
									<Action
										id="edit-after-hours-response"
										label={t("Edit Response")}
										icon={Icon.Edit3}
										onClick={() => this.setState({ showManageOfficeAutoRepliesModal: true })}
									/>
								)}
							</div>
						</div>
						<div className="auto-replies__reply">{afterHoursWorkflow.MessageTemplate ? afterHoursWorkflow.MessageTemplate.msg_text : ""}</div>
					</div>
				)}

				{businessHoursWorkflow && (
					<div className="auto-replies__box__info__container">
						<div className="auto-replies__box__info">
							<div className="auto-replies__box__info__toggle">
								<ReactSwitch
									id="after-office-hours"
									className="auto-replies__box__info__toggle__item fnctst-office-during-switch"
									checked={businessHoursWorkflow.status === "active" ? true : false}
									onChange={checked => this.updateBusinessHoursStatus(checked)}
									height={25}
									disabled={!allowUpdate}
									onColor="#60A9FF"
									offColor="#c5c5c5"
								/>
								<div className="auto-replies__box__info__toggle__item auto-replies__box__info__toggle__text">
									{businessHoursWorkflow.status === "active" ? t("On") : t("Off")}
								</div>
								<div className="auto-replies__box__info__toggle__item auto-replies__box__info__toggle__text">{t("During Office Hours")}</div>
							</div>
							<div className="auto-replies__box__info__actions">
								{allowUpdate && (
									<Action
										id="edit-during-hours-response"
										label={t("Edit Response")}
										icon={Icon.Edit3}
										onClick={() => this.setState({ showManageOfficeAutoRepliesModal: true })}
									/>
								)}
							</div>
						</div>
						<div className="auto-replies__reply">{businessHoursWorkflow.MessageTemplate ? businessHoursWorkflow.MessageTemplate.msg_text : ""}</div>
					</div>
				)}

				<OfficeAutoRepliesModal
					show={showManageOfficeAutoRepliesModal}
					onHide={() => this.onHide()}
					afterHoursWorkflow={afterHoursWorkflow}
					businessHoursWorkflow={businessHoursWorkflow}
					onAfterHoursResponseChange={this.onAfterHoursResponseChange}
					onDuringHoursResponseChange={this.onDuringHoursResponseChange}
				/>
			</div>
		);
	}

	renderCustomAutoReplies() {
		let { customHourWorkflows } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowCreate = user.GroupPermission.create_auto_replies;
		let allowUpdate = user.GroupPermission.update_auto_replies;
		let allowDelete = user.GroupPermission.delete_auto_replies;

		return (
			<div className="auto-replies__box auto-replies__box--custom-replies fnctst-custom-replies-container">
				<div className="auto-replies__box__header">
					<div className="auto-replies__box__header__text">{t("General Auto Replies")}</div>
					{allowCreate && (
						<div className="mb-button" onClick={() => this.setState({ showManageCustomAutoReplyModal: true })}>
							{t("Add Auto Reply")}
						</div>
					)}
				</div>
				<p>
					{t(
						"Add auto replies based on specific times and days you select. Individuals will only receive these messages once every 24 hours to prevent interrupting the flow of conversation."
					)}
				</p>
				{customHourWorkflows &&
					customHourWorkflows.map((workflow, index) => {
						return (
							<div key={workflow.id} className="auto-replies__box__info__container">
								<div className="auto-replies__box__info">
									<div className="auto-replies__box__info__toggle">
										<ReactSwitch
											id={`customers-hours-${workflow.id}`}
											className="auto-replies__box__info__toggle__item"
											checked={workflow.status === "active" ? true : false}
											onChange={checked => this.updateCustomHoursStatus(checked, index)}
											height={25}
											disabled={!allowUpdate}
											onColor="#60A9FF"
											offColor="#c5c5c5"
										/>
										<div className="auto-replies__box__info__toggle__item auto-replies__box__info__toggle__text">
											{workflow.status === "active" ? "On" : "Off"}
										</div>
										<div className="auto-replies__box__info__toggle__item auto-replies__box__info__toggle__text fnctst-auto-reply-name">{workflow.name}</div>
									</div>
									<div className="auto-replies__box__info__actions">
										{allowUpdate && (
											<Action
												id={`edit-custom-hours-response-${workflow.id}`}
												label={t("Edit Response")}
												icon={Icon.Edit3}
												onClick={() => this.setState({ showManageCustomAutoReplyModal: true, selectedCustomAutoResponse: workflow })}
											/>
										)}
										{allowDelete && (
											<Action
												id={`delete-custom-hours-response-${workflow.id}`}
												label={t("Delete Auto Response")}
												icon={Icon.Trash2}
												onClick={() => this.showDeleteWorkflowAlert(workflow)}
											/>
										)}
									</div>
								</div>
								<div className="auto-replies__reply">{workflow.MessageTemplate ? workflow.MessageTemplate.msg_text : ""}</div>
								{this.renderHours(workflow)}
							</div>
						);
					})}
			</div>
		);
	}

	renderHours(workflow) {
		let { t } = this.props;

		let days = "";
		let startTime = "";
		let endTime = "";

		if (!this.workflowConstant) {
			return null;
		}

		// Get the selected days, and the times
		try {
			let condition = workflow.conditions.find(c => c.type === this.workflowConstant.conditions.isCustomHours);
			let orderedDays = Object.keys(DAYS_OF_WEEK).map(k => DAYS_OF_WEEK[k].value);
			days = orderedDays.filter(d => condition.params.days.includes(d));
			startTime = condition.params.start_time;
			endTime = condition.params.end_time;
		} catch (error) {
			console.log(`error: `, error);
			return null;
		}
		return (
			<div className="auto-replies__view-hours">
				{t("Sends")} {days.map((day, i) => `${day.substring(0, 3)}${days.length === i + 1 ? "" : ", "}`)} {startTime} - {endTime}
			</div>
		);
	}

	render() {
		let { showManageCustomAutoReplyModal, selectedCustomAutoResponse } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Auto Replies")} />
				<div className="auto-replies">
					{this.renderOfficeAutoReplies()}
					{this.renderCustomAutoReplies()}
					<ManageCustomAutoRepliesModal
						show={showManageCustomAutoReplyModal}
						onHide={() => this.setState({ showManageCustomAutoReplyModal: false, selectedCustomAutoResponse: null })}
						selectedWorkflow={selectedCustomAutoResponse}
						onSave={this.saveCustomHours}
					/>
					<Alert
						type="info"
						show={this.state.showDeleteWorkflowAlert}
						title={t("Are you sure?")}
						confirm={t("Yes")}
						cancel={t("Cancel")}
						onClose={confirmed => this.deleteWorkflow(confirmed)}
					>
						<div>{t("Are you sure you want to delete the Auto Response?")}</div>
					</Alert>
				</div>
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(AutoReplies)));

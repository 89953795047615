import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import Kichiri from "../../services/KichiriService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

import CRMUpdateModal from "./CRMUpdateModal";
import Alert from "../../components/common/Alert";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import List from "../../components/common/List";
import SearchInput from "../../components/common/SearchInput";

import GAService from "../../services/GAService";
import { SORT_ORDER } from "../../constants/CommonConstants";
import { CRM_INTEGRATIONS, CRM_INTEGRATION_COLUMNS } from "../../constants/CRMIntegration";

import withLocation from "../../components/common/WithLocation";

import "../../styles/css/components/commons/react-table.css";

import "./CRMIntegrations.css";

class CRMIntegrations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loadedAll: false,
			data: [],
			searchTerm: "",
			limitDefault: 25,
			limit: 25,
			pageSize: 50,
			sortField: CRM_INTEGRATION_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			locationId: UserService.getActiveLocation().id,
			success: false,
			hasError: false,
			infoText: "",
			errorText: "",
			createMode: false,
			currentToUpdateRow: undefined,
			triggerRow: null,
			hasWarning: false,
			warningText: ""
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = location => {
		let locationId = UserService.getActiveLocation().id;

		this.setState(
			{
				locationId
			},
			() => {
				this.fetchData();
			}
		);
	};

	async fetchData() {
		try {
			let { locationId, searchTerm, sortField, sortOrder, limit } = this.state;

			await this.update({ loading: true });

			let data = await CrmIntegrationsService.fetch({
				locationId,
				params: { searchTerm, sortField, sortOrder, limit, status: ["active", "inactive"] },
				ignoreCache: true
			});

			await this.update({ data: data, loading: false, loadedAll: data.length < limit });
		} catch (error) {
			console.log(error);
			await this.update({ loading: false });
		}
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let columns = CRM_INTEGRATION_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	onRecordClicked = async item => {
		// nothing for now
	};

	renderRecord = recordData => {
		try {
			return [
				recordData.name,
				recordData.crm_type,
				recordData.crm_version,
				recordData.integration_type,
				recordData.direction,
				recordData.meta_data,
				recordData.status,
				this.renderActions(recordData)
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderActions(record) {
		let { t } = this.props;
		const user = UserService.get();

		return (
			<React.Fragment>
				<div className="crm__actions">
					{user.GroupPermission.update_crm_integrations && (
						<Action
							key={`updateCrmIntegration-${record.id}`}
							id={`updateCrmIntegration-${record.id}`}
							label={t("Update")}
							icon={Icon.Edit}
							onClick={() => this.setState({ currentToUpdateRow: record }, () => this.setState({ showUpdateModal: true, createMode: false }))}
						/>
					)}
					{user.GroupPermission.update_crm_integrations && record.crm_type === CRM_INTEGRATIONS.type.cdk.name && (
						<Action
							key={`triggerCrmIntegration-${record.id}`}
							id={`triggerCrmIntegration-${record.id}`}
							label={t("Trigger Integration")}
							icon={Icon.Zap}
							onClick={() =>
								this.setState({ triggerRow: record }, () =>
									this.setState({ hasWarning: true, warningText: t("You are about to trigger integration manually.") })
								)
							}
						/>
					)}
				</div>
			</React.Fragment>
		);
	}

	onUpdateModalSubmit = async result => {
		let { t } = this.props;

		if (!result) {
			await this.update({ hasError: true, errorText: t("Error Saving CRM Integration") });
			return;
		}

		await this.update({ showUpdateModal: false, success: true, infoText: t("CRM Integration Saved") });
		await this.fetchData();
	};

	async handleTriggerOnClick() {
		const { locationId, triggerRow } = this.state;
		let { t } = this.props;

		let success = await CrmIntegrationsService.integrationTrigger({ locationId, crmType: triggerRow.crm_type, metadata: triggerRow.meta_data });

		if (!success) {
			this.setState({ hasError: true, errorText: t("Something went wrong while triggering the integration manually. Please try again.") });
			return;
		}

		this.setState({ success: true, infoText: t("Integration triggered") });
	}

	render() {
		const {
			data,
			loadedAll,
			sortField,
			sortOrder,
			loading,
			hasError,
			success,
			infoText,
			errorText,
			currentToUpdateRow,
			showUpdateModal,
			createMode,
			hasWarning,
			warningText
		} = this.state;
		let { t } = this.props;

		const user = UserService.get();

		return (
			<Page>
				<Header title={t("CRM Integrations")}>
					<Action id="refresh" label={t("Refresh")} icon={Icon.RefreshCcw} onClick={() => this.fetchData()} />
					{user.GroupPermission.update_crm_integrations && (
						<Action
							id="create"
							label={t("Create New")}
							icon={Icon.Plus}
							onClick={() => {
								this.setState({ createMode: true, currentToUpdateRow: undefined }, () => this.setState({ showUpdateModal: true, createMode: true }));
							}}
						/>
					)}
				</Header>
				<div className="Common__search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
				</div>
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no crm integrations yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				{showUpdateModal && (
					<CRMUpdateModal
						id={currentToUpdateRow ? currentToUpdateRow.id : undefined}
						locationId={this.state.locationId}
						show={showUpdateModal}
						onHide={() => this.setState({ showUpdateModal: false, createMode: false })}
						createMode={createMode}
						onSubmit={this.onUpdateModalSubmit}
					/>
				)}
				<Alert type="error" show={hasError} title={t("Error")} confirm={t("OK")} onClose={() => this.setState({ hasError: false, errorText: "" })}>
					<div>{errorText}</div>
				</Alert>
				<Alert
					type="warning"
					show={hasWarning}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={confirmed => {
						if (confirmed) {
							this.handleTriggerOnClick();
						}
						this.setState({ hasWarning: false, warningText: "", triggerRow: null });
					}}
				>
					<div>{warningText}</div>
				</Alert>
				<Alert type="success" show={success} title={t("Success")} confirm={t("OK")} onClose={() => this.setState({ success: false, infoText: "" })}>
					<div>{infoText}</div>
				</Alert>
			</Page>
		);
	}
}

export default withLocation(withTranslation(null, { withRef: true })(CRMIntegrations));

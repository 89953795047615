import Kichiri from "./KichiriService";
import UserService from "./UserService";

export const GmbLocationStatsService = {
	/**
	 * Get GMB location stats for a location and date range
	 * @param {Integer} locationId
	 * @param {DateTime} start
	 * @param {DateTime} end
	 * @returns {Object} An array of GMB Location Stats
	 */
	async getGmbLocationStats(locationId, start, end) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}
			const authToken = UserService.get() ? UserService.get().auth_token : "";
			const response = await Kichiri.location.getGmbLocationStats({ locationId: locationId }, { startRange: start, endRange: end }, authToken);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetch an analysis on Gmb Location Stats for a location
	 * @param {Integer} locationId
	 * @return {Object} Analyzed GMB Location Stats
	 */
	async getGmbLocationStatsAnalysis(locationId) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}
			const authToken = UserService.get() ? UserService.get().auth_token : "";
			const response = await Kichiri.location.getGmbLocationStatsAnalysis({ locationId: locationId }, {}, authToken);
			return response.data;
		} catch (error) {
			throw error;
		}
	}
};

export default GmbLocationStatsService;

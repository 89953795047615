import React, { Component } from "react";
import Select from "react-select";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import Checkbox from "../../components/common/Checkbox";

import "../../styles/css/scenes/contact-selector.css";

class ContactSelectorFlagUpdate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			flags: {
				receive_marketing_emails: true,
				receive_marketing_sms: true,
				receive_transactional_emails: true,
				receive_transactional_sms: true,
				receive_feedback_emails: true,
				receive_feedback_sms: true
			},
			confirmModalText: ""
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleOnConfirmModal = async confirm => {
		let { flags } = this.state;

		if (confirm && this.props.updateFlagsForContacts) {
			let response = await this.props.updateFlagsForContacts(flags);

			if (response) {
				ToastService.info(`Successfully updated flags for selected contacts`);
			} else {
				ToastService.error(`There was an error updating flags for selected contacts`);
			}
		}

		await this.update({ confirmModalText: "" });
	};

	onToggleCheckbox = async flag => {
		let { flags } = this.state;

		flags[flag] = !flags[flag];

		await this.update({ flags });
	};

	render() {
		let { confirmModalText } = this.state;
		let { numSelectedContacts, showHeader } = this.props;

		let disabledButtons = numSelectedContacts === 0;

		return (
			<div className={`contact-selector__update-contacts flags_section`}>
				{showHeader && <div className="contact-selector__update-contacts-header">Flags</div>}
				<div className="contact-selector__update-contacts-column">
					<div
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							this.onToggleCheckbox("receive_marketing_emails");
						}}
					>
						<Checkbox id="receive_marketing_emails" name="receive_marketing_emails" checked={this.state.flags["receive_marketing_emails"]} />
						<div className="contact-selector__update-contacts__checkbox__option__label">Receive Marketing Emails</div>
					</div>
					<div
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							this.onToggleCheckbox("receive_marketing_sms");
						}}
					>
						<Checkbox id="receive_marketing_sms" name="receive_marketing_sms" checked={this.state.flags["receive_marketing_sms"]} />
						<div className="contact-selector__update-contacts__checkbox__option__label">Receive Marketing SMS</div>
					</div>
					<div
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							this.onToggleCheckbox("receive_transactional_emails");
						}}
					>
						<Checkbox id="receive_transactional_emails" name="receive_transactional_emails" checked={this.state.flags["receive_transactional_emails"]} />
						<div className="contact-selector__update-contacts__checkbox__option__label">Receive Transactional Emails</div>
					</div>
					<div
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							this.onToggleCheckbox("receive_transactional_sms");
						}}
					>
						<Checkbox id="receive_transactional_sms" name="receive_transactional_sms" checked={this.state.flags["receive_transactional_sms"]} />
						<div className="contact-selector__update-contacts__checkbox__option__label">Receive Transactional SMS</div>
					</div>
					<div
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							this.onToggleCheckbox("receive_feedback_emails");
						}}
					>
						<Checkbox id="receive_feedback_emails" name="receive_feedback_emails" checked={this.state.flags["receive_feedback_emails"]} />
						<div className="contact-selector__update-contacts__checkbox__option__label">Receive Feedback Emails</div>
					</div>

					<div
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							this.onToggleCheckbox("receive_feedback_sms");
						}}
					>
						<Checkbox id="receive_feedback_sms" name="receive_feedback_sms" checked={this.state.flags["receive_feedback_sms"]} />
						<div className="contact-selector__update-contacts__checkbox__option__label">Receive Feedback SMS</div>
					</div>
					<div className="contact-selector__row-btns">
						<button
							className="btn btn-success"
							onClick={() =>
								this.setState({
									confirmModalText: `Are you sure you want to overwrite these flags for the selected contacts?`
								})
							}
							disabled={disabledButtons}
						>
							<Icon.Edit2 /> Overwrite
						</button>
					</div>
					<div>
						{confirmModalText && confirmModalText !== "" && (
							<div className="contact-selector__update-contacts__confirm">
								<div className="contact-selector__update-contacts__confirm-text text-center">
									<span>{confirmModalText}</span>
								</div>
								<div className="contact-selector__update-contacts__confirm-btns">
									<button className="mb-button mb-button--cancel" onClick={() => this.handleOnConfirmModal(false)}>
										Cancel
									</button>
									<button className="mb-button" onClick={() => this.handleOnConfirmModal(true)}>
										Okay
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default ContactSelectorFlagUpdate;

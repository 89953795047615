export const SORT_ORDER = {
	desc: "desc",
	asc: "asc"
};

export const STATUS = {
	active: "active",
	inactive: "inactive",
	deleted: "deleted"
};

export const STATUS_FILTER = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	active: {
		id: "active",
		display: "On",
		order: 2
	},
	inactive: {
		id: "inactive",
		display: "Off",
		order: 3
	}
};

export const KEYWORDS_COLUMNS = {
	status: {
		id: "status",
		value: "Status",
		sortable: false,
		sortField: "status",
		width: 1
	},
	words: {
		id: "words",
		value: "Words",
		sortable: false,
		sortField: "words",
		width: 2
	},
	response: {
		id: "response",
		value: "Response",
		sortable: false,
		sortField: "response",
		width: 5
	},
	action: {
		id: "action",
		value: "Action",
		sortable: false,
		sortField: "action",
		width: 1,
		notClickable: true
	}
};

import React from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";
import SupportChatService from "../../services/SupportChatService";
import LocationService from "../../services/LocationService";

import withLocation from "../../components/common/WithLocation";
import ConversionSettingsModal from "../Settings/Reengagements/ConversionSettingsModal";

class WidgetConversionsRevenue extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			widgetConversions: 0,
			revenue: 0,
			showConversionSettingsModal: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		try {
			let locationId = UserService.getActiveLocation().id;
			let result = await AnalyticsService.getTotalConversionsCount({ locationId });

			// Get average appointment cost stored in the location metadata
			let location = await UserService.getActiveLocation();
			let averageConversionValue = location.average_conversion_value;
			let widgetConversions = result.widgetConversions;
			let revenue = Math.floor(widgetConversions * averageConversionValue);

			await this.update({
				widgetConversions: widgetConversions,
				revenue: revenue
			});
		} catch (error) {
			console.log(error);
		}
	};

	onWidgetsGetStarted = () => {
		SupportChatService.showNewMessage("Hi, I would like to enable Webchat and booking features. Would you be able to help?");
	};

	onOpenConversionSettingsModal = async id => {
		await this.update({ showConversionSettingsModal: true });
	};

	onHideConversionSettingsModal = async shouldResetComponent => {
		await this.update({ showConversionSettingsModal: false });

		if (shouldResetComponent) {
			this.fetchData();
		}
	};

	renderHeader = () => {
		const { cardName, isPermissible } = this.props;

		return (
			<div className="dashboard__card__header Common__cursor--pointer" onClick={this.onOpenConversionSettingsModal}>
				<div className="dashboard__card__header__icon">
					<Icon.DollarSign size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
					{cardName ? cardName : "Widget Revenue"} <Icon.Info size={20} data-tip data-for="dashboard-widget-conversion-revenue-info" />{" "}
				</div>
				<ReactTooltip
					id="dashboard-widget-conversion-revenue-info"
					className="mb-react-tooltip mb-react-tooltip--medium"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="right"
				>
					Shows the revenue generated through widget conversions.
					{isPermissible && !isPermissible() ? "Requires the webchat or booking widget to be active." : null}
				</ReactTooltip>
			</div>
		);
	};

	render = () => {
		let { revenue, showConversionSettingsModal } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<>
					{this.renderHeader()}
					<div className="dashboard__widget__conversions">
						<div className="mb-button" onClick={this.onWidgetsGetStarted}>
							Get Started
						</div>
					</div>
				</>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<>
					{this.renderHeader()}

					<div className="dashboard__widget__conversions">Widget data restricted.</div>
				</>
			);
		}

		let content = "N/A";

		let areWidgetsEnabled = LocationService.isWebchatPermissible() || LocationService.isBookingsPermissible();

		// Only show if there are active crm integrations and there is at least some revenue
		if (areWidgetsEnabled && revenue && revenue > 0) {
			content = `$ ${revenue.toLocaleString()}`;
		}

		return (
			<>
				{this.renderHeader()}
				<div className="dashboard__card__stat">
					<div className="dashboard__card__stat__count Common__cursor--pointer" onClick={this.onOpenConversionSettingsModal}>
						{content}
					</div>
				</div>

				<ConversionSettingsModal show={showConversionSettingsModal} onHide={this.onHideConversionSettingsModal} />
			</>
		);
	};
}

export default withLocation(WidgetConversionsRevenue);

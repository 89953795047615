import React, { Component } from "react";
import "../../styles/css/components/commons/page.css";

class Page extends Component {
	render() {
		let { className } = this.props;
		return <div className={`dh-page ${className ? className : ""}`}>{this.props.children}</div>;
	}
}

export default Page;

import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import AppointmentsService from "../../services/AppointmentsService";

import { NOTIFICATION_STATUS, NOTIFICATION_TYPES } from "../../constants/Appointments";

import "../../styles/css/components/commons/dh-details.css";

class NotificationList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			notifications: null
		};
	}

	async componentDidMount() {
		await this.update({ loading: true });
		await this.fetchNotifications();
		await this.update({ loading: false });
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async fetchNotifications() {
		let appointmentId = this.props.appointmentId;

		// The call to fetch notifications
		let location = UserService.getActiveLocation();

		let notifications = await AppointmentsService.fetchAppointmentNotifications(location.id, {
			appointmentId: appointmentId,
			sortField: "created_at",
			sortOrder: "desc"
		});

		await this.update({
			notifications
		});
	}

	renderNotificationList() {
		let { notifications } = this.state;
		let { t } = this.props;

		if (!notifications) {
			return null;
		}

		return notifications.map(notification => (
			<div className="dh-details-row">
				<div>
					<label>{t("Type")}</label>
					<div>{NOTIFICATION_TYPES[notification.type].display}</div>
				</div>
				<div>
					<label>{t("Send Date")}</label>
					<div>{moment(notification.send_after).format("MMM Do YYYY, h:mm a")}</div>
				</div>
				<div>
					<label>{t("Status")}</label>
					<div>{NOTIFICATION_STATUS[notification.status].display}</div>
				</div>
				<div>
					<label>{t("Created At")}</label>
					<div>{moment(notification.created_at).format("MMM Do YYYY, h:mm a")}</div>
				</div>
			</div>
		));
	}

	render() {
		const { loading } = this.state;
		let { t } = this.props;

		return (
			<div>
				{loading && (
					<div className="dh-details-item">
						<label>{t("loading ...")}</label>
					</div>
				)}
				{!loading && (
					<>
						<div className="dh-details-sub-title">{t("Notifications")}</div>
						{this.renderNotificationList()}
					</>
				)}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(NotificationList);

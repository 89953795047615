import React, { Component } from "react";
import * as Icon from "react-feather";
import SideModal from "../../components/common/SideModal";
import ContactSelectorFilters from "./ContactSelectorFilters";

class ContactFiltersSideModal extends Component {
	render() {
		const { show, onHide, refreshContacts, tagsChange } = this.props;
		return (
			<SideModal show={show} title={"Filter Contacts"} titleIcon={Icon.Filter} onHide={onHide} closeOnClick={false}>
				<ContactSelectorFilters refreshContacts={refreshContacts} tagsChange={tagsChange} />
			</SideModal>
		);
	}
}

export default ContactFiltersSideModal;

import React, { Component } from "react";

import "./LandingPage.css";

class LandingPage extends Component {
	render() {
		let { title, text, imageUrl, buttonText, onGetStartedClicked, beta, newFeature } = this.props;

		return (
			<div className={`landing-page-container ${!this.props.children ? "landing-page--no-children" : ""}`}>
				<div className="landing-page">
					{imageUrl && (
						<div className="landing-page__image">
							<img src={imageUrl} alt="welcome" />
						</div>
					)}
					<div className="landing-page__content">
						<div className="landing-page__title">
							{title} {beta && <div className="landing-page__beta">(Beta)</div>}
							{newFeature && <div className="landing-page__beta">(New)</div>}
						</div>
						<div className="landing-page__text_content">{text}</div>
						<div onClick={onGetStartedClicked} className="landing-page__button">
							{buttonText}
						</div>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPage;

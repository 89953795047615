import React from "react";
import Select from "react-select";
import _ from "lodash";

import UserService from "../../../services/UserService";

import "../../../styles/css/components/commons/condition.css";
import "./reengagements.css";

const OPERATORS = {
	"=": { value: "=", label: "=" },
	"<": { value: "<", label: "<" },
	">": { value: ">", label: ">" },
	"<=": { value: "<=", label: "<=" },
	">=": { value: ">=", label: ">=" },
	"!=": { value: "!=", label: "!=" }
};

class Condition extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showConfirmDeleteModal: false,
			condition: this.props.condition ? this.props.condition : []
		};
	}

	isReadOnly = () => {
		let user = UserService.get();
		return !user.GroupPermission.update_reengagements;
	};

	onConfirmDeleteCondition = () => {
		this.setState({ showConfirmDeleteModal: true });
	};

	onRemoveCondition = confirm => {
		if (confirm && this.props.onRemoveCondition) {
			this.props.onRemoveCondition();
		}
		this.setState({
			showConfirmDeleteModal: false
		});
	};

	onConditionOperatorChange = option => {
		if (this.props.onConditionOperatorChange) {
			this.props.onConditionOperatorChange(option);
		}
	};

	onConditionTypeChange = event => {
		if (this.props.onConditionTypeChange) {
			this.props.onConditionTypeChange(event);
		}
	};

	onParamChange = (conditionId, paramId, param) => {
		if (this.props.onParamChange) {
			this.props.onParamChange(conditionId, paramId, param);
		}
	};

	onParamValueChange = (conditionId, paramId, param, value) => {
		param.value = value;
		this.onParamChange(conditionId, paramId, { id: param.id, value: param.value, operator: param.operator });
	};

	onParamOperatorChange = (conditionId, paramId, param, operator) => {
		param.operator = operator.value;
		this.onParamChange(conditionId, paramId, { id: param.id, value: param.value, operator: param.operator });
	};

	onAddParameter = key => {
		if (this.props.onAddParameter) {
			this.props.onAddParameter(key);
		}
	};

	onRemoveParameter = key => {
		if (this.props.onRemoveParameter) {
			this.props.onRemoveParameter(key);
		}
	};

	renderParamValue = value => {
		if (typeof value === "object" && value !== null && value !== "") {
			return JSON.stringify(value);
		}
		return value;
	};

	renderParameters() {
		let { condition, conditionInfo } = this.props;

		if (!conditionInfo.params || Object.keys(conditionInfo.params).length < 1) {
			return null;
		}

		let parameters = Object.values(conditionInfo.params);

		return (
			<>
				<div className="condition__parameters">Parameters:</div>
				{parameters.map(paramInfo => {
					let param = null;
					let operators = [...Object.values(OPERATORS)];

					if (condition && condition.params) {
						param = condition.params[paramInfo.id];
					} else if (!param) {
						param = conditionInfo.params[paramInfo.id];
					}

					if (paramInfo.operators) {
						operators = operators.filter(op => paramInfo.operators.indexOf(op.value) >= 0);
					}

					return (
						<div className="condition__parameter" key={paramInfo.id}>
							<div className="condition__parameter__name rmm__conditions__condition__parameter__name">
								{paramInfo.required ? <span className="text-danger">*</span> : ""}
								<input
									className="Common__input fnctst-parameter-key rmm__conditions__condition__parameter__name__input"
									value={paramInfo.name}
									placeholder={paramInfo.name}
									disabled={true}
								/>
							</div>
							<div className="condition__parameter__operator">
								<Select
									className="rmm__conditions__condition__parameter__operator__select"
									value={param && param.operator ? OPERATORS[param.operator] : null}
									placeholder="Operator"
									options={Object.values(operators)}
									onChange={option => this.onParamOperatorChange(conditionInfo.id, paramInfo.id, param, option)}
								/>
							</div>
							{paramInfo.type === "number" || paramInfo.type === "string" ? (
								<input
									name={paramInfo.id}
									className="Common__input fnctst-parameter-value"
									value={param && typeof param.value !== "undefined" ? param.value : paramInfo.value}
									placeholder={"value"}
									onChange={event => this.onParamValueChange(conditionInfo.id, paramInfo.id, param, event.target.value)}
									disabled={this.isReadOnly()}
								/>
							) : null}

							{paramInfo.type === "select" && (
								<Select
									id={`${paramInfo.id}`}
									className={"rmm__conditions__condition__parameter__value__select"}
									options={paramInfo.options}
									isMulti={paramInfo.isMulti}
									value={param.value}
									onChange={option => this.onParamValueChange(conditionInfo.id, paramInfo.id, param, option)}
									disabled={this.isReadOnly()}
								/>
							)}
						</div>
					);
				})}
			</>
		);
	}

	render() {
		let { conditionInfo } = this.props;

		return (
			<div>
				<div className="condition__type__description">{conditionInfo.description}</div>
				{this.renderParameters()}
			</div>
		);
	}
}

export default Condition;

import React, { Component } from "react";
import ReactSelect from "react-select";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import GroupPermissionService from "../../services/GroupPermissionService";
import SideModal from "../../components/common/SideModal";

class UserFilters extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,

			accountTypes: [],
			selectedLocations: [],
			selectedAccountTypes: []
		};
	}

	fetchSubAccounts = async () => {
		let accountTypes = await GroupPermissionService.fetchSubordinateAccounts();

		accountTypes = accountTypes.map(s => {
			return {
				value: s.id,
				label: s.name,
				type: s.type
			};
		});

		this.setState({
			accountTypes
		});
	};

	handleFiltersOnSubmit() {
		const { selectedLocations, selectedAccountTypes } = this.state;
		const { handleFiltersOnSubmit } = this.props;
		if (handleFiltersOnSubmit) {
			const locationIds = selectedLocations ? selectedLocations.map(selectedLocation => selectedLocation.value) : [];
			const groupIds = selectedAccountTypes ? selectedAccountTypes.map(selectedAccountType => selectedAccountType.value) : [];
			handleFiltersOnSubmit(locationIds, groupIds);
		}
	}
	componentDidMount() {
		this.fetchSubAccounts();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationsChange = async value => {
		await this.update({ selectedLocations: value });
		this.handleFiltersOnSubmit();
	};

	onAccountTypesChange = async value => {
		await this.update({ selectedAccountTypes: value });
		this.handleFiltersOnSubmit();
	};

	render() {
		const { accountTypes, selectedAccountTypes, selectedLocations } = this.state;
		const { show, handleOnHide, locations, t } = this.props;

		return (
			<SideModal show={show} title={t("Filter Users")} titleIcon={Icon.Filter} onHide={handleOnHide} closeOnClick={false}>
				<div className="form-horizontal">
					<div className="form-group">
						<label className="col-sm-12">{t("Locations")}</label>
						<div className="col-sm-12">
							<ReactSelect
								value={selectedLocations}
								onChange={this.onLocationsChange}
								closeMenuOnSelect={false}
								isMulti={true}
								options={locations}
								placeholder={t("Select Locations...")}
								isClearable={true}
							/>
						</div>
					</div>
					<div className="form-group">
						<label className="col-sm-12">{t("Account Types")}</label>
						<div className="col-sm-12">
							<ReactSelect
								value={selectedAccountTypes}
								onChange={this.onAccountTypesChange}
								closeMenuOnSelect={false}
								isMulti={true}
								options={accountTypes}
								placeholder={t("Select Account Types...")}
								isClearable={true}
							/>
						</div>
					</div>
				</div>
			</SideModal>
		);
	}
}

export default withTranslation(null, { withRef: true })(UserFilters);

import React, { Component } from "react";

import UtilityService from "../../services/UtilityService";
import ApiKeysService from "../../services/ApiKeysService";

import Modal from "../../components/common/DHModal";

import "../../styles/css/scenes/update-api-keys-modal.css";

class UpdateAPIKeysModal extends Component {
	state = {
		name: this.props.data ? this.props.data.name : "webchat",
		username: this.props.data ? this.props.data.username : "",
		password: this.props.data ? this.props.data.password : "",
		token: this.props.data ? this.props.data.token : "",
		status: this.props.data ? this.props.data.status : "active",
		knownHosts: this.props.data ? this.props.data.known_hosts : "",
		parentUpdate: true,
		keyNames: [],
		domain: ""
	};

	componentDidMount() {
		this.fetchAllowedKeyNames();
	}

	handleOnSubmit(e) {
		if (this.props.onSubmit) {
			const { name, username, password, token, status, knownHosts } = this.state;
			const { data, createMode } = this.props;
			this.props.onSubmit(createMode, createMode ? 0 : data.id, name, username, password, token, status, knownHosts);
		}
	}

	async fetchAllowedKeyNames() {
		try {
			let keyNames = await ApiKeysService.getAllKeyNames();
			this.setState({ keyNames: keyNames });
		} catch (error) {
			console.log(error);
		}
	}

	renderNameDropdownItems() {
		const { keyNames } = this.state;
		const items = [];
		keyNames.forEach((aKeyName, key) => {
			items.push(
				<option key={key} value={aKeyName}>
					{aKeyName}
				</option>
			);
		});
		return items;
	}

	handleTokenOnChange(e) {
		e.preventDefault();
		let token = UtilityService.uuid();
		this.setState({ token });
	}

	handleKeyNameOnChange(e) {
		this.setState({ name: e.target.value });
	}

	handleKeyStatusOnChange(e) {
		this.setState({ status: e.target.value });
	}

	handleGenericEventHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleOnAddDomain = () => {
		// Create 4 urls with the same domain/path
		let prefixes = ["http://", "https://", "http://www.", "https://www."];

		let { domain, knownHosts } = this.state;

		let strToAdd = "";
		// If there are hosts, add a comma
		if (knownHosts.trim() !== "") {
			strToAdd += ",";
		}

		// for each prefix, add the url with the domain name
		for (let i = 0; i < prefixes.length; i++) {
			const prefix = prefixes[i];
			strToAdd += `${prefix}${domain}`;
			// don't add a trailing comma if its the last item
			if (i !== prefixes.length - 1) {
				strToAdd += ",";
			}
		}

		// update known hosts
		knownHosts += strToAdd;
		this.setState({ knownHosts, domain: "" });
	};

	render() {
		const { data, show, onHide, createMode } = this.props;
		const { name, username, token, password, status, knownHosts, domain } = this.state;

		return (
			<Modal show={show} onHide={() => onHide()} title={`${createMode ? "Create " : "Update API Key"} `}>
				{(data || createMode) && (
					<div className="modal__flex-container">
						<div className="modal__field">Key Name</div>
						<select name="name" id="api-name" type="text" onChange={e => this.handleKeyNameOnChange(e)} value={name || ""} className="form-control">
							{this.renderNameDropdownItems()}
						</select>
						<div className="modal__field">Username</div>
						<input
							name="username"
							id="api-username"
							type="text"
							onChange={e => this.handleGenericEventHandler(e)}
							value={username}
							className="form-control"
							autoComplete="off"
						/>

						<div className="modal__field">Password</div>
						<input
							name="password"
							id="api-password"
							type="text"
							value={password}
							className="form-control"
							autoComplete="off"
							onChange={e => this.handleGenericEventHandler(e)}
						/>

						{name === "nucleus" && (
							<>
								<div className="modal__field">Domain + Path</div>
								<div className="modal__field__row api-keys-modal__domain">
									<div className="modal__field__row__item api-keys-modal__domain__item__input">
										<input
											name="domain"
											id="domain"
											type="text"
											onChange={e => this.handleGenericEventHandler(e)}
											value={domain}
											className="form-control"
											autoComplete="off"
										/>
									</div>
									<div className="modal__field__row__item api-keys-modal__domain__item__btn">
										<div className="mb-button mb-button--fit api-keys-modal__domain__item__add-to-hosts" onClick={this.handleOnAddDomain}>
											Add To Hosts
										</div>
									</div>
								</div>
							</>
						)}

						<div className="modal__field">Known Hosts</div>
						<input
							name="knownHosts"
							id="known-hosts-name"
							type="text"
							onChange={e => this.handleGenericEventHandler(e)}
							value={knownHosts}
							className="form-control"
							autoComplete="off"
						/>

						{!createMode && (
							<>
								<div className="modal__field">Token</div>
								<div style={{ display: "flex", alignItems: "center", width: "100%" }}>
									<input name="name" id="api-token" type="text" value={token} className="form-control" autoComplete="off" disabled />
									<button id="refresh" className="btn btn-rounded btn-default btn-sm fnctst-update-token" onClick={e => this.handleTokenOnChange(e)}>
										<i className={`fa fa-refresh`} />
									</button>
								</div>
								<div className="modal__field">Status</div>
								<select name="name" id="api-status" type="text" onChange={e => this.handleKeyStatusOnChange(e)} value={status || ""} className="form-control">
									<option value="active">Active</option>
									<option value="inactive">Inactive</option>
									<option value="deleted">Deleted</option>
								</select>
							</>
						)}
						<div className="modal__actions">
							<div id="manageApiKey" className="mb-button" onClick={e => this.handleOnSubmit(e)}>
								{createMode ? "Create" : "Update"}
							</div>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}
export default UpdateAPIKeysModal;

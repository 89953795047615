import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import SearchInput from "../../components/common/SearchInput";
import Action from "../../components/common/Action";

import Task from "./Task";

import GAService from "../../services/GAService";
import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";

import withLocation from "../../components/common/WithLocation";

import UpdateTasksModal from "./UpdateTasksModal";

import "./tasks.css";

class Tasks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tasks: [],
			loading: false,

			searchString: "",
			filteredTasks: [],
			showModalTasks: false
		};
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		await this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({ loading: true });

		await this.update({
			tasks: [],
			searchString: "",
			filteredTasks: [],
			showModalTasks: false
		});
		await this.fetchData();

		await this.update({ loading: false });
	};

	fetchData = async () => {
		let locationId = UserService.getActiveLocation().id;

		try {
			let tasks = await LocationService.fetchTasks({ locationId });

			if (!tasks) {
				return;
			}

			this.sortTasks(tasks); // To do in backend in DH-2888

			await this.update({ tasks, filteredTasks: tasks });
		} catch (error) {
			console.log(error);
		}
	};

	sortTasks = tasks => {
		tasks.sort((taskA, taskB) => {
			// Sort by pinned first
			if (taskA.pinned && !taskB.pinned) return -1;
			if (!taskA.pinned && taskB.pinned) return 1;

			let taskACreatedAt = taskA.created_at;
			let taskBCreatedAt = taskB.created_at;

			// Sort by created at, older ones first
			if (moment(taskACreatedAt) < moment(taskBCreatedAt)) return -1;
			if (moment(taskACreatedAt) > moment(taskBCreatedAt)) return 1;

			return 0;
		});
	};

	onSearchChange = async value => {
		await this.update({ searchTerm: value });

		const filteredTasks = this.state.tasks.filter(task => {
			if (task.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
				return true;
			}
			if (task.content.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
				return true;
			}
			return false;
		});

		await this.update({ filteredTasks });
	};

	onTaskSubmit = async () => {
		await this.onHideTaskModal();
		await this.fetchData();
	};

	onTaskUpdate = async () => {
		await this.fetchData();
	};

	handleCreateTaskClick = async () => {
		await this.update({ showModalTasks: true });
	};

	onHideTaskModal = async () => {
		await this.update({ showModalTasks: false });
	};

	renderTasks() {
		let { tasks, filteredTasks, searchTerm } = this.state;

		let taskList = searchTerm ? filteredTasks : tasks;

		if (!taskList) {
			return;
		}

		return (
			<div className="tasks">
				<div className="tasks__tasks-container">
					{taskList.map((task, index) => (
						<Task task={task} onTaskUpdate={this.onTaskUpdate} />
					))}
				</div>
			</div>
		);
	}

	renderInfo() {
		let { t } = this.props;

		// DH-3551: Todo
		let information =
			"Three dashes with new lines above and below will create a divider:\n\n" +
			"above\n" +
			"<leave new line here>\n" +
			"---\n" +
			"<leave new line here>\n" +
			"below\n\n" +
			"*Italics*\n" +
			"**Bold**\n" +
			"~~Strike Through~~\n\n" +
			"[Link](https://demandhub.co)\n\n" +
			"Ordered List: Using 1.\n" +
			"1. First item\n" +
			"2. Second Item\n\n" +
			"Unordered List: Using dashes\n" +
			"- First item\n" +
			"- Second item\n\n" +
			"Images: \n" +
			"![image_description](https://bit.ly/36i2wgR)";

		return (
			<>
				<div data-tip data-for="info-task-tooltip" className="tasks__info">
					?
				</div>
				<ReactTooltip offset="{'left': 100}" id="info-task-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
					<div className="tasks__info__tooltip-text">{information}</div>
				</ReactTooltip>
			</>
		);
	}

	render() {
		let { loading, showModalTasks, tasks } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowCreate = user.GroupPermission.create_tasks;

		if (loading) {
			return (
				<Page>
					<Header title={t("Tasks")} />
					<ContentLoader height={"100%"} width={"100%"}>
						{/* Search */}
						<rect x="30" y="0" rx="5" ry="5" width="300" height="35" />
						{/* First row */}
						<rect x="30" y="65" rx="5" ry="5" width="315" height="315" />
						<rect x="370" y="65" rx="5" ry="5" width="315" height="315" />
						<rect x="710" y="65" rx="5" ry="5" width="315" height="315" />
					</ContentLoader>
				</Page>
			);
		}

		if (!tasks || tasks.length === 0) {
			return (
				<Page>
					<Header title={t("Tasks")}>
						<Action id="refresh" label={t("Refresh")} icon={Icon.RefreshCcw} onClick={this.resetComponent} />
						{allowCreate && <Action id="create" label={t("Create Task")} icon={Icon.Plus} onClick={this.handleCreateTaskClick} />}
					</Header>
					<div className="tasks__landing-page-container">
						<div className="tasks__landing-page">
							<div className="tasks__landing-page__image">
								<img src="https://cdn.demandhub.co/web-app/assets/notes-banner.svg" alt="welcome" />
							</div>
							<div className="tasks__landing-page__content">
								<div className="tasks__landing-page__title">{t("Tasks")}</div>
								<div className="tasks__landing-page__text-content">{t("Your personal task managment assistant.")}</div>
							</div>
						</div>
					</div>
					<UpdateTasksModal show={showModalTasks} createMode={true} onSubmit={this.onTaskSubmit} onHide={this.onHideTaskModal} />
				</Page>
			);
		}

		return (
			<Page>
				<Header title={t("Tasks")}>
					<Action id="refresh" label={t("Refresh")} icon={Icon.RefreshCcw} onClick={this.resetComponent} />
					{allowCreate && <Action id="create" label={t("Create Task")} icon={Icon.Plus} onClick={this.handleCreateTaskClick} />}
				</Header>
				<div className="tasks__search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
				</div>
				{this.renderTasks()}
				{this.renderInfo()}
				<UpdateTasksModal show={showModalTasks} createMode={true} onSubmit={this.onTaskSubmit} onHide={this.onHideTaskModal} />
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Tasks)));

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Workflows from "./Workflows";
import WorkflowRuns from "./WorkflowRuns/WorkflowRuns";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import GAService from "../../../services/GAService";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

import { WORKFLOW_TABS } from "../../../constants/Workflows";

class WorkflowOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: "workflows"
		};
	}

	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });
	};

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.workflows,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Workflows")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={WORKFLOW_TABS.workflows.id} value={WORKFLOW_TABS.workflows.value} />
					<Tab id={WORKFLOW_TABS.workflowRuns.id} value={WORKFLOW_TABS.workflowRuns.value} />
				</Tabs>
				{WORKFLOW_TABS.workflows.id === selectedTab && <Workflows />}
				{WORKFLOW_TABS.workflowRuns.id === selectedTab && <WorkflowRuns />}
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(WorkflowOverview);

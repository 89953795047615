export const CONFIRM_BLOCK_COLUMNS_CONTACT = {
	name: {
		id: "name",
		value: "Name",
		sortable: false,
		width: 1
	},
	created: {
		id: "created",
		value: "Created",
		sortable: false,
		width: 1
	},
	status: {
		id: "status",
		value: "Status",
		sortable: false,
		width: 1
	}
};

export const CONFIRM_BLOCK_COLUMNS_APPOINTMENTS = {
	name: {
		id: "name",
		value: "Name",
		sortable: false,
		width: 1
	},
	state: {
		id: "state",
		value: "State",
		sortable: false,
		width: 1
	},
	status: {
		id: "status",
		value: "Status",
		sortable: false,
		width: 1
	}
};

import React from "react";
import * as Icon from "react-feather";
import { Collapse } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import Action from "../../../components/common/Action";
import Alert from "../../../components/common/Alert";
import Condition from "./Condition";
import ActionField from "./ActionField";
import ActionConfig from "./ActionConfig";
import ToggleSwitch from "../../../components/common/ToggleSwitch";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import UtilityService from "../../../services/UtilityService";

import { STATUS } from "../../../constants/CommonConstants";

import "../../../styles/css/components/commons/workflow.css";

class Workflow extends React.PureComponent {
	state = {
		expanded: false,
		saveable: this.props.workflow && !this.props.workflow.id ? true : false,
		saving: false,

		name: this.props.workflow ? this.props.workflow.name : "",
		priority: this.props.workflow ? this.props.workflow.priority : "",
		status: this.props.workflow ? this.props.workflow.status : "",
		logging: this.props.workflow ? this.props.workflow.logging : false,

		existsActionFields:
			this.props.workflowsConfig.actionInfo &&
			this.props.workflowsConfig.actionInfo[this.props.workflow.action_type] &&
			this.props.workflowsConfig.actionInfo[this.props.workflow.action_type].actionFields,

		showConfirmDeleteModal: false,
		showConfirmLoggingModal: false,
		showConfirmSaveModal: false
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onToggleExpanded = () => {
		this.update({
			expanded: !this.state.expanded
		});
	};

	isReadOnly = () => {
		let user = UserService.get();
		return !user.GroupPermission.update_workflows;
	};

	onCopyWorkflow = () => {
		let { workflow, t } = this.props;

		let newWorkflow = { ...workflow };
		newWorkflow.id = 0;
		newWorkflow.location_id = 0;
		newWorkflow.action_template_id = null;
		newWorkflow.action_crm_id = null;
		newWorkflow.action_bot_id = null;
		newWorkflow.action_inbox_id = null;
		newWorkflow.action_tag_id = 0;
		newWorkflow.action_users = null;
		newWorkflow.action_group_id = 0;
		newWorkflow.action_conversation_id = 0;
		newWorkflow.action_tag_id = 0;

		if (newWorkflow) {
			let workflowJSON = JSON.stringify(newWorkflow, null, 4);
			if (navigator.clipboard) {
				navigator.clipboard.writeText(workflowJSON);
				ToastService.info(t("Copied to clipboard"));
				return;
			}

			//ie 11 hack
			if (window.clipboardData) {
				window.clipboardData.setData("text", workflowJSON);
				ToastService.info(t("Copied to clipboard!"));
				return;
			}
		}
	};

	onConfirmDeleteWorkflow = status => {
		this.update({
			showConfirmDeleteModal: true,
			status: status
		});
	};

	onConfirmEnableLoggingWorkflow = () => {
		this.update({
			showConfirmLoggingModal: true
		});
	};

	onWorkflowChanged = async workflow => {
		if (!this.props.onWorkflowChanged) {
			return;
		}

		await this.props.onWorkflowChanged(workflow);
		this.forceUpdate();
	};

	fillOutActionConfigurationData = async () => {
		let { workflow, workflowsConfig } = this.props;

		if (!workflowsConfig) {
			return null;
		}

		let actionConfiguration = workflowsConfig.actionInfo[workflow.action_type].actionConfig;

		// Start by setting them equal to what it already is
		let workflowActionConfig = null;

		// If it's completely null, set to an empty object
		if (!workflow.action_config) {
			workflowActionConfig = {};
		} else {
			workflowActionConfig = JSON.parse(JSON.stringify(workflow.action_config));
		}

		for (const actionConfig of Object.values(actionConfiguration)) {
			let field = workflowsConfig.actionConfig[actionConfig.id];

			if (typeof workflowActionConfig[field.field] === "undefined") {
				workflowActionConfig[field.field] = field.default;
			}
		}

		return workflowActionConfig;
	};

	onWorkflowStatusChange = async confirm => {
		let { workflow } = this.props;
		let { status } = this.state;
		await this.update({
			showConfirmDeleteModal: false
		});

		if (confirm) {
			if (workflow.id) {
				// If we've already created it, update it's status to the new status. This will force an update
				await this.onStatusChange({ value: status });

				// Save the workflow
				let confirm = true;
				await this.onSaveWorkflow(confirm);
			}
			// Remove it from the list of workflows
			else if (status === STATUS.deleted && this.props.onRemoveWorkflow) {
				await this.props.onRemoveWorkflow();
			} else {
				await this.onStatusChange({ value: status });
			}
		}
	};

	onWorkflowLoggingChange = async confirm => {
		let { logging } = this.state;
		let { workflow } = this.props;

		await this.update({
			showConfirmLoggingModal: false
		});

		if (!workflow || this.isReadOnly()) {
			return;
		}

		if (confirm) {
			await this.update({ logging: !logging, saveable: true });

			await this.onWorkflowChanged(workflow);

			// Save the workflow
			await this.onSaveWorkflow(confirm);
		}
	};

	onNameChange = async event => {
		await this.update({
			name: event.target.value,
			saveable: true,
			expanded: true
		});
	};

	onPriorityChange = async event => {
		await this.update({
			priority: event.target.value,
			saveable: true,
			expanded: true
		});
	};

	onTriggerChange = async option => {
		let { workflow } = this.props;
		if (!workflow || this.isReadOnly()) {
			return;
		}

		workflow.trigger_type = option.value;
		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onActionChange = async option => {
		let { workflow, workflowsConfig } = this.props;
		if (!workflow || this.isReadOnly()) {
			return;
		}
		await this.update({
			existsActionFields: false
		});

		workflow.action_type = option.value;
		workflow.action_config = null; // Empty the action config
		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);

		await this.update({
			saveable: true,
			existsActionFields:
				workflowsConfig.actionInfo && workflowsConfig.actionInfo[workflow.action_type] && workflowsConfig.actionInfo[workflow.action_type].actionFields
		});
	};

	onStatusChange = async option => {
		let { workflow } = this.props;

		if (!workflow || this.isReadOnly()) {
			return;
		}

		let status = option.value;
		await this.update({
			saveable: true,
			status
		});

		if (!workflow.id) {
			workflow.status = status;
		}

		await this.onWorkflowChanged(workflow);
	};

	onConditionOperatorChange = async (conditionIndex, option) => {
		let { workflow } = this.props;
		if (!workflow || !workflow.conditions || this.isReadOnly()) {
			return;
		}

		if (!option || option.length < 1) {
			delete workflow.conditions[conditionIndex].operators;
		} else {
			workflow.conditions[conditionIndex].operators = option.map(item => item.value);
		}

		await this.update({
			saveable: true
		});
		await this.onWorkflowChanged(workflow);
	};

	onConditionTypeChange = async (conditionIndex, option) => {
		let { workflow, workflowsConfig } = this.props;
		if (!workflow || !workflow.conditions || this.isReadOnly()) {
			return;
		}

		workflow.conditions[conditionIndex].type = option.value;

		// Get the condition information for this condition type
		let conditionInfo = workflowsConfig.conditionInfo[option.value];

		// reset params
		workflow.conditions[conditionIndex].params = {};

		// If there are params specified for this condition type, we can replace the current params with the ones specified for this condition type
		if (conditionInfo.params && Object.keys(conditionInfo.params).length > 0) {
			// Go through the default values and add them to the param list
			for (const param of Object.values(conditionInfo.params)) {
				workflow.conditions[conditionIndex].params[param.id] = param.default ? param.default : "";
			}
		}

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onRemoveCondition = async conditionIndex => {
		let { workflow } = this.props;
		if (!workflow || !workflow.conditions) {
			return;
		}

		workflow.conditions.splice(conditionIndex, 1);
		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onAddParameter = async (conditionIndex, parameter = null) => {
		let { workflow, workflowsConfig } = this.props;
		if (!workflow || !workflow.conditions || this.isReadOnly()) {
			return;
		}

		if (!workflow.conditions[conditionIndex].params) {
			workflow.conditions[conditionIndex].params = {};
		}

		if (Object.keys(workflow.conditions[conditionIndex].params).length > 5) {
			return;
		}

		if (parameter && parameter.length > 0) {
			let condition = workflow.conditions[conditionIndex];
			let conditionInfo = workflowsConfig.conditionInfo[condition.type];
			let param = conditionInfo.params[parameter];

			if (workflow.conditions[conditionIndex].params[param.id]) {
				return;
			}

			workflow.conditions[conditionIndex].params[param.id] = param.default ? param.default : "";

			await this.update({
				saveable: true
			});
			await this.onWorkflowChanged(workflow);

			return;
		}

		let counter = 0;
		let newParamMade = false;

		while (!newParamMade) {
			if (counter > 4) {
				newParamMade = true;
			} else if (workflow.conditions[conditionIndex].params[`param ${counter}`]) {
				counter++;
			} else {
				workflow.conditions[conditionIndex].params[`param ${counter}`] = `value ${counter}`;
				newParamMade = true;
			}
		}

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onRemoveParameter = async (conditionIndex, key) => {
		let { workflow } = this.props;
		if (!workflow || !workflow.conditions || !workflow.conditions[conditionIndex] || !workflow.conditions[conditionIndex].params || this.isReadOnly()) {
			return;
		}

		delete workflow.conditions[conditionIndex].params[key];
		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onParamChange = async (conditionIndex, newParams) => {
		let { workflow } = this.props;
		if (!workflow || !workflow.conditions || !workflow.conditions[conditionIndex] || !workflow.conditions[conditionIndex].params || this.isReadOnly()) {
			return;
		}

		// Update the params
		workflow.conditions[conditionIndex].params = newParams;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onParamValueChange = async (conditionIndex, param, value) => {
		let { workflow } = this.props;
		if (!workflow || !workflow.conditions || !workflow.conditions[conditionIndex] || !workflow.conditions[conditionIndex].params || this.isReadOnly()) {
			return;
		}

		workflow.conditions[conditionIndex].params[param] = value;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onAddCondition = async conditionIndex => {
		let { workflowsConfig, workflow } = this.props;
		if (!workflow || this.isReadOnly() || !workflowsConfig) {
			return;
		}

		if (!workflow.conditions) {
			workflow.conditions = [];
		}

		if (workflow.conditions.length > 4) {
			return;
		}

		let newCondition = { type: workflowsConfig.assignableConditions[0].value, params: {} };
		workflow.conditions.splice(conditionIndex + 1, 0, newCondition);
		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onSaveConfirm = () => {
		if (!this.state.saveable || this.isReadOnly()) {
			return;
		}
		this.update({
			showConfirmSaveModal: true
		});
	};

	onSaveWorkflow = async confirm => {
		let { saveable } = this.state;
		let { t } = this.props;

		if (!confirm) {
			await this.update({
				showConfirmSaveModal: false
			});
			return;
		}

		if (!saveable) {
			return;
		}

		await this.update({
			saveable: false,
			showConfirmSaveModal: false,
			saving: true
		});

		let { workflow, workflowsConfig } = this.props;
		let { name, priority, status, logging } = this.state;

		// Don't make changes to the prop object directly
		let workflowChanges = { ...workflow };
		workflowChanges.name = name;
		workflowChanges.priority = priority;
		workflowChanges.status = status;
		workflowChanges.logging = logging;

		let existsActionConfig =
			workflowsConfig.actionInfo &&
			workflowsConfig.actionInfo[workflow.action_type] &&
			workflowsConfig.actionInfo[workflow.action_type].actionConfig &&
			Object.keys(workflowsConfig.actionInfo[workflow.action_type].actionConfig).length > 0;

		// Fill out workflow action configurations that havn't been changed with their defaults
		if (existsActionConfig) {
			workflowChanges.action_config = await this.fillOutActionConfigurationData();
		}

		let newWorkflow = null;
		if (this.props.onWorkflowChanged) {
			newWorkflow = await this.props.onWorkflowChanged(workflowChanges, true);
		}

		let created = !workflow.id; // If the workflow has an Id, it has already been created

		if (!newWorkflow) {
			ToastService.error(`${created ? t("An error occurred trying to create the workflow") : t("An error occurred trying to update the workflow")}`);
			await this.update({
				saving: false
			});
			return;
		}

		ToastService.info(created ? t("Created workflow") : t("Updated workflow"));

		this.forceUpdate();

		await this.update({
			saving: false
		});
	};

	triggerColor = () => {
		let { workflow, workflowsConfig } = this.props;

		let info = workflowsConfig[`triggerInfo`][workflow.trigger_type];

		if (!info) {
			return "red";
		}

		return info.color;
	};

	onActionFieldSelectChange = async (field, value) => {
		let { workflow } = this.props;
		if (!workflow || this.isReadOnly()) {
			return;
		}
		workflow[field] = value;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onActionFieldInputChange = async (actionFieldId, value) => {
		let { workflow, workflowsConfig } = this.props;
		if (!workflow || this.isReadOnly() || !workflowsConfig) {
			return;
		}

		if (workflowsConfig.actionFields[actionFieldId].type === "number" && !value.match(/^[0-9]*$/)) {
			return;
		}

		let field = workflowsConfig.actionFields[actionFieldId].field;
		workflow[field] = value;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onActionFieldSwitchChange = async (field, checked) => {
		let { workflow } = this.props;

		if (!workflow || this.isReadOnly()) {
			return;
		}

		workflow[field] = checked;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onActionConfigSelectChange = async (field, value) => {
		let { workflow } = this.props;
		if (!workflow || this.isReadOnly()) {
			return;
		}

		// If no object, create one
		if (!workflow.action_config) {
			workflow.action_config = {};
		}

		workflow.action_config[field] = value;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onActionConfigInputChange = async (actionFieldId, value) => {
		let { workflow, workflowsConfig } = this.props;
		if (!workflow || this.isReadOnly() || !workflowsConfig) {
			return;
		}

		if (workflowsConfig.actionConfig[actionFieldId].type === "number" && !value.match(/^[0-9]*$/)) {
			return;
		}

		// If no object, create one
		if (!workflow.action_config) {
			workflow.action_config = {};
		}

		let field = workflowsConfig.actionConfig[actionFieldId].field;

		if (workflowsConfig.actionConfig[actionFieldId].type === "number") {
			workflow.action_config[field] = Number(value);
		} else {
			workflow.action_config[field] = value;
		}

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onActionConfigSwitchChange = async (field, checked) => {
		let { workflow } = this.props;

		if (!workflow || this.isReadOnly()) {
			return;
		}

		// If no object, create one
		if (!workflow.action_config) {
			workflow.action_config = {};
		}

		workflow.action_config[field] = checked;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	onActionConfigObjectChange = async (field, jsObject) => {
		let { workflow } = this.props;

		if (!workflow || this.isReadOnly()) {
			return;
		}

		// If no object, create one
		if (!workflow.action_config) {
			workflow.action_config = {};
		}

		workflow.action_config[field] = jsObject;

		await this.update({
			saveable: true
		});

		await this.onWorkflowChanged(workflow);
	};

	renderActionField = (actionField, index) => {
		let { workflow, workflowsConfig } = this.props;

		if (!actionField || !actionField.id) {
			return null;
		}

		let actionFieldInfo = workflowsConfig.actionFields[actionField.id];
		let field = { ...actionFieldInfo };
		field = Object.assign(field, actionField);

		let onChange = () => {};
		if (field.inputType === "select") {
			onChange = value => this.onActionFieldSelectChange(field.field, value);
		} else if (field.inputType === "text") {
			onChange = value => this.onActionFieldInputChange(field.id, value);
		} else if (field.inputType === "switch") {
			onChange = checked => this.onActionFieldSwitchChange(field.field, checked);
		}
		return <ActionField key={index} workflow={workflow} field={field} onChange={onChange} />;
	};

	renderActionConfig = (actionConfig, index) => {
		let { workflow, workflowsConfig } = this.props;

		if (!actionConfig || !actionConfig.id) {
			return null;
		}

		let actionConfigInfo = workflowsConfig.actionConfig[actionConfig.id];
		let field = { ...actionConfigInfo };
		field = Object.assign(field, actionConfig);

		let onChange = () => {};
		if (field.inputType === "select") {
			onChange = value => this.onActionConfigSelectChange(field.field, value);
		} else if (field.inputType === "text") {
			onChange = value => this.onActionConfigInputChange(field.id, value);
		} else if (field.inputType === "switch") {
			onChange = checked => this.onActionConfigSwitchChange(field.field, checked);
		} else if (field.inputType === "object") {
			onChange = o => this.onActionConfigObjectChange(field.field, o);
		}
		return <ActionConfig key={index} workflow={workflow} workflowsConfig={workflowsConfig} field={field} onChange={onChange} />;
	};

	renderHeader = () => {
		let { workflow, workflowsConfig, t } = this.props;
		let { name, priority, expanded, saveable, logging } = this.state;

		let triggerName = workflowsConfig.triggerInfo[workflow.trigger_type] ? workflowsConfig.triggerInfo[workflow.trigger_type].name : null;
		let actionName = workflowsConfig.actionInfo[workflow.action_type] ? workflowsConfig.actionInfo[workflow.action_type].name : null;

		if (triggerName && actionName) {
			var summary = `${triggerName} → ${actionName}`;
		}

		let doesWorkflowExist = workflow.id;
		let isWorkflowDeleted = workflow.status === STATUS.deleted;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		return (
			<div className="workflow__content__header">
				<div className="workflow__content__header__left">
					<input
						id={`${doesWorkflowExist ? workflow.id : UtilityService.uuid()}-name`}
						name="name"
						className="Common__input workflow__content__header__left__name"
						placeholder={t("Name ...")}
						value={name}
						onChange={this.onNameChange}
						disabled={this.isReadOnly()}
					/>
					<div className="workflow__content__header__left__label">Priority: </div>
					<input
						id={`${doesWorkflowExist ? workflow.id : UtilityService.uuid()}-priority`}
						name="priority"
						className="Common__input fnctst-workflow-priority"
						placeholder={t("Priority ...")}
						style={{ width: 50 }}
						value={priority}
						onChange={this.onPriorityChange}
						disabled={this.isReadOnly()}
					/>
					{workflow && !doesWorkflowExist && (
						<div className="workflow__content__header__left__label workflow__content__header__left--warning">({t("Unsaved")})</div>
					)}
					{saveable && doesWorkflowExist ? (
						<div className="workflow__content__header__left__label workflow__content__header__left--warning">({t("Unsaved Changes")})</div>
					) : null}
					{summary && <div className="workflow__content__header__left__label">{summary}</div>}
				</div>
				<div className="workflow__content__header__right">
					{isSuperOrCs && doesWorkflowExist ? (
						<Action icon={Icon.Copy} transparent={true} darkMode={true} label={t("Copy Workflow JSON")} onClick={() => this.onCopyWorkflow()} />
					) : null}

					<div
						className="dh-tip workflow__content__header__right__color workflow__content__header__right__control"
						tip={`${t("Trigger:")} ${workflow.trigger_type}`}
						style={{ backgroundColor: this.triggerColor() }}
					/>

					{isWorkflowDeleted && (
						<Action
							icon={Icon.ZapOff}
							transparent={true}
							darkMode={true}
							label={t("Set to inactive")}
							onClick={() => this.onConfirmDeleteWorkflow("inactive")}
						/>
					)}
					{!isWorkflowDeleted && (
						<>
							{doesWorkflowExist ? (
								<Action
									icon={Icon.AlignLeft}
									active={logging}
									darkMode={true}
									label={logging ? t("Disable Logging") : t("Enable Logging")}
									onClick={() => this.onConfirmEnableLoggingWorkflow()}
								/>
							) : null}
							{/* Get activating and inactivating to work. Needs to bubble up to workflows.js */}
							<ToggleSwitch
								id="toggle-status"
								checked={workflow.status === STATUS.active ? true : false}
								onChange={checked => this.onConfirmDeleteWorkflow(checked ? STATUS.active : STATUS.inactive)}
								label={workflow.status === STATUS.active ? t("Disable Workflow") : t("Enable Workflow")}
							/>
							<Action icon={Icon.Trash2} transparent={true} darkMode={true} label={t("Delete")} onClick={() => this.onConfirmDeleteWorkflow("deleted")} />
						</>
					)}

					<Action icon={expanded ? Icon.ChevronUp : Icon.ChevronDown} transparent={true} darkMode={true} label={t("Expand")} onClick={this.onToggleExpanded} />
				</div>
			</div>
		);
	};

	renderInfo = (workflowInfoType, workflowField) => {
		let { workflow, workflowsConfig } = this.props;

		if (!workflowsConfig[workflowInfoType]) {
			return null;
		}

		let infoType = workflowsConfig[workflowInfoType]; // Eg. triggerInfo
		let workflowFieldValue = workflow[workflowField]; // Get a specific value of the current workflow
		let workflowFieldInfo = infoType[workflowFieldValue]; // Information about this field

		if (!workflowFieldInfo) {
			return null;
		}

		return (
			<div className="workflow__content__more__section__text">
				<div className="workflow__content__more__section__text__description">{workflowFieldInfo.description}</div>
				{/* TODO: DH 2351 Specify Which action fields are recommended */}
				{/* <div>{JSON.stringify(info.actionFields)}</div> */}
			</div>
		);
	};

	renderCollapsibleSection = () => {
		let { workflow, workflowsConfig, t } = this.props;
		let { expanded, saveable, saving, existsActionFields } = this.state;

		if (!workflowsConfig) {
			return null;
		}

		let triggerValue = {
			value: workflow.trigger_type,
			label: workflowsConfig.triggerInfo[workflow.trigger_type] ? workflowsConfig.triggerInfo[workflow.trigger_type].name : null
		};
		let actionValue = {
			value: workflow.action_type,
			label: workflowsConfig.actionInfo[workflow.action_type] ? workflowsConfig.actionInfo[workflow.action_type].name : null
		};

		let existsActionConfig =
			workflowsConfig.actionInfo &&
			workflowsConfig.actionInfo[workflow.action_type] &&
			workflowsConfig.actionInfo[workflow.action_type].actionConfig &&
			Object.keys(workflowsConfig.actionInfo[workflow.action_type].actionConfig).length > 0;

		return (
			<Collapse in={expanded}>
				<div>
					<div className="workflow__content__more">
						<div className="workflow__content__more__section">
							<div className="workflow__content__more__section__header">{t("Trigger")}</div>
							<Select
								className="triggers-select"
								options={workflowsConfig.assignableTriggers}
								value={triggerValue}
								onChange={this.onTriggerChange}
								placeholder={t("Trigger...")}
								isDisabled={this.isReadOnly()}
							/>
							{this.renderInfo("triggerInfo", "trigger_type")}
						</div>
						<div className="workflow__content__more__section workflow__content__more__section--conditions">
							<div className="workflow__content__more__section__header">{t("Conditions")}</div>
							{expanded && this.renderConditions()}
						</div>
						<div className="workflow__content__more__section workflow__content__more__section--action">
							<div className="workflow__content__more__section__header--action">
								<div className="workflow__content__more__section__header">{t("Action")}</div>
								<Select
									className="actions-select"
									options={workflowsConfig.assignableActions}
									value={actionValue}
									onChange={this.onActionChange}
									placeholder={t("Action...")}
									isDisabled={this.isReadOnly()}
								/>
								{this.renderInfo("actionInfo", "action_type")}
								{existsActionFields && Object.keys(workflowsConfig.actionInfo[workflow.action_type].actionFields).length > 0 && (
									<div className="workflow__content__more__section__action-fields">
										{Object.values(workflowsConfig.actionInfo[workflow.action_type].actionFields).map(
											(actionField, index) => expanded && this.renderActionField(actionField, index)
										)}
									</div>
								)}
								{existsActionConfig && <div className="workflow__content__more__section__subheader">{t("Action Config")}</div>}
								{existsActionConfig && Object.keys(workflowsConfig.actionInfo[workflow.action_type].actionConfig).length > 0 && (
									<div className="workflow__content__more__section__action-config">
										{Object.values(workflowsConfig.actionInfo[workflow.action_type].actionConfig).map(
											(actionConfig, index) => expanded && this.renderActionConfig(actionConfig, index)
										)}
									</div>
								)}
							</div>
							{!this.isReadOnly() && (
								<div className="workflow__content__more__section__button">
									<div className={`mb-button mb-button--fit ${saveable ? "" : "mb-button--disabled"}`} onClick={this.onSaveConfirm}>
										{saving ? t("Saving") : t("Save")}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Collapse>
		);
	};

	renderConditions = () => {
		let { workflow, workflowsConfig, t } = this.props;

		if (!workflowsConfig) {
			return null;
		}

		return (
			<div className="workflow__content__more__conditions">
				{workflow.conditions &&
					workflow.conditions.map((condition, conditionIndex) => (
						<Condition
							key={`${conditionIndex}-${workflow.conditions.length}`}
							id={conditionIndex}
							workflowId={workflow.id ? workflow.id : `new-${UtilityService.uuid()}`}
							condition={condition}
							workflowsConfig={workflowsConfig}
							onConditionOperatorChange={option => this.onConditionOperatorChange(conditionIndex, option)}
							onConditionTypeChange={event => this.onConditionTypeChange(conditionIndex, event)}
							onAddParameter={key => this.onAddParameter(conditionIndex, key)}
							onRemoveParameter={key => this.onRemoveParameter(conditionIndex, key)}
							onRemoveCondition={() => this.onRemoveCondition(conditionIndex)}
							onParamChange={params => this.onParamChange(conditionIndex, params)}
							onParamValueChange={(key, value) => this.onParamValueChange(conditionIndex, key, value)}
						/>
					))}
				{!this.isReadOnly() && (
					<div className="workflow__content__more__conditions__add">
						{workflow.conditions && workflow.conditions.length < 5 && (
							<Action
								icon={Icon.PlusCircle}
								transparent={true}
								darkMode={true}
								label={t("Add Condition")}
								onClick={() => this.onAddCondition(workflow.conditions.length)}
							/>
						)}
					</div>
				)}
			</div>
		);
	};

	renderConfirmSaveModal = () => {
		let { name, showConfirmSaveModal } = this.state;
		let { workflow, workflowsConfig, t } = this.props;

		if (!workflowsConfig || !workflow || !workflowsConfig.actionInfo || !workflowsConfig.actionInfo[workflow.action_type]) {
			return null;
		}

		let requiredFields = Object.values(workflowsConfig.actionInfo[workflow.action_type].actionFields).filter(field => field.required === true);

		return (
			<Alert type="info" show={showConfirmSaveModal} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onSaveWorkflow}>
				<div className="workflow__confirm-save-alert">
					{requiredFields.map(field => {
						if (!workflowsConfig.actionFields[field.id]) {
							return null;
						}
						let workflowField = workflowsConfig.actionFields[field.id].field;
						if (workflow[workflowField] !== "" && workflow[workflowField] !== null) {
							return null;
						}

						return (
							<div className="text-danger" key={field.id}>
								{t("{{fieldName}} requires a value but it does not have one.", { fieldName: field.name })}
							</div>
						);
					})}
					<div className="text-center">{t("Are you sure you would like to save the workflow?")}</div>
				</div>
			</Alert>
		);
	};

	render() {
		let { workflow, index, t } = this.props;
		let { name, status, logging, showConfirmDeleteModal, showConfirmLoggingModal } = this.state;

		if (!workflow) {
			return null;
		}

		return (
			<>
				<div className={`workflow ${!workflow.id && "fnctst-new-workflow"} index-${index}`} style={{ borderLeftColor: this.triggerColor() }}>
					{/* TODO: DH 2352 Allow dragging and droping workflows*/}
					{/* <div className="workflow__toggle" onClick={this.onToggleExpanded}>
					{expanded ? <Icon.Minimize2 className="workflow__toggle__icon" size={16} /> : <Icon.Maximize2 className="workflow__toggle__icon" size={16} />}
					</div> */}
					<div className="workflow__content">
						{this.renderHeader()}
						{this.renderCollapsibleSection()}
					</div>
					<Alert
						type="warning"
						show={showConfirmDeleteModal}
						title={t("Are you sure?")}
						confirm={t("Yes")}
						cancel={t("No")}
						onClose={this.onWorkflowStatusChange}
					>
						<div className="text-center">
							{status === STATUS.active
								? t("Are you sure you would like to activate the workflow?")
								: status === STATUS.inactive
								? t("Are you sure you would like to deactivate the workflow?")
								: t("Are you sure you would like to delete the workflow?")}
						</div>
					</Alert>
					<Alert
						type="warning"
						show={showConfirmLoggingModal}
						title={t("Are you sure?")}
						confirm={t("Yes")}
						cancel={t("No")}
						onClose={this.onWorkflowLoggingChange}
					>
						<div className="text-center">
							{logging ? t("Are you sure you would like to disable logging the workflow?") : t("Are you sure you would like to enable logging the workflow?")}
						</div>
					</Alert>

					{this.renderConfirmSaveModal()}
				</div>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(Workflow);

import React, { Component } from "react";
import Select from "react-select";
import _ from "underscore";
import AsyncSelect from "react-select/lib/Async";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import debounce from "debounce-promise";
import ReactSwitch from "react-switch";
import ContentLoader from "react-content-loader";
import { withTranslation, Trans } from "react-i18next";

import { SM_STATES, SM_TYPES, SM_TYPES_FULL } from "../../constants/ScheduledMessages";
import { MODE, MEDIUM } from "../../constants/Messenger";
import { TEMPLATE_TYPE } from "../../constants/TemplateConstants";

import Modal from "../../components/common/DHModal";
import Alert from "../../components/common/Alert";
import TextArea from "../../components/common/TextArea";
import AttachmentItem from "../../components/common/AttachmentItem";
import TextBubble from "../../components/common/TextBubble";
import Checkbox from "../../components/common/Checkbox";
import BulkContactUpload from "../Bulk/Contacts/BulkContactUpload";
import List from "../../components/common/List";
import SendDraftModal from "./SendDraftModal";

import TagService from "../../services/TagService";
import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import TemplateService from "../../services/TemplateService";
import MessagesService from "../../services/MessagesService";
import UtilityService from "../../services/UtilityService";
import BulkSendService from "../../services/BulkSendService";
import LocationService from "../../services/LocationService";
import SupportChatService from "../../services/SupportChatService";

import "../../styles/css/components/commons/scheduled-message-modal.css";
import "react-datepicker/dist/react-datepicker.css";

const asyncSelectCustomStyles = {
	control: (provided, state) => {
		return {
			...provided,
			maxHeight: 150,
			overflow: "auto"
		};
	},
	group: (provided, state) => {
		return {
			...provided,
			height: 40
		};
	}
};

class ScheduledMessageModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			scheduledMessageId: null,
			saving: false,
			loading: false,

			content: "",
			templateVariables: [],
			recipients: [],
			minSendAfter: UtilityService.getNext15Minutes(),
			sendAfter: UtilityService.getNext15Minutes(),
			quota: null,
			scheduledMessage: null,
			state: null,

			type: this.props.type || SM_TYPES.general,
			scheduleMessageTypeOptions: [],

			disableTypeChange: false,

			showConfirmAlert: false,

			showFullMessagePreview: false,

			currentRecipients: 0,
			persistedRecipients: 0,

			attachmentsChanged: false,
			mediaFromSelectedTemplate: false,
			mediaIds: [],

			// For general messages
			generalTemplateId: 0,

			// For Review Invites
			reviewTemplates: [],
			selectedReviewTemplateId: 0,
			reviewTemplateTextWithoutReplacements: "",
			reviewTemplateTextWithReplacements: "",

			showBulkContactUpload: false,

			// For Schedule Message NPS
			isAnonymous: false,

			showSendDraftModal: false
		};

		// xxx - can't use lodash for asyncselect
		// https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
		this.onDebouncedSearchContactsOrGroups = debounce(this.searchContactsOrGroups.bind(this), 1000);
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.createScheduleMessageTypeOptions();
	}

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			let type = this.props.type || SM_TYPES.general;
			await this.update({ type });
			await this.resetComponent();
		}
	}

	async resetComponent() {
		let { sendAfter, type } = this.state;
		let { scheduledMessageId } = this.props;

		await this.update({
			generalTemplateId: 0,
			reviewTemplates: [],
			selectedReviewTemplateId: 0,

			// Reset attachment related states
			attachmentsChanged: false,
			mediaFromSelectedTemplate: false,
			mediaIds: []
		});

		if (type === SM_TYPES.reviewInvite) {
			// Get review invite templates
			await this.fetchReviewInviteTemplates();
		}

		if (scheduledMessageId) {
			await this.update({ disableTypeChange: true });
			await this.fetchScheduledMessage(scheduledMessageId);
			await this.update({ minSendAfter: UtilityService.getNext15Minutes() });
		} else {
			await this.updateQuota(sendAfter);
			await this.update({
				minSendAfter: UtilityService.getNext15Minutes(),
				scheduledMessageId: null,
				content: "",
				recipients: this.props.recipients ? this.props.recipients : [],
				persistedRecipients: 0,
				currentRecipients: 0,
				persistedSendAfter: null,
				sendAfter: UtilityService.getNext15Minutes(),
				state: null,
				disableTypeChange: false,

				isAnonymous: false
			});

			if (this.props.recipients && this.props.recipients.length > 0) {
				let currentRecipients = this.getCurrentRecipientCount();
				await this.update({
					currentRecipients
				});
			}
		}

		await this.fetchMessageReplacements();

		// Get the template text for the review invites
		await this.getTemplateText();
	}

	updateQuota = async sendAfter => {
		let quota = await BulkSendService.getScheduledMessageLimit(sendAfter);
		await this.update({
			quota
		});
	};

	async fetchReviewInviteTemplates() {
		let locationId = await UserService.getActiveLocation().id;

		let reviewTemplates = await TemplateService.fetchTemplates({ locationId, types: ["review_request"] });

		await this.update({ reviewTemplates });

		// Find the default review template
		let defaultTemplate = await TemplateService.extractDefaultTemplate(reviewTemplates);

		if (defaultTemplate) {
			await this.update({ selectedReviewTemplateId: defaultTemplate.id });
		}
	}

	fetchMessageReplacements = async () => {
		let { type } = this.state;

		let templateVariables = [];
		if (type === SM_TYPES.reviewInvite) {
			templateVariables = await TemplateService.getMessageVariablesForType(TEMPLATE_TYPE.reviewRequest);
		} else {
			templateVariables = await TemplateService.getMessageVariablesForType();
		}

		await this.update({ templateVariables });
	};

	getTemplateText = async () => {
		let { selectedReviewTemplateId } = this.state;
		let { t } = this.props;

		if (!selectedReviewTemplateId) {
			return;
		}

		let overwriteReplacements = {};
		overwriteReplacements.contactFull = "FirstName LastName"; // XXXXXXXXX NOT SURE

		let templateWithReplacements = await TemplateService.getTemplate({
			templateId: selectedReviewTemplateId,
			replaceMessageVariables: true,
			locationId: UserService.getActiveLocation().id,
			overwriteReplacements
		});

		let templateWithoutReplacements = await TemplateService.getTemplate({
			templateId: selectedReviewTemplateId
		});

		if (!templateWithReplacements || !templateWithoutReplacements) {
			return;
		}

		let media = null;
		// If this scheduled message has any media attachments
		if (templateWithoutReplacements.Media && templateWithoutReplacements.Media.length > 0) {
			media = templateWithoutReplacements.Media[0].download_url;
		}

		if (media) {
			await this.update({
				mediaIds: [templateWithoutReplacements.Media[0].id]
			});
		} else {
			await this.update({
				mediaIds: []
			});
		}

		await this.update({
			reviewTemplateTextWithoutReplacements: templateWithoutReplacements.msg_text,
			reviewTemplateTextWithReplacements: templateWithReplacements.msg_text
		});
	};

	createScheduleMessageTypeOptions = async () => {
		let isGeneralEnabled = LocationService.isScheduledGeneralEnabled();
		let isNpsEnabled = LocationService.isNpsPermissible() && LocationService.isScheduledNpsEnabled();
		let isReviewInvitesEnabled = LocationService.isReviewInvitesEnabled() && LocationService.isScheduledReviewInvitesEnabled();
		let isReengagementEnabled = LocationService.isSMReengagementsEnabled();

		// Create options for types
		let scheduleMessageTypeOptions = [];

		if (isGeneralEnabled) {
			scheduleMessageTypeOptions.push({
				label: SM_TYPES_FULL.general,
				value: SM_TYPES.general
			});
		}
		if (isNpsEnabled) {
			scheduleMessageTypeOptions.push({
				label: SM_TYPES_FULL.nps,
				value: SM_TYPES.nps
			});
		}
		if (isReviewInvitesEnabled) {
			scheduleMessageTypeOptions.push({
				label: SM_TYPES_FULL.reviewInvite,
				value: SM_TYPES.reviewInvite
			});
		}
		if (isReengagementEnabled) {
			scheduleMessageTypeOptions.push({
				label: SM_TYPES_FULL.reengagement,
				value: SM_TYPES.reengagement
			});
		}

		await this.update({ scheduleMessageTypeOptions });
	};

	fetchScheduledMessage = async scheduledMessageId => {
		let { t } = this.props;

		if (!scheduledMessageId) {
			return;
		}

		await this.update({ loading: true });

		let sm = await MessagesService.getScheduledMessage(scheduledMessageId);

		if (!sm) {
			await this.update({ loading: false });
			return;
		}

		let set = sm.Contacts.map(c => {
			return {
				label: `${t("Contact")}: ${c.name} - ${MessagesService.getMediumData(c.preferred_medium, c.phone, c.email)}`,
				value: c.id,
				type: "contact"
			};
		});

		set = set.concat(
			sm.Tags.map(g => {
				return {
					label: `${t("Group")}: ${g.name}`,
					value: g.id,
					type: "group",
					contacts: g.Contacts
				};
			})
		);

		let sendAfter = moment(sm.send_after).toDate();

		await this.updateQuota(sendAfter);
		await this.update({
			scheduledMessageId,
			recipients: set,
			persistedRecipients: sm.recipients,
			currentRecipients: 0,
			content: sm.content,

			persistedSendAfter: sendAfter,
			sendAfter,
			state: sm.state,
			loading: false,

			generalTemplateId: sm.template_id,

			selectedReviewTemplateId: sm.template_id,
			type: sm.type,

			isAnonymous: sm.is_anonymous
		});

		// For now we only have the ability to send one attachment at a time
		let media = null;

		// If this scheduled message has any media attachments
		if (sm.Media && sm.Media.length > 0) {
			media = sm.Media[0].download_url;
		}

		if (media) {
			await this.update({
				mediaIds: [sm.Media[0].id]
			});
		} else {
			await this.update({
				mediaIds: []
			});
		}
	};

	onCancel = async () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	onToggleShowFullMessagePreview = async () => {
		let { showFullMessagePreview } = this.state;
		await this.update({ showFullMessagePreview: !showFullMessagePreview });
	};

	onSave = async () => {
		let {
			scheduledMessageId,
			content,
			recipients,
			sendAfter,
			status,
			attachmentsChanged,
			mediaIds,
			generalTemplateId,
			selectedReviewTemplateId,
			isAnonymous
		} = this.state;
		let { type } = this.state;
		let { t } = this.props;

		if (!this.isValid()) {
			return;
		}

		let tagIds = [];
		let contactIds = [];

		for (let r of recipients) {
			if (r.type === "contact") {
				contactIds.push(r.value);
			} else {
				tagIds.push(r.value);
			}
		}

		await this.update({ saving: true });

		try {
			let locationId = UserService.getActiveLocation().id;
			let companyId = UserService.getActiveCompany().id;

			// If we are uploading or replacing a new attachment. For now we only have one attachment possible.
			// If we remove all attachments: mediaIds should be []
			// Normally, we should find which medias are new/removed and update the mediaIds list
			let newMediaIds = [];
			if ((type === SM_TYPES.general || type === SM_TYPES.reviewInvite) && attachmentsChanged && mediaIds && mediaIds.length > 0) {
				// For now, we only support 1 media
				let uploadedMedia = await MessagesService.uploadLocalMedia({
					mediaIds: mediaIds,
					mode: MODE.customer,
					medium: MEDIUM.sms.key,
					limit: 1,
					companyId,
					locationId
				});

				if (!uploadedMedia) {
					ToastService.error(t("Error uploading media."));
					await this.update({ saving: false });
					return;
				}

				newMediaIds = uploadedMedia.map(m => m.id);
				MessagesService.clearLocalMedia();
			}

			if (newMediaIds && newMediaIds.length > 0) {
				mediaIds = newMediaIds;
			}

			let user = await UserService.get();

			let sm = null;

			let templateId = null;
			if (type === SM_TYPES.reviewInvite) {
				templateId = selectedReviewTemplateId;
			} else if (type === SM_TYPES.general) {
				templateId = generalTemplateId;
			}

			if (!scheduledMessageId) {
				sm = await MessagesService.createScheduledMessage({
					locationId,
					tagIds,
					contactIds,
					mediaIds,
					senderId: user.id,
					sendAfter,
					content,
					type,
					templateId,
					isAnonymous
				});
			} else {
				sm = await MessagesService.updateScheduledMessage({
					scheduledMessageId,
					tagIds,
					contactIds,
					mediaIds,
					senderId: user.id,
					sendAfter,
					content,
					type,
					templateId,
					status,
					isAnonymous
				});
			}

			// Reset attachment data
			await this.update({
				mediaIds
			});

			if (!sm) {
				let toast =
					scheduledMessageId === null
						? t("An error occurred trying to schedule your message.")
						: t("An error occurred trying to update your scheduled message.");
				ToastService.info(toast);
				await this.update({ saving: false });
				return;
			}

			if (status === "deleted") {
				ToastService.info(t("Scheduled message has been deleted!"));
			} else if (!scheduledMessageId) {
				ToastService.info(t("Successfully scheduled message!"));
			} else {
				ToastService.info(t("Successfully updated scheduled message!"));
			}

			if (this.props.onHide) {
				this.props.onHide(sm);
			}
		} catch (error) {
			console.log(error);
		}

		await this.update({ saving: false });
	};

	getCurrentRecipientCount() {
		let { recipients } = this.state;

		let contactIds = [];

		for (let r of recipients) {
			if (r.type === "contact") {
				contactIds.push(r.value);
			} else {
				let groupContacts = r.contacts.map(c => {
					return c.id;
				});
				contactIds = contactIds.concat(groupContacts);
			}
		}

		contactIds = _.uniq(contactIds);

		let count = contactIds.length;

		return count;
	}

	hasTooManyDailyRecipients(sms = true) {
		let { quota, currentRecipients, persistedRecipients, scheduledMessageId, persistedSendAfter, sendAfter } = this.state;

		if (!quota) {
			return false;
		}

		let remaining = quota.dailySmsRemaining;

		if (!sms) {
			remaining = quota.dailyMmsRemaining;
		}

		// If the user is editing a scheduled message then current recipient count should be less than the persisted recipient count in the database
		if (scheduledMessageId && currentRecipients <= persistedRecipients) {
			return false;
		}
		// If the user is editing a scheduled message and the current number of recipients they have selected is greator than what has been persisted then we should add the persisted number of recipients back into the remaining total for that day, ignore this condition if they decide to change the date
		else if (scheduledMessageId && moment(persistedSendAfter).format("YYYY-MM-DD") === moment(sendAfter).format("YYYY-MM-DD")) {
			remaining += persistedRecipients;
		}

		// If the current amount of recipients exceeds the remaing amount
		return currentRecipients > remaining;
	}

	hasTooManyMonthlyRecipients(sms = true) {
		let { quota, currentRecipients, mediaIds, persistedRecipients } = this.state;

		if (!quota) {
			return false;
		}

		let remaining = quota.monthlySmsRemaining;

		if (!sms) {
			remaining = quota.monthlyMmsRemaining;
		}

		remaining -= currentRecipients;

		return remaining < 0;
	}

	isValid = () => {
		let { content, recipients, saving, mediaIds } = this.state;
		let { type } = this.state;

		let isValid = false;
		let hasMedia = mediaIds && mediaIds.length > 0;

		// Validity depends on the type of scheduled message
		if (type === SM_TYPES.general) {
			isValid =
				(content.length > 0 || hasMedia) &&
				recipients.length > 0 &&
				((hasMedia && !this.hasTooManyDailyRecipients(false) && !this.hasTooManyMonthlyRecipients(false)) ||
					(!hasMedia && !this.hasTooManyDailyRecipients(true) && !this.hasTooManyMonthlyRecipients(true))) &&
				!saving;
		} else if (type === SM_TYPES.nps || type === SM_TYPES.reviewInvite || type === SM_TYPES.reengagement) {
			isValid =
				recipients.length > 0 &&
				((hasMedia && !this.hasTooManyDailyRecipients(false) && !this.hasTooManyMonthlyRecipients(false)) ||
					(!hasMedia && !this.hasTooManyDailyRecipients(true) && !this.hasTooManyMonthlyRecipients(true))) &&
				!saving;
		}

		// If it is currenlty valid, and we have media, check that mms enabled
		if (isValid && hasMedia) {
			isValid = LocationService.isScheduledMessagesMMSEnabled();
		}

		return isValid;
	};

	onContentChange = async (event, file = null) => {
		await this.update({ content: event.target.value, generalTemplateId: event.templateId || 0 });
		if (file) {
			this.onFileAdded(file);
			await this.update({ mediaFromSelectedTemplate: true });
		}
	};

	searchContactsOrGroups = async searchTerm => {
		let { t } = this.props;
		let locationId = UserService.getActiveLocation().id;

		let groups = await TagService.getTags(locationId, { searchTerm, type: "group", includeContacts: true });

		groups = groups.map(g => {
			return {
				label: `${t("Group")}: ${g.name}`,
				value: g.id,
				type: "group",
				contacts: g.Contacts
			};
		});

		let contacts = await MessagesService.searchConversations({ searchTerm, locationId, receiveTransactionalSms: true, receiveTransactionalEmail: true });

		let results = contacts.map(c => {
			return {
				label: `${t("Contact")}: ${c.name} - ${c.medium_data}`,
				value: c.id,
				type: "contact"
			};
		});

		return groups.concat(results);
	};

	onResultSelect = async results => {
		await this.update({ recipients: results });

		let currentRecipients = this.getCurrentRecipientCount();
		await this.update({
			currentRecipients
		});
	};

	onTypeChange = async type => {
		await this.update({ type: type.value });
		await this.resetComponent();
	};

	onDateChange = async value => {
		let currentRecipients = this.getCurrentRecipientCount();
		this.updateQuota(value);
		this.update({ sendAfter: value, currentRecipients });
	};

	onConfirmSave = () => {
		if (!this.isValid()) {
			return;
		}

		this.update({
			showConfirmAlert: true
		});
	};

	onConfirmAlertClose = confirm => {
		this.update({
			showConfirmAlert: false
		});

		if (confirm) {
			this.onSave();
		}
	};

	onFileAdded = async files => {
		if (!files || files.length < 1) {
			return;
		}

		let file = files[0];

		let media = MessagesService.storeLocalMedia({ file });

		if (!media) {
			console.log(`Error adding files to local media.`, files);
			return;
		}

		let localMediaId = media.id;

		let { mediaIds } = this.state;

		// For now, we only support one media attachment
		mediaIds = [];
		mediaIds.push(localMediaId);

		await this.update({
			mediaIds,
			attachmentsChanged: true
		});
	};

	onHideAttachment = async () => {
		await this.update({
			attachmentsChanged: true,
			mediaIds: []
		});
	};

	titleText = () => {
		let { scheduledMessageId, state } = this.state;
		let { type } = this.state;
		let { t } = this.props;

		let typeName = "";
		if (SM_TYPES.general === type) {
			typeName = SM_TYPES_FULL.general;
		} else if (SM_TYPES.nps === type) {
			typeName = SM_TYPES_FULL.nps;
		} else if (SM_TYPES.reviewInvite === type) {
			typeName = SM_TYPES_FULL.reviewInvite;
		} else {
			typeName = SM_TYPES_FULL.reengagement;
		}

		let title = "";

		title = scheduledMessageId === null ? `${t("New Scheduled")} ${typeName}` : `${t("Update Scheduled")} ${typeName}`;

		if (state === SM_STATES.sent) {
			title = `${t("View Scheduled")} ${typeName} (${t("Sent")})`;
		}

		return title;
	};

	onTemplateChange = async option => {
		let templateId = parseInt(option.value, 10);
		await this.update({ selectedReviewTemplateId: templateId });
		await this.getTemplateText();
	};

	onShowBulkContactUpload = async () => {
		await this.update({ showBulkContactUpload: true });
	};

	onHideBulkContactUpload = async () => {
		await this.update({ showBulkContactUpload: false });
	};

	onContactUploadCompleted = async uploadedContacts => {
		let { recipients } = this.state;
		let { t } = this.props;

		await this.update({ showBulkContactUpload: false });

		// Convert it into a format something scheduled message modal understands
		// {label, type, value}. ex: {label: "Contact: Awd - wadwadwa@dawd.com" type: "contact" value: 382365}
		let newRecipients = uploadedContacts.map(contact => {
			let sendTo = contact.phone ? contact.phone : contact.email;
			let contactName = contact.name;
			let contactId = contact.contact_id;

			return {
				label: `${t("Contact")}: ${contactName} - ${sendTo}`,
				type: "contact",
				value: contactId
			};
		});

		// Make a new array that has the original recipients plus these new ones
		let combinedRecipients = recipients.slice();
		combinedRecipients = combinedRecipients.concat(newRecipients);

		// Remove duplicates
		let ids = combinedRecipients.map(c => c.value);
		let uniqueRecipients = combinedRecipients.filter(({ value }, index) => !ids.includes(value, index + 1));

		await this.update({ recipients: uniqueRecipients });

		// Updated recipients
		let currentRecipients = this.getCurrentRecipientCount();
		await this.update({ currentRecipients });
	};

	onSendDraft = async () => {
		if (!this.isValid()) {
			return;
		}

		await this.update({ showSendDraftModal: true });
	};

	onHideSendDraftModal = async () => {
		await this.update({ showSendDraftModal: false });
	};

	renderContent = () => {
		let { content, templateVariables, state } = this.state;
		let { type } = this.state;
		let { t } = this.props;

		if (type !== SM_TYPES.general && type !== SM_TYPES.reengagement) {
			return null;
		}

		return (
			<>
				<div className="sm-modal__field">{t("Content")}</div>
				<TextArea
					disabled={state === SM_STATES.sent}
					name="msg-text"
					id="msg-text"
					type="text"
					onChange={this.onContentChange}
					value={content}
					required
					height={130}
					rows={5}
					style={{ resize: "none" }}
					showFeedbackFaces={true}
					showFeedbackLength={true}
					showScrollbar={false}
					placeholder={t("Type a message ...")}
					showVariables={true}
					showCustomFields={true}
					varDropdownVisibleY={-250}
					varDropdownVisibleX={-20}
					varDropdownVisibleZ={0}
					variables={templateVariables}
					blueBorder
					attachments={state !== SM_STATES.sent}
					onFileAdded={this.onFileAdded}
				/>
			</>
		);
	};

	renderMedia = () => {
		let { mediaIds } = this.state;
		let { t } = this.props;

		let hasMedia = mediaIds && mediaIds.length > 0;
		let showMMSWarning = hasMedia && !LocationService.isScheduledMessagesMMSEnabled();

		return (
			<>
				{hasMedia && (
					<div className="modal__attachments">
						<div className="modal__field">{t("Attachment")}</div>
						{mediaIds.map(mediaId => (
							<AttachmentItem key={mediaId} mediaId={mediaId} hide={this.onHideAttachment} />
						))}
					</div>
				)}
				{showMMSWarning && (
					<div className="sm-modal__no-mms">
						<div>{t("MMS for Scheduled Messages is not enabled. This means that no media or images will be sent out via schedule message.")}</div>
						<div>{t("To enabled MMS for Scheduled Messages, please contact support@demandhub.co or chat with us.")}</div>
						<br />
						<div className="mb-button mb-button--fit mb-button--center" onClick={() => SupportChatService.showNewMessage()}>
							{t("Chat with us")}
						</div>
					</div>
				)}
			</>
		);
	};

	renderAlertText = () => {
		let { type, sendAfter } = this.state;
		let { t } = this.props;

		let { localTimeZone, localTimeZoneShortHand } = UtilityService.getTimeZoneHelpers();
		let currentTime = moment(sendAfter).format("MMMM Do YYYY, h:mm A");

		if (type === SM_TYPES.general) {
			return (
				<>
					{t(
						"By clicking 'Yes', you confirm that this is a transactional message, not for marketing purposes, unless you have prior written consent. Are you sure you would like to send this at {{currentTime}}, in your local time {{- localTimeZone}} ({{localTimeZoneShortHand}})?",
						{
							currentTime: currentTime,
							localTimeZone: localTimeZone,
							localTimeZoneShortHand: localTimeZoneShortHand
						}
					)}
				</>
			);
		}

		return (
			<>
				{t("Are you sure you would like to send this at {{currentTime}}, in your local time {{- localTimeZone}} ({{localTimeZoneShortHand}})?", {
					currentTime: currentTime,
					localTimeZone: localTimeZone,
					localTimeZoneShortHand: localTimeZoneShortHand
				})}
			</>
		);
	};

	renderTemplates = () => {
		let {
			reviewTemplates,
			selectedReviewTemplateId,
			reviewTemplateTextWithReplacements,
			reviewTemplateTextWithoutReplacements,
			state,
			templateVariables,
			showFullMessagePreview
		} = this.state;
		let { type } = this.state;
		let { t } = this.props;

		if (type !== SM_TYPES.reviewInvite) {
			return null;
		}

		let templatesOptions = reviewTemplates.map(template => {
			return {
				label: template.name,
				value: template.id
			};
		});

		let template = reviewTemplates.find(t => t.id === selectedReviewTemplateId);
		let templateName = typeof template === "undefined" ? t("Unknown Template Name") : template.name;
		let templateHasBackup = template ? template.has_backup : false;

		let existsReviewTemplateTextWithReplacements = reviewTemplateTextWithReplacements && reviewTemplateTextWithReplacements.length > 0;
		let messagePreviewText = existsReviewTemplateTextWithReplacements ? reviewTemplateTextWithReplacements : t("No Text ...");

		let existsReviewTemplateTextWithoutReplacements = reviewTemplateTextWithoutReplacements && reviewTemplateTextWithoutReplacements.length > 0;
		let messageOriginalText = existsReviewTemplateTextWithoutReplacements ? reviewTemplateTextWithoutReplacements : t("No Text ...");

		let supportedVarsRegex = existsReviewTemplateTextWithReplacements ? new RegExp(`(${templateVariables.map(r => r.allPatterns).join("|")})`, "gi") : null;

		return (
			<>
				<div className="sm-modal__field">
					<span>{t("Review Invite Template")}</span>
					{templateHasBackup && (
						<span className="sm-modal__field__info" data-tip data-for="template-has-backup">
							<Icon.Info size={14} />
							<ReactTooltip id="template-has-backup" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="top">
								{t("This template has a backup. In cases where a message variable can't be used the template backup will be used...")}
							</ReactTooltip>
						</span>
					)}
				</div>
				<Select
					id="template-select"
					className="sm-modal__select"
					options={templatesOptions}
					value={{ label: templateName, value: selectedReviewTemplateId }}
					placeholder={t("Select a template ...")}
					onChange={this.onTemplateChange}
					isDisabled={state === SM_STATES.sent}
				/>

				<div className="sm-modal__review_invite_preview">
					<div className="sm-modal__review_invite_preview__title_container">
						<div className="sm-modal__review_invite_preview__title">{t("Message Preview")}</div>
						<div data-tip data-for="switchMessagePreviewContainer" className="sm-modal__review_invite_preview__switch">
							<ReactSwitch
								id="switchMessagePreview"
								height={22}
								width={38}
								checked={showFullMessagePreview}
								uncheckedIcon={false}
								checkedIcon={false}
								onColor="#60A9FF"
								offColor="#c5c5c5"
								onChange={() => {
									this.onToggleShowFullMessagePreview();
								}}
							/>
						</div>
						<ReactTooltip id="switchMessagePreviewContainer" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="top">
							{t("Show With Variables")}
						</ReactTooltip>
					</div>
					<div className="sm-modal__text-bubble">
						{!showFullMessagePreview && <TextBubble text={messageOriginalText} blue={true} highlight={true} highlightRegex={supportedVarsRegex} />}
						{showFullMessagePreview && <TextBubble text={messagePreviewText} blue={true} />}
					</div>
				</div>
			</>
		);
	};

	renderRemainingMessage = () => {
		let { sendAfter, quota, mediaIds, state } = this.state;
		let { t } = this.props;

		let hasMedia = mediaIds && mediaIds.length > 0;

		if (!quota || state === SM_STATES.sent) {
			return null;
		}

		if (hasMedia) {
			return (
				<>
					<div className="sm-modal__quota">
						<Trans
							i18nKey="You have <1>{{dailyMmsRemaining}}</1> daily scheduled MMS messages remaining for {{date}}"
							values={{ dailyMmsRemaining: quota.dailyMmsRemaining, date: moment(sendAfter).format("MMM Do, YYYY") }}
							components={[<strong />]}
						/>
					</div>
					<div className="sm-modal__quota">
						<Trans
							i18nKey="You have <1>{{monthlyMmsRemaining}}</1> monthly scheduled MMS messages remaining for {{monthYear}}"
							values={{ monthlyMmsRemaining: quota.monthlyMmsRemaining, monthYear: moment(sendAfter).format("MMMM, YYYY") }}
							components={[<strong />]}
						/>
					</div>
				</>
			);
		}

		return (
			<>
				<div className="sm-modal__quota">
					<Trans
						i18nKey="You have <1>{{dailySmsRemaining}}</1> daily scheduled SMS messages remaining for {{date}}"
						values={{ dailySmsRemaining: quota.dailySmsRemaining, date: moment(sendAfter).format("MMM Do, YYYY") }}
						components={[<strong />]}
					/>
				</div>
				<div className="sm-modal__quota">
					<Trans
						i18nKey="You have <1>{{monthlySmsRemaining}}</1> monthly scheduled SMS messages remaining for {{monthYear}}"
						values={{ monthlySmsRemaining: quota.monthlySmsRemaining, monthYear: moment(sendAfter).format("MMMM, YYYY") }}
						components={[<strong />]}
					/>
				</div>
			</>
		);
	};

	renderLimitReached = () => {
		let { quota, mediaIds, state } = this.state;
		let { t } = this.props;

		if (!quota || state === SM_STATES.sent) {
			return null;
		}

		let hasMedia = mediaIds && mediaIds.length > 0;

		if (hasMedia) {
			let dailyMmsLimitReached = hasMedia && this.hasTooManyDailyRecipients(false);
			let monthlyMmsLimitReached = this.hasTooManyMonthlyRecipients(false);

			return (
				<>
					{dailyMmsLimitReached && (
						<div className="sm-modal__limit-reached">
							<div>{t("You have too many MMS recipients in this scheduled message.")}</div>
							<div>{t("Consider removing some contacts or groups to stay within the limit.")}</div>
							<div>{t("To increase the limit, please contact support@demandhub.co or chat with us.")}</div>
							<br />
							<div className="mb-button mb-button--fit mb-button--center" onClick={() => SupportChatService.showNewMessage()}>
								{t("Chat with us")}
							</div>
						</div>
					)}
					{monthlyMmsLimitReached && (
						<div className="sm-modal__limit-reached">
							<div>{t("You have passed the monthly MMS limit for scheduled messages.")}</div>
							<div>{t("Consider removing some contacts or groups to stay within the limit.")}</div>
							<div>{t("To increase the limit, please contact support@demandhub.co or chat with us.")}</div>
							<br />
							<div className="mb-button mb-button--fit mb-button--center" onClick={() => SupportChatService.showNewMessage()}>
								{t("Chat with us")}
							</div>
						</div>
					)}
				</>
			);
		}

		let dailySmsLimitReached = !hasMedia && this.hasTooManyDailyRecipients(true);
		let monthlySmsLimitReached = this.hasTooManyMonthlyRecipients(true);

		return (
			<>
				{dailySmsLimitReached && (
					<div className="sm-modal__limit-reached">
						<div>{t("You have too many SMS recipients in this scheduled message.")}</div>
						<div>{t("Consider removing some contacts or groups to stay within the limit.")}</div>
						<div>{t("To increase the limit, please contact support@demandhub.co or chat with us.")}</div>
						<br />
						<div className="mb-button mb-button--fit mb-button--center" onClick={() => SupportChatService.showNewMessage()}>
							{t("Chat with us")}
						</div>
					</div>
				)}
				{monthlySmsLimitReached && (
					<div className="sm-modal__limit-reached">
						<div>{t("You have passed the monthly SMS limit for scheduled messages.")}</div>
						<div>{t("Consider removing some contacts or groups to stay within the limit.")}</div>
						<div>{t("To increase the limit, please contact support@demandhub.co or chat with us.")}</div>
						<br />
						<div className="mb-button mb-button--fit mb-button--center" onClick={() => SupportChatService.showNewMessage()}>
							{t("Chat with us")}
						</div>
					</div>
				)}
			</>
		);
	};

	renderLoading = () => {
		let { state, saving, type, scheduleMessageTypeOptions } = this.state;
		let { t } = this.props;

		let { localTimeZone, localTimeZoneShortHand } = UtilityService.getTimeZoneHelpers();

		let scheduleMessageTypeLabel = scheduleMessageTypeOptions.find(smType => smType.value === type);
		scheduleMessageTypeLabel = typeof scheduleMessageTypeLabel === "undefined" ? t("Unknown Type") : scheduleMessageTypeLabel.label;

		return (
			<div className="sm-modal">
				<ContentLoader height={50} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
				</ContentLoader>
				<ContentLoader height={50} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
				</ContentLoader>

				<div className="sm-modal__field">{t("Type")}</div>
				<ContentLoader height={38} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="38" />
				</ContentLoader>

				<div className="sm-modal__field sm-modal__field__recipients__title">
					{t("Recipients")}
					{state !== SM_STATES.sent && (
						<div className="sm-modal__field__bulk_upload" onClick={this.onShowBulkContactUpload}>
							{t("Upload Contacts")}
						</div>
					)}
				</div>
				<ContentLoader height={38} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="38" />
				</ContentLoader>

				{type === SM_TYPES.nps && (
					<div className="modal__field nps-anonymous-field">
						<p>{t("Anonymous NPS")}</p>
					</div>
				)}

				<div className="sm-modal__field">{t("Content")}</div>
				<ContentLoader height={130} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="130" />
				</ContentLoader>

				<div className="sm-modal__schedule">
					<ReactTooltip id="timezone-info" type="info" className="mb-react-tooltip" effect="solid" place="right" arrowColor="#333">
						{t("The date is in your local timezone")} <br /> {localTimeZone} ({localTimeZoneShortHand})
					</ReactTooltip>
					<div className="sm-modal__field">
						{t("Send Date")}
						<div className="sm-modal__schedule__info_icon" data-tip data-for="timezone-info">
							<Icon.Info size="16" />
						</div>
					</div>

					<ContentLoader height={35} width={"230"}>
						<rect x="0" y="0" rx="5" ry="5" width="230" height="35" />
					</ContentLoader>
				</div>

				<div className={`mb-button mb-button--disabled`}>{saving ? t("Saving...") : t("Save")}</div>
			</div>
		);
	};

	getHeaders = () => {
		let { t } = this.props;

		let RECIPIENTS_COLUMN = {
			contact: {
				id: "contact",
				value: t("Contact"),
				sortable: true,
				sortField: "contact",
				width: 2,
				widthMd: 3
			}
		};

		let columns = RECIPIENTS_COLUMN;

		let headers = {
			items: columns,
			sortBy: () => {}
		};

		return headers;
	};

	renderRecord = recordData => {
		return [recordData.label];
	};

	renderBody = () => {
		let { sendAfter, minSendAfter, recipients, scheduledMessageId, state, saving, type, scheduleMessageTypeOptions, disableTypeChange } = this.state;
		let { show, t } = this.props;

		let { localTimeZone, localTimeZoneShortHand } = UtilityService.getTimeZoneHelpers();

		let scheduleMessageTypeLabel = scheduleMessageTypeOptions.find(smType => smType.value === type);
		scheduleMessageTypeLabel = typeof scheduleMessageTypeLabel === "undefined" ? t("Unknown Type") : scheduleMessageTypeLabel.label;

		const locationId = UserService.getActiveLocation().id;

		return (
			<div className="sm-modal">
				{this.renderRemainingMessage()}

				<div className="sm-modal__field">{t("Type")}</div>
				<Select
					id="schedule-message-type-select"
					className="sm-modal__select"
					options={scheduleMessageTypeOptions}
					value={{ label: scheduleMessageTypeLabel, value: type }}
					placeholder={t("Select a type ...")}
					onChange={this.onTypeChange}
					isDisabled={disableTypeChange || state === SM_STATES.sent}
				/>
				<div className="sm-modal__field sm-modal__field__recipients__title">
					{t("Recipients")}
					{state !== SM_STATES.sent && (
						<div className="sm-modal__field__bulk_upload" onClick={this.onShowBulkContactUpload}>
							{t("Upload Contacts")}
						</div>
					)}
				</div>

				{state !== SM_STATES.sent ? (
					<AsyncSelect
						styles={asyncSelectCustomStyles}
						isDisabled={state === SM_STATES.sent}
						// isDisabled={true}
						className="sm-modal__search"
						placeholder={t("Type a phone number or name of a contact or group ...")}
						loadOptions={this.onDebouncedSearchContactsOrGroups}
						isMulti
						value={recipients}
						onChange={this.onResultSelect}
					/>
				) : (
					<List
						items={recipients}
						loading={false}
						loadedAll={true}
						headers={this.getHeaders()}
						renderRecord={this.renderRecord}
						noDataTitle={t("No recipients found...")}
						noDataIcon={<Icon.AlertCircle />}
					/>
				)}

				{type === SM_TYPES.nps && (
					<div className="modal__field nps-anonymous-field">
						<p>{t("Anonymous NPS")}</p>
						<Checkbox
							id="isAnonymous"
							name="isAnonymous"
							checked={this.state.isAnonymous}
							onChange={event => {
								this.update({ isAnonymous: event.target.checked });
							}}
						/>
					</div>
				)}

				{this.renderContent()}
				{this.renderTemplates()}
				{this.renderMedia()}

				<div className="sm-modal__schedule">
					<ReactTooltip id="timezone-info" type="info" className="mb-react-tooltip" effect="solid" place="right" arrowColor="#333">
						{t("The date is in your local timezone")} <br /> {localTimeZone} ({localTimeZoneShortHand})
					</ReactTooltip>
					<div className="sm-modal__field">
						{t("Send Date")}
						<div className="sm-modal__schedule__info_icon" data-tip data-for="timezone-info">
							<Icon.Info size="16" />
						</div>
					</div>
					<DatePicker
						disabled={state === SM_STATES.sent}
						minDate={minSendAfter}
						selected={sendAfter}
						onChange={this.onDateChange}
						showTimeSelect
						dateFormat="MMMM d, yyyy  @ h:mm aa"
						timeFormat="HH:mm"
						timeIntervals={15}
						timeCaption={t("Time")}
						className="sm-modal__schedule__datepicker"
					/>
				</div>

				{this.renderLimitReached()}

				{state !== SM_STATES.sent && (
					<div className="sm-modal__actions">
						{scheduledMessageId && !saving && (
							<div className={`mb-button mb-button--cancel ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onCancel}>
								{t("Cancel")}
							</div>
						)}
						{state === null && (
							<div className={`mb-button sm-modal__send-draft ${!this.isValid() ? "sm-modal__send-draft--off" : ""}`} onClick={this.onSendDraft}>
								{t("Preview")}
							</div>
						)}
						<div className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onConfirmSave}>
							{saving ? t("Saving...") : t("Save")}
						</div>
					</div>
				)}
			</div>
		);
	};

	render() {
		let { showConfirmAlert, loading, type, scheduleMessageTypeOptions, showBulkContactUpload, showSendDraftModal, mediaIds, content } = this.state;
		let { show, t } = this.props;

		let scheduleMessageTypeLabel = scheduleMessageTypeOptions.find(smType => smType.value === type);
		scheduleMessageTypeLabel = typeof scheduleMessageTypeLabel === "undefined" ? t("Unknown Type") : scheduleMessageTypeLabel.label;

		const locationId = UserService.getActiveLocation().id;

		return (
			<>
				<Modal show={show} onHide={this.props.onHide} title={this.titleText()}>
					{!loading ? this.renderBody() : null}
					{loading ? this.renderLoading() : null}
				</Modal>

				<Alert show={showConfirmAlert} onClose={this.onConfirmAlertClose} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")}>
					{this.renderAlertText()}
				</Alert>
				{showBulkContactUpload && (
					<BulkContactUpload
						show={showBulkContactUpload}
						onClose={this.onHideBulkContactUpload}
						title={t("Upload Contacts to DemandHub")}
						location={locationId}
						onCompleted={this.onContactUploadCompleted}
					/>
				)}
				<SendDraftModal show={showSendDraftModal} onHide={this.onHideSendDraftModal} message={content} mediaIds={mediaIds}></SendDraftModal>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(ScheduledMessageModal);

// Libraries
import React, { Component } from "react";
import * as Icon from "react-feather";
import debounce from "debounce-promise";

// Services
import UserService from "../../services/UserService";
import CalendarService from "../../services/CalendarService";
import MessagesService from "../../services/MessagesService";
import ToastService from "../../services/ToastService";

// Components
import SideModal from "../../components/common/SideModal";
import DHAsyncSelect from "../../components/common/DHAsyncSelect";

// Constants
import { SORT_ORDER } from "../../constants/CommonConstants";
import { USERS_COLUMNS } from "../../constants/Users";
import { CALENDAR_EVENT_TYPES } from "../../constants/Calendar";

// Styles
import "./calendar-filters-side-modal.css";

class CalendarFiltersSideModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			calendars: [],
			contacts: [],
			users: [],
			types: Object.values(CALENDAR_EVENT_TYPES)
		};

		this.onDebouncedSearchCalendars = debounce(this.searchCalendars.bind(this), 1000);
		this.onDebouncedSearchContacts = debounce(this.searchContacts.bind(this), 1000);
		this.onDebouncedSearchUsers = debounce(this.searchUsers.bind(this), 1000);
		this.onDebouncedSearchTypes = debounce(this.searchTypes.bind(this), 1000);
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount = () => {};

	resetComponent = () => {};

	searchCalendars = async searchTerm => {
		let location = UserService.getActiveLocation();
		let { data: calendars, error } = await CalendarService.fetch({
			query: {
				searchTerm,
				locationId: location.id
			}
		});

		if (error) {
			ToastService.error("An error occurred trying to get calendars.");
			return;
		}

		let results = calendars.map(calendar => {
			return {
				label: calendar.title,
				value: calendar.id
			};
		});

		return results;
	};

	searchContacts = async searchTerm => {
		let locationId = UserService.getActiveLocation().id;

		let contacts = await MessagesService.searchConversations({ searchTerm, locationId });

		let results = contacts.map(c => {
			return {
				label: `Contact: ${c.name} - ${c.medium_data}`,
				value: c.id
			};
		});

		return results;
	};

	searchUsers = async searchTerm => {
		let user = UserService.get();
		let company = UserService.getActiveCompany();

		let { users } = await UserService.fetchUsers({
			limit: 20,
			companyId: company.id,
			userId: user.id,
			searchTerm: searchTerm,
			sortField: USERS_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc
		});

		let results = users.map(u => {
			return {
				label: `${u.first_name} ${u.last_name}`,
				value: u.id
			};
		});

		return results;
	};

	searchTypes = async searchTerm => {
		// TODO - DH-3608 - Add this to the /calendar/metadata endpoint so that different CalendarEvent types can be managed on the backend
		let results = Object.values(CALENDAR_EVENT_TYPES).filter(type => {
			return type.label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
		});

		return results;
	};

	onSelectCalendar = async results => {
		await this.update({ calendars: results });
	};

	onSelectContact = async results => {
		await this.update({ contacts: results });
	};

	onSelectUser = async results => {
		await this.update({ users: results });
	};

	onSelectType = async results => {
		await this.update({ types: results });
	};

	onApplyFilters = () => {
		let { calendars, contacts, users, types } = this.state;

		if (this.props.onFiltersChanged) {
			this.props.onFiltersChanged({ calendars, contacts, users, types });
		}
	};

	render() {
		const { show, onHide } = this.props;
		const { calendars, contacts, users, types } = this.state;

		return (
			<SideModal show={show} title={"Calendar Filters"} titleIcon={Icon.Filter} onHide={onHide} closeOnClick={false}>
				<DHAsyncSelect
					label="Calendar"
					placeholder="Select a calendar ..."
					value={calendars}
					isMulti
					onSearch={this.onDebouncedSearchCalendars}
					onSelect={this.onSelectCalendar}
				/>

				<DHAsyncSelect
					label="Contact"
					placeholder="Search Contacts ..."
					isMulti
					value={contacts}
					onSearch={this.onDebouncedSearchContacts}
					onSelect={this.onSelectContact}
				/>

				<DHAsyncSelect label="Users" placeholder="Search Users ..." isMulti value={users} onSearch={this.onDebouncedSearchUsers} onSelect={this.onSelectUser} />

				<DHAsyncSelect
					label="Types"
					placeholder="Search Types ..."
					isMulti
					value={types}
					onSearch={this.onDebouncedSearchTypes}
					onSelect={this.onSelectType}
					defaultOptions
				/>

				<div className="calendar-filters-side-modal__actions">
					<div className="calendar-filters-side-modal__actions__button mb-button" onClick={this.onApplyFilters}>
						Apply
					</div>
				</div>
			</SideModal>
		);
	}
}

export default CalendarFiltersSideModal;

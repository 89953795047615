import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Select from "react-select";
import ReactSwitch from "react-switch";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import * as Icon from "react-feather";
import AsyncSelect from "react-select/lib/Async";
import debounce from "debounce-promise";

import Kichiri from "../../services/KichiriService";
import NumberService from "../../services/NumberService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import LocationService from "../../services/LocationService";
import NotificationService from "../../services/NotificationService";
import ToastService from "../../services/ToastService";
import CompanyService from "../../services/CompanyService";
import UtilityService from "../../services/UtilityService";

import LanguageDropdown from "../../components/common/LanguageDropdown";
import AddressAutocomplete from "../../components/common/AddressAutocomplete";
import TimezoneDropdown from "../../components/common/TimezoneDropdown";
import CountryDropdown from "../../components/common/CountryDropdown";
import Alert from "../../components/common/Alert";
import Spinners from "../../components/common/Spinners";
import ManageNumbers from "../../components/common/ManageNumbers";
import TimeDropdown from "../../components/common/TimeDropdown";
import UnsavedChanges from "../../components/common/UnsavedChanges";
import Action from "../../components/common/Action";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";

import { CODES_TO_LABEL, LANGUAGES, LANGUAGES_DROPDOWN } from "../../constants/LanguageConstants";
import GROUP_PERMISSIONS from "../../constants/GroupPermissions";
import { STATUS } from "../../constants/CommonConstants";

import "../../styles/css/scenes/locations.css";

class ManageLocation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			locationId: "create",
			company: { value: UserService.getActiveCompany().id, label: UserService.getActiveCompany().name },
			selectableCompanies: [],
			location: null,
			name: "",
			handle: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			country: "",
			latitude: 0,
			longitude: 0,
			countryObject: null,
			timezone: "",
			timezoneObject: {},
			vertical: "",
			assignableVerticals: [],
			subVertical: null,
			assignableSubVerticals: [],
			language: LANGUAGES.enCA,
			supported_languages: [{ label: CODES_TO_LABEL[LANGUAGES.enCA], value: LANGUAGES.enCA }],
			verticalsConfig: {},
			autoRespondReviews: true,
			replyInHours: 72,
			website: "",
			phone: "",
			defaultAreaCode: "",
			email: "",
			monthlyInviteGoal: 75,
			monthlyReviewGoal: 25,
			messageRandomSeconds: 300,
			messageMinSeconds: 300,
			messageStartTime: "09:00",
			messageEndTime: "20:00",
			smsLogoUrl: "",
			emailLogoUrl: "",
			googlePlaceCid: "",
			googlePlaceId: "",
			googlePlaceUrl: "",
			facebookPlaceId: "",
			businessNumber: "",
			partialPaymentThreshold: 0,
			minimumPaymentAmount: 0,
			stripeAccountId: "",
			facebookMarkIncomingRead: false,
			saveSuccess: false,
			saveError: false,
			autoCompleteAddress: "",
			autoCompleteAddressObject: { label: "", value: "" },
			completeAddress: "",
			populateError: false,
			loading: true,
			loadingFailed: false,
			showPseudoLocationWarning: false,
			pseudoLocation: false,
			isTrial: false,
			numbers: [],
			errorText: "Sorry, an error was encountered while saving this location. Please try again.",
			nextDisabled: true,
			isKeywordsEnabled: false,
			keywordsTemplateLimit: 3,
			keywordsWordsPerTemplate: 1,
			maxDailySmsScheduledMessageLimit: 50,
			maxDailyMmsScheduledMessageLimit: 25,
			maxMonthlySmsScheduledMessageLimit: 500,
			maxMonthlyMmsScheduledMessageLimit: 125,
			maxWeeklyRequest: 150,
			maxDailyReengagements: 30,
			averageConversionValue: 100,
			deactivateOn: null,

			/* Dirty Bit */
			changesMade: false
		};

		this.handleEdited = false;

		this.manageNumbersComponent = React.createRef();

		this.onDebouncedSearchCompanies = debounce(this.fetchCompanies.bind(this), 1000);
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		let locationId = this.props.match.params.locationId;

		await this.update({
			locationId
		});
		await this.fetchVerticals();
		if (this.isCreate()) {
			await this.fetchCompanies();
			this.setState({ loading: false, action: "Create" });
		} else {
			await this.fetchLocation();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchCompanies = async searchTerm => {
		let companies = await CompanyService.fetchCompanies({ limit: 1000, searchTerm, status: [STATUS.active] });

		if (!companies) {
			this.update({ selectableCompanies: [] });
			return;
		}

		companies = companies.map(c => {
			return { value: c.id, label: c.name };
		});

		this.update({
			selectableCompanies: companies
		});

		return companies;
	};

	genericEventHandler = async event => {
		await this.update({ [event.target.name]: event.target.value });
		await this.update({ changesMade: true });
	};

	handleRandomSecondChange = event => {
		// don't allow more than 180 minutes of random seconds
		let newRandomSeconds = Math.min(event.target.value, 10800);
		this.setState({ messageRandomSeconds: newRandomSeconds });
		this.setState({ changesMade: true });
	};

	handleNameChange = event => {
		let value = event.target.value;

		let update = {
			name: value
		};

		if (!this.handleEdited) {
			let newHandle = LocationService.generateHandle(value);
			update.handle = newHandle;
		}
		this.setState(update);
		this.setState({ changesMade: true });
	};

	handleHandleChange = event => {
		this.handleEdited = true;
		this.setState({ handle: event.target.value, changesMade: true });
	};

	handleCountryChange = newValue => {
		const country = newValue ? newValue.label : "";
		this.setState({ countryObject: newValue, country, changesMade: true });
	};

	handleAutocompleteAddressOnChange = newValue => {
		const autoCompleteAddress = newValue ? newValue.label.split(", ")[0] : "";
		this.setState({ autoCompleteAddressObject: newValue, autoCompleteAddress, changesMade: true });
	};
	handleTimezoneChange = selectedTimezone => {
		const timezone = selectedTimezone ? selectedTimezone.id : "";
		this.setState({ timezoneObject: selectedTimezone, timezone, changesMade: true });
	};

	handleMessageStartTimeChange = newValue => {
		let messageStartTimeString = newValue.target.value.split(":");
		let messageStartTime = moment()
			.hours(messageStartTimeString[0])
			.minutes(messageStartTimeString[1]);

		let messageEndTimeString = this.state.messageEndTime.split(":");
		let messageEndTime = moment()
			.hours(messageEndTimeString[0])
			.minutes(messageEndTimeString[1]);

		if (messageStartTime.isAfter(messageEndTime, "minute")) {
			newValue = this.state.messageEndTime;
		} else {
			newValue = newValue.target.value;
		}

		this.setState({ messageStartTime: newValue, changesMade: true });
	};

	handleMessageEndTimeChange = newValue => {
		let messageStartTimeString = this.state.messageStartTime.split(":");
		let messageStartTime = moment()
			.hours(messageStartTimeString[0])
			.minutes(messageStartTimeString[1]);

		let messageEndTimeString = newValue.target.value.split(":");
		let messageEndTime = moment()
			.hours(messageEndTimeString[0])
			.minutes(messageEndTimeString[1]);

		if (messageStartTime.isAfter(messageEndTime, "minute")) {
			newValue = this.state.messageStartTime;
		} else {
			newValue = newValue.target.value;
		}

		this.setState({ messageEndTime: newValue, changesMade: true });
	};

	handleOnPopulateClick = async e => {
		e.preventDefault();
		const { autoCompleteAddressObject, autoCompleteAddress } = this.state;
		if (autoCompleteAddressObject.value.length === 0) {
			return;
		}
		try {
			let data = await LocationService.getPlacesDetails(autoCompleteAddressObject.value);
			const { administrative_area_level_1, cid, country, locality, phone, postal_code, street_address, website, subpremise, complete_address } = data;
			let handle = LocationService.generateHandle(autoCompleteAddress);

			let updateObj = {
				address2: subpremise,
				address1: street_address,
				city: locality,
				state: administrative_area_level_1,
				country,
				countryObject: { label: country, value: country },
				latitude: data.latitude,
				longitude: data.longitude,
				zip: postal_code,
				website,
				phone,
				googlePlaceCid: cid,
				googlePlaceId: autoCompleteAddressObject.value,
				name: autoCompleteAddress,
				completeAddress: complete_address,
				handle
			};

			if (data.timezoneId) {
				updateObj.timezone = data.timezoneId;
				updateObj.timezoneObject = data.timezoneDetails;
			}

			await this.update(updateObj);
			this.setState({ googlePlaceUrl: this.generatePlacesUrl(), changesMade: true });
		} catch (error) {
			console.error(error);
			this.setState({ populateError: true });
		}
	};

	generatePlacesUrl = () => {
		const { completeAddress, autoCompleteAddress } = this.state;

		// Use the CID to generate the URL to see reviews
		let hexCid = NumberService.largeDecimalTohex(this.state.googlePlaceCid);

		let googlePlaceUrl = `https://www.google.com/search?q=${autoCompleteAddress.split(" ").join("+")},${completeAddress.split(" ").join("+")}&ludocid=${
			this.state.googlePlaceCid
		}#lrd=0x0:0x${hexCid},1`;

		return googlePlaceUrl;
	};

	handleSaveLocationClick = async event => {
		const { locationId, company, pseudoLocation } = this.state;
		const authToken = UserService.get() ? UserService.get().auth_token : "";
		event.preventDefault();
		event.stopPropagation();

		// double check if the numbers have been set properly
		let numbers = this.state.numbers;

		// eslint-disable-next-line
		let safeCount = numbers.filter(number => number.safe == true).length;
		if (safeCount !== 1 && !this.state.pseudoLocation) {
			this.showErrorModal(`At least one number must be a primary number`);
			this.setState({ loading: false });
			return;
		}
		// for (let i = 0; i < numbers.length; i++) {
		// 	if (!UtilityService.isMobilePhoneValid(numbers[i].number)) {
		// 		this.showErrorModal(`Sorry, the phone number '${numbers[i].number}' is invalid`);
		// 		this.setState({ loading: false });
		// 		return;
		// 	}
		// }

		if (!this.state.vertical || !this.state.vertical.value) {
			this.showErrorModal(`A vertical must be selected`);
			return;
		}

		let assignPrimaryNumberToContacts = false;

		if (this.manageNumbersComponent && !pseudoLocation) {
			assignPrimaryNumberToContacts = this.manageNumbersComponent.assignPrimaryNumberToContacts();
		}

		let supportedLanguages = [LANGUAGES.enCA];
		if (this.state.supported_languages && this.state.supported_languages.length > 0) {
			supportedLanguages = this.state.supported_languages.map(l => l.value);
		}

		// Set up the location data
		const locationData = {
			company: company.value,
			name: this.state.name,
			handle: this.state.handle,
			address_1: this.state.address1,
			address_2: this.state.address2,
			city: this.state.city,
			state_prov: this.state.state,
			zip_postal: this.state.zip,
			country: this.state.country,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			timezone: parseInt(this.state.timezone, 10),
			vertical: this.state.vertical ? this.state.vertical.value : "Other",
			sub_vertical: this.state.subVertical ? this.state.subVertical.value : null,
			language: this.state.language ? this.state.language : LANGUAGES.enCA,
			supported_languages: supportedLanguages,
			website: this.state.website,
			phone: this.state.phone,
			default_area_code: this.state.defaultAreaCode,
			email: this.state.email,
			monthly_invite_goal: this.state.monthlyInviteGoal,
			monthly_review_goal: this.state.monthlyReviewGoal,
			reply_in_hours: this.state.replyInHours,
			sms_logo_url: this.state.smsLogoUrl,
			email_logo_url: this.state.emailLogoUrl,
			google_place_cid: this.state.googlePlaceCid,
			google_place_id: this.state.googlePlaceId,
			google_place_url: this.state.googlePlaceUrl,
			facebook_place_id: this.state.facebookPlaceId,
			business_number: this.state.businessNumber,
			partial_payment_threshold: this.state.partialPaymentThreshold,
			minimum_payment_amount: this.state.minimumPaymentAmount,
			stripe_account_id: this.state.stripeAccountId,
			facebook_mark_incoming_read: this.state.facebookMarkIncomingRead,
			is_pseudo: this.state.pseudoLocation,
			is_trial: this.state.isTrial,
			numbers: this.state.numbers,
			message_random_seconds: this.state.messageRandomSeconds,
			message_min_seconds: this.state.messageMinSeconds,
			message_start_time: this.state.messageStartTime,
			message_end_time: this.state.messageEndTime,
			keywords_template_limit: this.state.keywordsTemplateLimit,
			keywords_words_per_template: this.state.keywordsWordsPerTemplate,
			max_daily_sms_scheduled_message_limit: this.state.maxDailySmsScheduledMessageLimit,
			max_daily_mms_scheduled_message_limit: this.state.maxDailyMmsScheduledMessageLimit,
			max_monthly_sms_scheduled_message_limit: this.state.maxMonthlySmsScheduledMessageLimit,
			max_monthly_mms_scheduled_message_limit: this.state.maxMonthlyMmsScheduledMessageLimit,
			max_weekly_request: this.state.maxWeeklyRequest,
			max_daily_reengagements: this.state.maxDailyReengagements,
			average_conversion_value: this.state.averageConversionValue,
			deactivate_on: this.state.deactivateOn,
			assign_primary_number_to_contacts: assignPrimaryNumberToContacts || false
		};

		this.setState({ loading: true });
		if (this.isCreate()) {
			let response = await LocationService.createLocation(locationData);
			if (!response) {
				this.showErrorModal();
				this.setState({ loading: false });
				return;
			}

			this.setState({ saveSuccess: true, loading: false });
			ToastService.info(`${this.state.name} created for ${company.label}.`);

			NotificationService.publish("locationCreated");
		} else {
			// Make an API call to update a location
			locationData.locationId = locationId;

			let response = await LocationService.updateLocation(locationData);

			if (!response) {
				this.showErrorModal();
				this.setState({ loading: false });
				return;
			}

			this.setState({ saveSuccess: true, loading: false });
			ToastService.info(`${this.state.name} updated!`);

			NotificationService.publish("locationUpdated", locationId);
		}
	};

	async fetchLocation() {
		let user = UserService.get();

		try {
			const { locationId } = this.state;
			let locationData = await LocationService.fetchLocation(locationId);

			if (!locationData) {
				throw new Error("No data found.");
			}

			if (!locationData.LocationFeature) {
				ToastService.error("Location is missing location features. Go to Manage -> Settings -> Features.");
				throw new Error("No location feature found for this location.");
			}

			let numbers = locationData.numbers;
			numbers.forEach(number => {
				number.notes = number.notes !== null ? JSON.parse(number.notes) : [];
				number.notes = number.notes.sort((n1, n2) => n1.created_at - n2.created_at);
			});

			let metaData = LocationService.getAndParseMetaData(locationData);
			let keywordsTemplateLimit = 3;
			let keywordsWordsPerTemplate = 1;
			if (metaData && metaData.keywords) {
				keywordsTemplateLimit = metaData.keywords.templateLimit ? metaData.keywords.templateLimit : keywordsTemplateLimit;
				keywordsWordsPerTemplate = metaData.keywords.wordsPerTemplateLimit ? metaData.keywords.wordsPerTemplateLimit : keywordsWordsPerTemplate;
			}

			let isKeywordsEnabled =
				locationData.LocationFeature.workflows &&
				locationData.LocationFeature.workflow_keywords &&
				(user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type || user.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type);

			let supportedLanguagesArray = JSON.parse(locationData.supported_languages);

			let supportedLanguages = supportedLanguagesArray.map(lang => {
				return {
					label: CODES_TO_LABEL[lang],
					value: lang
				};
			});

			await this.update({
				location: locationData,
				name: locationData.name,
				handle: locationData.handle,
				address1: locationData.address_1,
				address2: locationData.address_2,
				city: locationData.city,
				state: locationData.state_prov,
				zip: locationData.zip_postal,
				country: locationData.country,
				countryObject: { value: locationData.country, label: locationData.country },
				latitude: locationData.latitude,
				longitude: locationData.longitude,
				timezone: locationData.Timezone.id,
				timezoneObject: locationData.Timezone,
				vertical: { value: locationData.vertical, label: locationData.vertical },
				subVertical: { value: locationData.sub_vertical, label: locationData.sub_vertical },
				language: locationData.language,
				supported_languages: supportedLanguages,
				website: locationData.website,
				phone: locationData.phone,
				defaultAreaCode: locationData.default_area_code,
				email: locationData.email,
				monthlyInviteGoal: locationData.monthly_invite_goal,
				monthlyReviewGoal: locationData.monthly_review_goal,
				autoRespondReviews: locationData.LocationFeature.reviews_auto_response,
				replyInHours: locationData.reply_in_hours,
				smsLogoUrl: metaData && metaData.sms_logo_url ? metaData.sms_logo_url : "",
				emailLogoUrl: metaData && metaData.email_logo_url ? metaData.email_logo_url : "",
				googlePlaceCid: metaData && metaData.google_place_cid ? metaData.google_place_cid : "",
				googlePlaceUrl: metaData && metaData.google_place_url ? metaData.google_place_url : "",
				facebookPlaceId: metaData && metaData.facebook_place_id ? metaData.facebook_place_id : "",
				googlePlaceId: locationData.google_place_id,
				businessNumber: locationData.business_number,
				partialPaymentThreshold: locationData.partial_payment_threshold,
				minimumPaymentAmount: locationData.minimum_payment_amount,
				stripeAccountId: locationData.stripe_account_id,
				facebookMarkIncomingRead: locationData.facebook_mark_incoming_read,
				numbers: numbers,
				messageRandomSeconds: locationData.message_random_seconds,
				messageMinSeconds: locationData.message_min_seconds,
				messageStartTime: locationData.message_start_time,
				messageEndTime: locationData.message_end_time,
				action: "Manage",
				loading: false,
				pseudoLocation: locationData.is_pseudo,
				isTrial: locationData.is_trial,
				isKeywordsEnabled,
				keywordsTemplateLimit,
				keywordsWordsPerTemplate,
				maxDailySmsScheduledMessageLimit: locationData.max_daily_sms_scheduled_message_limit,
				maxDailyMmsScheduledMessageLimit: locationData.max_daily_mms_scheduled_message_limit,
				maxMonthlySmsScheduledMessageLimit: locationData.max_monthly_sms_scheduled_message_limit,
				maxMonthlyMmsScheduledMessageLimit: locationData.max_monthly_mms_scheduled_message_limit,
				maxWeeklyRequest: locationData.max_weekly_request,
				maxDailyReengagements: locationData.max_daily_reengagements,
				averageConversionValue: locationData.average_conversion_value,
				deactivateOn: locationData.deactivate_on ? moment(locationData.deactivate_on).format("LL") : null
			});

			this.setAssignableSubVerticals();
		} catch (error) {
			this.setState({ action: "Manage", loadingFailed: true, loading: false });
			console.log(error);
		}
	}

	fetchVerticals = async () => {
		let verticals = await LocationService.getVerticals();
		let assignableVerticals = Object.values(verticals)
			.sort()
			.map(vertical => {
				return { value: vertical.id, label: vertical.id };
			});
		await this.update({ assignableVerticals, verticalsConfig: verticals });
	};

	setAssignableSubVerticals = async () => {
		let { vertical, verticalsConfig } = this.state;
		let assignableSubVerticals = [];

		if (verticalsConfig && vertical && verticalsConfig[vertical.value] && verticalsConfig[vertical.value].subVertical) {
			assignableSubVerticals = Object.values(verticalsConfig[vertical.value].subVertical).map(subVertical => {
				return { value: subVertical, label: subVertical };
			});
		}

		await this.update({ assignableSubVerticals });
	};

	onVerticalChange = async event => {
		await this.update({ vertical: event, changesMade: true, subVertical: "" });

		await this.setAssignableSubVerticals();
	};

	numbersOnChange(numbers) {
		this.setState({
			numbers: numbers,
			changesMade: true
		});
	}

	isCreate() {
		const { locationId } = this.state;
		return locationId === "create";
	}

	showErrorModal(message = "Sorry, an error was encountered while saving this location. Please try again.") {
		this.setState({
			errorText: message,
			saveError: true
		});
	}

	showAreaCodeMismatchWarning = () => {
		let { phone, defaultAreaCode, numbers } = this.state;

		return LocationService.isAreaCodeMismatching({ phone, defaultAreaCode, numbers });
	};

	render() {
		const { pseudoLocation, isTrial, changesMade, phone } = this.state;
		let user = UserService.get();

		if (this.isCreate() && !user.GroupPermission.create_locations) {
			return <Redirect to="/dashboard" />;
		}

		if (this.state.saveSuccess) {
			return <Redirect to="/settings/locations" />;
		}

		if (this.state.loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}

		return (
			<Page>
				<Header title={`${this.state.action} Location ${this.state.name}`} />
				{changesMade && <UnsavedChanges />}
				<Alert
					type="error"
					show={this.state.saveError}
					title="Saving Error"
					confirm="OK"
					onClose={() => {
						this.setState({ saveError: false });
					}}
				>
					<div>{this.state.errorText}</div>
				</Alert>
				<Alert
					type="warning"
					show={this.state.showPseudoLocationWarning}
					title="Are you sure?"
					confirm="Yes"
					cancel="No"
					onClose={confirmed => {
						if (confirmed) {
							this.setState({ pseudoLocation: !this.state.pseudoLocation, changesMade: true });
						}
						this.setState({
							showPseudoLocationWarning: false
						});
					}}
				>
					<div>Are you sure you want to change the status of the location? This can have undesirable side effects.</div>
				</Alert>
				<Alert
					type="error"
					show={this.state.populateError}
					title="Error populating data"
					confirm="OK"
					onClose={() => {
						this.setState({ populateError: false });
					}}
				>
					<div>Sorry, an error was encountered while populating the fields.</div>
				</Alert>
				<div className="row locations-page">
					<div className="col-lg-12">
						<div className="ibox float-e-margins">
							{this.state.loadingFailed ? (
								<div>
									<h2>
										Sorry, no data was found{" "}
										<span role="img" aria-label="sad-face">
											😞
										</span>
									</h2>
									<Link to="/settings/locations" className="btn btn-warning">
										Go Back
									</Link>
								</div>
							) : (
								<form className="form-horizontal" onSubmit={this.handleSaveLocationClick}>
									{!this.isCreate() &&
										(user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type ||
											user.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type) && (
											<div className="form-group">
												<label className="col-sm-2 control-label">
													Location Id <span className="text-danger">*</span>
												</label>
												<div className="col-sm-10">
													<input
														name="locationId"
														id="locationId"
														type="text"
														disabled={true}
														value={this.state.locationId}
														className="form-control Common__input"
													/>
												</div>
											</div>
										)}
									{this.isCreate() && (
										<div className="form-group">
											<label className="col-sm-2 control-label">
												Company<span className="text-danger">*</span>
											</label>
											<div className="col-sm-10">
												<AsyncSelect
													id="company-selector"
													isDisabled={false}
													isSearchable={true}
													isClearable={false}
													placeholder="Select a company"
													defaultOptions={this.state.selectableCompanies}
													loadOptions={this.onDebouncedSearchCompanies}
													value={this.state.company ? this.state.company : null}
													onChange={value => this.update({ company: value })}
												/>
											</div>
										</div>
									)}
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Location Name <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input name="name" id="name" type="text" onChange={this.handleNameChange} value={this.state.name} className="Common__input" required />
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Handle <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="handle"
												id="handle"
												type="text"
												onChange={this.handleHandleChange}
												value={this.state.handle}
												className="Common__input"
												required
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Is On Trial</label>
										<div className="col-sm-10">
											<ReactSwitch
												id="is_trial"
												type="text"
												onChange={() => this.setState({ isTrial: !this.state.isTrial, changesMade: true })}
												checked={isTrial}
												onColor="#4A90E2"
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Pseudo Location</label>
										<div className="col-sm-10">
											<ReactSwitch
												id="is_pseudo_location"
												type="text"
												onChange={() => this.setState({ showPseudoLocationWarning: true })}
												checked={pseudoLocation}
												onColor="#4A90E2"
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Enter Business Name</label>
										<div className="col-sm-8">
											<AddressAutocomplete
												id="address_auto_complete"
												value={this.state.autoCompleteAddressObject}
												onChange={this.handleAutocompleteAddressOnChange}
											/>
										</div>
										<div className="col-sm-2">
											<button id="populate_button" className="btn btn-warning btn-md inline" onClick={this.handleOnPopulateClick}>
												Populate
											</button>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Address 1 <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="address1"
												id="address_1"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.address1}
												className="Common__input"
												required
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Address 2</label>
										<div className="col-sm-10">
											<input
												name="address2"
												id="address_2"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.address2}
												className="Common__input"
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											City <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input name="city" id="city" type="text" onChange={this.genericEventHandler} value={this.state.city} className="Common__input" required />
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											State/Province <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="state"
												id="state"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.state}
												className="Common__input"
												required
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Country <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<CountryDropdown name="country" id="country" onChange={this.handleCountryChange} value={this.state.countryObject} required />
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											ZIP/Postal <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input name="zip" id="zip" type="text" onChange={this.genericEventHandler} value={this.state.zip} className="Common__input" required />
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Latitude <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="latitude"
												id="latitude"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.latitude}
												className="Common__input"
												required
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Longitude <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="longitude"
												id="longitude"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.longitude}
												className="Common__input"
												required
											/>
										</div>
									</div>
									{!pseudoLocation && (
										<div className="form-group">
											<label className="col-sm-2 control-label">
												Time Zone <span className="text-danger">*</span>
											</label>
											<div className="col-sm-10">
												<TimezoneDropdown
													id="timezone"
													value={{
														label: this.state.timezoneObject ? this.state.timezoneObject.text || this.state.timezoneObject.label : "",
														value: this.state.timezoneObject ? this.state.timezoneObject.value : "",
														id: this.state.timezoneObject ? this.state.timezoneObject.id : ""
													}}
													onChange={this.handleTimezoneChange}
													required
												/>
											</div>
										</div>
									)}
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Vertical<span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<Select
												id="verticals"
												options={this.state.assignableVerticals}
												onChange={this.onVerticalChange}
												value={this.state.vertical}
												isSearchable={true}
												isClearable={true}
												placeholder="Select a vertical"
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Sub Vertical</label>
										<div className="col-sm-10">
											<Select
												id="sub-verticals"
												options={this.state.assignableSubVerticals}
												onChange={e => this.setState({ subVertical: e, changesMade: true })}
												value={this.state.subVertical}
												isSearchable={true}
												isClearable={true}
												placeholder="Select a sub vertical"
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Language</label>
										<div className="col-sm-10">
											<LanguageDropdown
												name="language"
												id="language"
												onChange={newValue => this.setState({ language: newValue.value, changesMade: true })}
												value={{ label: CODES_TO_LABEL[this.state.language], value: this.state.language }}
												required
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Supported Languages</label>
										<div className="col-sm-10">
											<Select
												id="supported_languages"
												name="supported_languages"
												options={LANGUAGES_DROPDOWN}
												value={this.state.supported_languages}
												isMulti
												placeholder="Select a language ..."
												onChange={newList => {
													this.setState({ supported_languages: newList, changesMade: true });
												}}
											/>
										</div>
									</div>

									<div className="form-group">
										<label className="col-sm-2 control-label">Website</label>
										<div className="col-sm-10">
											<input name="website" id="website" type="text" onChange={this.genericEventHandler} value={this.state.website} className="Common__input" />
										</div>
									</div>
									{!pseudoLocation && (
										<div className="form-group">
											<label className="col-sm-2 control-label">
												Main Phone <span className="text-danger">*</span>
											</label>
											<div className="col-sm-10">
												<input
													name="phone"
													id="phone"
													type="tel"
													onChange={this.genericEventHandler}
													value={this.state.phone}
													className="Common__input"
													required
												/>
											</div>
										</div>
									)}
									{!pseudoLocation && (
										<div className="form-group">
											<label className="col-sm-2 control-label">Default Phone Number Area Code</label>
											<div className="col-sm-10">
												<input
													name="defaultAreaCode"
													id="default_area_code"
													type="tel"
													onChange={this.genericEventHandler}
													value={this.state.defaultAreaCode}
													className="Common__input"
												/>
											</div>
										</div>
									)}
									<div className="form-group">
										<label className="col-sm-2 control-label">Contact Email</label>
										<div className="col-sm-10">
											<input name="email" id="email" type="text" onChange={this.genericEventHandler} value={this.state.email} className="Common__input" />
										</div>
									</div>
									{!pseudoLocation && (
										<>
											<div className="form-group">
												<div className="col-sm-10 col-sm-offset-1">
													<ManageNumbers
														ref={ref => (this.manageNumbersComponent = ref)}
														numbers={this.state.numbers}
														companyId={this.state.company.value}
														locationId={this.state.locationId}
														onChange={numbers => this.numbersOnChange(numbers)}
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">
													Monthly Invite Goal <span className="text-danger">*</span>
												</label>
												<div className="col-sm-10">
													<input
														name="monthlyInviteGoal"
														id="invite_goal"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.monthlyInviteGoal}
														className="Common__input"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">
													Monthly Review <span className="text-danger">*</span>
												</label>
												<div className="col-sm-10">
													<input
														name="monthlyReviewGoal"
														id="review_goal"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.monthlyReviewGoal}
														className="Common__input"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">
													<span data-tip data-for="message-random-seconds-tooltip">
														Message Random Seconds <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
													</span>
													<ReactTooltip
														id="message-random-seconds-tooltip"
														className="mb-react-tooltip text-left"
														arrowColor="#333"
														type="info"
														effect="solid"
														place="top"
													>
														The number of seconds over which to <br /> randomize an automated review invite. By <br /> default it is set to 300 seconds.
													</ReactTooltip>
												</label>
												<div className="col-sm-10">
													<input
														name="messageRandomSeconds"
														id="messageRandomSeconds"
														type="text"
														onChange={this.handleRandomSecondChange}
														value={this.state.messageRandomSeconds}
														className="Common__input"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">
													<span data-tip data-for="message-min-seconds-tooltip">
														Message Min Seconds <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
													</span>
													<ReactTooltip
														id="message-min-seconds-tooltip"
														className="mb-react-tooltip text-left"
														arrowColor="#333"
														type="info"
														effect="solid"
														place="top"
													>
														The minimum number of seconds to wait
														<br /> before sending an automated review invite. By
														<br /> default it is set to 300 seconds.
													</ReactTooltip>
												</label>
												<div className="col-sm-10">
													<input
														name="messageMinSeconds"
														id="messageMinSeconds"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.messageMinSeconds}
														className="Common__input"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">
													<span data-tip data-for="message-start-time-tooltip">
														Message Start Time <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
													</span>
													<ReactTooltip
														id="message-start-time-tooltip"
														className="mb-react-tooltip text-left"
														arrowColor="#333"
														type="info"
														effect="solid"
														place="top"
													>
														The start time (HH:MM) of the range in which <br /> automated review invites are sent. By <br /> default it is set to 09:00.
													</ReactTooltip>
												</label>
												<div className="col-sm-10">
													<TimeDropdown
														id={"messageStartTime"}
														checked={false}
														isDisabled={false}
														handleChange={this.handleMessageStartTimeChange}
														value={this.state.messageStartTime}
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">
													<span data-tip data-for="message-end-time-tooltip">
														Message End Time <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
													</span>
													<ReactTooltip
														id="message-end-time-tooltip"
														className="mb-react-tooltip text-left"
														arrowColor="#333"
														type="info"
														effect="solid"
														place="top"
													>
														The end time (HH:MM) of the range in which <br /> automated review invites are sent. By default
														<br /> it is set to 20:00.
													</ReactTooltip>
												</label>
												<div className="col-sm-10">
													<TimeDropdown
														id={"messageEndTime"}
														checked={false}
														isDisabled={false}
														handleChange={this.handleMessageEndTimeChange}
														value={this.state.messageEndTime}
													/>
												</div>
											</div>
											{this.state.autoRespondReviews && (
												<div className="form-group">
													<label className="col-sm-2 control-label">
														<span>Auto Reply In Hours</span>
													</label>
													<div className="col-sm-10">
														<input
															name="replyInHours"
															id="reply_in_hours"
															type="text"
															onChange={this.genericEventHandler}
															value={this.state.replyInHours}
															className="Common__input"
															required
														/>
													</div>
												</div>
											)}
											<div className="form-group">
												<label className="col-sm-2 control-label">Mark Incoming Facebook Messages As Read</label>
												<div className="col-sm-10">
													<ReactSwitch
														id="facebook_mark_incoming_read"
														type="text"
														onChange={() => this.setState({ facebookMarkIncomingRead: !this.state.facebookMarkIncomingRead, changesMade: true })}
														checked={this.state.facebookMarkIncomingRead}
														uncheckedIcon={false}
														checkedIcon={false}
														onColor="#4A90E2"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">SMS Logo Url</label>
												<div className="col-sm-10">
													<textarea
														name="smsLogoUrl"
														id="sms_logo_url"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.smsLogoUrl}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Email Logo Url</label>
												<div className="col-sm-10">
													<textarea
														name="emailLogoUrl"
														id="email_logo_url"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.emailLogoUrl}
														className="Common__input"
													/>
												</div>
											</div>
										</>
									)}
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Google Place CID <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="googlePlaceCid"
												id="google_place_cid"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.googlePlaceCid}
												className="Common__input"
												required
											/>
											<span id="helpBlock" className="help-block">
												Visit the&nbsp;
												<a href="https://supple.com.au/tools/google-review-link-generator/" target="_blank" rel="noopener noreferrer">
													Supple Review Link Generator
												</a>
												&nbsp;and extract the "ludocid"
											</span>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Google Place ID <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="googlePlaceId"
												id="google_place_id"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.googlePlaceId}
												className="Common__input"
												required
											/>
											<span id="helpBlock" className="help-block">
												Visit the&nbsp;
												<a href="https://developers.google.com/places/place-id" target="_blank" rel="noopener noreferrer">
													Google Place ID Finder
												</a>
											</span>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">
											Google Place URL <span className="text-danger">*</span>
										</label>
										<div className="col-sm-10">
											<input
												name="googlePlaceUrl"
												id="google_place_url"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.googlePlaceUrl}
												className="Common__input"
												required
											/>
											<span id="helpBlock" className="help-block">
												Visit the&nbsp;
												<a href="https://supple.com.au/tools/google-review-link-generator/" target="_blank" rel="noopener noreferrer">
													Supple Review Link Generator
												</a>
												&nbsp;and get a link to existing reviews
											</span>
										</div>
									</div>
									<div className="form-group">
										<label className="col-sm-2 control-label">Facebook Place ID</label>
										<div className="col-sm-10">
											<input
												name="facebookPlaceId"
												id="facebook_place_id"
												type="text"
												onChange={this.genericEventHandler}
												value={this.state.facebookPlaceId}
												className="Common__input"
											/>
										</div>
									</div>
									{!pseudoLocation && (
										<>
											<div className="form-group">
												<label className="col-sm-2 control-label">Business Number ID</label>
												<div className="col-sm-10">
													<input
														name="businessNumber"
														id="business_number"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.businessNumber}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Partial Payment Threshold</label>
												<div className="col-sm-10">
													<input
														name="partialPaymentThreshold"
														id="partial_payment_threshold"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.partialPaymentThreshold}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Minimum Payment Amount</label>
												<div className="col-sm-10">
													<input
														name="minimumPaymentAmount"
														id="minimum_payment_amount"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.minimumPaymentAmount}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Stripe Account ID</label>
												<div className="col-sm-10">
													<input
														name="stripeAccountId"
														id="stripe_account_id"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.stripeAccountId}
														className="Common__input"
													/>
												</div>
											</div>
											{this.state.isKeywordsEnabled && (
												<>
													<div className="form-group">
														<label className="col-sm-2 control-label">Keywords - Template Limit</label>
														<div className="col-sm-10">
															<input
																name="keywordsTemplateLimit"
																id="keywords_template_limit"
																type="text"
																onChange={this.genericEventHandler}
																value={this.state.keywordsTemplateLimit}
																className="Common__input"
															/>
														</div>
													</div>
													<div className="form-group">
														<label className="col-sm-2 control-label">Keywords - Words Per Template</label>
														<div className="col-sm-10">
															<input
																name="keywordsWordsPerTemplate"
																id="keywords_words_per_template"
																type="text"
																onChange={this.genericEventHandler}
																value={this.state.keywordsWordsPerTemplate}
																className="Common__input"
															/>
														</div>
													</div>
												</>
											)}
											<div className="form-group">
												<label className="col-sm-2 control-label">Max Daily SMS Scheduled Message Limit</label>
												<div className="col-sm-10">
													<input
														name="maxDailySmsScheduledMessageLimit"
														id="max_daily_sms_scheduled_message_limit"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.maxDailySmsScheduledMessageLimit}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Max Daily MMS Scheduled Message Limit</label>
												<div className="col-sm-10">
													<input
														name="maxDailyMmsScheduledMessageLimit"
														id="max_daily_mms_scheduled_message_limit"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.maxDailyMmsScheduledMessageLimit}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Max Monthly SMS Scheduled Message Limit</label>
												<div className="col-sm-10">
													<input
														name="maxMonthlySmsScheduledMessageLimit"
														id="max_monthly_sms_scheduled_message_limit"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.maxMonthlySmsScheduledMessageLimit}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Max Monthly MMS Scheduled Message Limit</label>
												<div className="col-sm-10">
													<input
														name="maxMonthlyMmsScheduledMessageLimit"
														id="max_monthly_mms_scheduled_message_limit"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.maxMonthlyMmsScheduledMessageLimit}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Max Weekly Review Request Limit</label>
												<div className="col-sm-10">
													<input
														name="maxWeeklyRequest"
														id="max_weekly_request"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.maxWeeklyRequest}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Max Daily Reengagements</label>
												<div className="col-sm-10">
													<input
														name="maxDailyReengagements"
														id="max_daily_reengagements"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.maxDailyReengagements}
														className="Common__input"
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">Average Conversion Value</label>
												<div className="col-sm-10">
													<input
														name="averageConversionValue"
														id="average_conversion_value"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.averageConversionValue}
														className="Common__input"
													/>
												</div>
											</div>
										</>
									)}

									<div className="form-group">
										<label className="col-sm-2 control-label">Deactivate Date</label>
										<div className="col-sm-10">
											<div className="locations__deactivate-on">
												<div className="InputFromTo InputDate">
													<DayPickerInput
														placeholder="Deactivation Date"
														format="LL"
														onDayChange={deactivateOn => this.setState({ deactivateOn })}
														component={props => <input {...props} value={this.state.deactivateOn ? moment(this.state.deactivateOn).format("LL") : ""} />}
													/>
												</div>
												<Action
													key="clear-deactivate-on-date"
													id="clear-deactivate-on-date"
													className="locations__deactivate-on__clear-button"
													icon={Icon.X}
													label="Remove Deactivation Date"
													onClick={() => this.setState({ deactivateOn: null })}
													transparent={true}
												/>
											</div>
										</div>
									</div>

									<div className="hr-line-dashed" />

									{this.showAreaCodeMismatchWarning() && (
										<div className="locations__warning">
											Warning: The area code of the new text number does not match the area code of the location's main voice number or the default area code.
										</div>
									)}

									<div className="form-group">
										<div className="col-sm-4 col-sm-offset-2">
											<button id="save" className="btn btn-primary" type="submit">
												Save
											</button>
											&nbsp;
											<Link to="/settings/locations" className="btn btn-warning">
												Cancel
											</Link>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</Page>
		);
	}
}

export default ManageLocation;

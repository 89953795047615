const StringService = {
	/**
	 * This function takes in querystring returned by react-router's this.props.location.search as input
	 * @param {String} queryString example input: ?key1=value1&key2=value2
	 * @returns {Object}
	 * The function will return an object in following format {key1: value1, key2: value2}
	 */
	parseQueryString(queryString) {
		const retVal = {};
		const queryStringArr = queryString.slice(1, queryString.length).split("&");
		queryStringArr.forEach(aQueryString => {
			const keyValueArr = aQueryString.split("=");
			retVal[keyValueArr[0]] = keyValueArr[1] || "";
		});
		return retVal;
	},

	/**
	 * Convert normal sting to camelCase
	 * @param {String} words
	 * @returns {String}
	 */
	camelize(words) {
		return words
			.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
				return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
			})
			.replace(/\s+/g, "");
	},
	/**
	 * Convert camelCase to capitalized sentence
	 * @param {String} word
	 * @returns {String}
	 */
	unCamelize(word) {
		return (
			word
				// insert a space before all caps
				.replace(/([A-Z])/g, " $1")
				// uppercase the first character
				.replace(/^./, function(str) {
					return str.toUpperCase();
				})
		);
	},
	/**
	 * get grade from percentage
	 * @param {number} percent
	 * @returns {Srting}
	 */
	convertPercentToGrade(percent) {
		if (percent <= 100 && percent > 90) {
			return "A";
		}
		if (percent <= 90 && percent > 80) {
			return "A-";
		}
		if (percent <= 80 && percent > 70) {
			return "B+";
		}
		if (percent <= 70 && percent > 60) {
			return "B";
		}
		if (percent <= 60 && percent > 50) {
			return "B-";
		}
		if (percent <= 50 && percent > 40) {
			return "C+";
		}
		if (percent <= 40 && percent > 30) {
			return "C";
		}
		if (percent <= 30 && percent > 20) {
			return "C-";
		}
		if (percent <= 20 && percent > 10) {
			return "D+";
		}
		if (percent <= 10 && percent > 0) {
			return "D";
		}
		if (percent <= 0) {
			return "F";
		}
	},

	/**
	 * Get bg color from grade
	 * @param {String} grade
	 */
	getBgColorFromGrades(grade) {
		if (grade === "A" || grade === "A-") {
			return "bg-exciting-green";
		}
		if (grade === "B+" || grade === "B" || grade === "B-") {
			return "bg-boring-green";
		}
		if (grade === "C+" || grade === "C" || grade === "C-") {
			return "bg-yellow";
		}
		if (grade === "D+" || grade === "D" || grade === "D-") {
			return "bg-orange";
		} else {
			return "bg-red";
		}
	},
	/**
	 * Check if email is valid
	 * @param {string} email
	 * @returns {boolean}
	 */
	isEmailValid(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	},
	/**
	 * Convert abb_abb_abb to Abb Abb Abb
	 * @param {string} text
	 * @returns {string}
	 */
	replaceUnderscoreWithSpacesAndCapitalize(text) {
		if (!text) {
			return "";
		}
		return text
			.toLowerCase()
			.split("_")
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}
};

export default StringService;

import React, { Component } from "react";
import ReactSwitch from "react-switch";
import { withTranslation } from "react-i18next";

import ToastService from "../../services/ToastService";
import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";
import MessagesService from "../../services/MessagesService";

import TeamChatMessage from "../MessengerBeta/Thread/TeamChatMessageList/TeamChatMessage";
import Modal from "../../components/common/DHModal";
import TextArea from "../../components/common/TextArea";

import "./update-tasks-modal.css";

class UpdateTasksModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			content: "",
			title: "",
			taskUserId: 0,
			showEveryone: false,
			loading: false,

			/* Foreign Associated Data */
			associatedMessage: null
		};
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		let { taskId, show, createMode, messageId } = this.props;

		await this.update({ content: "", title: "", taskUserId: 0, showEveryone: false, loading: false });

		if (show && createMode && messageId) {
			await this.fetchMessage();
		}

		if (show && taskId && !createMode) {
			await this.fetchTask();
		}
	};

	async componentDidUpdate(prevProps) {
		if (this.props.show && prevProps.show !== this.props.show) {
			await this.resetComponent();
		}
	}

	fetchTask = async () => {
		let { taskId, t } = this.props;
		let locationId = UserService.getActiveLocation().id;

		await this.update({ loading: true });

		let task = await LocationService.fetchTask({ locationId, taskId });

		if (!task) {
			ToastService.info(t("An error occured fetching a task."));
			await this.update({ loading: false });
			return;
		}

		await this.update({
			content: task.content,
			title: task.title,
			taskUserId: task.user_id,
			showEveryone: task.show_everyone,

			associatedMessage: task.Message
		});

		await this.update({ loading: false });
	};

	fetchMessage = async () => {
		let { messageId } = this.props;

		await this.update({ loading: true });

		let associatedMessage = await MessagesService.getMessage(messageId);

		await this.update({ associatedMessage, loading: false });
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onSubmit = async () => {
		let { createMode, taskId, t } = this.props;
		let { content, title, showEveryone, associatedMessage } = this.state;

		let locationId = UserService.getActiveLocation().id;

		let messageId = null;
		if (associatedMessage) {
			messageId = associatedMessage.id;
		}

		if (createMode) {
			let response = await LocationService.createTask({ locationId, title, content, messageId });
			if (!response) {
				ToastService.info(t("An error occured creating a task."));
				return;
			}
		} else {
			let response = await LocationService.updateTask({ locationId, taskId, content, title, showEveryone, messageId });
			if (!response) {
				ToastService.info(t("An error occured creating a task."));
				return;
			}
		}

		if (this.props.onSubmit) {
			await this.props.onSubmit();
		}
	};

	handleTaskContentInputChange = async event => {
		await this.update({ content: event.target.value });
	};

	handleTaskTitleInputChange = async event => {
		await this.update({ title: event.target.value });
	};

	onToggleShowEveryone = async event => {
		let { showEveryone } = this.state;

		await this.update({ showEveryone: !showEveryone });
	};

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	renderTitle = () => {
		const { createMode, t } = this.props;

		return createMode ? t("Create a task") : t("Update a task");
	};

	renderVisibility = () => {
		// DH-3347: Since this doesn't really work for tasks, hide for now.
		return null;

		/*
		let { taskUserId, showEveryone } = this.state;
		let userId = UserService.get().id;

		// Only the task creator should be able to change the visibility
		let disabled = taskUserId !== userId;
		return (
			<>
				<div className="modal__field">Show Everyone</div>
				<ReactSwitch
					id="share-location-task-switch"
					onChange={this.onToggleShowEveryone}
					checked={showEveryone}
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#4A90E2"
					disabled={disabled}
				/>
			</>
		);
		*/
	};

	render() {
		let { show, createMode, t } = this.props;
		let { content, title, loading, associatedMessage } = this.state;

		if (loading) {
			return null;
		}

		return (
			<Modal show={show} title={this.renderTitle()} onHide={this.onHide}>
				<div className="modal__flex-container">
					<input
						id="task-modal-title-input"
						className="ultm__task_title Common__input"
						placeholder={t("Task Title")}
						value={title}
						onChange={this.handleTaskTitleInputChange}
					/>
					<div className="ultm__textarea">
						<TextArea
							id="task-text-modal-input"
							name="task-modal-input"
							type="text"
							height={330}
							rows={4}
							style={{ resize: "none" }}
							value={content}
							showFeedbackFaces={false}
							showFeedbackLength={false}
							showVariables={false}
							blueBorder={true}
							placeholder={t("Type your new task here...")}
							onChange={this.handleTaskContentInputChange}
						/>
					</div>
					{associatedMessage && (
						<div className="ultm__associated_message">
							<div className="ultm__associated_message__name">{associatedMessage.sender_user_name}</div>
							<div className="ultm__associated_message__content">
								<TeamChatMessage
									message={{ event_type: "message", id: associatedMessage.id, content: associatedMessage.content, media: associatedMessage.media }}
									hideHoverOptions={true}
									hideDate={true}
									horizontalMedia={true}
									mediaMaxHeightOrWidth={100}
									mediaReadOnly={true}
								/>
							</div>
						</div>
					)}
					{!createMode && this.renderVisibility()}
					<div className="mb-button" onClick={this.onSubmit}>
						{createMode ? t("Create") : t("Update")}
					</div>
				</div>
			</Modal>
		);
	}
}
export default withTranslation(null, { withRef: true })(UpdateTasksModal);

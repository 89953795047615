import React, { Component } from "react";
import * as Icon from "react-feather";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { CSVLink } from "react-csv";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import UtilityService from "../../services/UtilityService";
import LocationService from "../../services/LocationService";
import ToastService from "../../services/ToastService";
import CsvService from "../../services/CsvService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import SearchInput from "../../components/common/SearchInput";
import Filters from "../../components/common/Filters";
import List from "../../components/common/List";
import withLocation from "../../components/common/WithLocation";

import { SORT_ORDER, DATE_RANGES } from "../../constants/CommonConstants";
import { LEADERBOARD_COLUMNS, LEADERBOARD_FILTERS } from "../../constants/LeaderboardConstants";

import "../../styles/css/scenes/leaderboard.css";

class Leaderboard extends Component {
	constructor(props) {
		super(props);

		let dateRanges = UtilityService.getDateRange(LEADERBOARD_FILTERS.thisMonth.id);
		let dateRangeStart = moment(dateRanges.start).toDate();
		let dateRangeEnd = moment(dateRanges.end).toDate();
		this.state = {
			loading: true,
			loadedAll: false,
			data: [],
			csvData: [],
			searchTerm: "",
			sortField: LEADERBOARD_COLUMNS.rank.sortField,
			sortOrder: SORT_ORDER.asc,
			filterSelected: LEADERBOARD_FILTERS.thisMonth.id,
			dateRangeStart: dateRangeStart,
			dateRangeEnd: dateRangeEnd,
			showAverageResponseTime: false
		};
		this.datePickerTo = React.createRef();
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = () => {
		this.fetchData();
	};

	fetchData = async (resetCache = false) => {
		this.setState({ loading: true });
		const location = UserService.getActiveLocation();

		let { searchTerm, sortField, sortOrder, dateRangeStart, dateRangeEnd } = this.state;

		// Make an API call to get the performance data
		let data = await LocationService.getLeaderboardData({
			locationId: location.id,
			start: dateRangeStart,
			end: dateRangeEnd,
			searchTerm,
			sortField,
			sortOrder,
			resetCache
		});
		if (!data) {
			ToastService.info(`An error occurred. Please try again later.`);
			this.setState({ loading: false });
			return;
		}

		let csvData = [];
		let columnHeaders = Object.keys(LEADERBOARD_COLUMNS);

		for (let i = 0; i < data.length; i++) {
			const aData = data[i];
			let aCsvData = {};
			// Only add the column headers that we show in the UI
			for (let j = 0; j < columnHeaders.length; j++) {
				const header = columnHeaders[j];
				let value = aData[LEADERBOARD_COLUMNS[header].sortField];
				aCsvData[LEADERBOARD_COLUMNS[header].value] = value ? value : 0;
			}
			csvData.push(aCsvData);
		}

		this.setState({ data: data, csvData, loading: false });
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});

		await this.fetchData();
	};

	handleFromChange = async dateRangeStart => {
		let { dateRangeEnd } = this.state;

		if (moment(dateRangeEnd).diff(moment(dateRangeStart), "days") >= 45) {
			dateRangeEnd = moment(dateRangeStart)
				.add(45, "days")
				.toDate();
		}

		await this.update({ dateRangeStart, dateRangeEnd });
		await this.fetchData();
	};

	handleToChange = async dateRangeEnd => {
		let { dateRangeStart } = this.state;

		if (moment(dateRangeEnd).diff(moment(dateRangeStart), "days") >= 45) {
			dateRangeStart = moment(dateRangeEnd)
				.subtract(45, "days")
				.toDate();
		}

		await this.update({ dateRangeStart, dateRangeEnd });
		this.showFromMonth();
		await this.fetchData();
	};

	showFromMonth() {
		const { dateRangeStart, dateRangeEnd } = this.state;
		if (!dateRangeStart) {
			return;
		}
		if (moment(dateRangeEnd).diff(moment(dateRangeStart), "months") < 2) {
			this.datePickerTo.getDayPicker().showMonth(dateRangeStart);
		}
	}

	onFilterSelect = async item => {
		let dateRanges = UtilityService.getDateRange(item.id);
		await this.update({ filterSelected: item.id, dateRangeStart: moment(dateRanges.start).toDate(), dateRangeEnd: moment(dateRanges.end).toDate() });
		await this.fetchData();
	};

	isFilterSelected = item => {
		return item === this.state.filterSelected;
	};

	getFilters = () => {
		let items = Object.keys(LEADERBOARD_FILTERS)
			.map(item => {
				return { id: LEADERBOARD_FILTERS[item].id, value: LEADERBOARD_FILTERS[item].display, order: LEADERBOARD_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			dateRanges: {
				title: "",
				items: items,
				onClick: this.onFilterSelect,
				isSelected: this.isFilterSelected
			}
		};

		return filters;
	};

	getHeaders = () => {
		let items = { ...LEADERBOARD_COLUMNS };
		let { showAverageResponseTime } = this.state;

		// Only show median response time or average response time, but not both
		if (showAverageResponseTime) {
			delete items.median_response_time;
		} else {
			delete items.average_response_time;
		}

		let headers = {
			items: items,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
	};

	onRecordClicked = () => {
		// nothing for now
	};

	renderRecord = recordData => {
		let { showAverageResponseTime } = this.state;
		try {
			// What we have specified in the headers will hide and show correctly depending on user permissions and location features
			let row = [
				recordData.rank,
				recordData.name,
				recordData.number_of_conversations,
				recordData.messages_sent,
				showAverageResponseTime ? recordData.average_response_time : recordData.median_response_time,
				recordData.invites_sent,
				recordData.invites_clicked,
				recordData.invite_click_rate,
				recordData.reminders_sent,
				recordData.attributed_reviews,
				recordData.auto_assigned_contact_count,
				recordData.auto_assigned_responses
			];

			return row;
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderDateRangeFilter = () => {
		let { dateRangeStart, dateRangeEnd } = this.state;
		const modifiers = { dateRangeStart, dateRangeEnd };

		return (
			<div className="common__datepicker__date-range">
				<div className="common__datepicker__date-range__text" data-tip data-for="date-range-selector-rtt">
					Date Range <Icon.Info size={13} />
					<ReactTooltip id="date-range-selector-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
						Filter data based on the specified date range. When selecting custom date ranges there is a limit of a 45 day date range.
					</ReactTooltip>
				</div>
				<div className="input-group">
					<div className="InputFromTo">
						<DayPickerInput
							value={moment(dateRangeStart).toDate()}
							placeholder=" From"
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
								disabledDays: { after: dateRangeEnd },
								toMonth: dateRangeEnd,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.datePickerTo.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<span>
							<DayPickerInput
								ref={el => (this.datePickerTo = el)}
								value={moment(dateRangeEnd).toDate()}
								placeholder=" To"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
									disabledDays: { before: dateRangeStart },
									modifiers,
									month: dateRangeStart,
									fromMonth: dateRangeStart,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>
			</div>
		);
	};

	render() {
		let { data, loading, sortField, sortOrder, dateRangeStart, dateRangeEnd, csvData } = this.state;
		const locationName = CsvService.filterActiveLocationName();

		return (
			<Page>
				<Header title="Leaderboard">
					<Action
						id="toggleResponseTime"
						label="Toggle Response Time"
						icon={Icon.Clock}
						onClick={() => this.update({ showAverageResponseTime: !this.state.showAverageResponseTime })}
					/>
					{csvData && csvData.length > 0 && (
						<CSVLink
							data={csvData}
							filename={`${locationName}-leaderboard-${moment(dateRangeStart).format("YYYY-MM-DD")}-${moment(dateRangeEnd).format("YYYY-MM-DD")}_analytics.csv`}
							target="_self"
						>
							<Action id="download" label="Download CSV" icon={Icon.Download} />
						</CSVLink>
					)}
					<Action id="refresh" label="Refresh Data" icon={Icon.RefreshCcw} onClick={() => this.fetchData(true)} />
				</Header>

				<div className="leaderboard__search">
					<SearchInput placeholder="Search ..." onChange={this.onSearchChange} />
				</div>
				{this.renderDateRangeFilter()}

				<Filters filters={this.getFilters()} />
				<List
					items={data}
					loading={loading}
					sortField={sortField}
					sortOrder={sortOrder}
					loadedAll={true}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					noDataTitle={"Looks like there's no leaderboard data yet..."}
					noDataIcon={<Icon.AlertCircle />}
				/>
			</Page>
		);
	}
}

export default withLocation(Leaderboard);

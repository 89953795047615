import React, { Component } from "react";
import EmojiService from "../../../../services/EmojiService";
import Emojis from "./Emojis";

class EmojisContainer extends Component {
	constructor(props) {
		super(props);

		this.emojiComponent = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		EmojiService.storeReference(this.emojiComponent);
	}

	render() {
		return <Emojis ref={ref => (this.emojiComponent = ref)} />;
	}
}

export default EmojisContainer;

import React, { Component } from "react";
import posed from "react-pose";
import { withTranslation } from "react-i18next";

import UtilityService from "../../../../services/UtilityService";

import Templates from "../../../../components/common/Templates";

import { KEYS } from "../../../../constants/Messenger";

import "./template-selector.css";

const Box = posed.div({
	visible: {
		y: 40,
		opacity: 1
	},
	hidden: {
		y: 700,
		opacity: 0
	}
});

class TemplateSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditTemplateModalOpen: false
		};

		this.templatesComponent = null;
		this.templatesModal = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		document.addEventListener("keydown", this.onKey);
		document.addEventListener("mousedown", this.handleClick, false);
		this.resetComponent();
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	resetComponent = async () => {
		await this.update({ isEditTemplateModalOpen: false });
	};

	handleClick = e => {
		if (this.templatesModal && this.templatesModal.contains(e.target)) {
			return;
		}

		// Prevent closing when the edit template modal is open
		if (this.state.isEditTemplateModalOpen) {
			return;
		}

		this.onClose();
	};

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onClose();
		}
	};

	onToggleShowEditTemplateModal = async isEditTemplateModalOpen => {
		await this.update({ isEditTemplateModalOpen: isEditTemplateModalOpen });
	};

	onSelect = template => {
		if (this.props.onSelect) {
			this.props.onSelect(template);
		}
	};

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onRefreshMessageTemplate = () => {
		if (this.templatesComponent && this.templatesComponent.resetComponent) {
			this.templatesComponent.resetComponent();
		}
	};

	render() {
		let { contactId, show, templateType, showEditAction, showRecentlyUsed, showFavourites } = this.props;
		let isNavigationCollapsed = UtilityService.isNavigationCollapsed();

		return (
			<>
				<Box
					ref={ref => (this.templatesModal = ref)}
					className={`mb-templates ${isNavigationCollapsed ? "mb-templates--collapsed" : "mb-templates--expanded"}`}
					pose={show ? "visible" : "hidden"}
				>
					<Templates
						ref={ref => (this.templatesComponent = ref)}
						onClose={this.onClose}
						onSelect={this.onSelect}
						onToggleShowEditTemplateModal={this.onToggleShowEditTemplateModal}
						templateType={templateType}
						contactId={contactId}
						showEditAction={showEditAction}
						typeEditable={false}
						replaceMessageVariables={true}
						showRecentlyUsed={showRecentlyUsed}
						showFavourites={showFavourites}
					/>
				</Box>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(TemplateSelector);

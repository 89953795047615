import React, { Component } from "react";

import UserService from "../../../services/UserService";
import BulkSendService from "../../../services/BulkSendService";
import CSVParsingService from "../../../services/CSVParsingService";

import BulkUploadCsv from "../BulkUploadCsv";
import BulkCsvMapperAppointments from "./BulkCsvMapperAppointments";
import UploadAppointmentsConfirmBlock from "./UploadAppointmentsConfirmBlock";
import Modal from "../../../components/common/DHModal";
import Alert from "../../../components/common/Alert";

import { BULK_TYPES, SAMPLE_CSV_DATA_APPOINTMENT } from "../../../constants/BulkActionsConstants";
import { CsvMappingConstants } from "../../../constants/CsvMappingConstants";

import "../../MessengerBeta/Thread/Message/message.css";
import "../../../styles/css/components/commons/bulk-actions.css";

class BulkAppointmentUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// Basic information
			bulkLoading: false,
			title: this.props.title ? this.props.title : "Upload Appointments",
			show: this.props.show ? this.props.show : false,

			// The appointments
			appointments: [],

			// CSV Data
			csvFile: [],
			csvHeaders: [],
			csvDataPreview: [],
			csvString: "",
			csvHasHeader: false,

			// Show/hide certain elements
			showCsvUpload: true,
			showCsvMapper: true,

			// Upload status
			bulkUploadError: false,
			bulkErrors: [],
			bulkUploadErrorMessage: "",
			bulkUploadSuccess: false,
			bulkUploadPartialSuccess: false,

			// Limits
			bulkLimit: 0,
			bulkRemaining: 0,
			bulkLimitReached: 0,

			// Timezone
			datesAreInUTC: false,

			// Success / failure feedback
			bulkUploadSuccesses: [],
			bulkActionFailed: false,
			selectedNameFormat: CsvMappingConstants.FORMAT.NAMES.lastCommaFirst.value
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.update({
				show: this.props.show
			});
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleGenericEventHandler = event => {
		this.update({ [event.target.name]: event.target.value });
	};

	toggleModal = async () => {
		await this.update({
			show: !this.state.show
		});
	};

	closeModal = async () => {
		await this.update({
			show: false
		});
		await this.resetAllStates();
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	toggleShowCsvUpload = () => {
		this.update({ showCsvUpload: !this.state.showCsvUpload });
	};

	toggleShowCsvMapper = () => {
		this.update({ showCsvMapper: !this.state.showCsvMapper });
	};

	getCsvDataPreview = (csvDataPreview, csvHasHeader, csvString) => {
		this.update({ csvDataPreview: csvDataPreview, csvHasHeader: csvHasHeader, csvString: csvString });
	};

	setCsvFile = csvFile => {
		this.update({ csvFile: csvFile });
	};

	updateMappedData = async (processedHeaders, processedCsvString, processedCsvData) => {
		await this.update({ csvHeaders: processedHeaders, csvString: processedCsvString, csvDataPreview: processedCsvData });
		await this.parseCsv();
	};

	parseCsv = async () => {
		let { csvString, csvHeaders, csvHasHeader } = this.state;

		let appointments = await CSVParsingService.parseCSVString(
			{
				noheader: !csvHasHeader,
				headers: csvHeaders,
				colParser: {
					skip: "omit" //any column with the skip header will be omitted
				}
			},
			csvString
		);

		await this.update({ appointments: appointments });
		await this.addLocationToAppointments();
	};

	async addLocationToAppointments() {
		let { appointments } = this.state;
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";

		for (const appointment of appointments) {
			appointment.locationId = locationId;
		}

		await this.update({ appointments: appointments });
	}

	bulkSend = async () => {
		try {
			await this.update({ bulkLoading: true });

			let { appointments, selectedNameFormat, datesAreInUTC } = this.state;

			let options = {
				nameFormat: selectedNameFormat,
				datesAreInUTC: datesAreInUTC
			};

			const bulkData = await BulkSendService.uploadAppointments({ appointments, options });

			await this.update({
				bulkLoading: false,
				bulkUploadSuccesses: bulkData.successfulRequests,
				successFeedback: bulkData.successFeedback,
				errorFeedback: bulkData.errorFeedback
			});

			if (bulkData.errors.length > 0) {
				await this.update({
					bulkErrors: bulkData.errors
				});
				if (bulkData.successfulRequests.length > 0) {
					await this.update({
						bulkUploadPartialSuccess: true,
						bulkUploadErrorMessage: "There was an error uploading some appointments."
					});
				} else {
					await this.update({
						bulkUploadError: true,
						bulkUploadErrorMessage: "Sorry. There there was an error with creating your appointments. "
					});
				}

				// Send the status email
				this.sendBulkStatusEmail();
			} else {
				await this.update({
					bulkUploadSuccess: true,
					bulkErrors: []
				});

				// Send the status email
				this.sendBulkStatusEmail();
			}
		} catch (error) {
			// For bulk actions we try to give the user some feedback. If we got here something must've really gone wrong
			await this.update({ bulkActionFailed: true, show: false });
		}

		await this.update({ bulkLoading: false });
	};

	async sendBulkStatusEmail() {
		try {
			let { csvString, csvHasHeader, bulkErrors, successFeedback, errorFeedback } = this.state;

			await BulkSendService.sendBulkStatusEmail(
				BULK_TYPES.appointmentUpload.friendlyName,
				"upload",
				successFeedback.length + errorFeedback.length,
				successFeedback.length,
				errorFeedback.length,
				bulkErrors,
				csvString,
				csvHasHeader
			);
		} catch (err) {
			console.log(err);
		}
	}

	resetAllStates = async () => {
		await this.update({
			bulkLoading: false,
			show: true,
			appointments: [],
			csvFile: [],
			csvHeaders: [],
			csvDataPreview: [],
			csvString: "",
			csvHasHeader: false,
			showCsvUpload: true,
			showCsvMapper: true,
			bulkUploadError: false,
			bulkErrors: [],
			bulkUploadSuccess: false,
			bulkUploadPartialSuccess: false,
			bulkActionFailed: false,
			datesAreInUTC: false
		});
	};

	updateBulkLimit = limit => {
		this.update({
			bulkLimit: limit
		});
	};

	handleCsvMapperNext = (selectedNameFormat = CsvMappingConstants.FORMAT.NAMES.auto.value, datesAreInUTC = false) => {
		this.toggleShowCsvMapper();
		this.update({ selectedNameFormat: selectedNameFormat, datesAreInUTC: datesAreInUTC });
	};

	render() {
		let {
			show,
			showCsvUpload,
			csvDataPreview,
			csvHasHeader,
			csvString,
			csvHeaders,
			showCsvMapper,
			csvFile,
			bulkLoading,
			bulkUploadSuccess,
			bulkUploadError,
			bulkUploadPartialSuccess,
			title,
			errorFeedback,
			successFeedback,
			appointments,
			bulkActionFailed
		} = this.state;

		let { onCompleted } = this.props;

		return (
			<div>
				<Modal show={show} onHide={this.closeModal} title={showCsvUpload || showCsvMapper ? title : ""}>
					<div className="bulk-contact-upload">
						{showCsvUpload ? (
							<BulkUploadCsv
								show={showCsvUpload}
								next={this.toggleShowCsvUpload}
								updateCsvData={this.getCsvDataPreview}
								setCsvFile={this.setCsvFile}
								onCancel={this.toggleModal}
								csvFile={csvFile}
								csvData={csvDataPreview}
								csvHasHeader={csvHasHeader}
								updateBulkLimit={this.updateBulkLimit}
								header="Upload a list of appointments you would like to add to Demandhub."
								fileLimitText="Limit of 1 CSV file per upload."
								rowLimitText="Up to 2,000 appointments can be uploaded at a time."
								sampleCSV={SAMPLE_CSV_DATA_APPOINTMENT}
							/>
						) : showCsvMapper ? (
							<BulkCsvMapperAppointments
								csvData={csvDataPreview}
								csvString={csvString}
								csvHasHeader={csvHasHeader}
								csvHeaders={csvHeaders}
								back={this.toggleShowCsvUpload}
								next={this.handleCsvMapperNext}
								updateMappedData={this.updateMappedData}
								showFirsXRows={10}
								nextButtonLabel="Upload Appointments"
								backButtonLabel="Change Csv"
							/>
						) : (
							<UploadAppointmentsConfirmBlock
								bulkLoading={bulkLoading}
								bulkActionSuccess={bulkUploadSuccess}
								bulkActionPartialSuccess={bulkUploadPartialSuccess}
								bulkActionError={bulkUploadError}
								onConfirm={this.bulkSend}
								onNext={() => {
									// fetch the latest appointments
									if (onCompleted) {
										onCompleted(this.state.successFeedback);
									}
									this.resetAllStates();
								}}
								back={() => {
									this.setState({
										showCsvMapper: true
									});
								}}
								successFeedback={successFeedback}
								errorFeedback={errorFeedback}
								csvAppointments={appointments}
							/>
						)}
					</div>
				</Modal>
				<Alert
					type="error"
					show={bulkActionFailed}
					title="Failed"
					confirm="OK"
					onClose={() => {
						this.setState({ bulkActionFailed: false });
						this.resetAllStates();
					}}
				>
					<div>Unexpected error while trying to create appointments.</div>
				</Alert>
			</div>
		);
	}
}

export default BulkAppointmentUpload;

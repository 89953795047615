import React from "react";
import * as Icon from "react-feather";
import posed from "react-pose";

import ShortLinkService from "../../../../services/ShortLinkService";
import UserService from "../../../../services/UserService";
import ToastService from "../../../../services/ToastService";

import Action from "../../../../components/common/Action";
import ManageShortLinkModal from "../../../Settings/ShortLinks/ManageShortLinkModal";

import { KEYS } from "../../../../constants/Messenger";
import { SHORT_LINK_COLUMNS, SHORT_LINK_LIMIT, SHORT_LINK_TYPES } from "../../../../constants/ShortLinksConstants";
import { SORT_ORDER } from "../../../../constants/CommonConstants";

import "./send-short-links-modal.css";

const Box = posed.div({
	visible: {
		y: -135,
		opacity: 1
	},
	hidden: {
		y: 350,
		opacity: 0
	}
});

class SendShortLinksModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			shortLinks: [],
			showManageShortLinkModal: false,
			selectedShortLinkId: null
		};

		this.shortLinkModal = null;
		this.manageShortLinkModal = null;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKey);
		document.addEventListener("mousedown", this.handleClick, false);
		this.resetComponent();
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let shortLinks = await ShortLinkService.fetch({
			locationId: UserService.getActiveLocation().id,
			type: SHORT_LINK_TYPES.custom,
			limit: SHORT_LINK_LIMIT,
			sortField: SHORT_LINK_COLUMNS.long_url.sortField,
			sortOrder: SORT_ORDER.asc
		});

		await this.update({ shortLinks, showManageShortLinkModal: false, selectedShortLinkId: null });
	};

	handleClick = event => {
		// If the manage short link modal is open, don't close the modal
		if (this.state.showManageShortLinkModal) {
			return;
		}

		if (this.shortLinkModal && this.shortLinkModal.contains(event.target)) {
			// the click is in this component
			return;
		}

		if (this.manageShortLinkModal && this.manageShortLinkModal.contains && this.manageShortLinkModal.contains(event.target)) {
			// the click is in this component
			return;
		}

		this.onClose();
	};

	onHideManageShortLinkModal = async updatedShortLink => {
		if (updatedShortLink) {
			ToastService.info("Short link saved.");

			await this.resetComponent();
			this.update({
				showManageShortLinkModal: false,
				selectedShortLinkId: updatedShortLink.id
			});

			return;
		}

		this.update({
			showManageShortLinkModal: false,
			selectedShortLinkId: null
		});
	};

	onCreateShortLink = async () => {
		await this.update({
			showManageShortLinkModal: true,
			selectedShortLinkId: null
		});
	};

	onEditShortLink = async shortLinkId => {
		await this.update({
			showManageShortLinkModal: true,
			selectedShortLinkId: shortLinkId
		});
	};

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onClose();
		}
	};

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	render = () => {
		const { show, onSelect } = this.props;
		const { shortLinks, showManageShortLinkModal, selectedShortLinkId } = this.state;

		let allowManage = UserService.isSuperAdminOrCustomerSuccessOrAccountOwner();

		return (
			<>
				<Box ref={ref => (this.shortLinkModal = ref)} className={`sslm`} pose={show ? "visible" : "hidden"}>
					<div className="sslm__close" onClick={this.onClose}>
						<Icon.X size="18" />
					</div>
					<div className="sslm__header">
						<div className="sslm__header__text">Custom short links</div>
						{allowManage ? (
							shortLinks && shortLinks.length < SHORT_LINK_LIMIT ? (
								<div className="sslm__header__create">
									<Icon.Plus size="18" onClick={this.onCreateShortLink} />
								</div>
							) : (
								<div className="sslm__header__limit">(Limit reached)</div>
							)
						) : null}
					</div>
					{shortLinks &&
						shortLinks.map((link, index) => (
							<div key={link.id} className={`sslm__item`}>
								<div
									key={link.id}
									className={`sslm__item__link  ${selectedShortLinkId === link.id ? "sslm__item--selected" : ""}`}
									onClick={() => onSelect(link.short_url)}
								>
									<div className="sslm__item__link__icon">
										<Icon.Link size={16} />
									</div>
									<div className="sslm__item__link__name">{link.name ? link.name : link.long_url}</div>
									<div className="sslm__item__link__long-url">{link.name ? link.long_url : ""}</div>
								</div>
								{allowManage && (
									<div className="sslm__item__actions">
										<Action id={`${index}-manage`} label={`View Details`} icon={Icon.Edit2} tooltipLeft={true} onClick={() => this.onEditShortLink(link.id)} />
									</div>
								)}
							</div>
						))}
					{(!shortLinks || shortLinks.length === 0) && (
						<div className="sslm__item sslm__item--none">
							<div className="sslm__item__name">No Short Links found.</div>
							<img className="sslm__item__img" src="https://cdn.demandhub.co/web-app/assets/blank_canvas.svg" />
						</div>
					)}
				</Box>
				<ManageShortLinkModal
					ref={ref => (this.manageShortLinkModal = ref)}
					show={allowManage && showManageShortLinkModal}
					id={selectedShortLinkId ? selectedShortLinkId : null}
					onHide={this.onHideManageShortLinkModal}
				/>
			</>
		);
	};
}

export default SendShortLinksModal;

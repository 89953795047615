import axios from "axios";

import Kichiri from "./KichiriService";
import UserService from "./UserService";

import AppConfig from "../config/app/web-app.config.js";
import { GBM } from "../constants/GBMConstants";

// Google Business Messages Service
export const GbmService = {
	/**
	 * Create a brand and an agent
	 * @param {Integer} locationId
	 * @param {String} brandName
	 * @param {Object} agentObject
	 * @param {String} logoUrl
	 * @return {Object}
	 */
	async createAgent({ locationId, brandName, agentObject, logoUrl }) {
		try {
			const response = await Kichiri.location.gbmCreateAgent({ locationId, brandName, agentObject, logoUrl }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
			if (error && error.response && error.response.data && typeof error.response.data.errorMessages !== "undefined") {
				return { isValid: error.response.data.isValid, errorMessages: error.response.data.errorMessages };
			}
		}
		return null;
	},

	/**
	 * Update an agent
	 * @param {Integer} locationId
	 * @param {Object} agentObject
	 * @return {Object}
	 */
	async updateAgent({ locationId, agentObject }) {
		try {
			const response = await Kichiri.location.gbmUpdateAgent({ locationId, agentObject }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
			if (error && error.response && error.response.data && typeof error.response.data.errorMessages !== "undefined") {
				return { errorMessages: error.response.data.errorMessages };
			}
		}
		return null;
	},

	/**
	 * Fetch a GBM Messenger Integration
	 *
	 * @param {Integer} locationId
	 * @returns {Object} If there is one a Messenger Integration will be returned, if none is found null is returned.
	 */
	async fetchGbmIntegration({ locationId }) {
		try {
			let response = await Kichiri.location.gbmFetchIntegration({}, { locationId }, UserService.getAuthToken());
			if (!response.data.integration) {
				return null;
			}

			let integration = response.data.integration;
			try {
				if (integration) {
					integration.properties = JSON.parse(integration.properties);
				}
			} catch (error) {
				console.log(error);
			}

			return integration;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a GBM Messenger Integration for a location
	 *
	 * @param {Integer} locationId
	 * @returns {Object} Messenger Integration
	 */
	async createGbmIntegration({ locationId }) {
		try {
			let response = await Kichiri.location.gbmCreateIntegration({ locationId }, {}, UserService.getAuthToken());
			let integration = response.data;

			try {
				integration.properties = JSON.parse(integration.properties);
			} catch (error) {
				console.log(error);
			}
			return integration;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Upload an agent logo to S3
	 *
	 * @param {Integer} locationId
	 * @param {Object} file
	 * @returns {String} The url of where it is stored on S3
	 */
	async uploadAgentLogo({ locationId, file }) {
		try {
			let data = new FormData();

			data.append("file", file);
			data.append("locationId", locationId);

			let url = `${AppConfig.API_SERVER}/api/location/gbm/agent_logo`;

			let response = await axios.post(url, data, {
				method: "POST",
				headers: {
					Authorization: UserService.getAuthToken()
				}
			});

			return response.data.url;
		} catch (error) {
			console.log(error);
			if (error && error.response && error.response.data && typeof error.response.data.errorMessages !== "undefined") {
				return { errorMessages: error.response.data.errorMessages };
			}
		}
		return null;
	},

	/**
	 * Add a GMB location to the Agent
	 *
	 * @param {Integer} locationId
	 * @returns {Object} The location response object from google
	 */
	async addLocation({ locationId }) {
		try {
			let response = await Kichiri.location.gbmAddLocation({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
			if (error && error.response && error.response.data && typeof error.response.data.errorMessages !== "undefined") {
				return { errorMessages: error.response.data.errorMessages };
			}
		}
		return null;
	},

	/**
	 * Synchronize a GMB Agent with GBM
	 *
	 * @param {Integer} locationId
	 * @returns {Object} The location response object from google
	 */
	async synchronizeAgent({ locationId }) {
		try {
			let response = await Kichiri.location.gbmSyncIntegration({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
			if (error && error.response && error.response.data && typeof error.response.data.errorMessages !== "undefined") {
				return { errorMessages: error.response.data.errorMessages };
			}
		}
		return null;
	},

	/**
	 * Send a request to Google Communications API to verify the agent
	 * @param {Integer} locationId
	 * @param {String} googleAuthCode
	 * @param {String} brandContactName
	 * @param {String} brandContactEmail
	 * @param {String} brandUrl
	 *
	 * @returns {Object} Messenger Integration
	 */
	async verifyAgent({ locationId, googleAuthCode, brandContactName, brandContactEmail, brandUrl }) {
		try {
			let response = await Kichiri.location.gbmVerifyAgent(
				{ locationId, googleAuthCode, brandContactName, brandContactEmail, brandUrl },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check the verification and launch status' of an agent or location
	 * @param {Integer} locationId
	 *
	 * @returns {Object} Messenger Integration
	 */
	async gbmCheckStatus({ locationId }) {
		try {
			let response = await Kichiri.location.gbmCheckStatus({}, { locationId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Send a request to Google Communications API to launch the agent
	 * @param {Integer} locationId
	 *
	 * @returns {Object} Messenger Integration
	 */
	async launchAgent({ locationId }) {
		try {
			let response = await Kichiri.location.gbmLaunchAgent({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Send a request to Google Communications API to verify the location
	 * @param {Integer} locationId
	 *
	 * @returns {Object} Messenger Integration
	 */
	async verifyLocation({ locationId }) {
		try {
			let response = await Kichiri.location.gbmVerifyLocation({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Send a request to Google Communications API to launch the location
	 * @param {Integer} locationId
	 *
	 * @returns {Object} Messenger Integration
	 */
	async launchLocation({ locationId }) {
		try {
			let response = await Kichiri.location.gbmLaunchLocation({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Helper method - Check if a messenger integration has an agent object in the properties field
	 * @param {Object} integration
	 * @returns {Boolean}
	 */
	hasGbmAgent(integration) {
		let hasGbmAgent = integration && integration.properties && integration.reference_id && integration.properties.agent;
		return hasGbmAgent;
	},

	/**
	 * Helper method - Check if a messenger integration has a location object (ei -> A GMB location has been added to the agent) in the properties field
	 * @param {Object} integration
	 * @returns {Boolean}
	 */
	hasGbmAgentLocation(integration) {
		let hasGbmAgentLocation = integration && integration.properties && integration.reference_id && integration.properties.location;
		return hasGbmAgentLocation;
	},

	/**
	 * Helper method - Check if a messenger integration has a verified agent and location status
	 * @param {Object} integration
	 * @returns {Boolean}
	 */
	isVerified(integration) {
		return this.isAgentVerified(integration) && this.isLocationVerified(integration);
	},

	/**
	 * Helper method - Check if a messenger integration has a verified agent status
	 * @param {Object} integration
	 * @returns {Boolean}
	 */
	isAgentVerified(integration) {
		return (
			integration &&
			integration.properties &&
			integration.reference_id &&
			integration.properties.agentVerification &&
			integration.properties.agentVerification.verificationState === GBM.verificationState.VERIFICATION_STATE_VERIFIED.id
		);
	},

	/**
	 * Helper method - Check if a messenger integration has a verified location status
	 * @param {Object} integration
	 * @returns {Boolean}
	 */
	isLocationVerified(integration) {
		return (
			integration &&
			integration.properties &&
			integration.reference_id &&
			integration.properties.locationVerification &&
			integration.properties.locationVerification.verificationState === GBM.verificationState.VERIFICATION_STATE_VERIFIED.id
		);
	},

	/**
	 * Helper method - Get the agent verification state
	 * @param {Object} integration
	 * @returns {String}
	 */
	agentVerificationState(integration) {
		if (integration && integration.properties && integration.reference_id && integration.properties.agentVerification) {
			return integration.properties.agentVerification.verificationState;
		}
		return null;
	},

	/**
	 * Helper method - Get the location verification state
	 * @param {Object} integration
	 * @returns {String}
	 */
	locationVerificationState(integration) {
		if (integration && integration.properties && integration.reference_id && integration.properties.locationVerification) {
			return integration.properties.locationVerification.verificationState;
		}
		return null;
	},

	/**
	 * Helper method - Check if a messenger integration is launched in the properties field
	 * @param {Object} integration
	 * @returns {Boolean}
	 */
	isLaunched(integration) {
		return this.isAgentLaunched(integration) && this.isLocationLaunched(integration);
	},

	/**
	 * Helper method - Check if the agent has a launched state
	 * @param {Object} integration
	 * @returns {String}
	 */
	isAgentLaunched(integration) {
		return (
			integration &&
			integration.properties &&
			integration.reference_id &&
			integration.properties.agentLaunch &&
			integration.properties.agentLaunch.businessMessages &&
			integration.properties.agentLaunch.businessMessages.launchDetails &&
			integration.properties.agentLaunch.businessMessages.launchDetails.LOCATION &&
			integration.properties.agentLaunch.businessMessages.launchDetails.LOCATION.launchState === GBM.launchState.LAUNCH_STATE_LAUNCHED.id
		);
	},

	/**
	 * Helper method - Check if the location has a launched state
	 * @param {Object} integration
	 * @returns {String}
	 */
	isLocationLaunched(integration) {
		return (
			integration &&
			integration.properties &&
			integration.reference_id &&
			integration.properties.locationLaunch &&
			integration.properties.locationLaunch.launchState === GBM.launchState.LAUNCH_STATE_LAUNCHED.id
		);
	},

	/**
	 * Helper method - Get the agent launch state
	 * @param {Object} integration
	 * @returns {String}
	 */
	agentLaunchState(integration) {
		try {
			if (integration && integration.properties && integration.reference_id && integration.properties.agentLaunch) {
				return integration.properties.agentLaunch.businessMessages.launchDetails.LOCATION.launchState;
			}
		} catch (error) {
			console.log(`agent launch state error - ${error}`);
		}
		return null;
	},

	/**
	 * Helper method - Get the location launch state
	 * @param {Object} integration
	 * @returns {String}
	 */
	locationLaunchState(integration) {
		if (integration && integration.properties && integration.reference_id && integration.properties.locationLaunch) {
			return integration.properties.locationLaunch.launchState;
		}
		return null;
	},

	/**
	 * Get the test urls of an agent
	 * @param {Object} integration
	 * @returns {Array}
	 */
	getTestUrls(integration) {
		if (
			!integration ||
			!integration.properties ||
			!integration.reference_id ||
			!integration.properties.agent ||
			!integration.properties.agent.businessMessagesAgent ||
			!integration.properties.agent.businessMessagesAgent.testUrls
		) {
			return [];
		}

		return integration.properties.agent.businessMessagesAgent.testUrls;
	}
};

export default GbmService;

import React, { Component } from "react";
import moment from "moment";
import createDOMPurify from "dompurify";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";
import ReviewSiteService from "../../services/ReviewSiteService";
import ReviewService from "../../services/ReviewService";

import Spinners from "./Spinners";
import StarRating from "../../components/common/StarRating";

import { SENTIMENT_THRESHOLD, CONDENSED_KEYWORDS } from "../../constants/InsightConstants";

import "../../styles/css/scenes/insight.css";

class Insight extends Component {
	constructor(props) {
		super(props);
		this.state = {
			insight: this.props.baseInsight,
			keyword: this.props.baseInsight.keyword,
			id: this.props.baseInsight.id,
			reviewIdsFromInsight: JSON.parse(this.props.baseInsight.reviews),
			reviewsFromInsight: [],
			sentiment: this.props.sentiment,
			showPopup: false,
			loading: false
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	updateDimensions = () => {
		this.forceUpdate();
	};

	/**
	 * This function updates the left-margin value to be assigned to the emoticon elements
	 * based on the size of the viewing device. Takes in % covered based on sentiment and returns
	 * margin in pixels.
	 * @param {Int} marginVal current value of left-margin % for placing the resultant emoticon
	 * @param {Int} parentWidth width of parent element
	 */
	adjustMarginsToDevice(marginVal, parentWidth) {
		//Make sure both of the smileyWidth options align with css height/width for the smiley faces
		let smileyWidth = UtilityService.mobileCheck() ? 25 : 50;
		marginVal = (marginVal / 100) * parentWidth;
		if (marginVal > parentWidth - smileyWidth) {
			marginVal = parentWidth - smileyWidth;
		}

		return marginVal;
	}

	/**
	 * This function is designed to take the "keyword" paramater, find all instances of it
	 * within the "review" parameter, and replace them with a bolded version of the keyword
	 * with a yellow background. Returns sanitized DOM object.
	 * @param {string} keyword word that is to be replaced
	 * @param {string} review the review being referenced that contains the keyword
	 */
	boldKeywords(review, keyword) {
		const DOMPurify = createDOMPurify(window);
		var result = "";
		//If keyword is a multi-noun phrase simply replace all references
		if (keyword.includes(" ")) {
			review = review.replace(new RegExp(keyword, "g"), "<span class='highlight-keyword'>" + keyword.bold() + "</span>");
			result = review;
		} else {
			let words = review.split(/(\s+|\?|!|\.|,)/g);
			for (let i = 0; i < words.length; i++) {
				var isCondensedNoun = false;
				if (CONDENSED_KEYWORDS[keyword]) {
					isCondensedNoun = CONDENSED_KEYWORDS[keyword].includes(words[i]);
				}
				if (words[i] === keyword || words[i].toLowerCase() === keyword || isCondensedNoun) {
					words[i] = "<span class='highlight-keyword'>" + words[i].bold() + "</span>";
				}
			}
			result = words.join("");
		}
		return DOMPurify.sanitize("<p>" + result + "</p>");
	}

	togglePopup = () => {
		if (!this.state.showPopup && (!this.state.reviewsFromInsight || this.state.reviewsFromInsight.length === 0)) {
			//only fetch when the popup is transistining from hide to show
			//and we havent fetched data once
			this.getReviewsFromInsight();
		}
		this.setState({ showPopup: !this.state.showPopup });
	};

	/**
	 * Asynchronous function that makes the API call that fetches reviews in which the base
	 * "Insight" keyword is mentioned. Updates component state on success, logs error on failure.
	 */
	getReviewsFromInsight = async () => {
		await this.update({ reviewsFromInsight: [], loading: true });

		const reviews = await ReviewService.fetchReviewsByIds({ reviewIds: this.state.reviewIdsFromInsight, locationId: UserService.getActiveLocation().id });

		this.update({ reviewsFromInsight: reviews, loading: false });
	};

	render() {
		let { t } = this.props;

		let insight = this.state.insight;
		let adjectives = Object.keys(JSON.parse(insight.adjectives));
		//Define keyword and scorebar proportions based on current dimensions
		let barWidth = UtilityService.mobileCheck() ? 0.48 * window.innerWidth : 0.38 * window.innerWidth;
		let keywordWidth = UtilityService.mobileCheck() ? 0.32 * window.innerWidth : 0.14 * window.innerWidth;
		let barWidthStyle = {
			width: barWidth + "px"
		};
		let keywordStyle = {
			width: keywordWidth + "px"
		};
		let marginVal = insight.value * 10;
		marginVal = this.adjustMarginsToDevice(marginVal, barWidth);
		let margin = {
			marginLeft: marginVal + "px"
		};
		let heading = insight.mentions + (insight.mentions <= 1 ? " review" : " reviews");
		let isPositiveSentiment = insight.sentiment === 1;
		let isNegativeSentiment = !isPositiveSentiment;
		let constants = SENTIMENT_THRESHOLD;
		return (
			<div key={this.state.insight.id} className="ReviewsInsights__Insights">
				<div className="ReviewsInsights__Container">
					<div className="ReviewInsights__keywordContainer" style={keywordStyle}>
						<h3>{insight.keyword}</h3>
						<p onClick={() => this.togglePopup()} className="Insight__mentions">
							{heading}
						</p>
					</div>
					<div className="ReviewsInsights__Keywords--score" ref="scorebar" style={barWidthStyle}>
						<div className="ReviewsInsights__scoreline" style={barWidthStyle} />
						{isPositiveSentiment && insight.value >= constants.extremelyPositiveThreshold && <div className="ReviewsInsights__bigSmileyFace" style={margin} />}
						{isPositiveSentiment && insight.value >= constants.positiveThreshold && insight.value < constants.extremelyPositiveThreshold && (
							<div className="ReviewsInsights__smileyFace" style={margin} />
						)}
						{isPositiveSentiment && insight.value >= constants.slightlyPositiveThreshold && insight.value < constants.positiveThreshold && (
							<div className="ReviewsInsights__neutralFace" style={margin} />
						)}
						{isNegativeSentiment && insight.value >= constants.extremelyNegativeThreshold && insight.value < constants.slightlyNegativeThreshold && (
							<div className="ReviewsInsights__sadFace" style={margin} />
						)}
						{isNegativeSentiment && insight.value >= 0 && insight.value < constants.extremelyNegativeThreshold && (
							<div className="ReviewsInsights__verySadFace" style={margin} />
						)}
					</div>
				</div>
				{this.state.showPopup === true ? (
					<div>
						<div>
							<p>
								{adjectives.length > 0 && (
									<b>
										{t("Common usages of the word:")} "{insight.keyword}"
									</b>
								)}
							</p>
							{adjectives.map(adjective => {
								return (
									<div key={adjective} className="btn ReviewInsights__noun-adjectives">
										{adjective}
									</div>
								);
							})}
						</div>
						<div className="ReviewsInsights__reviewcontent">
							{this.state.loading ? (
								<div className="Common__spinnerchild--center">
									<Spinners type="tail-fade" loading={this.state.loading} size="120px" />
								</div>
							) : (
								<div>
									<p>
										<b>
											{t("Here are the top {{number}} reviews mentioning", { number: this.state.reviewsFromInsight.length })} "{insight.keyword}"{" "}
										</b>
									</p>
									{this.state.reviewsFromInsight.map(review => {
										if (!review) return "";
										return (
											<div key={review.id}>
												<StarRating rating={review.rating} maxRating={review.max_rating} />
												<div id="comments" dangerouslySetInnerHTML={{ __html: this.boldKeywords(review.comments, insight.keyword) }} />
												<div className="ReviewInsights__information--text">
													{t("Posted on")} {moment(review.posted_at).format("ll")} {review.posted_by ? `- ${review.posted_by}` : ""} (
													{ReviewSiteService.getSiteNameFromId(review.site_id)})
												</div>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
				) : (
					<div />
				)}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(Insight);

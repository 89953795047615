export const TAG_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	created_at: {
		id: "created_at",
		value: "Date Created",
		sortable: true,
		sortField: "created_at",
		width: 2,
		widthMd: 0
	},
	updated_at: {
		id: "updated_at",
		value: "Last Updated",
		sortable: true,
		sortField: "updated_at",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 2,
		notClickable: true
	}
};

export const TAG_ACTIONS = {
	addToContacts: "addToContacts",
	overwriteToContacts: "overwriteToContacts",
	removeToContacts: "removeToContacts"
};

export const TAG_TYPES = {
	group: "group",
	tag: "tag"
};

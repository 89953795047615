import React from "react";
import moment from "moment";
import * as Icon from "react-feather";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import Action from "../../../../components/common/Action";
import TextArea from "../../../../components/common/TextArea";
import TeamChatMessage from "./TeamChatMessage";

class TeamChatScheduledMessagesListItem extends React.PureComponent {
	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	renderDatePicker = () => {
		let { minSendAfter, sendAfter, onDateChange } = this.props;

		let minTime = moment()
			.startOf("day")
			.toDate();

		if (moment().format("YYYY-MM-DD") === moment(sendAfter).format("YYYY-MM-DD")) {
			minTime = moment(minSendAfter).toDate();
		}

		return (
			<div className="mb-tcsm__item__date-picker">
				<DatePicker
					minDate={minSendAfter}
					placeholderText="Send Date"
					selected={moment(sendAfter).toDate()}
					onChange={onDateChange}
					shouldCloseOnSelect={true}
					dateFormat="MMMM dd, yyyy"
					className="Common__input mb-send__picker__date"
				/>
				<DatePicker
					minDate={minSendAfter}
					minTime={minTime}
					maxTime={setHours(setMinutes(new Date(), 45), 23)}
					placeholderText="Send Time"
					selected={moment(sendAfter).toDate()}
					onChange={onDateChange}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption="Time"
					shouldCloseOnSelect={true}
					dateFormat="h:mm aa"
					className="Common__input mb-send__picker__time"
				/>
			</div>
		);
	};

	render = () => {
		let {
			conversationId,
			conversations,
			editing,
			content,
			queuedMessage,
			selectedMessage,
			onEditMessage,
			onDeleteMessage,
			onContentChange,
			onSave,
			onCancel
		} = this.props;
		let isEditing = editing && selectedMessage && selectedMessage.id === queuedMessage.id;

		let conversation = null;

		if (!conversationId) {
			conversation = conversations.find(o => o.id === queuedMessage.conversation_id);
		}

		return (
			<div className="mb-tcsm__item">
				<div className="mb-tcsm__item__header">
					<div className="mb-tcsm__item__header__text">
						{!conversationId && <div className="mb-tcsm__item__header__text__name">{conversation ? conversation.name : queuedMessage.Conversation.name} -</div>}
						<div className="mb-tcsm__item__header__text__send-after">
							Scheduled to send on {moment(queuedMessage.send_after).format("dddd, MMMM Do YYYY @ h:mm a")}
						</div>
					</div>

					<div className="mb-tcsm__item__header__actions">
						<Action id={`edit-${queuedMessage.id}`} label="Edit" icon={Icon.Edit} onClick={onEditMessage} />
						<Action id={`delete-${queuedMessage.id}`} label="Delete" icon={Icon.Trash2} onClick={onDeleteMessage} />
					</div>
				</div>

				{isEditing && this.renderDatePicker()}

				<div className="mb-tcsm__item__content">
					{isEditing ? (
						<TextArea
							name="noteMessage"
							id="noteMessage"
							type="text"
							height={100}
							rows={4}
							value={content}
							onChange={onContentChange}
							blueBorder={true}
							showMessageTemplates={false}
						/>
					) : (
						<TeamChatMessage
							message={{ event_type: "message", id: queuedMessage.id, content: queuedMessage.content, media: queuedMessage.media, reactions: null }}
							hideHoverOptions={true}
							hideDate={true}
							horizontalMedia={true}
							mediaMaxHeightOrWidth={100}
							mediaReadOnly={false}
						/>
					)}
				</div>
				{isEditing && (
					<div className="mb-tcsm__item__editing">
						<div className="mb-button mb-button--fit" onClick={onSave}>
							Save
						</div>
						<div className="mb-button mb-button--cancel mb-button--fit" onClick={onCancel}>
							Cancel
						</div>
					</div>
				)}
			</div>
		);
	};
}

export default TeamChatScheduledMessagesListItem;

import React, { Component } from "react";
import cronstrue from "cronstrue";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";
import moment from "moment";

import UserService from "../../services/UserService";
import WorkflowService from "../../services/WorkflowService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

import Dropdown from "../../components/common/Dropdown";
import Checkbox from "../../components/common/Checkbox";
import Alert from "../../components/common/Alert";
import Modal from "../../components/common/DHModal";
import InboxService from "../../services/InboxService";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import Input from "../../components/common/Input";
import Toggle from "../../components/common/Toggle";
import Action from "../../components/common/Action";

import { CRM_INTEGRATIONS, CRM_INTEGRATION_UPDATE_MODAL_TABS } from "../../constants/CRMIntegration";

import AppConfig from "../../config/app/web-app.config";

const DEFAULT_INTEGRATION_SCHEDULES = [
	"0 6-20 * * 1-5", // Every hour, between 06:00 AM and 08:59 PM, Monday through Friday
	"0 0 * * * *", // Every hour
	"0 0 0 * * *", // At 12:00 AM
	"0 0 0 * * 0", //At 12:00 AM, only on Sunday
	"11 0-3,8-23 * * *"
];

class CRMUpdateModal extends Component {
	state = {
		enums: [],
		parentUpdate: true,
		name: "",
		crmType: "",
		crmVersion: "",
		integrationType: "",
		direction: "",
		syncType: "",
		runType: "",
		metadata: "",
		status: "",
		crmWorkflow: null,
		inboxes: [],
		selectedInbox: "all",
		disableSubmit: false,
		hasError: false,
		errorText: "",
		feed: CRM_INTEGRATIONS.feeds.sales,
		dealerId: "",
		salesCheck: false,
		serviceCheck: false,
		adfRecipientName: "",
		adfRecipientEmail: "",
		tenantId: "",
		branchCode: "",
		apiKey: "",

		selectedTab: CRM_INTEGRATION_UPDATE_MODAL_TABS.general.id,
		selectedScheduleIndex: 0,
		schedules: []
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	async componentDidUpdate(prevProps) {
		// If show updated, reset component
		if (prevProps.show !== this.props.show && this.props.show) {
			await this.resetComponent();
		}
	}

	async resetComponent() {
		let integration = null;

		if (this.props.id) {
			integration = await CrmIntegrationsService.fetchRestIntegration(this.props.id);
		}

		await this.update({
			name: integration && integration.name ? integration.name : "",
			crmType: integration && integration.crm_type ? integration.crm_type : "demandhub",
			crmVersion: integration && integration.crm_version ? integration.crm_version : "",
			integrationType: integration && integration.integration_type ? integration.integration_type : "sync_appointments",
			direction: integration && integration.direction ? integration.direction : "to_crm",
			syncType: integration && integration.sync_type ? integration.sync_type : "rest_api",
			runType: integration && integration.run_type ? integration.run_type : "interval",
			metadata: integration && integration.meta_data ? JSON.stringify(integration.meta_data) : "{}",
			status: integration && integration.status ? integration.status : "",
			schedules: integration && integration.schedules ? JSON.parse(JSON.stringify(integration.schedules)) : []
		});

		await this.fetchEnums();
		await this.massageData();

		let { crmType } = this.state;
		if (crmType === CRM_INTEGRATIONS.type.adfEmail.name) {
			await this.fetchADFWorkflow();
			await this.fetchInboxes();
		}
	}

	isViewOnly() {
		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();
		return !isSuperOrCs;
	}

	async massageData() {
		const { createMode } = this.props;
		let { crmType, metadata, dealerId } = this.state;

		try {
			if (createMode) {
				return;
			}
			const jsonMetadata = JSON.parse(metadata);

			// If dealerId is blank, check if we can get it from the meta data object
			if (!dealerId) {
				if (jsonMetadata.sales && jsonMetadata.sales.dealerId) {
					dealerId = jsonMetadata.sales.dealerId;
				} else if (jsonMetadata.service && jsonMetadata.service.dealerId) {
					dealerId = jsonMetadata.service.dealerId;
				} else if (jsonMetadata.customers && jsonMetadata.customers.dealerId) {
					dealerId = jsonMetadata.customers.dealerId;
				}
			}

			await this.update({
				dealerId
			});

			if (jsonMetadata && jsonMetadata.apiKey) {
				await this.update({ apiKey: jsonMetadata.apiKey });
			}

			if (jsonMetadata && jsonMetadata.tenantId) {
				await this.update({ tenantId: jsonMetadata.tenantId });
			}

			if (jsonMetadata && jsonMetadata.branchCode) {
				await this.update({ branchCode: jsonMetadata.branchCode });
			}

			if (crmType === CRM_INTEGRATIONS.type.cdk.name) {
				if (Object.keys(jsonMetadata).length === 0) {
					return;
				}
				let feed = CRM_INTEGRATIONS.feeds.sales;
				if (jsonMetadata.service) {
					feed = CRM_INTEGRATIONS.feeds.service;
				}
				if (jsonMetadata.customers) {
					feed = CRM_INTEGRATIONS.feeds.customers;
				}
				await this.update({
					feed
				});
			}
			if (crmType === CRM_INTEGRATIONS.type.dealervault.name || crmType === CRM_INTEGRATIONS.type.cdk180.name) {
				const salesCheck = jsonMetadata.feeds ? jsonMetadata.feeds.includes(CRM_INTEGRATIONS.feeds.sales) : false;
				await this.update({ salesCheck });
			}
			if (crmType === CRM_INTEGRATIONS.type.dealervault.name) {
				const serviceCheck = jsonMetadata.feeds ? jsonMetadata.feeds.includes(CRM_INTEGRATIONS.feeds.service) : false;
				await this.update({ serviceCheck });
			}
			if (crmType === CRM_INTEGRATIONS.type.adfEmail.name) {
				if (jsonMetadata && jsonMetadata.adf) {
					await this.update({
						adfRecipientName: jsonMetadata.adf.contact || "",
						adfRecipientEmail: jsonMetadata.adf.to_email || ""
					});
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	fetchADFWorkflow = async () => {
		let workflowConstant = await WorkflowService.getCachedWorkflowsConfig();

		let workflows = await WorkflowService.fetchWorkflows({
			locationId: UserService.getActiveLocation().id,
			status: ["active"],
			triggerTypes: [workflowConstant.triggers.incomingMessage],
			feature: workflowConstant.features.sendADFEmail
		});

		if (!workflows || workflows.length < 1) {
			return;
		}

		await this.update({ crmWorkflow: workflows[0] });
	};

	fetchInboxes = async () => {
		let inboxes = await InboxService.fetchInboxes(UserService.getActiveLocation().id, UserService.get().id);
		await this.update({ inboxes });

		let { crmWorkflow } = this.state;

		if (!crmWorkflow) {
			await this.update({ selectedInbox: "all" });
			return;
		}

		await WorkflowService.updateCachedWorkflowsConfig();
		let workflowConstant = await WorkflowService.getCachedWorkflowsConfig();

		let condition = crmWorkflow.conditions.find(c => c.type === workflowConstant.conditions.isInboxId);

		if (!condition) {
			return;
		}

		let selectedInbox = condition.params.id.toString();

		await this.update({ selectedInbox });
	};

	async handleOnSubmit() {
		const { crmType, crmVersion, integrationType, direction, status, name, selectedInbox, syncType, runType, schedules, metadata } = this.state;
		const { id, createMode, locationId } = this.props;

		if (!this.props.onSubmit) {
			return;
		}

		try {
			if (crmType === CRM_INTEGRATIONS.type.cdk180.name || crmType === CRM_INTEGRATIONS.type.cdk.name || crmType === CRM_INTEGRATIONS.type.dealervault.name) {
				if (!metadata || metadata === "{}") {
					metadata = await this.handleGenerateMetadata();
				}
			}

			if (createMode) {
				let created = await CrmIntegrationsService.create({
					locationId,
					name,
					crmType,
					crmVersion,
					integrationType,
					direction,
					syncType,
					metadata,
					inboxId: selectedInbox,
					runType
					// Was this never supposed to send schedules ??? maybe only needed on update?
				});

				this.props.onSubmit(created);
			} else {
				let response = await CrmIntegrationsService.update({
					id,
					name,
					crmType,
					crmVersion,
					integrationType,
					direction,
					syncType,
					metadata,
					runType,
					status,
					inboxId: selectedInbox,
					schedules
				});

				this.props.onSubmit(response);
			}
		} catch (error) {
			await this.update({ hasError: true, errorText: error.message });
		}
	}

	async fetchEnums() {
		try {
			let enums = await CrmIntegrationsService.fetchEnum();
			await this.update({ enums });
		} catch (error) {
			console.log(error);
		}
	}

	handleGenericEventHandler = async event => {
		await this.update({ [event.target.name]: event.target.value });
	};

	getCrmTypeOptions() {
		const { enums } = this.state;
		if (!enums || enums.length === 0 || !enums["crm_types"]) {
			return;
		}
		const crmType = enums.crm_types;
		const retVal = crmType.map((aCrmType, key) => (
			<option value={aCrmType} key={key}>
				{aCrmType}
			</option>
		));
		return retVal;
	}

	getIntegrationTypeOptions() {
		const { enums } = this.state;
		if (!enums || enums.length === 0 || !enums["integration_types"]) {
			return;
		}
		const integrationType = enums.integration_types;
		const retVal = integrationType.map((anIntegrationType, key) => (
			<option value={anIntegrationType} key={key}>
				{anIntegrationType}
			</option>
		));
		return retVal;
	}

	getSyncTypeOptions() {
		const { enums } = this.state;
		if (!enums || enums.length === 0 || !enums["sync_types"]) {
			return;
		}
		const syncTypes = enums.sync_types;
		const retVal = syncTypes.map((syncType, key) => (
			<option value={syncType} key={key}>
				{syncType}
			</option>
		));
		return retVal;
	}

	getRunTypeOptions() {
		const { enums } = this.state;
		if (!enums || enums.length === 0 || !enums["run_types"]) {
			return;
		}
		const runTypes = enums.run_types;
		const retVal = runTypes.map((runType, key) => (
			<option value={runType} key={key}>
				{runType}
			</option>
		));
		return retVal;
	}

	getDirectionOptions() {
		const { enums } = this.state;
		if (!enums || enums.length === 0 || !enums["direction"]) {
			return;
		}
		const direction = enums.direction;
		const retVal = direction.map((aDirection, key) => (
			<option value={aDirection} key={key}>
				{aDirection}
			</option>
		));
		return retVal;
	}

	getStatusOptions() {
		const { enums } = this.state;
		if (!enums || enums.length === 0 || !enums["status"]) {
			return;
		}
		const status = enums.status;
		const retVal = status.map((aStatus, key) => (
			<option value={aStatus} key={key}>
				{aStatus}
			</option>
		));
		return retVal;
	}

	handleInboxChange = async event => {
		await this.update({ selectedInbox: event.target.value });
	};

	getAdfEmailInboxOptions() {
		let { inboxes } = this.state;

		let options = inboxes.map((inbox, index) => (
			<option value={inbox.id} key={index}>
				{inbox.name}
			</option>
		));

		options.unshift(
			<option value="0" key="all">
				All
			</option>
		);
		return options;
	}

	handleCrmTypeOnChange = async event => {
		let newCRMType = event.target.value;
		await this.update({ crmType: newCRMType });
		if (newCRMType === CRM_INTEGRATIONS.type.cdk180.name) {
			await this.update({ metadata: JSON.stringify({ feeds: [CRM_INTEGRATIONS.feeds.sales] }) });
			return;
		}

		if (newCRMType === CRM_INTEGRATIONS.type.adfEmail.name) {
			await this.fetchADFWorkflow();
			await this.fetchInboxes();
			await this.update({ metadata: JSON.stringify({}) });
			return;
		}

		if (newCRMType === CRM_INTEGRATIONS.type.cdk.name || newCRMType === CRM_INTEGRATIONS.type.cdk_180_rest.name) {
			await this.update({ direction: "from_crm" });
		}

		await this.update({ metadata: JSON.stringify({}) });
		this.handleGenerateMetadata();
	};

	async handleCrmVersionOnChange(event) {
		await this.update({ crmVersion: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleDirectionOnChange(event) {
		await this.update({ direction: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleIntegrationTypeOnChange(event) {
		await this.update({ integrationType: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleSyncTypeOnChange(event) {
		await this.update({ syncType: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleRunTypeOnChange(event) {
		await this.update({ runType: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleStatusOnChange(event) {
		await this.update({ status: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleMetadataOnChange(event) {
		await this.update({ metadata: event.target.value });
	}

	async handleNameOnChange(event) {
		await this.update({ name: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleSalesFeedOnChange(event) {
		await this.update({ salesCheck: event.target.checked });
		this.handleGenerateMetadata();
	}

	async handleServiceFeedOnChange(event) {
		await this.update({ serviceCheck: event.target.checked });
		this.handleGenerateMetadata();
	}

	async handleDealerIdChange(event) {
		await this.update({ dealerId: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleFeedOnChange(event) {
		await this.update({ feed: event.target.value });
		this.handleGenerateMetadata();
	}

	async handleOnCloseError(event) {
		await this.update({ hasError: false, errorText: "" });
	}

	async handleGenerateMetadata() {
		try {
			let { crmType, metadata, apiKey, tenantId, branchCode } = this.state;

			let oldMetaData = null;
			try {
				oldMetaData = JSON.parse(metadata);
			} catch (error) {
				console.log(`Error: parsing meta data`);
			}

			if (crmType === CRM_INTEGRATIONS.type.cdk.name || crmType === CRM_INTEGRATIONS.type.cdk_180_rest.name) {
				let { feed, dealerId } = this.state;
				let newMetaData = {
					[feed]: { dealerId }
				};
				// If the old meta data had a query id, we will keep it
				if (oldMetaData && oldMetaData[feed] && oldMetaData[feed].queryId) {
					newMetaData[feed].queryId = oldMetaData[feed].queryId;
				}
				await this.update({ metadata: JSON.stringify(newMetaData) });
				return JSON.stringify(newMetaData);
			}

			let feeds = null;
			if ([CRM_INTEGRATIONS.type.dealervault.name, CRM_INTEGRATIONS.type.cdk180.name].includes(crmType)) {
				const { salesCheck } = this.state;
				feeds = [];
				if (salesCheck) {
					feeds.push(CRM_INTEGRATIONS.feeds.sales);
				}
			}
			if (CRM_INTEGRATIONS.type.dealervault.name === crmType) {
				const { serviceCheck } = this.state;
				if (!feeds) {
					feeds = [];
				}
				if (serviceCheck) {
					feeds.push(CRM_INTEGRATIONS.feeds.service);
				}
			}

			if (apiKey) {
				oldMetaData.apiKey = apiKey;
			}

			if (tenantId) {
				oldMetaData.tenantId = tenantId;
			}

			if (branchCode) {
				oldMetaData.branchCode = branchCode;
			}

			if (feeds) {
				if (!oldMetaData) {
					oldMetaData = {};
				}
				oldMetaData.feeds = feeds;
				const newMetaData = JSON.stringify(oldMetaData);
				await this.update({ metadata: newMetaData });
				return newMetaData;
			}

			await this.update({ metadata: JSON.stringify(oldMetaData) });
			return JSON.stringify(oldMetaData);
		} catch (error) {
			console.log(error);
		}
	}
	isGenerateMetadataVisible() {
		const { crmType } = this.state;
		return [CRM_INTEGRATIONS.type.cdk.name, CRM_INTEGRATIONS.type.dealervault.name].includes(crmType);
	}

	onAdfInputChange = event => {
		let { metadata, adfRecipientName, adfRecipientEmail } = this.state;

		let newMetaData = null;

		try {
			newMetaData = JSON.parse(metadata);
		} catch (error) {
			console.log(error);
			newMetaData = {};
		}

		if (!newMetaData.adf) {
			newMetaData.adf = {
				to_email: "",
				resources: [],
				contact: ""
			};
		}

		newMetaData.adf.resources = [];

		let field = event.target.name;

		if (field === "adfRecipientName") {
			newMetaData.adf.contact = event.target.value;
			newMetaData.adf.resources.push({
				name: event.target.value,
				email: adfRecipientEmail
			});
		} else if (field === "adfRecipientEmail") {
			newMetaData.adf.to_email = event.target.value;
			newMetaData.adf.resources.push({
				name: adfRecipientName,
				email: event.target.value
			});
		}

		this.setState({ metadata: JSON.stringify(newMetaData) });
		this.handleGenericEventHandler(event);
	};

	handleApiKeyOnChange = async event => {
		await this.handleGenericEventHandler(event);
		this.handleGenerateMetadata();
	};

	handleSiteIdOnChange = async event => {
		await this.handleGenericEventHandler(event);
		this.handleGenerateMetadata();
	};

	handleBranchCodeOnChange = async event => {
		await this.handleGenericEventHandler(event);
		this.handleGenerateMetadata();
	};

	renderADFEmailIntegrationFields = () => {
		const { crmType, integrationType, direction, adfRecipientName, adfRecipientEmail } = this.state;
		let { t } = this.props;

		if (crmType !== CRM_INTEGRATIONS.type.adfEmail.name || integrationType !== "chat_lead_generation" || direction !== "to_crm") {
			return null;
		}

		return (
			<>
				<div className="modal__field">{t("ADF Recipient Name")}</div>
				<input
					id="adf-recipient-name"
					name="adfRecipientName"
					type="text"
					onChange={this.onAdfInputChange}
					value={adfRecipientName || ""}
					className="form-control"
					autoComplete="off"
					disabled={this.isViewOnly()}
				/>

				<div className="modal__field">{t("ADF Recipient Email")}</div>
				<input
					id="adf-recipient-email"
					name="adfRecipientEmail"
					type="text"
					onChange={this.onAdfInputChange}
					value={adfRecipientEmail || ""}
					className="form-control"
					autoComplete="off"
					disabled={this.isViewOnly()}
				/>
			</>
		);
	};

	renderApiKeyField = () => {
		const { crmType, apiKey } = this.state;
		let { t } = this.props;

		if (crmType !== CRM_INTEGRATIONS.type.juvonno.name && crmType !== CRM_INTEGRATIONS.type.cliniko.name && crmType !== CRM_INTEGRATIONS.type.mindbody.name) {
			return null;
		}

		return (
			<>
				<div className="modal__field">{t("Api Key")}</div>
				<input
					id="api-key"
					name="apiKey"
					type="text"
					onChange={e => this.handleApiKeyOnChange(e)}
					value={apiKey}
					className="form-control"
					autoComplete="off"
					disabled={this.isViewOnly()}
				/>
			</>
		);
	};

	renderTenantId = () => {
		const { crmType, tenantId } = this.state;
		let { t } = this.props;

		if (crmType !== CRM_INTEGRATIONS.type.juvonno.name && crmType !== CRM_INTEGRATIONS.type.mindbody.name && crmType !== CRM_INTEGRATIONS.type.janeapp.name) {
			return null;
		}

		let tenantLabel = t("Tenant Id");
		if (crmType === CRM_INTEGRATIONS.type.mindbody.name) {
			tenantLabel = t("Site Id");
		}

		return (
			<>
				<div className="modal__field">{tenantLabel}</div>
				<input
					id="tenant-id"
					name="tenantId"
					type="text"
					onChange={e => this.handleSiteIdOnChange(e)}
					value={tenantId}
					className="form-control"
					autoComplete="off"
					disabled={this.isViewOnly()}
				/>
			</>
		);
	};

	renderJuvonnoFields = () => {
		const { crmType, branchCode } = this.state;
		let { t } = this.props;

		if (crmType !== CRM_INTEGRATIONS.type.juvonno.name) {
			return null;
		}

		return (
			<>
				<div className="modal__field">{t("Branch Code")}</div>
				<input
					id="branch-code"
					name="branchCode"
					type="text"
					onChange={e => this.handleBranchCodeOnChange(e)}
					value={branchCode}
					className="form-control"
					autoComplete="off"
					disabled={this.isViewOnly()}
				/>
			</>
		);
	};

	onTabSelected = tab => {
		this.setState({
			selectedTab: tab.id
		});
	};

	onAddSchedule = async () => {
		let { schedules } = this.state;

		schedules.push({
			days_ahead: 1,
			days_behind: 1,
			schedule: DEFAULT_INTEGRATION_SCHEDULES[0]
		});

		await this.update({
			schedules,
			selectedScheduleIndex: schedules.length - 1
		});
	};

	onSelectSchedule = async index => {
		await this.update({
			selectedScheduleIndex: index
		});
	};

	onScheduleExpressionSelected = async (index, expression) => {
		let { schedules } = this.state;

		schedules = schedules.slice();

		schedules[index].schedule = expression;

		await this.update({
			schedules
		});
	};

	onScheduleInputChange = async (index, event) => {
		let { schedules } = this.state;
		let { name, value } = event.target;

		schedules = schedules.slice();

		schedules[index][name] = value;

		await this.update({
			schedules
		});
	};

	onScheduleRemoved = async index => {
		let { schedules } = this.state;

		schedules = schedules.slice();

		schedules[index].status = "deleted";

		await this.update({
			schedules
		});
	};

	processCronExpression = expression => {
		let { t } = this.props;

		try {
			return cronstrue.toString(expression);
		} catch (error) {
			// Simply cannot process, and wait for valid cron
		}

		return t("(calculating ...)");
	};

	renderPotentialSchedules = index => {
		let { t } = this.props;

		return (
			<div className="cis__list__item__defaults">
				<div className="cis__list__item__defaults__title">{t("Preconfigured Schedules you can use for this Integration Schedule:")}</div>
				{DEFAULT_INTEGRATION_SCHEDULES.map(item => {
					let event = {
						target: {
							name: "schedule",
							value: item
						}
					};

					return (
						<div className="cis__list__item__defaults__item" onClick={() => this.onScheduleInputChange(index, event)}>
							{this.processCronExpression(item)}
						</div>
					);
				})}
			</div>
		);
	};

	onScheduleStatusToggle = (index, checked) => {
		let status = "inactive";

		if (checked) {
			status = "active";
		}

		this.onScheduleInputChange(index, { target: { name: "status", value: status } });
	};

	hasNonDeletedSchedules = () => {
		let { schedules } = this.state;

		return schedules.filter(s => s.status !== "deleted").length > 0;
	};

	renderSchedules = () => {
		let { schedules, selectedScheduleIndex } = this.state;
		let { t } = this.props;

		return (
			<div className="cis__list">
				{!this.hasNonDeletedSchedules() && (
					<div className="cis__list__empty">
						<img alt="No integration schedules" className="cis__list__empty__img" src={`${AppConfig.CDN_URL}web-app/assets/no-integration-schedules.svg`} />
						<div className="cis__list__empty__blurb">
							{t("You don't have any integration schedules setup. Add a new schedule to start collecting data from your integration.")}
						</div>
					</div>
				)}

				{schedules.map((schedule, index) => {
					if (schedule.status === "deleted") {
						return null;
					}

					let lastSyncedAt = schedule.last_synced_at ? moment(schedule.last_synced_at).format("YYYY-MM-DD hh:mm A") : null;

					return (
						<div className="cis__list__item" onClick={() => this.onSelectSchedule(index)}>
							<div className="cis__list__item__title">
								{selectedScheduleIndex !== index && <>{this.processCronExpression(schedule.schedule)}</>}
								{selectedScheduleIndex === index && (
									<Input
										label={
											<>
												{t("Schedule (Cron Expression)")}
												<div className="cis__list__item__title__last-synced">
													{lastSyncedAt && selectedScheduleIndex === index ? (
														<>
															{t("Last Synced At:")}
															{lastSyncedAt}
														</>
													) : null}
												</div>
											</>
										}
										name="schedule"
										id="schedule"
										type="text"
										onChange={event => this.onScheduleInputChange(index, event)}
										value={schedule.schedule}
										disabled={this.isViewOnly()}
									/>
								)}

								<div className="cis__list__item__title__spacer" />

								{schedule.status === "inactive" && <div className="cis__list__item__title__status">({t("Inactive")})</div>}

								{!this.isViewOnly() && (
									<Action
										className="cis__list__item__title__remove-action"
										key={`delete-${index}`}
										id={`delete-${index}`}
										label={t("Delete")}
										icon={Icon.Trash2}
										onClick={() => this.onScheduleRemoved(index)}
									/>
								)}
							</div>
							{lastSyncedAt && selectedScheduleIndex !== index && (
								<div className="cis__list__item__title__last-synced">
									{t("Last Synced At:")} {lastSyncedAt}
								</div>
							)}

							{selectedScheduleIndex === index && (
								<>
									<div className="cis__list__item__schedule-description cis__list__item__schedule-description--no-bottom-margin">
										{t("This integration schedule will attempt to retrieve data")} {this.processCronExpression(schedule.schedule)}
									</div>
									{this.renderPotentialSchedules(index)}
									<Input
										label={t("Days Ahead")}
										name="days_ahead"
										id="days_ahead"
										type="number"
										onChange={event => this.onScheduleInputChange(index, event)}
										value={schedule.days_ahead}
										disabled={this.isViewOnly()}
									/>
									<Input
										label={t("Days Behind")}
										name="days_behind"
										id="days_behind"
										type="number"
										onChange={event => this.onScheduleInputChange(index, event)}
										value={schedule.days_behind}
										disabled={this.isViewOnly()}
									/>
									<div className="cis__list__item__schedule-description">
										{t(
											"This integration schedule will only consider data created or updated in a {{windowSize}} day window looking ahead {{daysAhead}} day(s) and looking back {{daysBehind}} day(s).",
											{
												daysAhead: schedule.days_ahead,
												daysBehind: schedule.days_behind,
												windowSize: parseInt(schedule.days_ahead) + parseInt(schedule.days_behind)
											}
										)}
									</div>

									<Toggle
										id="is_one_time"
										label={t("One Time")}
										description={t("Only attempt to retrieve the data once for this schedule. Once this schedule has ran, it will deactivate itself.")}
										checked={schedule.is_one_time}
										onChange={() => this.onScheduleInputChange(index, { target: { name: "is_one_time", value: !schedule.is_one_time } })}
										disabled={this.isViewOnly()}
									/>
									<Toggle
										id="status"
										label={t("Scheduled Enabled")}
										description={t(
											"You can turn this CRM Integration Schedule on or off. One Time schedules will turn off automatically after running for the first time."
										)}
										checked={schedule.status === "active"}
										onChange={checked => this.onScheduleStatusToggle(index, checked)}
										disabled={this.isViewOnly()}
									/>
								</>
							)}
						</div>
					);
				})}

				<div className="cis__list__add">
					{!this.isViewOnly() && <Action key={`add-schedule`} id={`add-schedule`} label={t("Add")} icon={Icon.PlusCircle} onClick={this.onAddSchedule} />}
				</div>

				{this.renderSaveButton()}
			</div>
		);
	};

	renderSaveButton = () => {
		let { createMode } = this.props;
		let { t } = this.props;

		if (this.isViewOnly()) {
			return null;
		}

		return (
			<div className="modal__actions">
				<div id={createMode ? "create" : "update"} className="mb-button" onClick={e => this.handleOnSubmit(e)}>
					{createMode ? t("Create") : t("Update")}
				</div>
			</div>
		);
	};

	render() {
		const { id, show, onHide, createMode, t } = this.props;
		const {
			crmType,
			crmVersion,
			integrationType,
			direction,
			metadata,
			status,
			hasError,
			errorText,
			name,
			feed,
			selectedInbox,
			syncType,
			runType,
			selectedTab
		} = this.state;
		var dealerId = this.state.dealerId;

		return (
			<React.Fragment>
				<Modal show={show} onHide={() => onHide()} title={createMode ? t("Create CRM Integrations") : t("Update CRM Integrations")}>
					{(id || createMode) && (
						<div className="modal__flex-container">
							<Tabs onSelect={this.onTabSelected} selected={selectedTab}>
								<Tab id={CRM_INTEGRATION_UPDATE_MODAL_TABS.general.id} value={CRM_INTEGRATION_UPDATE_MODAL_TABS.general.display} />
								<Tab id={CRM_INTEGRATION_UPDATE_MODAL_TABS.schedules.id} value={CRM_INTEGRATION_UPDATE_MODAL_TABS.schedules.display} />
							</Tabs>

							{selectedTab === CRM_INTEGRATION_UPDATE_MODAL_TABS.schedules.id && this.renderSchedules()}

							{selectedTab === CRM_INTEGRATION_UPDATE_MODAL_TABS.general.id && (
								<>
									<div className="modal__field">{t("Name")}</div>
									<input
										id="crm-name"
										type="text"
										onChange={e => this.handleNameOnChange(e)}
										value={name || ""}
										className="form-control"
										autoComplete="off"
										disabled={this.isViewOnly()}
									/>

									<div className="modal__field">{t("CRM Type")}</div>
									<select
										id="crm-type"
										onChange={e => this.handleCrmTypeOnChange(e)}
										value={crmType || ""}
										className="form-control"
										disabled={this.isViewOnly()}
									>
										{this.getCrmTypeOptions()}
									</select>

									{crmType === CRM_INTEGRATIONS.type.dealervault.name && (
										<div className="modal__field">
											<div className="modal__field__row">
												<div className="modal__field__row__item modal__field" style={{ width: 90 }}>
													{t("Sales Feed")}
												</div>
												<div style={{ height: 20 }} className="modal__field__row__item">
													<Checkbox
														id="sales-check"
														name="sales-check"
														checked={this.state.salesCheck}
														onChange={e => this.handleSalesFeedOnChange(e)}
														disabled={this.isViewOnly()}
													/>
												</div>
											</div>
											<div className="modal__field__row">
												<div className="modal__field modal__field__row__item" style={{ width: 90 }}>
													{t("Service Feed")}
												</div>
												<div style={{ height: 20 }} className="modal__field__row__item">
													<Checkbox
														id="service-check"
														name="service-check"
														checked={this.state.serviceCheck}
														onChange={e => this.handleServiceFeedOnChange(e)}
														disabled={this.isViewOnly()}
													/>
												</div>
											</div>
										</div>
									)}

									{(crmType === CRM_INTEGRATIONS.type.cdk.name || crmType === CRM_INTEGRATIONS.type.cdk_180_rest.name) && (
										<>
											<div className="modal__field">{t("Dealer Id")}</div>
											<input
												id="crm-dealer-id"
												type="text"
												required
												onChange={e => this.handleDealerIdChange(e)}
												value={dealerId}
												className="form-control"
												autoComplete="off"
												disabled={this.isViewOnly()}
											/>

											<div className="modal__field">{t("Feed")}</div>
											<Dropdown
												id="crm-feed"
												value={feed || CRM_INTEGRATIONS.feeds.sales}
												cClass="form-control"
												data={CRM_INTEGRATIONS.type[crmType].feeds}
												onChange={e => this.handleFeedOnChange(e)}
											/>
										</>
									)}

									<div className="modal__field">{t("CRM Version")}</div>
									<input
										id="crm-version"
										type="text"
										onChange={e => this.handleCrmVersionOnChange(e)}
										value={crmVersion || ""}
										className="form-control"
										autoComplete="off"
										disabled={this.isViewOnly()}
									/>

									<div className="modal__field">{t("Integration Type")}</div>
									<select
										id="crm-integration-type"
										onChange={e => this.handleIntegrationTypeOnChange(e)}
										value={integrationType || ""}
										className="form-control"
										disabled={this.isViewOnly()}
									>
										{this.getIntegrationTypeOptions()}
									</select>

									<div className="modal__field">{t("Sync Type")}</div>
									<select
										id="crm-sync-type"
										onChange={e => this.handleSyncTypeOnChange(e)}
										value={syncType || ""}
										className="form-control"
										disabled={this.isViewOnly()}
									>
										{this.getSyncTypeOptions()}
									</select>

									<div className="modal__field">{t("Run Type")}</div>
									<select
										id="crm-run-type"
										onChange={e => this.handleRunTypeOnChange(e)}
										value={runType || ""}
										className="form-control"
										disabled={this.isViewOnly()}
									>
										{this.getRunTypeOptions()}
									</select>

									<div className="modal__field">{t("Direction")}</div>
									<select
										name="name"
										id="crm-direction"
										type="text"
										onChange={e => this.handleDirectionOnChange(e)}
										value={direction || ""}
										className="form-control"
										disabled={this.isViewOnly()}
									>
										{this.getDirectionOptions()}
									</select>

									{this.renderADFEmailIntegrationFields()}

									{this.renderApiKeyField()}
									{this.renderTenantId()}
									{this.renderJuvonnoFields()}

									<div className="modal__field">{t("Metadata Object")}</div>
									<textarea
										id="crm-metadata-object"
										style={{ width: "100%" }}
										rows="6"
										value={metadata}
										onChange={e => this.handleMetadataOnChange(e)}
										disabled={this.isViewOnly() || this.isGenerateMetadataVisible() || crmType === CRM_INTEGRATIONS.type.cdk180.name}
									/>

									{crmType === CRM_INTEGRATIONS.type.adfEmail.name && (
										<>
											<div className="modal__field">{t("Inbox")}</div>
											<select
												name="inbox"
												id="crm-inbox"
												type="text"
												onChange={this.handleInboxChange}
												value={selectedInbox || "all"}
												className="form-control"
												disabled={this.isViewOnly()}
											>
												{this.getAdfEmailInboxOptions()}
											</select>
										</>
									)}

									{!createMode && (
										<>
											<div className="modal__field">{t("Status")}</div>
											<select
												name="name"
												id="crm-status"
												type="text"
												onChange={e => this.handleStatusOnChange(e)}
												value={status || ""}
												className="form-control"
												disabled={this.isViewOnly()}
											>
												{this.getStatusOptions()}
											</select>
										</>
									)}

									{this.renderSaveButton()}
								</>
							)}
						</div>
					)}
				</Modal>
				<Alert type="error" show={hasError} title={t("Invalid JSON")} confirm="OK" onClose={e => this.handleOnCloseError(e)}>
					<div>{errorText}</div>
				</Alert>
			</React.Fragment>
		);
	}
}

export default withTranslation(null, { withRef: true })(CRMUpdateModal);

export const SM_COLUMNS = {
	content: {
		id: "content",
		value: "Content",
		sortable: true,
		sortField: "content",
		width: 2
	},
	contact: {
		id: "contact",
		value: "Contact",
		sortable: false,
		sortField: "contact",
		width: 2
	},
	recipients: {
		id: "recipients",
		value: "Recipients",
		sortable: true,
		sortField: "recipients",
		width: 1,
		tooltip: "Only contacts that have enabled receiving transactional messages will receive a message."
	},
	responses: {
		id: "responses",
		value: "Responses",
		sortable: true,
		sortField: "responses",
		width: 1
	},
	sender: {
		id: "sender",
		value: "Sender",
		sortable: true,
		sortField: "sender",
		width: 1
	},
	send_after: {
		id: "send_after",
		value: "Send At",
		sortable: true,
		sortField: "send_after",
		width: 2
	},
	created_at: {
		id: "created_at",
		value: "Date Created",
		sortable: true,
		sortField: "created_at",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 2,
		notClickable: true
	}
};

export const SM_STATES = {
	pending: "pending",
	sending: "sending",
	sent: "sent",
	error: "error"
};

export const SM_TYPES = {
	general: "general",
	nps: "nps",
	reviewInvite: "review_invite",
	reengagement: "reengagement"
};

export const SM_TYPES_FULL = {
	general: "Message",
	nps: "NPS Request",
	reviewInvite: "Review Invite",
	reengagement: "Reengagement"
};

export const SM_FILTERS_STATE = {
	pending: {
		id: "pending",
		value: "Pending",
		order: 1
	},
	sent: {
		id: "sent",
		value: "Sent",
		order: 2
	},
	cancelled: {
		id: "cancelled",
		value: "Cancelled",
		order: 3
	}
};

export const SM_TABS = {
	general: {
		id: "general",
		value: "General",
		route: "/scheduled-messages/general"
	},
	nps: {
		id: "nps",
		value: "NPS",
		route: "/scheduled-messages/nps"
	},
	reviewInvite: {
		id: "reviewInvite",
		value: "Review Invite",
		route: "/scheduled-messages/review-invite"
	},
	reengagement: {
		id: "reengagement",
		value: "Reengagement",
		route: "/scheduled-messages/reengagement"
	}
};

import React, { Component } from "react";
import TextHighlighter from "./TextHighlighter";
import Linkify from "./Linkify";

import UtilityService from "../../services/UtilityService";

import "../../styles/css/components/commons/text-bubble.css";
import "../../styles/css/common.css";

class TextBubble extends Component {
	constructor(props) {
		super(props);

		this.state = {
			backgroundColor: this.props.blue ? "#66afe9" : this.props.green ? "#1ab394" : this.props.backgroundColor ? this.props.backgroundColor : "#1ab394",
			highlight: this.props.highlight ? this.props.highlight : false,
			bubbleTailSide: this.props.bubbleTailLeft ? "left-top" : "right-top"
		};
	}

	renderContent = () => {
		let { text } = this.props;
		if (!text) {
			return null;
		}

		return text.split("\n").map((blurb, index) => {
			let key = index;

			if (blurb.length === 0) {
				return <br key={key} />;
			}

			let languageDirection = UtilityService.detectLanguageDirection(blurb);

			return (
				<p className="mb-message-content-blurb" dir={languageDirection} key={key}>
					<Linkify classNames={["mb-message-content-link--out"]}>{blurb}</Linkify>
				</p>
			);
		});
	};

	render() {
		let { backgroundColor, highlight, bubbleTailSide } = this.state;
		let { text, highlightRegex } = this.props;
		return (
			<div className="talk-bubble" style={{ backgroundColor: backgroundColor }}>
				<div className={`tri-right ${bubbleTailSide}`} style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
				<div className="talktext">{highlight ? <TextHighlighter text={text} highlightRegex={highlightRegex} /> : this.renderContent()}</div>
			</div>
		);
	}
}

export default TextBubble;

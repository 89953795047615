let listeners = [];
let interval = undefined;

/** Main interval timer */
const CHECK_INTERVALS = 1000 * 60 * 1;

const PollingService = {
	/**
	 * Initialize interval in the top component after login
	 */
	initialize() {
		interval = setInterval(() => runListeners(), CHECK_INTERVALS);
	},

	/**
	 * add new listener
	 * @param {String} key
	 * @param {function} expression callback called at interval
	 * @param {number} milliseconds > 1000 * 60 * 1 milliseconds
	 */
	addListener(key, expression = () => {}, milliseconds = 1000 * 60 * 2) {
		listeners.push({
			key,
			expression,
			time: milliseconds,
			elapsedTime: milliseconds
		});

		runExpression(key, expression);
	},
	/**
	 * Filter interval by key
	 * @param {String} key
	 */
	removeListener(key) {
		listeners = listeners.filter(aListener => aListener.key !== key);
	},

	/**
	 * Clear all intervals
	 */
	clearAllListeners() {
		if (interval) {
			console.log("Clearing all intervals...");
			clearInterval(interval);
		}
	}
};

/**
 * Runs all the functions in listeners object
 */
const runListeners = () => {
	listeners.forEach(aListener => {
		if (aListener.elapsedTime > CHECK_INTERVALS) {
			aListener.elapsedTime -= CHECK_INTERVALS;
			return;
		}
		runExpression(aListener.key, aListener.expression);
		aListener.elapsedTime = aListener.time;
	});
};
/**
 *
 * @param {string} key name/key of the interval
 * @param {function} expression function to be run
 */
const runExpression = (key, expression) => {
	try {
		console.log(`Executing ${key} interval`);
		expression();
	} catch (error) {
		console.log(error);
	}
};

export default PollingService;

/**
 * Abstracts any functionality related to CSV parsing
 */
import * as csvtojsonV2 from "csvtojson";

const CSVParsingService = {
	/**
	 * Parse string of text (representing CSV file) and return corresponding
	 * 2D array of values present in said CSV file
	 * @param {String} str
	 * @returns {Array[Array[String]]}
	 */
	parseCSV(str) {
		var arr = [];
		var quote = false; // true means we're inside a quoted field

		// iterate over each character, keep track of current row and column (of the returned array)
		for (var row = 0, col = 0, c = 0; c < str.length; c++) {
			var cc = str[c],
				nc = str[c + 1]; // current character, next character
			arr[row] = arr[row] || []; // create a new row if necessary
			arr[row][col] = arr[row][col] || ""; // create a new column (start with empty string) if necessary

			// If the current character is a quotation mark, and we're inside a
			// quoted field, and the next character is also a quotation mark,
			// add a quotation mark to the current column and skip the next character
			if (cc === '"' && quote && nc === '"') {
				arr[row][col] += cc;
				++c;
				continue;
			}

			// If it's just one quotation mark, begin/end quoted field
			if (cc === '"') {
				quote = !quote;
				continue;
			}

			// If it's a comma and we're not in a quoted field, move on to the next column
			if (cc === "," && !quote) {
				++col;
				continue;
			}

			// If it's a newline (CRLF) and we're not in a quoted field, skip the next character
			// and move on to the next row and move to column 0 of that new row
			if (cc === "\r" && nc === "\n" && !quote) {
				++row;
				col = 0;
				++c;
				continue;
			}

			// If it's a newline (LF or CR) and we're not in a quoted field,
			// move on to the next row and move to column 0 of that new row
			if (cc === "\n" && !quote) {
				++row;
				col = 0;
				continue;
			}
			if (cc === "\r" && !quote) {
				++row;
				col = 0;
				continue;
			}

			// Otherwise, append the current character to the current column
			arr[row][col] += cc;
		}
		return arr;
	},

	/**
	 * Parse a csv string and return an Object
	 * @param {Object} params CSVtoJson parsing parameters
	 * @param {String} csvString The string that we want to parse
	 * @returns {Array} Returns an array of the parsed data
	 */
	async parseCSVString(params, csvString) {
		try {
			let csvData = await csvtojsonV2(params).fromString(csvString);

			let data = this.removeEmptyData(csvData, params.output);

			return data;
		} catch (error) {
			console.log(error.stack);
			throw error;
		}
	},

	/**
	 * Preview a csv string by parsing X amount of the first lines
	 * @param {Object} params CSVtoJson parsing parameters
	 * @param {String} csvString The string that we want to parse
	 * @param {*} csvHasHeader if the csv string has a header
	 * @param {*} maxLines The max amount of lines to preview
	 */
	async parseCsvStringPreview(params, csvString, csvHasHeader, maxLines) {
		try {
			maxLines = csvHasHeader ? maxLines + 1 : maxLines;
			let numLines = 0;
			let csvPreviewString = "";
			await csvtojsonV2(params)
				.fromString(csvString)
				.subscribe(csvLine => {
					csvPreviewString += `${csvLine}\n`;
					numLines++;
					if (numLines === maxLines - 1) {
						throw Error("Don't parse more");
					}
				});
			params.noheader = true;
			let csvData = await this.parseCSVString(params, csvPreviewString);
			return csvData;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Remove Object properties or Array Objects that have empty data
	 * @param {Object/Array} data Data representing csv data
	 * @param {String} type
	 */
	async removeEmptyData(data, type = "json") {
		try {
			let newData = [];
			// If all the columns are empty (have no data). Then ignore that row

			for (const row of data) {
				let emptyRow = false;
				// If each row is stored in as an array
				if (type === "csv") {
					emptyRow = this.isArrayEmpty(row);
				} else {
					// If the data is stored as an object
					emptyRow = this.isObjectEmpty(row);
				}
				if (emptyRow) {
					continue;
				}
				newData.push(row);
			}
			return newData;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Checks if an array only has "empty" values
	 * @param {Array} array
	 * @returns {Boolean} True if all of it's properties are empty string, undefined, or null
	 */
	isArrayEmpty(array) {
		try {
			let isEmpty = true;
			for (const item of array) {
				// If there is something in the item, then it is not empty
				if (item || item !== "") {
					isEmpty = false;
					break;
				}
			}
			return isEmpty;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Checks if an object only has "empty" values
	 * @param {Object} obj
	 * @returns {Boolean} True if all of it's properties are empty string, undefined, or null
	 */
	isObjectEmpty(obj) {
		try {
			let isEmpty = true;
			for (const key in obj) {
				const value = obj[key];
				// If there is something in a property, then it is not empty
				if (value || value !== "") {
					isEmpty = false;
					break;
				}
			}
			return isEmpty;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Turns an array to a csv string
	 * @param {Array} array
	 * @returns {String}
	 */
	dataToCsvString(array) {
		// Iterate through every row and every value in each row, escape the value
		return array.map(row => row.map(value => this.escapeCsvValue(value)).join(",")).join("\n");
	},

	/**
	 * Escape values in a csv
	 * @param {String} value
	 * @returns {String}
	 */
	escapeCsvValue(value) {
		if (typeof value === "string") {
			if (value.includes('"')) {
				value = value.replace(/"/g, '""');
			}
			if (value.includes(",") || value.includes("\n")) {
				value = `"${value}"`;
			}
		}
		return value;
	}
};

export default CSVParsingService;

import React from "react";
import { Star } from "lucide-react";

class StarSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rating: this.props.defaultRating
		};
	}

	handleStarClick = starRating => {
		if (this.props.handleStarClick) {
			this.props.handleStarClick(starRating);
		}
		this.setState({ rating: starRating });
	};

	componentDidUpdate(prevProps) {
		if (prevProps.defaultRating !== this.props.defaultRating) {
			this.setState({ rating: this.props.defaultRating });
		}
	}

	render() {
		const { maxRating, size = 20 } = this.props;
		const { rating } = this.state;

		const stars = Array.from({ length: maxRating }, (_, i) => ({
			rating: i + 1,
			full: i < rating
		}));

		return (
			<div className="review-stars">
				{stars.map(star => (
					<Star
						key={star.rating}
						data-rating={star.rating}
						color="#ffdc57"
						fill={star.full ? "#ffdc57" : "none"}
						stroke="#ffdc57"
						width={size}
						height={size}
						onClick={() => this.handleStarClick(star.rating)}
						style={{ cursor: "pointer" }}
					/>
				))}
			</div>
		);
	}
}

export default StarSelection;

export const PAGE_PERMISSIONS = [
	"business_management",
	"email",
	"pages_messaging",
	"pages_show_list",
	"pages_manage_ads",
	"pages_manage_metadata",
	"pages_read_engagement",
	"pages_read_user_content",
	"pages_manage_posts",
	"pages_manage_engagement",
	"instagram_basic",
	"instagram_manage_messages"
];

export const LOGIN_RESPONSE_STATUS = {
	authorized: "authorized",
	notAuthorized: "not_authorized",
	unknown: "unknown"
};

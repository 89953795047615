export const API_KEY_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 1
	},
	username: {
		id: "username",
		value: "Username",
		sortable: true,
		sortField: "username",
		width: 1
	},
	token: {
		id: "token",
		value: "Token",
		sortable: true,
		sortField: "token",
		width: 1
	},
	status: {
		id: "status",
		value: "Status",
		sortable: true,
		sortField: "status",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

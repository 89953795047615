import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import posed, { PoseGroup } from "react-pose";
import "../../styles/css/components/commons/dh-dropdown.css";

export const ContextMenu = posed.div({
	enter: {
		y: 10,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 0,
		x: 0,
		opacity: 0,
		transition: {
			duration: 200
		}
	}
});
class DHDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showList: false
		};

		this.triggerContainer = React.createRef();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		document.addEventListener("mousedown", this.onMouseDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			await this.update({
				showList: show
			});
		}
	}

	onMouseDown = async e => {
		let { showList } = this.state;

		// If something within the context menu list is clicked, then simply return and let the parent dismiss the context menu
		if (this.dropdown && this.dropdown.contains && this.dropdown.contains(e.target)) {
			return;
		}

		// If the trigger is clicked and the context menu is hidden then show it
		if (this.triggerContainer && this.triggerContainer.contains && this.triggerContainer.contains(e.target) && !showList) {
			await this.update({
				showList: true
			});

			// Notify the parent that we are now showing the dropdown
			if (this.props.onChange) {
				this.props.onChange({ show: true });
			}
		}
		// If any other possible click happens and a reference to the dropdown exists, then dismiss it
		else if (this.dropdown && this.dropdown.contains) {
			await this.update({
				showList: false
			});

			// Notify the parent that we have hid the dropdown
			if (this.props.onChange) {
				this.props.onChange({ show: false });
			}
		}
	};

	render() {
		let { trigger, triggerClassName, options, optionsClassName, offset } = this.props;
		let { showList } = this.state;

		return (
			<>
				<div ref={ref => (this.triggerContainer = ref)} className={triggerClassName}>
					{trigger}
				</div>
				<PoseGroup>
					{showList && (
						<ContextMenu style={offset} key="container" ref={ref => (this.dropdown = ref)} className={[optionsClassName, "dropdown__options"].join(" ")}>
							{options}
						</ContextMenu>
					)}
				</PoseGroup>
			</>
		);
	}
}

export default withRouter(DHDropdown);

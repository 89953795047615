import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { CRM_CONNECTION_STATUS, CRM_INTEGRATIONS } from "../../constants/CRMIntegration";

import "../../styles/css/scenes/connections.css";

class ConnectionsCard extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	getStatusClass = status => {
		if (status === CRM_CONNECTION_STATUS.connected.id) {
			return "connections__item__status--ok";
		} else if (status === CRM_CONNECTION_STATUS.notConnected.id) {
			return "connections__item__status--bad";
		} else if (status === CRM_CONNECTION_STATUS.partiallyConnected.id) {
			return "connections__item__status--partial";
		}

		return "";
	};

	render() {
		let { t } = this.props;
		let { crmIntegrationId, crmIntegrationData, title, logoUrl, description, status, actionText, onClick } = this.props;

		let hasStatus = typeof status !== "undefined" && status !== null;
		let statusClass = this.getStatusClass(status);

		return (
			<div className="connections__section-list">
				<div className="connections__section-list-item">
					<div className="connections__section-list-item__icon">
						<img src={logoUrl} alt="Connected Apps" />
					</div>
					<div className="connections__section-list-item__information">
						<div className="connections__section-list-item__information_title">{title}</div>
						<div className="connections__section-list-item__information_description">{description}</div>
					</div>
					<div className="connections__section-list-item__spacer" />
					<div className="connections__section-list-item__status_indicator">
						{hasStatus && (
							<>
								<div
									className={`connections__section-list-item__status_indicator_circle ${statusClass}`}
									data-tip
									data-for={`connection-card-tooltip-${crmIntegrationId}`}
								/>
								<ReactTooltip
									id={`connection-card-tooltip-${crmIntegrationId}`}
									className="mb-react-tooltip"
									arrowColor="#333"
									type="info"
									effect="solid"
									place="bottom"
								>
									{status ? CRM_CONNECTION_STATUS[status].label : CRM_CONNECTION_STATUS.notConnected.label}
									{status === CRM_CONNECTION_STATUS.partiallyConnected.id ? ". Pending Appointments to be synced." : null}
									{crmIntegrationData && crmIntegrationData.sync_type === CRM_INTEGRATIONS.syncType.syncApp ? " (Sync App)" : null}
								</ReactTooltip>
							</>
						)}
					</div>
					<div className="connections__section-list-item__action">
						{onClick && (
							<div className="mb-button mb-button--fit" onClick={onClick}>
								{actionText}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ConnectionsCard);

import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import Select from "react-select";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";

import WidgetConfigService from "../../../services/WidgetConfigService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import LocationService from "../../../services/LocationService";
import MessagesService from "../../../services/MessagesService";
import UtilityService from "../../../services/UtilityService";

import TextArea from "../../../components/common/TextArea";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import Toggle from "../../../components/common/Toggle";
import UnsavedChanges from "../../../components/common/UnsavedChanges";

import { STATUS, STATUS_LABEL } from "../../../constants/CommonConstants";
import { MODE, MEDIUM } from "../../../constants/Messenger";
import AppConfig from "../../../config/app/web-app.config";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/micro-site-config.css";

class MicroSiteConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			widgetId: null,
			widgetConfig: {},
			about: "",
			minStarRating: 4,
			mediaId: null,
			showBanner: true,
			bannerImage: "",
			status: { value: STATUS.active, label: STATUS_LABEL.active },
			statusOptions: Object.keys(STATUS).map(s => {
				return { value: STATUS[s], label: STATUS_LABEL[s] };
			}),
			bookings: false,
			trackingId: "",

			loading: false,
			saving: false,
			changesMade: false
		};
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = () => {
		this.resetComponent();
	};

	async resetComponent() {
		try {
			let location = UserService.getActiveLocation();

			await this.update({ loading: true });

			let widget = await WidgetConfigService.findWidget({ locationId: location.id, name: "micro_site" });

			if (!widget) {
				await this.update({
					widgetId: null,
					widgetConfig: {},
					about: "",
					minStarRating: 4,
					bannerImage: "",
					showBanner: true,
					mediaId: null,
					bookings: false,
					status: { value: STATUS.active, label: STATUS_LABEL.active },
					statusOptions: Object.keys(STATUS).map(s => {
						return { value: STATUS[s], label: STATUS_LABEL[s] };
					}),
					trackingId: "",

					loading: false,
					changesMade: false
				});
				return;
			}

			let config = widget.config;

			let bannerImage = "";
			let mediaId = null;
			if (config && config.media && config.media.banner_image) {
				mediaId = config.media.banner_image.id;
				let media = await MessagesService.getMedia({ publicId: mediaId });
				bannerImage = UtilityService.appendQueryAuthToken(media.download_url, UserService.getActiveLocation().id, UserService.getAuthToken());
			}

			await this.update({
				loading: false,
				widgetId: widget.id,
				widgetConfig: config,
				status: widget.status ? { value: STATUS[widget.status], label: STATUS_LABEL[widget.status] } : { value: STATUS.active, label: STATUS_LABEL.active },
				about: config && config.about ? config.about : "",
				bookings: config.bookings,
				bannerImage: bannerImage,
				mediaId,
				minStarRating: config && config.minStarsThreshold ? config.minStarsThreshold : 4,
				showBanner: config && typeof config.showBanner !== "undefined" ? config.showBanner : true,
				trackingId: config && config.googleAnalytics && config.googleAnalytics.trackingId ? config.googleAnalytics.trackingId : ""
			});
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	handleSaveClick = async () => {
		let { widgetId, widgetConfig, status, about, mediaId, minStarRating, showBanner, bookings, trackingId, changesMade } = this.state;

		if (!this.isSaveable()) {
			return;
		}

		await this.update({ saving: true });

		let locationId = UserService.getActiveLocation().id;
		let companyId = UserService.getActiveCompany().id;

		let uploadedMedia = null;
		if (changesMade && mediaId) {
			let localMedia = MessagesService.getLocalMedia(mediaId);

			if (localMedia) {
				uploadedMedia = await MessagesService.uploadLocalMedia({
					mediaIds: [localMedia.id],
					mode: MODE.customer,
					medium: MEDIUM.sms.key,
					limit: 1,
					companyId,
					locationId
				});

				if (!uploadedMedia) {
					ToastService.error("Error uploading media.");
					await this.update({ saving: false });
					return;
				}

				MessagesService.clearLocalMedia();
				uploadedMedia = uploadedMedia[0];
			}
		}

		let config = {
			about,
			showBanner,
			bookings
		};

		config = Object.assign(widgetConfig, config);

		if (!config.googleAnalytics) {
			config.googleAnalytics = {};
		}

		config.googleAnalytics.trackingId = trackingId;

		if (minStarRating && parseInt(minStarRating) > 0) {
			config.minStarsThreshold = parseInt(minStarRating);
		}

		if (uploadedMedia) {
			config.media = {};
			config.media.banner_image = {
				id: uploadedMedia.public_id
			};
		}

		let success = await WidgetConfigService.updateWidget({ widgetId, status: status.value, config: JSON.stringify(config) });

		await this.update({ mediaId: null, saving: false, changesMade: false });

		if (!success) {
			ToastService.error(`An error occurred trying to save changes. Please try again.`);
			return;
		}

		ToastService.info(`Saved changed!`);
	};

	isSaving = () => {
		return this.state.saving;
	};

	isSaveable = () => {
		return this.state.changesMade && !this.isSaving();
	};

	handleGenericEventHandler = async event => {
		await this.update({ [event.target.name]: event.target.value, changesMade: true });
	};

	handleStatusChange = async newValue => {
		await this.update({ status: newValue, changesMade: true });
	};

	handleBannerImageChange = async event => {
		await this.handleGenericEventHandler(event);
	};

	onChangeBannerImage = event => {
		let localMedia = MessagesService.storeLocalMedia({ file: event.target.files[0] });
		this.update({ bannerImage: localMedia.download_url, mediaId: localMedia.id, changesMade: true });
	};

	renderLoading = () => {
		return (
			<div>
				<ContentLoader height={671} width={"430"}>
					{/* The tabs */}
					<rect x="30" y="0" rx="5" ry="5" width="400" height="20" />

					{/* First row */}
					<rect x="30" y="40" rx="5" ry="5" width="400" height="155" />
					<rect x="30" y="215" rx="5" ry="5" width="400" height="60" />
					<rect x="30" y="295" rx="5" ry="5" width="400" height="200" />
					<rect x="30" y="515" rx="5" ry="5" width="400" height="63" />
					<rect x="370" y="598" rx="5" ry="5" width="60" height="35" />
				</ContentLoader>
			</div>
		);
	};

	render() {
		let { widgetId, loading, about, minStarRating, showBanner, bannerImage, status, statusOptions, bookings, trackingId, saving, changesMade } = this.state;
		let location = UserService.getActiveLocation();

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		if (!LocationService.isMicroSitePermissible()) {
			return <Redirect to="/settings" />;
		}

		if (loading) {
			return this.renderLoading();
		}

		if (!widgetId) {
			return (
				<div className="micro-site-config Common__flex-grow">
					Micro Site Widget not configured. Please contact {AppConfig.DEMANDHUB.SUPPORT.EMAIL} for help.
				</div>
			);
		}

		return (
			<div className="micro-site-config Common__flex-grow">
				<div className="micro-site-config__visit-site">
					<a target="_blank" href={`${AppConfig.CORPORATE_URL}sites/${location.handle}`} rel="noopener noreferrer">
						Visit Micro Site <Icon.ExternalLink className="micro-site-config__visit-site__icon" />
					</a>
				</div>
				<div className="dh-details-item">
					<label>About</label>
					{allowUpdate ? (
						<TextArea
							name="about"
							id="about"
							type="text"
							height={130}
							rows={4}
							style={{ resize: "none" }}
							value={about}
							showFeedbackFaces={false}
							showFeedbackLength={false}
							showVariables={false}
							showMessageTemplates={false}
							blueBorder={true}
							placeholder="About the business..."
							disabled={this.isSaving() || !allowUpdate}
							onChange={e => this.handleGenericEventHandler(e)}
						/>
					) : (
						<div>{about}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Minimum Star Rating Threshold</label>
					{allowUpdate ? (
						<input
							name="minStarRating"
							id="minStarRating"
							onChange={e => this.handleGenericEventHandler(e)}
							type="number"
							value={minStarRating}
							className="form-control Common__input"
							disabled={this.isSaving() || !allowUpdate}
						/>
					) : (
						<div>{minStarRating}</div>
					)}
				</div>
				<Toggle
					id="show-banner"
					label="Show Banner Image"
					checked={showBanner}
					description="Show the banner image."
					onChange={() => this.update({ showBanner: !showBanner, changesMade: true })}
					disabled={this.isSaving() || !allowUpdate}
				/>

				{LocationService.isBookingsPermissible() && (
					<Toggle
						id="show-booking"
						label="Enable Booking"
						checked={bookings}
						description="Enable the booking widget and book now button."
						onChange={() => this.update({ bookings: !bookings, changesMade: true })}
						disabled={this.isSaving() || !allowUpdate}
					/>
				)}

				{showBanner && (
					<React.Fragment>
						<div className="micro-site-config__banner-image dh-details-item">
							<label className="micro-site-config__banner-image__text">
								Banner Image URL <small>(600 x 1024 pixels recommended)</small>
							</label>
							{allowUpdate && (
								<div className="micro-site-config__banner-image__btn" htmlFor="image-upload">
									<Action id="upload" label="Upload Image" icon={bannerImage === "" ? Icon.Upload : Icon.Edit2} onClick={() => this.bannerImageInput.click()} />
								</div>
							)}
							<input
								ref={ref => (this.bannerImageInput = ref)}
								name="bannerImage"
								id="image-upload"
								onChange={e => this.onChangeBannerImage(e)}
								type="file"
								accept="image/*"
								className="micro-site-config__banner-image__input"
								disabled={this.isSaving() || !allowUpdate}
							/>
						</div>

						{bannerImage && bannerImage.length > 0 && <img className="micro-site-config__banner-image" alt="Banner" src={bannerImage} />}
					</React.Fragment>
				)}

				<div className="dh-details-item">
					<label>Status</label>
					<Select
						id="status"
						name="status"
						isDisabled={this.isSaving() || !allowUpdate}
						value={status}
						onChange={newValue => this.handleStatusChange(newValue)}
						options={statusOptions}
						placeholder="Select a status"
						clearable={false}
					/>
				</div>

				<div className="dh-details-item">
					<label>Google Analytics Tracking Id</label>
					<input
						maxLength={65}
						name="trackingId"
						id="trackingId"
						onChange={e => this.handleGenericEventHandler(e)}
						value={trackingId}
						className="Common__input"
						disabled={!allowUpdate}
						placeholder="UA-000000-0"
					/>
				</div>

				{changesMade && allowUpdate && (
					<div className="dh-details__save">
						<div id="save" className={`mb-button ${this.isSaveable() ? "" : "mb-button--disabled"}`} onClick={this.handleSaveClick}>
							{saving ? "Saving..." : "Save"}
						</div>
					</div>
				)}

				{changesMade && <UnsavedChanges />}
			</div>
		);
	}
}

export default withRouter(withLocation(MicroSiteConfig));

import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import ReactSwitch from "react-switch";
import * as Icon from "react-feather";
import posed, { PoseGroup } from "react-pose";
import ContentLoader from "react-content-loader";
import Select from "react-select";
import { MessageSquareText, Smartphone } from "lucide-react";

import { WIDGET_NAME, WIDGET_CONFIG, WEBCHAT_POSITIONS, DEFAULT_WEBCHAT_POSITION } from "../../../constants/WidgetConstants";
import { BUBBLE_ICONS, CODES_TO_LABEL } from "../../../constants/LanguageConstants";

import TooltipImage from "./TooltipImage";
import DHSelect from "../../../components/common/DHSelect";
import withLocation from "../../../components/common/WithLocation";
import UnsavedChanges from "../../../components/common/UnsavedChanges";
import SketchPicker from "../../../components/common/ReactColorPicker/Sketch";
import { ShowcaseList, ShowcaseRow } from "../../../components/common/ShowcaseList";
import EditCustomPositioningModal from "./EditCustomPositioningModal";
import WebchatPreview from "./WebchatPreview";
import LanguageSelectorMini from "../../../components/common/LanguageSelectorMini";

import WidgetConfigService from "../../../services/WidgetConfigService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import LocationService from "../../../services/LocationService";
import TranslationService from "../../../services/TranslationService";
import UtilityService from "../../../services/UtilityService";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/web-chat-config.css";

const DEFAULT = "default";

export const Field = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 0,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

class WebchatConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// Widget Data
			isActive: false,

			// Web Chat Config Keys
			forceFullName: false,
			placeholders: {},
			chatHead: true,
			tooltip: true,
			tooltipMobile: true,
			tooltipGreeting: {},
			tooltipBold: false,
			tooltipForeground: "#404040",
			tooltipBackground: "#ffffff",
			tooltipImage: "",
			tooltipAlwaysShow: false,
			showPhoneConfirmation: false,
			googleAnalytics: {},
			trackingId: "",
			promptBookingWidget: false,
			zIndex: null,
			zIndexImportant: false,
			hideOnDevice: false,
			recaptcha: false,
			minRecaptchaScore: 0.7,

			bubble: {},

			positioning: {}, // the entire positioning object from the widget config
			position: "", // where the widget should be position on the page (bottom-left, ...)
			devicePosition: WIDGET_CONFIG.positioningModes[0], // The screen size, desktop or mobile
			offset: { top: 0, bottom: 0, left: 0, right: 0 }, // The custom offset for the currently selecte device and position
			selectedPositionUrl: WIDGET_CONFIG.offsetModes.default.value, // Custom positioning by url
			showEditCustomUrlModal: false, // To show the add custom url modal

			languageSectionSupportedOptions: [],
			languageSectionTooltipGreeting: "en_CA",
			languageSectionPlaceholders: "en_CA",
			languageSectionBubbleText: "en_CA",

			// Regular state keys

			showModal: false,
			changesMade: false,
			locationId: null,
			widgetId: null,
			loading: true
		};

		this.tooltipBackgroundColourPicker = null;
		this.tooltipForegroundColourPicker = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async () => {
		await this.update({ loading: true });
		await this.resetComponent();
		await this.update({ loading: false });
	};

	async componentDidMount() {
		await this.update({ loading: true });
		await this.resetComponent();
		await this.update({ loading: false });
	}

	componentWillUnmount() {}

	async resetComponent() {
		let location = UserService.getActiveLocation();

		let widget = await WidgetConfigService.findWidget({
			locationId: location.id,
			name: WIDGET_NAME.webchat
		});

		let {
			forceFullName,
			placeholders,
			chatHead,
			tooltip,
			tooltipMobile,
			tooltipGreeting,
			tooltipBold,
			tooltipForeground,
			tooltipBackground,
			tooltipImage,
			tooltipAlwaysShow,
			showPhoneConfirmation,
			googleAnalytics,
			positioning,
			promptBookingWidget,
			zIndex,
			zIndexImportant,
			bubble,
			recaptcha,
			minRecaptchaScore
		} = widget.config;
		let { devicePosition, selectedPositionUrl } = this.state;

		let newPosition = WEBCHAT_POSITIONS.find(p => p.value === positioning[devicePosition.value][selectedPositionUrl].position);
		let offset = positioning[devicePosition.value][selectedPositionUrl].offset;
		let hideOnDevice = positioning[devicePosition.value][selectedPositionUrl].hide;

		// Set language related options before the config
		// So we know how to read the config correctly
		await this.setLanguageSelectionOptions();

		await this.update({
			isActive: widget.status === "active",

			// Web Chat Config Keys
			forceFullName: typeof forceFullName === "boolean" ? forceFullName : false,
			placeholders: placeholders || WidgetConfigService.getDefaultWebchatPlaceholders(),
			chatHead: typeof chatHead === "boolean" ? chatHead : true,
			tooltip: typeof tooltip === "boolean" ? tooltip : true,
			tooltipMobile: typeof tooltipMobile === "boolean" ? tooltipMobile : true,
			tooltipGreeting: tooltipGreeting || WidgetConfigService.getDefaultTooltipGreeting(),
			tooltipBold: typeof tooltipBold === "boolean" ? tooltipBold : false,
			tooltipForeground: tooltipForeground || WidgetConfigService.getDefaultTooltipForeground(),
			tooltipBackground: tooltipBackground || WidgetConfigService.getDefaultTooltipBackground(),
			tooltipImage: tooltipImage || WidgetConfigService.getDefaultTooltipImageUrl(),
			tooltipAlwaysShow: tooltipAlwaysShow || WidgetConfigService.getDefaultTooltipAlwaysShow(),
			showPhoneConfirmation: showPhoneConfirmation || WidgetConfigService.getDefaultShowPhoneConfirmation(),
			trackingId: googleAnalytics && googleAnalytics.trackingId ? googleAnalytics.trackingId : "",
			googleAnalytics: googleAnalytics ? googleAnalytics : {},
			promptBookingWidget: typeof promptBookingWidget === "boolean" ? promptBookingWidget : false,
			zIndex: zIndex ? zIndex : null,
			zIndexImportant: typeof zIndexImportant === "boolean" ? zIndexImportant : false,
			hideOnDevice: typeof hideOnDevice === "boolean" ? hideOnDevice : false,
			bubble: bubble ? bubble : {},
			recaptcha: typeof recaptcha === "boolean" ? recaptcha : false,
			minRecaptchaScore: typeof minRecaptchaScore !== "undefined" ? parseFloat(minRecaptchaScore) : 0.7,

			positioning,
			position: typeof newPosition !== "undefined" ? newPosition : DEFAULT_WEBCHAT_POSITION,
			offset: offset || WidgetConfigService.getDefaultWebchatOffset(),
			showEditCustomUrlModal: false,

			// Regular state keys
			locationId: location.id,
			widgetId: widget.id,
			showModal: false,
			changesMade: false
		});
	}

	onSave = async () => {
		let {
			isActive,

			// Web Chat Config Keys
			forceFullName,
			placeholders,
			chatHead,
			tooltip,
			tooltipMobile,
			tooltipGreeting,
			tooltipBold,
			tooltipForeground,
			tooltipBackground,
			tooltipImage,
			tooltipAlwaysShow,
			showPhoneConfirmation,
			googleAnalytics,
			trackingId,
			promptBookingWidget,
			zIndex,
			zIndexImportant,
			bubble,

			recaptcha,
			minRecaptchaScore,

			positioning,

			// Regular state keys
			widgetId
		} = this.state;

		if (!googleAnalytics) {
			googleAnalytics = {};
		}

		Object.assign(googleAnalytics, { trackingId });

		let config = {
			forceFullName,
			placeholders,
			chatHead,
			tooltip,
			tooltipMobile,
			tooltipGreeting,
			tooltipBold,
			tooltipForeground,
			tooltipBackground,
			tooltipImage,
			tooltipAlwaysShow,
			showPhoneConfirmation,
			positioning,
			googleAnalytics,
			promptBookingWidget,
			zIndexImportant,
			bubble,
			recaptcha,
			minRecaptchaScore: parseFloat(minRecaptchaScore)
		};

		if (parseInt(zIndex)) {
			config.zIndex = parseInt(zIndex);
		}

		let newStatus = isActive ? "active" : "inactive";

		try {
			await WidgetConfigService.updateWidget({ widgetId, config, status: newStatus });

			await this.resetComponent();

			ToastService.info("Web Chat has been updated!");
		} catch (error) {
			console.log(error);
			ToastService.error("Error updating the config!");
		}
	};

	setLanguageSelectionOptions = async () => {
		let locationLanguage = TranslationService.getLocationLanguage();
		let supportedLocationOptions = TranslationService.getSupportedLanguagesOptions();

		await this.update({
			languageSectionSupportedOptions: supportedLocationOptions,
			languageSectionTooltipGreeting: locationLanguage,
			languageSectionPlaceholders: locationLanguage,
			languageSectionBubbleText: locationLanguage
		});
	};

	onCancel = () => {
		this.resetComponent();
	};

	onOffsetChange = (field, value) => {
		let { offset, selectedPositionUrl, devicePosition, positioning } = this.state;

		Object.assign(offset, { [field]: value });

		positioning[devicePosition.value][selectedPositionUrl].offset[field] = value;

		this.update({
			offset,
			positioning,
			changesMade: true
		});
	};

	onPlaceholderChange = (field, value) => {
		let { placeholders, languageSectionPlaceholders } = this.state;

		let newPlaceholders = JSON.parse(JSON.stringify(placeholders));
		newPlaceholders[languageSectionPlaceholders][field] = value;

		this.update({
			placeholders: newPlaceholders,
			changesMade: true
		});
	};

	onChange = (field, checked) => {
		this.update({
			[field]: checked,
			changesMade: true
		});
	};

	onToolTipGreetingChange = newValue => {
		let { tooltipGreeting, languageSectionTooltipGreeting } = this.state;

		let newToolTipGreeting = JSON.parse(JSON.stringify(tooltipGreeting));
		newToolTipGreeting[languageSectionTooltipGreeting] = newValue;

		this.update({
			tooltipGreeting: newToolTipGreeting,
			changesMade: true
		});
	};

	onDevicePositionChange = option => {
		let { positioning } = this.state;

		let offset = positioning[option.value][DEFAULT].offset;
		let positionValue = positioning[option.value][DEFAULT].position;
		let position = WEBCHAT_POSITIONS.find(p => p.value === positionValue);
		let hideOnDevice = positioning[option.value][DEFAULT].hide;

		this.update({ position, offset, devicePosition: option, selectedPositionUrl: DEFAULT, hideOnDevice });
	};

	onToggleHideWidget = (field, value) => {
		let { positioning, devicePosition, selectedPositionUrl, hideOnDevice } = this.state;

		positioning[devicePosition.value][selectedPositionUrl].hide = value;
		hideOnDevice = value;

		this.update({
			hideOnDevice,
			positioning,
			changesMade: true
		});
	};

	onPositionChange = position => {
		let { positioning, devicePosition, selectedPositionUrl } = this.state;

		let newPosition = WEBCHAT_POSITIONS.find(item => item.value === position.value);

		positioning[devicePosition.value][selectedPositionUrl].position = position.value;

		this.update({
			position: newPosition,
			positioning,
			changesMade: true
		});
	};

	onUpdateImage = url => {
		this.update({ tooltipImage: url, changesMade: true });
	};

	invalidToolTip = () => {
		let { tooltipGreeting, languageSectionTooltipGreeting } = this.state;

		let toolTipText = tooltipGreeting[languageSectionTooltipGreeting];

		// If the length of the tooltip is less than 10 characters or does not have a space
		return !/\S/.test(toolTipText) || toolTipText.length < 10;
	};

	Selector = ({ id, value, options, onChange }) => {
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return <Select id={id} onChange={onChange} value={value} isClearable={false} options={options} isDisabled={!allowUpdate} />;
	};

	Switch = ({ field, checked, onChange }) => {
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return (
			<ReactSwitch
				id={field}
				height={22}
				width={38}
				checked={checked}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor="#60A9FF"
				offColor="#c5c5c5"
				onChange={newValue => {
					onChange(field, newValue);
				}}
				disabled={!allowUpdate}
			/>
		);
	};

	onShowCustomPositioningModal = () => {
		this.update({ showEditCustomUrlModal: true, selectedPositionUrl: DEFAULT });
	};

	onAddCustomPositioningUrl = (url, oldUrl) => {
		let { positioning, devicePosition, selectedPositionUrl } = this.state;

		let position = WEBCHAT_POSITIONS.find(p => p.value === positioning[devicePosition.value][DEFAULT].position);
		let offset = positioning[devicePosition.value][DEFAULT].offset;
		positioning[devicePosition.value][url] = {
			position: position.value,
			hide: false,
			offset: {
				top: 0,
				right: 0,
				bottom: 0,
				left: 0
			}
		};

		// If this positioning url is being modified, keep the same positioning as before
		if (oldUrl === selectedPositionUrl && positioning[devicePosition.value][selectedPositionUrl] && selectedPositionUrl !== DEFAULT) {
			positioning[devicePosition.value][url] = positioning[devicePosition.value][selectedPositionUrl];
			position = WEBCHAT_POSITIONS.find(p => p.value === positioning[devicePosition.value][url].position);
			offset = positioning[devicePosition.value][url].offset;
		}

		if (oldUrl !== DEFAULT) {
			delete positioning[devicePosition.value][oldUrl];
		}

		this.update({ positioning, position, offset, showEditCustomUrlModal: false, changesMade: true, selectedPositionUrl: url });
	};

	onEditCustomPositioning = (index, url) => {
		this.update({ selectedPositionUrl: url });
		this.onShowCustomPositioningModal();
	};

	onDeleteCustomPositioning = (index, event, url) => {
		event.preventDefault();
		event.stopPropagation();

		// Can't delete defualt
		if (url && url.toLowerCase() === DEFAULT) {
			return;
		}

		let { positioning, devicePosition } = this.state;

		if (!positioning[devicePosition.value][url]) {
			return;
		}

		delete positioning[devicePosition.value][url];

		let position = WEBCHAT_POSITIONS.find(p => p.value === positioning[devicePosition.value][DEFAULT].position);
		let offset = positioning[devicePosition.value][DEFAULT].offset;

		this.update({
			positioning,
			position: typeof position !== "undefined" ? position : DEFAULT_WEBCHAT_POSITION,
			offset: offset || WidgetConfigService.getDefaultWebchatOffset(),
			changesMade: true,
			selectedPositionUrl: DEFAULT
		});
	};

	onCustomPositioningSelected = (index, url) => {
		let { offset, position, devicePosition, positioning, hideOnDevice } = this.state;

		offset = positioning[devicePosition.value][url].offset;
		position = positioning[devicePosition.value][url].position;
		position = WEBCHAT_POSITIONS.find(p => p.value === position);
		hideOnDevice = positioning[devicePosition.value][url].hide;

		this.update({
			positioning,
			offset,
			position,
			hideOnDevice,
			selectedPositionUrl: url
		});
	};

	onBubbleIconSelected = bubbleIconId => {
		let { bubble } = this.state;

		let newBubble = JSON.parse(JSON.stringify(bubble));
		newBubble.icon = bubbleIconId;
		this.update({ bubble: newBubble, changesMade: true });
	};

	renderContentLoader() {
		let renderField = multiplier => {
			let offset = multiplier * 120;

			return (
				<>
					<rect x="30" y={20 + offset} rx="0" ry="0" width="150" height="10" />
					<rect x="30" y={40 + offset} rx="0" ry="0" width="350" height="10" />
					<rect x="500" y={20 + offset} rx="0" ry="0" width="40" height="20" />
					<rect x="30" y={100 + offset} rx="0" ry="0" width="550" height="2" />
				</>
			);
		};

		return (
			<ContentLoader speed={2} width={1000} height={460} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
				{renderField(0)}
				{renderField(1)}
				{renderField(2)}
				{renderField(3)}
				{renderField(4)}
				<rect x="600" y="20" rx="0" ry="0" width="300" height="250" />
			</ContentLoader>
		);
	}

	renderPreview() {
		const {
			position,
			chatHead,
			tooltip,
			tooltipMobile,
			tooltipGreeting,
			tooltipImage,

			tooltipBackground,
			tooltipForeground,
			tooltipBold,

			languageSectionTooltipGreeting,

			// Bubble
			bubble,
			languageSectionBubbleText,

			// Regular state keys
			changesMade
		} = this.state;

		let hideSave = this.invalidToolTip() || !changesMade;

		return (
			<div className="wcc__preview">
				<WebchatPreview
					position={position}
					/* Tooltip */
					chatHead={chatHead}
					showTooltip={tooltip}
					tooltipGreeting={tooltipGreeting}
					tooltipImage={tooltipImage}
					tooltipBackground={tooltipBackground}
					tooltipForeground={tooltipForeground}
					tooltipBold={tooltipBold}
					languageSectionTooltipGreeting={languageSectionTooltipGreeting}
					/* Bubble */
					bubble={bubble}
					languageSectionBubbleText={languageSectionBubbleText}
				/>
				<PoseGroup>
					{!hideSave && (
						<Field key="updateChanges" className="wcc__preview__update">
							<div className="mb-button" onClick={this.onSave}>
								Save
							</div>
							<div className="mb-button mb-button--cancel" onClick={this.onCancel}>
								Cancel
							</div>
						</Field>
					)}
				</PoseGroup>
			</div>
		);
	}

	renderBubbleFields() {
		const {
			bubble,

			// Language selectors
			languageSectionSupportedOptions,
			languageSectionBubbleText
		} = this.state;

		let user = UserService.get();

		let allowUpdate = user.GroupPermission.update_widgets;

		// Bubble settings
		let showBubbleText = bubble.showText;
		let bubbleText = bubble.text;
		let bubbleIcon = bubble.icon;
		let bubbleBackgroundColor = bubble.backgroundColor;

		let showLanguageSelectors = languageSectionSupportedOptions.length > 1;

		return (
			<Field key="bubble" className="wcc__options__field wcc__options__field--large-heading">
				<div className="rwc__options__field__label rwc__options__field__label--center">
					<div className="rwc__options__field__label__title rwc__options__field__label__title--large">Bubble Options</div>
					<div className="rwc__options__field__label__description">Options related to the bubble.</div>
				</div>
				<div className="rwc__options__field__label">
					<div className="rwc__options__field__label__title">Icon</div>
				</div>
				<div className="rwc__options__field__select">
					<div className="rwc__options__field__bubble_icons_container">
						<div
							className={`rwc__options__field__bubble_icon ${bubbleIcon === BUBBLE_ICONS.text ? "rwc__options__field__bubble_icon--active" : ""}`}
							onClick={() => this.onBubbleIconSelected(BUBBLE_ICONS.text)}
						>
							<MessageSquareText color="#000000" width={25} height={25} />
						</div>
						<div
							className={`rwc__options__field__bubble_icon ${bubbleIcon === BUBBLE_ICONS.phone ? "rwc__options__field__bubble_icon--active" : ""}`}
							onClick={() => this.onBubbleIconSelected(BUBBLE_ICONS.phone)}
						>
							<Smartphone color="#000000" width={25} height={25} />
						</div>
					</div>
				</div>
				<Field key="showBubbleText" className="wcc__options__field">
					<div className="wcc__options__field__label">
						<div className="wcc__options__field__label__title">Show Text</div>
						<div className="wcc__options__field__label__description">To show or hide the bubble text.</div>
					</div>

					<this.Switch
						field="showBubbleText"
						checked={showBubbleText}
						onChange={(field, checked) => {
							let newBubble = JSON.parse(JSON.stringify(bubble));
							newBubble.showText = checked;
							this.update({ bubble: newBubble, changesMade: true });
						}}
					/>
				</Field>
				{showBubbleText && (
					<Field key="bubbleText" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">
								Bubble Text
								{showLanguageSelectors && (
									<div className="wcc__options__field__label__title__language_container">
										<LanguageSelectorMini
											language={languageSectionBubbleText}
											languageOptions={languageSectionSupportedOptions}
											onChange={newLanguage => {
												this.update({ languageSectionBubbleText: newLanguage });
											}}
										/>
									</div>
								)}
							</div>
							<div className="wcc__options__field__label__description">Change the bubble text.</div>
						</div>
						<div className="wcc__options__field__input">
							<input
								maxLength={65}
								name="bubbleText"
								id="bubbleText"
								onChange={e => {
									let newValue = e.target.value;
									let newBubble = JSON.parse(JSON.stringify(bubble));
									newBubble.text[languageSectionBubbleText] = newValue;
									this.update({ bubble: newBubble, changesMade: true });
								}}
								value={bubbleText[languageSectionBubbleText]}
								className="Common__input"
								disabled={!allowUpdate}
							/>
						</div>
					</Field>
				)}
				<Field key="bubbleBackgroundColor" className="wcc__options__field">
					<div className="wcc__options__field__label">
						<div className="wcc__options__field__label__title">Background Color</div>
						<div className="wcc__options__field__label__description">Note: The text will automatically adjust based on the contrast.</div>
					</div>

					<SketchPicker
						id="bubbleBackgroundColor"
						value={bubbleBackgroundColor}
						handleChange={color => {
							let newBubble = JSON.parse(JSON.stringify(bubble));

							if (UtilityService.shouldTextBeWhite(color)) {
								newBubble.textColor = "#FFFFFF";
							} else {
								newBubble.textColor = "#000000";
							}

							newBubble.backgroundColor = color;
							this.update({ bubble: newBubble, changesMade: true });
						}}
						disableAlpha={true}
					/>
				</Field>
			</Field>
		);
	}

	renderTooltipFields() {
		const {
			// Web Chat Config Keys
			chatHead,
			tooltip,
			tooltipMobile,
			tooltipGreeting,
			tooltipBold,
			tooltipForeground,
			tooltipBackground,
			tooltipImage,
			tooltipAlwaysShow,

			// Language selectors
			languageSectionSupportedOptions,
			languageSectionTooltipGreeting
		} = this.state;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		let showLanguageSelectors = languageSectionSupportedOptions.length > 1;

		let showTooltipOptions = tooltip || tooltipMobile;

		return (
			<Field key="tooltips" className="wcc__options__field wcc__options__field--large-heading">
				<div className="rwc__options__field__label rwc__options__field__label--center">
					<div className="rwc__options__field__label__title rwc__options__field__label__title--large">Tooltip Options</div>
					<div className="rwc__options__field__label__description">Options related to the popup above the bubble.</div>
				</div>

				<Field key="tooltip" className="wcc__options__field">
					<div className="wcc__options__field__label">
						<div className="wcc__options__field__label__title">Show Tooltip</div>
						<div className="wcc__options__field__label__description">Show or hide the entire tooltip message bubble.</div>
					</div>

					<this.Switch field="tooltip" checked={tooltip} onChange={this.onChange} />
				</Field>

				<Field key="tooltipMobile" className="wcc__options__field">
					<div className="wcc__options__field__label">
						<div className="wcc__options__field__label__title">Show Tooltip on Mobile</div>
						<div className="wcc__options__field__label__description">Show or hide the entire tooltip message bubble on mobile.</div>
					</div>

					<this.Switch field="tooltipMobile" checked={tooltipMobile} onChange={this.onChange} />
				</Field>

				{showTooltipOptions && (
					<Field key="chatHead" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">Show Chat Head</div>
							<div className="wcc__options__field__label__description">Show or hide the Avatar Image that lives inside of the tooltip.</div>
						</div>

						<this.Switch field="chatHead" checked={chatHead} onChange={this.onChange} />
					</Field>
				)}

				{showTooltipOptions && chatHead && (
					<Field key="tooltipImage" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">Chat Head Image</div>
						</div>
						<div className="wcc__options__field__avatar">
							<img className="wcc__options__field__avatar__image" src={tooltipImage} alt="webchat avatar" />
							{allowUpdate && (
								<div id="fnctst-change-webchat-head-image" className="mb-button mb-button--align-center" onClick={() => this.update({ showModal: true })}>
									<Icon.Edit2 size="14" /> Edit Image
								</div>
							)}
						</div>
					</Field>
				)}

				{showTooltipOptions && (
					<Field key="tooltipGreeting" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">
								Tooltip
								{showLanguageSelectors && (
									<div className="wcc__options__field__label__title__language_container">
										<LanguageSelectorMini
											language={languageSectionTooltipGreeting}
											languageOptions={languageSectionSupportedOptions}
											onChange={newLanguage => {
												this.update({ languageSectionTooltipGreeting: newLanguage });
											}}
										/>
									</div>
								)}
							</div>
							<div className="wcc__options__field__label__description">Add a greeting to your Web Chat tooltip to better capture website visitors.</div>
						</div>
						<div className="wcc__options__field__input">
							<input
								maxLength={65}
								name="tooltipGreeting"
								id="tooltipGreeting"
								onChange={e => this.onToolTipGreetingChange(e.target.value)}
								value={tooltipGreeting[languageSectionTooltipGreeting]}
								className="Common__input"
								disabled={!allowUpdate}
							/>
							<div className={`wcc__options__field__input__tooltip-length ${this.invalidToolTip() ? "wcc__options__field__input__tooltip-length--red" : ""}`}>
								{tooltipGreeting[languageSectionTooltipGreeting].length} / 65
							</div>
						</div>
					</Field>
				)}

				{showTooltipOptions && (
					<Field key="tooltipBold" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">Tooltip Bold</div>
							<div className="wcc__options__field__label__description">If the tooltip text should be bolded.</div>
						</div>

						<this.Switch field="tooltipBold" checked={tooltipBold} onChange={this.onChange} />
					</Field>
				)}

				{showTooltipOptions && (
					<Field key="tooltipForeground" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">Tooltip Foreground</div>
							<div className="wcc__options__field__label__description">Foreground text color</div>
						</div>

						<SketchPicker
							id="tooltipForeground"
							value={tooltipForeground}
							handleChange={color => {
								this.onChange("tooltipForeground", color);
							}}
							disableAlpha={true}
						/>
					</Field>
				)}

				{showTooltipOptions && (
					<Field key="tooltipBackground" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">Tooltip Background</div>
							<div className="wcc__options__field__label__description">Background color</div>
						</div>

						<SketchPicker
							id="tooltipForeground"
							value={tooltipBackground}
							handleChange={color => {
								this.onChange("tooltipBackground", color);
							}}
							disableAlpha={true}
						/>
					</Field>
				)}

				{showTooltipOptions && (
					<Field key="tooltipAlwaysShow" className="wcc__options__field">
						<div className="wcc__options__field__label">
							<div className="wcc__options__field__label__title">Always Show Tooltip</div>
							<div className="wcc__options__field__label__description">
								Whether the tooltip should always be shown every time the page is refreshed, even if it has already been closed once.
							</div>
						</div>

						<this.Switch field="tooltipAlwaysShow" checked={tooltipAlwaysShow} onChange={this.onChange} />
					</Field>
				)}
			</Field>
		);
	}

	renderPositioningFields() {
		const {
			// Web Chat Config Keys
			position,
			positioning,
			devicePosition,
			offset,
			selectedPositionUrl,
			showEditCustomUrlModal,
			hideOnDevice
		} = this.state;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return (
			<Field key="positioning" className="wcc__options__field wcc__options__field--large-heading">
				<div className="rwc__options__field__label rwc__options__field__label--center">
					<div className="rwc__options__field__label__title rwc__options__field__label__title--large">Widget Positioning</div>
					<div className="rwc__options__field__label__description">Positioning on desktop, mobile, and on custom web pages.</div>
				</div>
				<div className="rwc__options__field__label">
					<div className="rwc__options__field__label__title">Device</div>
				</div>
				<div className="rwc__options__field__select">
					<this.Selector id="device-position" value={devicePosition} options={WIDGET_CONFIG.positioningModes} onChange={this.onDevicePositionChange} />
				</div>

				<ShowcaseList
					key="customPositioning"
					title="Positioning on Web Pages"
					label="Url"
					onAddClicked={this.onShowCustomPositioningModal}
					onEditClicked={this.onEditCustomPositioning}
					onDeleteClicked={this.onDeleteCustomPositioning}
					onItemClicked={this.onCustomPositioningSelected}
					allowUpdate={allowUpdate}
					orderable={false}
					noBorder={true}
				>
					{Object.keys(positioning[devicePosition.value]).map((url, index) => {
						return (
							<ShowcaseRow
								rowIndex={index}
								key={url}
								title={url}
								titleToTitleCase={false}
								disableTitles={[WIDGET_CONFIG.offsetModes.default.value]}
								selectedTitle={selectedPositionUrl}
								subtitle={""}
								description={index === 0 ? "The default positioning for all webpages." : ""}
								footer={""}
							/>
						);
					})}
				</ShowcaseList>

				<EditCustomPositioningModal
					show={showEditCustomUrlModal}
					url={selectedPositionUrl === DEFAULT ? "" : selectedPositionUrl}
					onHide={() => this.update({ showEditCustomUrlModal: false })}
					onSave={this.onAddCustomPositioningUrl}
				/>

				<Field key="hideOnDevice" className="wcc__options__field wcc__options__field--no-border">
					<div className="wcc__options__field__label">
						<div className="wcc__options__field__label__title">
							Hide On {WIDGET_CONFIG.positioningModes[0].value === devicePosition.value ? "Desktop" : "Mobile"}
						</div>
						<div className="wcc__options__field__label__description">
							Stop showing the widget on {WIDGET_CONFIG.positioningModes[0].value === devicePosition.value ? "desktop" : "mobile"}.
						</div>
					</div>

					<this.Switch field="hideOnDevice" checked={hideOnDevice} onChange={this.onToggleHideWidget} />
				</Field>

				<Field key="position" className="wcc__options__field wcc__options__field--small wcc__options__field--no-border">
					<div className="wcc__options__field__label">
						<div className="wcc__options__field__label__title">Position</div>
						<div className="wcc__options__field__label__description">Adjust the position of the Web Chat.</div>
					</div>
					<div className="wcc__options__field__input">
						<DHSelect
							label=""
							id="position"
							name="position"
							options={WEBCHAT_POSITIONS}
							value={position}
							onChange={this.onPositionChange}
							required={true}
							disabled={!allowUpdate}
						/>
					</div>
				</Field>
				<Field key="offset" className="wcc__options__field wcc__options__field--large wcc__options__field--no-border">
					<div className="wcc__options__field__label">
						<div className="wcc__options__field__label__title">Offset</div>
						<div className="wcc__options__field__label__description">Adjust the offset of the Web Chat to give more padding from the position.</div>
					</div>
					<div className="wcc__options__field__input">
						<div className="wcc__options__field__input__label">Top</div>
						<input
							maxLength={65}
							name="top"
							id="topPosition"
							onChange={e => this.onOffsetChange("top", e.target.value)}
							value={offset.top}
							className="Common__input"
							disabled={!allowUpdate}
						/>

						<div className="wcc__options__field__input__label">Right</div>
						<input
							maxLength={65}
							name="right"
							id="rightPosition"
							onChange={e => this.onOffsetChange("right", e.target.value)}
							value={offset.right}
							className="Common__input"
							disabled={!allowUpdate}
						/>

						<div className="wcc__options__field__input__label">Bottom</div>
						<input
							maxLength={65}
							name="bottom"
							id="bottomPosition"
							onChange={e => this.onOffsetChange("bottom", e.target.value)}
							value={offset.bottom}
							className="Common__input"
							disabled={!allowUpdate}
						/>

						<div className="wcc__options__field__input__label">Left</div>
						<input
							maxLength={65}
							name="left"
							id="leftPosition"
							onChange={e => this.onOffsetChange("left", e.target.value)}
							value={offset.left}
							className="Common__input"
							disabled={!allowUpdate}
						/>
					</div>
				</Field>
			</Field>
		);
	}

	render() {
		const {
			// Status indicator for webchat
			isActive,

			// Web Chat Config Keys
			forceFullName,
			placeholders,
			tooltipImage,
			showPhoneConfirmation,
			googleAnalytics,
			trackingId,
			promptBookingWidget,
			zIndex,
			zIndexImportant,
			recaptcha,
			minRecaptchaScore,

			// Language selectors
			languageSectionSupportedOptions,
			languageSectionPlaceholders,

			// Regular state keys
			showModal,
			changesMade,
			locationId,
			loading
		} = this.state;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		if (!LocationService.isWebchatPermissible()) {
			return <Redirect to="/settings" />;
		}

		if (loading) {
			return this.renderContentLoader();
		}

		let showLanguageSelectors = languageSectionSupportedOptions.length > 1;

		return (
			<div className="wcc">
				<div className="wcc__options">
					<PoseGroup>
						{isSuperOrCs && (
							<Field key="isActive" className="wcc__options__field">
								<div className="wcc__options__field__label">
									<div className="wcc__options__field__label__title">Web Chat</div>
									<div className="wcc__options__field__label__description">
										Enable Web Chat to allow your website visitors to chat directly with your texting line.
									</div>
								</div>

								<this.Switch field="isActive" checked={isActive} onChange={this.onChange} />
							</Field>
						)}

						<Field key="forceFullName" className="wcc__options__field">
							<div className="wcc__options__field__label">
								<div className="wcc__options__field__label__title">Request Full Name</div>
								<div className="wcc__options__field__label__description">Enable two separate fields on the Web Chat for First Name and Last Name.</div>
							</div>

							<this.Switch field="forceFullName" checked={forceFullName} onChange={this.onChange} />
						</Field>

						<Field key="showPhoneConfirmation" className="wcc__options__field">
							<div className="wcc__options__field__label">
								<div className="wcc__options__field__label__title">Require Phone Confirmation</div>
								<div className="wcc__options__field__label__description">Add a Phone Confirmation field in the webchat form.</div>
							</div>

							<this.Switch field="showPhoneConfirmation" checked={showPhoneConfirmation} onChange={this.onChange} />
						</Field>

						<Field key="promptBookingWidget" className="wcc__options__field">
							<div className="wcc__options__field__label">
								<div className="wcc__options__field__label__title">Prompt Booking Widget</div>
								<div className="wcc__options__field__label__description">Users can choose between sending a message and making a booking.</div>
							</div>

							<this.Switch field="promptBookingWidget" checked={promptBookingWidget} onChange={this.onChange} />
						</Field>

						{UserService.isSuperAdminOrCustomerSuccess() && (
							<Field key="recaptcha" className="wcc__options__field">
								<div className="wcc__options__field__label">
									<div className="wcc__options__field__label__title">Recaptcha</div>
									<div className="wcc__options__field__label__description">
										Enable invisible recaptcha to determine the trustworthiness of users filling out webchat.
									</div>
								</div>

								<this.Switch field="recaptcha" checked={recaptcha} onChange={this.onChange} />
							</Field>
						)}
						{UserService.isSuperAdminOrCustomerSuccess() && recaptcha && (
							<Field key="minRecaptchaScore" className="wcc__options__field">
								<div className="wcc__options__field__label">
									<div className="wcc__options__field__label__title">Minimum Recaptcha Score</div>
									<div className="wcc__options__field__label__description">Recaptcha score from 0-1.</div>
								</div>
								<div className="wcc__options__field__input">
									<input
										maxLength={1}
										type="number"
										name="minRecaptchaScore"
										id="minRecaptchaScore"
										onChange={e => this.onChange("minRecaptchaScore", e.target.value)}
										value={minRecaptchaScore}
										className="Common__input"
										disabled={!allowUpdate}
										placeholder="0.7"
									/>
								</div>
							</Field>
						)}

						{googleAnalytics && (
							<Field key="trackingId" className="wcc__options__field">
								<div className="wcc__options__field__label">
									<div className="wcc__options__field__label__title">Tracking Id</div>
									<div className="wcc__options__field__label__description">Add your Google Analytics tracking Id to see widget analytics.</div>
								</div>
								<div className="wcc__options__field__input">
									<input
										maxLength={65}
										name="trackingId"
										id="trackingId"
										onChange={e => this.onChange("trackingId", e.target.value)}
										value={trackingId}
										className="Common__input"
										disabled={!allowUpdate}
										placeholder="UA-000000-0"
									/>
								</div>
							</Field>
						)}

						<Field key="zIndex" className="wcc__options__field">
							<div className="wcc__options__field__label">
								<div className="wcc__options__field__label__title">Z Index</div>
								<div className="wcc__options__field__label__description">The Z Index positioning value.</div>
							</div>
							<div className="wcc__options__field__input">
								<input
									maxLength={15}
									type="number"
									name="zIndex"
									id="zIndex"
									onChange={e => this.onChange("zIndex", e.target.value)}
									value={zIndex}
									className="Common__input"
									disabled={!allowUpdate}
									placeholder="10"
								/>
							</div>
						</Field>

						<Field key="zIndexImportant" className="wcc__options__field">
							<div className="wcc__options__field__label">
								<div className="wcc__options__field__label__title">Z Index Important</div>
								<div className="wcc__options__field__label__description">If the z-index css should use the !important rule.</div>
							</div>

							<this.Switch field="zIndexImportant" checked={zIndexImportant} onChange={this.onChange} />
						</Field>

						<Field key="placeholders" className="wcc__options__field wcc__options__field--tall">
							<div className="wcc__options__field__label">
								<div className="wcc__options__field__label__title">
									Placeholders
									{showLanguageSelectors && (
										<div className="wcc__options__field__label__title__language_container">
											<LanguageSelectorMini
												language={languageSectionPlaceholders}
												languageOptions={languageSectionSupportedOptions}
												onChange={newLanguage => {
													this.update({ languageSectionPlaceholders: newLanguage });
												}}
											/>
										</div>
									)}
								</div>
								<div className="wcc__options__field__label__description">Change the placeholder names for all the fields and buttons on the Web Chat.</div>
							</div>
							<div className="wcc__options__field__input">
								<div className="wcc__options__field__input__label">Header</div>
								<input
									maxLength={25}
									name="header"
									id="headerPlaceholder"
									onChange={e => this.onPlaceholderChange("header", e.target.value)}
									value={placeholders[languageSectionPlaceholders].header}
									className="Common__input"
									disabled={!allowUpdate}
								/>
								<div className="wcc__options__field__input__label">Blurb</div>
								<input
									maxLength={70}
									name="blurb"
									id="blurbPlaceholder"
									onChange={e => this.onPlaceholderChange("blurb", e.target.value)}
									value={placeholders[languageSectionPlaceholders].blurb}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								{!forceFullName && (
									<>
										<div className="wcc__options__field__input__label">Name</div>
										<input
											maxLength={65}
											name="name"
											id="namePlaceholder"
											onChange={e => this.onPlaceholderChange("name", e.target.value)}
											value={placeholders[languageSectionPlaceholders].name}
											className="Common__input"
											disabled={!allowUpdate}
										/>
									</>
								)}
								{forceFullName && (
									<>
										<div className="wcc__options__field__input__label">First Name</div>
										<input
											maxLength={65}
											name="firstName"
											id="firstNamePlaceholder"
											onChange={e => this.onPlaceholderChange("firstName", e.target.value)}
											value={placeholders[languageSectionPlaceholders].firstName}
											className="Common__input"
											disabled={!allowUpdate}
										/>

										<div className="wcc__options__field__input__label">Last Name</div>
										<input
											maxLength={65}
											name="lastName"
											id="lastNamePlaceholder"
											onChange={e => this.onPlaceholderChange("lastName", e.target.value)}
											value={placeholders[languageSectionPlaceholders].lastName}
											className="Common__input"
											disabled={!allowUpdate}
										/>
									</>
								)}

								<div className="wcc__options__field__input__label">Phone</div>
								<input
									maxLength={65}
									name="phone"
									id="phonePlaceholder"
									onChange={e => this.onPlaceholderChange("phone", e.target.value)}
									value={placeholders[languageSectionPlaceholders].phone}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								<div className="wcc__options__field__input__label">Message</div>
								<input
									maxLength={65}
									name="message"
									id="messagePlaceholder"
									onChange={e => this.onPlaceholderChange("message", e.target.value)}
									value={placeholders[languageSectionPlaceholders].message}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								<div className="wcc__options__field__input__label">Location</div>
								<input
									maxLength={65}
									name="location"
									id="locationPlaceholder"
									onChange={e => this.onPlaceholderChange("location", e.target.value)}
									value={placeholders[languageSectionPlaceholders].location}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								<div className="wcc__options__field__input__label">Department</div>
								<input
									maxLength={65}
									name="department"
									id="departmentPlaceholder"
									onChange={e => this.onPlaceholderChange("department", e.target.value)}
									value={placeholders[languageSectionPlaceholders].department}
									className="Common__input"
									disabled={!allowUpdate}
								/>

								<div className="wcc__options__field__input__label">Submit</div>
								<input
									maxLength={65}
									name="submit"
									id="submitPlaceholder"
									onChange={e => this.onPlaceholderChange("submit", e.target.value)}
									value={placeholders[languageSectionPlaceholders].submit}
									className="Common__input"
									disabled={!allowUpdate}
								/>
							</div>
						</Field>

						{this.renderTooltipFields()}
						{this.renderBubbleFields()}
						{this.renderPositioningFields()}
					</PoseGroup>
				</div>

				{this.renderPreview()}
				{showModal && (
					<TooltipImage
						showModal={showModal}
						onHide={() => this.update({ showModal: false })}
						onUpdateImage={this.onUpdateImage}
						tooltipImage={tooltipImage}
						locationId={locationId}
					/>
				)}
				{changesMade && <UnsavedChanges />}
			</div>
		);
	}
}

export default withRouter(withLocation(WebchatConfig));

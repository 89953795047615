import React, { Component } from "react";
import * as Icon from "react-feather";
import posed from "react-pose";
import ContentLoader from "react-content-loader";

import { MEDIA_ICON, MEDIA_TYPES } from "../../../../constants/Messenger";
import { KEYS } from "../../../../constants/Messenger";

import "./reply-message.css";
import MessagesService from "../../../../services/MessagesService";
import ToastService from "../../../../services/ToastService";

import ThreadMedia from "../Media/ThreadMedia";

const BoxReplyMessage = posed.div({
	visible: {
		y: -125,
		opacity: 1
	},
	hidden: {
		y: 350,
		opacity: 0
	}
});

class ReplyMessage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			replyText: "",
			replyMedia: null,
			replyAuthor: "",
			show: false,
			loading: false,
			minimize: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async resetComponent() {
		let { replyMessageId } = this.props;

		// Clear the state data
		await this.update({
			replyText: "",
			replyMedia: null,
			replyAuthor: "",
			show: false,
			loading: false
		});

		if (!replyMessageId) {
			return;
		}

		await this.update({
			loading: true
		});

		// Fetch the message we are replying to
		let messageFetched = await MessagesService.getMessage(replyMessageId);

		if (!messageFetched) {
			ToastService.error("The message you are replying to no longer exists.");
			await this.update({
				loading: false
			});
			return;
		}

		await this.update({
			replyText: messageFetched.content,
			replyMedia: messageFetched.media,
			replyAuthor: messageFetched.sender_user_name,
			show: true,
			loading: false
		});
	}

	async componentDidMount() {
		document.addEventListener("keydown", this.onKey);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
	}

	componentDidUpdate(prevProps) {
		let { replyMessageId } = this.props;

		if (prevProps.replyMessageId !== replyMessageId) {
			this.resetComponent();
		}
	}

	onCloseReplyMessage = () => {
		this.update({ minimize: false });
		// Tell parent component to cancel the reply
		if (this.props.onReplyMessageCancel) {
			this.props.onReplyMessageCancel();
		}
	};

	onMinimize = () => {
		this.update({ minimize: !this.state.minimize });
	};

	onReplyBoxStyles = () => {
		let { inputComposeHeight } = this.props;

		if (!inputComposeHeight) {
			return null;
		}

		return { marginBottom: `${inputComposeHeight - 30}px` };
	};

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onCloseReplyMessage();
		}
	};

	render() {
		let { replyText, replyMedia, replyAuthor, show, loading, minimize } = this.state;

		// Show a ghost load
		if (loading) {
			return (
				<BoxReplyMessage className="mb-reply-message" pose={"visible"}>
					<ContentLoader height={400} width={"100%"}>
						{/* The image square */}
						<rect x="0" y="0" rx="5" ry="5" width="130" height="70" />

						{/* The title line */}
						<rect x="140" y="0" rx="5" ry="5" width="240" height="20" />

						{/* The reply content lines */}
						<rect x="140" y="28" rx="5" ry="5" width="160" height="10" />
						<rect x="140" y="43" rx="5" ry="5" width="160" height="10" />
						<rect x="140" y="58" rx="5" ry="5" width="160" height="10" />
					</ContentLoader>
				</BoxReplyMessage>
			);
		}

		// Append the correct icon to the front of the text
		if (replyMedia && replyMedia.length > 0) {
			// Replace the message used in the list component to a string of emojis
			var emojiIcon = MEDIA_ICON[replyMedia[0].type];

			// Declare if it is of type image. If so, we will preview it.
			var isMediaImage = replyMedia[0].type === MEDIA_TYPES.image;
		}

		let pose = "hidden";
		if (show) {
			pose = "visible";
		}

		let replyBoxStyles = this.onReplyBoxStyles();

		return (
			<BoxReplyMessage className={`mb-reply-message ${minimize ? "mb-reply-message--minimized" : ""}`} style={replyBoxStyles} pose={pose}>
				<div className="mb-reply-message__container-actions">
					<div className="mb-reply-message__container-actions__action" onClick={this.onMinimize}>
						{minimize ? <Icon.Maximize2 size="18" /> : <Icon.Minus size="18" />}
					</div>
					<div className="mb-reply-message__container-actions__action" onClick={this.onCloseReplyMessage}>
						<Icon.X size="18" />
					</div>
				</div>
				<div className="mb-reply-message__container">
					{isMediaImage && (
						<div className="mb-reply-message__image-content">
							<ThreadMedia media={replyMedia[0]} maxHeightOrWidth={200} />
						</div>
					)}
					<div className="mb-reply-message__text-content">
						<div className="mb-reply-message__title">Reply to {replyAuthor}</div>
						<div className="mb-reply-message__text">
							{emojiIcon && <span className="mb-reply-message__text__emoji">{emojiIcon}</span>}
							{replyText}
						</div>
					</div>
				</div>
			</BoxReplyMessage>
		);
	}
}

export default ReplyMessage;

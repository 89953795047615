import UserService from "../services/UserService";

const STATUS = {
	active: "active",
	inactive: "inactive",
	deleted: "deleted"
};

export { STATUS };

export const COMPANY_COLUMNS = {
	id: {
		id: "id",
		value: "Id",
		sortable: true,
		sortField: "id",
		width: () => (UserService.isSuperAdminOrCustomerSuccess() ? 1 : 0),
		notClickable: true
	},
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 5,
		notClickable: true
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const COMPANY_FILTERS = {
	all: {
		id: "all",
		display: "All",
		value: "all",
		label: "all",
		order: 1
	},
	favorites: {
		id: "favorites",
		display: "Favorites",
		value: "favorites",
		label: "Favorites",
		order: 2
	}
};

export const MAX_RECENT_COMPANIES_TO_STORE = 7;

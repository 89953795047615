import React, { Component } from "react";
import Modal from "../../components/common/DHModal";
import TextArea from "../../components/common/TextArea";
import AttachmentItem from "../../components/common/AttachmentItem";
import { withTranslation } from "react-i18next";

import WorkflowService from "../../services/WorkflowService";
import UserService from "../../services/UserService";
import MessagesService from "../../services/MessagesService";
import ToastService from "../../services/ToastService";

import { MODE, MEDIUM } from "../../constants/Messenger";

import "../../styles/css/scenes/auto-replies.css";

class OfficeAutoRepliesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			saving: false,

			// Attachment related variables
			afterHoursAttachmentsChanged: false,
			afterHoursMediaIds: [],
			duringHoursAttachmentsChanged: false,
			duringHoursMediaIds: []
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			if (JSON.stringify(prevProps.afterHoursWorkflow) === JSON.stringify(this.props.afterHoursWorkflow)) {
				this.setMedia();
				return;
			}
			if (JSON.stringify(prevProps.businessHoursWorkflow) === JSON.stringify(this.props.businessHoursWorkflow)) {
				this.setMedia();
				return;
			}
		}
		return;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	setMedia = async () => {
		let { afterHoursWorkflow, businessHoursWorkflow } = this.props;

		if (afterHoursWorkflow) {
			let workflow = await WorkflowService.fetchWorkFlow(afterHoursWorkflow.id, true);

			let mediaIds = [];

			// For now we only allow for one attachment at a time
			let media = null;

			// If this scheduled message has any media attachments
			if (workflow && workflow.MessageTemplate && workflow.MessageTemplate.Media && workflow.MessageTemplate.Media.length > 0) {
				media = workflow.MessageTemplate.Media[0];
			}

			if (media) {
				// For now, we only allow one media
				mediaIds = [workflow.MessageTemplate.Media[0].id];
			}
			await this.update({
				afterHoursMediaIds: mediaIds
			});
		}

		if (businessHoursWorkflow) {
			let mediaIds = [];
			let workflow = await WorkflowService.fetchWorkFlow(businessHoursWorkflow.id, true);

			// For now we only allow for one attachment at a time
			let media = null;

			// If this scheduled message has any media attachments
			if (workflow && workflow.MessageTemplate && workflow.MessageTemplate.Media && workflow.MessageTemplate.Media.length > 0) {
				media = workflow.MessageTemplate.Media[0];
			}

			if (media) {
				// For now, we only allow one media
				mediaIds = [workflow.MessageTemplate.Media[0].id];
			}

			await this.update({
				duringHoursMediaIds: mediaIds
			});
		}
	};

	isSavable = () => {
		return !this.state.saving;
	};

	updateAutoReplies = async () => {
		let { afterHoursWorkflow, businessHoursWorkflow, onHide, t } = this.props;
		let { afterHoursMediaIds, afterHoursAttachmentsChanged, duringHoursMediaIds, duringHoursAttachmentsChanged } = this.state;

		if (!this.isSavable()) {
			return;
		}

		await this.update({
			saving: true
		});

		let locationId = UserService.getActiveLocation().id;
		let companyId = UserService.getActiveCompany().id;

		// after hours auto response media
		let afterHoursNewMediaIds = [];
		if (afterHoursAttachmentsChanged) {
			// For now, we only support 1 media
			let uploadedMedia = await MessagesService.uploadLocalMedia({
				mediaIds: afterHoursMediaIds,
				mode: MODE.customer,
				medium: MEDIUM.sms.key,
				limit: 1,
				companyId,
				locationId
			});
			if (!uploadedMedia) {
				ToastService.error(t("Error uploading media."));
				await this.update({ saving: false });
				return;
			}

			afterHoursNewMediaIds = uploadedMedia.map(m => m.id);
			MessagesService.clearLocalMedia();

			afterHoursMediaIds = afterHoursNewMediaIds;
		}

		// during hours auto response media
		let duringHoursNewMediaIds = [];
		if (duringHoursAttachmentsChanged && duringHoursMediaIds) {
			// For now, we only support 1 media
			let uploadedMedia = await MessagesService.uploadLocalMedia({
				mediaIds: duringHoursMediaIds,
				mode: MODE.customer,
				medium: MEDIUM.sms.key,
				limit: 1
			});
			if (!uploadedMedia) {
				ToastService.error(t("Error uploading during hours media."));
				await this.update({ saving: false });
				return;
			}
			duringHoursNewMediaIds = uploadedMedia.map(m => m.id);
			duringHoursMediaIds = duringHoursNewMediaIds;
		}

		if (afterHoursWorkflow) {
			await WorkflowService.updateAutoReply({
				workflowId: afterHoursWorkflow.id,
				response: afterHoursWorkflow.MessageTemplate.msg_text,
				status: afterHoursWorkflow.status,
				mediaIds: afterHoursMediaIds
			});
		}
		if (businessHoursWorkflow) {
			await WorkflowService.updateAutoReply({
				workflowId: businessHoursWorkflow.id,
				response: businessHoursWorkflow.MessageTemplate.msg_text,
				status: businessHoursWorkflow.status,
				mediaIds: duringHoursMediaIds
			});
		}

		ToastService.info(t("Office Hours Auto Replies Updated"));

		await this.update({
			afterHoursMediaIds,
			afterHoursAttachmentsChanged: false,
			duringHoursMediaIds,
			duringHoursAttachmentsChanged: false,
			saving: false
		});

		if (onHide) {
			onHide();
		}
	};

	afterHoursOnFileAdded = async files => {
		let file = files[0];
		let localMediaId = MessagesService.storeLocalMedia({ file }).id;
		let { afterHoursMediaIds } = this.state;

		// For now, we only support one media attachment
		afterHoursMediaIds = [];
		afterHoursMediaIds.push(localMediaId);

		await this.update({
			afterHoursMediaIds,
			afterHoursAttachmentsChanged: true
		});
	};

	afterHoursOnHideAttachment = async () => {
		await this.update({
			afterHoursAttachmentsChanged: true,
			afterHoursMediaIds: []
		});
	};

	duringHoursOnFileAdded = async files => {
		let file = files[0];
		let localMediaId = MessagesService.storeLocalMedia({ file }).id;
		let { duringHoursMediaIds } = this.state;

		// For now, we only support one media attachment
		duringHoursMediaIds = [];
		duringHoursMediaIds.push(localMediaId);

		await this.update({
			duringHoursMediaIds,
			duringHoursAttachmentsChanged: true
		});
	};

	duringHoursOnHideAttachment = async () => {
		await this.update({
			duringHoursAttachmentsChanged: true,
			duringHoursMediaIds: []
		});
	};

	render() {
		let { show, onHide, afterHoursWorkflow, businessHoursWorkflow, onAfterHoursResponseChange, onDuringHoursResponseChange, t } = this.props;
		let { afterHoursMediaIds, duringHoursMediaIds, saving } = this.state;

		return (
			<Modal show={show} onHide={onHide} title={t("Office Auto Replies")}>
				<div className="modal__flex-container">
					{afterHoursWorkflow && (
						<React.Fragment>
							<div className="auto-replies__modal__header">{t("After Office Hours Reply")}</div>
							<TextArea
								id="manage-after-office-hour-reply"
								type="text"
								height={130}
								rows={5}
								style={{ resize: "none" }}
								value={afterHoursWorkflow.MessageTemplate.msg_text}
								showFeedbackFaces={true}
								showFeedbackLength={true}
								showVariables={true}
								blueBorder={true}
								onChange={onAfterHoursResponseChange}
								attachments={true}
								onFileAdded={this.afterHoursOnFileAdded}
							/>
							{afterHoursMediaIds && afterHoursMediaIds.length > 0 && (
								<div className="">
									<div className="modal__field">{t("Attachment")}</div>
									{afterHoursMediaIds.map(mediaId => (
										<AttachmentItem key={mediaId} mediaId={mediaId} hide={this.afterHoursOnHideAttachment} />
									))}
								</div>
							)}
						</React.Fragment>
					)}
					{businessHoursWorkflow && (
						<React.Fragment>
							<div className="auto-replies__modal__header auto-replies__modal__during-hours">{t("During Office Hours Reply")}</div>
							<TextArea
								id="manage-during-office-hour-reply"
								type="text"
								height={130}
								rows={5}
								style={{ resize: "none" }}
								value={businessHoursWorkflow.MessageTemplate.msg_text}
								showFeedbackFaces={true}
								showFeedbackLength={true}
								showVariables={true}
								blueBorder={true}
								onChange={onDuringHoursResponseChange}
								attachments={true}
								onFileAdded={this.duringHoursOnFileAdded}
							/>
							{duringHoursMediaIds && duringHoursMediaIds.length > 0 && (
								<div className="">
									<div className="modal__field">{t("Attachment")}</div>
									{duringHoursMediaIds.map(mediaId => (
										<AttachmentItem key={mediaId} mediaId={mediaId} hide={this.duringHoursOnHideAttachment} />
									))}
								</div>
							)}
						</React.Fragment>
					)}
					<div className="auto-replies__modal__actions">
						<div className={`mb-button ${this.isSavable() ? "" : "mb-button--disabled"}`} onClick={() => this.updateAutoReplies()}>
							{saving ? t("Saving...") : t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(OfficeAutoRepliesModal);

import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import MessagesService from "../../services/MessagesService";
import UtilityService from "../../services/UtilityService";
import UserService from "../../services/UserService";

import Spinners from "./Spinners";

import { MEDIA_TYPES } from "../../constants/Messenger";

import "../../styles/css/components/commons/attachment.css";

class AttachmentItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			media: null
		};

		this.uploadInput = null;
	}

	hide = () => {
		if (this.props.disabled) {
			return;
		}
		if (this.props.hide) {
			this.props.hide();
		}
	};

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.mediaId !== this.props.mediaId) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { mediaId } = this.props;

		if (!mediaId) {
			this.setState({ media: null });
			return;
		}

		let media = await MessagesService.getMedia({ mediaId });

		if (!media) {
			console.log(`media not found ${mediaId}`);
			this.setState({ media: null });
			return;
		}

		media.download_url = UtilityService.appendQueryAuthToken(media.download_url, UserService.getActiveLocation().id, UserService.getAuthToken());

		this.setState({ media });
	};

	renderMediaName = () => {
		const { media } = this.state;

		return (
			<>
				<div className="attachment__file__text__name">{media.file_name}</div>
				{media.file_name.length > 24 && <div>{media.extension}</div>}
			</>
		);
	};

	renderBody = () => {
		const { media } = this.state;

		if (!media) {
			return null;
		}

		return (
			<>
				{media && media.file_name && media.type !== MEDIA_TYPES.image && (
					<div className="attachment__file">
						<Icon.FileText size="42" />
						<div className="attachment__file__text">{this.renderMediaName()}</div>
					</div>
				)}
				{media && media.type === MEDIA_TYPES.image && <img className="attachment__img" src={media.download_url} alt="An attachment" />}
			</>
		);
	};

	renderLoading = () => {
		return <Spinners loading={true} size="25px" type="tail-fade" />;
	};

	render() {
		const { disabled, loading, t } = this.props;

		return (
			<div className={`attachment ${loading ? "attachment--loading" : ""} ${disabled ? "attachment--disabled" : ""}`}>
				{loading && this.renderLoading()}
				{!loading && this.renderBody()}
				{!disabled && (
					<div className="attachment__overlay">
						<div onClick={this.hide}>
							<Icon.XCircle size="42" />
						</div>
						<div onClick={this.hide}>{t("Remove")}</div>
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(AttachmentItem);

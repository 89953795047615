import React, { PureComponent } from "react";

import "./color-swatch.css";

const COLORS = ["#ff6900", "#fcb900", "#7bdcb5", "#00D084", "#8ED1FC", "#0693E3", "#ABB8C3", "#EB144C", "#F78DA7", "#9900EF"];

class ColorSwatch extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedColor: props.initialColor || COLORS[0]
		};
	}

	componentDidUpdate(prevProps) {
		let { initialColor } = this.props;

		if (initialColor !== prevProps.initialColor) {
			this.update({
				selectedColor: initialColor || COLORS[0]
			});
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onColorSelected = async color => {
		await this.update({
			selectedColor: color
		});

		if (this.props.onSelect) {
			this.props.onSelect({ color });
		}
	};

	render() {
		let { disabled } = this.props;
		let { selectedColor } = this.state;

		return (
			<div className="color-swatch">
				{COLORS.map((color, index) => {
					let classes = ["color-swatch__item"];

					let isSelected = selectedColor === color;
					let styles = { backgroundColor: color };

					if (isSelected) {
						classes.push("color-swatch__item--selected");
						styles.outlineColor = color;
					}

					if (disabled && !isSelected) {
						return null;
					}

					return <div key={index} className={classes.join(" ")} style={styles} onClick={() => this.onColorSelected(color)} />;
				})}
			</div>
		);
	}
}

export default ColorSwatch;

import React, { Component } from "react";

import Page from "../../../components/common/Page";
import LeadsBarChart from "./LeadsBarChart";
import LeadsPieChart from "./LeadsPieChart";
import LeadsByDate from "./LeadsByDate";
import LeadsByHour from "./LeadsByHour";

import "../../../styles/css/scenes/leadSourceAnalytics.css";

class LeadSourceAnalytics extends Component {
	render() {
		return (
			<Page>
				<LeadsBarChart />
				<LeadsPieChart />
				<LeadsByDate />
				<LeadsByHour />
			</Page>
		);
	}
}

export default LeadSourceAnalytics;

import React, { Component } from "react";
import Page from "../common/Page";
import { withTranslation } from "react-i18next";

import AppConfig from "../../config/app/web-app.config.js";

import "../../styles/css/components/layouts/not-found.css";

class NotFound extends Component {
	render() {
		let { t } = this.props;

		return (
			<Page>
				<div className="not-found">
					<img alt="Not Found" className="not-found__img" src={`${AppConfig.CDN_URL}web-app/assets/404.gif`} />
					<div className="not-found__title">{t("Page Not Found")}</div>
					<div className="not-found__subtext"> {t("Sorry, but the page you requested could not be found. Please check the URL and try again.")} </div>
				</div>
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(NotFound);

import Kichiri from "./KichiriService";
import UserService from "./UserService";

const LogService = {
	/**
	 * Get tags for a location
	 * @param {Object} error
	 * @param {Object} info - object we get from an error boundary
	 */
	async log(error, info) {
		try {
			let location = UserService.getActiveLocation();
			const user = UserService.get();

			let body = {
				locationId: location ? location.id : null,
				userId: user ? user.id : null,
				error: `${error.stack}`,
				info: info ? info.componentStack : null,
				type: "web-app"
			};

			await Kichiri.admin.log(body, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	}
};

export default LogService;

export default {
	"ERR-0000": "Sorry, an unexpected error occurred.",
	"ERR-1000": "%FIELD% field is a required field.",
	"ERR-1001": "%FIELD% field should be minimum %LENGTH% characters.",
	"ERR-1002": "%FIELD% field should have at least %LENGTH% item selected.",
	"ERR-1003": "%FIELD% field should be of type String.",
	"ERR-1004": "%FIELD% field should be of type Boolean.",
	"ERR-1005": "%FIELD% field should be of type Integer.",
	"ERR-1006": "%FIELD% field should be of type Array.",
	"ERR-1007": "%FIELD% field should be of type Object."
};

import axios from "axios";

export const upload = async (file, name, url, authToken, extension) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("name", name);
	formData.append("extension", extension);

	try {
		let response = await axios.post(url, formData, {
			method: "POST",
			headers: {
				Authorization: authToken
			}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * XXX - There is a little gunda...
 *
 * Currently we allow all users to adjust their notification preferences (From ManageUser & EditProfile).
 * If a Super Admin is editing a User that is an Administrator they will be able to view and update all of the Administrator's notification settings.
 * If a Super Admin is editing a User that is a Restricted Team Member (or anyone that has view_messages_assigned_to_others to false), the Super Admin can see all of the settings, but cannot edit some of them.
 * If a Restricted Team Member (or anyone that has view_messages_assigned_to_others to false) is viewing themselves, they will only be able to edit certain notifications preferences that are important to them.
 *
 *
 * I've made a ticket for this here: DH-2170
 *
 * For now we need to live with this gunda flag
 */

export default {
	super_administrator: {
		id: 1,
		type: "super_administrator",
		system: true,
		canCreate: [
			"super_administrator",
			"customer_success",
			"reseller",
			"account_owner",
			"administrator",
			"team_leader",
			"team_member",
			"restricted_team_member",
			"contributor"
		]
	},
	customer_success: {
		id: 7,
		type: "customer_success",
		system: true,
		canCreate: ["customer_success", "account_owner", "administrator", "team_leader", "team_member", "restricted_team_member", "contributor"]
	},
	restricted_customer_success: {
		type: "restricted_customer_success",
		name: "Restricted Customer Success",
		system: true,
		canCreate: []
	},
	reseller: {
		id: 5,
		type: "reseller",
		system: true,
		canCreate: ["account_owner", "administrator", "team_leader", "team_member", "restricted_team_member", "contributor"]
	},
	account_owner: {
		type: "account_owner",
		system: false,
		canCreate: ["administrator", "team_leader", "team_member", "restricted_team_member", "contributor"]
	},
	administrator: {
		type: "administrator",
		system: false,
		canCreate: ["team_leader", "team_member", "restricted_team_member", "contributor"]
	},
	team_leader: {
		type: "team_leader",
		system: false,
		canCreate: []
	},
	team_member: {
		type: "team_member",
		system: false,
		canCreate: []
	},
	restricted_team_member: {
		type: "team_member",
		system: false,
		canCreate: []
	},
	contributor: {
		type: "contributor",
		system: false,
		canCreate: []
	}
};

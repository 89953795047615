export const SIGN_UP_COLUMNS = {
	legal_business_name: {
		id: "legal_business_name",
		value: "Business Name",
		sortable: true,
		sortField: "legal_business_name",
		width: 2
	},
	state: {
		id: "state",
		value: "State",
		sortable: true,
		sortField: "state",
		width: 1
	},
	contact_name: {
		id: "contact_name",
		value: "Contact Name",
		sortable: true,
		sortField: "contact_name",
		width: 2,
		widthMd: 0
	},
	country: {
		id: "country",
		value: "Country",
		sortable: false,
		sortField: "country",
		width: 1,
		widthMd: 0
	},
	is_free_trial: {
		id: "is_free_trial",
		value: "Free Trial",
		sortable: true,
		sortField: "is_free_trial",
		width: 1,
		widthMd: 0
	},
	start_date: {
		id: "start_date",
		value: "Start Date",
		sortable: true,
		sortField: "start_date",
		width: 1,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1,
		notClickable: true
	}
};

export const SIGN_UP_STATE_FILTERS = {
	all: {
		value: "all",
		label: "All"
	},
	draft: {
		value: "draft",
		label: "Draft"
	},
	sent: {
		value: "sent",
		label: "Sent"
	},
	payment_confirmed: {
		value: "payment_confirmed",
		label: "Payment Confirmed"
	},
	signed: {
		value: "signed",
		label: "Signed"
	},
	submitted: {
		value: "submitted",
		label: "Submitted"
	}
};

export const SIGN_UP_STATES = {
	draft: "draft",
	sent: "sent",
	paymentConfirmed: "payment_confirmed",
	signed: "signed",
	submitted: "submitted"
};

export const CURRENCY_OPTIONS = [
	{ value: "CAD", label: "CAD" },
	{ value: "USD", label: "USD" }
];

export const FREQUENCY_OPTIONS = [
	{ value: "monthly", label: "Monthly" },
	{ value: "quarterly", label: "Quarterly" },
	{ value: "biannually", label: "Biannually" },
	{ value: "annually", label: "Annually" }
];

export const CONTRACT_TYPE_OPTIONS = [
	{ value: "standard", label: "Standard (Annual)" },
	{ value: "annual_month_to_month", label: "Annual Month To Month" },
	{ value: "annual_3_month_opt_out", label: "Annual 3 Month Opt Out" },
	{ value: "month_to_month", label: "Month To Month" }
];

export const POORLY_MANAGED_ONE_TIME_COUPONS = ["One Time Setup Discount CAD", "One Time Setup Discount USD"];

export const POORLY_MANAGED_COUPONS = [
	"$30 off CAD",
	"$50 off CAD",
	"$75 off CAD",
	"$100 off CAD",
	"$150 off CAD",
	"$30 off USD",
	"$50 off USD",
	"$75 off USD",
	"$100 off USD",
	"$150 off USD"
];

export const SPECIAL_TERMS_OPTIONS = [
	{
		value: "trial-period-start-date",
		label: "Trial Period and Official Start Date"
	},
	{
		value: "for-us-customers-only",
		label: "FOR US CUSTOMERS ONLY"
	},
	{
		value: "promotional-discount",
		label: "Promotional Discount"
	},
	{
		value: "web-chat-option",
		label: "Web Chat Option"
	},
	{
		value: "3-month-provision",
		label: "3-Month Provision"
	},
	{
		value: "30-day-opt-out",
		label: "30 Day Opt-out"
	},
	{
		value: "90-days-opt-out",
		label: "90 Days Opt-Out"
	},
	{
		value: "mms-package-option",
		label: "MMS Package Option"
	}
];

export const SPECIAL_TERMS = {
	"trial-period-start-date":
		"The Trial start-date will be the earlier of the on-boarding date or XX XX, 20XX and continues for 14 days thereafter. The Customer can opt-out of the agreement until the 14th day after the Trial start-date at 5:00 pm Eastern Standard Time on that day.",
	"for-us-customers-only":
		"The One-time Setup Fee will not be charged during the trial. If the Customer opts out of DemandHub during the trial period, the One-time Setup Fee will be void.",
	"promotional-discount":
		"A discount of $XX has been applied to the monthly subscription. The Customer will maintain the promotional discount as long as this subscription is active.",
	"web-chat-option": "The customer has the option to remove Web Chat at the end of the trial which will reduce the monthly subscription fee to $XX.",
	"3-month-provision":
		"At the end of the 3rd month, the Customer will have the option of opting out of the remainder of the agreement.﻿The Customer must inform DemandHub in writing 24 hrs before the initial 3 month mark if they would like to cancel the balance of the agreement.",
	"30-day-opt-out": "The Customer is required to provide written notice to DemandHub at least 30 days in advance of their intention to opt-out.",
	"90-days-opt-out":
		"At the end of 90-days, the customer has the option of opting out of the remainder of the agreement. The Customer must inform DemandHub in writing 24 hrs before the initial 90 days mark if they would like to cancel the balance of the agreement.",
	"mms-package-option":
		"The Customer has the option to add an MMS Package to the Subscription that includes the ability to send up to X,XXX MMS messages per month for an additional $XX per month."
};

export const FEATURE_LIST = [
	{ value: "reviews", label: "Reviews" },
	{ value: "webchat", label: "Web Chat" },
	{ value: "messenger", label: "Messenger Platform" },
	{ value: "reviewsWidget", label: "Reviews Widget" },
	{ value: "mobile", label: "Mobile Application" },
	{ value: "reengagements", label: "Patient Reengagements" },
	{ value: "leadManager", label: "Lead Manager" },
	{ value: "aiReceptionist", label: "AI Receptionist" },
	{ value: "onlineScheduling", label: "Online Scheduling" }
];

export const COUNTRIES = {
	"United States": {
		value: "United States",
		label: "United States"
	},
	Canada: {
		value: "Canada",
		label: "Canada"
	}
};

export const CA_PROVINCES = {
	Ontario: "Ontario",
	Quebec: "Quebec",
	"British Columbia": "British Columbia",
	Alberta: "Alberta",
	Manitoba: "Manitoba",
	Saskatchewan: "Saskatchewan",
	"New Brunswick": "New Brunswick",
	"Nova Scotia": "Nova Scotia",
	"Prince Edward Island": "Prince Edward Island",
	"Newfoundland and Labrador": "Newfoundland and Labrador",
	"Northwest Territories": "Northwest Territories",
	Nunavut: "Nunavut",
	Yukon: "Yukon"
};

export const US_STATES = {
	Alabama: "Alabama",
	Alaska: "Alaska",
	Arizona: "Arizona",
	Arkansas: "Arkansas",
	California: "California",
	Colorado: "Colorado",
	Connecticut: "Connecticut",
	Delaware: "Delaware",
	Florida: "Florida",
	Georgia: "Georgia",
	Hawaii: "Hawaii",
	Idaho: "Idaho",
	Illinois: "Illinois",
	Indiana: "Indiana",
	Iowa: "Iowa",
	Kansas: "Kansas",
	Kentucky: "Kentucky",
	Louisiana: "Louisiana",
	Maine: "Maine",
	Maryland: "Maryland",
	Massachusetts: "Massachusetts",
	Michigan: "Michigan",
	Minnesota: "Minnesota",
	Mississippi: "Mississippi",
	Missouri: "Missouri",
	Montana: "Montana",
	Nebraska: "Nebraska",
	Nevada: "Nevada",
	"New Hampshire": "New Hampshire",
	"New Jersey": "New Jersey",
	"New Mexico": "New Mexico",
	"New York": "New York",
	"North Carolina": "North Carolina",
	"North Dakota": "North Dakota",
	Ohio: "Ohio",
	Oklahoma: "Oklahoma",
	Oregon: "Oregon",
	Pennsylvania: "Pennsylvania",
	"Rhode Island": "Rhode Island",
	"South Carolina": "South Carolina",
	"South Dakota": "South Dakota",
	Tennessee: "Tennessee",
	Texas: "Texas",
	Utah: "Utah",
	Vermont: "Vermont",
	Virginia: "Virginia",
	Washington: "Washington",
	"West Virginia": "West Virginia",
	Wisconsin: "Wisconsin",
	Wyoming: "Wyoming"
};

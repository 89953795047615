import React, { Component } from "react";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";

import JSONService from "../../../services/JSONService";
import { CsvService } from "../../../services/CsvService";

import Kichiri from "../../../services/KichiriService";
import Action from "../../../components/common/Action";
import UserService from "../../../services/UserService";
import UtilityService from "../../../services/UtilityService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import CompanyService from "../../../services/CompanyService";

import Spinners from "../../../components/common/Spinners";
import List from "../../../components/common/List";

import { SORT_ORDER } from "../../../constants/CommonConstants";
import { OVERALL_SNAPSHOT_COLUMNS } from "../../../constants/AnalyticsConstants";

class LocationAnalyticsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locations: [],
			locationsReviews: [],
			snapshotData: [],
			loading: false,
			overallSnapshotCsv: null,
			sortField: OVERALL_SNAPSHOT_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	resetComponent = async () => {
		await this.setAllLocationsForCompany();
	};

	/**
	 * get all the locations for the company
	 */
	async setAllLocationsForCompany() {
		const locations = [];
		try {
			await this.update({ loading: true });

			let data = await CompanyService.fetchLocations({ companyId: UserService.getActiveCompany().id });

			if (!data) {
				data = [];
			}

			data.forEach(aLocation => locations.push({ id: aLocation.id, name: aLocation.name }));

			await this.update({ locations });
			await this.setOverallRatingsForAllLocations();
			await this.setOverallSnapshotData();
		} catch (error) {
			console.log(error);
		}
		await this.update({ loading: false });
	}

	/**
	 * Get total_reviews and avg_rating for all the locations
	 */
	async setOverallRatingsForAllLocations() {
		const { locations } = this.state;

		const locationReviewPromises = [];

		locations.forEach(aLocation => {
			locationReviewPromises.push(
				AnalyticsService.getOverallRatingAndReviews({ locationId: aLocation.id }).catch(error => {
					return { failed: true, error };
				})
			);
		});

		const locationReviewPromisesResolve = await Promise.all(locationReviewPromises);

		var locationsReviews = [];

		for (let i = 0; i < locationReviewPromisesResolve.length; i++) {
			if (locationReviewPromisesResolve[i].failed) {
				console.log(locationReviewPromisesResolve[i].error);
				continue;
			}

			locationsReviews.push({
				id: locationReviewPromisesResolve[i].location_id,
				name: locationReviewPromisesResolve[i].location_name,
				reviews: locationReviewPromisesResolve[i].total_reviews,
				rating: locationReviewPromisesResolve[i].avg_rating.toFixed(1)
			});
		}

		await this.update({ locationsReviews });
	}

	/**
	 * For each locations, get snapshot data viz. invites_sent, clicked, ctr, reviews_received from their joining to the current day
	 */
	async setOverallSnapshotData() {
		const { locationsReviews } = this.state;

		let snapshotData = [];
		try {
			const overallSnapshotPromiseList = [];

			locationsReviews.forEach(async aLocationReview => {
				try {
					let data = AnalyticsService.getOverallSnapshot({ locationId: aLocationReview.id });
					if (data) {
						overallSnapshotPromiseList.push(data);
					}
				} catch (error) {
					overallSnapshotPromiseList.push({ failed: true, error });
				}
			});

			const overallSnapshotPromiseResponses = await Promise.all(overallSnapshotPromiseList);

			for (let i = 0; i < overallSnapshotPromiseResponses.length; i++) {
				if (overallSnapshotPromiseResponses[i].failed) {
					console.log(overallSnapshotPromiseResponses[i].error);
					continue;
				}

				snapshotData.push({
					...locationsReviews[i],
					invites_sent: overallSnapshotPromiseResponses[i].invites_sent,
					invites_clicked: overallSnapshotPromiseResponses[i].invites_clicked,
					ctr: overallSnapshotPromiseResponses[i].ctr,
					reviews_received: overallSnapshotPromiseResponses[i].reviews_received,
					conversion_rate: overallSnapshotPromiseResponses[i].conversion_rate
				});
			}
		} catch (error) {
			console.log(error);
		}

		await this.update({ snapshotData });
		this.generateCsv();
	}

	generateCsv = async () => {
		let { snapshotData } = this.state;

		let csv = [];
		csv.push(["Location", "Rating", "Reviews", "Invites Sent", "Invites Clicked", "CTR", "Reviews Received", "Conversation Rate"]);
		try {
			for (let i = 0; i < snapshotData.length; i++) {
				const data = snapshotData[i];
				csv.push([
					`${data.name}`,
					data.rating,
					data.reviews,
					data.invites_sent,
					data.invites_clicked,
					`${data.ctr}%`,
					data.reviews_received,
					`${data.conversion_rate}%`
				]);
			}

			await this.update({
				overallSnapshotCsv: csv
			});

			this.forceUpdate();
		} catch (error) {
			console.log(error);
		}
	};

	getHeaders = () => {
		let headers = {
			items: OVERALL_SNAPSHOT_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder, snapshotData } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ loading: true, sortField, sortOrder });

		let sortedData = JSONService.sortByField(snapshotData, sortField, sortOrder);

		await this.update({ snapshotData: sortedData, loading: false });
		this.forceUpdate();
	};

	onRecordClicked = () => {
		// nothing for now
	};

	renderRecord = recordData => {
		try {
			return [
				recordData.name,
				recordData.rating,
				recordData.reviews,
				recordData.invites_sent,
				recordData.invites_clicked,
				recordData.ctr,
				recordData.reviews_received,
				recordData.conversion_rate
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderList = () => {
		const { snapshotData, sortField, sortOrder, loading } = this.state;

		return (
			<List
				items={snapshotData}
				loading={loading}
				loadedAll={true}
				sortField={sortField}
				sortOrder={sortOrder}
				headers={this.getHeaders()}
				renderRecord={this.renderRecord}
				onRecordClicked={this.onRecordClicked}
				noDataTitle="No data has been found..."
				noDataIcon={<Icon.AlertCircle />}
			/>
		);
	};

	render() {
		const { overallSnapshotCsv, loading } = this.state;
		const { isCard, cardName, isEnabled, isPermissible } = this.props;

		const locationName = CsvService.filterActiveLocationName();

		if (isEnabled && !isEnabled()) {
			return (
				<div className={`analytics__section__chart`}>
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{cardName ? cardName : "Overall Snapshot"}</div>
					</div>
					<br />
					<div>Reviews is not enabled. Contact support to get started.</div>
				</div>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<div className={`analytics__section__chart`}>
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{cardName ? cardName : "Overall Snapshot"}</div>
					</div>
					<br />
					<div>Review data is restricted.</div>
				</div>
			);
		}

		if (loading) {
			return (
				<div className={`analytics__section__chart`}>
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{cardName ? cardName : "Overall Snapshot"}</div>
					</div>
					<div className="analytics__section__chart__spinner">
						<Spinners type="tail-fade" loading={true} size="60px" />
					</div>
				</div>
			);
		}

		return (
			<div className={`analytics__section__chart lead-source-analytics__chart-section`}>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : "Overall Snapshot"}</div>
					<div className="dashboard__card__header__actions">
						{overallSnapshotCsv && (
							<CSVLink data={overallSnapshotCsv} filename={`${locationName}-overall_snapshot.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>
				<br />
				<div className="Common__chart-container lead-source-analytics__chart-section">
					<div className="table-responsive">{this.renderList()}</div>
				</div>
			</div>
		);
	}
}

export default withRouter(LocationAnalyticsTable);

// Libraries
import React, { PureComponent } from "react";
import moment from "moment";
import _ from "underscore";
import debounce from "debounce-promise";
import ContentLoader from "react-content-loader";

// Components
import Modal from "../../components/common/DHModal";
import Alert from "../../components/common/Alert";
import Input from "../../components/common/Input";
import DHSelect from "../../components/common/DHSelect";
import Toggle from "../../components/common/Toggle";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import DHAsyncSelect from "../../components/common/DHAsyncSelect";
import RRuleEditor from "./RRuleEditor";
import ColorSwatch from "./ColorSwatch";

// Services
import CalendarService from "../../services/CalendarService";
import UserService from "../../services/UserService";
import MessagesService from "../../services/MessagesService";
import ToastService from "../../services/ToastService";

// Constants
import { SORT_ORDER } from "../../constants/CommonConstants";
import { USERS_COLUMNS } from "../../constants/Users";
import { CALENDAR_EVENT_MODAL_TABS, CALENDAR_EVENT_ATTENDANCE_TYPES, CALENDAR_TYPES } from "../../constants/Calendar";

// Styles
import "./calendar-event-modal.css";

class CalendarEventModal extends PureComponent {
	constructor(props) {
		super(props);

		let { show } = this.props;

		this.state = {
			// Modal Display State variables
			show: show,
			loading: false,
			modalTitle: "",
			canAuthorChanges: false,
			showConfirmDeleteModal: false,
			selectedTab: CALENDAR_EVENT_MODAL_TABS.general.id,

			// Calendar Event Fetching
			calendarEventId: null,

			// Calendar Event data
			title: "",
			description: "",
			isAllDay: false,
			isRepeated: false,
			repetitionPattern: "",
			initialRepititionPattern: "",
			authorUserId: 0,
			color: "#ff6900",

			// Other misc state items
			currentUserGuestSettings: null,

			// Multi Select User State Data
			contactInvitees: [],
			userInvitees: [],

			// Derived State
			canInviteesModifyEvent: false
		};

		this.onDebouncedSearchContacts = debounce(this.searchContacts.bind(this), 1000);
		this.onDebouncedSearchUsers = debounce(this.searchUsers.bind(this), 1000);
		this.onDebouncedSearchCalendars = debounce(this.searchCalendars.bind(this), 1000);
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { initialEvent } = this.props;
		let { startAt, endAt } = initialEvent;

		await this.update({ loading: true });

		let newState = {
			isCreateMode: true,
			modalTitle: "",
			selectedCalendar: null,
			selectedTab: CALENDAR_EVENT_MODAL_TABS.general.id,

			calendarId: 0,
			authorUserId: 0,
			title: "",
			description: "",
			startAt,
			endAt,
			isAllDay: false,
			isRepeated: false,
			repetitionPattern: "",
			color: "#ff6900",

			contactInvitees: [],
			userInvitees: [],
			currentUserGuestSettings: null,

			canAuthorChanges: true
		};

		let calendarEvent = await this.fetchCalendarEvent();

		if (!calendarEvent) {
			await this.update(newState);
		}

		await this.update({ loading: false });
	};

	fetchCalendarEvent = async () => {
		let { calendarEventId } = this.props;

		if (!calendarEventId) {
			return null;
		}

		// Fetch the CalendarEvent object
		let { data: calendarEvent, error } = await CalendarService.fetchCalendarEvent({ params: { calendarEventId } });

		if (error) {
			ToastService.error("An error occurred trying to get calendar event.");
			return;
		}

		// Parse the contacts and users invited to the calendar event to make it viewable in the UI
		let { userInvitees, contactInvitees } = CalendarService.parseInvitees({ calendarEvent });

		// Determine whether the current user can view/edit this CalendarEvent
		let canAuthorChanges = CalendarService.canAuthor({ authorUserId: calendarEvent.author_user_id, userInvitees });

		// Retrieve the current users notification settings for the current event if they are an invited user
		let currentUserGuestSettings = CalendarService.getCurrentUserGuestSettings({ calendarEvent });

		let newState = {
			isCreateMode: false,
			modalTitle: `Event: ${calendarEvent.title}`,
			selectedCalendar: null,
			selectedTab: CALENDAR_EVENT_MODAL_TABS.general.id,

			calendarEventId,
			calendarId: calendarEvent.calendar_id,
			authorUserId: calendarEvent.author_user_id,
			title: calendarEvent.title,
			description: calendarEvent.description,
			startAt: calendarEvent.start_at,
			endAt: calendarEvent.end_at,
			isAllDay: calendarEvent.is_all_day,
			isRepeated: calendarEvent.is_repeated,
			repetitionPattern: calendarEvent.repetition_pattern,
			initialRepititionPattern: calendarEvent.repetition_pattern,
			color: calendarEvent.color,

			userInvitees,
			contactInvitees,
			currentUserGuestSettings,

			canAuthorChanges: canAuthorChanges
		};

		if (calendarEvent.Calendar) {
			newState.selectedCalendar = {
				label: calendarEvent.Calendar.title,
				value: calendarEvent.Calendar.id
			};
		}

		await this.update(newState);

		return calendarEvent;
	};

	searchCalendars = async searchTerm => {
		let location = UserService.getActiveLocation();
		let { data: calendars, error } = await CalendarService.fetch({
			query: {
				searchTerm,
				locationId: location.id
			}
		});

		if (error) {
			ToastService.error("An error occurred trying to get calendars.");
			return;
		}

		let results = calendars.map(calendar => {
			return {
				label: calendar.title,
				value: calendar.id
			};
		});

		return results;
	};

	searchContacts = async searchTerm => {
		let locationId = UserService.getActiveLocation().id;

		let contacts = await MessagesService.searchConversations({ searchTerm, locationId });

		let results = contacts.map(c => {
			return {
				label: `Contact: ${c.name} - ${c.medium_data}`,
				value: c.id,
				type: "contact"
			};
		});

		return results;
	};

	searchUsers = async searchTerm => {
		let user = UserService.get();
		let company = UserService.getActiveCompany();

		let { users } = await UserService.fetchUsers({
			limit: 20,
			companyId: company.id,
			userId: user.id,
			searchTerm: searchTerm,
			sortField: USERS_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc
		});

		let results = users.map(u => {
			return {
				label: `User: ${u.first_name} ${u.last_name}`,
				value: u.id,
				type: "user"
			};
		});

		return results;
	};

	onSelectContact = async results => {
		await this.update({ contactInvitees: results });
	};

	onSelectUser = async results => {
		await this.update({ userInvitees: results });
	};

	onInputChange = async event => {
		await this.update({ [event.target.name]: event.target.value });
	};

	reconcileInvitees = () => {
		let { contactInvitees, userInvitees, canInviteesModifyEvent, currentUserGuestSettings } = this.state;

		let contacts = contactInvitees.map(contact => {
			return {
				contact_id: contact.value
			};
		});

		let users = userInvitees.map(user => {
			// Set current User's guest settings before reconciling the invitee on the backend
			if (currentUserGuestSettings && user.value === currentUserGuestSettings.user_id) {
				return {
					user_id: user.value,

					is_admin: canInviteesModifyEvent,

					attendance: currentUserGuestSettings.attendance,
					should_notify: currentUserGuestSettings.should_notify,
					should_remind: currentUserGuestSettings.should_remind,
					remind_before: currentUserGuestSettings.remind_before
				};
			}

			// Otherwise return the standard payload for users that are not from the same
			return {
				user_id: user.value,
				is_admin: canInviteesModifyEvent
			};
		});

		return users.concat(contacts);
	};

	onSave = async event => {
		let {
			isCreateMode,

			calendarId,
			calendarEventId,

			title,
			description,
			isAllDay,
			isRepeated,
			repetitionPattern,
			startAt,
			endAt,
			color
		} = this.state;

		event.preventDefault();
		event.stopPropagation();

		let calendarEventInvitees = this.reconcileInvitees();

		let calendarEvent = null;

		if (isCreateMode) {
			let location = UserService.getActiveLocation();
			let company = UserService.getActiveCompany();

			let { data } = await CalendarService.createCalendarEvent({
				body: {
					companyId: company.id,
					locationId: location.id,
					calendarId,
					title,
					description,
					startAt,
					endAt,
					isAllDay,
					isRepeated,
					repetitionPattern,
					calendarEventInvitees,
					color,
					status: "active",
					type: CALENDAR_TYPES.generic
				}
			});

			calendarEvent = data;
		} else {
			let { data } = await CalendarService.updateCalendarEvent({
				body: {
					calendarId,
					title,
					description,
					startAt,
					endAt,
					isAllDay,
					isRepeated,
					repetitionPattern,
					calendarEventInvitees,
					color
				},
				params: {
					calendarEventId
				}
			});

			calendarEvent = data;
		}

		if (!calendarEvent) {
			ToastService.error("An error occurred trying to save this calendar event.");
		} else {
			ToastService.info("Calendar event saved successfully.");
		}

		if (this.props.onClose && calendarEvent) {
			this.props.onClose(calendarEvent);
		}
	};

	onSelectCalendar = async selectedCalendar => {
		await this.update({
			calendarId: selectedCalendar.value,
			selectedCalendar
		});
	};

	onRRuleChange = async ({ rrule }) => {
		await this.update({
			repetitionPattern: rrule
		});
	};

	onDelete = event => {
		event.preventDefault();
		event.stopPropagation();

		this.update({
			showConfirmDeleteModal: true
		});
	};

	onDeleteAlertClose = async confirmed => {
		if (confirmed) {
			this.deleteCalendarEvent();
		}

		await this.update({
			showConfirmDeleteModal: false
		});
	};

	deleteCalendarEvent = async () => {
		let { calendarEventId } = this.state;

		let { data: calendarEvent } = await CalendarService.updateCalendarEvent({
			body: {
				status: "deleted"
			},
			params: {
				calendarEventId
			}
		});

		if (!calendarEvent) {
			ToastService.error("An error occurred trying to remove this event.");
		} else {
			ToastService.info("Calendar event removed.");
		}

		if (this.props.onClose && calendarEvent) {
			this.props.onClose(calendarEvent);
		}
	};

	onSelect = async ({ color }) => {
		await this.update({
			color
		});
	};

	onTabSelect = async tab => {
		this.update({
			selectedTab: tab.id
		});
	};

	onChangeUserGuestSetting({ field, value }) {
		let { currentUserGuestSettings } = this.state;
		let clone = { ...currentUserGuestSettings };
		clone[field] = value;
		this.update({ currentUserGuestSettings: clone });
	}

	isCurrentUserAGuest() {
		let { currentUserGuestSettings } = this.state;
		return currentUserGuestSettings !== null;
	}

	renderUserSettings() {
		let { currentUserGuestSettings } = this.state;

		if (!this.isCurrentUserAGuest()) {
			return null;
		}

		return (
			<>
				<DHSelect
					label="Attendance"
					name="attendance"
					id="attendance"
					onChange={selection => this.onChangeUserGuestSetting({ field: "attendance", value: selection.value })}
					defaultValue={currentUserGuestSettings.attendance}
					value={CALENDAR_EVENT_ATTENDANCE_TYPES[currentUserGuestSettings.attendance]}
					options={Object.values(CALENDAR_EVENT_ATTENDANCE_TYPES)}
					required={true}
					isClearable={false}
				/>
				<Toggle
					id="shouldNotify"
					label="Notify me"
					description="Notify me of this event"
					checked={currentUserGuestSettings.should_notify}
					onChange={shouldNotify => this.onChangeUserGuestSetting({ field: "should_notify", value: shouldNotify })}
				/>
				<Toggle
					id="shouldRemind"
					label="Remind me"
					description="Remind me of this event"
					checked={currentUserGuestSettings.should_remind}
					onChange={shouldRemind => this.onChangeUserGuestSetting({ field: "should_remind", value: shouldRemind })}
				/>
				<Input
					label="Remind Before"
					name="description"
					id="description"
					type="number"
					onChange={event => this.onChangeUserGuestSetting({ field: "remind_before", value: event.target.value })}
					value={currentUserGuestSettings.remind_before}
				/>
			</>
		);
	}

	renderLoader() {
		return (
			<ContentLoader width={500} height={300}>
				<rect x="20" y="0" rx="4" ry="4" width="150" height="15" />
				<rect x="20" y="20" rx="8" ry="8" width="450" height="40" />

				<rect x="20" y="70" rx="4" ry="4" width="150" height="15" />
				<rect x="20" y="90" rx="8" ry="8" width="450" height="40" />

				<rect x="20" y="140" rx="4" ry="4" width="150" height="15" />
				<rect x="20" y="160" rx="8" ry="8" width="450" height="40" />

				<rect x="20" y="210" rx="4" ry="4" width="150" height="15" />
				<rect x="20" y="230" rx="8" ry="8" width="450" height="40" />
			</ContentLoader>
		);
	}

	render() {
		let { show, onClose } = this.props;
		let {
			// Modal Display State Variables
			loading,
			isCreateMode,
			modalTitle,
			showConfirmDeleteModal,
			canAuthorChanges,
			selectedTab,
			selectedCalendar,

			// DB CalendarEvent Data
			title,
			description,
			isAllDay,
			isRepeated,
			initialRepititionPattern,
			startAt,
			endAt,
			color,
			canInviteesModifyEvent,

			// User and Contact Selector Helper State Variables
			contactInvitees,
			userInvitees
		} = this.state;

		return (
			<>
				<Modal show={show} onHide={onClose} title={isCreateMode ? "New Event" : modalTitle} className="cem">
					<Tabs onSelect={this.onTabSelect} selected={selectedTab} removePadding>
						<Tab id={CALENDAR_EVENT_MODAL_TABS.general.id} value={CALENDAR_EVENT_MODAL_TABS.general.value} />
						<Tab id={CALENDAR_EVENT_MODAL_TABS.guests.id} value={CALENDAR_EVENT_MODAL_TABS.guests.value} />
						<Tab id={CALENDAR_EVENT_MODAL_TABS.schedule.id} value={CALENDAR_EVENT_MODAL_TABS.schedule.value} />
						{this.isCurrentUserAGuest() && <Tab id={CALENDAR_EVENT_MODAL_TABS.settings.id} value={CALENDAR_EVENT_MODAL_TABS.settings.value} />}
					</Tabs>

					{loading && this.renderLoader()}

					{!loading && (
						<form className="cem__form" onSubmit={this.onSave}>
							{selectedTab === CALENDAR_EVENT_MODAL_TABS.general.id && (
								<>
									<ColorSwatch initialColor={color} onSelect={this.onSelect} disabled={!canAuthorChanges} />
									<Input label="Start" name="startAt" id="startAt" type="text" value={moment(startAt).format("dddd, MMMM Do YYYY @ hh:mm a")} disabled />
									<Input label="End" name="endAt" id="endAt" type="text" value={moment(endAt).format("dddd, MMMM Do YYYY @ hh:mm a")} disabled />

									<Input
										label="Event Name"
										name="title"
										id="title"
										type="text"
										onChange={this.onInputChange}
										value={title}
										required
										disabled={!canAuthorChanges}
									/>
									<Input
										label="Description"
										name="description"
										id="description"
										type="text"
										onChange={this.onInputChange}
										value={description}
										required
										disabled={!canAuthorChanges}
									/>

									{canAuthorChanges && (
										<DHAsyncSelect
											label="Calendar"
											placeholder="Select a calendar ..."
											value={selectedCalendar}
											onSearch={this.onDebouncedSearchCalendars}
											onSelect={this.onSelectCalendar}
										/>
									)}
								</>
							)}

							{selectedTab === CALENDAR_EVENT_MODAL_TABS.guests.id && (
								<>
									<DHAsyncSelect
										label="Contact"
										placeholder="Search for a contacts to invite to this event ..."
										isMulti
										value={contactInvitees}
										onSearch={this.onDebouncedSearchContacts}
										onSelect={this.onSelectContact}
										disabled={!canAuthorChanges}
									/>

									<DHAsyncSelect
										label="Users"
										placeholder="Search for users to invite to this event ..."
										isMulti
										value={userInvitees}
										onSearch={this.onDebouncedSearchUsers}
										onSelect={this.onSelectUser}
										disabled={!canAuthorChanges}
									/>

									{canAuthorChanges && (
										<Toggle
											id="canInviteesModifyEvent"
											label="Guest Permissions"
											description="Can guests modify this event?"
											checked={canInviteesModifyEvent}
											onChange={canInviteesModifyEvent => this.update({ canInviteesModifyEvent })}
										/>
									)}
								</>
							)}

							{selectedTab === CALENDAR_EVENT_MODAL_TABS.schedule.id && (
								<>
									<Toggle
										id="isAllDay"
										label="All Day Event"
										description="Does this event last all day?"
										checked={isAllDay}
										onChange={isAllDay => this.update({ isAllDay })}
										disabled={!canAuthorChanges}
									/>
									<Toggle
										id="isRepeated"
										label="Repeats"
										description="Does this event repeat?"
										checked={isRepeated}
										onChange={isRepeated => this.update({ isRepeated })}
										disabled={!canAuthorChanges}
									/>
									{isRepeated && canAuthorChanges && <RRuleEditor rrule={initialRepititionPattern} startDate={startAt} onChange={this.onRRuleChange} />}
								</>
							)}

							{selectedTab === CALENDAR_EVENT_MODAL_TABS.settings.id && this.renderUserSettings()}

							{/* If the user can author changes or if the current selected tab is "Settings", then allow the user to save. The Settings tab are personal settings on an event level for a particular user */}
							{(canAuthorChanges || selectedTab === CALENDAR_EVENT_MODAL_TABS.settings.id) && (
								<div className="cem__form__actions">
									<input id="save" className="mb-button" type="submit" value="Save" />
									{!isCreateMode && (
										<button className="mb-button" onClick={this.onDelete}>
											Remove
										</button>
									)}
								</div>
							)}
						</form>
					)}
				</Modal>
				<Alert type="warning" show={showConfirmDeleteModal} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onDeleteAlertClose}>
					<div>Are you sure you want to remove this event?</div>
				</Alert>
			</>
		);
	}
}

export default CalendarEventModal;

import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

import UserService from "../../../../services/UserService";
import WidgetConfigService from "../../../../services/WidgetConfigService";
import withLocation from "../../../../components/common/WithLocation";

import Tabs from "../../../../components/common/Tabs";
import Tab from "../../../../components/common/Tab";
import General from "./General";
import Services from "./Services";
import BookingSlots from "./BookingSlots";

import { WIDGET_NAME, BOOKING_TABS } from "../../../../constants/WidgetConstants";

import "../../../../styles/css/scenes/widget-config.css";
import LocationService from "../../../../services/LocationService";

class BookingConfig extends Component {
	state = {
		selectedTab: BOOKING_TABS.general.id,
		widgetConfig: {}
	};

	componentDidMount() {
		this.fetchWidget();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async () => {
		await this.update({ selectedTab: null });
		await this.fetchWidget();
		await this.update({ selectedTab: BOOKING_TABS.general.id });
	};

	fetchWidget = async () => {
		let widget = await WidgetConfigService.findWidget({ locationId: UserService.getActiveLocation().id, name: WIDGET_NAME.embeddedBookingWidget });

		if (!widget) {
			return;
		}

		await this.update({ widgetConfig: widget });
	};

	onTabSelect = tab => {
		this.setState({ selectedTab: tab.id });
	};

	onConfigChange = async () => {
		await this.fetchWidget();
	};

	render() {
		const { selectedTab, widgetConfig } = this.state;

		if (!LocationService.isBookingsPermissible()) {
			return <Redirect to="/settings" />;
		}

		return (
			<>
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={BOOKING_TABS.general.id} value={BOOKING_TABS.general.value} />
					{widgetConfig.config && widgetConfig.config.useBookingSlot && <Tab id={BOOKING_TABS.bookingSlots.id} value={BOOKING_TABS.bookingSlots.value} />}
					<Tab id={BOOKING_TABS.services.id} value={BOOKING_TABS.services.value} />
				</Tabs>

				{BOOKING_TABS.general.id === selectedTab && <General onConfigChange={this.onConfigChange} />}
				{BOOKING_TABS.bookingSlots.id === selectedTab && <BookingSlots />}
				{BOOKING_TABS.services.id === selectedTab && <Services />}
			</>
		);
	}
}

export default withRouter(withLocation(BookingConfig));

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import "../../styles/css/components/commons/tabs.css";
import Tab from "./Tab";

class Tabs extends Component {
	render() {
		let { onSelect, selected, children, routing, tabs, removePadding } = this.props;

		const childrenWithProps = React.Children.map(children, child => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { onSelect, selected, routing });
			}
			return child;
		});

		let classes = ["dh-tabs"];

		if (removePadding) {
			classes.push("dh-tabs--no-padding");
		}

		if (routing) {
			classes.push("dh-tabs--routing");

			return (
				<>
					<div className={classes.join(" ")}>
						{tabs && tabs.map(tab => <Tab key={tab.id} id={tab.id} value={tab.value} {...{ onSelect, selected, routing }} />)}
					</div>
					{childrenWithProps}
				</>
			);
		}

		return <div className="dh-tabs">{childrenWithProps}</div>;
	}
}

export default Tabs;

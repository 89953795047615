import React, { Component } from "react";
import posed, { PoseGroup } from "react-pose";

import "../../styles/css/components/commons/menu.css";

const ContextMenu = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 10,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

export default class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		document.addEventListener("mousedown", this.onMouseDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	onMouseDown = async e => {
		let { show } = this.state;

		// If the user clicked on the "Menu" (ContextMenu/Dropdown) we should ignore the mouse down event and exit early.
		if (this.dropdown && this.dropdown.contains && this.dropdown.contains(e.target)) {
			return;
		}

		// If the contextContainer (AKA the trigger component) exists and the user clicked on it, while the dropdown is not showing, lettuce show the dropdown
		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !show) {
			await this.update({
				show: true
			});
		}
		// In all other cases, meaning the dropdown still exists on the page, but the user did not click on it, we should hide the dropdown
		else if (this.dropdown && this.dropdown.contains) {
			await this.update({
				show: false
			});
		}
	};

	onItemSelected = () => {
		this.update({ show: false });
	};

	getChildrenWithMiddlewareProps = () => {
		let { children } = this.props;

		// Retrieve all the children components we want to render
		return React.Children.map(children, child => {
			// Determine if the child element is valid
			if (React.isValidElement(child)) {
				// If it is valid, clone the element, but inject the onItemSelect function in this parent component as apart of the call to its own onSelect prop
				return React.cloneElement(child, {
					onSelect: () => {
						this.onItemSelected();
						child.props.onSelect();
					}
				});
			}

			return child;
		});
	};

	render() {
		let { show } = this.state;
		let { top, right, width, left, triggerComponent } = this.props;

		return (
			<>
				<div ref={ref => (this.contextContainer = ref)}>{triggerComponent}</div>
				<PoseGroup>
					{show && (
						<ContextMenu key="container" ref={ref => (this.dropdown = ref)} className="menu" style={{ top, right, left, width }}>
							{this.getChildrenWithMiddlewareProps()}
						</ContextMenu>
					)}
				</PoseGroup>
			</>
		);
	}
}

import UserService from "./UserService";

let supportChatInitialized = false;

let removed = false;

const SupportChatService = {
	/**
	 * Initialize Support Chat Widget
	 */
	init() {
		if (supportChatInitialized) {
			return;
		}

		const user = UserService.get();

		if (!user) {
			return;
		}

		let isTestCompany = UserService.get().Company.is_test_company;

		if (!isTestCompany) {
			this.initializedHubSpot();
			supportChatInitialized = true;
		}
	},

	/**
	 * Uninitialize Support Chat
	 */
	uninitializeChat() {
		try {
			window.HubSpotConversations.clear({ resetWidget: true });
		} catch (error) {
			console.log("SupportChatService - An error occurred trying to clear the HubSpot Chat.");
		}
	},

	/**
	 * Initializes the HubSpot Chat
	 */
	async initializedHubSpot() {
		const user = UserService.get();

		let script = document.createElement("script");
		script.setAttribute("src", "//js-na1.hs-scripts.com/20194369.js");
		script.setAttribute("type", "text/javascript");
		script.setAttribute("id", "hs-script-loader");
		script.setAttribute("defer", "");
		script.setAttribute("async", "");

		document.body.appendChild(script);

		var _hsq = (window._hsq = window._hsq || []);
		_hsq.push([
			"identify",
			{
				email: user.email,
				id: user.id
			}
		]);

		window.hsConversationsSettings = {
			loadImmediately: false,
			enableWidgetCookieBanner: false,
			disableAttachment: false,
			identificationEmail: user.email,
			identificationToken: user.hubSpot ? user.hubSpot.identificationToken : ""
		};

		window.hsConversationsOnReady = [
			() => {
				window.HubSpotConversations.on("widgetLoaded", payload => {
					window.HubSpotConversations.widget.open();
				});

				window.HubSpotConversations.on("widgetClosed", payload => {
					setTimeout(() => {
						window.HubSpotConversations.widget.remove();
						removed = true;
					}, 500);
				});
			}
		];
	},

	/**
	 * Show the HubSpot Chat, currently HubSpot does not support prefilling the compose view, A feature request has been made :)
	 */
	showNewMessage(prePopulatedContent = "") {
		try {
			// We grab the status of the widget
			let status = window.HubSpotConversations.widget.status();

			// If the widget is loaded then we use the refresh otherwise load the widget
			if (status.loaded) {
				window.HubSpotConversations.widget.refresh();
			} else {
				window.HubSpotConversations.widget.load();
			}
		} catch (error) {
			console.log("SupportChatService - An error occurred trying to load the HubSpot Chat.");
		}
	}
};

export default SupportChatService;

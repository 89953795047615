export const CREATE_LOCATION_STEPS = {
	gmbApi: {
		step: 1,
		headingText: "Let's create a new location",
		subHeadingText: "Find your business on Google My Business to make the set up faster.",
		headingIcon: "fa fa-magic fa-2x"
	},
	locationInfo: {
		step: 2,
		headingText: "Where is your business located?",
		subHeadingText: "Please fill out all the required fields (marked by a red star).",
		headingIcon: "fa fa-magic"
	},
	contactInfo: {
		step: 3,
		headingText: "Contact information",
		subHeadingText: "Where can people find you online?",
		headingIcon: "fa fa-magic"
	},
	selectSMSService: {
		step: 4,
		headingText: "Select SMS Service Provider",
		subHeadingText: "Please choose between the different sms services you would like to use",
		headingIcon: "fa fa-magic"
	},
	createSMSNumbers: {
		step: 5,
		headingText: "Order SMS Numbers",
		subHeadingText: "Search and order numbers from a provider or Skip to next step to Assign an existing number",
		headingIcon: "fa fa-magic"
	},
	smsNumbers: {
		step: 6,
		headingText: "Set up SMS numbers",
		subHeadingText:
			"Create or assign numbers that are used for DemandHub Messenger and Webchat. Remember that every location must have at least 1 'primary' number.",
		headingIcon: "fa fa-magic"
	},
	goals: {
		step: 7,
		headingText: "What are the goals?",
		subHeadingText: "Having goals is an easy way to measure how you are doing!",
		headingIcon: "fa fa-magic"
	},
	settings: {
		step: 8,
		headingText: "Configure settings",
		subHeadingText: "Select any of these that apply.",
		headingIcon: "fa fa-magic"
	},
	logos: {
		step: 9,
		headingText: "Logos",
		subHeadingText: "What are the url's of your logos?",
		headingIcon: "fa fa-magic"
	},
	integrations: {
		step: 10,
		headingText: "Google and Facebook integrations",
		subHeadingText: "Connect your DemandHub location to Google and Facebook.",
		headingIcon: "fa fa-magic"
	},
	connections: {
		step: 11,
		headingText: "GMB and Facebook Connections",
		subHeadingText: "Connect your location to GMB and Facebook to get latest reviews.",
		headingIcon: "fa fa-magic"
	},
	summary: {
		step: 12,
		headingText: "Summary",
		subHeadingText: "Here is everything that you have set for your location. Please review carefully.",
		headingIcon: "fa fa-smile"
	},
	complete: {
		step: 13,
		headingText: "Success!",
		subHeadingText: "Congratulations! The location has been set up properly. ",
		headingIcon: "fa fa-smile"
	}
};

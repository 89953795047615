import UserService from "./UserService";
import Kichiri from "./KichiriService";

const NPSService = {
	/**
	 * Create a nps request
	 * @param {String} name
	 * @param {Number} locationId
	 * @param {String} phone
	 * @param {String} email
	 * @param {Boolean} isAnonymous
	 * @param {String} language
	 *
	 * @returns
	 */
	async createNpsRequest({ name, locationId, phone, email, isAnonymous, language }) {
		try {
			let response = await Kichiri.nps_request.create({ name, locationId, phone, email, isAnonymous, language }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
			if (error.response && error.response.data.error) {
				return error.response.data;
			}
		}
		return null;
	},

	/**
	 * See if a nps request has been send to a phone or email
	 * @param {Number} locationId
	 * @param {String} phoneOrEmail
	 * @returns {Object} the last_sent field will give us a boolean to know if it has been sent before or not
	 */
	async lastSentNpsRequest(locationId, phoneOrEmail) {
		try {
			const isEmailOrPhoneParam = phoneOrEmail.indexOf("@") >= 0 ? "email" : "phone";

			let response = await Kichiri.nps_request.lastSent(
				{},
				{
					location: locationId,
					[isEmailOrPhoneParam]: phoneOrEmail
				},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch Nps suggestions
	 * @param {Integer} locationId
	 * @param {Integer} limit
	 * @param {Integer} offset
	 * @param {Integer} minScore
	 * @param {Integer} maxScore
	 * @param {String} dateRange
	 * @param {String} hasSuggestion
	 * @returns {Array} Nps suggestions
	 */
	async fetch({ locationId, limit, offset, minScore, maxScore, dateRange, hasSuggestion }) {
		try {
			let response = await Kichiri.nps.fetch({}, { locationId, limit, offset, minScore, maxScore, dateRange, hasSuggestion }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the count of how many nps suggestions there are in total given the filters
	 * @param {Integer} locationId
	 * @param {Integer} minScore
	 * @param {Integer} maxScore
	 * @param {String} dateRange
	 * @param {String} hasSuggestion
	 * @returns {Array} Nps suggestions
	 */
	async count({ locationId, minScore, maxScore, dateRange, hasSuggestion }) {
		try {
			let response = await Kichiri.nps.count({}, { locationId, minScore, maxScore, dateRange, hasSuggestion }, UserService.getAuthToken());
			return response.data.count;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

export default NPSService;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";
import UtilityService from "../../services/UtilityService";

import Spinners from "../../components/common/Spinners";
import Alert from "../../components/common/Alert";
import LoadingButton from "../../components/common/LoadingButton";
import PasswordInput from "../../components/common/PasswordInput";
import withLocation from "../../components/common/WithLocation";
import Input from "../../components/common/Input";

import { LOGIN_METHOD } from "../../constants/Users";
import { KEYS } from "../../constants/Messenger";
import config from "../../config/app/web-app.config";

import "../../styles/css/scenes/login.css";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogTitle: "",
			errorMsg: "",
			email: "",
			password: "",
			loggingIn: false,
			loginMethod: null,
			loginSource: "",
			loginToken: null,
			showGoogleSignIn: true
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		document.body.classList.add("dh-login-bg");
		UtilityService.checkAndSetDarkBackground();
	}

	componentWillUnmount() {
		document.body.classList.remove("dh-login-bg");
		document.body.classList.remove("Common-bg--dark");
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleEmailChange = event => {
		this.update({ email: event.target.value });
	};

	handlePasswordChange = ({ value }) => {
		this.update({ password: value });
	};

	onInputKeyDown = event => {
		if (event.keyCode !== KEYS.enter) {
			return;
		}

		let { email, password } = this.state;
		if (email && email.trim() && password && password.trim()) {
			this.handleLoginButton();
		}
	};

	handleLoginButton = async () => {
		let { email, password, loggingIn } = this.state;

		if (!email || !password) {
			return;
		}

		if (loggingIn) {
			return;
		}

		await this.update({ loginToken: null, loginMethod: LOGIN_METHOD.email.id, loginSource: "" });
		await this.login();
	};

	login = async () => {
		let { email, password, loginToken, loginMethod, loginSource } = this.state;
		try {
			await this.update({ loggingIn: true });

			// Pass login/password to the API, and get back an authentication token on success
			let response = await UserService.login({ email, password, loginToken, loginMethod, loginSource });

			if (response.isTwoFactorRequired && response.verifyToken) {
				// Cache the username and password for use on the Two Factor page (VerifyPin component)
				UserService.cacheUserCredentials({ email, password });

				let gotoUrl = `/verify/${response.verifyToken}`;

				let redirectUrl = new URLSearchParams(this.props.location.search).get("redirect_url");

				if (redirectUrl) {
					gotoUrl = `/verify/${response.verifyToken}?redirect_url=${redirectUrl}`;
				}

				this.props.history.push(gotoUrl);
				return;
			}

			let user = response;

			// Store in local storage
			UserService.set(user);

			// Add to ga tracking
			GAService.GASetUserId(UserService.get().id);

			await this.update({ loggingIn: false });
		} catch (error) {
			if (typeof error.response === "undefined") {
				this.update({ loggingIn: false, dialogTitle: "Error", errorMsg: "A network error occurred. Please try again." });
				return;
			}

			if (error.response.status === 401) {
				let errorMsg = "Sorry, the username or password is incorrect. Please try again.";
				if (loginMethod === LOGIN_METHOD.google.id) {
					errorMsg = "Sorry, that Google user is not authorized to log into DemandHub. Please try again.";
				}
				this.update({ loggingIn: false, dialogTitle: "Error", errorMsg });
			} else if (error.response.status === 403) {
				this.update({
					loggingIn: false,
					dialogTitle: "Error",
					errorMsg: "Sorry this account is inactive. Please contact support@demandhub.co to reactivate your account."
				});
			} else if (error.response.status === 429) {
				this.update({
					loggingIn: false,
					dialogTitle: "Too many attempts",
					errorMsg: "Sorry, too many login attempts. Please try again in 10 minutes."
				});
			} else {
				this.update({ loggingIn: false, dialogTitle: "Error", errorMsg: "An unexpected error occurred. Please try again." });
			}
		}
	};

	handleForgotPassword = () => {
		this.update({ dialogTitle: "Reset Password", errorMsg: 'Please contact <a href="mailto:support@demandhub.co">support@demandhub.co</a>' });
	};

	onGooglFailure = response => {
		ToastService.error("Google Sign In Failed.");
		console.log(`Google Sign In Failed.`);
		console.log(response);
	};

	onGoogleSignIn = async response => {
		try {
			let profile = jwt_decode(response.credential);
			response.profile = profile; // save it in the object that we stringify

			await this.update({
				email: profile ? profile.email : "",
				loginToken: response.credential,
				loginMethod: LOGIN_METHOD.google.id,
				loginSource: JSON.stringify(response)
			});
			await this.login();
		} catch (error) {
			console.log(error);
			ToastService.error("Error trying to sign in. Please try again.");
		}
	};

	render() {
		let { loggingIn } = this.state;

		if (loggingIn) {
			return (
				<div className="login Common__card">
					<div className="login__logo">
						<img className="login__logo__img" src="https://cdn.demandhub.co/img/logo/final-light-png.png" width="60%" alt="login-logo" />
					</div>
					<div className="login__header">Loading DemandHub...</div>
					<div className="login__loading">
						<div className="Common__spinnerdiv--center">
							<Spinners loading={true} type="circle" size="5px" />
						</div>
					</div>
				</div>
			);
		}

		let user = UserService.get();
		if (user !== null) {
			let ssoDomain = "demandhub.co";

			// Set a cookie for the authorization token as well (used when we are an SSO JWT provider for Hubspot)
			document.cookie = `authorization=${user.auth_token};max-age=86400;domain=${ssoDomain}`;

			// If there is a redirect_url query param, we redirect there instead of the root of the webapp
			let redirectUrl = new URLSearchParams(this.props.location.search).get("redirect_url");

			if (redirectUrl) {
				redirectUrl = decodeURIComponent(redirectUrl);
				window.location = redirectUrl;
				return null;
			}

			return <Redirect to={this.props.location.state || "/"} />;
		}

		let { errorMsg, dialogTitle, email, showGoogleSignIn } = this.state;

		return (
			<>
				<div className="login Common__card animated fadeIn">
					<div className="login__logo">
						<img className="login__logo__img" src="https://cdn.demandhub.co/img/logo/final-light-png.png" width="60%" alt="login-logo" />
					</div>
					<div className="login__header">Welcome to DemandHub</div>

					<div className="login__form">
						<Input
							name="email"
							id="email"
							type="text"
							placeholder="Email"
							onChange={this.handleEmailChange}
							onKeyDown={this.onInputKeyDown}
							value={email}
							required
						/>

						<PasswordInput id="password" onChange={this.handlePasswordChange} onKeyDown={this.onInputKeyDown} required="" autoComplete="current-password" />
						<LoadingButton
							componentClass="mb-button mb-button--slim mb-button--center login__login-btn"
							loading={loggingIn}
							onClick={this.handleLoginButton}
							type="submit"
							buttonId="loadingbutton"
							size="4px"
						>
							Sign In
						</LoadingButton>
					</div>

					{showGoogleSignIn && (
						<>
							<div className="login__divider" />
							<div className="login__sign-in-options">
								<GoogleOAuthProvider
									clientId={`${config.GOOGLE.GMB_API_CLIENT.CLIENT_ID}`}
									onScriptLoadError={error => {
										console.log(error);
										this.setState({ showGoogleSignIn: false });
									}}
								>
									<GoogleLogin
										onSuccess={this.onGoogleSignIn}
										onError={this.onGooglFailure}
										text="signin_with"
										context="signin"
										shape="rectangular"
										logo_alignment="center"
										width={228}
										cookiePolicy="single_host_origin"
									/>
								</GoogleOAuthProvider>
							</div>
						</>
					)}

					<div className="login__forgot-password">
						<Link to="/forgot/password" className="login__forgot-password__link">
							Forgot password?
						</Link>
					</div>
				</div>

				<Alert type="error" show={errorMsg !== ""} title={dialogTitle} confirm="OK" onClose={() => this.update({ errorMsg: "" })}>
					<div>{errorMsg}</div>
				</Alert>
			</>
		);
	}
}

export default withRouter(withLocation(Login));

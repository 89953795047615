import React, { Component } from "react";
import Select from "react-select";
import { X } from "lucide-react";

import UtilityService from "../../../../services/UtilityService";
import UserService from "../../../../services/UserService";
import InboxService from "../../../../services/InboxService";
import ToastService from "../../../../services/ToastService";
import ContactService from "../../../../services/ContactService";

import { STATE } from "../../../../constants/Messenger";

import "./list-bulk-controls.css";

class ListBulkControls extends Component {
	constructor(props) {
		super(props);

		this.state = {
			assignableStatuses: [],
			assignableInboxes: [],
			bulkActions: {
				status: "",
				inbox: -1
			},
			changesMade: false,
			loading: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		// Set loading to true, and reset dirty bit
		await this.update({ loading: true });

		// Fetch assignable statuses
		await this.fetchStatuses();

		// Fetch assignable inboxes
		await this.fetchAssignableInboxes();

		// Set loading to false
		await this.update({ loading: false });
	};

	async fetchStatuses() {
		let { currentStatus } = this.props;

		// Get the messenger states possible for a contact
		let statesAssignable = [];

		// Push each state in using Open/Closed as labels, and active/inactive as vals
		for (var key in STATE) {
			let statusName = key;
			let statusValue = STATE[key];

			// Don't include the deleted status
			if (statusName === "deleted") continue;

			// Create a status we can choose from
			statesAssignable.push({
				label: UtilityService.toTitleCase(statusName),
				value: statusValue
			});
		}

		// Update the statuses we can choose from
		await this.update({
			assignableStatuses: statesAssignable
		});
	}

	async fetchAssignableInboxes() {
		let locationId = UserService.getActiveLocation().id;
		let userId = UserService.get().id;

		// Fetch inboxes for this location
		let inboxes = await InboxService.fetchInboxes(locationId, userId);
		if (!inboxes) {
			return;
		}

		// Create options for the inbox select
		let inboxesObject = inboxes.map(inbox => {
			return {
				value: inbox.id,
				label: inbox.name
			};
		});
		inboxesObject.push({
			label: "Unassigned",
			value: 0
		});

		// Update the list of inboxes we could assign to
		this.update({
			assignableInboxes: inboxesObject
		});
	}

	// For <Select> changes
	onChangeSelect = (field_name, value) => {
		let { bulkActions } = this.state;
		bulkActions[field_name] = value;
		this.update({
			changesMade: true,
			bulkActions
		});
	};

	closeControls = () => {
		if (this.props.onCloseBulkControls) {
			this.props.onCloseBulkControls();
		}
	};

	isSaveDisabled = () => {
		let { loading, changesMade } = this.state;
		let { bulkActionContactsArray } = this.props;

		return loading || bulkActionContactsArray.length === 0 || !changesMade;
	};

	onSubmit = async () => {
		let { bulkActions } = this.state;
		let { bulkActionContactsArray } = this.props;

		if (this.isSaveDisabled()) {
			return;
		}

		try {
			let updateObject = {};

			// Check if we want to update the status
			if (bulkActions.status !== "") {
				updateObject.status = bulkActions.status;
			}

			// Check if we want to update the inbox
			if (bulkActions.inbox !== -1) {
				updateObject.inboxId = bulkActions.inbox;
			}

			if (Object.keys(updateObject).length !== 0) {
				updateObject.contactIds = bulkActionContactsArray;

				// Update the contacts
				await ContactService.bulkUpdateContacts(updateObject);
			}

			ToastService.info("Contacts updated successfully");
		} catch (e) {
			console.error(e);
			ToastService.error("Failed to update contacts");
		}

		// Close the controls
		if (this.props.onCloseBulkControls) {
			this.props.onCloseBulkControls(true);
		}
	};

	onSelectAllBulkAction = async () => {
		if (this.props.onSelectAllBulkAction) {
			this.props.onSelectAllBulkAction();
		}
	};

	render() {
		let { assignableStatuses, assignableInboxes, loading, bulkActions } = this.state;
		let { bulkActionContactsArray } = this.props;

		if (loading) {
			return null;
		}

		let status = bulkActions.status;
		let inboxId = bulkActions.inbox;

		// Create the readable label for the current status
		let assignableStatusLabel = assignableStatuses.find(theStatus => theStatus.value === status);
		assignableStatusLabel = typeof assignableStatusLabel === "undefined" ? "Change Status" : assignableStatusLabel.label;

		// Create the readable label for the currently assigned inbox
		let assignableInboxLabel = assignableInboxes.find(inbox => {
			if (inbox.value === inboxId) {
				return inbox.label;
			} else if (inbox.value === 0 && inboxId === null) {
				// if the inbox id is null, it is considered to be as "Unassigned"
				return inbox.label;
			} else {
				return null;
			}
		});

		assignableInboxLabel = !assignableInboxLabel ? "Change Inbox" : assignableInboxLabel.label;

		return (
			<div className="mb-list__bulk-controls-section__container">
				<div className="mb-list__bulk-controls-section__top">
					<div className="mb-list__bulk-controls-section__title">
						Bulk Actions <span className="mb-list__bulk-controls-section__subtitle">({bulkActionContactsArray.length} Contacts)</span>
					</div>
					<div className="mb-list__bulk-controls-section__close" onClick={this.closeControls}>
						<X />
					</div>
				</div>
				<div className="mb-list__bulk-controls-section__control-item">
					<div className="mb-list__bulk-controls-section__control-item__label">New Status</div>
					<Select
						id="status"
						name="status"
						options={assignableStatuses}
						value={{ label: assignableStatusLabel, value: status }}
						className="mb-list__bulk-controls-section__control-item__select"
						placeholder={"Contact Status"}
						onChange={newStatus => {
							this.onChangeSelect("status", newStatus.value);
						}}
					/>
				</div>
				<div className="mb-list__bulk-controls-section__control-item">
					<div className="mb-list__bulk-controls-section__control-item__label">Move to Inbox</div>
					<Select
						id="inbox"
						name="inbox"
						options={assignableInboxes}
						value={{ label: assignableInboxLabel, value: inboxId }}
						className="mb-list__bulk-controls-section__control-item__select"
						placeholder={"Inbox"}
						onChange={newInbox => {
							this.onChangeSelect("inbox", newInbox.value);
						}}
					/>
				</div>

				<div className="mb-list__bulk-controls-section__final_controls">
					<div className={`mb-button`} onClick={this.onSelectAllBulkAction}>
						Select All
					</div>
					<div className={`mb-button ${this.isSaveDisabled() ? "mb-button--disabled" : ""}`} onClick={this.onSubmit}>
						Save
					</div>
				</div>
			</div>
		);
	}
}

export default ListBulkControls;

import React from "react";
import PropTypes from "prop-types";
import "../../styles/css/components/commons/spinners.css";

/* 
    Usage: <Spinners loading={true} size="25px" type="circle-fade"/>
*/
const Spinners = ({ type, loading, size, color }) => {
	if (loading) {
		return <div className={`Spinners__${type}`} style={{ fontSize: size, color: color }} />;
	} else {
		return null;
	}
};

Spinners.propTypes = {
	loading: PropTypes.bool.isRequired,
	size: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired
};

export default Spinners;

import React from "react";
import moment from "moment";

import "./result.css";

const Result = props => {
	let { handler, data, selected } = props;

	let { name, medium, medium_data, content, inbox, created_at } = data;

	return (
		<div className={`mb-result ${selected ? "mb-result--active" : ""}`} onClick={() => handler(data)}>
			<div className="mb-result-top">
				<div className="mb-result-name">{name}</div>
				<div className={`mb-result-medium mb-result-medium--${medium}`}>{medium_data}</div>
				{inbox && <div className="mb-result-inbox">{inbox.name}</div>}
				<div className="mb-result-date">{moment(created_at).format("YYYY-MM-DD")}</div>
			</div>
			<div className="mb-result-bottom">{content || ""}</div>
		</div>
	);
};

export default Result;

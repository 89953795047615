import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";

import PaymentService from "../../../services/PaymentService";
import UserService from "../../../services/UserService";

import Alert from "../../../components/common/Alert";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";

import withLocation from "../../../components/common/WithLocation";

import { PAYMENT_STATUS, PAYMENT_TYPES } from "../../../constants/Payments";

import "./payment-request-details.css";
import ToastService from "../../../services/ToastService";
class PaymentRequestDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			paymentRequest: null,
			alertMessage: "",
			showConfirmAlert: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async location => {
		await this.update({ loading: true });
		await this.fetchPaymentRequest();
		await this.update({ loading: false });
	};

	async componentDidMount() {
		await this.update({ loading: true });
		await this.fetchPaymentRequest();
		await this.update({ loading: false });
	}

	async fetchPaymentRequest() {
		let paymentRequestId = this.props.match.params.paymentRequestId;

		let paymentRequest = await PaymentService.fetchPaymentRequest(paymentRequestId);

		await this.update({ paymentRequest });
	}

	onCharge = async () => {
		let { paymentRequest } = this.state;
		let { t } = this.props;

		await this.update({
			onConfirm: async confirmed => {
				await this.update({
					showConfirmAlert: false
				});

				if (!confirmed) {
					return;
				}

				let updatedPaymentRequest = await PaymentService.chargeCustomer(paymentRequest.id);

				if (!updatedPaymentRequest) {
					ToastService.error(t("An error occurred trying to charge customer."));
					return;
				}

				await this.update({ paymentRequest: updatedPaymentRequest });

				ToastService.info(t("Customer charged successfully."));
			},
			alertMessage: t("Are you sure you want to charge this customer?"),
			showConfirmAlert: true
		});
	};

	onCancel = async () => {
		let { paymentRequest } = this.state;
		let { t } = this.props;

		await this.update({
			onConfirm: async confirmed => {
				await this.update({
					showConfirmAlert: false
				});

				if (!confirmed) {
					return;
				}

				let updatedPaymentRequest = await PaymentService.cancelPayment(paymentRequest.id);

				if (!updatedPaymentRequest) {
					ToastService.error(t("An error occurred trying to cancel this payment request."));
					return;
				}

				await this.update({ paymentRequest: updatedPaymentRequest });

				ToastService.info(t("Payment request cancelled successfully."));
			},
			alertMessage: t("Are you sure you want to cancel this payment request?"),
			showConfirmAlert: true
		});
	};

	onRefund = async () => {
		let { paymentRequest } = this.state;
		let { t } = this.props;

		await this.update({
			onConfirm: async confirmed => {
				await this.update({
					showConfirmAlert: false
				});

				if (!confirmed) {
					return;
				}

				let updatedPaymentRequest = await PaymentService.processRefund(paymentRequest.id);

				if (!updatedPaymentRequest) {
					ToastService.error(t("An error occurred trying to refund this payment."));
					return;
				}

				await this.update({ paymentRequest: updatedPaymentRequest });

				ToastService.info(t("Payment refund sent for processing."));
			},
			alertMessage: t("Are you sure you want to refund this payment?"),
			showConfirmAlert: true
		});
	};

	onRequestPayment = async () => {
		let { paymentRequest } = this.state;
		let { t } = this.props;

		await this.update({
			onConfirm: async confirmed => {
				await this.update({
					showConfirmAlert: false
				});

				if (!confirmed) {
					return;
				}

				let newPaymentRequest = await PaymentService.createPaymentRequest({
					invoiceId: paymentRequest.Invoice.id,
					contactId: paymentRequest.Invoice.contact_id
				});

				if (!newPaymentRequest) {
					ToastService.error(t("An error occurred trying to request payment."));
					return;
				}

				ToastService.info(t("Payment request created successfully."));
			},
			alertMessage: t("Are you sure you want to request a payment for this estimate?"),
			showConfirmAlert: true
		});
	};

	renderDetails() {
		let { paymentRequest } = this.state;
		let { t } = this.props;

		return (
			<div className="prd-details">
				<div className="prd-details-info">
					<div className="prd-details-title">{t("Details")}</div>
					<div className="prd-details-item">
						<label>{t("Type")}</label>
						<div>{PAYMENT_TYPES[paymentRequest.type].display}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Status")}</label>
						<div>{PAYMENT_STATUS[paymentRequest.status].display}</div>
					</div>
					{paymentRequest.Invoice && (
						<>
							<div className="prd-details-item">
								<label>{t("Invoice ID")}</label>
								<div>{paymentRequest.Invoice.crm_invoice_id || paymentRequest.Invoice.id}</div>
							</div>
							<div className="prd-details-item">
								<label>{t("Invoice Date")}</label>
								<div>{moment(paymentRequest.Invoice.invoice_date).format("MMM Do, YYYY")}</div>
							</div>
							<div className="prd-details-item">
								<label>{t("Amount")}</label>
								<div>{PaymentService.formatCharge(paymentRequest.Invoice.total_amount)}</div>
							</div>
							<div className="prd-details-item">
								<label>{t("Paid Amount")}</label>
								<div>{PaymentService.formatCharge(paymentRequest.customer_paid_amount)}</div>
							</div>
							<div className="prd-details-item">
								<label>{t("Insurance Amount")}</label>
								<div>{PaymentService.formatCharge(paymentRequest.Invoice.insurance_amount)}</div>
							</div>
						</>
					)}
					<div className="prd-details-item">
						<label>{t("Name")}</label>
						<div>{paymentRequest.Contact.name}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Contact")}</label>
						<div>{paymentRequest.Contact.email || paymentRequest.Contact.phone}</div>
					</div>

					<div className="prd-details-item">
						<label>{t("Notes")}</label>
						<div>{paymentRequest.notes || t("No Notes")}</div>
					</div>
					{paymentRequest.Message && (
						<div className="prd-details-item">
							<label>{t("Message")}</label>
							<div className="prd-message">{paymentRequest.Message.content}</div>
						</div>
					)}
					<div className="prd-details-item">
						<label>{t("Allow A Tip?")}</label>
						<div>{paymentRequest.has_tips ? "Yes" : "No"}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Charge Later?")}</label>
						<div>{paymentRequest.charge_later ? "Yes" : "No"}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Charge Date")}</label>
						<div>{moment(paymentRequest.charge_date).format("MMM Do, YYYY")}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Invoice")}</label>
						<div>
							{paymentRequest.Invoice ? (
								<a href={PaymentService.getInvoiceDownloadLink(paymentRequest.id, paymentRequest.public_id)}>{t("Download Invoice")}</a>
							) : (
								t("N/A")
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderHeader() {
		let { t } = this.props;

		return (
			<div className="prd-invoice-items__list__record prd-invoice-items__list__record--header">
				<div className="prd-invoice-items__list__record__name">{t("Name")}</div>
				<div className="prd-invoice-items__list__record__amount">{t("Amount")}</div>
			</div>
		);
	}

	renderRecord(item, index) {
		return (
			<div key={index} className={`prd-invoice-items__list__record ${index % 2 === 0 ? "prd-invoice-items__list__record--odd" : ""}`}>
				<div className="prd-invoice-items__list__record__name">{item.name}</div>
				<div className="prd-invoice-items__list__record__amount">{PaymentService.formatCharge(item.amount)}</div>
			</div>
		);
	}

	renderItems() {
		let { paymentRequest } = this.state;

		let lineItems = PaymentService.generateDisplayLineItems(paymentRequest.Invoice);

		return (
			<>
				{this.renderHeader()}
				<div className="prd-invoice-items__list">{lineItems.map((item, index) => this.renderRecord(item, index))}</div>
			</>
		);
	}

	renderActions() {
		let { paymentRequest } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_payments;

		if (paymentRequest.type === PAYMENT_TYPES.estimate.id && allowUpdate) {
			return (
				<div className="prd-actions">
					<div className="prd-action-button" onClick={this.onRequestPayment}>
						{t("Request Payment")}
					</div>
				</div>
			);
		}

		return (
			<div className="prd-actions">
				{paymentRequest.status === PAYMENT_STATUS.confirmed.id && allowUpdate && (
					<div className="prd-action-button" onClick={this.onCharge}>
						{t("Charge Customer")}
					</div>
				)}
				{paymentRequest.status === PAYMENT_STATUS.not_paid.id && allowUpdate && (
					<div className="prd-action-button" onClick={this.onCancel}>
						{t("Cancel Payment")}
					</div>
				)}
				{paymentRequest.status === PAYMENT_STATUS.paid.id && allowUpdate && (
					<div className="prd-action-button" onClick={this.onRefund}>
						{t("Process Refund")}
					</div>
				)}
			</div>
		);
	}

	renderInvoiceItems() {
		let { t } = this.props;

		return (
			<div className="prd-invoice-items">
				<div className="prd-invoice-items__title">{t("Invoice Items")}</div>
				{this.renderItems()}
				{this.renderActions()}
			</div>
		);
	}

	renderTimeline() {
		let { paymentRequest } = this.state;
		let { t } = this.props;

		return (
			<div className="prd-timeline">
				<div className="prd-timeline-title">{t("Timeline")}</div>
				<div className="prd-timeline-details">
					{paymentRequest.payment_events.map(event => {
						return (
							<>
								<div key={`${event.id}-0`} className="prd-timeline-details-line" />
								<div key={`${event.id}-1`} className="prd-timeline-details-event">
									<div className="prd-timeline-details-event-name">
										{event.name} <br />
										{moment(event.created_at).format("ddd, MMM Do YYYY, h:mm a")}
									</div>
								</div>
							</>
						);
					})}
				</div>
			</div>
		);
	}

	render() {
		let { paymentRequest, loading, showConfirmAlert, onConfirm, alertMessage } = this.state;
		let { t } = this.props;

		if (loading || !paymentRequest) {
			return null;
		}

		let typeString = paymentRequest.type === PAYMENT_TYPES.estimate.id ? t("Estimate Details") : t("Payment Request Details");

		return (
			<Page>
				<Header title={typeString} onBackButtonClick={() => this.props.history.goBack()} />
				<div className="prd-content">
					{this.renderDetails()}
					<div className="prd-column">
						{this.renderTimeline()}
						{paymentRequest.Invoice ? this.renderInvoiceItems() : null}
					</div>
				</div>
				<Alert type="warning" show={showConfirmAlert} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={onConfirm}>
					<div>{alertMessage}</div>
				</Alert>
			</Page>
		);
	}
}

export default withLocation(withTranslation(null, { withRef: true })(PaymentRequestDetails));

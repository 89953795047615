import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import UserPreferences from "../../components/common/UserPreferences";
import MUPNotificationCard from "../../components/common/MUPNotificationCard";
import MUPNotificationHeader from "../../components/common/MUPNotificationHeader";

import { EMAIL_RECIPIENT_TYPE } from "../../constants/Users";

import "../../styles/css/scenes/manage-user-profile.css";

class ManageUserPreferences extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userId: 0,
			locations: this.props.locations,
			notifications: {},
			locationNotifications: {},
			isOpen: true,
			showOnLeaderboardOption: this.props.showOnLeaderboardOption || false,
			locationsRemovable: this.props.locationsRemovable || false
		};

		this.setupLocationNotifications();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.locations !== this.props.locations ||
			prevProps.isContactAssignedToOthersDisabled !== this.props.isContactAssignedToOthersDisabled ||
			prevProps.isUnassignedPushDisabled !== this.props.isUnassignedPushDisabled
		) {
			this.setupLocationNotifications(this.props.locations);
			this.setState({ locations: this.props.locations });
		}
	}

	setupLocationNotifications(locations) {
		if (!locations) {
			locations = this.state.locations;
		}

		// Iterate over the locations for the user and determine the active one
		for (let l of locations) {
			// TODO - We should not be changing these values on the state itself, but rather making a clone and adjust those values.

			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`] = {};
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].notifications = {};
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].isOpen = false;
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].collapseIcon = "glyphicon glyphicon-menu-down";
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].email_recipient_type = l.email_recipient_type;
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].daily_review_digest = l.daily_review_digest;
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].weekly_location_report = l.weekly_location_report;
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].show_on_leaderboard = l.show_on_leaderboard;
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].queue_deadline_report = l.queue_deadline_report;
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].notify_after_hours = l.notify_after_hours;
			// eslint-disable-next-line
			this.state.locationNotifications[`${l.id}`].notify_inbox_only = l.notify_inbox_only;

			Object.keys(l).forEach(key => {
				if (key.indexOf("contact_") === 0) {
					// Set the state with the valid location notification keys
					// eslint-disable-next-line
					this.state.locationNotifications[`${l.id}`].notifications[key] = l[key];
				}
			});
		}

		// If there is only one location, have it open by default
		if (locations.length === 1) {
			let l = locations[0];
			this.toggleFilterCollapse(null, l.id);
		}
	}

	removeLocation(event, locationId) {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.hasOwnProperty("removeLocationFromAssigned")) {
			this.props.removeLocationFromAssigned(locationId);
		}
	}

	updateNotifications = (locationId, notifications) => {
		// eslint-disable-next-line
		this.state.locationNotifications[`${locationId}`].notifications = notifications;
		this.setState({ locationNotifications: this.state.locationNotifications }, () => {
			this.updatePreferences();
		});
	};

	updatePreferences() {
		if (this.props.hasOwnProperty("handleManagePreferencesChange")) {
			this.props.handleManagePreferencesChange(this.state.locationNotifications);
		}
	}

	toggleFilterCollapse(event, locationId) {
		if (event !== null) {
			event.preventDefault();
			event.stopPropagation();
		}

		// eslint-disable-next-line
		this.state.locationNotifications[`${locationId}`].isOpen = !this.state.locationNotifications[`${locationId}`].isOpen;

		let isOpen = this.state.locationNotifications[`${locationId}`].isOpen;
		let icon = "glyphicon glyphicon-menu-up";
		if (!isOpen) {
			icon = "glyphicon glyphicon-menu-down";
		}

		// eslint-disable-next-line
		this.state.locationNotifications[`${locationId}`].collapseIcon = icon;
		this.setState({ locationNotifications: this.state.locationNotifications });
	}

	cardOnChange = (locationId, field, value) => {
		// eslint-disable-next-line
		if (!value) {
			this.state.locationNotifications[`${locationId}`][field] = !this.state.locationNotifications[`${locationId}`][field];
		} else {
			this.state.locationNotifications[`${locationId}`][field] = value.value;
		}
		this.setState(
			{
				locationNotifications: this.state.locationNotifications
			},
			() => {
				this.updatePreferences();
			}
		);
	};

	render() {
		const { isContactAssignedToOthersDisabled, isUnassignedPushDisabled, t } = this.props;
		return (
			<div className="row">
				<div className="col-xs-12">
					{this.state.locations.map(location => (
						<div key={location.id} className="mup-location">
							<button
								className="btn btn-default"
								style={{
									maxWidth: this.state.locationsRemovable ? "388px" : "422px",
									width: this.state.locationsRemovable ? "388px" : "422px",
									marginLeft: "-7px",
									overflow: "hidden",
									textOverflow: "ellipsis"
								}}
								onClick={event => {
									this.toggleFilterCollapse(event, location.id);
								}}
							>
								{`${location.name}, ${location.city}`}{" "}
								<small>
									<i className={this.state.locationNotifications[`${location.id}`].collapseIcon} />
								</small>
							</button>
							{this.state.locationsRemovable === true ? (
								<button
									id="unassign-location"
									className="btn btn-danger"
									style={{ maxWidth: "34px", width: "34px", marginLeft: "-3px" }}
									onClick={event => {
										this.removeLocation(event, location.id);
									}}
								>
									X
								</button>
							) : (
								""
							)}
							<Collapse in={this.state.locationNotifications[`${location.id}`].isOpen}>
								<div>
									<MUPNotificationHeader header={t("Email Updates")} />
									<MUPNotificationCard
										title={t("Email Recipient Type")}
										description={t("Receive email notifications and reports in specified way")}
										locationNotification={this.state.locationNotifications[`${location.id}`]}
										field="email_recipient_type"
										select={true}
										options={Object.values(EMAIL_RECIPIENT_TYPE)}
										value={{
											value: EMAIL_RECIPIENT_TYPE[this.state.locationNotifications[`${location.id}`].email_recipient_type].value,
											label: EMAIL_RECIPIENT_TYPE[this.state.locationNotifications[`${location.id}`].email_recipient_type].label
										}}
										placeholder={t("To, CC, or BCC...")}
										onChange={value => {
											this.cardOnChange(location.id, "email_recipient_type", value);
										}}
									/>
									<MUPNotificationCard
										title={t("Daily Digest")}
										description={t("An email containing activity from the last day")}
										locationNotification={this.state.locationNotifications[`${location.id}`]}
										field="daily_review_digest"
										onChange={() => {
											this.cardOnChange(location.id, "daily_review_digest");
										}}
									/>
									<MUPNotificationCard
										title={t("Weekly Report")}
										description={t("An email containing activity from the last week")}
										locationNotification={this.state.locationNotifications[`${location.id}`]}
										field="weekly_location_report"
										onChange={() => {
											this.cardOnChange(location.id, "weekly_location_report");
										}}
									/>
									{/* https://stackoverflow.com/questions/53048037/react-showing-0-instead-of-nothing-with-short-circuit-conditional-component */}
									{location.reviews_invites_queue ? (
										<MUPNotificationCard
											title={t("Invite Queue Report")}
											description={t("Receive an email before the deadline time for outstanding messages")}
											locationNotification={this.state.locationNotifications[`${location.id}`]}
											field="queue_deadline_report"
											onChange={() => {
												this.cardOnChange(location.id, "queue_deadline_report");
											}}
										/>
									) : null}
									{this.state.showOnLeaderboardOption && (
										<>
											<MUPNotificationHeader header={t("Leaderboard")} />
											<MUPNotificationCard
												title={t("Show On Leaderboard")}
												description={t("Show on the Leaderboard for this location")}
												locationNotification={this.state.locationNotifications[`${location.id}`]}
												field="show_on_leaderboard"
												onChange={() => {
													this.cardOnChange(location.id, "show_on_leaderboard");
												}}
											/>
										</>
									)}
									<UserPreferences
										locationId={location.id}
										updateNotifications={this.updateNotifications}
										notifications={this.state.locationNotifications[`${location.id}`].notifications}
										isContactAssignedToOthersDisabled={isContactAssignedToOthersDisabled}
										isUnassignedPushDisabled={isUnassignedPushDisabled}
									/>

									<MUPNotificationCard
										title={t("Notify me After Hours")}
										description={t("Allow DemandHub to notify you after business hours for new messages and leads for this location.")}
										locationNotification={this.state.locationNotifications[`${location.id}`]}
										field="notify_after_hours"
										onChange={() => {
											this.cardOnChange(location.id, "notify_after_hours");
										}}
									/>
									<MUPNotificationCard
										title={t("Inbox Only Visibility")}
										description={t("Only show conversations for inboxes you are assigned to for this location.")}
										locationNotification={this.state.locationNotifications[`${location.id}`]}
										field="notify_inbox_only"
										onChange={() => {
											this.cardOnChange(location.id, "notify_inbox_only");
										}}
									/>
									<div style={{ marginTop: "15px", fontStyle: "italic", width: "440px" }}>
										<small>{t("Desktop and Phone notifications are sent immediately.")}</small>
										<br />
										<small>{t("Email notifications are sent if a new message has not been responded to within 5 minutes.")}</small>
									</div>
									<hr style={{ border: "none" }} />
								</div>
							</Collapse>
						</div>
					))}
				</div>
				<div className="col-sm-12" />
			</div>
		);
	}
}
export default withTranslation(null, { withRef: true })(ManageUserPreferences);

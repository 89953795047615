import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Tour from "reactour";
import posed from "react-pose";
import queryString from "query-string";

import { MODE, STATE, MEDIUM, TOURS, ACTION_CHANNELS } from "../../constants/Messenger";

import UserService from "../../services/UserService";
import RealtimeService from "../../services/WebsocketsConnection";
import NotificationService from "../../services/NotificationService";
import TeamChatService from "../../services/TeamChatService";
import LocationService from "../../services/LocationService";
import GAService from "../../services/GAService";
import MessagesService from "../../services/MessagesService";
import CompanyService from "../../services/CompanyService";

import withLocation from "../../components/common/WithLocation";
import TeamChatScheduledMessagesList from "./Thread/TeamChatMessageList/TeamChatScheduledMessagesList";
import CustomerChatScheduledMessagesList from "./Thread/CustomerChatMessageList/CustomerChatScheduledMessagesList";

import Navbar from "./Navbar/Navbar";
import List from "./List/List";
import Thread from "./Thread/Thread";

import "./fonts/avenir-next.css";
import "./messenger-beta.css";

let stepsForMessengerBetaTour = [];

const Container = posed.div({
	visible: {
		y: 0,
		opacity: 1
	},
	hidden: {
		y: 0,
		opacity: 0
	}
});

class MessengerBeta extends Component {
	constructor(props) {
		super(props);

		let user = UserService.get();
		let location = UserService.getActiveLocation();

		this.state = {
			locationId: location.id,
			userId: user.id,
			currentConvo: null,
			mode: user.GroupPermission.view_customer_messages ? MODE.customer : MODE.team,
			needsMessengerBetaTour: !UserService.hasToured(TOURS.messengerBeta, user.id),
			showTeamChatScheduledMessages: false,
			showCustomerScheduledMessages: false
		};

		this.isVisible = false;
		this.listComponent = null;
		this.threadComponent = null;
		this.navbarComponent = null;

		stepsForMessengerBetaTour = [
			{
				selector: "",
				content: <div className="mb-tour-step">Welcome to the new DemandHub Messenger!</div>,
				stepInteraction: false
			},
			{
				selector: ".mb-navbar",
				content: <div className="mb-tour-step">You can navigate to your different conversations here ...</div>
			},
			{
				selector: ".mb-tour-3",
				content: <div className="mb-tour-step">Customer conversations can be filtered by who is assigned to them ...</div>
			},
			{
				selector: ".mb-tour-4",
				content: <div className="mb-tour-step">You can filter your Open and Closed customer conversations here ...</div>
			},
			{
				selector: ".mb-tour-5",
				content: <div className="mb-tour-step">Searching is just as easy as before ...</div>
			},
			{
				selector: ".mb-tour-6",
				content: <div className="mb-tour-step">... or start a new customer conversation ...</div>
			}
		];

		if (TeamChatService.isEnabled()) {
			stepsForMessengerBetaTour = stepsForMessengerBetaTour.concat([
				{
					selector: ".mb-tour-7",
					content: <div className="mb-tour-step">You can also chat with your team members using the all new TeamChat!</div>
				},
				{
					selector: ".mb-tour-8",
					content: <div className="mb-tour-step">Channels allow you to create purpose driven chats for colleagues in a department or division.</div>
				},
				{
					selector: ".mb-tour-9",
					content: <div className="mb-tour-step">You can also start a direct chat with your colleagues. You can have two or more people in a direct chat.</div>
				},
				{
					selector: "",
					content: <div className="mb-tour-step">You are ready to start using the all new DemandHub Messenger!</div>
				}
			]);
		} else {
			stepsForMessengerBetaTour.push({
				selector: "",
				content: <div className="mb-tour-step">You are ready to start using the all new DemandHub Messenger!</div>
			});
		}

		this.subscribe();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		// If we see that a contact has been updated, check if it's deleted or blocked
		NotificationService.subscribeOnce("contactUpdated", "MessengerBeta_component", contact => {
			if (contact.is_blocked || contact.status === "deleted") {
				// Set our current conversation to null
				/*

				// This causes issues. To fix in DH-2652

				this.update({
					currentConvo: null
				});
				*/
			}
		});

		this.isVisible = true;

		NotificationService.publish("collapseNav");

		// Fetch error codes and metadata, and store the information in an instance variable in messages service
		await MessagesService.storeProviderMetaData();

		this.consumeDeepLink();
	}

	componentWillUnmount() {
		this.isVisible = false;
	}

	consumeDeepLink = async () => {
		let { listComponent } = this;

		if (!this.props.location || !this.props.location.search) {
			return;
		}

		let { contactId, locationId } = queryString.parse(this.props.location.search);

		if (contactId && locationId && listComponent) {
			let locations = await CompanyService.fetchLocations(UserService.getActiveCompany().id);
			if (!locations || locations.length < 1) {
				return;
			}

			// Set the search term to a special keyword which allows us to search for a conversation via the publicId for the contact
			let searchTerm = `contact:${contactId}`;

			// A little ugly right now, but we technically perform the search twice, but it will avoid alot of gunda with the SearchResults components
			// listComponent.triggerSearch(searchTerm);

			let foundLocationId = locations.filter(l => l.public_id === locationId).map(l => l.id)[0];

			// Retrieve the intended deep link convo and set the Thread component to it
			let [deepLinkConvo] = await MessagesService.searchConversations({ searchTerm, locationId: foundLocationId });
			await this.update({
				currentConvo: deepLinkConvo
			});
		}
	};

	subscribe = () => {
		let interval = setInterval(async () => {
			console.log("Attempting to connect to DemandHub Realtime Service ...");

			if (!RealtimeService.isConnected) {
				return;
			}

			clearInterval(interval);

			// Customer Chat Subscriptions
			NotificationService.subscribeOnce("newMessage", "messengerBeta__subscribe", message => this.onNewMessage(message));
			RealtimeService.onMessageMarkedRead(this.onMessageMarkedRead);
			RealtimeService.onMessageMarkedUnread(this.onMessageMarkedUnread);

			// Team Chat Subscriptions
			NotificationService.subscribeOnce("newInternalMessage", "messengerBeta__subscribe", message => this.onInternalMessage(message));
			RealtimeService.onInternalMessageUserRemoved(this.onInternalMessageUserRemoved);

			// General Subscriptions
			RealtimeService.onTypingEvent(this.onTypingEvent);
		}, 500);
	};

	unsubscribe = () => {
		// Perform some extra business logic to disconnect.
	};

	onConvoSelect = async convo => {
		await this.update({
			mode: MODE.customer,
			currentConvo: convo
		});
	};

	isCurrentConversation(message) {
		let { currentConvo, mode } = this.state;
		if (mode === MODE.customer) {
			return currentConvo && currentConvo.contact_id === message.contact_id;
		} else if (mode === MODE.team) {
			return currentConvo && currentConvo.conversationId === message.conversation_id;
		}
	}

	onNewMessage = message => {
		if (!this.isVisible) {
			return;
		}

		let { listComponent, threadComponent } = this;
		let unread = 1;

		if (this.isCurrentConversation(message)) {
			threadComponent.onNewMessage(message);
			RealtimeService.markMessageRead(message);
			unread = 0;
		}

		// if (message.Contact) {
		// 	NotificationService.publish("contactUpdated", message.Contact);
		// }

		if (listComponent) {
			listComponent.onNewMessage(message, unread);
		}
	};

	onInternalMessage(message) {
		let { threadComponent } = this;

		// not used right now
		// let { conversationId, userId } = event;

		if (this.isCurrentConversation(message) && threadComponent) {
			threadComponent.onNewMessage(message);
		} else {
			NotificationService.publish("markInternalMessageRead", {});
		}
	}

	onMessageMarkedRead = message => {
		let { listComponent, threadComponent } = this;

		if (this.isCurrentConversation(message) && listComponent && threadComponent) {
			threadComponent.onNewMessage(message);
		}

		if (listComponent) {
			listComponent.onNewMessage(message);
		}
	};

	onMessageMarkedUnread = async messagesUnreadObject => {
		let { listComponent, threadComponent } = this;
		let { contactId, firstUnreadMessage, unreadCount } = messagesUnreadObject;

		if (this.isCurrentConversation(firstUnreadMessage) && listComponent && threadComponent) {
			await threadComponent.onNewMessage(firstUnreadMessage);
		}

		if (listComponent) {
			await listComponent.onMessageUnreads(contactId, unreadCount);
		}
	};

	onTypingEvent = typingEvent => {
		let { listComponent, threadComponent } = this;
		let { mode } = this.state;
		if (mode === MODE.customer && listComponent && threadComponent) {
			listComponent.onTypingEvent(typingEvent);
		}

		if (threadComponent) {
			threadComponent.onTypingEvent(typingEvent);
		}
	};

	onInternalMessageUserRemoved = event => {
		let { navbarComponent, threadComponent } = this;

		if (navbarComponent) {
			navbarComponent.onInternalMessageUserRemoved();
		}

		if (threadComponent) {
			threadComponent.onInternalMessageUserRemoved();
		}
	};

	onSend = (message, unread) => {
		let { listComponent } = this;
		let { mode } = this.state;
		if (mode === MODE.customer && listComponent) {
			listComponent.onNewMessage(message, unread);
			RealtimeService.markMessageRead(message);
		}
	};

	onStatusChange = (contactId, status) => {
		let { listComponent } = this;

		if (listComponent) {
			listComponent.onStatusChange(contactId, status);
		}
	};

	onLocationChanged = async () => {
		let { locationId } = this.state;

		let location = UserService.getActiveLocation();

		// Check if the location is actually being changed
		if (location.id !== locationId) {
			// Update the location which will be the prop for the list/nav component
			await this.update({
				locationId: location.id,
				currentConvo: null
			});

			// If the location has changed, we need to resubscribe to all the different chat related events
			this.subscribe();
		}
	};

	onNavSelect = async (mode, item, inboxId = null, contactId = null, params) => {
		let { userId } = this.state;

		if (mode === MODE.customer) {
			if (params && params.action === ACTION_CHANNELS.customer.scheduledCustomerMessages.id) {
				await this.update({
					mode,
					currentConvo: null,
					showTeamChatScheduledMessages: false,
					showCustomerScheduledMessages: true
				});
				return;
			}
			await this.listComponent.setFilter(item, inboxId);
			await this.listComponent.onChangeFilter({ status: STATE.open, contactId });
			await this.update({ showCustomerScheduledMessages: false, showTeamChatScheduledMessages: false });

			return;
		}

		if (mode === MODE.team) {
			if (params && params.action === ACTION_CHANNELS.team.scheduledTeamMessages.id) {
				await this.update({
					mode,
					currentConvo: null,
					showTeamChatScheduledMessages: true,
					showCustomerScheduledMessages: false
				});
				return;
			}

			await this.update({
				mode: mode,
				currentConvo: {
					medium: MEDIUM.demandhub.key,
					senderId: userId,
					conversationId: item.id,
					status: STATE.open,
					type: item.type,
					channelType: item.channel_type,
					name: item.name
				},
				showTeamChatScheduledMessages: false,
				showCustomerScheduledMessages: false
			});

			return;
		}
	};

	onSeeScheduledMessages = async convo => {
		let { userId } = this.state;

		await this.update({
			mode: MODE.team,
			currentConvo: {
				medium: MEDIUM.demandhub.key,
				senderId: userId,
				conversationId: convo.conversationId,
				status: STATE.open,
				type: convo.type,
				name: convo.name
			},
			showTeamChatScheduledMessages: true
		});
	};

	onMessengerBetaTourClosed = () => {
		let { userId } = this.state;

		UserService.completeTour(TOURS.messengerBeta, userId);

		this.update({
			needsMessengerBetaTour: false
		});
	};

	onCloseTeamChatScheduledMessageList = async convo => {
		let { userId } = this.state;

		await this.update({
			showTeamChatScheduledMessages: false,
			mode: MODE.team,
			currentConvo: {
				medium: MEDIUM.demandhub.key,
				senderId: userId,
				conversationId: convo.id,
				status: STATE.open,
				type: convo.type,
				name: convo.name
			}
		});
	};

	onCloseCustomerScheduledMessageList = async () => {
		await this.update({ showCustomerScheduledMessages: false });
	};

	renderMobileMessage() {
		return (
			<div className="mb-mobile">
				<h1>Looks like you are on mobile!</h1>
				<div className="mb-mobile__text">
					For the best experience, please download the DemandHub Mobile Messenger App for Android or iOS.
					<br />
					<br />
					<div className="messenger-store-logos">
						<a href="https://itunes.apple.com/ca/app/demandhub-messenger/id1438570354?mt=8">
							<img
								src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
								width="120px"
								alt="App Store Logo"
							/>
						</a>
						&nbsp;&nbsp;
						<a href="https://play.google.com/store/apps/details?id=com.demandhubmessenger" rel="noreferrer noopener">
							<img src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" width="150px" alt="Play Store Logo" />
						</a>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let { locationId, currentConvo, mode, needsMessengerBetaTour, showTeamChatScheduledMessages, showCustomerScheduledMessages } = this.state;

		if (!LocationService.isMessengerPermissible()) {
			return <Redirect to="/dashboard" />;
		}

		return (
			<>
				<Container className="messenger-beta" initialPose="hidden" pose="visible">
					<Navbar ref={ref => (this.navbarComponent = ref)} locationId={locationId} onSelect={this.onNavSelect} />
					<List
						show={mode === MODE.customer && !showCustomerScheduledMessages}
						ref={ref => {
							this.listComponent = ref;
							MessagesService.setMessengerListReference(ref);
						}}
						locationId={locationId}
						onConvoSelect={this.onConvoSelect}
						activeContactId={null}
					/>
					<Thread
						ref={ref => (this.threadComponent = ref)}
						locationId={locationId}
						convo={currentConvo}
						mode={mode}
						onSend={this.onSend}
						onStatusChange={this.onStatusChange}
						onSeeScheduledMessages={this.onSeeScheduledMessages}
						hide={showTeamChatScheduledMessages || showCustomerScheduledMessages}
					/>

					{showCustomerScheduledMessages && <CustomerChatScheduledMessagesList onClose={this.onCloseCustomerScheduledMessageList} />}

					{showTeamChatScheduledMessages && (
						<TeamChatScheduledMessagesList
							onClose={this.onCloseTeamChatScheduledMessageList}
							conversationId={currentConvo ? currentConvo.conversationId : null}
						/>
					)}
				</Container>
				{this.renderMobileMessage()}
				<Tour
					id="react-tour-messenger"
					steps={stepsForMessengerBetaTour}
					isOpen={needsMessengerBetaTour}
					onRequestClose={this.onMessengerBetaTourClosed}
					disableDotsNavigation={true}
					disableInteraction={true}
					rounded={10}
					lastStepNextButton={<div>OK!</div>}
					closeWithMask={false}
				/>
			</>
		);
	}
}

export default withRouter(withLocation(MessengerBeta));

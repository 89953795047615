import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import config from "../../config/app/web-app.config";

import GAService from "../../services/GAService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import SupportChatService from "../../services/SupportChatService";

import SearchInput from "../../components/common/SearchInput";
import withLocation from "../../components/common/WithLocation";
import ConnectionsCard from "./ConnectionsCard";

import { CRM_INTEGRATIONS, CRM_LOGO_URL } from "../../constants/CRMIntegration";

import "../../styles/css/scenes/connections.css";

class ConnectionsApps extends Component {
	constructor(props) {
		super(props);

		this.state = {
			appList: {},
			searchTerm: "",
			showConnectModal: false,
			modalAppId: null
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			appList: {},
			searchTerm: "",
			showConnectModal: false,
			modalAppId: null
		});
		await this.fetchData();
	};

	fetchData = async () => {
		let appList = await CrmIntegrationsService.fetchApps();
		await this.update({ appList });
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
	};

	onSupportOpen = appName => {
		let crmSupportMsg = "Hi there! I'm interested in connecting my CRM with " + appName + ". Can you help me with that?";
		SupportChatService.showNewMessage(crmSupportMsg);
	};

	openConnectionModal = async appId => {
		await this.update({
			showConnectModal: true,
			modalAppId: appId
		});
	};

	onHideModal = async () => {
		await this.update({
			showConnectModal: false,
			modalAppId: null
		});
	};

	render() {
		let { t } = this.props;
		let { appList, searchTerm } = this.state;

		if (!appList || !Object.keys(appList).length) {
			return null;
		}

		// Convert appList object to an array
		let appsArray = Object.values(appList);

		// Apply filter if searchTerm is present
		if (searchTerm) {
			appsArray = appsArray.filter(app => app.name.toLowerCase().includes(searchTerm.toLowerCase()));
		}

		return (
			<div className="connections">
				<div className="connections__inner">
					<div className="connections__search-section">
						<SearchInput placeholder={t("Search Apps...")} onChange={this.onSearchChange} />
					</div>
					<div className="connections__section">
						<div className="connections__section-list">
							{appsArray.map(app => {
								// Hide non public apps
								if (!app.public) {
									return null;
								}

								return (
									<ConnectionsCard
										key={app.id}
										title={app.name}
										description={app.description}
										logoUrl={`${config.CDN_URL}${CRM_LOGO_URL}${app.logo}`}
										actionText="Add Integration"
										onClick={() => {
											this.onSupportOpen(app.name);
										}}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(ConnectionsApps)));

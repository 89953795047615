import React, { Component } from "react";

import LocationAnalyticsTable from "./LocationAnalyticsTable";
import ReviewCountsOverYear from "./ReviewCountsOverYear";
import RatingsByLocation from "./RatingsByLocation";
import ReviewsByLocation from "./ReviewsByLocation";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";

import "../../../styles/css/scenes/analytics.css";

class LocationAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authToken: UserService.get() ? UserService.get().auth_token : "",
			chartDataLoading: true,
			reviewsByLocationLoading: true,
			locations: [],
			locationsReviews: [],
			chartData: [],
			locationsLoading: true,
			reviewsByLocationCsv: null,
			ratingsByLocationCsv: null,
			reviewCountCsv: null
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	render() {
		return (
			<Page>
				<Header title="Location Analytics" />

				<div className="dh-page__cards">
					<div className="analytics-page__split location-analytics__split">
						<div className="analytics-page__split__card">
							<ReviewsByLocation />
						</div>

						<div className="analytics-page__split__card">
							<RatingsByLocation />
						</div>
					</div>

					<ReviewCountsOverYear />

					<LocationAnalyticsTable />
				</div>
			</Page>
		);
	}
}

export default LocationAnalytics;

import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import LocationService from "../../services/LocationService";

import { TASK_COLORS, TASK_COLOR_DEFAULT, TASK_STATUS } from "../../constants/LocationConstants";

import UpdateTasksModal from "./UpdateTasksModal";

import "./task.css";
import "./fireworks.css";

class Task extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModalTasks: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onTaskUpdated = async () => {
		if (this.props.onTaskUpdate) {
			this.props.onTaskUpdate();
		}
	};

	handleEditTaskClick = async () => {
		await this.update({ showModalTasks: true });
	};

	handlePinToggle = async () => {
		let { task, t } = this.props;

		let taskId = task.id;
		let pinned = !task.pinned;
		let locationId = UserService.getActiveLocation().id;

		let response = await LocationService.updateTask({ locationId, taskId, pinned });
		if (!response) {
			ToastService.info(t("An error occured pinning the task."));
			return;
		}

		await this.onTaskUpdated();
	};

	handleMarkTaskDoneClick = async () => {
		let { task, t } = this.props;

		let taskId = task.id;
		let status = TASK_STATUS.done;
		let locationId = UserService.getActiveLocation().id;

		let response = await LocationService.updateTask({ locationId, taskId, status });
		if (!response) {
			ToastService.info(t("An error occured marking the task as done."));
			return;
		}

		await this.onTaskUpdated();
	};

	handleDeleteTaskClick = async () => {
		let { task, t } = this.props;

		let response = await LocationService.deleteTask({ taskId: task.id });

		if (!response) {
			ToastService.error(t(`Error deleting task.`));
			return;
		}

		ToastService.info(t(`Successfully deleted task.`));

		await this.onTaskUpdated();
	};

	onCopyToClipboard = () => {
		let { task, t } = this.props;
		let content = task.content;

		if (navigator.clipboard) {
			navigator.clipboard.writeText(content);
			ToastService.info(t(`Copied to clipboard!`));
			return;
		}

		//ie 11 hack
		if (window.clipboardData) {
			window.clipboardData.setData("text", content);
			ToastService.info(t("Copied to clipboard!"));
			return;
		}
	};

	onDuplicateTask = async () => {
		let { task, t } = this.props;

		let taskId = task.id;
		let locationId = UserService.getActiveLocation().id;

		let response = await LocationService.duplicateTask({ locationId, taskId });
		if (!response) {
			ToastService.info(t("An error occured duplicating the task."));
			return;
		}

		await this.onTaskUpdated();
	};

	handleEditColorClick = async () => {
		let { task, t } = this.props;

		let taskId = task.id;
		let locationId = UserService.getActiveLocation().id;

		let previousColor = task.color;
		if (!previousColor) {
			previousColor = TASK_COLOR_DEFAULT;
		}

		// The default color
		let newColor = TASK_COLOR_DEFAULT;

		// Try to choose the next colour
		if (TASK_COLORS.includes(previousColor)) {
			const currentIndex = TASK_COLORS.indexOf(previousColor);
			const nextIndex = (currentIndex + 1) % TASK_COLORS.length;
			newColor = TASK_COLORS[nextIndex];
		}

		let response = await LocationService.updateTask({ locationId, taskId, color: newColor });
		if (!response) {
			ToastService.info(t("An error occured changing the location task color."));
			return;
		}

		await this.onTaskUpdated();
	};

	onTaskSubmit = async () => {
		await this.onHideTaskModal();
		await this.onTaskUpdated();
	};

	onHideTaskModal = async () => {
		await this.update({ showModalTasks: false });
	};

	render() {
		let { task, t } = this.props;
		let { showModalTasks } = this.state;

		let user = UserService.get();
		let userId = user.id;

		let allowCreate = user.GroupPermission.create_tasks;
		let allowUpdate = user.GroupPermission.update_tasks;
		let allowDelete = user.GroupPermission.delete_tasks;

		let taskId = task.id;
		let title = task.title;
		let content = task.content;
		let taskUserId = task.user_id;
		let taskLastUpdated = moment(task.updated_at).format("MMMM Do YYYY, h:mm A");
		let pinned = task.pinned;
		let color = task.color ? task.color : TASK_COLOR_DEFAULT;
		let showEveryone = task.show_everyone;
		let status = task.status;
		let taskIsDone = status === TASK_STATUS.done;

		let isOwner = taskUserId === userId;

		let visibilityLabel = t("Only Me");
		if (showEveryone) {
			visibilityLabel = t("Everyone");
		}
		/* To implement in DH-2888
		else if (userShowList && userShowList.length > 0) {
			// visibilityLabel = "Me + Others"; 
		}
		*/

		let ownerLabel = t("Me");
		if (!isOwner) {
			ownerLabel = task.User.first_name;
		}

		let isEasterEgg = false;
		if (title.toLowerCase().includes("satty")) {
			isEasterEgg = true;
		}

		let additionalCss = "";
		if (taskIsDone) {
			additionalCss += " task__single-task--done ";
		}

		return (
			<>
				<div key={taskId} className={`task__single-task ${additionalCss}`} style={{ backgroundColor: color }}>
					{isEasterEgg && (
						<div class="task__fireworks">
							<div class="task__fireworks__before" />
							<div class="task__fireworks__after" />
						</div>
					)}
					{pinned && <div class="task__ribbon" />}
					<div className="task__task-controls">
						{isOwner && allowUpdate && (
							<>
								<div data-tip data-for={`pin-task-tooltip-${taskId}`} className="task__task-control fnctst-pin-task" onClick={this.handlePinToggle}>
									{pinned ? <Icon.Bookmark size="10" fill="#9EE0FF" /> : <Icon.Bookmark size="10" />}
								</div>
								<ReactTooltip id={`pin-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
									<div className="text-left">{pinned ? "Unpin" : "Pin"}</div>
								</ReactTooltip>
							</>
						)}

						{isOwner && allowUpdate && !taskIsDone && (
							<>
								<ReactTooltip id={`status-done-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
									<div className="text-left">{t("Mark As Done")}</div>
								</ReactTooltip>

								<div
									data-tip
									data-for={`status-done-task-tooltip-${taskId}`}
									className="task__task-control fnctst-edit-task-status-done"
									onClick={this.handleMarkTaskDoneClick}
								>
									<Icon.CheckCircle size="10" />
								</div>
							</>
						)}

						{/* Since this doesn't work for tasks, let's hide it for now.
						
						isOwner && allowUpdate && (
							<>
								<ReactTooltip id={`visibility-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
									<div className="text-left">Change Visibility</div>
								</ReactTooltip>

								<div
									data-tip
									data-for={`visibility-task-tooltip-${taskId}`}
									className="task__task-control fnctst-edit-note-visibility"
									onClick={this.handleEditTaskClick}
								>
									{showEveryone ? <Icon.Eye size="10" fill="#9EE0FF" /> : <Icon.Eye size="10" />}
								</div>
							</>
						)*/}

						{isOwner && allowUpdate && (
							<>
								<div data-tip data-for={`color-task-tooltip-${taskId}`} className="task__task-control" onClick={this.handleEditColorClick}>
									<Icon.Tag size="10" />
								</div>
								<ReactTooltip id={`color-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
									<div className="text-left">{t("Change Colour")}</div>
								</ReactTooltip>
							</>
						)}

						<div data-tip data-for={`clipboard-task-tooltip-${taskId}`} className="task__task-control" onClick={this.onCopyToClipboard}>
							<Icon.Clipboard size="10" />
						</div>
						<ReactTooltip id={`clipboard-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
							<div className="text-left">{t("Copy To Clipboard")}</div>
						</ReactTooltip>

						{allowCreate && (
							<>
								<div data-tip data-for={`copy-task-tooltip-${taskId}`} className="task__task-control fnctst-duplicate-task" onClick={this.onDuplicateTask}>
									<Icon.Copy size="10" />
								</div>
								<ReactTooltip id={`copy-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
									<div className="text-left">{t("Duplicate Task")}</div>
								</ReactTooltip>
							</>
						)}

						{isOwner && allowUpdate && (
							<>
								<div data-tip data-for={`edit-task-tooltip-${taskId}`} className="task__task-control fnctst-edit-task" onClick={this.handleEditTaskClick}>
									<Icon.Edit2 size="10" />
								</div>
								<ReactTooltip id={`edit-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
									<div className="text-left">{t("Edit")}</div>
								</ReactTooltip>
							</>
						)}

						{isOwner && allowDelete && (
							<>
								<div data-tip data-for={`delete-task-tooltip-${taskId}`} className="task__task-control fnctst-delete-task" onClick={this.handleDeleteTaskClick}>
									<Icon.Trash2 size="10" />
								</div>
								<ReactTooltip id={`delete-task-tooltip-${taskId}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
									<div className="text-left">{t("Delete")}</div>
								</ReactTooltip>
							</>
						)}
					</div>
					<div className="task__task-title">{title}</div>
					<span className="task__task-content">
						<ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
					</span>
					{/* Since this doesn't really work for tasks, let's hide this for now.
						<div className="task__ownership">
							Visibility: {visibilityLabel}, Owner: {ownerLabel}
						</div>
					*/}
					<div className="task__bottom__info">
						{t("Status")}: {status}
					</div>
					<div className="task__bottom__info">
						{t("Updated")}: {taskLastUpdated}
					</div>
				</div>
				<UpdateTasksModal show={showModalTasks} taskId={taskId} createMode={false} onSubmit={this.onTaskSubmit} onHide={this.onHideTaskModal} />
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(Task);

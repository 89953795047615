import React from "react";
import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import DHSelect from "../../../components/common/DHSelect";
import { withTranslation } from "react-i18next";

import { FIREWALL_MATCH_TYPE, FIREWALL_TYPE, FIREWALL_FILTERS } from "../../../constants/Settings";

import FirewallService from "../../../services/FirewallService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";

class EditRuleModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			description: "",
			value: "",
			type: { value: FIREWALL_TYPE.phone.id, label: FIREWALL_TYPE.phone.value },
			matchType: { value: FIREWALL_MATCH_TYPE.exact.id, label: FIREWALL_MATCH_TYPE.exact.value },

			edited: false
		};
	}

	componentDidUpdate = async prevProps => {
		if (prevProps.show !== this.props.show) {
			if (this.props.ruleId) {
				await this.fetchFirewall(this.props.ruleId);
			} else {
				await this.resetComponent();
			}
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({
			name: "",
			description: "",
			value: "",
			type: FIREWALL_TYPE.phone.id,
			matchType: FIREWALL_MATCH_TYPE.exact.id
		});
	};

	fetchFirewall = async id => {
		let firewall = await FirewallService.fetchFirewallRule({ firewallId: id });

		await this.update({
			name: firewall.name,
			description: firewall.description,
			value: firewall.value,
			type: { value: firewall.field, label: FIREWALL_TYPE[firewall.field] ? FIREWALL_TYPE[firewall.field].value : firewall.field },
			matchType: {
				value: firewall.match_type,
				label: FIREWALL_MATCH_TYPE[firewall.match_type] ? FIREWALL_MATCH_TYPE[firewall.match_type].value : firewall.match_type
			}
		});
	};

	onSave = async () => {
		let { t } = this.props;

		if (!this.canSave()) {
			return;
		}

		let { ruleId, onSave, type: selectedFilter } = this.props;
		let { name, description, value, type, matchType } = this.state;

		let success = null;
		if (ruleId) {
			success = await FirewallService.update({
				firewallId: ruleId,
				locationId: UserService.getActiveLocation().id,
				name,
				description,
				value,
				field: type.value,
				matchType: matchType.value
			});
		} else {
			let rule = { name, description, value, field: type.value, matchType: matchType.value };
			if (selectedFilter === FIREWALL_FILTERS.location.id) {
				rule.locationId = UserService.getActiveLocation().id;
			} else if (selectedFilter === FIREWALL_FILTERS.company.id) {
				rule.companyId = UserService.getActiveCompany().id;
			}
			success = await FirewallService.create(rule);
		}

		if (success) {
			ToastService.info(ruleId ? t("Rule updated.") : t("Successfully created."));
		} else {
			ToastService.error(t("Failed to save. Please try again."));
		}

		if (onSave) {
			onSave();
		}
	};

	handleGenericEventHandler = (event, name) => {
		if (!name) {
			name = event.target.name;
		}
		let value = event.target ? event.target.value : event;
		this.update({ [name]: value, edited: true });
	};

	canSave = () => {
		let { edited, name, description, value, type, matchType } = this.state;

		if (
			edited &&
			name.length > 0 &&
			description.length > 0 &&
			value.length > 0 &&
			type &&
			type.value &&
			type.value.length > 0 &&
			matchType &&
			matchType.value &&
			matchType.value.length > 0
		) {
			return true;
		}

		return false;
	};

	render = () => {
		let { show, ruleId, onHide, t } = this.props;
		let { name, description, value, type, matchType } = this.state;

		return (
			<Modal show={show} onHide={onHide} title={ruleId ? t("Update rule") : t("Create Rule")}>
				<div style={{ width: "100%" }}>
					<Input id="name" name="name" label={t("Name")} type="text" onChange={e => this.handleGenericEventHandler(e)} value={name} />

					<Input
						id="description"
						name="description"
						label={t("Description")}
						type="textarea"
						onChange={e => this.handleGenericEventHandler(e)}
						value={description}
						autoComplete="off"
						maxLength={255}
					/>

					<Input id="value" name="value" label={t("Value")} type="text" onChange={e => this.handleGenericEventHandler(e)} value={value} autoComplete="off" />

					<DHSelect
						id="type"
						name="type"
						label={t("Type")}
						value={type}
						options={Object.values(FIREWALL_TYPE).map(aType => {
							return { value: aType.id, label: aType.value };
						})}
						onChange={e => this.handleGenericEventHandler(e, "type")}
					/>

					<DHSelect
						id="match_type"
						name="matchType"
						label={t("Match Type")}
						value={matchType}
						options={Object.values(FIREWALL_MATCH_TYPE).map(aType => {
							return { value: aType.id, label: aType.value };
						})}
						onChange={e => this.handleGenericEventHandler(e, "matchType")}
					/>

					<div className="modal__actions">
						<div className={`mb-button mb-button--fit ${!this.canSave() ? "mb-button--disabled" : ""}`} onClick={this.onSave}>
							{t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(EditRuleModal);

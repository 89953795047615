import React, { Component } from "react";
import "../../styles/css/scenes/manage-user-profile.css";

class MUPNotificationHeader extends Component {
	render() {
		const { header } = this.props;
		return (
			<div className="text-center mup-notifications-card-header">
				<strong>{header}</strong>
			</div>
		);
	}
}

export default MUPNotificationHeader;

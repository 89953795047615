import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import AppConfig from "../../config/app/web-app.config";

import Kichiri from "../../services/KichiriService";
import Spinners from "../../components/common/Spinners";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { upload } from "../../services/FileUploadService";

import ProfilePictureLegacy from "../../components/common/ProfilePictureLegacy";
import LanguageDropdown from "../../components/common/LanguageDropdown";
import ManageUserPreferences from "../../components/common/ManageUserPreferences";
import Alert from "../../components/common/Alert";
import Checkbox from "../../components/common/Checkbox";
import PasswordInput from "../../components/common/PasswordInput";
import Signature from "../../components/common/Signature";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";

import { CODES_TO_LABEL } from "../../constants/LanguageConstants";

import { USER_PASSWORD_REQUIREMENTS } from "../../constants/Password";

import "../../styles/css/scenes/manage-user-profile.css";

// const EMAIL_IFRAME_STYLES = {
// 	backgroundColor: "#ffffff",
// 	border: "1px solid #e5e6e7",
// 	borderRadius: "5px",
// 	width: "100%",
// 	minWidth: "100%",
// 	height: "130px",
// 	background: "white"
// };

class ManageUserProfile extends Component {
	constructor(props) {
		super(props);
		let user = UserService.get();

		this.state = {
			userId: user ? `${user.id}` : 0,
			groupId: user ? `${user.group_id}` : 0,
			firstName: user ? `${user.first_name}` : "",
			lastName: user ? `${user.last_name}` : "",
			phone: user ? `${user.phone}` : "",
			language: { value: UserService.getUserLanguage(), label: CODES_TO_LABEL[UserService.getUserLanguage()] },
			notifications: {},
			assignedLocations: [],

			profilePictureFile: user ? `${user.profile_picture_url}` : "",
			profilePictureError: false,

			enableMessengerSignature: user.enable_messenger_signature ? user.enable_messenger_signature : false,
			messengerSignature: user.messenger_signature ? user.messenger_signature : "",
			messengerHtmlSignature: user.messenger_html_signature ? user.messenger_html_signature : "",
			showHTMLSignaturePreview: user.messenger_html_signature ? true : false,

			messengerClickToSend: user.messenger_click_to_send ? true : false,

			password: "",
			confirmPassword: "",
			passwordPassedRequirements: true, // true since by default the field is empty
			passwordMismatch: false,
			passwordMismatchInline: false,
			passwordRequirementsError: false,
			passwordRequirements: USER_PASSWORD_REQUIREMENTS,

			saveError: false,
			saving: false,
			saveSuccess: false,
			messengerConversationNudging: user.messenger_conversation_nudging ? true : false,
			messengerShowReplySuggestions: user.messenger_show_reply_suggestions ? true : false,
			enableTours: user.enable_tours ? true : false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchAssignedLocations();
	}

	async fetchAssignedLocations() {
		let userId = this.state.userId;
		if (userId === 0) {
			console.log(`Unable to get correct user id`);
			return;
		}

		let userLocations = await UserService.getUserLocations(userId);

		await this.update({
			assignedLocations: userLocations
		});
	}

	handlePasswordChange = ({ value, passed }) => {
		const { confirmPassword } = this.state;

		this.update({
			passwordMismatchInline: confirmPassword !== value,
			password: value,
			passwordPassedRequirements: passed
		});
	};

	handleConfirmPasswordChange = ({ value, passed }) => {
		const { password } = this.state;

		this.update({
			passwordMismatchInline: password !== value,
			confirmPassword: value
		});
	};

	handleFirstNameChange(event) {
		let value = event.target.value;
		this.update({ firstName: value });
	}

	handleLastNameChange(event) {
		let value = event.target.value;
		this.update({ lastName: value });
	}

	handlePhoneChange(event) {
		this.update({ phone: event.target.value });
	}

	handleProfilePictureOnChange(newProfilePictureFile) {
		this.update({ profilePictureFile: newProfilePictureFile });
	}

	handleLanguageOnChange(newValue) {
		this.update({ language: newValue });
	}

	handleChangeEnableMessengerSignature = event => {
		const status = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		this.update({ enableMessengerSignature: status });
	};

	handleSignatureOnChange = newValue => {
		this.update({ messengerSignature: newValue });
	};

	handleHtmlSignatureOnChange = newValue => {
		this.update({ messengerHtmlSignature: newValue });
	};

	handleHtmlSignaturePreviewToggle = () => {
		let { showHTMLSignaturePreview } = this.state;

		this.update({ showHTMLSignaturePreview: !showHTMLSignaturePreview });
	};

	handleChangeEnableTours = event => {
		const status = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		this.update({ enableTours: status });
	};

	handleSaveUserClick = async event => {
		event.preventDefault();
		event.stopPropagation();

		const {
			userId,
			password,
			confirmPassword,
			passwordPassedRequirements,
			firstName,
			lastName,
			phone,
			groupId,
			profilePictureFile,
			language,
			enableMessengerSignature,
			messengerSignature,
			messengerHtmlSignature,
			messengerClickToSend,
			messengerConversationNudging,
			messengerShowReplySuggestions,
			enableTours
		} = this.state;
		const authToken = UserService.get() ? UserService.get().auth_token : "";
		const company = UserService.getActiveCompany();

		await this.update({ saving: true });

		// Get all of the User Location objects from the backend
		let assignedUserLocations = this.state.assignedLocations;

		// Set up the user data (but don't send the password by default)
		const userData = {
			userId,
			group: groupId,
			company: company.id,
			first_name: firstName.trim(),
			last_name: lastName.trim(),
			phone,
			language: language.value,
			assigned_user_locations: assignedUserLocations,
			enable_messenger_signature: enableMessengerSignature,
			messenger_signature: messengerSignature,
			messenger_html_signature: messengerHtmlSignature,
			messenger_click_to_send: messengerClickToSend,
			messenger_conversation_nudging: messengerConversationNudging,
			messenger_show_reply_suggestions: messengerShowReplySuggestions,
			enable_tours: enableTours
		};

		// Only send the password if it is not blank
		if (password !== "") {
			userData.password = password;
		}

		// Make sure the passwords match (if it's being updated)
		if (password !== "" && password !== confirmPassword) {
			await this.update({ passwordMismatch: true, saving: false });
			return;
		}

		// If the password does not meet requirements
		if (password !== "" && !passwordPassedRequirements) {
			await this.update({ passwordRequirementsError: true, saving: false });
			return;
		}

		const originalProfileUrl = UserService.get() ? UserService.get().profile_picture_url : "";

		// Save the state data
		try {
			// Image related conditional logic
			try {
				// If we are setting our profile image to nothing but we originally have something stored
				if (profilePictureFile === "" && originalProfileUrl !== "") {
					// Delete the profile image
					await this.removeProfilePicture();
				}
				// If we are setting our profile image to something new, and previously had one
				else if (profilePictureFile !== "" && originalProfileUrl !== "" && profilePictureFile !== originalProfileUrl) {
					// Delete the original profile image
					await this.removeProfilePicture();
					// Upload this new one
					await this.uploadProfilePicture();
				}
				// If we are upload a new image for the first time
				else if (profilePictureFile !== "" && originalProfileUrl === "") {
					// Upload this new one
					await this.uploadProfilePicture();
				}
			} catch (error) {
				console.log(error);
				await this.update({ profilePictureError: true, saving: false });
				return;
			}

			// Update the user
			let userResponseData = await UserService.updateUser(userData);

			// Update user data locally
			const user = UserService.get();

			if (user) {
				user.first_name = userResponseData.first_name;
				user.last_name = userResponseData.last_name || "";
				user.phone = userResponseData.phone;
				user.profile_picture_url = userResponseData.profile_picture_url;
				user.language = userResponseData.language;
				user.enable_messenger_signature = userResponseData.enable_messenger_signature;
				user.messenger_signature = userResponseData.messenger_signature;
				user.messenger_html_signature = userResponseData.messenger_html_signature;
				user.messenger_click_to_send = userResponseData.messenger_click_to_send;
				user.messenger_conversation_nudging = userResponseData.messenger_conversation_nudging;
				user.messenger_show_reply_suggestions = userResponseData.messenger_show_reply_suggestions;
				user.enable_tours = userResponseData.enable_tours;
				UserService.set(user);
			}

			await this.update({ saving: false, saveSuccess: true });
		} catch (error) {
			console.log(error);
			await this.update({ saveError: true, saving: false });
		}
	};
	async removeProfilePicture() {
		try {
			const { userId } = this.state;

			await UserService.removeProfilePicture(userId);
			return;
		} catch (error) {
			console.log(error);
		}
	}

	async uploadProfilePicture() {
		const { profilePictureFile, userId } = this.state;
		const authToken = UserService.get() ? UserService.get().auth_token : "";
		try {
			await upload(profilePictureFile, `Profile picture for ${userId}`, `${AppConfig.API_SERVER}/api/user/${userId}/profile_picture/update`, authToken);
		} catch (e) {
			throw e;
		}
	}

	onNotificationChange = event => {
		let { name, checked } = event.target;

		// TODO - We should fix this to not mutate the state directly
		// eslint-disable-next-line
		this.state.notifications[name] = checked;

		this.update({
			notifications: this.state.notifications
		});
	};

	handleManagePreferencesChange = locationPreferences => {
		let assignedLocations = this.state.assignedLocations;
		for (let i = 0; i < assignedLocations.length; i++) {
			const location = assignedLocations[i];
			location.email_recipient_type = locationPreferences[`${location.id}`].email_recipient_type;
			location.daily_review_digest = locationPreferences[`${location.id}`].daily_review_digest;
			location.weekly_location_report = locationPreferences[`${location.id}`].weekly_location_report;
			location.show_on_leaderboard = locationPreferences[`${location.id}`].show_on_leaderboard;
			location.queue_deadline_report = locationPreferences[`${location.id}`].queue_deadline_report;

			location.notify_after_hours = locationPreferences[`${location.id}`].notify_after_hours;
			location.notify_inbox_only = locationPreferences[`${location.id}`].notify_inbox_only;

			Object.keys(location).forEach(key => {
				if (key.indexOf("contact_") === 0) {
					location[key] = locationPreferences[`${location.id}`].notifications[key];
				}
			});
		}
		this.update({
			assignedLocations: assignedLocations
		});
	};

	handleToggleField(value, field) {
		this.update({
			[field]: value
		});
	}

	render() {
		if (this.state.saving) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}
		const {
			firstName,
			lastName,
			password,
			confirmPassword,
			phone,
			saveError,
			saveSuccess,
			passwordMismatch,
			profilePictureError,
			passwordMismatchInline,
			passwordRequirementsError,
			language,
			enableMessengerSignature,
			messengerSignature,
			messengerHtmlSignature,
			messengerClickToSend,
			messengerConversationNudging,
			passwordRequirements,
			messengerShowReplySuggestions,
			enableTours
		} = this.state;
		let { t } = this.props;

		const user = UserService.get();

		return (
			<Page>
				<Header title={t("Manage User Profile")} />
				<div className="manage-user-page">
					<div className="row">
						<div className="ibox float-e-margins">
							<form className="form-horizontal">
								<ProfilePictureLegacy handleProfilePictureOnChange={profilePictureFile => this.handleProfilePictureOnChange(profilePictureFile)} />

								<div className="form-group">
									<label className="col-sm-2 control-label">{t("First Name")}</label>
									<div className="col-sm-10">
										<input
											name="first_name"
											id="first_name"
											type="text"
											onChange={event => this.handleFirstNameChange(event)}
											value={firstName}
											className="Common__input"
										/>
									</div>
								</div>
								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Last Name")}</label>
									<div className="col-sm-10">
										<input
											name="last_name"
											id="last_name"
											type="text"
											onChange={event => this.handleLastNameChange(event)}
											value={lastName}
											className="Common__input"
										/>
									</div>
								</div>
								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Phone")}</label>
									<div className="col-sm-10">
										<input name="phone" id="phone" type="text" onChange={event => this.handlePhoneChange(event)} value={phone} className="Common__input" />
									</div>
								</div>
								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Password")}</label>
									<div className="col-sm-10">
										<PasswordInput
											name="form_password"
											id="form_password"
											onChange={this.handlePasswordChange}
											value={password}
											requirements={passwordRequirements}
										/>
										<span className="help-block m-b-none">{t("Leave these fields blank if you are not creating or updating your password.")}</span>
									</div>
								</div>

								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Confirm Password")}</label>
									<div className="col-sm-10">
										<PasswordInput
											name="form_confirm_password"
											id="form_confirm_password"
											onChange={this.handleConfirmPasswordChange}
											value={confirmPassword}
										/>
										{passwordMismatchInline && <span className="help-block text-danger fa fa-times">&nbsp;{t("Passwords do not match.")}</span>}
										<span className="help-block m-b-none">{t("Please re-enter the password (if creating or updating the password)")}.</span>
									</div>
								</div>
								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Language")}</label>
									<div className="col-sm-10">
										<LanguageDropdown name="language" id="language" onChange={newValue => this.handleLanguageOnChange(newValue)} value={language} required />
									</div>
								</div>
								<br />
								<div className="form-group manage-user__click-to-send">
									<label className="col-sm-2 control-label">{t("Message Sending Behaviour")}</label>
									<div className="col-sm-10">
										<div className="manage-user__click-to-send__option" onClick={() => this.handleToggleField(false, "messengerClickToSend")}>
											<Checkbox
												id="messenger_click_to_send_off"
												name="messenger_click_to_send_off"
												checked={!messengerClickToSend}
												onChange={() => this.handleToggleField(false, "messengerClickToSend")}
												className="manage-user__click-to-send__option__checkbox"
												radio={true}
											/>
											<div>
												<div className="manage-user__click-to-send__option__label">{t("Press Enter To Send")}</div>
												<div>{t("Pressing Enter will send message")}</div>
											</div>
										</div>
										<br />
										<div className="manage-user__click-to-send__option" onClick={() => this.handleToggleField(true, "messengerClickToSend")}>
											<Checkbox
												id="messenger_click_to_send_on"
												name="messenger_click_to_send_on"
												checked={messengerClickToSend}
												onChange={() => this.handleToggleField(true, "messengerClickToSend")}
												className="manage-user__click-to-send__option__checkbox"
												radio={true}
											/>
											<div>
												<div className="manage-user__click-to-send__option__label">{t("Click Send")}</div>
												<div>{t("Clicking Send will send message")}</div>
											</div>
										</div>
									</div>
								</div>
								<br />
								<div className="form-group manage-user__click-to-send">
									<label className="col-sm-2 control-label">{t("Messenger Customer Chat Settings")}</label>
									<div className="col-sm-10">
										<div
											className="manage-user__click-to-send__option"
											onClick={event => {
												event.preventDefault();
												event.stopPropagation();
												this.update({ messengerConversationNudging: !messengerConversationNudging });
											}}
										>
											<Checkbox
												id="messenger_conversation_nudging_toggle"
												name="messenger_conversation_nudging_toggle"
												checked={messengerConversationNudging}
												className="manage-user__click-to-send__option__checkbox"
											/>
											<div>
												<div className="manage-user__click-to-send__option__label">{t("Nudge conversations")}</div>
												<div>{t("A reminder will be shown in messenger if a follow up or response might be needed.")}</div>
											</div>
										</div>
									</div>
								</div>

								<div className="form-group manage-user__click-to-send">
									<label className="col-sm-2 control-label" />
									<div className="col-sm-10">
										<div
											className="manage-user__click-to-send__option"
											onClick={event => {
												event.preventDefault();
												event.stopPropagation();
												this.update({ messengerShowReplySuggestions: !messengerShowReplySuggestions });
											}}
										>
											<Checkbox
												id="messenger_show_reply_suggestions_toggle"
												name="messenger_show_reply_suggestions_toggle"
												checked={messengerShowReplySuggestions}
												className="manage-user__click-to-send__option__checkbox"
											/>
											<div>
												<div className="manage-user__click-to-send__option__label">{t("Show Reply Suggestions")}</div>
												<div>{t("Show reply suggestions in customer chat.")}</div>
											</div>
										</div>
									</div>
								</div>
								<br />
								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Messenger Signature")}</label>
									<div className="col-sm-10 manage-user__signature-checkmark-container">
										<Checkbox
											id="enable_messenger_signature"
											name="enable_messenger_signature"
											checked={enableMessengerSignature}
											onChange={this.handleChangeEnableMessengerSignature}
											className="manage-user__signature-checkmark"
										/>
										<span className="checkmark manage-user__signature-checkmark-span">{t("Enable Messenger Signature")}</span>
									</div>
								</div>
								{enableMessengerSignature && (
									<div className="form-group">
										<label className="col-sm-2 control-label">{t("Signatures")}</label>
										<div className="col-sm-10">
											<Signature
												plainTextSignature={messengerSignature}
												htmlSignature={messengerHtmlSignature}
												onPlainTextSignatureChange={this.handleSignatureOnChange}
												onHtmlSignatureChange={this.handleHtmlSignatureOnChange}
											/>
										</div>
									</div>
								)}
								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Product Tours")}</label>
									<div className="col-sm-10 manage-user__signature-checkmark-container">
										<Checkbox
											id="enable_tours"
											name="enable_tours"
											checked={enableTours}
											onChange={this.handleChangeEnableTours}
											className="manage-user__signature-checkmark"
										/>
										<span className="checkmark manage-user__signature-checkmark-span">{t("Enable Product Tours")}</span>
									</div>
								</div>
								<br />
								<div className="form-group">
									<label className="col-sm-2 control-label">{t("Notifications")}</label>
									<div className="col-sm-10">
										<ManageUserPreferences
											locations={this.state.assignedLocations}
											handleManagePreferencesChange={this.handleManagePreferencesChange}
											showOnLeaderboardOption={false}
											locationsRemovable={false}
											isContactAssignedToOthersDisabled={!user.GroupPermission.view_messages_assigned_to_others}
											isUnassignedPushDisabled={!user.GroupPermission.view_unassigned_messages}
										/>{" "}
									</div>
								</div>
								<br />
								<div className="form-group">
									<div className="col-sm-4 col-sm-offset-2">
										<button id="save" className="btn btn-primary" onClick={event => this.handleSaveUserClick(event)}>
											{t("Save")}
										</button>
										&nbsp;
										<Link to="/" className="btn btn-warning">
											{t("Cancel")}
										</Link>
									</div>
								</div>
							</form>
						</div>
					</div>
					<hr />
					<Alert
						type="error"
						show={saveError}
						title={t("Saving Error")}
						confirm={t("OK")}
						onClose={() => {
							this.update({ saving: false, saveError: false });
						}}
					>
						<div>{t("Sorry, an error was encountered while saving this user. Please try again.")}</div>
					</Alert>
					<Alert type="success" show={saveSuccess} title={t("User Updated Successfully")} confirm={t("OK")} onClose={() => this.props.history.push("/")} />
					<Alert
						type="error"
						show={passwordMismatch}
						title={t("Password Mismatch")}
						confirm={t("OK")}
						onClose={() => {
							this.update({ passwordMismatch: false });
						}}
					>
						<div>{t("Sorry, the passwords do not match. Please try again.")}</div>
					</Alert>
					<Alert
						type="error"
						show={passwordRequirementsError}
						title={t("Password Requirements")}
						confirm={t("OK")}
						onClose={() => {
							this.update({ passwordRequirementsError: false });
						}}
					>
						<div>{t("Sorry, the password does not meet the requirements. Please try again.")}</div>
					</Alert>
					<Alert
						type="error"
						show={profilePictureError}
						title={t("Profile Image Change")}
						confirm={t("OK")}
						onClose={() => {
							this.update({ profilePictureError: false });
						}}
					>
						<div>{t("Sorry, an error was encountered while changing the profile image. Please try again.")}</div>
					</Alert>
				</div>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(ManageUserProfile));

import React from "react";

import NotificationService from "../../services/NotificationService";

export default function withLocation(WrappedComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);
			this.state = {};
			this.wrappedComponentRef = React.createRef(); // Use React.createRef() to create a ref
		}

		componentDidMount() {
			NotificationService.subscribeOnce("locationChanged", "__with_location__" + WrappedComponent.name, this.onLocationChanged);
		}

		componentWillUnmount() {
			NotificationService.unsubscribe("locationChanged", this.onLocationChanged);
		}

		onLocationChanged = location => {
			// Check if the current instance of the wrapped component is available
			if (this.wrappedComponentRef.current) {
				const wrappedComponentInstance = this.wrappedComponentRef.current;

				// Check if the wrapped component has an `onLocationChanged` method
				if (typeof wrappedComponentInstance.onLocationChanged !== "function") {
					throw new Error(
						`WrappedComponent: Unable to change locations, no onLocationChanged found in component ${wrappedComponentInstance.constructor.name}.`
					);
				}

				// Call the `onLocationChanged` method on the wrapped component instance
				wrappedComponentInstance.onLocationChanged(location);
			}
		};

		render() {
			// Pass the ref to the WrappedComponent using the ref prop
			return <WrappedComponent ref={this.wrappedComponentRef} {...this.props} />;
		}
	};
}

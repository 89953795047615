import React, { Component } from "react";
import * as Icon from "react-feather";

import "../../styles/css/components/commons/select.css";

class Select extends Component {
	render() {
		const { id, disabled, value, onChange, placeholder, required, options } = this.props;

		return (
			<div className="dh-select-container">
				<select
					id={id}
					className="dh-select"
					disabled={disabled}
					value={value}
					onChange={(newValue, actionMeta) => onChange(newValue, actionMeta)}
					placeholder={placeholder}
					required={required}
				>
					{options.map((option, index) => (
						<option name={option.label} value={option.value} key={index}>
							{option.label}
						</option>
					))}
				</select>
				<div className="dh-select-chevron">
					<Icon.ChevronDown />
				</div>
			</div>
		);
	}
}

export default Select;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import Modal from "../../components/common/DHModal";
import Alert from "../../components/common/Alert";
import Checkbox from "../../components/common/Checkbox";

import ScheduledMessageModal from "../ScheduledMessages/ScheduledMessageModal";

import GAService from "../../services/GAService";
import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import UtilityService from "../../services/UtilityService";
import NPSService from "../../services/NPSService";
import TranslationService from "../../services/TranslationService";

import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";
import { SM_TYPES } from "../../constants/ScheduledMessages";
import { CODES_TO_LABEL, LANGUAGES } from "../../constants/LanguageConstants";

import "../../styles/css/scenes/net-promotor-request.css";

class SendNpsRequest extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.name ? this.props.name : "",
			phoneOrEmail: this.props.phoneOrEmail ? this.props.phoneOrEmail : "",
			isSentBefore: false,
			lastSentDate: "",
			showSentBeforeModal: false,
			showSendManyModal: false,
			isAnonymous: false,
			language: LANGUAGES.enCA
		};

		this.lastSentDebounce = _.debounce(
			async () => {
				await this.checkLastSentNpsRequest();
			},
			500,
			{
				leading: true,
				trailing: true
			}
		);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		await this.resetComponent();
	}

	resetComponent = async () => {
		await this.update({
			name: this.props.name ? this.props.name : "",
			phoneOrEmail: this.props.phoneOrEmail ? this.props.phoneOrEmail : "",
			isSentBefore: false,
			lastSentDate: "",
			showSentBeforeModal: false,
			isAnonymous: false
		});

		await this.setLanguageSelectionOptions();
	};

	async componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			await this.resetComponent();
		}
	}

	setLanguageSelectionOptions = async () => {
		let locationLanguage = TranslationService.getLocationLanguage();
		let supportedLocationOptions = TranslationService.getSupportedLanguagesOptions();

		await this.update({
			languageSupportedOptions: supportedLocationOptions,
			language: locationLanguage
		});
	};

	onHide = async () => {
		if (this.props.onHide) {
			await this.props.onHide();
		}
	};

	handleNameChange = async event => {
		await this.update({ name: event.target.value });
	};

	handlePhoneOrEmailChange = async event => {
		await this.update({ phoneOrEmail: event.target.value });
		this.lastSentDebounce();
	};

	onLanguageChange = async newLanguage => {
		await this.update({ language: newLanguage.value });
	};

	handleOnSubmit = async () => {
		const { phoneOrEmail, isSentBefore } = this.state;
		let { t } = this.props;

		// If the phone or email input has not been filled out
		if (phoneOrEmail === "") {
			this.phoneOrEmailInput.setCustomValidity(t("Phone or Email field is required."));
			this.phoneOrEmailInput.reportValidity();
			return;
		}
		// Confirm that the phone or email input it valid
		if (!isEmail(phoneOrEmail) && !UtilityService.isMobilePhoneValid(phoneOrEmail)) {
			this.phoneOrEmailInput.setCustomValidity(t("Phone or Email field value is invalid."));
			this.phoneOrEmailInput.reportValidity();
			return;
		}

		// Show confirmation modal if sent before
		if (isSentBefore) {
			await this.update({
				showSentBeforeModal: true
			});
		} else {
			await this.sendNps();
		}
	};

	showScheduleNpsButton = () => {
		return LocationService.isScheduledNpsPermissible();
	};

	checkLastSentNpsRequest = async () => {
		let { phoneOrEmail } = this.state;
		const locationId = UserService.getActiveLocation().id;

		await this.update({ isSentBefore: false, lastSentDate: "" });

		// Check for duplicate numbers if we have a sufficient number of digits
		if (phoneOrEmail < 7) {
			return;
		}

		// Check if the number or email was already sent a message
		let npsRequest = await NPSService.lastSentNpsRequest(locationId, phoneOrEmail);
		if (!npsRequest || npsRequest.last_sent === false) {
			return;
		}

		// if the review request has not been sent recently
		await this.update({
			isSentBefore: true,
			lastSentDate: npsRequest.created_at
		});
	};

	sendNps = async () => {
		const { name, phoneOrEmail, isAnonymous, language } = this.state;
		let { t } = this.props;

		const locationId = UserService.getActiveLocation().id;
		const phoneOrEmailField = phoneOrEmail.indexOf("@") >= 0 ? "email" : "phone";

		const data = { name: name, locationId: locationId, [phoneOrEmailField]: phoneOrEmail, isAnonymous, language };
		let result = await NPSService.createNpsRequest(data);

		// If there was an issue with sending the NPS request
		if (!result || result.error) {
			ToastService.error(t("Error trying to create NPS request: {{error}}", { error: result }));
			return;
		}

		ToastService.info(t("NPS Request has been sent"));

		await this.onHide();

		GAService.GAEvent({
			category: GA_CATEGORIES.nps.name,
			action: GA_ACTIONS.invites.sendNpsInvite,
			label: `NPS Invite Sent`
		});
	};

	renderForm = () => {
		const { phoneOrEmail, name, t } = this.props;
		const { isSentBefore, lastSentDate, language, languageSupportedOptions } = this.state;

		let showLanguageSelectors = languageSupportedOptions.length > 1;

		return (
			<div className="modal__flex-container">
				<div className="modal__field">
					{t("Name")}
					<span className="text-danger">*</span>
				</div>
				<input
					id="nps-name-field"
					type="text"
					onChange={e => this.handleNameChange(e)}
					placeholder={t("Name")}
					value={name}
					className="Common__input"
					ref={input => (this.nameInput = input)}
					onInvalid={() => this.nameInput.setCustomValidity(t("Name field is required."))}
					onInput={() => this.nameInput.setCustomValidity("")}
					required
					autoFocus
				/>

				<div className="modal__field">
					{t("Phone or Email")}
					<span className="text-danger">*</span>
				</div>
				<div id="last-sent" className={isSentBefore ? "text-danger" : "text-success"}>
					({t("Last Sent")}: {isSentBefore ? new Date(lastSentDate).toDateString() : t("Never")})
				</div>
				<input
					id="nps-contact-field"
					type="text"
					onChange={e => this.handlePhoneOrEmailChange(e)}
					placeholder={t("Phone number or email")}
					ref={input => (this.phoneOrEmailInput = input)}
					value={phoneOrEmail}
					className="Common__input"
					onInput={() => this.phoneOrEmailInput.setCustomValidity("")}
				/>

				{showLanguageSelectors && (
					<div className="modal__field nps-language-field">
						<p>{t("Language")}</p>
						<Select
							id="language"
							options={languageSupportedOptions}
							value={{ label: CODES_TO_LABEL[language], value: language }}
							onChange={this.onLanguageChange}
						/>
					</div>
				)}

				<div className="modal__field nps-anonymous-field">
					<p>{t("Anonymous NPS")}</p>
					<Checkbox
						id="isAnonymous"
						name="isAnonymous"
						checked={this.state.isAnonymous}
						onChange={event => {
							this.update({ isAnonymous: event.target.checked });
						}}
					/>
				</div>

				<button id="final-nps-send-invite" className={`mb-button`} onClick={this.handleOnSubmit}>
					{t("Send NPS Request")}
				</button>

				{this.showScheduleNpsButton() && (
					<div
						id="bulk-send-button"
						onClick={() => {
							this.onHide();
							this.setState({
								showSendManyModal: true
							});
						}}
						className="text-center"
					>
						{t("Send Many")}
					</div>
				)}
			</div>
		);
	};

	renderModal = () => {
		let { t } = this.props;

		return (
			<Modal show={true} onHide={this.onHide} title={t("Send NPS Request")} className="send-nps-request-modal">
				{this.renderForm()}
			</Modal>
		);
	};

	render() {
		const { showSentBeforeModal, lastSentDate, showSendManyModal } = this.state;
		const { show, t } = this.props;

		return (
			<>
				{show && this.renderModal()}
				<ScheduledMessageModal
					scheduledMessageId={null}
					show={showSendManyModal}
					onHide={() => this.setState({ showSendManyModal: false })}
					type={SM_TYPES.nps}
				/>
				<Alert
					type="warning"
					show={showSentBeforeModal}
					title={t("Previously Sent")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={result => {
						// Close the modal
						this.update({ showSentBeforeModal: false });

						// If we want to send the nps
						if (result) {
							// This is a bit of JS hackery, since the SweetAlert for the repeat message doesn't dismiss quickly
							// enough, causing UI issues when the success/fail SweetAlert tries to pop
							setTimeout(() => {
								this.sendNps();
							}, 1000);
						}
					}}
				>
					<div>
						{t("This customer was already sent a NPS request on {{date}}. Do you want to send again?", { date: new Date(lastSentDate).toDateString() })}
					</div>
				</Alert>
			</>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(SendNpsRequest));

const ReviewSiteService = {
	getReviewSiteStyleClass,
	getSiteNameFromId,
	isCustomReviewSite
};

/**
 * Check if a site is a custom review site.
 * For now just check if the site id is greater than 9
 * @param {number} siteId
 */
function isCustomReviewSite(siteId) {
	return siteId > 9;
}

/**
 * Determine the style class based on the specified site id
 * @param {Number} reviewSiteId
 */

function getReviewSiteStyleClass(reviewSiteId) {
	// Determine the actual review site type (these correspond to values in our DB)
	let styleClass = "";

	switch (reviewSiteId) {
		case 0:
			styleClass = "gray-review";
			break;
		case 1:
			styleClass = "blue-review";
			break;
		case 2:
			styleClass = "lightblue-review";
			break;
		case 3:
			styleClass = "blue-review";
			break;
		case 4:
			styleClass = "red-review";
			break;
		case 5:
			styleClass = "turquoise-review";
			break;
		case 6:
			styleClass = "blue-review";
			break;
		case 7:
			styleClass = "pink-review";
			break;
		case 8:
			styleClass = "yellow-review";
			break;
		case 9:
			styleClass = "custom-review";
			break;
		default:
			styleClass = "blue-review";
			break;
	}

	return styleClass;
}

// function getSiteNameFromId(allReviews, site_id) {
// 	const someReviews = allReviews.filter(a => {
// 		return a.id === site_id;
// 	});
// 	return someReviews.length > 0 ? someReviews[0].name : "";
// }

/**
 *
 * @param {Number} siteId
 * @param {String} customSiteName
 * @returns {String} siteName
 */

function getSiteNameFromId(siteId, customSiteName) {
	let siteName = "";
	switch (siteId) {
		case 0:
			siteName = "Options";
			break;
		case 1:
			siteName = "Direct";
			break;
		case 2:
			siteName = "Google";
			break;
		case 3:
			siteName = "Facebook";
			break;
		case 4:
			siteName = "Yelp";
			break;
		case 5:
			siteName = "RateMDs";
			break;
		case 6:
			siteName = "HealthGrades";
			break;
		case 7:
			siteName = "RealSelf";
			break;
		case 8:
			siteName = "YellowPages";
			break;
		case 9:
			siteName = customSiteName ? customSiteName : "Unnamed Custom";
			break;
		default:
			siteName = "None";
			break;
	}

	return siteName;
}

export default ReviewSiteService;

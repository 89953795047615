import React, { Component } from "react";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import withLocation from "../../components/common/WithLocation";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import ManageInvites from "./ManageInvites";
import Users from "./Users";

import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";
import { USERS_OVERVIEW_TABS } from "../../constants/Users";

import GAService from "../../services/GAService";
import UserService from "../../services/UserService";

class UsersOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: USERS_OVERVIEW_TABS.users.id
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onTabSelect = async tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.usersOverview.name,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		await this.update({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;
		let props = this.props;

		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();
		let isAccountOwner = UserService.isAccountOwner();

		let canViewInvites = isSuperAdminOrCustomerSuccess || isAccountOwner;

		return (
			<Page>
				<Header title="Users" />
				{isSuperAdminOrCustomerSuccess && (
					<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
						<Tab id={USERS_OVERVIEW_TABS.users.id} value={USERS_OVERVIEW_TABS.users.value} />
						{canViewInvites && <Tab id={USERS_OVERVIEW_TABS.invites.id} value={USERS_OVERVIEW_TABS.invites.value} />}
					</Tabs>
				)}
				{selectedTab === USERS_OVERVIEW_TABS.users.id && <Users {...props} />}
				{canViewInvites && selectedTab === USERS_OVERVIEW_TABS.invites.id && <ManageInvites {...props} />}
			</Page>
		);
	}
}

export default UsersOverview;

import React, { Component } from "react";
import { motion } from "framer-motion";

import * as Icon from "react-feather";

import "../../styles/css/components/commons/side-modal.css";
import { KEYS } from "../../constants/Messenger";

let variants = {
	hidden: {
		y: 0,
		x: 350,
		opacity: 0,
		transition: {
			duration: 0.2
		},
		position: "fixed",
		top: 0,
		right: 0
	},
	show: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.2
		},
		position: "fixed",
		top: 0,
		right: 0
	}
};

class SideModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showX: typeof this.props.showX !== "undefined" ? this.props.showX : true,
			closeOnClick: typeof this.props.closeOnClick !== "undefined" ? this.props.closeOnClick : true
		};
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKey);
		document.addEventListener("mousedown", this.onHandleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		document.removeEventListener("mousedown", this.onHandleClick, false);
	}

	onHandleClick = e => {
		if (this.sideModal && this.sideModal.contains(e.target)) {
			// the click is in the component
			return;
		}
		if (this.state.closeOnClick) {
			this.onHide();
		}
	};

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onHide();
		}
	};

	onHide() {
		if (this.props.onHide) {
			this.props.onHide();
		}
	}

	render() {
		const { id, show, title, titleIcon, subTitle } = this.props;
		let DynamicTitleIcon = titleIcon;

		let animate = show ? "show" : "hidden";

		return (
			<motion.div ref={ref => (this.sideModal = ref)} id={id} className="side-modal" variants={variants} animate={animate} initial="hidden">
				<div className="side-modal__body">
					<div className="side-modal__header">
						<div className="side-modal__header-x">
							<Icon.X onClick={() => this.onHide()} />
						</div>
						<div className="side-modal__header-title">
							{DynamicTitleIcon && (
								<div>
									<DynamicTitleIcon />
								</div>
							)}
							<div>{title}</div>
						</div>
						{subTitle && (
							<div className="side-modal__header-sub-title">
								<div>{subTitle}</div>
							</div>
						)}
					</div>
					<div>{this.props.children}</div>
				</div>
			</motion.div>
		);
	}
}

export default SideModal;

import ReactGA from "react-ga4";
import config from "../config/app/web-app.config";
import UserService from "./UserService";

ReactGA.initialize(config.GA_TRACKING_ID);

const GAService = {
	/**
	 * Set the userid of the analytics session
	 * @param {number} userId
	 */
	GASetUserId(userId) {
		let user = UserService.get();
		if (user && user.first_name && user.first_name.includes("FNCTST")) {
			return;
		}

		ReactGA.set({ userId });
	},

	/**
	 * Set the event of the action
	 * @param {string} category
	 * @param {string} action
	 * @param {string} label
	 */
	GAEvent({ category = "UNDEFINED_CATEGORY", action = "UNDEFINED_ACTION", label = "UNDEFINED_LABEL" }) {
		let user = UserService.get();
		if (user && user.first_name && user.first_name.includes("FNCTST")) {
			return;
		}

		const options = { category, action, label };
		ReactGA.event({
			...options
		});
	},

	/**
	 * Fire a page view
	 * @param {string} page
	 */
	GAPageView({ page = "/" }) {
		let user = UserService.get();
		if (user && user.first_name && user.first_name.includes("FNCTST")) {
			return;
		}

		ReactGA.send({ hitType: "pageview", page, title: page });
	}
};

export default GAService;

import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { LANGUAGES_DROPDOWN } from "../../constants/LanguageConstants.js";

const LanguageDropdown = ({ value, onChange, required, id, className, disabled }) => {
	const { t } = useTranslation(); // Use the useTranslation hook

	return (
		<Select
			id={id}
			className={className}
			options={LANGUAGES_DROPDOWN}
			onChange={newValue => onChange(newValue)}
			value={value}
			focusedOption={value}
			placeholder={t("Please select a Language")} // Use the translation function
			required={required || false}
			isDisabled={disabled}
		/>
	);
};

export default LanguageDropdown;

import React from "react";
import PropTypes from "prop-types";

import UtilityService from "../../services/UtilityService";

/**
 * This component returns a dropdrown.
 * Just pass in data, value,  callback, and custom class and enjoy
 * @prop {Array} data - Array of JSON objects (required)
 * @prop {number|string} value - value of selected item in dropdown (required)
 * @prop {func} onChange - callback function for onChange (required)
 * @prop {string} cClass - css class name (required)
 * @prop {object} cStyle - custom style object
 */
const Dropdown = ({ id, data, value, onChange, cClass, cStyle }) => {
	return (
		<select id={id} className={cClass} style={cStyle} onChange={e => onChange(e)} value={value}>
			{data.map(aData => (
				<option key={UtilityService.uuid()} value={aData.id}>
					{aData.name}
				</option>
			))}
		</select>
	);
};

Dropdown.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
	cClass: PropTypes.string.isRequired,
	sStyle: PropTypes.object
};
export default Dropdown;

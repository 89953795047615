import React, { Component } from "react";

import LocationAnalyticsTable from "./LocationAnalyticsTable";
import ReviewCountsOverYear from "./ReviewCountsOverYear";
import RatingsByLocation from "./RatingsByLocation";
import ReviewsByLocation from "./ReviewsByLocation";
import DashboardCard from "../../Dashboard/DashboardCard";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";

import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../styles/css/scenes/analytics.css";

class LocationAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authToken: UserService.get() ? UserService.get().auth_token : "",
			chartDataLoading: true,
			reviewsByLocationLoading: true,
			locations: [],
			locationsReviews: [],
			chartData: [],
			locationsLoading: true,
			reviewsByLocationCsv: null,
			ratingsByLocationCsv: null,
			reviewCountCsv: null
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	render() {
		return (
			<div>
				<div className="row wrapper white-bg page-heading">
					<div className="col-sm-6 col-md-8 col-lg-9">
						<h2>Location Analytics</h2>
					</div>
				</div>

				<div>
					<div className="row">
						<div className="col-md-6 col-sm-12 Analytics__ibox-content LocationAnalytics__table--condensed">
							<DashboardCard className={DASHBOARD_CARDS.reviewsByLocation.className} height={DASHBOARD_CARDS.reviewsByLocation.h}>
								<ReviewsByLocation isCard={true} />
							</DashboardCard>
						</div>

						<div className="col-md-6 col-sm-12 Analytics__ibox-content LocationAnalytics__table--condensed">
							<DashboardCard className={DASHBOARD_CARDS.ratingsByLocation.className} height={DASHBOARD_CARDS.ratingsByLocation.h}>
								<RatingsByLocation isCard={true} />
							</DashboardCard>
						</div>
					</div>

					<br></br>
					<DashboardCard className={DASHBOARD_CARDS.reviewCountsOverYear.className} height={DASHBOARD_CARDS.reviewCountsOverYear.h}>
						<ReviewCountsOverYear isCard={true} />
					</DashboardCard>
					<br />
					<DashboardCard className={DASHBOARD_CARDS.locationAnalyticsTable.className} height={DASHBOARD_CARDS.locationAnalyticsTable.h}>
						<LocationAnalyticsTable isCard={true} />
					</DashboardCard>
				</div>
			</div>
		);
	}
}

export default LocationAnalytics;

import React from "react";
import ReactTooltip from "react-tooltip";
import ContentLoader from "react-content-loader";
import { withTranslation, Trans } from "react-i18next";

import UserService from "../../services/UserService";
import TemplateService from "../../services/TemplateService";
import BookingRequestService from "../../services/BookingRequestService";
import ToastService from "../../services/ToastService";

import Modal from "./DHModal";
import TextBubble from "./TextBubble";

import { MEDIUM } from "../../constants/Messenger";

import "../../styles/css/scenes/booking-requests.css";

class SendBookingRequestLinkModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			sending: false,

			showMissingBookingRequestUrlError: false,
			lastBookingRequest: null,
			templates: [],
			selectedTemplateText: ""
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate(prevProps) {
		if (this.props.show && prevProps.show !== this.props.show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		await this.update({
			loading: false,
			sending: false,

			showMissingBookingRequestUrlError: false,
			lastBookingRequest: null,
			templates: [],
			selectedTemplateText: ""
		});
		this.fetchData();
	};

	fetchData = async () => {
		const { convo, show } = this.props;

		let isPermissible = BookingRequestService.isPermissible();

		if (!convo || !convo.contact_id || !isPermissible || !show) {
			return;
		}
		await this.update({ loading: true });

		let lastBookingRequest = await BookingRequestService.getLastCreated(convo.contact_id);

		let hasBookingRequestUrl = await BookingRequestService.hasBookingRequestLink(convo.contact_id);
		if (!hasBookingRequestUrl) {
			await this.update({ loading: false, showMissingBookingRequestUrlError: true });
			return;
		}

		const types = ["booking_request"];

		let bookingRequestTemplates = await TemplateService.fetchTemplates({
			contactId: convo.contact_id,
			locationId: UserService.getActiveLocation().id,
			types,
			replaceMessageVariables: true
		});

		if (!bookingRequestTemplates) {
			await this.update({ loading: false });
			return;
		}

		let selectedTemplateText = "";
		if (bookingRequestTemplates && bookingRequestTemplates[0] && bookingRequestTemplates[0].msg_text) {
			selectedTemplateText = bookingRequestTemplates[0].msg_text;
		}

		await this.update({ loading: false, lastBookingRequest, templates: bookingRequestTemplates, selectedTemplateText });
	};

	sendBookingRequestLink = async () => {
		const { convo, onHide, t } = this.props;

		if (!convo || !convo.contact_id) {
			return;
		}

		if (!this.canSend()) {
			return;
		}

		await this.update({ sending: true });

		let response = await BookingRequestService.sendBookingRequest(convo.contact_id);

		if (!response) {
			ToastService.error(t(`Failed to send a booking request link.`));
		} else {
			ToastService.info(t(`Booking Request Sent.`));
		}

		await this.update({ sending: false });
		if (onHide) {
			onHide();
		}
	};

	canSend = () => {
		const { convo } = this.props;
		let { sending } = this.state;
		if (!convo || !convo.contact_id || sending) {
			return false;
		}

		return true;
	};

	renderMedium = () => {
		const { convo } = this.props;

		let details = null;
		let MediumIcon = null;
		if (convo.preferred_medium === MEDIUM.sms.key) {
			details = convo.phone;
			MediumIcon = MEDIUM.sms.icon;
		} else if (convo.preferred_medium === MEDIUM.email.key) {
			details = convo.email;
			MediumIcon = MEDIUM.email.icon;
		} else if (MEDIUM[convo.preferred_medium]) {
			details = MEDIUM[convo.preferred_medium].value;
			MediumIcon = MEDIUM[convo.preferred_medium].icon;
		}

		return (
			<>
				<span className="sbrm__name__icon" data-tip data-for="sbrm-medium-icon">
					{MediumIcon && <MediumIcon size={16} />}
				</span>
				<ReactTooltip id="sbrm-medium-icon" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
					<>{details}</>
				</ReactTooltip>
			</>
		);
	};

	renderLoading = () => {
		return (
			<div className="sbrm__loading">
				<ContentLoader height={253} width={"100%"}>
					{/* Contact name */}
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="35" />
					{/* Text bubble */}
					<rect x="0" y="50" rx="5" ry="5" width="100%" height="100" />
					{/* Last completed details */}
					<rect x="0" y="165" rx="5" ry="5" width="100%" height="25" />
					{/* Save button */}
					<rect x="0" y="205" rx="5" ry="5" width="100%" height="40" />
				</ContentLoader>
			</div>
		);
	};

	renderForm = () => {
		let { lastBookingRequest, selectedTemplateText, sending, showMissingBookingRequestUrlError } = this.state;
		const { convo, t } = this.props;

		if (showMissingBookingRequestUrlError || !selectedTemplateText) {
			return (
				<div>
					<Trans i18nKey="Unable to generate a booking request url. Please fill in the <1>Booking Request Url</1> in the booking widget settings. Contact support at support@demandhub.co for help or inquiries.">
						Unable to generate a booking request url. Please fill in the <b>Booking Request Url</b> in the booking widget settings. Contact support at
						support@demandhub.co for help or inquiries.
					</Trans>
				</div>
			);
		}

		return (
			<>
				{convo && (
					<div className="sbrm__name">
						{t("To:")} {convo.name}
						{this.renderMedium()}
					</div>
				)}
				<TextBubble text={selectedTemplateText} blue={true} />
				<div className={`sbrm__last-request ${lastBookingRequest ? "text-danger" : ""}`}>
					({t("Last Completed Booking Request:")} {lastBookingRequest ? new Date(lastBookingRequest.created_at).toDateString() : t("Never")})
				</div>
				<div className="sbrm__send-button">
					<div className={`mb-button ${this.canSend() ? "" : "mb-button--disabled"}`} onClick={this.sendBookingRequestLink}>
						{sending ? t("Sending...") : t("Send")}
					</div>
				</div>
			</>
		);
	};

	render = () => {
		const { show, onHide, title } = this.props;
		let { loading } = this.state;
		return (
			<>
				<Modal show={show} onHide={onHide} title={title} className="sbrm">
					<>
						{loading && this.renderLoading()}
						{!loading && this.renderForm()}
					</>
				</Modal>
			</>
		);
	};
}

export default withTranslation(null, { withRef: true })(SendBookingRequestLinkModal);

import React from "react";
import ReactSwitch from "react-switch";

// Simply helper component to keep our React Switches consistent in terms of the look
const ToggleSwitch = ({ checked, id, onChange, disabled = false, label, uncheckedIcon = false, checkedIcon = false }) => {
	return (
		<>
			<div className={`${label ? "dh-tip" : ""}`} tip={label} style={{ height: 22 }}>
				<ReactSwitch
					height={22}
					width={38}
					id={id}
					checked={checked}
					uncheckedIcon={uncheckedIcon}
					checkedIcon={checkedIcon}
					onColor="#60A9FF"
					offColor="#c5c5c5"
					onChange={onChange}
					disabled={disabled}
				/>
			</div>
		</>
	);
};

export default ToggleSwitch;

import React from "react";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";

import RealtimeService from "../../../services/WebsocketsConnection";
import UserService from "../../../services/UserService";

import "./realtime.css";

class Realtime extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentCompanyId: UserService.getActiveCompany().id,
			selectedUserId: 0,
			selectedConnection: "No Connection has been selected ...",
			pool: []
		};

		this.refresh = null;
	}

	async componentDidMount() {
		this.fetchConnections();

		this.refresh = setInterval(() => {
			this.fetchConnections();
		}, 10000);
	}

	componentWillUnmount() {
		clearInterval(this.refresh);
	}

	async fetchConnections() {
		let { currentCompanyId } = this.state;
		let pool = await RealtimeService.getConnectionsForCompany({ companyId: currentCompanyId });

		pool = pool.slice();

		await this.update({
			pool
		});
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	renderConnectionDetails = () => {
		let { selectedConnection } = this.state;

		return (
			<div className="realtime__selected">
				<pre>{JSON.stringify(selectedConnection, null, 4)}</pre>
			</div>
		);
	};

	onConnectionSelected = async connection => {
		await this.update({
			selectedConnection: connection
		});
	};

	renderConnection = connection => {
		return (
			<div key={connection.id} className="realtime__users__item__connections__item" onClick={() => this.onConnectionSelected(connection)}>
				{connection.id}
			</div>
		);
	};

	renderConnections = connections => {
		return (
			<div className="realtime__users__item__connections">
				{connections.map(connection => {
					return this.renderConnection(connection);
				})}
			</div>
		);
	};

	onUserSelected = async user => {
		await this.update({
			selectedUserId: user.id
		});
	};

	renderUsers = () => {
		let { pool, selectedUserId } = this.state;
		return (
			<div className="realtime__users">
				{pool.map((poolItem, index) => {
					return (
						<div key={index} className="realtime__users__item" onClick={() => this.onUserSelected(poolItem.user)}>
							<div className="realtime__users__item__title">
								{poolItem.user.name} ({poolItem.user.id})
							</div>

							{selectedUserId === poolItem.user.id && this.renderConnections(poolItem.connections)}
						</div>
					);
				})}
			</div>
		);
	};

	render = () => {
		return (
			<Page>
				<Header title="Realtime" />

				<div className="realtime">
					{this.renderUsers()}
					{this.renderConnectionDetails()}
				</div>
			</Page>
		);
	};
}

export default Realtime;

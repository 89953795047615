import config from "../config/app/web-app.config";
import UserService from "./UserService";
import Kichiri from "./KichiriService";
import { upload } from "./FileUploadService";

import AppConfig from "../config/app/web-app.config";
import { IFRAME_STYLES } from "../constants/WidgetConstants";

export default {
	/**
	 * Genetate nucleus script
	 * @param {String} token
	 * @returns {String}
	 */
	getNucleusScript(token) {
		return `<script type="text/javascript">(function(){window.dh={chaabi:"${token}"};var script=document.createElement("script");script.setAttribute("src","${config.CDN_URL}${config.NUCLEUS_CDN}");script.setAttribute("chaabi","${token}");script.setAttribute("name","dh_nucleus_script");script.setAttribute("defer","");document.body.appendChild(script)})();</script>`;
	},
	/**
	 * Generate injectable code
	 * @param {String} token
	 */
	getInjectableScript(token) {
		return `window.dh = { chaabi: "${token}" };\nvar script = document.createElement("script");\nscript.setAttribute("src", "${config.CDN_URL}${config.NUCLEUS_CDN}")\nscript.setAttribute("chaabi", "${token}");\nscript.setAttribute("name", "dh_nucleus_script");\nscript.setAttribute("defer","");\ndocument.body.appendChild(script);`;
	},
	/**
	 * Get default tooltip image url
	 */
	getDefaultTooltipImageUrl() {
		return "https://cdn.demandhub.co/web-messenger/assets/tooltip.png";
	},
	/**
	 * Get default tooltip greeting
	 */
	getDefaultTooltipGreeting() {
		return {
			en_CA: "Hi there, any questions? Text us here!",
			en_US: "Hi there, any questions? Text us here!",
			en_GB: "Hi there, any questions? Text us here!",
			fr_CA: "Salut, des questions? Textez-nous ici!",
			es_US: "¡Hola, alguna pregunta? ¡Envíanos un mensaje de texto aquí!"
		};
	},
	/**
	 * Get default tooltip foreground
	 */
	getDefaultTooltipForeground() {
		return "#404040";
	},
	/**
	 * Get default tooltip background
	 */
	getDefaultTooltipBackground() {
		return "#ffffff";
	},
	/**
	 * Get default tooltip always show value
	 */
	getDefaultTooltipAlwaysShow() {
		return false;
	},
	/**
	 * Get default show phone confirmation value
	 */
	getDefaultShowPhoneConfirmation() {
		return false;
	},
	/**
	 * Get default offset for webchat (2.0)
	 */
	getDefaultWebchatOffset() {
		return { right: 0, bottom: 0 };
	},

	/**
	 * Get default placeholders for webchat (2.0)
	 */
	getDefaultWebchatPlaceholders() {
		return {
			en_CA: {
				header: "Let's chat over text!",
				blurb: "Please provide your details below and we'll be in touch via sms soon.",
				name: "Name",
				firstName: "First Name",
				lastName: "Last Name",
				phone: "Cell Phone",
				message: "Message",
				location: "Location",
				department: "Department",
				submit: "Submit"
			},
			en_US: {
				header: "Let's chat over text!",
				blurb: "Please provide your details below and we'll be in touch via sms soon.",
				name: "Name",
				firstName: "First Name",
				lastName: "Last Name",
				phone: "Cell Phone",
				message: "Message",
				location: "Location",
				department: "Department",
				submit: "Submit"
			},
			en_GB: {
				header: "Let's chat over text!",
				blurb: "Please provide your details below and we'll be in touch via sms soon.",
				name: "Name",
				firstName: "First Name",
				lastName: "Last Name",
				phone: "Cell Phone",
				message: "Message",
				location: "Location",
				department: "Department",
				submit: "Submit"
			},
			fr_CA: {
				header: "Discutons par texto!",
				blurb: "Veuillez fournir vos détails ci-dessous et nous serons en contact par SMS bientôt.",
				name: "Nom",
				firstName: "Prénom",
				lastName: "Nom de famille",
				phone: "Téléphone cellulaire",
				message: "Message",
				location: "Emplacement",
				department: "Département",
				submit: "Soumettre"
			},
			es_US: {
				header: "¡Charlemos por mensaje de texto!",
				blurb: "Por favor, proporcione sus detalles a continuación y nos pondremos en contacto por SMS pronto.",
				name: "Nombre",
				firstName: "Nombre",
				lastName: "Apellido",
				phone: "Celular",
				message: "Mensaje",
				location: "Ubicación",
				department: "Departamento",
				submit: "Enviar"
			}
		};
	},

	/**
	 * Fetch nucleus api key for a location
	 * @param {Number} locationId
	 * @returns {String}
	 */
	async fetchNucleusKeyForLocation(locationId) {
		try {
			const apiKey = await Kichiri.api_keys.getToken({}, { name: "nucleus", locationId }, UserService.getAuthToken());
			return apiKey.data.token;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Update a widget configuration
	 * @param {Number} widgetId
	 * @param {String} status "active" or "inactive"
	 * @param {String} config string representation of the config object
	 */
	async updateWidget({ widgetId, status, config }) {
		try {
			await Kichiri.widget.update({ widgetId, status, config }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Fetches an array of widgets for a location
	 * @param {Number} locationId
	 * @return {Array}
	 */
	async fetchAllWidgets(locationId) {
		try {
			let response = await Kichiri.widget.list({}, { locationId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Fetches a widget with the specified id
	 * @param {Number} widgetId
	 * @return {Object}
	 */
	async fetchWidget(widgetId) {
		try {
			let response = await Kichiri.widget.fetch({ widgetId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Get profile pictures of all users for a location
	 * @param {Number} locationId
	 */
	async getProfilePictures(locationId) {
		try {
			const profilePicturesResponse = await Kichiri.location.getProfilePictures({ locationId }, {}, UserService.getAuthToken());
			return profilePicturesResponse.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Find a widget
	 * @param {Number} widgetId
	 * @param {Number} locationId
	 * @param {String} name
	 *
	 * @returns {Object} WidgetConfig
	 */
	async findWidget({ widgetId, locationId, name }) {
		try {
			let response = await Kichiri.widget.find({}, { widgetId, locationId, name }, UserService.getAuthToken());
			let widget = response.data;
			try {
				widget.config = JSON.parse(widget.config);
			} catch (error) {
				console.log(error.stack);
			}
			return widget;
		} catch (error) {
			console.log(error.stack);
		}
		return null;
	},

	/**
	 * Upload a banner image for micro sites
	 * @param {Object} file
	 * @param {String} extension the file extension
	 * @returns Url of the uploaded image
	 */
	async uploadMicroSiteBanner(file, extension) {
		try {
			let handle = UserService.getActiveLocation().handle;
			let url = `${AppConfig.API_SERVER}/api/location/${handle}/micro_site/banner_image`;
			let data = await upload(file, "", url, UserService.getAuthToken(), extension);
			return data.url;
		} catch (error) {
			console.log(error.stack);
		}
		return null;
	},

	/**
	 * Adjust the styling for all the different divs based on the position of the web-chat
	 *
	 * @param {Object} styles
	 * @param {Object} offset
	 * @returns {Object}
	 */
	extendStylesForPositioning({ position, styles, offset = { bottom: 0, right: 0, left: 0, top: 0 } }) {
		let positioning = {};
		let { bottom, right, left, top } = {};

		bottom = parseInt(bottom, 10) || 0;
		right = parseInt(right, 10) || 0;
		left = parseInt(left, 10) || 0;
		top = parseInt(top, 10) || 0;

		if (!position) {
			position = IFRAME_STYLES.positions.bottomRight;
		}

		let updatedStyles = {};

		if (position === IFRAME_STYLES.positions.bottomRight) {
			//
			updatedStyles.bottom = `${bottom + offset.bottom}px`;
			updatedStyles.right = `${right + offset.right}px`;
			//
		} else if (position === IFRAME_STYLES.positions.bottom) {
			//
			updatedStyles.bottom = `${bottom + offset.bottom}px`;
			updatedStyles.right = `calc(50% - (${styles.width} / 2))`;
			//
		} else if (position === IFRAME_STYLES.positions.bottomLeft) {
			//
			updatedStyles.bottom = `${bottom + offset.bottom}px`;
			updatedStyles.left = `${left + offset.left}px`;
			//
		} else if (position === IFRAME_STYLES.positions.left) {
			//
			updatedStyles.left = `${left + offset.left}px`;
			updatedStyles.bottom = `calc(50% - (${styles.height} / 2))`;
			//
		} else if (position === IFRAME_STYLES.positions.topLeft) {
			//
			updatedStyles.top = `${top + offset.top}px`;
			updatedStyles.left = `${left + offset.left}px`;
			//
		} else if (position === IFRAME_STYLES.positions.top) {
			//
			updatedStyles.top = `${top + offset.top}px`;
			updatedStyles.right = `calc(50% - (${styles.width} / 2))`;
			//
		} else if (position === IFRAME_STYLES.positions.topRight) {
			//
			updatedStyles.top = `${top + offset.top}px`;
			updatedStyles.right = `${right + offset.right}px`;
			//
		} else if (position === IFRAME_STYLES.positions.right) {
			//
			updatedStyles.right = `${right + offset.right}px`;
			updatedStyles.bottom = `calc(50% - (${styles.height} / 2))`;
			//
		}

		let updated = Object.assign(styles, updatedStyles);

		if (positioning.hide) {
			updated.display = "none";
		}

		return updated;
	},

	/**
	 * Get default tooltip URL
	 */
	getDefaultTooltipUrl() {
		return `${config.common.cdnUrl}/web-messenger/assets/tooltip.png`;
	},

	/**
	 * Get default tooltip greeting
	 */
	getDefaultGreeting() {
		return "Hi there, have a question? Text us here!";
	}
};

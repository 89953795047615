/**
 * Specific constants to Insight.js
 */

export const SENTIMENT_THRESHOLD = {
	extremelyPositiveThreshold: 9,
	positiveThreshold: 7,
	slightlyPositiveThreshold: 5,
	extremelyNegativeThreshold: 2,
	slightlyNegativeThreshold: 4.9
};

/**
 * A simple map that helps handle refernece identification for
 * commonly condensed nouns customers may use when writing reviews
 */

export const CONDENSED_KEYWORDS = { doctor: ["dr", "Dr"] };

import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import NumberService from "../../services/NumberService";

class NumbersAutocomplete extends Component {
	state = {
		options: []
	};
	// react-select doesnt accpet functions that returns promise.
	// So we have to create following non async function to call the final async function
	fetchNumbersNotAsync(input) {
		this.fetchNumbers(input);
	}
	async fetchNumbers(input) {
		const { provider, countryCode } = this.props;

		if (input.length < 3) {
			return;
		}
		if (input.length === 0) {
			this.setState({ options: [{ label: "", value: "" }] });
			return;
		}

		let availableNumbers = await NumberService.fetchAvailableNumbers({ areaCode: input, provider, countryCode });

		if (!availableNumbers) {
			this.setState({ options: [{ label: "", value: "" }] });
			return;
		}

		const options = availableNumbers.map(aNumber => ({ label: aNumber, value: aNumber }));
		this.setState({ options });
	}
	valueRenderer(option) {
		return <span style={{ alignItems: "left" }}>{option.label.split(", ")[0]}</span>;
	}
	render() {
		const { value, onChange, required, id, t } = this.props;
		const { options } = this.state;
		return (
			<Select
				id={id}
				options={options}
				onChange={newValue => onChange(newValue)}
				value={value}
				focusedOption={value}
				onInputChange={value => this.fetchNumbersNotAsync(value)}
				placeholder={t("Please enter area code (at least 3 digits)")}
				required={required || false}
				valueRenderer={option => this.valueRenderer(option)}
			/>
		);
	}
}
export default withTranslation(null, { withRef: true })(NumbersAutocomplete);

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ReplyTemplatesButton from "../Templates/ReplyTemplatesButton";
import ToastService from "../../services/ToastService";
import ReviewService from "../../services/ReviewService";
import UserService from "../../services/UserService";

import "../../styles/css/scenes/review-response.css";

class ReviewResponse extends Component {
	state = {
		responseText: this.props.value,
		loading: false
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleResponseOnChange(value = "") {
		this.setState({ responseText: value });
	}

	onTemplateSelected = template => {
		let message = template.msg_text;
		// If there is any media for this template
		if (template.Media && template.Media.length > 0) {
			let media = template.Media[0];
			if (!message) {
				message = "";
			}

			// For all media type, we will add the short url since some of the review platforms may not support media
			message += ` ${media.short_url}`;
			message.trim();
		}

		this.handleResponseOnChange(message);
	};

	handleOnAiSuggestionClick = async () => {
		let { reviewId, t } = this.props;

		const previousResponseText = this.state.responseText;

		await this.update({ loading: true, responseText: t("Generating AI Review Reply ...") });

		let response = await ReviewService.generateAiReplySuggestion({ reviewId });

		if (!response) {
			ToastService.error(t("An error occured generating the AI response."));
			await this.update({ responseText: previousResponseText, loading: false });
			return;
		}

		await this.update({ responseText: response, loading: false });
	};

	render() {
		const { reviewId, reviewRequestId, name, locationId, onCancel, handleOnPostClick, disabled, t } = this.props;
		const { responseText, loading } = this.state;

		let user = UserService.get();
		if (!user.GroupPermission.respond_reviews) {
			return null;
		}

		const canReponseToReviews = user.GroupPermission.respond_reviews;

		return (
			<div className="review-responding">
				<textarea
					name="review-reply-textarea"
					id="review-reply-textarea"
					style={{ resize: "none" }}
					placeholder={t("Type your response here or select a template...")}
					value={responseText}
					className="form-control review-responding__textarea"
					rows="3"
					onChange={event => this.handleResponseOnChange(event.target.value)}
					disabled={disabled || loading ? true : false}
				/>
				<div className="review-responding__controls">
					{canReponseToReviews && (
						<span className="mb-button fnctst-generate-ai-reply-button" onClick={this.handleOnAiSuggestionClick}>
							{t("Generate AI Reply")}
						</span>
					)}{" "}
					<ReplyTemplatesButton
						locationId={locationId}
						activeReviewRequestId={reviewRequestId}
						activeContactName={name}
						dropdownClassName="ReviewResponse__template--dropdown"
						handleTemplateMessageOnClick={this.onTemplateSelected}
						btnSize="btn-sm"
						templateType={"review_response"}
					/>{" "}
					<span className="mb-button fnctst-post-reply-button" onClick={() => handleOnPostClick(reviewId, responseText)}>
						{t("Post")}
					</span>{" "}
					<span className="mb-button mb-button--cancel fnctst-cancel-reply-button" onClick={() => onCancel(reviewId)}>
						{t("Cancel")}
					</span>
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ReviewResponse);

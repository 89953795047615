import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UserService from "../../services/UserService";
import Kichiri from "../../services/KichiriService";
import UtilityService from "../../services/UtilityService";
import { withTranslation } from "react-i18next";

import Alert from "../../components/common/Alert";

import "../../styles/css/scenes/reset-password.css";

class TokenEntry extends Component {
	state = {
		token: "",
		tokenFailed: false,
		exceededVerifyMessage: "verify exceeded",
		tokenVerifyTryExceeded: false,
		isTokenShort: false
	};

	componentDidMount() {
		document.body.classList.add("gray-bg");
		UtilityService.checkAndSetDarkBackground();
	}

	componentWillUnmount() {
		document.body.classList.remove("gray-bg");
		document.body.classList.remove("Common-bg--dark");
	}
	handleTokenOnChange(e) {
		this.setState({ token: e.target.value });
	}
	restrictInputToNumbers(e) {
		const re = /^\d+$/;
		if (!re.test(e.key) && e.key !== "Enter") {
			e.preventDefault();
		}
	}

	async handleSubmitOnClick(e) {
		e.preventDefault();
		const { token, exceededVerifyMessage } = this.state;
		if (token.length < 6) {
			this.setState({ isTokenShort: true });
			return;
		}
		try {
			let data = await UserService.verifyToken({ token });

			this.props.history.push({ pathname: "/password/reset", state: { token: data.token } });
		} catch (error) {
			console.log(error);
			if (error.response.data.error === exceededVerifyMessage) {
				this.setState({ tokenVerifyTryExceeded: true });
			} else {
				this.setState({ tokenFailed: true });
			}
		}
	}
	render() {
		const { token, tokenFailed, tokenVerifyTryExceeded, isTokenShort } = this.state;
		let { t } = this.props;

		if (UserService.get()) {
			this.props.history.push("/");
		}

		return (
			<div>
				<div className="reset-password Common__card">
					<h2 className="font-bold">{t("Enter Token")}</h2>
					<form onSubmit={e => this.handleSubmitOnClick(e)}>
						<h4 className="text-left">{t("Please enter the 6 digit code you received via text message.")}</h4>
						<div className="form-group">
							<input
								type="text"
								maxLength="6"
								value={token}
								onKeyPress={e => this.restrictInputToNumbers(e)}
								onChange={e => this.handleTokenOnChange(e)}
								className="form-control Common__input"
							/>
						</div>
						<button className="mb-button mb-button--slim mb-button--fit" type="submit" disabled={token.length < 6}>
							{t("Verify")}
						</button>
					</form>
				</div>
				<Alert
					type="error"
					show={isTokenShort}
					title={t("Invalid Code")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ isTokenShort: false });
					}}
				>
					<div>{t("The reset code must be at least 6 digits long.")}</div>
				</Alert>
				<Alert
					type="error"
					show={tokenFailed}
					title={t("Invalid or Expired Code")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ tokenFailed: false });
					}}
				>
					<div>
						{t(
							"Please try entering your 6-digit code again. If the code was requested more than 24 hours ago, please start the password reset process again or contact us at support@demandhub.co"
						)}
					</div>
				</Alert>
				<Alert
					type="error"
					show={tokenVerifyTryExceeded}
					title={t("Token Verify Limit Exceeded")}
					confirm={t("OK")}
					onClose={() => this.props.history.push("/")}
				>
					<div>
						{t("You have exceeded the daily limit of attempts to verify your code. Please try again in 24 hours or contact us at support@demandhub.co")}
					</div>
				</Alert>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(TokenEntry));

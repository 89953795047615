import UserService from "./UserService";

export const CsvService = {
	/**
	 * Filter the name of a location to be appropriate for a filename
	 * @return {string} the name of the location after it's been filtered
	 */
	filterActiveLocationName() {
		let name = UserService.getActiveLocation() ? UserService.getActiveLocation().name : "unknown";
		name = name.replace(/[']/gm, "");
		// eslint-disable-next-line
		name = name.replace(/[\-\s\(\)\.:;!@\$%\^\&*+]/gim, "-");
		name = name.replace(/[-]+/gm, "-");
		name = name.toLowerCase();
		return name;
	}
};

export default CsvService;

import React, { Component } from "react";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";

import LightboxService from "../../../../services/LightboxService";
import UtilityService from "../../../../services/UtilityService";
import MessagesService from "../../../../services/MessagesService";

import MediaGrid from "../../../../components/common/MediaGrid";

import "./customer-media-grid.css";

class CustomerMediaGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mediaList: [],
			limit: 10,
			canLoadMore: true,
			loading: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.contactId !== this.props.contactId) {
			await this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({ mediaList: [], limit: 10, canLoadMore: true, loading: false });
		await this.fetchMedia();
	};

	fetchMedia = async () => {
		let { mediaList, limit } = this.state;
		let { contactId } = this.props;

		await this.update({ loading: true });

		let newMediaList = await MessagesService.fetchMediaForContact({
			contactId,
			limit
		});

		if (newMediaList.length === mediaList.length || newMediaList.length < limit) {
			await this.update({ canLoadMore: false });
		}

		await this.update({ mediaList: newMediaList, loading: false });
	};

	onLoadMoreMedia = async () => {
		let { limit } = this.state;
		let upperLimit = limit + 10;

		await this.update({
			limit: upperLimit
		});

		await this.fetchMedia();
	};

	onMediaClicked = url => {
		let { mediaList } = this.state;

		// Filter it so it's only images, add the correct params it's looking for
		let mediaListForLightbox = mediaList.map(m => {
			if (m.type !== "image") {
				return null;
			}

			return {
				src: UtilityService.appendQueryAuthToken(m.download_url),
				file_name: m.file_name,
				height: m.height,
				width: m.width
			};
		});

		// Remove the nulls
		mediaListForLightbox = mediaListForLightbox.filter(item => {
			return item !== null;
		});

		// Find the correct index
		let mediaIndex = 0;
		for (let i = 0; i < mediaList.length; i++) {
			if (mediaListForLightbox[i].src === url) {
				mediaIndex = i;
				break;
			}
		}

		LightboxService.setMedia({ media: mediaListForLightbox, mediaIndex: mediaIndex });
		LightboxService.open();
	};

	renderLoading = () => {
		return (
			<ContentLoader height={"210"} width={"100%"}>
				<rect x="0" y="0" rx="0" ry="0" width="100%" height="210" />
			</ContentLoader>
		);
	};

	render = () => {
		let { loading, mediaList, canLoadMore } = this.state;

		if (mediaList.length < 1) {
			return (
				<div className="customer-media-grid">
					<div className="customer-media-grid__no-media-image">
						<img src="https://cdn.demandhub.co/web-app/assets/media-banner.svg" alt="media loading" />
					</div>
				</div>
			);
		}

		return (
			<div className="customer-media-grid">
				<MediaGrid mediaList={mediaList} itemWidth={250} onMediaClicked={url => this.onMediaClicked(url)} />
				{loading && <div className="customer-media-grid__loading">{this.renderLoading()}</div>}
				{!loading && canLoadMore && (
					<div className="customer-media-grid__load-more" onClick={this.onLoadMoreMedia}>
						<div className="mb-button">Load More</div>
					</div>
				)}
			</div>
		);
	};
}

export default CustomerMediaGrid;

import React, { Component } from "react";

import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ClickRate extends Component {
	state = { clickRate: 0 };

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	async fetchData() {
		let clickRate = await LocationService.fetchClickRate({ locationId: UserService.getActiveLocation().id });
		if (!clickRate) {
			this.setState({ clickRate: 0 });
			return;
		}
		this.setState({ clickRate });
	}

	render() {
		const { clickRate } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;

		return (
			<DashboardCard className={DASHBOARD_CARDS.clickRate.className} height={DASHBOARD_CARDS.clickRate.h}>
				<div className="dashboard__card__header">
					<div className="dashboard__card__header__icon">
						<Icon.MousePointer size="42" />
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">{cardName ? cardName : "Click Rate"}</div>
				</div>

				<div className="dashboard__card__stat">
					{isEnabled() && isPermissible() ? (
						<>
							<div className="dashboard__card__stat__count">{clickRate}%</div>
							<small>since joining</small>
						</>
					) : (
						`${isEnabled() ? "Review Invites are not enabled. Contact support to get started." : "Review Invites Data Restricted."}`
					)}
				</div>
			</DashboardCard>
		);
	}
}

export default withLocation(ClickRate);

export const CUSTOM_FIELD_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	field: {
		id: "field",
		value: "Field",
		sortable: true,
		sortField: "field",
		width: 2
	},
	favorite: {
		id: "favorite",
		value: "Favorite",
		sortable: true,
		sortField: "favorite",
		width: 1
	},
	description: {
		id: "description",
		value: "Description",
		sortable: true,
		sortField: "description",
		width: 2
	},
	type: {
		id: "type",
		value: "Type",
		sortable: true,
		sortField: "type",
		width: 2
	},
	updated_at: {
		id: "updated_at",
		value: "Last Updated",
		sortable: true,
		sortField: "updated_at",
		width: 2
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const CUSTOM_FIELD_TYPES = {
	string: "string",
	boolean: "boolean"
};

export const CUSTOM_FIELD_TYPES_LABELS = {
	string: "String",
	boolean: "Boolean"
};

export const CUSTOM_FIELD_MAX_FAVORITES = 3;

import React, { Component } from "react";

import LeadsBarChart from "./LeadsBarChart";
import LeadsPieChart from "./LeadsPieChart";
import LeadsByDate from "./LeadsByDate";
import LeadsByHour from "./LeadsByHour";

import "../../../styles/css/scenes/leadSourceAnalytics.css";

class LeadSourceAnalytics extends Component {
	render() {
		return (
			<div className="dh-page__cards">
				<LeadsBarChart />
				<LeadsPieChart />
				<LeadsByDate />
				<LeadsByHour />
			</div>
		);
	}
}

export default LeadSourceAnalytics;

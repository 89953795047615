/**
 * Add all the constants that are used throughout the app and are same through all the environments
 */

export const MOBILE_WIDTH = 767;

export const DATE_FORMAT = "YYYY-MM-DD";
export const SHORT_TIME_FORMAT = "hh:mm A";

export const DEMANDHUB_COMPANY_ID = 1;
export const DEMANDHUB_DEMO_COMPANY_ID = 60;

export const DAY_SORTER = {
	sunday: 0,
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6
};

export const GRADE_SORTER = {
	a: 0,
	"a-": 1,
	"b+": 2,
	b: 3,
	"b-": 4,
	"c+": 5,
	c: 6,
	"c-": 7,
	"d+": 8,
	d: 9,
	"d-": 10,
	f: 11
};

export const CLOSEIO = {
	LISTNAME_FIELD: "custom.lcf_TvilIHVWn8OwazT6uhW1oS0KtYQ9m4mjbTs9DvLZKbQ"
};

export const CLOSEIO_REP_REPORT_GET_QUERY = '"custom.List Name":"Reputation Report Requested"';

export const MESSAGE_LENGTH_THRESHOLDS = {
	happy: { threshold: 160, description: "" },
	meh: { threshold: 320, description: "The template is getting a bit long for sms..." },
	sad: { threshold: 480, description: "The template is getting large. This is not ideal for sms messages." },
	alert: { threshold: 481, description: "A template of this size is not recommended for sms!" }
};

export const SORT_ORDER = {
	desc: "desc",
	asc: "asc"
};

export const STATUS = {
	active: "active",
	inactive: "inactive",
	deleted: "deleted"
};

export const STATUS_LABEL = {
	active: "Active",
	inactive: "Inactive",
	deleted: "Deleted"
};

export const STATUS_VERB = {
	active: "Activate",
	inactive: "Deactivate",
	deleted: "Delete"
};

export const CONNECTION_STATUS = {
	connected: {
		id: "connected",
		display: "Connected"
	},
	reconnecting: {
		id: "reconnecting",
		display: "Reconnecting..."
	}
};

export const CHART_COLORS = [
	"#1F77B4",
	"#FC7F0D",
	"#2CA02C",
	"#D62729",
	"#aec7e8",
	"#98df8a",
	"#ff9896",
	"#ffbb78",
	"#7f7f7f",
	"#c5b0d5",
	"#dbdb8d",
	"#ff7f0e",
	"#9edae5",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#c49c94",
	"#e377c2",
	"#f7b6d2",
	"#c7c7c7",
	"#bcbd22",
	"#17becf"
];

export const DAYS_OF_WEEK = {
	monday: {
		id: "monday",
		value: "Monday"
	},
	tuesday: {
		id: "tuesday",
		value: "Tuesday"
	},
	wednesday: {
		id: "wednesday",
		value: "Wednesday"
	},
	thursday: {
		id: "thursday",
		value: "Thursday"
	},
	friday: {
		id: "friday",
		value: "Friday"
	},
	saturday: {
		id: "saturday",
		value: "Saturday"
	},
	sunday: {
		id: "sunday",
		value: "Sunday"
	}
};

export const DAYS_OF_WEEK_SHORT = {
	monday: "Mon",
	tuesday: "Tue",
	wednesday: "Wed",
	thursday: "Thu",
	friday: "Fri",
	saturday: "Sat",
	sunday: "Sun"
};

export const DAY_OF_WEEK_LIST = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const USER_STATUS = {
	available: "available",
	away: "away"
};

export const DATE_RANGES = {
	thisWeek: "this_week",
	thisMonth: "this_month",
	lastMonth: "last_month",
	allTime: "all_time"
};

export const MERIDIAN_TIMES_OPTIONS = {
	am: {
		value: "am",
		label: "AM"
	},
	pm: {
		value: "pm",
		label: "PM"
	}
};

export const TWELVE_HOUR_OPTIONS = [
	{ value: 0, label: "12" },
	{ value: 1, label: "1" },
	{ value: 2, label: "2" },
	{ value: 3, label: "3" },
	{ value: 4, label: "4" },
	{ value: 5, label: "5" },
	{ value: 6, label: "6" },
	{ value: 7, label: "7" },
	{ value: 8, label: "8" },
	{ value: 9, label: "9" },
	{ value: 10, label: "10" },
	{ value: 11, label: "11" }
];

export const FIFTEEN_MINUTE_OPTIONS = [
	{ value: 0, label: "00" },
	{ value: 15, label: "15" },
	{ value: 30, label: "30" },
	{ value: 45, label: "45" }
];

export const OPERATORS = {
	and: "and",
	or: "or",
	in: "in",
	notIn: "notIn",
	eq: "eq",
	like: "like",
	ne: "ne",
	gt: "gt",
	gte: "gte",
	lt: "lt",
	lte: "lte",
	is: "is",
	isNot: "isNot"
};

export const PAGE_SIZE_OPTIONS = {
	// 2: { value: 2, label: "2 per page" }, // For testing
	10: { value: 10, label: "10 per page" },
	50: { value: 50, label: "50 per page" },
	100: { value: 100, label: "100 per page" }
};

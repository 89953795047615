import Kichiri from "./KichiriService";
import UserService from "./UserService";
import moment from "moment";
import LocationService from "./LocationService";
import { LOCATION_FEATURES } from "../constants/LocationConstants";
import NotificationService from "./NotificationService";
import { BOOKING_REQUESTS_STATUS } from "../constants/BookingRequests";

let bookingRequestInterval = null;

export default {
	/**
	 * Fetch Booking requests
	 * @param {Number} locationId
	 * @param {String} searchTerm
	 * @param {String} status
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {String} limit
	 *
	 * @returns {Object[]}
	 */
	async fetchBookingRequests({ locationId, searchTerm, status, sortField, sortOrder, limit }) {
		try {
			const bookingRequests = await Kichiri.booking_widget.fetchBookingRequests(
				{},
				{ locationId, searchTerm, status, sortField, sortOrder, limit },
				UserService.getAuthToken()
			);
			return bookingRequests.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a single booking request
	 * @param {Number} locationId
	 * @param {Number} bookingRequestId
	 *
	 * @returns {Object[]}
	 */
	async fetchBookingRequest({ locationId, bookingRequestId }) {
		try {
			const response = await Kichiri.booking_widget.fetchBookingRequest({ bookingRequestId }, { locationId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Count Booking requests
	 * @param {Number} locationId
	 * @param {String} searchTerm
	 * @param {String} status
	 *
	 * @returns {Object[]}
	 */
	async countBookingRequests({ locationId, searchTerm, status }) {
		try {
			const bookingRequests = await Kichiri.booking_widget.countBookingRequests({}, { locationId, searchTerm, status }, UserService.getAuthToken());
			NotificationService.publish("bookingRequestCount", bookingRequests.data.count);
			return bookingRequests.data.count;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a booking request
	 * @param {Number} bookingRequestId
	 * @param {("awaiting"|"confirmed"|"cancelled"|"completed"|"deleted")} status
	 * @param {String} date
	 * @param {String} startTime
	 * @param {String} endTime
	 * @param {Number} locationId
	 * @param {String} reason
	 * @param {String} note
	 * @param {Boolean} sendNotification
	 */
	async updateBookingRequest({ bookingRequestId, status, date, startTime, endTime, locationId, reason, note, sendNotification }) {
		try {
			await Kichiri.booking_widget.updateBookingRequest(
				{ bookingRequestId, status, date, startTime, endTime, locationId, reason, note, sendNotification },
				{},
				UserService.getAuthToken()
			);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get time op
	 * @param {String} startTime
	 * @param {String} endTime
	 * @param {Number} duration
	 */
	getTimeOptions(startTime, endTime, duration) {
		let currentTime = moment()
				.startOf("day")
				.add(startTime, "hours")
				.format("HH:mm"),
			nextTime = moment()
				.startOf("day")
				.add(startTime, "hours")
				.add(30, "minutes")
				.format("HH:mm");
		const timeSlots = [];
		while (currentTime <= endTime) {
			timeSlots.push({ label: currentTime, value: currentTime });
			currentTime = nextTime;
			nextTime = moment()
				.startOf("day")
				.add(currentTime, "hours")
				.add(30, "minutes")
				.format("HH:mm");
		}
		return timeSlots;
	},

	/**
	 * Get business hours for the day
	 * @param {Number} locationId
	 * @param {String} day
	 */
	async fetchBusinessHours(locationId, day) {
		try {
			const businessHoursResponse = await Kichiri.location.fetchBusinessHoursForDay({ locationId }, { day }, UserService.getAuthToken());
			return businessHoursResponse.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get appointment settings for a locaion
	 * @param {Number} locationId
	 * @param {String} searchTerm
	 * @returns {Array}
	 */
	async getAppointmentSettings({ locationId, searchTerm }) {
		try {
			const appointmentSettingsResponse = await Kichiri.appointments.getAppointmentSettings({ locationId }, { searchTerm }, UserService.getAuthToken());
			return appointmentSettingsResponse.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Delete appointment setting by id
	 * @param {Number} id
	 */
	async deleteAppointmentSettings(id) {
		try {
			await Kichiri.appointments.deleteAppointmentSettings({ id }, {}, UserService.getAuthToken());
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Create a new appointment setting
	 * @param {Object} data
	 */
	async createAppointmentSetting(data) {
		try {
			await Kichiri.appointments.createAppointmentSettings(data, {}, UserService.getAuthToken());
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Update a new appointment setting
	 * @param {Object} data
	 */
	async updateAppointmentSetting(data) {
		try {
			await Kichiri.appointments.updateAppointmentSettings(data, {}, UserService.getAuthToken());
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Check if the booking requests are permissible
	 */
	isPermissible() {
		try {
			const user = UserService.get();
			return LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.bookings.id) && user.GroupPermission.view_bookings;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Start a setInterval for getting the booking request count
	 */
	setBookingRequestInterval() {
		// Update the bookingRequest count every 5 minutes
		bookingRequestInterval = setInterval(
			async () => {
				await this.countBookingRequests({
					locationId: UserService.getActiveLocation().id,
					status: BOOKING_REQUESTS_STATUS.awaiting
				});
			},
			300000 // 5 min
		);
	},

	/**
	 * Clear the booking request count interval
	 */
	clearBookingRequestInterval() {
		clearInterval(bookingRequestInterval);
		bookingRequestInterval = null;
	},

	/**
	 * Send a booking request request message
	 * @param {Number} contactId
	 */
	async sendBookingRequest(contactId) {
		try {
			await Kichiri.booking_widget.sendBookingRequest({ contactId }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the last created booking request
	 * @param {Number} contactId
	 */
	async getLastCreated(contactId) {
		try {
			let response = await Kichiri.booking_widget.getLastCreated({}, { contactId, locationId: UserService.getActiveLocation().id }, UserService.getAuthToken());

			let bookingRequest = response.data;
			if (!bookingRequest.last_created) {
				return null;
			}
			return bookingRequest;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check if we are able to generate a booking request link
	 * @param {Integer} contactId
	 *
	 * @returns {Boolean}
	 */
	async hasBookingRequestLink(contactId) {
		try {
			let response = await Kichiri.booking_widget.hasRequestUrl({}, { contactId, locationId: UserService.getActiveLocation().id }, UserService.getAuthToken());
			return response.data.status;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

import React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
	$getSelection,
	$isRangeSelection,
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	FORMAT_ELEMENT_COMMAND,
	FORMAT_TEXT_COMMAND,
	REDO_COMMAND,
	SELECTION_CHANGE_COMMAND,
	UNDO_COMMAND,
	$createParagraphNode
} from "lexical";
import { $setBlocksType } from "@lexical/selection";
import { $isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode } from "@lexical/list";

const LowPriority = 1;

function Divider() {
	return <div className="divider" />;
}

class ToolbarPluginClass extends React.Component {
	constructor(props) {
		super(props);
		this.toolbarRef = React.createRef();
		this.state = {
			canUndo: false,
			canRedo: false,
			isBold: false,
			isItalic: false,
			isUnderline: false,
			isStrikethrough: false,
			blockType: "paragraph" // Track the current block type
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		const { editor } = this.props;
		this.unregister = mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					this.updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload, newEditor) => {
					this.updateToolbar();
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_UNDO_COMMAND,
				payload => {
					this.update({ canUndo: payload });
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_REDO_COMMAND,
				payload => {
					this.update({ canRedo: payload });
					return false;
				},
				LowPriority
			)
		);
	}

	componentWillUnmount() {
		if (this.unregister) {
			this.unregister();
		}
	}

	updateToolbar = () => {
		const selection = $getSelection();

		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();

			// Default to paragraph
			let blockType = "paragraph";

			if ($isListNode(anchorNode)) {
				const parentList = anchorNode.getTopLevelElementOrThrow();
				blockType = parentList.getListType();
			}

			this.update({
				isBold: selection.hasFormat("bold"),
				isItalic: selection.hasFormat("italic"),
				isUnderline: selection.hasFormat("underline"),
				isStrikethrough: selection.hasFormat("strikethrough"),
				blockType // Update the block type state
			});
		}
	};

	formatParagraph = () => {
		this.props.editor.update(() => {
			const selection = $getSelection();
			if ($isRangeSelection(selection)) {
				$setBlocksType(selection, () => $createParagraphNode());
			}
		});
	};

	formatBulletList = () => {
		const { blockType } = this.state;
		if (blockType !== "bullet") {
			this.props.editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
		} else {
			this.formatParagraph();
		}
	};

	formatNumberedList = () => {
		const { blockType } = this.state;
		if (blockType !== "number") {
			this.props.editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
		} else {
			this.formatParagraph();
		}
	};

	render() {
		const { editor } = this.props;
		const { canUndo, canRedo, isBold, isItalic, isUnderline, isStrikethrough, blockType } = this.state;

		return (
			<div className="dh-lexical__toolbar" ref={this.toolbarRef}>
				{/* Formatting Buttons */}
				<button
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
					className={`dh-lexical__toolbar__toolbar-item spaced ${isBold ? "active" : ""}`}
					aria-label="Format Bold"
				>
					<i className="dh-lexical__toolbar__icon bold" />
				</button>
				<button
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
					className={`dh-lexical__toolbar__toolbar-item spaced ${isItalic ? "active" : ""}`}
					aria-label="Format Italics"
				>
					<i className="dh-lexical__toolbar__icon italic" />
				</button>
				<button
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
					className={`dh-lexical__toolbar__toolbar-item spaced ${isUnderline ? "active" : ""}`}
					aria-label="Format Underline"
				>
					<i className="dh-lexical__toolbar__icon underline" />
				</button>
				<button
					onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
					className={`dh-lexical__toolbar__toolbar-item spaced ${isStrikethrough ? "active" : ""}`}
					aria-label="Format Strikethrough"
				>
					<i className="dh-lexical__toolbar__icon strikethrough" />
				</button>
				<Divider />
				{/* List Buttons */}
				<button
					onClick={this.formatBulletList}
					className={`dh-lexical__toolbar__toolbar-item spaced ${blockType === "bullet" ? "active" : ""}`}
					aria-label="Bullet List"
				>
					<i className="dh-lexical__toolbar__icon list-ul" />
				</button>
				<button
					onClick={this.formatNumberedList}
					className={`dh-lexical__toolbar__toolbar-item spaced ${blockType === "number" ? "active" : ""}`}
					aria-label="Numbered List"
				>
					<i className="dh-lexical__toolbar__icon list-ol" />
				</button>
			</div>
		);
	}
}

// Functional component wrapper
function ToolbarPlugin() {
	const [editor] = useLexicalComposerContext();

	return <ToolbarPluginClass editor={editor} />;
}

export default ToolbarPlugin;

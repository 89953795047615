import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { withTranslation } from "react-i18next";

import BookingRequestService from "../../services/BookingRequestService";
import ToastService from "../../services/ToastService";
import UserService from "../../services/UserService";
import NotificationService from "../../services/NotificationService";
import SupportChatService from "../../services/SupportChatService";
import GAService from "../../services/GAService";

import withLocation from "../../components/common/WithLocation";
import EditBookingRequestModal from "../../components/common/EditBookingRequestModal";
import BookingRequestStatusAlert from "../../components/common/BookingRequestStatusAlert";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import Filters from "../../components/common/Filters";
import List from "../../components/common/List";
import SearchInput from "../../components/common/SearchInput";
import LandingPage from "../LandingPage/LandingPage";
import Alert from "../../components/common/Alert";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { BOOKING_REQUESTS_COLUMNS, BOOKING_REQUESTS_FILTERS, BOOKING_REQUESTS_STATUS } from "../../constants/BookingRequests";

import "react-toastify/dist/ReactToastify.css";
import "../../styles/css/components/commons/react-table.css";
import "./booking-requests.css";

class BookingRequests extends Component {
	constructor(props) {
		super(props);

		let { searchTerm, sortField, sortOrder, statusFilter } = queryString.parse(this.props.location.search);

		this.state = {
			bookingRequests: [],
			loading: true,
			loadedAll: true,
			searchTerm: searchTerm || "",
			sortField: sortField ? sortField : BOOKING_REQUESTS_COLUMNS.date.sortField,
			sortOrder: sortOrder ? sortOrder : SORT_ORDER.desc,
			limit: 50,
			pageSize: 50,
			statusFilter: statusFilter ? statusFilter : Object.keys(BOOKING_REQUESTS_FILTERS)[0],

			// For the edit modal
			showModal: false,
			bookingRequestUpdatingId: null,
			bookingRequestUpdatingStatus: null,
			bookingRequestUpdating: null,

			// Confirm change status modal
			showConfirmStatusChangeModal: false,

			// If bookings is disabled
			showBlockedAlert: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.update({ loading: true });
		this.fetchData();
	}

	onLocationChanged = location => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		let { t } = this.props;

		await this.update({ loading: true });

		const locationId = UserService.getActiveLocation().id;
		let { searchTerm, statusFilter, sortField, sortOrder, limit } = this.state;

		const bookingRequests = await BookingRequestService.fetchBookingRequests({
			locationId,
			searchTerm,
			status: statusFilter,
			sortField,
			sortOrder,
			limit
		});

		if (!bookingRequests) {
			ToastService.info(t("No booking requests found."));
			this.update({ loading: false });
			return;
		}

		await this.update({ bookingRequests, loadedAll: bookingRequests.length < limit });

		this.fetchBookingRequestCount();

		this.update({ loading: false });
	};

	fetchBookingRequestCount = async () => {
		let bookingRequestCount = await BookingRequestService.countBookingRequests({
			locationId: UserService.getActiveLocation().id,
			status: BOOKING_REQUESTS_STATUS.awaiting
		});
		NotificationService.publish("bookingRequestCount", bookingRequestCount);
	};

	setUrlParams() {
		if (this.props.history) {
			let { statusFilter, searchTerm, sortField, sortOrder } = this.state;

			let params = {};

			if (statusFilter) {
				params.statusFilter = statusFilter;
			}

			if (searchTerm) {
				params.searchTerm = searchTerm;
			}

			if (sortField) {
				params.sortField = sortField;
			}

			if (sortOrder) {
				params.sortOrder = sortOrder;
			}

			params = new URLSearchParams(params);
			this.props.history.replace({
				pathname: this.props.location.pathname,
				search: params.toString()
			});
		}
	}

	async updateStatus(id, status, sendNotificationMessage) {
		let { t } = this.props;
		try {
			const locationId = UserService.getActiveLocation().id;

			await BookingRequestService.updateBookingRequest({ bookingRequestId: id, status, locationId, sendNotification: sendNotificationMessage });

			ToastService.info(t("Appointment updated!"));

			await this.update({ loading: false, showModal: false });

			await this.fetchData();
		} catch (error) {
			ToastService.error(t("Appointment update failed!"));
			console.log(error);
		}
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		await this.fetchData();
	};

	onActionClicked = async (recordData, status) => {
		await this.update({
			showConfirmStatusChangeModal: true,
			bookingRequestUpdatingId: recordData.id,
			bookingRequestUpdating: recordData,
			bookingRequestUpdatingStatus: status
		});
	};

	onBRStatusAlertClose = async (confirmed, sendNotificationMessage) => {
		let { bookingRequestUpdatingId, bookingRequestUpdatingStatus } = this.state;
		await this.update({ showConfirmStatusChangeModal: false });
		if (confirmed) {
			await this.updateStatus(bookingRequestUpdatingId, bookingRequestUpdatingStatus, sendNotificationMessage);
		}
		await this.update({
			bookingRequestUpdatingId: null,
			bookingRequestUpdating: null,
			bookingRequestUpdatingStatus: null
		});
	};

	onFilterSelected = async item => {
		await this.update({ statusFilter: item.id });
		this.setUrlParams();
		await this.fetchData();
	};

	isFilterSelected = item => {
		return item === this.state.statusFilter;
	};

	getFilters() {
		let { t } = this.props;

		let bookingRequestFilters = Object.keys(BOOKING_REQUESTS_FILTERS)
			.map(item => {
				return { id: item, value: BOOKING_REQUESTS_FILTERS[item].display, order: BOOKING_REQUESTS_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			filters: {
				title: t("Status"),
				items: bookingRequestFilters,
				onClick: this.onFilterSelected,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	}

	getHeaders() {
		let headers = {
			items: BOOKING_REQUESTS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		this.setUrlParams();
		await this.fetchData();
	};

	renderRecord = recordData => {
		try {
			return [
				`${moment(recordData.date).format("LL")}`,
				`${recordData.start_time}-${recordData.end_time}`,
				recordData.reason,
				this.renderNote(recordData),
				recordData.Contact.name,
				recordData.Contact.phone,
				recordData.Contact.email,
				this.renderOfferedBy(recordData),
				recordData.AppointmentSetting ? recordData.AppointmentSetting.name : "",
				this.renderActions(recordData)
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderNote = recordData => {
		if (!recordData.note) {
			return "";
		}

		return (
			<div className="dh-tip booking-requests__notes_tooltip" tip={recordData.note}>
				<Icon.Info />
			</div>
		);
	};

	renderOfferedBy = recordData => {
		if (!recordData.AppointmentSetting) {
			return "";
		}
		var appointmentUser = recordData.AppointmentSetting.User;
		return UserService.createFullName({ firstName: appointmentUser.first_name, lastName: appointmentUser.last_name });
	};

	renderActions = recordData => {
		let { statusFilter } = this.state;
		let { t } = this.props;

		let user = UserService.get();

		return (
			<div className="dh-list__actions">
				{user.GroupPermission.update_bookings && statusFilter === BOOKING_REQUESTS_FILTERS.awaiting.id && (
					<Action
						key={`confirm-${recordData.id}`}
						id={`confirm-${recordData.id}`}
						label={t("Confirm")}
						icon={Icon.Check}
						onClick={() => this.onActionClicked(recordData, BOOKING_REQUESTS_STATUS.confirmed)}
					/>
				)}

				{user.GroupPermission.update_bookings &&
					(statusFilter === BOOKING_REQUESTS_FILTERS.confirmed.id || statusFilter === BOOKING_REQUESTS_FILTERS.awaiting.id) && (
						<Action
							key={`completed-${recordData.id}`}
							id={`completed-${recordData.id}`}
							label={t("Completed")}
							icon={Icon.CheckCircle}
							onClick={() => this.onActionClicked(recordData, BOOKING_REQUESTS_STATUS.completed)}
						/>
					)}

				{user.GroupPermission.update_bookings &&
					statusFilter !== BOOKING_REQUESTS_FILTERS.completed.id &&
					statusFilter !== BOOKING_REQUESTS_FILTERS.cancelled.id &&
					statusFilter !== BOOKING_REQUESTS_FILTERS.deleted.id && (
						<Action
							key={`cancel-${recordData.id}`}
							id={`cancel-${recordData.id}`}
							label={t("Cancel")}
							icon={Icon.X}
							onClick={() => this.onActionClicked(recordData, BOOKING_REQUESTS_STATUS.cancelled)}
						/>
					)}

				{user.GroupPermission.update_bookings &&
					(statusFilter === BOOKING_REQUESTS_FILTERS.cancelled.id || statusFilter === BOOKING_REQUESTS_FILTERS.deleted.id) && (
						<Action
							key={`awaiting-${recordData.id}`}
							id={`awaiting-${recordData.id}`}
							label={t("Awaiting")}
							icon={Icon.Clock}
							onClick={() => this.onActionClicked(recordData, BOOKING_REQUESTS_STATUS.awaiting)}
						/>
					)}
				{user.GroupPermission.delete_bookings && statusFilter !== BOOKING_REQUESTS_FILTERS.deleted.id && (
					<Action
						key={`delete-${recordData.id}`}
						id={`delete-${recordData.id}`}
						label={t("Delete")}
						icon={Icon.Trash2}
						onClick={() => this.onActionClicked(recordData, BOOKING_REQUESTS_STATUS.deleted)}
					/>
				)}
				{user.GroupPermission.update_bookings && (
					<Action
						key={`edit-${recordData.id}`}
						id={`edit-${recordData.id}`}
						label={t("Edit")}
						icon={Icon.Edit}
						onClick={() => this.update({ showModal: true, bookingRequestUpdatingId: recordData.id })}
					/>
				)}
			</div>
		);
	};

	onRecordClicked = item => {
		let location = UserService.getActiveLocation();

		if (!item || !item.Contact) {
			return;
		}

		if (this.props && this.props.history) {
			this.props.history.push(`/inbox?contactId=${item.Contact.public_id}&locationId=${location.public_id}`);
			window.location.reload();
		}
	};

	onGetStarted = () => {
		this.update({ showBlockedAlert: true });
	};

	onBlockedFeatureAlertClose = async confirm => {
		if (confirm) {
			SupportChatService.showNewMessage();
		}

		await this.update({
			showBlockedAlert: false
		});
	};

	renderLandingPage() {
		let { showBlockedAlert } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<LandingPage
					title={t("Booking Requests")}
					text={t("Add a booking widget to your website and allow customers to request a time range for an appointment.")}
					buttonText={t("Get Started")}
					onGetStartedClicked={() => this.onGetStarted()}
					beta={true}
				>
					<div className="landing-page__more">
						<div className="landing-page__block">
							<div className="landing-page__block__item">
								<img
									className="landing-page__block__item__img"
									alt="Accept Incoming Booking Requests"
									src="https://cdn.demandhub.co/img/misc/Booking-Requests-2.png"
								/>
							</div>
							<div className="landing-page__block__item">
								<div className="landing-page__block__item__text">{t("Accept incoming booking requests.")}</div>
							</div>
						</div>
						<div className="landing-page__block">
							<div className="landing-page__block__item">
								<img
									className="landing-page__block__item__img"
									alt="Communicate with customers"
									src="https://cdn.demandhub.co/img/misc/Booking-Requests-3.png"
								/>
							</div>
							<div className="landing-page__block__item">
								<div className="landing-page__block__item__text">{t("Easily and quickly communicate with customers.")}</div>
							</div>
							<div className="landing-page__block__item__text" />
						</div>
						<div className="landing-page__block">
							<div className="landing-page__block__item">
								<img className="landing-page__block__item__img" alt="Stay Organized" src="https://cdn.demandhub.co/img/misc/Booking-Requests-4.png" />
							</div>
							<div className="landing-page__block__item">
								<div className="landing-page__block__item__text">{t("Stay organized and improve productivity.")}</div>
							</div>
						</div>
					</div>
				</LandingPage>
				<Alert type="info" show={showBlockedAlert} title={t("Upgrade Subscription")} confirm={t("Chat with us")} onClose={this.onBlockedFeatureAlertClose}>
					{t("To enable Booking, please contact support@demandhub.co or chat with us.")}
				</Alert>
			</Page>
		);
	}

	render() {
		let {
			bookingRequests,
			searchTerm,
			loading,
			loadedAll,
			sortField,
			sortOrder,
			showModal,
			bookingRequestUpdatingId,
			showConfirmStatusChangeModal,
			bookingRequestUpdating,
			bookingRequestUpdatingStatus
		} = this.state;
		let { t } = this.props;

		if (!BookingRequestService.isPermissible()) {
			return this.renderLandingPage();
		}

		return (
			<Page>
				<Header title={t("Booking Requests")}>
					<Action id="refresh" label={t("Refresh Data")} icon={Icon.RefreshCcw} onClick={() => this.fetchData()} />
				</Header>
				<div className="Common__search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} initValue={searchTerm} />
				</div>
				<Filters filters={this.getFilters()} />
				<List
					items={bookingRequests}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("No booking requests found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<EditBookingRequestModal
					show={showModal}
					bookingRequestId={bookingRequestUpdatingId}
					onClose={() => this.update({ showModal: false, bookingRequestUpdatingId: null })}
				/>
				<BookingRequestStatusAlert
					show={showConfirmStatusChangeModal}
					onClose={this.onBRStatusAlertClose}
					bookingRequest={bookingRequestUpdating}
					toStatus={bookingRequestUpdatingStatus}
				/>
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(BookingRequests)));

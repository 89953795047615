import React, { PureComponent } from "react";

import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";

import withLocation from "../../components/common/WithLocation";

class InviteGoal extends PureComponent {
	state = { monthlyInviteSatus: "on-track", monthlyInvites: 0, monthlyInviteGoal: 0, requiredInviteCount: 0 };

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	fetchData = async () => {
		let data = await LocationService.fetchInviteGoals(UserService.getActiveLocation().id);
		if (!data) {
			this.setState({ monthlyInviteSatus: "on-track", monthlyInvites: 0, monthlyInviteGoal: 0, requiredInviteCount: 0 });
			return;
		}

		this.setState({
			monthlyInviteSatus: data.monthlyInviteSatus,
			monthlyInvites: data.monthlyInvites,
			monthlyInviteGoal: data.monthlyInviteGoal,
			requiredInviteCount: data.requiredInviteCount
		});
	};

	render() {
		const { monthlyInviteSatus, monthlyInvites, monthlyInviteGoal, requiredInviteCount } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;

		if ((isEnabled && !isEnabled()) || (isPermissible && !isPermissible())) {
			return (
				<div>
					<div>{cardName ? cardName : "Invitation Goal This Month"}</div>
					<div className="text-center">
						{!isEnabled() ? <>Review Invites are not enabled. Contact support to get started.</> : <>Review Invite Data Restricted.</>}
					</div>
				</div>
			);
		}

		let invitePercent = 0;
		if (monthlyInviteGoal >= 0) {
			invitePercent = monthlyInvites / monthlyInviteGoal;
		}

		return (
			<div>
				<div>
					<span>{cardName ? cardName : "Invitation Goal This Month"}</span>
					<small className="pull-right">
						{monthlyInvites}/{monthlyInviteGoal}
					</small>
				</div>
				<div className="progress progress-large">
					<div
						style={{ width: parseInt(invitePercent * 100, 10) + "%" }}
						className={monthlyInviteSatus === "off-track" ? "progress-bar progress-bar-danger" : "progress-bar progress-bar-success"}
					/>
				</div>
				<div className="text-center">
					{monthlyInviteSatus === "off-track" ? (
						<span className="text-danger">
							You're off track! Send {requiredInviteCount} {requiredInviteCount === 1 ? "invite" : "invites"} to catch up.
						</span>
					) : (
						<span className="text-success">
							{monthlyInviteSatus === "on-track"
								? "You're on track! Great work!"
								: "You're on track! Send " + requiredInviteCount + (requiredInviteCount === 1 ? " invite" : " invites") + " to keep pace."}
						</span>
					)}
				</div>
			</div>
		);
	}
}

export default withLocation(InviteGoal);

import React from "react";
import { withTranslation } from "react-i18next";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import LocationService from "../../../services/LocationService";
import NotificationService from "../../../services/NotificationService";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";

const INPUT_DEFAULTS = {
	conversionValue: 100
};

const INPUT_LIMITS = {
	conversionValueMin: 0
};

class ConversionSettingsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			edited: false,
			conversionValue: INPUT_DEFAULTS.conversionValue
		};
	}

	componentDidUpdate = prevProps => {
		let { show } = this.props;

		if (prevProps.show !== show && show) {
			this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onHide = (resetComponent = false) => {
		if (this.props.onHide) {
			this.props.onHide(resetComponent);
		}
	};

	resetComponent = async () => {
		let { t } = this.props;

		let locationData = await LocationService.fetchLocation(UserService.getActiveLocation().id);

		await this.update({
			edited: false,
			conversionValue: locationData.average_conversion_value
		});
	};

	onSave = async () => {
		let { t } = this.props;

		if (this.invalid()) {
			return;
		}

		let { conversionValue } = this.state;

		let response = await LocationService.updateLocation({
			locationId: UserService.getActiveLocation().id,
			company: UserService.getActiveCompany().id,
			average_conversion_value: conversionValue
		});

		if (!response) {
			ToastService.error(t("Error occurred trying to set value. Please try again."));
			return;
		}

		ToastService.info(t("Conversion Settings Updated."));

		let location = await UserService.getActiveLocation();
		location.average_conversion_value = response.average_conversion_value;

		UserService.setActiveLocation(location);
		NotificationService.publish("locationUpdated", UserService.getActiveLocation().id);

		this.onHide(true);
	};

	handleGenericEventHandler = event => {
		let name = event.target.name;
		let value = event.target ? event.target.value : event;
		this.update({ [name]: value, edited: true });
	};

	isInputValid = (value, min) => {
		return value && parseInt(value) < min;
	};

	invalid = () => {
		const { conversionValue } = this.state;

		if (!conversionValue || parseInt(conversionValue) < INPUT_LIMITS.conversionValueMin) {
			return true;
		}

		return false;
	};

	render = () => {
		const { show, t } = this.props;
		const { conversionValue } = this.state;

		return (
			<Modal show={show} onHide={this.onHide} title={t("Conversion Settings")}>
				<Input
					id="conversion-conversion-value"
					name="conversionValue"
					label={t("Average  Conversion Value")}
					description={t(`The dollar amount that each customer or patient is valued at for each appointment booked.`)}
					type="number"
					onChange={e => this.handleGenericEventHandler(e)}
					value={conversionValue}
					invalid={this.isInputValid(conversionValue, INPUT_LIMITS.conversionValueMin)}
				/>

				<div className="modal__actions">
					<div className={`mb-button mb-button--fit ${this.invalid() ? "mb-button--disabled" : ""}`} onClick={this.onSave}>
						{t("Save")}
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(ConversionSettingsModal);

import React from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Page from "../../components/common/Page";
import Alert from "../../components/common/Alert";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import SearchInput from "../../components/common/SearchInput";
import UpdateCustomFieldsModal from "./UpdateCustomFieldsModal";
import withLocation from "../../components/common/WithLocation";

import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";
import ToastService from "../../services/ToastService";

import { CUSTOM_FIELD_COLUMNS } from "../../constants/CustomFields";
import { SORT_ORDER } from "../../constants/CommonConstants";
import { CUSTOM_FIELD_TYPES_LABELS } from "../../constants/CustomFields";

class CustomFields extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loadedAll: false,
			searchTerm: "",
			data: [],
			sortField: CUSTOM_FIELD_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			limit: 50,
			pageSize: 50,
			selectedCustomField: null,
			deleteConfirmModal: false,
			showUpdateModal: false
		};
	}

	componentDidMount = () => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = () => {
		this.fetchData();
	};

	fetchData = async () => {
		const locationId = UserService.getActiveLocation().id;
		let { searchTerm, sortField, sortOrder, limit } = this.state;

		await this.update({ loading: true });

		let data = await LocationService.getCustomFields({ locationId, searchTerm, sortField, sortOrder, limit });
		if (!data) {
			await this.update({ loading: false });
			return;
		}

		await this.update({ data: data, loading: false, loadedAll: data.length < limit });
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.fetchData();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	getHeaders = () => {
		let headers = {
			items: CUSTOM_FIELD_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		await this.fetchData();
	};

	onRecordClicked = recordData => {
		this.setState({ selectedCustomField: recordData, showUpdateModal: true });
	};

	onConfirmDelete = recordData => {
		this.update({ deleteConfirmModal: true, selectedCustomField: recordData });
	};

	onCloseConfirmDelete = confirmed => {
		if (confirmed) {
			this.onDelete();
			return;
		}
		this.update({ deleteConfirmModal: false });
	};

	onDelete = async () => {
		let { selectedCustomField } = this.state;
		let { t } = this.props;

		if (!selectedCustomField) {
			await this.update({ deleteConfirmModal: false });
			return;
		}

		let result = await LocationService.updateCustomField({ locationId: UserService.getActiveLocation().id, id: selectedCustomField.id, status: "deleted" });
		if (!result) {
			ToastService.error(t("Unable to delete custom field."));
			await this.update({ deleteConfirmModal: false });
			return;
		}
		ToastService.info(t("Custom field deleted!"));

		await this.update({ deleteConfirmModal: false, selectedCustomField: null });
		await this.fetchData();
	};

	onUpdateModalSubmit = async (createMode, data) => {
		if (createMode) {
			await this.onCreateCustomField(data);
		} else {
			await this.onUpdateCustomField(data);
		}
		await this.update({ selectedCustomField: null, showUpdateModal: false });
		await this.fetchData();
	};

	onUpdateModalHide = async () => {
		await this.update({ showUpdateModal: false });
	};

	onCreateCustomField = async data => {
		let { t } = this.props;

		let customField = {
			locationId: UserService.getActiveLocation().id
		};
		customField = Object.assign(customField, data);

		let response = await LocationService.createCustomField(customField);
		if (!response) {
			ToastService.error(t("An error occurred trying to create a custom field."));
			return;
		}
		ToastService.info(t("Custom field created!"));
	};

	onUpdateCustomField = async data => {
		let { t } = this.props;

		let customField = {
			locationId: UserService.getActiveLocation().id
		};
		customField = Object.assign(customField, data);

		let response = await LocationService.updateCustomField(customField);

		if (!response) {
			ToastService.error(t("An error occurred trying to update the custom field."));
			return;
		}
		ToastService.info(t("Custom field updated!"));
	};

	renderRecord = data => {
		let { t } = this.props;
		const user = UserService.get();

		return [
			data.name,
			data.field,
			data.favorite ? <Icon.Check /> : "",
			data.description,
			CUSTOM_FIELD_TYPES_LABELS[data.type] ? CUSTOM_FIELD_TYPES_LABELS[data.type] : data.type,
			data.updated_at,
			<div className="dh-list__actions">
				<Action
					id={`edit-custom-field-${data.id}`}
					label={t("Edit")}
					icon={Icon.Edit}
					onClick={() => this.setState({ selectedCustomField: data, showUpdateModal: true })}
				/>
				{user.GroupPermission.update_custom_fields && (
					<Action id={`delete-custom-field-${data.id}`} label={t("Delete")} icon={Icon.Trash2} onClick={() => this.onConfirmDelete(data)} />
				)}
			</div>
		];
	};

	render() {
		let { data, loading, loadedAll, sortField, sortOrder, selectedCustomField, deleteConfirmModal, showUpdateModal } = this.state;
		let { t } = this.props;

		const user = UserService.get();
		const location = UserService.getActiveLocation();

		return (
			<Page>
				<div className="contacts-search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
					<div className="contacts-search__actions">
						{user.GroupPermission.create_custom_fields && location.custom_field_limit > data.length && (
							<Action
								id="new-custom-field"
								label={t("Create")}
								icon={Icon.Plus}
								onClick={() => this.setState({ showUpdateModal: true, selectedCustomField: null })}
							/>
						)}
					</div>
				</div>
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no custom fields...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<UpdateCustomFieldsModal data={selectedCustomField} show={showUpdateModal} onHide={this.onUpdateModalHide} onSubmit={this.onUpdateModalSubmit} />
				<Alert type="warning" show={deleteConfirmModal} title={t("Please confirm")} confirm={t("Yes")} cancel={t("No")} onClose={this.onCloseConfirmDelete}>
					<div>{t("Are you sure you would like to delete this custom field?")}</div>
				</Alert>
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(CustomFields)));

import React, { Component } from "react";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";

import withLocation from "../../components/common/WithLocation";

class InboundChatLeads extends Component {
	state = { inboundMessagesCount: 0 };
	async fetchInboundMessages() {
		const locationId = UserService.getActiveLocation().id;
		let inboundMessagesCount = await AnalyticsService.getInboundConversationsForLastXDays({ locationId, allTimeStats: true, days: 0 });
		if (!inboundMessagesCount) {
			this.setState({ inboundMessagesCount: 0 });
			return;
		}
		this.setState({ inboundMessagesCount });
	}

	componentDidMount() {
		this.fetchInboundMessages();
	}

	onLocationChanged = () => {
		this.fetchInboundMessages();
	};

	render() {
		const { inboundMessagesCount } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;

		return (
			<>
				<div className="dashboard__card__header">
					<div className="dashboard__card__header__icon">
						<Icon.MessageCircle size="42" />
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">{cardName ? cardName : "Inbound Chat Leads"}</div>
				</div>
				<div className="dashboard__card__stat">
					{isEnabled() && isPermissible() ? (
						<>
							<div className="dashboard__card__stat__count">{inboundMessagesCount}</div>
							<small>since joining</small>
						</>
					) : (
						`${isEnabled() ? "Messenger is not enabled. Contact support to get started." : "Messenger report data restricted."}`
					)}
				</div>
			</>
		);
	}
}

export default withLocation(InboundChatLeads);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";

// Moment imports
import "moment/locale/es";
import "moment/locale/fr";

let config = {
	fallbackLng: "en_US",
	lng: "en_US",
	resources: {
		// English
		en_CA: {
			translations: require("./locales/en_CA/translations.json")
		},
		en_US: {
			translations: require("./locales/en_US/translations.json")
		},
		en_GB: {
			translations: require("./locales/en_GB/translations.json")
		},
		// Spanish
		es_US: {
			translations: require("./locales/es_US/translations.json")
		},
		// French
		fr_CA: {
			translations: require("./locales/fr_CA/translations.json")
		}
	},
	ns: ["translations"],
	defaultNS: "translations",

	/*
		This enables the debug mode in the i18n framework. When set to true, 
		additional information about the internal state of the library and its 
		processes will be logged to the console. This is useful for developers to 
		troubleshoot issues related to localization and translation, such as missing keys, 
		loading errors, or to verify which translations are being used.
	*/
	debug: false,

	/*
		This setting, when enabled, allows the i18n library to handle missing translations. 
		That is by calling missingKeyHandler.
	*/
	saveMissing: true,
	missingKeyHandler: (lng, ns, key, fallbackValue) => {
		// console.log(`i18n: Missing translation: ${key} [lng: ${lng}, ns: ${ns}, fallbackValue: ${fallbackValue}]`);
	}
};

// Setup i18n
i18n.use(initReactI18next).init(config);
i18n.languages = Object.keys(config.resources);

// Default the moment localization
let defaultLanguageForMoment = config.lng.split('_')[0];
moment.locale(defaultLanguageForMoment);

export default i18n;

import React from "react";
import ContentLoader from "react-content-loader";

import TeamChatService from "../../../../services/TeamChatService";
import ToastService from "../../../../services/ToastService";
import UtilityService from "../../../../services/UtilityService";
import UserService from "../../../../services/UserService";
import NotificationService from "../../../../services/NotificationService";
import LocationService from "../../../../services/LocationService";

import Alert from "../../../../components/common/Alert";
import TeamChatTaskListItem from "./TeamChatTaskListItem";
import UpdateTasksModal from "../../../Tasks/UpdateTasksModal";

import { TASK_STATUS } from "../../../../constants/LocationConstants";

import "./team-chat-task-list.css";

class TeamChatScheduledMessagesList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tasks: [],

			/* Related to the modal */
			taskEditMessageId: null,
			showModalTasks: false,

			loading: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async (showLoading = true) => {
		let { conversationId } = this.props;

		let locationId = UserService.getActiveLocation().id;

		if (showLoading) {
			await this.update({ loading: true });
		}

		let tasks = await LocationService.fetchTeamChatTasks({ locationId, conversationId, ignoreDone: true });

		if (!tasks) {
			ToastService.error("Failed to fetch tasks");
			await this.update({
				loading: false,
				tasks: []
			});
			return;
		}

		await this.update({
			loading: false,
			tasks
		});
	};

	onMarkAsDone = async task => {
		let taskId = task.id;
		let status = TASK_STATUS.done;
		let locationId = UserService.getActiveLocation().id;

		let response = await LocationService.updateTask({ locationId, taskId, status });
		if (!response) {
			ToastService.info("An error occured marking the task as done.");
			return;
		}

		ToastService.info(`Successfully marked as done.`);

		this.resetComponent(false);
	};

	onDeleteTask = async task => {
		let taskId = task.id;
		let locationId = UserService.getActiveLocation().id;

		let response = await LocationService.deleteTask({ taskId, locationId });

		if (!response) {
			ToastService.error(`Error deleting task.`);
			return;
		}

		ToastService.info(`Successfully deleted task.`);

		this.resetComponent(false);
	};

	onEditTask = async task => {
		await this.update({ showModalTasks: true, taskEditMessageId: task.id });
	};

	onReferencedMessageClick = async messageId => {
		if (this.props.onReferencedMessageClick) {
			await this.props.onReferencedMessageClick(messageId);
		}
	};

	onTaskSubmit = async () => {
		await this.update({ showModalTasks: false });
		this.resetComponent(false);
	};

	onHideTaskModal = async () => {
		await this.update({ showModalTasks: false });
		this.resetComponent(false);
	};

	render = () => {
		let { loading, tasks, showModalTasks, taskEditMessageId } = this.state;
		let { onClose } = this.props;

		if (loading) {
			return (
				<div className="mb-tctk">
					<div className="mb-tctk__back" onClick={onClose}>
						Loading Tasks for this conversation.
						<div className="mb-tctk__back__message">Back to Messages</div>
					</div>
					<ContentLoader height={600} width={"100%"}>
						<rect x="20" y="0" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="155" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="310" rx="5" ry="5" width="98%" height="135" />
						<rect x="20" y="465" rx="5" ry="5" width="98%" height="135" />
					</ContentLoader>
				</div>
			);
		}

		if (!tasks || tasks.length === 0) {
			return (
				<div className="mb-tctk">
					<div className="mb-tctk__back" onClick={onClose}>
						No Tasks found for this conversation.
						<div className="mb-tctk__back__message">Back to Messages</div>
					</div>
				</div>
			);
		}

		return (
			<>
				<div className="mb-tctk">
					<div className="mb-tctk__back" onClick={onClose}>
						Tasks for this conversation.
						<div className="mb-tctk__back__message">Back to Messages</div>
					</div>
					{tasks.map(task => (
						<TeamChatTaskListItem
							key={`${task.id}`}
							task={task}
							onReferencedMessageClick={this.onReferencedMessageClick}
							onMarkAsDone={() => this.onMarkAsDone(task)}
							onEditTask={() => this.onEditTask(task)}
							onDeleteTask={() => this.onDeleteTask(task)}
						/>
					))}
				</div>
				<UpdateTasksModal show={showModalTasks} taskId={taskEditMessageId} createMode={false} onSubmit={this.onTaskSubmit} onHide={this.onHideTaskModal} />
			</>
		);
	};
}

export default TeamChatScheduledMessagesList;

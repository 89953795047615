import React from "react";
import moment from "moment";
import * as Icon from "react-feather";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import Action from "../../../../components/common/Action";
import TextArea from "../../../../components/common/TextArea";
import TeamChatMessage from "./TeamChatMessage";

import "./team-chat-task-list.css";

class TeamChatTaskListItem extends React.PureComponent {
	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onReferencedMessageClick = async () => {
		let { task } = this.props;

		let messageId = task.Message.id;

		if (this.props.onReferencedMessageClick) {
			await this.props.onReferencedMessageClick(messageId);
		}
	};

	render = () => {
		let { task, onMarkAsDone, onEditTask, onDeleteTask } = this.props;

		let taskContent = task.content;
		let taskTitle = task.title;
		let taskMessage = task.Message;

		return (
			<div className="mb-tctk__item">
				<div className="mb-tctk__item__header">
					<div className="mb-tctk__item__header__title">{taskTitle}</div>
					<div className="mb-tctk__item__header__actions">
						<Action id={`done-${task.id}`} label="Mark as Done" icon={Icon.CheckCircle} onClick={onMarkAsDone} />
						<Action id={`edit-${task.id}`} label="Edit" icon={Icon.Edit} onClick={onEditTask} />
						<Action id={`delete-${task.id}`} label="Delete" icon={Icon.Trash2} onClick={onDeleteTask} />
					</div>
				</div>
				<div className="mb-tctk__item__content">
					<div className="mb-tctk__item__task_content">{taskContent}</div>
					<div className="mb-tctk__item__associated_message" onClick={this.onReferencedMessageClick}>
						<div className="mb-tctk__item__associated_message__name">{taskMessage.sender_user_name}</div>
						<div className="mb-tctk__item__associated_message__content">
							<TeamChatMessage
								message={{ event_type: "message", id: taskMessage.id, content: taskMessage.content, media: taskMessage.media }}
								hideHoverOptions={true}
								hideDate={true}
								horizontalMedia={true}
								mediaMaxHeightOrWidth={100}
								mediaReadOnly={true}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default TeamChatTaskListItem;

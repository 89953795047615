import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Spinners from "../../components/common/Spinners";
import TotalReviews from "../Dashboard/TotalReviews";
import ReviewGoal from "../Dashboard/ReviewGoal";
import AverageRating from "../Dashboard/AverageRating";
import TotalReplies from "../Dashboard/TotalReplies";
import withLocation from "../../components/common/WithLocation";
import ReviewsBySite from "./Location/ReviewsBySite";
import RatingsBySite from "./Location/RatingsBySite";
import DashboardCard from "../Dashboard/DashboardCard";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { AnalyticsService } from "../../services/AnalyticsService";
import GmbService from "../../services/GmbService";

import "../../styles/css/scenes/analytics.css";
import "../../App.css";

class AnalyticsSummary extends Component {
	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	renderBody = () => {
		return (
			<>
				<div className="analytics-page__split">
					<div className="analytics-page__split__card">
						<AverageRating isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
					</div>
					<div className="analytics-page__split__card">
						<ReviewGoal isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
					</div>
				</div>
				<div className="analytics-page__split">
					<div className="analytics-page__split__card">
						<TotalReviews isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
					</div>
					<div className="analytics-page__split__card">
						<TotalReplies isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
					</div>
				</div>
				<div className="analytics-page__split">
					<div className="analytics-page__split__card">
						<ReviewsBySite isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
					</div>
					<div className="analytics-page__split__card">
						<RatingsBySite isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
					</div>
				</div>
			</>
		);
	};

	render() {
		return (
			<Page>
				<Header title="Review Summary" />
				<div className="dh-page__cards">{this.renderBody()}</div>
			</Page>
		);
	}
}

export default withRouter(withLocation(AnalyticsSummary));

import React from "react";
import { withTranslation } from "react-i18next";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import ReviewSites from "../../ReviewSites/ReviewSites";
import ReviewsGeneral from "./ReviewsGeneral";
import InviteQueue from "./InviteQueue";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";

import { REVIEW_TABS } from "../../../constants/Settings";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

class Reviews extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: REVIEW_TABS.general.id
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.reviews,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let location = UserService.getActiveLocation();

		return (
			<Page>
				<Header title={t("Reviews")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={REVIEW_TABS.general.id} value={REVIEW_TABS.general.value} />
					{user.GroupPermission.view_review_sites && <Tab id={REVIEW_TABS.reviewSites.id} value={REVIEW_TABS.reviewSites.value} />}
					{location.LocationFeature.reviews_invites_queue && <Tab id={REVIEW_TABS.reviewInviteQueue.id} value={REVIEW_TABS.reviewInviteQueue.value} />}
				</Tabs>
				{REVIEW_TABS.reviewSites.id === selectedTab && <ReviewSites />}
				{REVIEW_TABS.general.id === selectedTab && <ReviewsGeneral />}
				{REVIEW_TABS.reviewInviteQueue.id === selectedTab && <InviteQueue />}
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(Reviews);

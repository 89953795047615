import React, { Component } from "react";
import ReactSwitch from "react-switch";
import Select from "react-select";
import ToastService from "../../../../services/ToastService";
import UtilityService from "../../../../services/UtilityService";

import LocationService from "../../../../services/LocationService";
import UserService from "../../../../services/UserService";

import Modal from "../../../../components/common/DHModal";

import { SCHEDULE } from "../../../../constants/LocationConstants";
import { MERIDIAN_TIMES_OPTIONS, TWELVE_HOUR_OPTIONS, FIFTEEN_MINUTE_OPTIONS } from "../../../../constants/CommonConstants";

import "../../../../styles/css/scenes/booking-widget-booking-slots.css";

import "react-toastify/dist/ReactToastify.css";

class BookingSlots extends Component {
	state = {
		schedule: null,
		daySchedules: null,
		saving: false,

		openTimeMeridian: MERIDIAN_TIMES_OPTIONS.am.value,
		openTimeHour: null,
		openTimeMinute: null,
		openTimeMeridianOptions: Object.values(MERIDIAN_TIMES_OPTIONS),
		openTimeHourOptions: Object.values(TWELVE_HOUR_OPTIONS),
		openTimeMinuteOptions: Object.values(FIFTEEN_MINUTE_OPTIONS),

		closeTimeMeridian: MERIDIAN_TIMES_OPTIONS.am.value,
		closeTimeHour: null,
		closeTimeMinute: null,
		closeTimeMeridianOptions: Object.values(MERIDIAN_TIMES_OPTIONS),
		closeTimeHourOptions: Object.values(TWELVE_HOUR_OPTIONS),
		closeTimeMinuteOptions: Object.values(FIFTEEN_MINUTE_OPTIONS),

		scheduleBeforeHour: null,
		scheduleBeforeMinute: null,
		scheduleBeforeMeridian: null,

		scheduleAfterHour: null,
		scheduleAfterMinute: null,
		scheduleAfterMeridian: null,

		changesMade: false
	};

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	resetComponent = async () => {
		await this.update({
			// General
			schedule: null,
			daySchedules: null,
			allSchedules: null,
			saving: false,
			changesMade: false,

			// Open time values
			openTimeMeridian: MERIDIAN_TIMES_OPTIONS.am.value,
			openTimeHour: null,
			openTimeMinute: null,
			// Open time options
			openTimeMeridianOptions: Object.values(MERIDIAN_TIMES_OPTIONS),
			openTimeHourOptions: Object.values(TWELVE_HOUR_OPTIONS),
			openTimeMinuteOptions: Object.values(FIFTEEN_MINUTE_OPTIONS),

			// Close time values
			closeTimeMeridian: MERIDIAN_TIMES_OPTIONS.am.value,
			closeTimeHour: null,
			closeTimeMinute: null,
			// Close time options
			closeTimeMeridianOptions: Object.values(MERIDIAN_TIMES_OPTIONS),
			closeTimeHourOptions: Object.values(TWELVE_HOUR_OPTIONS),
			closeTimeMinuteOptions: Object.values(FIFTEEN_MINUTE_OPTIONS),

			// The schedule before (the close time) the selected schedule
			scheduleBeforeHour: null,
			scheduleBeforeMinute: null,
			scheduleBeforeMeridian: null,

			// The schedule after (the open time) the selected schedule
			scheduleAfterHour: null,
			scheduleAfterMinute: null,
			scheduleAfterMeridian: null
		});

		await this.onScheduleChange();
	};

	onScheduleChange = async () => {
		await this.fetchSchedule();

		let { schedule } = this.state;

		let { hour: openTimeHour, minute: openTimeMinute, meridian: openTimeMeridian } = UtilityService.getTimeData(schedule.timingsArray[0].open);
		let { hour: closeTimeHour, minute: closeTimeMinute, meridian: closeTimeMeridian } = UtilityService.getTimeData(schedule.timingsArray[0].close);

		openTimeHour = { value: openTimeHour === 12 && openTimeMeridian === MERIDIAN_TIMES_OPTIONS.pm.value ? 0 : openTimeHour, label: openTimeHour };
		openTimeMinute = { value: openTimeMinute, label: this.padStart(openTimeMinute) };
		closeTimeHour = { value: closeTimeHour === 12 && closeTimeMeridian === MERIDIAN_TIMES_OPTIONS.pm.value ? 0 : closeTimeHour, label: closeTimeHour };
		closeTimeMinute = { value: closeTimeMinute, label: this.padStart(closeTimeMinute) };

		await this.update({
			schedule: { ...schedule },
			openTimeHour,
			openTimeMinute,
			openTimeMeridian,
			closeTimeHour,
			closeTimeMinute,
			closeTimeMeridian
		});

		await this.fetchSchedules();
		await this.resolveBeforeAndAfterSchedules();
		await this.updateOptions();
	};

	fetchSchedule = async () => {
		try {
			let { schedule } = this.props;

			if (!schedule) {
				throw new Error("Schedule missing from parent component");
			}

			let scheduleCopy = { ...schedule };

			await this.update({ schedule: scheduleCopy });
		} catch (error) {
			console.log(error);
		}
	};

	fetchSchedules = async () => {
		try {
			let { schedule } = this.state;
			let locationId = UserService.getActiveLocation().id;

			let daySchedules = await LocationService.getSchedulesForLocation(locationId, SCHEDULE.types.bookingRequestSlot, [schedule.day]);
			let allSchedules = await LocationService.getSchedulesForLocation(locationId, SCHEDULE.types.bookingRequestSlot);

			if (!daySchedules || !allSchedules) {
				ToastService.error(`Unable to fetch schedules.`);
				return;
			}

			await this.update({ daySchedules, allSchedules });
		} catch (error) {
			console.log(error);
		}
	};

	updateOptions = async () => {
		let {
			openTimeHour,
			openTimeMinute,
			openTimeMeridian,

			closeTimeHour,
			closeTimeMinute,
			closeTimeMeridian,

			schedule
		} = this.state;

		if (!schedule) {
			return;
		}

		// The default time options
		let openTimeMeridianOptions = Object.values(MERIDIAN_TIMES_OPTIONS);
		let openTimeHourOptions = Object.values(TWELVE_HOUR_OPTIONS);
		let openTimeMinuteOptions = Object.values(FIFTEEN_MINUTE_OPTIONS);

		let closeTimeMeridianOptions = Object.values(MERIDIAN_TIMES_OPTIONS);
		let closeTimeHourOptions = Object.values(TWELVE_HOUR_OPTIONS);
		let closeTimeMinuteOptions = Object.values(FIFTEEN_MINUTE_OPTIONS);

		/**
		 * Filter Options
		 */
		// If open time is PM, close time cannot be AM
		if (openTimeMeridian === MERIDIAN_TIMES_OPTIONS.pm.value) {
			closeTimeMeridian = MERIDIAN_TIMES_OPTIONS.pm.value;
			closeTimeMeridianOptions = closeTimeMeridianOptions.filter(time => time.value === MERIDIAN_TIMES_OPTIONS.pm.value);
		}
		// If close time is AM, open time cannot be AM
		if (closeTimeMeridian === MERIDIAN_TIMES_OPTIONS.am.value) {
			openTimeMeridian = MERIDIAN_TIMES_OPTIONS.am.value;
			openTimeMeridianOptions = closeTimeMeridianOptions.filter(time => time.value === MERIDIAN_TIMES_OPTIONS.am.value);
		}

		// Filter hours options, just within the schedule itself
		// Open time hour cannot be greater than the close time hour, and vice versa
		if (openTimeMeridian === closeTimeMeridian) {
			openTimeHourOptions = openTimeHourOptions.filter(time => time.value <= closeTimeHour.value);
			closeTimeHourOptions = closeTimeHourOptions.filter(time => time.value >= openTimeHour.value);
		}

		// Set the min and max minute options
		// If the open our and the close hour are the same, we must filter the selectable minute options
		if (openTimeHour.value === closeTimeHour.value && openTimeMeridian === closeTimeMeridian) {
			openTimeMinuteOptions = openTimeMinuteOptions.filter(m => m.value <= closeTimeMinute.value);
			closeTimeMinuteOptions = closeTimeMinuteOptions.filter(m => m.value >= openTimeMinute.value);
		}

		// Filter based on before/after schedules
		let {
			scheduleBeforeHour,
			scheduleBeforeMinute,
			scheduleBeforeMeridian,

			scheduleAfterHour,
			scheduleAfterMinute,
			scheduleAfterMeridian
		} = this.state;

		// The schedule time in 24 hour format
		let { hour: scheduleOpenHour } = UtilityService.getTimeData(schedule.timingsArray[0].open, false);
		let { hour: scheduleCloseHour } = UtilityService.getTimeData(schedule.timingsArray[0].close, false);

		// The open time hour must be >= the schedule before close hour
		if (scheduleBeforeHour !== null && scheduleBeforeMinute !== null && scheduleBeforeMeridian === openTimeMeridian) {
			let hour = scheduleBeforeHour >= 12 ? scheduleBeforeHour - 12 : scheduleBeforeHour;
			openTimeHourOptions = openTimeHourOptions.filter(time => time.value >= hour);
		}

		// If the open hour and the schedule before close hour is the same, the minute options must be >= to the schedule before minute
		if (scheduleBeforeHour !== null && scheduleBeforeHour === scheduleOpenHour && scheduleBeforeMeridian === openTimeMeridian) {
			openTimeMinuteOptions = openTimeMinuteOptions.filter(time => time.value >= scheduleBeforeMinute);
		}

		// The close hour must be <= the schedule after open hour
		if (scheduleAfterHour !== null && scheduleAfterMinute !== null && scheduleAfterMeridian === closeTimeMeridian) {
			let hour = scheduleAfterHour >= 12 ? scheduleAfterHour - 12 : scheduleAfterHour;
			closeTimeHourOptions = closeTimeHourOptions.filter(time => time.value <= hour);
		}
		// If the close hour and the schedule after open hour is the same, the minute options must be <= to the schedule after minute
		if (scheduleAfterHour !== null && scheduleAfterHour === scheduleCloseHour && scheduleAfterMeridian === closeTimeMeridian) {
			closeTimeMinuteOptions = closeTimeMinuteOptions.filter(time => time.value <= scheduleAfterMinute);
		}

		// Restrictions on the Meridian time options
		if (scheduleBeforeMeridian === MERIDIAN_TIMES_OPTIONS.pm.value) {
			openTimeMeridianOptions = openTimeMeridianOptions.filter(time => time.value === MERIDIAN_TIMES_OPTIONS.pm.value);
		}
		if (scheduleAfterMeridian === MERIDIAN_TIMES_OPTIONS.am.value) {
			closeTimeMeridianOptions = closeTimeMeridianOptions.filter(time => time.value === MERIDIAN_TIMES_OPTIONS.am.value);
		}

		// Select default options if the values do not exist in the filtered options
		// For open times
		if (openTimeHourOptions.length > 0 && openTimeHourOptions.findIndex(hour => hour.value === openTimeHour.value) === -1) {
			openTimeHour = openTimeHourOptions[0];
		}
		if (openTimeMinuteOptions.length > 0 && openTimeMinuteOptions.findIndex(minute => minute.value === openTimeMinute.value) === -1) {
			openTimeMinute = openTimeMinuteOptions[0];
		}
		if (openTimeMeridianOptions.length > 0 && openTimeMeridianOptions.findIndex(meridian => meridian.value === openTimeMeridian) === -1) {
			openTimeMeridian = openTimeMeridianOptions[0];
		}

		// For close times
		if (closeTimeHourOptions.length > 0 && closeTimeHourOptions.findIndex(hour => hour.value === closeTimeHour.value) === -1) {
			closeTimeHour = closeTimeHourOptions[0];
		}
		if (closeTimeMinuteOptions.length > 0 && closeTimeMinuteOptions.findIndex(minute => minute.value === closeTimeMinute.value) === -1) {
			closeTimeMinute = closeTimeMinuteOptions[0];
		}
		if (closeTimeMeridianOptions.length > 0 && closeTimeMeridianOptions.findIndex(meridian => meridian.value === closeTimeMeridian) === -1) {
			closeTimeMeridian = closeTimeMeridianOptions && closeTimeMeridianOptions[0] ? closeTimeMeridianOptions[0].value : null;
		}

		await this.update({
			openTimeHour,
			openTimeMinute,
			openTimeMeridian,
			openTimeHourOptions,
			openTimeMinuteOptions,
			openTimeMeridianOptions,

			closeTimeHour,
			closeTimeMinute,
			closeTimeMeridian,
			closeTimeHourOptions,
			closeTimeMinuteOptions,
			closeTimeMeridianOptions
		});

		await this.updateSchedule();
	};

	resolveBeforeAndAfterSchedules = async () => {
		let { schedule, daySchedules } = this.state;

		if (!schedule || !daySchedules) {
			return;
		}

		let scheduleBeforeHour = null;
		let scheduleBeforeMinute = null;
		let scheduleBeforeMeridian = null;

		let scheduleAfterHour = null;
		let scheduleAfterMinute = null;
		let scheduleAfterMeridian = null;

		// The schedule time in 24 hour format
		let { hour: scheduleOpenHour, minute: scheduleOpenMinute } = UtilityService.getTimeData(schedule.timingsArray[0].open, false);
		let { hour: scheduleCloseHour, minute: scheduleCloseMinute } = UtilityService.getTimeData(schedule.timingsArray[0].close, false);

		// Get all the schedules that are the same day as the selected schedule
		let sameDayOfWeekSchedules = daySchedules.filter(s => s.day === schedule.day && s.id !== schedule.id);

		for (let i = 0; i < sameDayOfWeekSchedules.length; i++) {
			const aSchedule = sameDayOfWeekSchedules[i];
			// Get the open time in 24 hour format (for easier comparisons)
			let { hour: openHour, minute: openMinute } = UtilityService.getTimeData(aSchedule.timingsArray[0].open, false);
			let { meridian: openMeridian } = UtilityService.getTimeData(aSchedule.timingsArray[0].open, true);

			// Get the close time in 24 hour format (for easier comparisons)
			let { hour: closeHour, minute: closeMinute } = UtilityService.getTimeData(aSchedule.timingsArray[0].close, false);
			let { meridian: closeMeridian } = UtilityService.getTimeData(aSchedule.timingsArray[0].close, true);

			// If we found a schedule that ends before the selected schedule starts
			if (closeHour < scheduleOpenHour || (closeHour === scheduleOpenHour && closeMinute <= scheduleOpenMinute)) {
				// A schedule before the selected schedule was found

				// If this is the latest close time
				if (!scheduleBeforeHour || closeHour > scheduleBeforeHour) {
					scheduleBeforeHour = closeHour;
					scheduleBeforeMinute = closeMinute;
					scheduleBeforeMeridian = closeMeridian;
				}

				// Continue to the next option
				continue;
			}

			// If we found a schedule that starts after the select schedule ends
			if (openHour > scheduleCloseHour || (openHour === scheduleCloseHour && openMinute >= scheduleCloseMinute)) {
				// A schedule after the selected schedule was found

				// If this is the earliest open time
				if (!scheduleAfterHour || openHour < scheduleAfterHour) {
					scheduleAfterHour = openHour;
					scheduleAfterMinute = openMinute;

					scheduleAfterMeridian = openMeridian;
				}

				// Continue to the next option
				continue;
			}
		}

		await this.update({
			scheduleBeforeHour,
			scheduleBeforeMinute,
			scheduleBeforeMeridian,

			scheduleAfterHour,
			scheduleAfterMinute,
			scheduleAfterMeridian
		});
	};

	padStart(value) {
		return String(value).padStart(2, "0");
	}

	handleOnSubmit = async () => {
		let { schedule, allSchedules } = this.state;

		if (this.isSaveDisabled()) {
			return;
		}

		await this.update({ saving: true });

		// Find the place to edit this schedule
		for (let i = 0; i < allSchedules.length; i++) {
			let currentSchedule = allSchedules[i];

			if (currentSchedule.day === schedule.day && currentSchedule.friendly_name === schedule.friendly_name) {
				allSchedules[i] = schedule;
			}
		}

		let locationId = UserService.getActiveLocation().id;

		// Submit the new schedule
		let response = await LocationService.updateSchedule({
			locationId,
			type: SCHEDULE.types.bookingRequestSlot,
			schedule: allSchedules
		});

		await this.update({ saving: false });

		if (!response) {
			ToastService.error(`Error saving. Please try again.`);
			return;
		}

		ToastService.info(`Saved.`);

		if (this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	onHide = async () => {
		let { onHide } = this.props;

		if (onHide) {
			onHide();
		}

		await this.resetComponent();
	};

	onClosedChange = async newValue => {
		let { schedule } = this.state;

		schedule["closed"] = schedule["closed"] === 0 ? 1 : 0;

		await this.update({ schedule, changesMade: true });
	};

	onOptionChange = async (field, option) => {
		// Update the schedule info
		await this.update({ [field]: option, changesMade: true });
		await this.updateSchedule();
		// Check the times of this schedule
		await this.checkOpenCloseTimes(field);
		// Check the times of the schedules before and after this selected schedule
		await this.checkBeforeAfterTimes(field);

		// Change the options for the hours/minutes/medirian of the open/close
		await this.updateOptions();
	};

	checkOpenCloseTimes = async field => {
		let { openTimeHour, openTimeMinute, openTimeMeridian, closeTimeHour, closeTimeMinute, closeTimeMeridian } = this.state;

		// Look at these fields
		let openFields = ["openTimeHour", "openTimeMinute", "openTimeMeridian"];
		let closeFields = ["closeTimeHour", "closeTimeMinute", "closeTimeMeridian"];

		// If the open time changed, but the open time is greater than the close time
		if (
			openFields.includes(field) &&
			openTimeMeridian === closeTimeMeridian &&
			(openTimeHour.value > closeTimeHour.value || (openTimeHour.value === closeTimeHour.value && openTimeMinute.value > closeTimeMinute.value))
		) {
			openTimeHour = closeTimeHour;
			openTimeMinute = closeTimeMinute;
			openTimeMeridian = closeTimeMeridian;
		}

		// If the close time changed, but the open time is greater than the close time
		if (
			closeFields.includes(field) &&
			openTimeMeridian === closeTimeMeridian &&
			(openTimeHour.value > closeTimeHour.value || (openTimeHour.value === closeTimeHour.value && openTimeMinute.value > closeTimeMinute.value))
		) {
			closeTimeHour = openTimeHour;
			closeTimeMinute = openTimeMinute;
			closeTimeMeridian = openTimeMeridian;
		}

		// Update the state fields
		await this.update({ openTimeHour, openTimeMinute, openTimeMeridian, closeTimeHour, closeTimeMinute, closeTimeMeridian });
		// Update the schedule opbject
		await this.updateSchedule();
	};

	checkBeforeAfterTimes = async field => {
		let { schedule, openTimeHour, openTimeMinute, openTimeMeridian, closeTimeHour, closeTimeMinute, closeTimeMeridian } = this.state;

		let openFields = ["openTimeHour", "openTimeMinute", "openTimeMeridian"];
		let closeFields = ["closeTimeHour", "closeTimeMinute", "closeTimeMeridian"];

		// The hour/minute/meridian of the schedule before and after the selected schedule
		let {
			scheduleBeforeHour,
			scheduleBeforeMinute,
			scheduleBeforeMeridian,

			scheduleAfterHour,
			scheduleAfterMinute,
			scheduleAfterMeridian
		} = this.state;

		// The schedule time in 24 hour format
		let { hour: scheduleOpenHour, minute: scheduleOpenMinute } = UtilityService.getTimeData(schedule.timingsArray[0].open, false);
		let { hour: scheduleCloseHour, minute: scheduleCloseMinute } = UtilityService.getTimeData(schedule.timingsArray[0].close, false);

		// If the new selected open time is less than the previous' schedule end time
		if (
			openFields.includes(field) &&
			scheduleBeforeHour !== null &&
			scheduleBeforeMinute !== null &&
			scheduleBeforeMeridian !== null &&
			(scheduleOpenHour < scheduleBeforeHour || (scheduleOpenHour === scheduleBeforeHour && scheduleOpenMinute < scheduleBeforeMinute))
		) {
			let hour = scheduleBeforeHour > 12 ? scheduleBeforeHour - 12 : scheduleBeforeHour;
			openTimeHour = { value: hour, label: `${hour}` };
			openTimeMinute = { value: scheduleBeforeMinute, label: `${this.padStart(scheduleBeforeMinute)}` };
			openTimeMeridian = scheduleBeforeMeridian;
		}

		// If the new selected close time is greater than the previous' schedule open time
		if (
			closeFields.includes(field) &&
			scheduleAfterHour !== null &&
			scheduleAfterMinute !== null &&
			scheduleAfterMeridian !== null &&
			(scheduleCloseHour > scheduleAfterHour || (scheduleCloseHour === scheduleAfterHour && scheduleCloseMinute > scheduleAfterMinute))
		) {
			let hour = scheduleAfterHour > 12 ? scheduleAfterHour - 12 : scheduleAfterHour;
			closeTimeHour = { value: hour, label: `${hour}` };
			closeTimeMinute = { value: scheduleAfterMinute, label: `${this.padStart(scheduleAfterMinute)}` };
			closeTimeMeridian = scheduleAfterMeridian;
		}

		// Update the state fields
		await this.update({ openTimeHour, openTimeMinute, openTimeMeridian, closeTimeHour, closeTimeMinute, closeTimeMeridian });
		// Update the schedule opbject
		await this.updateSchedule();
	};

	updateSchedule = async () => {
		let { schedule, openTimeHour, openTimeMinute, openTimeMeridian, closeTimeHour, closeTimeMinute, closeTimeMeridian } = this.state;

		// Convert the hour to 24 hour time
		let openHour = openTimeHour.value;
		let closeHour = closeTimeHour.value;
		if (openTimeMeridian === MERIDIAN_TIMES_OPTIONS.pm.value && openHour < 12) {
			openHour += 12;
		}
		if (closeTimeMeridian === MERIDIAN_TIMES_OPTIONS.pm.value && closeHour < 12) {
			closeHour += 12;
		}

		// Update the open and close time
		schedule.timingsArray[0].open = `${this.padStart(openHour)}:${this.padStart(openTimeMinute.value)}`;
		schedule.timingsArray[0].close = `${this.padStart(closeHour)}:${this.padStart(closeTimeMinute.value)}`;

		// Update the schedule opbject
		await this.update({ schedule });
	};

	isSaveDisabled = () => {
		return !this.state.changesMade || this.state.saving;
	};

	render() {
		let { show } = this.props;
		let {
			saving,
			schedule,
			openTimeMeridian,
			openTimeHour,
			openTimeMinute,
			openTimeMeridianOptions,
			openTimeHourOptions,
			openTimeMinuteOptions,
			closeTimeMeridian,
			closeTimeHour,
			closeTimeMinute,
			closeTimeMeridianOptions,
			closeTimeHourOptions,
			closeTimeMinuteOptions
		} = this.state;

		if (!schedule) {
			return null;
		}

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return (
			<>
				<Modal title={schedule ? `${schedule.day} - ${schedule.friendly_name}` : `Edit Service`} show={show} onHide={this.onHide}>
					<div className="bwbs__modal">
						<div className="bwbs__modal__row">
							{/* Modify Open Time */}
							<div className="bwbs__modal__row__title">Open Time</div>
							<div className="bwbs__modal__row__inputs">
								<div className="bwbs__modal__row__inputs__item">
									<div className="">Hour</div>
									<Select
										id="open-time-hours"
										options={openTimeHourOptions}
										value={openTimeHour}
										placeholder="Hour"
										onChange={value => this.onOptionChange("openTimeHour", value)}
										isDisabled={!allowUpdate}
									/>
								</div>
								<div className="bwbs__modal__row__inputs__item">
									<div className="">Minute</div>
									<Select
										id="open-time-minutes"
										options={openTimeMinuteOptions}
										value={openTimeMinute}
										placeholder="Minute"
										onChange={value => this.onOptionChange("openTimeMinute", value)}
										isDisabled={!allowUpdate}
									/>
								</div>
								<div className="bwbs__modal__row__inputs__item">
									<div className="">AM/PM</div>
									<Select
										id="meridian-time-open"
										options={openTimeMeridianOptions}
										value={MERIDIAN_TIMES_OPTIONS[openTimeMeridian]}
										placeholder="AM or PM"
										onChange={option => this.onOptionChange("openTimeMeridian", option.value)}
										isDisabled={!allowUpdate}
									/>
								</div>
							</div>
						</div>
						{/* Modify Close Time */}
						<div className="bwbs__modal__row">
							<div className="bwbs__modal__row__title">Close Time</div>
							<div className="bwbs__modal__row__inputs">
								<div className="bwbs__modal__row__inputs__item">
									<div className="">Hour</div>
									<Select
										id="close-time-hours"
										options={closeTimeHourOptions}
										value={closeTimeHour}
										placeholder="Hour"
										onChange={value => this.onOptionChange("closeTimeHour", value)}
										isDisabled={!allowUpdate}
									/>
								</div>
								<div className="bwbs__modal__row__inputs__item">
									<div className="">Minute</div>
									<Select
										id="close-time-minutes"
										options={closeTimeMinuteOptions}
										value={closeTimeMinute}
										placeholder="Minute"
										onChange={value => this.onOptionChange("closeTimeMinute", value)}
										isDisabled={!allowUpdate}
									/>
								</div>
								<div className="bwbs__modal__row__inputs__item">
									<div className="">AM/PM</div>
									<Select
										id="meridian-time-close"
										options={closeTimeMeridianOptions}
										value={MERIDIAN_TIMES_OPTIONS[closeTimeMeridian]}
										placeholder="AM or PM"
										onChange={option => this.onOptionChange("closeTimeMeridian", option.value)}
										isDisabled={!allowUpdate}
									/>
								</div>
							</div>
						</div>
						{/* Modify If Closed */}
						<div className="bwbs__modal__row">
							<div className="bwbs__modal__row__title">Closed</div>
							<div className="bwbs__modal__row__item bwbs__modal__row--toggle__switch">
								<ReactSwitch
									id="closeDay"
									height={22}
									width={38}
									checked={schedule["closed"] === 1 ? true : false}
									uncheckedIcon={false}
									checkedIcon={false}
									onColor="#60A9FF"
									offColor="#c5c5c5"
									className={""}
									onChange={this.onClosedChange}
									disabled={!allowUpdate}
								/>
							</div>
						</div>
						{allowUpdate && (
							<div className="modal__actions">
								<div id="save-btn" className={`mb-button ${this.isSaveDisabled() ? "mb-button--disabled" : ""}`} onClick={this.handleOnSubmit}>
									{saving ? "Saving" : "Save"}
								</div>
							</div>
						)}
					</div>
				</Modal>
			</>
		);
	}
}

export default BookingSlots;

import React, { Component } from "react";
import posed, { PoseGroup } from "react-pose";
import ReactSwitch from "react-switch";
import ContentLoader from "react-content-loader";
import * as Icon from "react-feather";

import ToastService from "../../../../services/ToastService";
import UserService from "../../../../services/UserService";
import WidgetConfigService from "../../../../services/WidgetConfigService";
import TranslationService from "../../../../services/TranslationService";

import { WIDGET_NAME } from "../../../../constants/WidgetConstants";
import { STATUS } from "../../../../constants/CommonConstants";
import { CODES_TO_LABEL } from "../../../../constants/LanguageConstants";

import LanguageSelectorMini from "../../../../components/common/LanguageSelectorMini";
import Input from "../../../../components/common/Input";
import { ShowcaseList, ShowcaseRow } from "../../../../components/common/ShowcaseList";
import UnsavedChanges from "../../../../components/common/UnsavedChanges";
import EditReasonModal from "./EditReasonModal";

import "../../../../styles/css/scenes/widget-config.css";
import "../../../../styles/css/scenes/booking-widget-config.css";

export const Field = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 100
		}
	},
	exit: {
		y: 0,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

class General extends Component {
	state = {
		loading: false,
		saving: false,
		showFormInputs: false,
		widgetConfig: null,
		changesMade: false,

		languageSectionSupportedOptions: [],
		languageSectionHeadings: "en_CA",
		languageSectionReasons: "en_CA",
		languageSectionForm: "en_CA",
		languageSectionPayments: "en_CA",

		/* Related to reasons */
		showReasonsModal: false,
		reasonEditIndex: null,
		reasonModalCreateMode: false
	};

	componentDidMount() {
		this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	setLanguageSelectionOptions = async () => {
		let locationLanguage = TranslationService.getLocationLanguage();
		let supportedLocationOptions = TranslationService.getSupportedLanguagesOptions();

		await this.update({
			languageSectionSupportedOptions: supportedLocationOptions,
			languageSectionHeadings: locationLanguage,
			languageSectionReasons: locationLanguage,
			languageSectionForm: locationLanguage,
			languageSectionPayments: locationLanguage
		});
	};

	async resetComponent() {
		await this.update({ loading: true });

		// Set language related options before the config
		// So we know how to read the config correctly
		await this.setLanguageSelectionOptions();

		try {
			await this.fetchWidget();
		} catch (error) {
			console.log(error);
		}

		await this.update({
			loading: false,
			saving: false,
			showFormInputs: false,
			changesMade: false,

			/* Related to reasons */
			showReasonsModal: false,
			reasonEditIndex: null,
			reasonModalCreateMode: false
		});
	}

	fetchWidget = async () => {
		let locationId = UserService.getActiveLocation().id;
		let widget = await WidgetConfigService.findWidget({ locationId, name: WIDGET_NAME.embeddedBookingWidget });

		if (!widget) {
			ToastService.error(`Unable to fetch widget config. Please try again.`);
			return;
		}

		await this.update({ widgetConfig: widget });
	};

	updateWidget = async () => {
		let { widgetConfig } = this.state;

		if (widgetConfig.config.bookingRequestUrl) {
			// Check if the url has an http or https, if not append http://
			if (!widgetConfig.config.bookingRequestUrl.match(/^[a-zA-Z]+:\/\//)) {
				widgetConfig.config.bookingRequestUrl = "http://" + widgetConfig.config.bookingRequestUrl;
			}
		}

		let success = await WidgetConfigService.updateWidget({
			widgetId: widgetConfig.id,
			config: JSON.stringify(widgetConfig.config),
			status: widgetConfig.status
		});

		if (!success) {
			ToastService.error(`Unable to save widget. Please try again.`);
			return;
		}
		ToastService.info("Saved changes!");

		if (this.props.onConfigChange) {
			this.props.onConfigChange();
		}

		await this.update({ changesMade: false });
	};

	onCancelChanges = () => {
		this.resetComponent();
	};

	Switch = ({ id, field, checked, onChange, onConfigInputChange, className }) => {
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return (
			<ReactSwitch
				id={id}
				height={22}
				width={38}
				checked={checked}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor="#60A9FF"
				offColor="#c5c5c5"
				className={className}
				onChange={newValue => {
					if (onChange) {
						onChange(field, newValue);
					}
					if (onConfigInputChange) {
						onConfigInputChange(field, newValue);
					}
				}}
				disabled={!allowUpdate}
			/>
		);
	};

	onChange = (field, value) => {
		let { widgetConfig } = this.state;

		if (!widgetConfig.config) {
			widgetConfig.config = {};
		}

		widgetConfig.config[field] = value;

		this.update({
			widgetConfig: widgetConfig,
			changesMade: true
		});
	};

	onCalendarHeaderChange = value => {
		let { widgetConfig, languageSectionHeadings } = this.state;

		let newWidgetConfig = JSON.parse(JSON.stringify(widgetConfig));
		newWidgetConfig["config"]["calendarHeader"][languageSectionHeadings] = value;

		this.update({
			widgetConfig: newWidgetConfig,
			changesMade: true
		});
	};

	onCalendarSubHeaderChange = value => {
		let { widgetConfig, languageSectionHeadings } = this.state;

		let newWidgetConfig = JSON.parse(JSON.stringify(widgetConfig));
		newWidgetConfig["config"]["calendarSubHeader"][languageSectionHeadings] = value;

		this.update({
			widgetConfig: newWidgetConfig,
			changesMade: true
		});
	};

	onStatusChange = (field, value) => {
		let { widgetConfig } = this.state;

		if (value) {
			widgetConfig.status = STATUS.active;
		} else {
			widgetConfig.status = STATUS.inactive;
		}

		this.update({
			widgetConfig: widgetConfig,
			changesMade: true
		});
	};

	handleCreateReason = async () => {
		await this.update({
			showReasonsModal: true,
			reasonEditIndex: null,
			reasonModalCreateMode: true
		});
	};

	handleReasonEdit = async rowIndex => {
		await this.update({
			showReasonsModal: true,
			reasonEditIndex: rowIndex,
			reasonModalCreateMode: false
		});
	};

	handleReasonDelete = async rowIndex => {
		let { widgetConfig } = this.state;

		// Remove the item from the array
		let reasonsArray = widgetConfig.config.inputs.reason.options.slice();
		reasonsArray.splice(rowIndex, 1);
		widgetConfig.config.inputs.reason.options = reasonsArray;

		await this.update({
			widgetConfig: widgetConfig,
			changesMade: true
		});
	};

	handleReasonOrderChange = async (oldIndex, newIndex) => {
		let { widgetConfig } = this.state;

		// Swap the array
		let reasonsArray = widgetConfig.config.inputs.reason.options.slice();
		[reasonsArray[oldIndex], reasonsArray[newIndex]] = [reasonsArray[newIndex], reasonsArray[oldIndex]];
		widgetConfig.config.inputs.reason.options = reasonsArray;

		await this.update({
			widgetConfig: widgetConfig,
			changesMade: true
		});
	};

	closeEditReasonModal = async () => {
		await this.update({
			showReasonsModal: false,
			reasonEditIndex: null,
			reasonModalCreateMode: false
		});
	};

	handleReasonOnSave = async newValue => {
		let { widgetConfig, reasonEditIndex, reasonModalCreateMode, languageSectionReasons } = this.state;

		let reasonsArray = widgetConfig.config.inputs.reason.options.slice();

		if (!reasonModalCreateMode) {
			reasonsArray[reasonEditIndex][languageSectionReasons] = newValue;
		} else {
			let newReasonObject = {};

			// Make an entry for every language
			for (let key in CODES_TO_LABEL) {
				if (CODES_TO_LABEL.hasOwnProperty(key)) {
					newReasonObject[key] = newValue;
				}
			}

			reasonsArray.push(newReasonObject);
		}

		widgetConfig.config.inputs.reason.options = reasonsArray;

		await this.update({
			widgetConfig: widgetConfig,
			changesMade: true,
			showReasonsModal: false,
			reasonEditIndex: null
		});
	};

	onConfigInputChange = (field, option, value) => {
		let { widgetConfig, languageSectionForm } = this.state;

		if (!widgetConfig.config) {
			widgetConfig.config = {};
		}

		if (!widgetConfig.config.inputs) {
			widgetConfig.config.inputs = {};
		}

		if (!widgetConfig.config.inputs[field]) {
			widgetConfig.config.inputs[field] = {};
		}

		if (option === "label" || option === "placeholder") {
			widgetConfig.config.inputs[field][option][languageSectionForm] = value;
		} else {
			widgetConfig.config.inputs[field][option] = value;
		}

		this.update({
			widgetConfig: widgetConfig,
			changesMade: true
		});
	};

	onTrackingIdChange = value => {
		let { widgetConfig } = this.state;
		let { config } = widgetConfig;
		let { googleAnalytics } = config;

		if (!googleAnalytics) {
			googleAnalytics = {};
		}

		googleAnalytics.trackingId = value;

		config.googleAnalytics = googleAnalytics;
		widgetConfig.config = config;
		this.update({
			widgetConfig: widgetConfig,
			changesMade: true
		});
	};

	handlePaymentsSectionHeaderChange = value => {
		let { widgetConfig, languageSectionPayments } = this.state;

		let newConfig = JSON.parse(JSON.stringify(widgetConfig));
		newConfig["config"]["paymentsSectionHeader"][languageSectionPayments] = value;

		this.update({
			widgetConfig: newConfig,
			changesMade: true
		});
	};

	handlePaymentsSectionDescriptionChange = value => {
		let { widgetConfig, languageSectionPayments } = this.state;

		let newConfig = JSON.parse(JSON.stringify(widgetConfig));
		newConfig["config"]["paymentsSectionDescription"][languageSectionPayments] = value;

		this.update({
			widgetConfig: newConfig,
			changesMade: true
		});
	};

	onConfigShowChange = (field, value) => {
		// Enforce one of them to be shown
		let turnOffPhoneProblem = field === "phone" && value === false && this.state.widgetConfig.config.inputs["email"]["show"] === false;
		let turnOffEmailProblem = field === "email" && value === false && this.state.widgetConfig.config.inputs["phone"]["show"] === false;

		if (turnOffPhoneProblem || turnOffEmailProblem) {
			ToastService.error(`One of phone or email must be shown.`);
			return;
		}

		// If show is off, set the required to false for data consistency
		if (value === false) {
			this.onConfigInputChange(field, "required", false);
		}

		// If we will show the field and but not the other, make this one required
		let showPhoneOnlyProblem = field === "phone" && value === true && this.state.widgetConfig.config.inputs["email"]["show"] === false;
		let showEmailOnlyProblem = field === "email" && value === true && this.state.widgetConfig.config.inputs["phone"]["show"] === false;
		if (showPhoneOnlyProblem || showEmailOnlyProblem) {
			this.onConfigInputChange(field, "required", true);
		}

		// If we will hide the field and the other wasn't required, require the other
		let hidePhoneProblem = field === "phone" && value === false && this.state.widgetConfig.config.inputs["email"]["required"] === false;
		let hideEmailProblem = field === "email" && value === true && this.state.widgetConfig.config.inputs["phone"]["required"] === false;
		if (hidePhoneProblem || hideEmailProblem) {
			let otherField = field === "email" ? "phone" : "email";
			this.onConfigInputChange(otherField, "required", true);
		}

		this.onConfigInputChange(field, "show", value);
	};

	onConfigRequireChange = (field, value) => {
		// Enforce one of them to be required
		let turnOffPhoneProblem = field === "phone" && value === false && this.state.widgetConfig.config.inputs["email"]["required"] === false;
		let turnOffEmailProblem = field === "email" && value === false && this.state.widgetConfig.config.inputs["phone"]["required"] === false;

		if (turnOffPhoneProblem || turnOffEmailProblem) {
			ToastService.error(`One of phone or email must be required.`);
			return;
		}

		this.onConfigInputChange(field, "required", value);
	};

	renderInputOptions = field => {
		let { widgetConfig, languageSectionForm } = this.state;
		let { config } = widgetConfig;
		let { inputs } = config;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		let show = inputs[field] && typeof inputs[field]["show"] !== "undefined" ? inputs[field]["show"] : true;

		return (
			<div className="bwc__options__field__toggles">
				<div className="bwc__options__field__toggles__switch">
					<div>Show Field</div>
					<this.Switch
						id={field + "Show"}
						className="bwc__options__field__toggles__switch__btn"
						field={field}
						checked={show}
						onConfigInputChange={(field, value) => this.onConfigShowChange(field, value)}
					/>
				</div>
				{show && (
					<div className="bwc__options__field__toggles__switch">
						<div>Required Field</div>
						<this.Switch
							id={field + "Required"}
							className="bwc__options__field__toggles__switch__btn"
							field={field}
							checked={inputs[field] && typeof inputs[field]["required"] !== "undefined" ? inputs[field]["required"] : false}
							onConfigInputChange={(field, value) => this.onConfigRequireChange(field, value)}
						/>
					</div>
				)}
				{show && (
					<>
						<div className="bwc__options__field__toggles__label">Label</div>
						<input
							id={field + "Label"}
							maxLength={65}
							name="label"
							onChange={e => this.onConfigInputChange(field, "label", e.target.value)}
							value={inputs[field] && typeof inputs[field]["label"][languageSectionForm] !== "undefined" ? inputs[field]["label"][languageSectionForm] : ""}
							className="Common__input"
							disabled={!allowUpdate}
						/>
					</>
				)}
				{show && (
					<>
						<div className="bwc__options__field__toggles__label">Input Placeholder</div>
						<input
							id={field + "Placeholder"}
							maxLength={65}
							name="placeholder"
							onChange={e => this.onConfigInputChange(field, "placeholder", e.target.value)}
							value={
								inputs[field] && typeof inputs[field]["placeholder"][languageSectionForm] !== "undefined"
									? inputs[field]["placeholder"][languageSectionForm]
									: ""
							}
							className="Common__input"
							disabled={!allowUpdate}
						/>
					</>
				)}
			</div>
		);
	};

	renderFormInputs = () => {
		return (
			<Field key="formInput">
				<div key="formInputName" className="bwc__options__field">
					<div className="bwc__options__field__label">
						<div className="wc__options__field__label__title">Name</div>
						<div className="wc__options__field__label__description">The name of the user filling out the form.</div>
					</div>
					{this.renderInputOptions("name")}
				</div>
				<div key="formInputReason" className="bwc__options__field">
					<div className="bwc__options__field__label">
						<div className="wc__options__field__label__title">Reason</div>
						<div className="wc__options__field__label__description">The reason for the booking.</div>
					</div>
					{this.renderInputOptions("reason")}
				</div>
				<div key="formInputNote" className="bwc__options__field">
					<div className="bwc__options__field__label">
						<div className="wc__options__field__label__title">Note</div>
						<div className="wc__options__field__label__description">The included message for the booking.</div>
					</div>
					{this.renderInputOptions("note")}
				</div>
				<div key="formInputEmail" className="bwc__options__field">
					<div className="bwc__options__field__label">
						<div className="wc__options__field__label__title">Email</div>
						<div className="wc__options__field__label__description">The email address of the person filling out the form.</div>
					</div>
					{this.renderInputOptions("email")}
				</div>
				<div key="formInputPhone" className="bwc__options__field">
					<div className="bwc__options__field__label">
						<div className="wc__options__field__label__title">Phone</div>
						<div className="wc__options__field__label__description">The phone numbers of the person filling out the form.</div>
					</div>
					{this.renderInputOptions("phone")}
				</div>
			</Field>
		);
	};

	renderLoading() {
		return (
			<ContentLoader height={480} width={440}>
				<rect x="30" y="30" rx="5" ry="5" width="410" height="80" />
				<rect x="30" y="140" rx="5" ry="5" width="410" height="80" />
				<rect x="30" y="270" rx="5" ry="5" width="410" height="80" />
				<rect x="30" y="400" rx="5" ry="5" width="410" height="80" />
			</ContentLoader>
		);
	}

	render() {
		let {
			widgetConfig,
			showFormInputs,
			changesMade,
			saving,
			loading,
			languageSectionHeadings,
			languageSectionPayments,
			languageSectionReasons,
			languageSectionForm,
			languageSectionSupportedOptions
		} = this.state;
		let { showReasonsModal, reasonEditIndex, reasonModalCreateMode } = this.state;

		if (loading) {
			return this.renderLoading();
		}

		if (!widgetConfig) {
			return <div>No booking widget configuration found.</div>;
		}

		let { config, status } = widgetConfig;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		let googleAnalytics = config.googleAnalytics;
		let trackingId = googleAnalytics && googleAnalytics.trackingId ? googleAnalytics.trackingId : "";
		let zIndex = config.zIndex || null;
		let zIndexImportant = config.zIndexImportant;

		// Reason related rendering
		let reasonsData = [];
		let reasonToEdit = "";

		let showLanguageSelectors = languageSectionSupportedOptions.length > 1;

		if (config && config.inputs && config.inputs.reason && config.inputs.reason.options) {
			let reasons = config.inputs.reason.options;

			// Build the reasons data array for showcase list
			for (let reason of reasons) {
				reasonsData.push({
					title: reason[languageSectionReasons]
				});
			}

			// If we want to edit a reason
			if (reasonEditIndex !== null) {
				reasonToEdit = reasons[reasonEditIndex];
			}
		}

		let { inputs } = config;

		return (
			<div className="bwc__options">
				<PoseGroup>
					<Field key="status" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Booking Widget</div>
							<div className="wc__options__field__label__description">Enable Booking Widget to allow your website visitors to book directly with you.</div>
						</div>
						<this.Switch
							id="status"
							field="status"
							checked={typeof status !== "undefined" && status === STATUS.active ? true : false}
							onChange={this.onStatusChange}
						/>
					</Field>

					<Field key="useBookingSlots" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Use Bookings Slots</div>
							<div className="wc__options__field__label__description">
								Create custom time slots that are selectable in the booking widget. (Morning, Afternoon, and Evening)
							</div>
						</div>
						<this.Switch
							id="useBookingSlot"
							field="useBookingSlot"
							checked={typeof config.useBookingSlot !== "undefined" ? config.useBookingSlot : true}
							onChange={this.onChange}
						/>
					</Field>

					{!config.useBookingSlot && (
						<Field key="useAmPm" className="wc__options__field">
							<div className="wc__options__field__label">
								<div className="wc__options__field__label__title">Use AM and PM Timings</div>
								<div className="wc__options__field__label__description">Instead of 00:00 to 24:00, display 12 AM to 12 PM</div>
							</div>
							<this.Switch id="useAmPm" field="useAmPm" checked={typeof config.useAmPm !== "undefined" ? config.useAmPm : true} onChange={this.onChange} />
						</Field>
					)}

					<Field key="calendarHeader" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">
								Booking Slot Header
								{showLanguageSelectors && (
									<div className="wcc__options__field__label__title__language_container">
										<LanguageSelectorMini
											language={languageSectionHeadings}
											languageOptions={languageSectionSupportedOptions}
											onChange={newLanguage => {
												this.update({ languageSectionHeadings: newLanguage });
											}}
										/>
									</div>
								)}
							</div>
							<div className="wc__options__field__label__description">The header shown above the booking slots.</div>
						</div>
						<div>
							<Input
								id="calendarHeader"
								name="calendarHeader"
								type="text"
								value={typeof config.calendarHeader !== "undefined" ? config.calendarHeader[languageSectionHeadings] : ""}
								onChange={event => this.onCalendarHeaderChange(event.target.value)}
								placeholder="Select a time window"
								disabled={!allowUpdate}
							/>
						</div>
					</Field>
					<Field key="calendarSubHeader" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">
								Booking Slot Sub Header
								{showLanguageSelectors && (
									<div className="wcc__options__field__label__title__language_container">
										<LanguageSelectorMini
											language={languageSectionHeadings}
											languageOptions={languageSectionSupportedOptions}
											onChange={newLanguage => {
												this.update({ languageSectionHeadings: newLanguage });
											}}
										/>
									</div>
								)}
							</div>
							<div className="wc__options__field__label__description">The sub header shown above the booking slots and below the header.</div>
						</div>
						<div>
							<Input
								id="calendarSubHeader"
								name="calendarSubHeader"
								type="text"
								value={typeof config.calendarSubHeader !== "undefined" ? config.calendarSubHeader[languageSectionHeadings] : ""}
								onChange={event => this.onCalendarSubHeaderChange(event.target.value)}
								placeholder="A sub header..."
								disabled={!allowUpdate}
							/>
						</div>
					</Field>

					<Field key="showProviderName" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Show Provider Name</div>
							<div className="wc__options__field__label__description">Under the provided service, show the provider's name.</div>
						</div>
						<this.Switch
							id="showProviderName"
							field="showProviderName"
							checked={typeof config.showProviderName !== "undefined" ? config.showProviderName : false}
							onChange={this.onChange}
						/>
					</Field>
					<Field key="useUserProfilePicture" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Use User Profile Picture As Provider</div>
							<div className="wc__options__field__label__description">
								When selecting providers, the provider's profile picture will be shown for the service they provide.
							</div>
						</div>
						<this.Switch
							id="useUserProfilePicture"
							field="useUserProfilePicture"
							checked={typeof config.useUserProfilePicture !== "undefined" ? config.useUserProfilePicture : false}
							onChange={this.onChange}
						/>
					</Field>
					<Field key="showServiceDuration" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Show Service Duration</div>
							<div className="wc__options__field__label__description">Show the duration of each service in minutes.</div>
						</div>
						<this.Switch
							id="showServiceDuration"
							field="showServiceDuration"
							checked={typeof config.showServiceDuration !== "undefined" ? config.showServiceDuration : false}
							onChange={this.onChange}
						/>
					</Field>

					<ShowcaseList
						key="reasonsList"
						title="Reasons"
						label="Reason"
						onAddClicked={this.handleCreateReason}
						onEditClicked={this.handleReasonEdit}
						onDeleteClicked={this.handleReasonDelete}
						onOrderChanged={(oldIndex, newIndex) => {
							this.handleReasonOrderChange(oldIndex, newIndex);
						}}
						allowUpdate={allowUpdate}
						orderable={true}
					>
						{showLanguageSelectors && (
							<div className="wcc__options__field__label__title__language_container">
								<LanguageSelectorMini
									language={languageSectionReasons}
									languageOptions={languageSectionSupportedOptions}
									onChange={newLanguage => {
										this.update({ languageSectionReasons: newLanguage });
									}}
								/>
							</div>
						)}
						{reasonsData.map((row, index) => {
							let { title, subtitle, description, footer } = row;
							return <ShowcaseRow rowIndex={index} title={title} subtitle={subtitle} description={description} footer={footer} />;
						})}
					</ShowcaseList>

					<Field key="form" className={`wc__options__field ${showFormInputs ? "bwc__options__field--no-border" : ""}`}>
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">
								Form
								{showLanguageSelectors && (
									<div className="wcc__options__field__label__title__language_container">
										<LanguageSelectorMini
											language={languageSectionForm}
											languageOptions={languageSectionSupportedOptions}
											onChange={newLanguage => {
												this.update({ languageSectionForm: newLanguage });
											}}
										/>
									</div>
								)}
							</div>
							<div className="wc__options__field__label__description">Customize the booking request form.</div>
						</div>
						<div className="wc__options__field__btn fnctst-booking-form-open" onClick={() => this.update({ showFormInputs: !this.state.showFormInputs })}>
							{showFormInputs ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
						</div>
					</Field>
					{showFormInputs && this.renderFormInputs()}

					{inputs && inputs.phone && inputs.phone.show && (
						<Field key="showPhoneConfirmation" className="wc__options__field">
							<div className="wc__options__field__label">
								<div className="wc__options__field__label__title">Require Phone Confirmation</div>
								<div className="wc__options__field__label__description">Add a Phone Confirmation field in the booking widget form.</div>
							</div>
							<this.Switch
								id="showPhoneConfirmation"
								field="showPhoneConfirmation"
								checked={typeof config.showPhoneConfirmation !== "undefined" ? config.showPhoneConfirmation : false}
								onChange={this.onChange}
							/>
						</Field>
					)}
					{inputs && inputs.email && inputs.email.show && (
						<Field key="showEmailConfirmation" className="wc__options__field">
							<div className="wc__options__field__label">
								<div className="wc__options__field__label__title">Require Email Confirmation</div>
								<div className="wc__options__field__label__description">Add an Email Confirmation field in the booking widget form.</div>
							</div>
							<this.Switch
								id="showEmailConfirmation"
								field="showEmailConfirmation"
								checked={typeof config.showEmailConfirmation !== "undefined" ? config.showEmailConfirmation : false}
								onChange={this.onChange}
							/>
						</Field>
					)}

					<Field key="bookingRequestUrl" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Booking Request Url</div>
							<div className="wc__options__field__label__description">
								Add a custom link where users can fill in a booking request. If micro sites is enabled, this field can be left empty and the micro site url will
								be used.
							</div>
						</div>
						<div>
							<Input
								id="bookingRequestUrl"
								name="bookingRequestUrl"
								type="text"
								value={typeof config.bookingRequestUrl !== "undefined" ? config.bookingRequestUrl : ""}
								onChange={e => this.onChange("bookingRequestUrl", e.target.value)}
								placeholder="https://www.my-website.com"
								disabled={!allowUpdate}
							/>
						</div>
					</Field>

					<Field key="trackingId" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Tracking Id</div>
							<div className="wc__options__field__label__description">Add your Google Analytics tracking Id to see widget analytics.</div>
						</div>
						<div>
							<Input
								id="trackingId"
								name="trackingId"
								type="text"
								value={trackingId}
								onChange={event => this.onTrackingIdChange(event.target.value)}
								placeholder="UA-000000-0"
								disabled={!allowUpdate}
							/>
						</div>
					</Field>

					<Field key="zIndex" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Z Index</div>
							<div className="wc__options__field__label__description">The Z Index positioning value.</div>
						</div>
						<div>
							<Input
								id="zIndex"
								name="zIndex"
								type="number"
								maxLength={15}
								value={zIndex}
								onChange={e => this.onChange("zIndex", parseInt(e.target.value))}
								placeholder="10"
								disabled={!allowUpdate}
							/>
						</div>
					</Field>
					{/* Not modifiable right now */}
					{/* <Field key="zIndexImportant" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Z Index Important</div>
							<div className="wc__options__field__label__description">If the z-index css should use the !important rule.</div>
						</div>
						<this.Switch
							id="zIndexImportant"
							field="zIndexImportant"
							checked={typeof zIndexImportant !== "undefined" ? zIndexImportant : false}
							onChange={this.onChange}
						/>
					</Field> */}

					<Field key="bookingRequestShow" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Open Booking Request By Default</div>
							<div className="wc__options__field__label__description">
								When sending out a booking request, open the booking widget on the website by default.
							</div>
						</div>
						<this.Switch
							id="bookingRequestShow"
							field="bookingRequestShow"
							checked={typeof config.bookingRequestShow !== "undefined" ? config.bookingRequestShow : false}
							onChange={this.onChange}
						/>
					</Field>

					<Field key="showPaymentsSections" className="wc__options__field">
						<div className="wc__options__field__label">
							<div className="wc__options__field__label__title">Credit Card Required</div>
							<div className="wc__options__field__label__description">
								Customers will need to fill in payment information. Payments location feature must be enabled.
							</div>
						</div>
						<this.Switch
							id="showPaymentsSections"
							field="showPaymentsSections"
							checked={typeof config.showPaymentsSections !== "undefined" ? config.showPaymentsSections : false}
							onChange={this.onChange}
						/>
					</Field>

					{config.showPaymentsSections && (
						<Field key="paymentsSectionHeader" className="wc__options__field">
							<div className="wc__options__field__label">
								<div className="wc__options__field__label__title">
									Payment Heading
									{showLanguageSelectors && (
										<div className="wcc__options__field__label__title__language_container">
											<LanguageSelectorMini
												language={languageSectionPayments}
												languageOptions={languageSectionSupportedOptions}
												onChange={newLanguage => {
													this.update({ languageSectionPayments: newLanguage });
												}}
											/>
										</div>
									)}
								</div>
								<div className="wc__options__field__label__description">The heading you would like to use on the payments section.</div>
							</div>
							<div>
								<Input
									id="paymentsSectionHeader"
									name="paymentsSectionHeader"
									type="text"
									value={config.paymentsSectionHeader[languageSectionPayments]}
									onChange={event => this.handlePaymentsSectionHeaderChange(event.target.value)}
									placeholder=""
									disabled={!allowUpdate}
								/>
							</div>
						</Field>
					)}

					{config.showPaymentsSections && (
						<Field key="paymentsSectionDescription" className="wc__options__field">
							<div className="wc__options__field__label">
								<div className="wc__options__field__label__title">
									Payment Description
									{showLanguageSelectors && (
										<div className="wcc__options__field__label__title__language_container">
											<LanguageSelectorMini
												language={languageSectionPayments}
												languageOptions={languageSectionSupportedOptions}
												onChange={newLanguage => {
													this.update({ languageSectionPayments: newLanguage });
												}}
											/>
										</div>
									)}
								</div>
								<div className="wc__options__field__label__description">
									The message you would like to tell your customers about taking payment information.
								</div>
							</div>
							<div>
								<Input
									id="paymentsSectionDescription"
									name="paymentsSectionDescription"
									type="text"
									value={config.paymentsSectionDescription[languageSectionPayments]}
									onChange={event => this.handlePaymentsSectionDescriptionChange(event.target.value)}
									placeholder=""
									disabled={!allowUpdate}
								/>
							</div>
						</Field>
					)}
				</PoseGroup>
				<div />

				<EditReasonModal
					show={showReasonsModal}
					createMode={reasonModalCreateMode}
					reason={reasonToEdit[languageSectionReasons] || ""}
					onHide={this.closeEditReasonModal}
					onSave={this.handleReasonOnSave}
				/>
				{changesMade && allowUpdate && (
					<UnsavedChanges>
						<div className="unsaved-changes__buttons">
							<div id="save-booking-widget-config" className="mb-button" onClick={this.updateWidget}>
								{saving ? "Saving" : "Save"}
							</div>
							<div className="mb-button mb-button--cancel" onClick={this.onCancelChanges}>
								Cancel
							</div>
						</div>
					</UnsavedChanges>
				)}
			</div>
		);
	}
}

export default General;

export const CsvMappingConstants = {
	FORMAT: {
		NAMES: {
			auto: {
				id: "auto",
				value: "auto",
				label: "Auto"
			},
			firstOnly: {
				id: "firstOnly",
				value: "firstOnly",
				label: "FirstName Only"
			},
			lastOnly: {
				id: "lastOnly",
				value: "lastOnly",
				label: "LastName Only"
			},
			firstCommaLast: {
				id: "firstCommaLast",
				value: "firstCommaLast",
				label: "FirstName, LastName"
			},
			firstSpaceLast: {
				id: "firstSpaceLast",
				value: "firstSpaceLast",
				label: "FirstName LastName"
			},
			lastCommaFirst: {
				id: "lastCommaFirst",
				value: "lastCommaFirst",
				label: "LastName, FirstName"
			},
			lastSpaceFirst: {
				id: "lastSpaceFirst",
				value: "lastSpaceFirst",
				label: "LastName FirstName"
			}
		}
	}
};

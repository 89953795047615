import React from "react";
import * as Icon from "react-feather";

import UtilityService from "../../services/UtilityService";

import { PASSWORD_REQUIREMENTS_GENERATOR, USER_PASSWORD_REQUIREMENTS } from "../../constants/Password.js";

import "../../styles/css/components/commons/input-password.css";

class PasswordRequirements extends React.PureComponent {
	passwordMeetsRequirements = value => {
		let { requirements = USER_PASSWORD_REQUIREMENTS } = this.props;

		return UtilityService.isValidPassword({ password: value, requirements: requirements });
	};

	render = () => {
		let { requirements = USER_PASSWORD_REQUIREMENTS, value, showRequirements } = this.props;

		if (!showRequirements && (Object.keys(requirements).length === 0 || !value)) {
			return null;
		}

		if (!showRequirements && this.passwordMeetsRequirements(value)) {
			return null;
		}

		return (
			<div className="input-password__requirements-table">
				{Object.keys(requirements).map(requirement => {
					let passwordRequirementsEntry = PASSWORD_REQUIREMENTS_GENERATOR[requirement];

					// Check how many of these characters are required
					let expected = requirements[requirement];

					// Get the label
					let label = passwordRequirementsEntry.label(expected);

					// Test the password
					let passedTest = passwordRequirementsEntry.test(value, expected);

					return (
						<div key={`required-${requirement}`} id={`required-${requirement}`} className="input-password__requirements-table__requirement">
							<div
								className={`input-password__requirements-table__requirement__icon 
									${passedTest ? `input-password__requirements-table__requirement__icon--passed` : `input-password__requirements-table__requirement__icon--failed`}
								`}
							>
								{passedTest ? <Icon.CheckCircle size="16" /> : <Icon.XCircle size="16" />}
							</div>
							<span className="input-password__requirements-table__requirement__label">{label}</span>
						</div>
					);
				})}
			</div>
		);
	};
}

export default PasswordRequirements;

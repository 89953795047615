export const USER_INVITES_STYLES = {
	select: {
		control: styles => ({ ...styles, backgroundColor: "white" }),
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "white"
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "white",
			backgroundColor: "#60A9FF",
			borderTopLeftRadius: "8px",
			borderBottomLeftRadius: "8px"
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "white",
			backgroundColor: "#60A9FF",
			borderTopRightRadius: "8px",
			borderBottomRightRadius: "8px",
			marginLeft: "-2px",
			":hover": {
				opacity: "0.8"
			}
		})
	}
};

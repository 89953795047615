import React, { Component } from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { withTranslation } from "react-i18next";

import { EDIT_BOOKING_REQUESTS_TABS, BOOKING_REQUESTS_STATUS } from "../../constants/BookingRequests";

import UserService from "../../services/UserService";
import BookingRequestService from "../../services/BookingRequestService";
import ToastService from "../../services/ToastService";

import Modal from "./DHModal";
import Tabs from "./Tabs";
import Tab from "./Tab";
import Alert from "./Alert";

import "../../styles/css/components/commons/edit-booking-request-modal.css";
import UtilityService from "../../services/UtilityService";
import BookingRequestStatusAlert from "./BookingRequestStatusAlert";

class EditBookingRequestModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			/* Modal related state data */
			selectedTab: null,
			loading: false,
			showAlertBookingRequestNotFound: false,
			showConfirmAlert: false,
			selectedStatusChange: null,

			/* Savable state data */
			bookingRequest: null,

			/* Dirty Bit */
			changesMade: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		let { bookingRequestId, show } = this.props;

		const locationId = UserService.getActiveLocation().id;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		// Set loading to true, and reset dirty bit
		await this.update({
			loading: true,
			changesMade: false,
			selectedStatusChange: null
		});

		// Reset modal tab
		await this.tabReset();

		// Fetch the booking request object from the backend
		let bookingRequest = await BookingRequestService.fetchBookingRequest({ locationId, bookingRequestId });
		if (!bookingRequest) {
			// Show booking not found dialog
			await this.update({ showAlertBookingRequestNotFound: true, loading: false });
			return;
		}

		// Set loading to false
		await this.update({
			bookingRequest: bookingRequest,
			loading: false
		});
	};

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			await this.resetComponent();
		}
	}

	// Change the tab we are one
	onTabSelect = async tab => {
		await this.update({ selectedTab: tab.id });
	};

	// Go back to the first tab
	tabReset = async () => {
		await this.update({ selectedTab: EDIT_BOOKING_REQUESTS_TABS.details });
	};

	/*
		Methods related to dom on-changes
	*/

	onClose = async () => {
		// If no changes were made, just tell the parent to close us
		if (this.props.onClose) {
			await this.props.onClose();
		}
	};

	// If the booking request is not found dialog closes
	onConfirmAlertBookingRequestNotFoundClose = async confirm => {
		await this.update({ showAlertBookingRequestNotFound: false });

		if (this.props.onClose) {
			await this.props.onClose();
		}
	};

	/*
		Methods related to actioning a booking request 
	*/

	updateStatus = async (status, sendNotificationMessage) => {
		let { t } = this.props;

		try {
			let { bookingRequest } = this.state;
			const locationId = UserService.getActiveLocation().id;
			await BookingRequestService.updateBookingRequest({ bookingRequestId: bookingRequest.id, status, locationId, sendNotification: sendNotificationMessage });

			ToastService.info(t("Appointment updated!"));

			await this.update({ loading: false, showModal: false });

			await this.resetComponent();
		} catch (error) {
			ToastService.error(t("Appointment update failed!"));
			console.log(error);
		}
	};

	onShowAlertConfirm = async status => {
		await this.update({ showConfirmAlert: true, selectedStatusChange: status });
	};

	onConfirmAlertConfirmClose = async (confirm, sendNotificationMessage) => {
		let { selectedStatusChange } = this.state;
		let status = selectedStatusChange;
		await this.update({ showConfirmAlert: false, selectedStatusChange: null });

		if (confirm) {
			await this.updateStatus(status, sendNotificationMessage);
		}
	};

	/*
		All tab render methods are below
	*/

	renderDetails() {
		let { bookingRequest } = this.state;
		let { t } = this.props;

		let contact = bookingRequest.Contact;
		let { name, email, phone } = contact;

		let date = moment(bookingRequest.date).format("MMMM Do YYYY");
		let startTime = bookingRequest.start_time;
		let endTime = bookingRequest.end_time;
		let bookingRequestStatus = bookingRequest.status;
		let reason = bookingRequest.reason;
		let createdAt = moment(bookingRequest.created_at).format("MMMM Do YYYY, h:mm A");
		let isNewCustomer = bookingRequest.new_customer;
		let note = bookingRequest.note;

		let requestedTime = `${startTime} - ${endTime}`;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		return (
			<>
				{isSuperOrCs && (
					<div className="ebrm__details">
						<div className="ebrm__details__item">
							<div className="ebrm__label">{t("Contact ID")}</div>
							<input id="contact-id" name="contactId" value={contact.id} className="ebrm__input" placeholder={t("Contact ID")} disabled={true} />
						</div>
						<div className="ebrm__details__item">
							<div className="ebrm__label">{t("Booking Request ID")}</div>
							<input
								id="bookiong-id"
								name="bookingId"
								value={bookingRequest.id}
								className="ebrm__input"
								placeholder={t("Booking Request ID")}
								disabled={true}
							/>
						</div>
					</div>
				)}
				<div className="ebrm__details">
					<div className="ebrm__details__item">
						<div className="ebrm__label">{t("Name")}</div>
						<input id="name" name="name" value={name} className="ebrm__input" placeholder={t("Contact Name")} disabled={true} />
					</div>
					<div className="ebrm__details__item">
						<div className="ebrm__label ebrm__label--phone">{t("Phone")}</div>
						<input id="phone" name="phone" value={phone} className="ebrm__input" placeholder={t("No phone present")} disabled={true} />
					</div>
					<div className="ebrm__details__item">
						<div className="ebrm__label ebrm__label--email">{t("Email")}</div>
						<input id="email" name="email" value={email} className="ebrm__input" placeholder={t("No email present")} disabled={true} />
					</div>
				</div>
				<div className="ebrm__details">
					<div className="ebrm__details__item">
						<div className="ebrm__label">{t("Requested Date For Booking")}</div>
						<input id="date" name="date" value={date} className="ebrm__input" placeholder={t("No date requested")} disabled={true} />
					</div>
					<div className="ebrm__details__item">
						<div className="ebrm__label ebrm__label--phone">{t("Request Time")}</div>
						<input id="time" name="time" value={requestedTime} className="ebrm__input" placeholder={t("No time requested")} disabled={true} />
					</div>
					<div className="ebrm__details__item">
						<div className="ebrm__label ebrm__label--email">{t("Current Booking Request Status")}</div>
						<input
							id="status"
							name="status"
							value={UtilityService.toTitleCase(bookingRequestStatus)}
							className="ebrm__input"
							placeholder={t("Booking request status")}
							disabled={true}
						/>
					</div>
					<div className="ebrm__details__item">
						<div className="ebrm__label ebrm__label--email">{t("Reason")}</div>
						<input id="reason" name="reason" value={reason} className="ebrm__input" placeholder={t("No reason present")} disabled={true} />
					</div>
					<div className="ebrm__details__item">
						<div className="ebrm__label ebrm__label--email">{t("New Customer?")}</div>
						<input
							id="newCustomer"
							name="newCustomer"
							value={isNewCustomer ? t("Yes") : t("No")}
							className="ebrm__input"
							placeholder={t("New customer")}
							disabled={true}
						/>
					</div>
					<div className="ebrm__details__item">
						<div className="ebrm__label ebrm__label--email">{t("Creation Date")}</div>
						<input id="createdAt" name="createdAt" value={createdAt} className="ebrm__input" placeholder={t("Creation date")} disabled={true} />
					</div>
				</div>
				{note && note.length > 0 && (
					<div className="ebrm__details__item">
						<div className="ebrm__notes__bubble">
							<div className="ebrm__notes__quote">”</div>
							<div className="ebrm__notes__message">{note}</div>
						</div>
					</div>
				)}
			</>
		);
	}

	renderActions() {
		let { bookingRequest } = this.state;
		let { t } = this.props;
		let user = UserService.get();

		let bookingRequestStatus = bookingRequest.status;

		let allowUpdate = user.GroupPermission.update_bookings;
		let allowDelete = user.GroupPermission.delete_bookings;

		return (
			<div className="ebrm__action-rows">
				{allowDelete && bookingRequestStatus !== BOOKING_REQUESTS_STATUS.awaiting && (
					<>
						<div className="ebrm__action-rows__row">
							<div className="ebrm__action-rows__row__text">
								<div className="ebrm__action-rows__row__title">{t("Awaiting Appointment")}</div>
							</div>
							<div className="ebrm__action-rows__row__button">
								<div className="mb-button" onClick={() => this.onShowAlertConfirm(BOOKING_REQUESTS_STATUS.awaiting)}>
									{t("Awaiting")}
								</div>
							</div>
						</div>
						<div className="ebrm__action-rows__spacer" />
					</>
				)}

				{allowUpdate && bookingRequestStatus !== BOOKING_REQUESTS_STATUS.confirmed && (
					<>
						<div className="ebrm__action-rows__row">
							<div className="ebrm__action-rows__row__text">
								<div className="ebrm__action-rows__row__title">{t("Confirm Appointment")}</div>
							</div>
							<div className="ebrm__action-rows__row__button">
								<div className="mb-button" onClick={() => this.onShowAlertConfirm(BOOKING_REQUESTS_STATUS.confirmed)}>
									{t("Confirm")}
								</div>
							</div>
						</div>
						<div className="ebrm__action-rows__spacer" />
					</>
				)}

				{allowUpdate && bookingRequestStatus !== BOOKING_REQUESTS_STATUS.cancelled && (
					<>
						<div className="ebrm__action-rows__row">
							<div className="ebrm__action-rows__row__text">
								<div className="ebrm__action-rows__row__title">{t("Cancel Appointment")}</div>
							</div>
							<div className="ebrm__action-rows__row__button">
								<div className="mb-button" onClick={() => this.onShowAlertConfirm(BOOKING_REQUESTS_STATUS.cancelled)}>
									{t("Cancel")}
								</div>
							</div>
						</div>
						<div className="ebrm__action-rows__spacer" />
					</>
				)}

				{allowDelete && bookingRequestStatus !== BOOKING_REQUESTS_STATUS.deleted && (
					<>
						<div className="ebrm__action-rows__row">
							<div className="ebrm__action-rows__row__text">
								<div className="ebrm__action-rows__row__title">{t("Delete Appointment")}</div>
							</div>
							<div className="ebrm__action-rows__row__button">
								<div className="mb-button" onClick={() => this.onShowAlertConfirm(BOOKING_REQUESTS_STATUS.deleted)}>
									{t("Delete")}
								</div>
							</div>
						</div>
						<div className="ebrm__action-rows__spacer" />
					</>
				)}
			</div>
		);
	}

	renderLoading(title) {
		return (
			<Modal show={true} onHide={this.onClose} title={title}>
				<div className="ebrm ebrm--centre-content">
					<div className="ebrm__loading">
						<ContentLoader height={650} width={"100%"}>
							{/* The tabs */}
							<rect x="0" y="0" rx="5" ry="5" width="75%" height="40" />

							{/* First row */}
							<rect x="0" y="80" rx="5" ry="5" width="230" height="70" />
							<rect x="250" y="80" rx="5" ry="5" width="230" height="70" />
							<rect x="500" y="80" rx="5" ry="5" width="230" height="70" />

							{/* Second row */}
							<rect x="0" y="160" rx="5" ry="5" width="230" height="70" />
							<rect x="250" y="160" rx="5" ry="5" width="230" height="70" />
							<rect x="500" y="160" rx="5" ry="5" width="230" height="70" />

							{/* Third row */}
							<rect x="0" y="240" rx="5" ry="5" width="230" height="70" />
							<rect x="250" y="240" rx="5" ry="5" width="230" height="70" />
							<rect x="500" y="240" rx="5" ry="5" width="230" height="70" />

							{/* Save button */}
							<rect x="700" y="500" rx="5" ry="5" width="70" height="35" />
						</ContentLoader>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		let { t } = this.props;

		// Get loading state
		let { loading, bookingRequest } = this.state;

		// Get the state of the contact being found
		let { showAlertBookingRequestNotFound } = this.state;

		// Declare the booking for the modal
		let title = t("Booking Request Details");

		// If we could not find the booking request
		if (showAlertBookingRequestNotFound) {
			return (
				<Alert type="warning" show={true} title={t("Booking not found")} confirm={t("Okay")} onClose={this.onConfirmAlertBookingRequestNotFoundClose}>
					{t("The booking could not be found.")}
				</Alert>
			);
		}

		// If there's no booking, there's nothing to show
		if (!bookingRequest) {
			return null;
		}

		// If we're still loading, we should display a spinner
		if (loading) {
			return this.renderLoading(title);
		}

		// After this point, we have a booking request and everything about it is loaded.
		let contact = bookingRequest.Contact;

		// Extract critical modal related variables
		let { selectedTab } = this.state;
		let { show } = this.props;

		// Alert dialogs
		let { showConfirmAlert, selectedStatusChange } = this.state;

		// Add name to the title
		title += " - " + contact.name;

		// If the user is blocked, we should add it to the title
		if (contact.is_blocked) {
			title += ` (${t("Blocked")})`;
		}

		return (
			<>
				<Modal show={show} onHide={this.onClose} title={title}>
					<div className="ebrm">
						<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
							<Tab id={EDIT_BOOKING_REQUESTS_TABS.details} value={t("Details")} />
							<Tab id={EDIT_BOOKING_REQUESTS_TABS.actions} value={t("Actions")} />
						</Tabs>
						{selectedTab === EDIT_BOOKING_REQUESTS_TABS.details && this.renderDetails()}
						{selectedTab === EDIT_BOOKING_REQUESTS_TABS.actions && this.renderActions()}
					</div>
				</Modal>
				<BookingRequestStatusAlert show={showConfirmAlert} toStatus={selectedStatusChange} onClose={this.onConfirmAlertConfirmClose} />
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(EditBookingRequestModal);

import React, { Component } from "react";
import * as Icon from "react-feather";

import "../../styles/css/components/commons/language-selector-mini.css";
import { MINI_FLAG_URL } from "../../constants/LanguageConstants";

class LanguageSelectorMini extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onChange = language => {
		if (this.props.onChange) {
			this.props.onChange(language);
		}
	};

	toggleModal = () => {
		let { showModal } = this.state;
		this.update({ showModal: !showModal });
	};

	render() {
		let { showModal } = this.state;
		let { language, languageOptions } = this.props;

		if (!language || !languageOptions) {
			return null;
		}

		let langaugeCode = language
			.split("_")[0]
			.toLowerCase()
			.toUpperCase(); //ex: EN
		let countryCode = language.split("_")[1].toLowerCase(); //ex: US

		return (
			<div className="languages-selector-mini" onClick={this.toggleModal}>
				<div className={`languages-selector-mini__languages_list ${!showModal ? "languages-selector-mini__languages_list--hidden" : ""}`}>
					{languageOptions.map(currentLanguage => {
						const countryCodeInList = currentLanguage.value.split("_")[1].toLowerCase(); // Assuming value is in the format of "en_CA"
						return (
							<div key={language.value} className="languages-selector-mini__languages_list__row" onClick={() => this.onChange(currentLanguage.value)}>
								<div className="languages-selector-mini__languages_list__row__icon_container">
									<img
										src={`${MINI_FLAG_URL}/16x12/${countryCodeInList}.png`}
										srcSet={`${MINI_FLAG_URL}/32x24/${countryCodeInList}.png 2x,${MINI_FLAG_URL}/48x36/${countryCodeInList}.png 3x`}
										width="16"
										height="12"
									></img>
								</div>
								<div className="languages-selector-mini__languages_list__row__language_code">{currentLanguage.label}</div>
							</div>
						);
					})}
				</div>

				<div className="languages-selector-mini__control" onClick={this.toggleModal}>
					<div className="languages-selector-mini__icon_container">
						<img
							src={`${MINI_FLAG_URL}/16x12/${countryCode}.png`}
							srcSet={`${MINI_FLAG_URL}/32x24/${countryCode}.png 2x,${MINI_FLAG_URL}/48x36/${countryCode}.png 3x`}
							width="16"
							height="12"
						></img>
					</div>
					<div className="languages-selector-mini__language_code">{langaugeCode}</div>
					<div className="languages-selector-mini__language_arrow">
						<Icon.ChevronDown size={17} />
					</div>
				</div>
			</div>
		);
	}
}

export default LanguageSelectorMini;

import React from "react";
import ReactSwitch from "react-switch";
import Select from "react-select";
import ContentLoader from "react-content-loader";
import { withTranslation } from "react-i18next";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import DHSelect from "../../../components/common/DHSelect";
import TextArea from "../../../components/common/TextArea";
import Checkbox from "../../../components/common/Checkbox";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";
import Toggle from "../../../components/common/Toggle";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import A2PService from "../../../services/A2PService";
import UtilityService from "../../../services/UtilityService";

import { EDIT_CAMPAIGN_TABS } from "../../../constants/A2P";

import "./a2p.css";

class EditCampaignModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// Modal Data
			selectedTab: EDIT_CAMPAIGN_TABS.basics.id,
			loading: false,
			config: null,

			// Selectors
			selectableUsecases: [],
			selectableSubUsecases: [],

			// Campaign Data
			usecase: "",
			subUsecases: "",
			resellerId: "",
			description: "",
			embeddedLink: false,
			embeddedPhone: false,
			affiliateMarketing: false,
			termsAndConditions: false,
			numberPool: false,
			ageGated: false,
			directLending: false,
			subscriberOptin: false,
			subscriberOptout: false,
			subscriberHelp: false,
			sample1: "",
			sample2: "",
			sample3: "",
			sample4: "",
			sample5: "",
			messageFlow: "",
			helpMessage: "",
			mnoIds: "",
			referenceId: "",
			autoRenewal: false,
			tag: "",
			optinKeywords: "",
			optoutKeywords: "",
			helpKeywords: "",
			optinMessage: "",
			optoutMessage: ""
		};
	}

	componentDidUpdate = async prevProps => {
		if (prevProps.show !== this.props.show) {
			await this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({ loading: true });

		await this.update({
			// Modal Data
			selectedTab: EDIT_CAMPAIGN_TABS.basics.id,
			config: null,

			// Selectors
			selectableUsecases: [],
			selectableSubUsecases: [],

			// Campaign Data
			usecase: "",
			subUsecases: "",
			resellerId: "",
			description: "",
			embeddedLink: false,
			embeddedPhone: false,
			affiliateMarketing: false,
			termsAndConditions: false,
			numberPool: false,
			ageGated: false,
			directLending: false,
			subscriberOptin: false,
			subscriberOptout: false,
			subscriberHelp: false,
			sample1: "",
			sample2: "",
			sample3: "",
			sample4: "",
			sample5: "",
			messageFlow: "",
			helpMessage: "",
			mnoIds: "",
			referenceId: "",
			autoRenewal: false,
			tag: "",
			optinKeywords: "",
			optoutKeywords: "",
			helpKeywords: "",
			optinMessage: "",
			optoutMessage: ""
		});

		await this.fetchConfig();
		await this.buildFormOptions();
		await this.fetchCampaign();
		await this.prefillWithDefaultData();

		await this.update({ loading: false });
	};

	onTabSelect = async tab => {
		await this.update({ selectedTab: tab.id });
	};

	// For <input> changes
	onChangeInput = ({ fieldName, value }) => {
		this.update({
			[fieldName]: value
		});
	};

	onSwitchChange = fieldName => {
		this.update({
			[fieldName]: !this.state[fieldName]
		});
	};

	onSubUseCaseCheckmarkEvent = ({ subcase, checked, numberOfSubUseCasesAllowed }) => {
		let { subUsecases } = this.state;

		let subUseCaseArray = JSON.parse(subUsecases);

		// If we can't check more, don't allow
		if (checked && subUseCaseArray.length >= numberOfSubUseCasesAllowed) {
			return;
		}

		let subUseCaseArrayCopy = subUseCaseArray.slice();

		if (checked) {
			subUseCaseArrayCopy.push(subcase);
		} else {
			var index = subUseCaseArrayCopy.indexOf(subcase);
			if (index !== -1) {
				subUseCaseArrayCopy.splice(index, 1);
			}
		}

		this.update({ subUsecases: JSON.stringify(subUseCaseArrayCopy) });
	};

	prefillWithDefaultData = async () => {
		let { config } = this.state;

		if (this.props.campaignId) {
			return;
		}

		await this.update({
			usecase: config.enums.campaignRequestSchema.usecase.dhDefault,
			subUsecases: config.enums.campaignRequestSchema.subUsecases.dhDefault,
			resellerId: config.enums.campaignRequestSchema.resellerId.dhDefault,
			description: config.enums.campaignRequestSchema.description.dhDefault,
			embeddedLink: config.enums.campaignRequestSchema.embeddedLink.dhDefault,
			embeddedPhone: config.enums.campaignRequestSchema.embeddedPhone.dhDefault,
			affiliateMarketing: config.enums.campaignRequestSchema.affiliateMarketing.dhDefault,
			termsAndConditions: config.enums.campaignRequestSchema.termsAndConditions.dhDefault,
			numberPool: config.enums.campaignRequestSchema.numberPool.dhDefault,
			ageGated: config.enums.campaignRequestSchema.ageGated.dhDefault,
			directLending: config.enums.campaignRequestSchema.directLending.dhDefault,
			subscriberOptin: config.enums.campaignRequestSchema.subscriberOptin.dhDefault,
			subscriberOptout: config.enums.campaignRequestSchema.subscriberOptout.dhDefault,
			subscriberHelp: config.enums.campaignRequestSchema.subscriberHelp.dhDefault,
			sample1: config.enums.campaignRequestSchema.sample1.dhDefault,
			sample2: config.enums.campaignRequestSchema.sample2.dhDefault,
			sample3: config.enums.campaignRequestSchema.sample3.dhDefault,
			sample4: config.enums.campaignRequestSchema.sample4.dhDefault,
			sample5: config.enums.campaignRequestSchema.sample5.dhDefault,
			messageFlow: config.enums.campaignRequestSchema.messageFlow.dhDefault,
			helpMessage: config.enums.campaignRequestSchema.messageFlow.dhDefault,
			mnoIds: config.enums.campaignRequestSchema.mnoIds.dhDefault,
			autoRenewal: config.enums.campaignRequestSchema.autoRenewal.dhDefault,
			tag: config.enums.campaignRequestSchema.tag.dhDefault,
			optinKeywords: config.enums.campaignRequestSchema.optinKeywords.dhDefault,
			optoutKeywords: config.enums.campaignRequestSchema.optoutKeywords.dhDefault,
			helpKeywords: config.enums.campaignRequestSchema.helpKeywords.dhDefault,
			optinMessage: config.enums.campaignRequestSchema.optinMessage.dhDefault,
			optoutMessage: config.enums.campaignRequestSchema.optoutMessage.dhDefault
		});
	};

	fetchConfig = async () => {
		let config = await A2PService.fetchConfig();
		await this.update({ config });
	};

	fetchCampaign = async () => {
		let { t } = this.props;

		if (!this.props.campaignId) {
			return;
		}

		try {
			let campaign = await A2PService.fetchCampaign({ campaignId: this.props.campaignId });

			if (!campaign) {
				ToastService.error(t("Could not fetch campaign."));
				return;
			}

			await this.update({
				usecase: campaign.usecase,
				subUsecases: campaign.sub_usecases,
				resellerId: campaign.reseller_id,
				description: campaign.description,
				embeddedLink: campaign.embedded_link,
				embeddedPhone: campaign.embedded_phone,
				affiliateMarketing: campaign.affiliate_marketing,
				termsAndConditions: campaign.terms_and_conditions,
				numberPool: campaign.number_pool,
				ageGated: campaign.age_gated,
				directLending: campaign.direct_lending,
				subscriberOptin: campaign.subscriber_optin,
				subscriberOptout: campaign.subscriber_optout,
				subscriberHelp: campaign.subscriber_help,
				sample1: campaign.sample1,
				sample2: campaign.sample2,
				sample3: campaign.sample3,
				sample4: campaign.sample4,
				sample5: campaign.sample5,
				messageFlow: campaign.message_flow,
				helpMessage: campaign.help_message,
				mnoIds: campaign.mno_ids,
				referenceId: campaign.reference_id,
				autoRenewal: campaign.auto_renewal,
				tag: campaign.tag,
				optinKeywords: campaign.optin_keywords,
				optoutKeywords: campaign.optout_keywords,
				helpKeywords: campaign.help_keywords,
				optinMessage: campaign.optin_message,
				optoutMessage: campaign.optout_message
			});
		} catch (error) {
			console.log(error);
		}
	};

	onSave = async () => {
		let { campaignId } = this.props;
		let { t } = this.props;

		let {
			usecase,
			subUsecases,
			resellerId,
			description,
			embeddedLink,
			embeddedPhone,
			affiliateMarketing,
			termsAndConditions,
			numberPool,
			ageGated,
			directLending,
			subscriberOptin,
			subscriberOptout,
			subscriberHelp,
			sample1,
			sample2,
			sample3,
			sample4,
			sample5,
			messageFlow,
			helpMessage,
			mnoIds,
			referenceId,
			autoRenewal,
			tag,
			optinKeywords,
			optoutKeywords,
			helpKeywords,
			optinMessage,
			optoutMessage
		} = this.state;

		let campaignData = {
			usecase,
			subUsecases,
			resellerId,
			description,
			embeddedLink,
			embeddedPhone,
			affiliateMarketing,
			termsAndConditions,
			numberPool,
			ageGated,
			directLending,
			subscriberOptin,
			subscriberOptout,
			subscriberHelp,
			sample1,
			sample2,
			sample3,
			sample4,
			sample5,
			messageFlow,
			helpMessage,
			mnoIds,
			referenceId,
			autoRenewal,
			tag,
			optinKeywords,
			optoutKeywords,
			helpKeywords,
			optinMessage,
			optoutMessage
		};

		let locationId = UserService.getActiveLocation().id;

		let response = null;
		if (campaignId) {
			response = await A2PService.updateCampaign({ campaignId, campaignData });
		} else {
			response = await A2PService.createCampaign({ locationId, campaignData });
		}

		if (response) {
			ToastService.info(campaignId ? t("Campaign updated.") : t("Successfully created."));
		} else {
			ToastService.error(t("Failed to save. Please try again."));
			return;
		}

		if (this.props.onSave) {
			await this.props.onSave();
		}
	};

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	buildFormOptions = async () => {
		let { config } = this.state;

		let useCases = config.enums.useCases;

		let selectableUsecases = [];
		let selectableSubUsecases = [];

		for (const [key, value] of Object.entries(useCases)) {
			let useCaseName = value.displayName;
			let useCaseId = value.id;
			let canBeASubUseCase = value.validSubUsecase;

			selectableUsecases.push({
				label: useCaseName,
				value: useCaseId
			});

			if (canBeASubUseCase) {
				selectableSubUsecases.push({
					label: useCaseName,
					value: useCaseId
				});
			}
		}

		await this.update({
			selectableUsecases,
			selectableSubUsecases
		});
	};

	renderFieldError({ minChar, maxChar, value }) {
		let { t } = this.props;

		if (typeof minChar !== "undefined" && value.length < minChar) {
			return <div className="a2p-modal__body__field_error_text">{t("Needs {{number}} more characters", { number: minChar - value.length })}</div>;
		} else if (typeof maxChar !== "undefined" && value.length > maxChar) {
			return <div className="a2p-modal__body__field_error_text">{t("Needs {{number}} less characters", { number: value.length - maxChar })}</div>;
		}

		return null;
	}

	renderBasicDetailsInfo() {
		let { config, usecase, subUsecases, description, messageFlow, selectableUsecases, selectableSubUsecases } = this.state;
		let { t } = this.props;

		// For rendering sub-usecases
		let subUseCaseArray = JSON.parse(subUsecases);

		let numberOfUseCaseText = "";
		let numberOfSubUseCasesAllowed = config.enums.useCases[usecase].maxSubUsecases;
		if (numberOfSubUseCasesAllowed === 0) {
			numberOfUseCaseText = t("No subcases allowed");
		} else {
			numberOfUseCaseText = t("Please select {{numberOfSubUseCasesAllowed}} subcases", { numberOfSubUseCasesAllowed: numberOfSubUseCasesAllowed });
		}

		// Find the usecase
		let usecaseLabel = selectableUsecases.find(theUsecase => theUsecase.value === usecase);
		usecaseLabel = typeof usecaseLabel === "undefined" ? t("Unknown Usecase") : usecaseLabel.label;

		return (
			<>
				<DHSelect
					label={t("Usecase")}
					name="use_cases"
					id="use_cases"
					onChange={option => {
						// Clear the subusecases
						this.update({ subUsecases: "[]" });
						this.onChangeInput({ fieldName: "usecase", value: option.value });
					}}
					value={{ label: usecaseLabel, value: usecase }}
					options={selectableUsecases}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.campaignRequestSchema.usecase.maxLength, value: usecase })}

				<div className="dh-details-item">
					<label>
						{t("Sub usecases")} ({numberOfUseCaseText})
					</label>
				</div>
				<div className="a2p-modal__body__subusecase_checkboxes">
					{selectableSubUsecases.map((subcase, index) => {
						return (
							<div key={subcase.value} className="a2p-modal__body__subusecase_checkboxes__single_checkbox">
								<div className="a2p-modal__body__subusecase_checkboxes__checkbox">
									<Checkbox
										id={`${subcase.value}-checkbox`}
										name={subcase.value}
										checked={subUseCaseArray.includes(subcase.value) ? true : false}
										onChange={event => {
											this.onSubUseCaseCheckmarkEvent({ subcase: subcase.value, checked: event.target.checked, numberOfSubUseCasesAllowed });
										}}
									/>
								</div>
								<div className="a2p-modal__body__subusecase_checkboxes__label">{subcase.label}</div>
							</div>
						);
					})}
				</div>

				<Input
					label={t("Description")}
					id="description"
					name="description"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "description", value: e.target.value })}
					value={description}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.description.maxLength,
					minChar: config.enums.campaignRequestSchema.description.minLength,
					value: description
				})}

				<Input
					label={t("Message Flow")}
					id="message_flow"
					name="message_flow"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "messageFlow", value: e.target.value })}
					value={messageFlow}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.messageFlow.maxLength,
					minChar: config.enums.campaignRequestSchema.messageFlow.minLength,
					value: messageFlow
				})}
			</>
		);
	}

	renderOptionsInfo() {
		let {
			config,

			embeddedLink,
			embeddedPhone,
			affiliateMarketing,
			termsAndConditions,
			numberPool,
			ageGated,
			directLending,
			autoRenewal
		} = this.state;
		let { t } = this.props;

		return (
			<>
				<Toggle id="embedded_link" label={t("Embedded Link")} checked={embeddedLink} onChange={() => this.onSwitchChange("embeddedLink")} />
				<Toggle
					id="affiliate_marketing"
					label={t("Afiliate Marketing")}
					checked={affiliateMarketing}
					onChange={() => this.onSwitchChange("affiliateMarketing")}
				/>
				<Toggle
					id="terms_and_conditions"
					label={t("Terms And Conditions")}
					checked={termsAndConditions}
					onChange={() => this.onSwitchChange("termsAndConditions")}
				/>
				<Toggle id="number_pool" label={t("Number Pool")} checked={numberPool} onChange={() => this.onSwitchChange("numberPool")} />
				<Toggle id="age_gated" label={t("Age Gated")} checked={ageGated} onChange={() => this.onSwitchChange("ageGated")} />
				<Toggle id="direct_lending" label={t("Direct Lending")} checked={directLending} onChange={() => this.onSwitchChange("directLending")} />
				<Toggle id="direct_lending" label={t("Auto Renewal")} checked={autoRenewal} onChange={() => this.onSwitchChange("autoRenewal")} />
			</>
		);
	}

	renderSamplesInfo() {
		let {
			config,

			sample1,
			sample2,
			sample3,
			sample4,
			sample5
		} = this.state;
		let { t } = this.props;

		return (
			<>
				<Input
					label={t("Sample 1")}
					id="sample1"
					name="sample1"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "sample1", value: e.target.value })}
					value={sample1}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.sample1.maxLength,
					minChar: config.enums.campaignRequestSchema.sample1.minLength,
					value: sample1
				})}

				<Input
					label={t("Sample 2")}
					id="sample2"
					name="sample2"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "sample2", value: e.target.value })}
					value={sample2}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.sample2.maxLength,
					minChar: config.enums.campaignRequestSchema.sample2.minLength,
					value: sample2
				})}

				<Input
					label={t("Sample 3")}
					id="sample3"
					name="sample3"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "sample3", value: e.target.value })}
					value={sample3}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.sample3.maxLength,
					minChar: config.enums.campaignRequestSchema.sample3.minLength,
					value: sample3
				})}

				<Input
					label={t("Sample 4")}
					id="sample4"
					name="sample4"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "sample4", value: e.target.value })}
					value={sample4}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.sample4.maxLength,
					minChar: config.enums.campaignRequestSchema.sample4.minLength,
					value: sample4
				})}

				<Input
					label={t("Sample 5")}
					id="sample5"
					name="sample5"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "sample5", value: e.target.value })}
					value={sample5}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.sample5.maxLength,
					minChar: config.enums.campaignRequestSchema.sample5.minLength,
					value: sample5
				})}
			</>
		);
	}

	renderKeywordInfo() {
		let {
			config,

			subscriberOptin,
			subscriberOptout,
			subscriberHelp,
			helpMessage,
			optinKeywords,
			optoutKeywords,
			helpKeywords,
			optinMessage,
			optoutMessage
		} = this.state;
		let { t } = this.props;

		return (
			<>
				<Toggle id="subscriber_optin" label={t("Subscriber Optin")} checked={subscriberOptin} onChange={() => this.onSwitchChange("subscriberOptin")} />

				<Input
					label={t("Optin Keywords")}
					name="optin_keywords"
					id="optin_keywords"
					onChange={e => this.onChangeInput({ fieldName: "optinKeywords", value: e.target.value })}
					type="text"
					value={optinKeywords}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.campaignRequestSchema.optinKeywords.maxLength, value: optinKeywords })}

				<Input
					label={t("Optin Message")}
					id="optin_message"
					name="optin_message"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "optinMessage", value: e.target.value })}
					value={optinMessage}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.optinMessage.maxLength,
					minChar: config.enums.campaignRequestSchema.optinMessage.minLength,
					value: optinMessage
				})}

				<Toggle id="subscriber_optin" label={t("Subscriber Optout")} checked={subscriberOptout} onChange={() => this.onSwitchChange("subscriberOptout")} />

				<Input
					label={t("Optout Keywords")}
					name="optout_keywords"
					id="optout_keywords"
					onChange={e => this.onChangeInput({ fieldName: "optoutKeywords", value: e.target.value })}
					type="text"
					value={optoutKeywords}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.campaignRequestSchema.optoutKeywords.maxLength, value: optoutKeywords })}

				<Input
					label={t("Optout Message")}
					id="optout_message"
					name="optout_message"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "optoutMessage", value: e.target.value })}
					value={optoutMessage}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.optoutMessage.maxLength,
					minChar: config.enums.campaignRequestSchema.optoutMessage.minLength,
					value: optoutMessage
				})}

				<Toggle id="subscriber_help" label="Subscriber Help" checked={subscriberHelp} onChange={() => this.onSwitchChange("subscriberHelp")} />

				<Input
					label={t("Help Keywords")}
					name="help_keywords"
					id="help_keywords"
					onChange={e => this.onChangeInput({ fieldName: "helpKeywords", value: e.target.value })}
					type="text"
					value={helpKeywords}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.campaignRequestSchema.helpKeywords.maxLength, value: helpKeywords })}

				<Input
					label={t("Help Message")}
					id="help_message"
					name="help_message"
					type="textarea"
					onChange={e => this.onChangeInput({ fieldName: "helpMessage", value: e.target.value })}
					value={helpMessage}
					required
				/>
				{this.renderFieldError({
					maxChar: config.enums.campaignRequestSchema.helpMessage.maxLength,
					minChar: config.enums.campaignRequestSchema.helpMessage.minLength,
					value: helpMessage
				})}
			</>
		);
	}

	renderMiscInfo() {
		let { config, resellerId, tag } = this.state;
		let { t } = this.props;

		return (
			<>
				<Input
					label={t("Reseller ID (R000000 = NO RESELLER)")}
					id="reseller_id"
					name="reseller_id"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "resellerId", value: e.target.value })}
					value={resellerId}
				/>
				{this.renderFieldError({ maxChar: config.enums.campaignRequestSchema.resellerId.maxLength, value: resellerId })}

				<Input
					label={t("Tag")}
					id="tag"
					name="tag"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "tag", value: e.target.value })}
					value={tag}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.campaignRequestSchema.tag.maxLength, value: tag })}
			</>
		);
	}

	render = () => {
		let { show, campaignId, onHide, t } = this.props;
		let { selectedTab, loading, config } = this.state;

		if (loading || !config) {
			return (
				<Modal show={show} onHide={onHide} title={campaignId ? t("Update Campaign") : t("Create Campaign")}>
					<div className="a2p-modal">
						<ContentLoader height={650} width={"100%"}>
							{/* The tabs */}
							<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />

							{/* Rows */}
							<rect x="0" y="70" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="150" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="230" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="310" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="390" rx="5" ry="5" width="100%" height="50" />
						</ContentLoader>
					</div>
				</Modal>
			);
		}

		return (
			<Modal show={show} onHide={onHide} title={campaignId ? t("Update Campaign") : t("Create Campaign")}>
				<div className="a2p-modal">
					<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
						<Tab id={EDIT_CAMPAIGN_TABS.basics.id} value={EDIT_CAMPAIGN_TABS.basics.value} />
						<Tab id={EDIT_CAMPAIGN_TABS.options.id} value={EDIT_CAMPAIGN_TABS.options.value} />
						<Tab id={EDIT_CAMPAIGN_TABS.samples.id} value={EDIT_CAMPAIGN_TABS.samples.value} />
						<Tab id={EDIT_CAMPAIGN_TABS.keywords.id} value={EDIT_CAMPAIGN_TABS.keywords.value} />
						<Tab id={EDIT_CAMPAIGN_TABS.misc.id} value={EDIT_CAMPAIGN_TABS.misc.value} />
					</Tabs>

					<div className="a2p-modal__body">{EDIT_CAMPAIGN_TABS.basics.id === selectedTab && this.renderBasicDetailsInfo()}</div>
					<div className="a2p-modal__body">{EDIT_CAMPAIGN_TABS.options.id === selectedTab && this.renderOptionsInfo()}</div>
					<div className="a2p-modal__body">{EDIT_CAMPAIGN_TABS.samples.id === selectedTab && this.renderSamplesInfo()}</div>
					<div className="a2p-modal__body">{EDIT_CAMPAIGN_TABS.keywords.id === selectedTab && this.renderKeywordInfo()}</div>
					<div className="a2p-modal__body">{EDIT_CAMPAIGN_TABS.misc.id === selectedTab && this.renderMiscInfo()}</div>

					<div className="modal__actions">
						<div className={`mb-button mb-button--fit}`} onClick={this.onSave}>
							{t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(EditCampaignModal);

import React from "react";
import Modal from "./DHModal";
import * as Icon from "react-feather";

import "../../styles/css/components/commons/alert.css";

const Alert = props => {
	let { children, title, show, onClose, disable, confirm, cancel, type } = props;
	return (
		<Modal show={show} onHide={() => onClose(false)} title={title}>
			{type && (
				<div className={`alert__icon alert__icon--${type}`}>
					{type === "info" ? (
						<Icon.AlertCircle />
					) : type === "success" ? (
						<Icon.CheckCircle />
					) : type === "error" ? (
						<Icon.XCircle />
					) : type === "warning" ? (
						<Icon.AlertCircle />
					) : (
						""
					)}
				</div>
			)}
			<div className="alert__contents">{children}</div>
			<div className="alert__actions">
				{cancel && (
					<div className={`mb-button mb-button--cancel ${!disable ? "" : "mb-button--disabled"}`} onClick={() => onClose(false)}>
						{cancel}
					</div>
				)}
				{confirm && (
					<div className={`mb-button ${!disable ? "" : "mb-button--disabled"}`} onClick={() => onClose(true)}>
						{confirm}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default Alert;

import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";

import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ReviewsToRespondTo extends PureComponent {
	state = {
		monthlyReviews: 0,
		monthlyReplies: 0
	};

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	fetchData = async () => {
		this.fetchReviewCount();
		this.fetchRepliesCount();
	};

	fetchReviewCount = async () => {
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		let monthlyReviews = await LocationService.fetchMonthlyReviewCount(locationId);
		if (!monthlyReviews) {
			this.setState({
				monthlyReviews: 0
			});
			return;
		}
		this.setState({ monthlyReviews });
	};

	fetchRepliesCount = async () => {
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		let data = await LocationService.fetchMonthlyRepliesCount(locationId);
		if (!data) {
			this.setState({
				monthlyReplies: 0
			});
			return;
		}
		this.setState({
			monthlyReplies: data.repliesCount
		});
	};

	renderBody = () => {
		let { reviewCount, replyCount, isEnabled, isPermissible } = this.props;

		if (!isEnabled()) {
			return <div className="dashboard__card__no-reviews-to-reply-to">Reviews are not enabled. Contact Support to get started.</div>;
		}

		if (!isPermissible()) {
			return <div className="dashboard__card__no-reviews-to-reply-to">Review Data Restricted.</div>;
		}

		// if we have proper review and reply counts
		if (reviewCount > 0 && reviewCount - replyCount > 0) {
			return (
				<div className="dashboard__card__reviews-respond-to">
					<div className="dashboard__card__reviews-respond-to__button">
						<div
							onClick={() => {
								GAService.GAEvent({ category: "Dashboard", action: "click", label: "Reply Now call to action on the dashboard" });
								this.props.history.push("/reviews");
							}}
							className="mb-button mb-button--slim mb-button--fit mb-button--center"
						>
							<i className="fa fa-reply" />
							&nbsp; Reply Now!
						</div>
					</div>
					<div className="dashboard__card__reviews-respond-to__text">
						<div className="dashboard__card__reviews-respond-to__text__number">{reviewCount - replyCount}</div>
						<div>new reviews this month can be replied to!</div>
					</div>
				</div>
			);
		}

		return <div className="dashboard__card__no-reviews-to-reply-to">There are no new reviews to respond to this month.</div>;
	};

	render() {
		const { cardName } = this.props;

		return (
			<DashboardCard className={DASHBOARD_CARDS.reviewsToRespondTo.className} height={DASHBOARD_CARDS.reviewsToRespondTo.h}>
				<div className="dashboard__card__header dashboard__card__header--reviews-to-action">
					<div className="dashboard__card__header__icon">
						<Icon.Airplay size="42" />
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">{cardName ? cardName : "Reviews To Action"}</div>
				</div>

				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withLocation(ReviewsToRespondTo));

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import LocationService from "../../services/LocationService";
import Select from "../../components/common/DHSelect";

class TimezoneDropdown extends Component {
	state = {
		timezones: []
	};
	componentDidMount() {
		this.fetchTimezones();
	}

	async fetchTimezones() {
		let data = await LocationService.fetchTimeZones({});

		if (!data) {
			return;
		}

		const timezones = data.map(aData => ({ label: aData.text, value: aData.value, id: aData.id }));
		this.setState({ timezones });
	}

	render() {
		const { value, onChange, required, id, t, disabled } = this.props;

		const { timezones } = this.state;
		return (
			<Select
				id={id}
				options={timezones}
				onChange={e => onChange(e)}
				value={value}
				isSearchable={true}
				isClearable={true}
				placeholder={t("Please select a timezone")}
				required={required || false}
				disabled={disabled}
			/>
		);
	}
}
export default withTranslation(null, { withRef: true })(TimezoneDropdown);

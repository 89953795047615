import UserService from "../services/UserService";
import GROUP_PERMISSIONS from "./GroupPermissions";

export const SHORT_LINK_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 1
	},
	type: {
		id: "type",
		value: "Type",
		sortable: true,
		sortField: "type",
		width: () => (UserService.isSuperAdminOrCustomerSuccess() ? 1 : 0)
	},
	short_url: {
		id: "short_url",
		value: "Short Url",
		sortable: true,
		sortField: "short_url",
		width: 2
	},
	long_url: {
		id: "long_url",
		value: "Long Url",
		sortable: true,
		sortField: "long_url",
		width: 3
	},
	open_count: {
		id: "open_count",
		value: "Open Count",
		sortable: true,
		sortField: "open_count",
		width: 1
	},
	last_open_time: {
		id: "last_open_time",
		value: "Last Open Time",
		sortable: true,
		sortField: "last_open_time",
		width: 2
	},
	expire_at: {
		id: "expire_at",
		value: "Expire At",
		sortable: true,
		sortField: "expire_at",
		width: 2
	},
	action: {
		id: "action",
		value: "Action",
		sortable: false,
		sortField: "action",
		width: 1,
		notClickable: true
	}
};

export const SHORT_LINK_TABS = {
	custom: {
		id: "custom",
		value: "Custom"
	},
	details: {
		id: "details",
		value: "Details"
	},
	qrCode: {
		id: "qrCode",
		value: "QR Code"
	}
};

export const SHORT_LINK_TYPES = {
	general: "general",
	payments: "payments",
	secureChat: "secure_chat",
	videoChat: "video_chat",
	media: "media",
	reviewRequest: "review_request",
	bookingRequest: "booking_request",
	custom: "custom"
};

export const SHORT_LINK_TYPE_LABEL = {
	general: "General",
	payments: "Payments",
	secureChat: "Secure Chat",
	videoChat: "Video Chat",
	media: "Media",
	reviewRequest: "Review Request",
	bookingRequest: "Booking Request",
	custom: "Custom"
};

export const SHORT_LINK_TYPE_FILTERS = {
	all: {
		id: "all",
		display: "All",
		order: 0
	},
	custom: {
		id: "custom",
		display: "Custom",
		order: 1
	},
	bookingRequest: {
		id: "booking_request",
		display: "Booking Request",
		order: 2
	},
	reviewRequest: {
		id: "review_request",
		display: "Review Request",
		order: 3
	},
	media: {
		id: "media",
		display: "Media",
		order: 4
	},
	videoChat: {
		id: "video_chat",
		display: "Video Chat",
		order: 5
	},
	secureChat: {
		id: "secure_chat",
		display: "Secure Chat",
		order: 6
	},
	payments: {
		id: "payments",
		display: "Payments",
		order: 7
	}
};

export const SHORT_LINK_EXPIRED_FILTERS = {
	all: {
		id: "all",
		display: "All",
		order: 0
	},
	valid: {
		id: "valid",
		display: "Valid Only",
		order: 1
	},
	expired: {
		id: "expired",
		display: "Expired",
		order: 2
	}
};

export const SHORT_LINK_LIMIT = 10;

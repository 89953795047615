export const LAGGING_LOCATIONS_FILTERS = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	favourites: {
		id: "favourites",
		display: "Favourites",
		order: 2
	}
};

export const LAGGING_LOCATIONS_COLUMNS = {
	company_name: {
		id: "company_name",
		value: "Company",
		sortable: true,
		sortField: "company_name",
		width: 1
	},
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 1
	},
	last_review_request: {
		id: "last_review_request",
		value: "Last Review Request",
		sortable: true,
		sortField: "last_review_request",
		width: 1
	},
	last_auto_review_request: {
		id: "last_auto_review_request",
		value: "Last Auto Review Request",
		sortable: true,
		sortField: "last_auto_review_request",
		width: 1
	},
	oldest_unread_date: {
		id: "oldest_unread_date",
		value: "Oldest Unread",
		sortable: true,
		sortField: "oldest_unread_date",
		width: 1
	},
	last_message_date: {
		id: "last_message_date",
		value: "Last Message",
		sortable: true,
		sortField: "last_message_date",
		width: 1
	},
	webchat_status: {
		id: "webchat_status",
		value: "Webchat",
		sortable: true,
		sortField: "webchat_status",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

import React, { Component } from "react";
import * as Icon from "react-feather";

import "./TeamChatDiscover.css";

class TeamChatDiscover extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		let { onGetStartedClicked } = this.props;

		return (
			<div className="teamchat-discovery">
				<div className="teamchat-discovery__wrapper">
					<div className="teamchat-discovery__icon">
						<Icon.Bell size="17" />
					</div>
					<div className="teamchat-discovery__subtitle">Introducing</div>
					<div className="teamchat-discovery__title">
						Team Chat <span className="teamchat-discovery__mini_title">(Beta)</span>
					</div>
					<div className="teamchat-discovery__divider" />
					<div className="teamchat-discovery__features">
						<div className="teamchat-discovery__feature">
							<div className="teamchat-discovery__feature__icon">
								<Icon.Users size="16" />
							</div>
							<div className="teamchat-discovery__feature__description">Start chatting and stay connected with your team!</div>
						</div>
						<div className="teamchat-discovery__feature">
							<div className="teamchat-discovery__feature__icon">
								<Icon.Info size="16" />
							</div>
							<div className="teamchat-discovery__feature__description">
								Learn more about team chat and it's benefits{" "}
								<a target="_blank" href="https://www.demandhub.co/products/teamchat/" rel="noopener noreferrer">
									here.
								</a>
							</div>
						</div>
					</div>
					<div className="teamchat-discovery__action" onClick={onGetStartedClicked}>
						Get Started
					</div>
				</div>
			</div>
		);
	}
}

export default TeamChatDiscover;

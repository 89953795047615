import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MessageSquareText, Smartphone, X } from "lucide-react";

import { IFRAME_STYLES } from "../../../constants/WidgetConstants";
import { BUBBLE_ICONS } from "../../../constants/LanguageConstants";

import withLocation from "../../../components/common/WithLocation";

import WidgetConfigService from "../../../services/WidgetConfigService";
import NotificationService from "../../../services/NotificationService";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/web-chat-config.css";

class WebchatPreviewBubble extends Component {
	constructor(props) {
		super(props);

		let iframeStyles = { ...IFRAME_STYLES.bubble };

		iframeStyles = WidgetConfigService.extendStylesForPositioning({
			styles: iframeStyles
		});

		this.state = {
			iframeStyles,
			isBubbleOpen: false
		};

		this.bubbleRef = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
			// Make sure the render adjusts to the new bubble width first
			setTimeout(() => {
				this.bubbleUpdate();
			}, 100);
		}
	}

	componentDidMount() {
		this.updateDimensions();

		// This hack is needed because for whatever reason, the bubble is not apart of the DOM when the component mounds
		setTimeout(() => {
			this.bubbleUpdate();
		}, 100);
	}

	bubbleUpdate = () => {
		let { bubble } = this.props;

		if (!this.bubbleRef) {
			return;
		}

		let element = this.bubbleRef;

		let newWidth = 100;

		if (!bubble.showText) {
			this.updateDimensions({ newWidth });
		} else {
			let { width } = element.getBoundingClientRect();

			// width should be between 100 and 260
			newWidth = Math.max(100, Math.min(260, width)) + 40;

			this.updateDimensions({ newWidth });
		}
	};

	toggleBubbleShown = () => {
		this.update({
			isBubbleOpen: !this.state.isBubbleOpen
		});
	};

	updateDimensions = ({ newWidth } = {}) => {
		let { position } = this.props;

		IFRAME_STYLES.bubble.width = `${newWidth}px`;

		let iframeStyles = { ...IFRAME_STYLES.bubble };

		iframeStyles = WidgetConfigService.extendStylesForPositioning({
			position: position.value,
			styles: iframeStyles
		});

		this.update({
			iframeStyles
		});

		NotificationService.publish("bubbleIframeStyleUpdate", newWidth);
	};

	render() {
		const {
			// Bubble
			bubble,
			languageSectionBubbleText
		} = this.props;
		let { isBubbleOpen, iframeStyles } = this.state;

		// Added this to show difference between bubble open and bubble closed
		let showModal = isBubbleOpen;

		// Bubble settings
		let showBubbleText = bubble.showText;
		let bubbleText = bubble.text[languageSectionBubbleText] || "Text us";
		let bubbleIcon = bubble.icon;
		let bubbleBackgroundColor = bubble.backgroundColor;
		let bubbleTextColor = bubble.textColor;

		let bubbleStyles = ["bubble"];

		if (!showBubbleText) {
			bubbleStyles.push("bubble--no-text");
		}

		if (showModal) {
			bubbleStyles.push("bubble--close");
		}

		return (
			<div id="dh-webchat-bubble-iframe" style={iframeStyles}>
				<div
					ref={ref => (this.bubbleRef = ref)}
					onClick={this.toggleBubbleShown}
					className={bubbleStyles.join(" ")}
					style={{ backgroundColor: bubbleBackgroundColor }}
				>
					<div className="bubble__icon_container">
						{!showModal && bubbleIcon === BUBBLE_ICONS.text && <MessageSquareText color={bubbleTextColor} width={27} height={27} strokeWidth={1.5} />}
						{!showModal && bubbleIcon === BUBBLE_ICONS.phone && <Smartphone color={bubbleTextColor} width={27} height={27} strokeWidth={1.5} />}

						{showModal && <X color={bubbleTextColor} width={27} height={27} strokeWidth={1.5} />}
					</div>
					{showBubbleText && (
						<div className="bubble__text" style={{ color: bubbleTextColor }}>
							{bubbleText}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(WebchatPreviewBubble));

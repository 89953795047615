export const REVIEW_SITE_IDS_BY_NAME = {
	direct: 1,
	google: 2,
	facebook: 3,
	yelp: 4,
	rateMds: 5,
	healthGrades: 6,
	realSelf: 7,
	yellowPages: 8,
	custom: 9
};
export const REVIEW_SITES = {
	google: {
		name: "Google",
		id: 2
	},
	facebook: {
		name: "Facebook",
		id: 3
	}
};
export const getReviewSiteName = siteId => {
	let site = "";

	switch (siteId) {
		case 0:
			site = "Options";
			break;
		case 1:
			site = "Direct";
			break;
		case 2:
			site = "Google";
			break;
		case 3:
			site = "Facebook";
			break;
		case 4:
			site = "Yelp";
			break;
		case 5:
			site = "RateMDs";
			break;
		case 6:
			site = "HealthGrades";
			break;
		case 7:
			site = "RealSelf";
			break;
		case 8:
			site = "YellowPages";
			break;
		default:
			site = "None";
			break;
	}

	return site;
};

import "babel-polyfill";
import "./services/ReportValidityPolyfill";
import "./services/NotificationPolyfill";
import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import App from "./App";
import $ from "jquery";

//import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "./../node_modules/font-awesome/css/font-awesome.css";
import "./../node_modules/animate.css/animate.min.css";

import "./../node_modules/react-grid-layout/css/styles.css";
import "./../node_modules/react-resizable/css/styles.css";

import "./index.css";

// Translations
import "./i18n/config";

window.$ = window.jQuery = global.jQuery = $;

// Require respects the order of statements (such as the above line for jquery)
require("bootstrap");

//ReactDOM.render(<Provider store={store}><StripeProvider apiKey="pk_test_ICKKzSeGdZGEdZnVLZUpjfjM"><App /></StripeProvider></Provider>, document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById("root"));

registerServiceWorker();

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import c3 from "c3";
import { formatDate, parseDate } from "react-day-picker/moment";

import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { AnalyticsService } from "../../services/AnalyticsService";

import Spinners from "../../components/common/Spinners";
import withLocation from "../../components/common/WithLocation";
import LegacyPage from "../../components/common/LegacyPage";

class LocationEmailReport extends Component {
	state = {
		emailReportStats: undefined,
		loading: false,
		reportType: "daily_digest_reports",
		start: moment()
			.subtract(30, "days")
			.toDate(),
		end: moment().toDate()
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchEmailReportStats();
	}

	async fetchEmailReportStats() {
		this.setState({ loading: true });
		const { start, end, reportType } = this.state;

		let data = await AnalyticsService.getLocationEmailReportAnalytics({
			locationId: UserService.getActiveLocation().id,
			start,
			end,
			reportType
		});

		if (!data) {
			this.setState({ loading: false });
			return;
		}

		this.setState({ emailReportStats: data }, () => this.updateChart());
	}

	updateChart() {
		this.setState({ loading: false }, () => {
			const { start, end } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;

			const totalOpenedX = this.state.emailReportStats.opened.map(anOpened => anOpened.date);
			totalOpenedX.unshift("x");
			const totalOpenedY = this.state.emailReportStats.opened.map(anOpened => anOpened.count);
			totalOpenedY.unshift("Total Reports Opened");

			const totalClickedX = this.state.emailReportStats.clicked.map(aClicked => aClicked.date);
			totalClickedX.unshift("x");
			const totalClickedY = this.state.emailReportStats.clicked.map(aClicked => aClicked.count);
			totalClickedY.unshift("Total Reports Clicked");

			c3.generate({
				bindto: "#email-report-analytics-chart",
				data: {
					x: "x",
					columns: [totalOpenedX, totalOpenedY, totalClickedX, totalClickedY],
					type: "bar"
				},
				axis: {
					x: {
						label: {
							text: isMonth ? "Month" : "Day",
							position: "outer-bottom"
						},
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								//return monthString[x.getMonth()];
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
							//format: '%Y' // format string is also available for timeseries data
						}
					},
					y: {
						label: {
							text: "Counts",
							position: "outer-top"
						}
					}
				}
			});
		});
	}

	onLocationChanged = location => {
		this.fetchEmailReportStats();
	};

	handleFromChange(start) {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	}
	handleToChange(end) {
		this.setState({ end }, this.showFromMonth);
	}
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}
	handleUpdateDate(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fetchEmailReportStats();
	}
	handleReportSelectorOnChange(event) {
		this.setState({ reportType: event.target.value }, () => this.fetchEmailReportStats());
	}
	render() {
		const { loading, emailReportStats, start, end, reportType } = this.state;
		const modifiers = { start, end };

		if (loading) {
			return (
				<div className="Common__spinnerdiv--center-flex">
					<Spinners type="tail-fade" loading={true} size="120px" />
				</div>
			);
		}
		return (
			<LegacyPage>
				<div>
					<div className="row wrapper border-bottom white-bg page-heading">
						<div className="ReviewSitesAnalytics__page--header">
							<div className="col-sm-6 col-md-8 col-lg-9">
								<h2>
									Location Email Reports <span className="hidden-xxs"> - {UserService.getActiveLocation().name}</span>
								</h2>
							</div>
						</div>

						<div className="form-group ReviewSitesAnalytics__form-datepicker">
							<label>Date Range&nbsp;&nbsp;</label>
							<div className="input-group" id="datepicker">
								<div className="InputFromTo">
									<DayPickerInput
										value={start}
										placeholder=" From"
										format="LL"
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [start, { from: start, to: end }],
											disabledDays: { after: end },
											toMonth: end,
											modifiers,
											numberOfMonths: 2,
											onDayClick: () => this.to.getInput().focus()
										}}
										onDayChange={start => this.handleFromChange(start)}
									/>{" "}
									<span className="InputFromTo-to">
										<DayPickerInput
											ref={el => (this.to = el)}
											value={end}
											placeholder=" To"
											format="LL"
											formatDate={formatDate}
											parseDate={parseDate}
											dayPickerProps={{
												selectedDays: [start, { from: start, to: end }],
												disabledDays: { before: start },
												modifiers,
												month: start,
												fromMonth: start,
												numberOfMonths: 2
											}}
											onDayChange={this.handleToChange}
										/>
									</span>
								</div>
							</div>{" "}
							<button id="date-update" className="btn btn-primary btn-sm ReviewSites__update-date--btn" onClick={e => this.handleUpdateDate(e)}>
								Update Date
							</button>
						</div>
						<div className="col-lg-3" style={{ display: "flex", width: "100%", alignItems: "center" }}>
							<div>
								<label htmlFor="reports-selector">Report Type&nbsp;&nbsp;</label>
							</div>
							<div style={{ width: "250px" }}>
								<select id="reports-selector" value={reportType} className="form-control" onChange={e => this.handleReportSelectorOnChange(e)}>
									<option value="daily_digest_reports">Daily Digest Report</option>
									<option value="location_reports">Location Report</option>
									<option value="contact_assigned_reports">Contact Assigned</option>
									<option value="new_message_reports">New Message Report</option>
								</select>
							</div>
						</div>
					</div>

					<div className="wrapper wrapper-content">
						<div className="row">
							<div className="col-lg-12">
								<div className="ibox">
									<div className="ibox-content forum-post-container">
										{!emailReportStats ? (
											<h2>
												Sorry, no data was found{" "}
												<span role="img" aria-label="sad-face">
													😞
												</span>
											</h2>
										) : (
											<div id="email-report-analytics-chart" />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LegacyPage>
		);
	}
}

export default withRouter(withLocation(LocationEmailReport));

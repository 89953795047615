import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import Insight from "../../components/common/Insight";
import Spinners from "../../components/common/Spinners";
import ReactTooltip from "react-tooltip";
import GAService from "../../services/GAService";

import withLocation from "../../components/common/WithLocation";

import "../../App.css";
import "../../styles/css/scenes/review-insights.css";
import { AnalyticsService } from "../../services/AnalyticsService";

class ReviewsInsights extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locationData: null,
			positiveInsights: [],
			negativeInsights: [],
			showReviewsModal: false,
			reviewsFromInsight: [],
			reportType: "all_time",
			loading: true
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		console.log("Icons made by: https://www.flaticon.com/authors/freepik and licensed by: http://creativecommons.org/licenses/by/3.0/");
		this.refreshPositiveInsights();
		this.refreshNegativeInsights();
	}

	onLocationChanged = location => {
		this.refreshPositiveInsights();
		this.refreshNegativeInsights();
	};

	async refreshPositiveInsights() {
		let user = UserService.get();

		if (!user) {
			return;
		}

		let data = await AnalyticsService.fetchReviewInsightData({
			location: UserService.getActiveLocation().id,
			sentiment: 1,
			reportType: this.state.reportType
		});

		if (!data) {
			this.setState({ loading: false });
			return;
		}

		this.setState({ positiveInsights: data, loading: false });
	}

	async refreshNegativeInsights() {
		let user = UserService.get();

		if (!user) {
			return;
		}

		let location = UserService.getActiveLocation();

		let data = await AnalyticsService.fetchReviewInsightData({
			location: location.id,
			sentiment: -1,
			reportType: this.state.reportType
		});

		this.setState({ negativeInsights: data });
	}

	refreshDateRange(reportTypeEnum) {
		this.setState({ reportType: reportTypeEnum }, () => {
			this.refreshPositiveInsights();
			this.refreshNegativeInsights();
		});
	}

	render() {
		if (this.state.loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners type="tail-fade" loading={true} size="120px" />
				</div>
			);
		}
		let location = UserService.getActiveLocation();

		if (!location) {
			// No locations have been configured
			return <Redirect to="/dashboard" />;
		}

		return (
			<div>
				<div className="row wrapper border-bottom white-bg page-heading">
					<div className="col-sm-6 col-md-8 col-lg-9">
						<h2>
							Insights<span className="hidden-xxs"> - {location.name}</span>
						</h2>
					</div>
				</div>
				<div className="wrapper wrapper-content main-table-margin">
					<div className="row">
						<div className="col-lg-12">
							<div className="ibox float-e-margins">
								<div className="ibox-content forum-post-container">
									<div>
										<ul className="ReviewInsights-nav">
											<li role="presentation" className={this.state.reportType === "this_week" ? "active" : ""}>
												<a id="weekly" href="#0" onClick={event => this.refreshDateRange("this_week")}>
													This Week
												</a>
											</li>
											<li role="presentation" className={this.state.reportType === "this_month" ? "active" : ""}>
												<a id="monthly" href="#0" onClick={event => this.refreshDateRange("this_month")}>
													This Month
												</a>
											</li>
											<li role="presentation" className={this.state.reportType === "last_month" ? "active" : ""}>
												<a id="lastMonth" href="#0" onClick={event => this.refreshDateRange("last_month")}>
													Last Month
												</a>
											</li>
											<li role="presentation" className={this.state.reportType === "all_time" ? "active" : ""}>
												<a id="allTime" href="#0" onClick={event => this.refreshDateRange("all_time")}>
													All Time
												</a>
											</li>
										</ul>
									</div>
									{this.state.negativeInsights.length === 0 && this.state.positiveInsights.length === 0 ? (
										<div>
											<br />
											<br />
											<h4>
												Sorry, no data was found{" "}
												<span role="img" aria-label="sad-face">
													😞
												</span>
											</h4>
										</div>
									) : (
										<div>
											<br />
											<br />
											<div className="ReviewsInsights__positiveInsights--title">
												<h2>
													Here's what your customers are talking about the most &nbsp;&nbsp;
													<i
														data-tip
														data-for="review-insights-tooltip"
														className="ReviewInsights__information--icon fa fa-question-circle"
														aria-hidden="true"
													/>
													<ReactTooltip
														className="mb-react-tooltip"
														arrowColor="#333"
														id="review-insights-tooltip"
														type="info"
														effect="solid"
														place="right"
														multiline="true"
													>
														This page shows you the most <br /> frequently used words customers <br /> have used to describe your business, <br /> and also
														throws light on their <br /> associated sentiments!
													</ReactTooltip>
												</h2>
											</div>
											{this.state.positiveInsights.length > 0
												? this.state.positiveInsights.map(insight => {
														return <Insight key={insight.id} baseInsight={insight} sentiment="1" />;
												  })
												: ""}
											{this.state.negativeInsights.length > 0
												? this.state.negativeInsights
														.slice(0)
														.reverse()
														.map(insight => {
															return <Insight key={insight.id} baseInsight={insight} sentiment="-1" />;
														})
												: ""}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(ReviewsInsights));

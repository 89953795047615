import React, { Component } from "react";
import moment from "moment";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import * as Icon from "react-feather";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import { CsvService } from "../../../services/CsvService";
import { AnalyticsService } from "../../../services/AnalyticsService";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import MessagesAnalytics from "./MessagesAnalytics";
import MessageStates from "./MessageStates";
import FailedMessageDeliverability from "./FailedMessageDeliverability";
import DashboardCard from "../../Dashboard/DashboardCard";
import Action from "../../../components/common/Action";
import Spinners from "../../../components/common/Spinners";

import "../../../App.css";
import "../../../styles/css/scenes/analytics.css";
import "react-day-picker/lib/style.css";

class MessengerAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			messageDeliveryStats: null,
			deliveryStatesCsv: null,
			deliveryCodesCsv: null,
			deliveryDescriptionCsv: null,
			messageDeliveryRate: null,
			deliveryStatesChart: null,
			deliveryDescriptionChart: null
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchMessagesStats();
	}

	fetchMessagesStats = async () => {
		try {
			let { deliveryStatesChart, deliveryDescriptionChart } = this.state;

			if (deliveryStatesChart) {
				deliveryStatesChart.destroy();
			}
			if (deliveryDescriptionChart) {
				deliveryDescriptionChart.destroy();
			}

			await this.update({ loading: true });

			const { start, end } = this.state;

			const msgDeliveryStates = await AnalyticsService.getMessageDeliveryAnalytics(UserService.getActiveLocation().id, start, end);
			const messageDeliveryRate = await AnalyticsService.getMessageDeliveryRate(UserService.getActiveLocation().id, start, end);

			await this.update({ messageDeliveryStats: msgDeliveryStates, messageDeliveryRate, loading: false });
			await this.updateMessageStatesChart();

			return;
		} catch (error) {
			console.log(error);
		}
	};

	async updateMessageStatesChart() {
		try {
			this.setState({ loading: false });

			const { messageDeliveryStats } = this.state;

			if (!messageDeliveryStats) {
				return;
			}

			let deliveryStates = messageDeliveryStats.deliveryStates;
			let deliveryWaitingX = deliveryStates.map(item => item.date);
			deliveryWaitingX.unshift("x");
			let deliveryWaitingY = deliveryStates.map(item => item.waiting);
			deliveryWaitingY.unshift("Waiting");
			let deliveredY = deliveryStates.map(item => item.delivered);
			deliveredY.unshift("Delivered");
			let notDeliveredY = deliveryStates.map(item => item.notDelivered);
			notDeliveredY.unshift("Not Delivered");

			let deliveryStateColumn = [deliveryWaitingX, deliveryWaitingY, deliveredY, notDeliveredY];

			let deliveryCodeStats = messageDeliveryStats.deliveryCodeStats;
			let deliveryCodeX = deliveryCodeStats.map(item => item.date);
			deliveryCodeX.unshift("x");
			let deliveryCodeColumns = [deliveryCodeX];

			let statsPerCode = {};
			for (let i = 0; i < deliveryCodeStats.length; i++) {
				const item = deliveryCodeStats[i];
				for (const key in item) {
					if (key === "date") {
						continue;
					}
					const count = item[key];
					if (!statsPerCode[key]) {
						statsPerCode[key] = [`${key}`];
					}
					statsPerCode[key].push(count);
				}
			}
			for (const key in statsPerCode) {
				const stat = statsPerCode[key];
				deliveryCodeColumns.push(stat);
			}

			let deliveryDescriptionStats = messageDeliveryStats.deliveryDescriptionStats;
			let descriptionX = deliveryDescriptionStats.map(item => item.date);
			descriptionX.unshift("x");
			let descriptionColumns = [descriptionX];

			let statsPerDescription = {};
			for (let i = 0; i < deliveryDescriptionStats.length; i++) {
				const item = deliveryDescriptionStats[i];
				for (const key in item) {
					if (key === "date") {
						continue;
					}
					const count = item[key];
					if (!statsPerDescription[key]) {
						statsPerDescription[key] = [`${key}`];
					}
					statsPerDescription[key].push(count);
				}
			}
			for (const key in statsPerDescription) {
				const stat = statsPerDescription[key];
				descriptionColumns.push(stat);
			}

			// Transpose data for CSV
			let deliveryStatesCsv = [...deliveryStateColumn];
			deliveryStatesCsv = deliveryStatesCsv[0].map((col, i) => deliveryStatesCsv.map(row => row[i])); // transpose the data
			if (deliveryStatesCsv && deliveryStatesCsv.length > 0) {
				deliveryStatesCsv[0][0] = "Date";
			}
			let deliveryCodesCsv = [...deliveryCodeColumns];
			deliveryCodesCsv = deliveryCodesCsv[0].map((col, i) => deliveryCodesCsv.map(row => row[i])); // transpose the data
			if (deliveryCodesCsv && deliveryCodesCsv.length > 0) {
				deliveryCodesCsv[0][0] = "Date";
			}

			let deliveryDescriptionCsv = [...descriptionColumns];
			deliveryDescriptionCsv = deliveryDescriptionCsv[0].map((col, i) => deliveryDescriptionCsv.map(row => row[i])); // transpose the data
			if (deliveryDescriptionCsv && deliveryDescriptionCsv.length > 0) {
				deliveryDescriptionCsv[0][0] = "Date";
			}

			this.setState({
				deliveryStatesCsv,
				deliveryCodesCsv,
				deliveryDescriptionCsv
			});

			let deliveryStatesChart = this.generateChart("#delivery-states-analytics-chart", deliveryStateColumn);
			let deliveryDescriptionChart = this.generateChart("#delivery-descriptions-analytics-chart", descriptionColumns);

			await this.update({
				deliveryStatesChart,
				deliveryDescriptionChart
			});
		} catch (error) {
			console.log(error.stack);
		}
	}

	generateChart(id, columns) {
		try {
			const { start, end } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;
			let chart = c3.generate({
				bindto: id,
				data: {
					x: "x",
					columns: columns,
					type: "bar"
				},
				axis: {
					x: {
						label: "Day",
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
						}
					},
					y: {
						label: "Counts"
					}
				}
			});
			return chart;
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.update({ start });
	};
	handleToChange = end => {
		this.update({ end });
	};

	handleUpdateDate = () => {
		this.fetchMessagesStats();
	};

	/**
	 * Implement Date Pickers
	 */
	renderDatePicker = () => {
		const { start, end } = this.state;
		const modifiers = { start, end };

		return (
			<div className="dashboard__card___datepicker">
				<label>Date Range&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={start}
							placeholder=" From"
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [start, { from: start, to: end }],
								disabledDays: { after: end },
								toMonth: end,
								modifiers,
								numberOfMonths: 2
							}}
							onDayChange={start => this.handleFromChange(start)}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={end}
								placeholder=" To"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { before: start },
									modifiers,
									month: start,
									fromMonth: start,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<button id="date-update" className="mb-button" onClick={this.handleUpdateDate}>
					Update Date
				</button>
			</div>
		);
	};

	renderDeliveryDescription = () => {
		const { loading, messageDeliveryStats, deliveryDescriptionCsv } = this.state;
		const locationName = CsvService.filterActiveLocationName();

		return (
			<DashboardCard>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">Delivery Descriptions</div>
					<div className="dashboard__card__header__actions">
						{deliveryDescriptionCsv && (
							<CSVLink data={deliveryDescriptionCsv} filename={`${locationName}-messenger_analytics_delivery_description.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>
				{this.renderDatePicker()}
				{loading ? (
					<div className="analytics__section__chart__spinner">
						<Spinners type="tail-fade" loading={true} size="60px" />
					</div>
				) : !messageDeliveryStats ? (
					<h4 className="text-center">
						Sorry, no data was found{" "}
						<span role="img" aria-label="sad-face">
							😞
						</span>
					</h4>
				) : (
					<div id="delivery-descriptions-analytics-chart" />
				)}
			</DashboardCard>
		);
	};

	renderDeliveryStates = () => {
		const { loading, messageDeliveryStats, deliveryStatesCsv } = this.state;
		const locationName = CsvService.filterActiveLocationName();
		return (
			<DashboardCard>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">Delivery States</div>
					<div className="dashboard__card__header__actions">
						{deliveryStatesCsv && (
							<CSVLink data={deliveryStatesCsv} filename={`${locationName}-messenger_analytics_delivery_states.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				{this.renderDatePicker()}
				{loading ? (
					<div className="analytics__section__chart__spinner">
						<Spinners type="tail-fade" loading={true} size="60px" />
					</div>
				) : !messageDeliveryStats ? (
					<h4 className="text-center">
						Sorry, no data was found{" "}
						<span role="img" aria-label="sad-face">
							😞
						</span>
					</h4>
				) : (
					<div id="delivery-states-analytics-chart" />
				)}
			</DashboardCard>
		);
	};

	render() {
		return (
			<Page>
				<Header title="Messenger Analytics" />

				<div className="dh-page__cards">
					<div className="wrapper wrapper-content analytics-messenger">
						<MessagesAnalytics />
						<MessageStates />

						{UserService.isSuperAdmin() && (
							<>
								{this.renderDeliveryStates()}
								{this.renderDeliveryDescription()}
							</>
						)}

						<FailedMessageDeliverability />
					</div>
				</div>
			</Page>
		);
	}
}

export default MessengerAnalytics;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import moment from "moment";
import { CSVLink } from "react-csv";
import c3 from "c3";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { AnalyticsService } from "../../services/AnalyticsService";
import { CsvService } from "../../services/CsvService";

import Spinners from "../../components/common/Spinners";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import withLocation from "../../components/common/WithLocation";

import "../../styles/css/scenes/analytics.css";
import "../../App.css";

class KeywordAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			keywordData: null,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			keywordDataCsv: null
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchAnalytics();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = location => {
		this.fetchAnalytics();
	};

	async fetchAnalytics() {
		try {
			this.setState({ loading: true });
			const locationId = UserService.getActiveLocation().id;
			let { start, end } = this.state;
			const analyticsData = await AnalyticsService.getKeywordAnalytics(locationId, start, end);
			await this.update({
				loading: false,
				keywordData: analyticsData,
				keywordDataCsv: analyticsData
			});
			this.updateCharts();
		} catch (error) {
			console.log(error);
		}
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	handleUpdateDate() {
		this.fetchAnalytics();
	}

	async updateCharts() {
		let { keywordData } = this.state;

		if (!keywordData || keywordData.length < 1) {
			return;
		}

		let dates = keywordData.map(item => item.date);
		dates.unshift("x");
		let leadSources = Object.keys(keywordData[0]);
		delete leadSources["date"];
		let statsPerSource = {};
		for (const item of keywordData) {
			for (const key in item) {
				if (key === "date") {
					continue;
				}
				const count = item[key];
				if (!statsPerSource[key]) {
					statsPerSource[key] = [`${key}`]; //key is what will be shown on the graph
				}
				statsPerSource[key].push(count);
			}
		}
		let columns = [];
		columns.push(dates);
		for (const source in statsPerSource) {
			const stat = statsPerSource[source];
			columns.push(stat);
		}
		this.generateChart("#keyword-usage-chart", columns);
	}

	generateChart(id, columns) {
		try {
			const { start, end } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;
			c3.generate({
				bindto: id,
				data: {
					x: "x",
					columns: columns,
					type: "bar"
				},
				axis: {
					x: {
						label: "Day",
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
						}
					},
					y: {
						label: "Counts"
					}
				}
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	renderBody = () => {
		const { loading, start, end } = this.state;
		const { isCard } = this.props;

		const modifiers = { start, end };
		return (
			<>
				<div className={`form-group dh-page__datepicker ${isCard ? "dh-page__datepicker--card" : ""}`}>
					<label>Date Range&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder=" From"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder=" To"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						Update Date
					</button>
				</div>
				{loading && (
					<div className="Common__spinnerdiv--center">
						<Spinners loading={true} type="tail-fade" size="120px" />
					</div>
				)}
				{!loading && (
					<div className="Common__chart-container">
						<div id="keyword-usage-chart" />
					</div>
				)}
			</>
		);
	};

	renderCardHeader = () => {
		const { keywordDataCsv } = this.state;
		const locationName = CsvService.filterActiveLocationName();
		const { cardName } = this.props;

		return (
			<div className="dashboard__card__header dashboard__card__header--full-width">
				<div className="dashboard__card__header__name">{cardName ? cardName : "Keyword Usage"}</div>
				<div className="dashboard__card__header__actions">
					{keywordDataCsv && (
						<CSVLink data={keywordDataCsv} filename={`${locationName}-keyword_analytics.csv`} target="_self">
							<Action label="Download" icon={Icon.Download} className="Common__csv-link">
								<Icon.Download />
							</Action>
						</CSVLink>
					)}
				</div>
			</div>
		);
	};

	render() {
		const { keywordDataCsv } = this.state;
		const locationName = CsvService.filterActiveLocationName();

		const { isCard, isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<>
					{this.renderCardHeader()}
					<div>
						<br />
						<div className="Keywords is not enabled. Contact support to get started."></div>
					</div>
				</>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<>
					{this.renderCardHeader()}
					<div>
						<br />
						<div className="Keywords data is restricted."></div>
					</div>
				</>
			);
		}

		if (isCard) {
			return (
				<>
					{this.renderCardHeader()}
					<div>{this.renderBody()}</div>
				</>
			);
		}

		return (
			<Page>
				<Header title="Keyword Analytics">
					{keywordDataCsv && (
						<CSVLink data={keywordDataCsv} filename={`${locationName}-keyword_analytics.csv`} target="_self">
							<Action label="Download" icon={Icon.Download} className="Common__csv-link">
								<Icon.Download />
							</Action>
						</CSVLink>
					)}
				</Header>
				{this.renderBody()}
			</Page>
		);
	}
}

export default withRouter(withLocation(KeywordAnalytics));

export const INVITE_STATUS = {
	creating: "creating",
	pending: "pending",
	sending: "sending",
	sent: "sent",
	failed: "failed",
	cancelled: "cancelled",
	deleted: "deleted"
};

export const INVITE_STATUS_CLASS = {
	creating: "invites__label--creating",
	pending: "invites__label--pending",
	sending: "invites__label--sending",
	sent: "invites__label--sent",
	failed: "invites__label--failed",
	cancelled: "invites__label--cancelled",
	deleted: "invites__label--deleted"
};

export const INVITES_DEADLINE_ACTION = {
	send: "send",
	expire: "expire",
	label: {
		send: "Send",
		expire: "Expire"
	}
};

export const SEND_REVIEW_INVITES_MODAL = {
	type: {
		invite: {
			id: "invite",
			name: "Invite"
		},
		reviewReminder: {
			id: "reviewReminder",
			name: "Review Reminder"
		}
	}
};

export const CREATE_REVIEW_INVITE_ERRORS = {
	receiveFeedbackSmsDisabled: {
		id: "Silent: !contact.receive_feedback_sms contact",
		message: "Sending SMS to this contact is disabled. They have the receive feedback flag disabled."
	},
	receiveFeedbackEmailDisabled: {
		id: "Silent: !contact.receive_feedback_emails contact",
		message: "Sending email to this contact is disabled. They have the receive feedback flag disabled."
	},
	failedToSend: {
		id: "failedToSend",
		message: "The review request could not be sent. Please check the phone number or email and try again."
	}
};

export const INVITES_FILTERS = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	remindable: {
		id: "remindable",
		display: "Remindable Only",
		order: 2
	}
};

export const INVITES_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 1,
		widthMd: 1
	},
	phoneOrEmail: {
		id: "phoneOrEmail",
		value: "Phone Or Email",
		sortable: false,
		sortField: "phoneOrEmail",
		width: 2,
		widthMd: 0
	},
	status: {
		id: "status",
		value: "Status",
		sortable: true,
		sortField: "status",
		width: 1
	},
	sendAfter: {
		id: "sendAfter",
		value: "Send After",
		sortable: true,
		sortField: "send_After",
		width: 2
	},
	leadSource: {
		id: "leadSource",
		value: "Lead Source",
		sortable: true,
		sortField: "lead_source",
		width: 1,
		widthMd: 0
	},
	clicked: {
		id: "clicked",
		value: "Clicked",
		sortable: true,
		sortField: "clicked_at",
		width: 1,
		widthMd: 0
	},
	sentBy: {
		id: "sentBy",
		value: "Sent By",
		sortable: false,
		sortField: "sentBy",
		width: 1,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const INVITES_TAB = {
	invites: {
		id: "invites",
		value: "Review Invites",
		route: "/invites/review-invites"
	},
	reminder: {
		id: "reminder",
		value: "Reminders",
		route: "/invites/reminders"
	}
};

export const INVITES_STATUS_OPTIONS = {
	all: {
		value: "all",
		label: "All"
	},
	sent: {
		value: "sent",
		label: "Sent"
	},
	pending: {
		value: "pending",
		label: "Pending"
	},
	failed: {
		value: "failed",
		label: "Failed"
	},
	cancelled: {
		value: "cancelled",
		label: "Cancelled"
	}
};

export const INVITES_REMINDABLE_OPTIONS = {
	all: {
		value: "all",
		label: "All"
	},
	remindable: {
		value: "remindable",
		label: "Remindable Only"
	}
};

export const UNKNOWN_NAMES_OPTIONS = {
	all: {
		value: "all",
		label: "All Names"
	},
	unknown: {
		value: "unknown",
		label: "Unknown Names"
	}
};

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";

import UserService from "../../../services/UserService";
import { CsvService } from "../../../services/CsvService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import JSONService from "../../../services/JSONService";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import FailedMessagesTable from "./FailedMessagesTable";

import "../../../App.css";
import "../../../styles/css/scenes/analytics.css";
import "react-day-picker/lib/style.css";

class FailedMessageDeliverability extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			failedMessages: [],
			messageDeliveryRate: null
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.fetchMessagesStats();
	}

	async fetchMessagesStats() {
		try {
			this.setState({ loading: true });
			const { start, end } = this.state;

			const failedMessages = await AnalyticsService.getFailedMessages(UserService.getActiveLocation().id, start, end);
			const messageDeliveryRate = await AnalyticsService.getMessageDeliveryRate(UserService.getActiveLocation().id, start, end);

			await this.update({ failedMessages, messageDeliveryRate, loading: false });

			return;
		} catch (error) {
			console.log(error);
		}
		this.setState({ loading: false });
	}

	onLocationChanged = location => {
		this.fetchMessagesStats();
	};

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};
	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}
	handleUpdateDate(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fetchMessagesStats();
	}

	sortFailedMessages = async (sortField, sortOrder) => {
		let { failedMessages } = this.state;

		let data = JSONService.sortByField(failedMessages, sortField, sortOrder);
		await this.update({ failedMessages: data });
	};

	render() {
		const { loading, start, end, failedMessages, messageDeliveryRate } = this.state;
		const { isCard, cardName, isEnabled, isPermissible } = this.props;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();

		if (isEnabled && !isEnabled()) {
			return (
				<div className="analytics__section__chart">
					{isCard && (
						<div className="dashboard__card__header dashboard__card__header--full-width">
							<div className="dashboard__card__header__name">{cardName ? cardName : "Failed Message Deliverability"}</div>
						</div>
					)}
					<br />
					<div>Messenger is not enabled. Contact support to get started.</div>
				</div>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<div className="analytics__section__chart">
					{isCard && (
						<div className="dashboard__card__header dashboard__card__header--full-width">
							<div className="dashboard__card__header__name">{cardName ? cardName : "Failed Message Deliverability"}</div>
						</div>
					)}
					<br />
					<div>Messenger reporting data is restricted.</div>
				</div>
			);
		}

		if (loading) {
			return (
				<div className="analytics__section__chart">
					{isCard && (
						<div className="dashboard__card__header dashboard__card__header--full-width">
							<div className="dashboard__card__header__name">{cardName ? cardName : "Failed Message Deliverability"}</div>
						</div>
					)}
					<div className="analytics__section__chart__spinner">
						<Spinners type="tail-fade" loading={true} size="60px" />
					</div>
				</div>
			);
		}

		return (
			<div className="analytics__section__chart">
				{isCard && (
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{cardName ? cardName : "Failed Message Deliverability"}</div>
						<div className="dashboard__card__header__actions">
							{failedMessages && failedMessages.length > 0 && (
								<CSVLink data={failedMessages} filename={`${locationName}-failed_messages.csv`} target="_self">
									<Action label="Download" icon={Icon.Download} className="Common__csv-link">
										<Icon.Download />
									</Action>
								</CSVLink>
							)}
						</div>
					</div>
				)}
				<div className={`form-group dh-page__datepicker ${isCard ? "dh-page__datepicker--card" : ""}`}>
					<label>Date Range&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder=" From"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder=" To"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						Update Date
					</button>
				</div>

				<div className="Common__chart-container">
					<div className="dh-page__chart-title">
						{!isCard && (
							<div>
								{cardName ? cardName : "Failed Message Deliverability"}
								{messageDeliveryRate && (
									<small>
										{messageDeliveryRate.failedMessages} failed messages out of {messageDeliveryRate.sentMessages} sent
									</small>
								)}
							</div>
						)}

						{!isCard && failedMessages && failedMessages.length > 0 && (
							<CSVLink data={failedMessages} filename={`${locationName}-failed_messages.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>

					<FailedMessagesTable messages={failedMessages} loading={loading} sortBy={this.sortFailedMessages} />
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(FailedMessageDeliverability));

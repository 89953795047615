import React, { Component } from "react";
import config from "../../../../config/app/web-app.config";
import { GiphyFetch } from "@giphy/js-fetch-api";
import ContentLoader from "react-content-loader";
import _ from "lodash";

import GifImage from "./GifImage";

import "./GifCarousel.css";
class GifCarousel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			gifs: [],
			offset: 0
		};

		this.giphyFetcher = null;

		this.loadMoreEdge = React.createRef();

		this.searchDebounced = _.debounce(
			async () => {
				await this.resetComponent();
				await this.fetchGifs();
			},
			1000,
			{
				leading: false,
				trailing: true
			}
		);

		this.loadMoreDebounced = _.debounce(
			async () => {
				await this.fetchGifs(true);
			},
			2000,
			{
				leading: false,
				trailing: true
			}
		);
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidUpdate(prevProps) {
		let { searchKey, show } = this.props;

		if (prevProps.show !== show) {
			await this.resetComponent();
			await this.fetchGifs();
		} else if (prevProps.searchKey !== searchKey) {
			await this.searchDebounced();
		}
	}

	async componentDidMount() {
		// Get sdk from config
		let webSDKKey = config.GIPHY_SDK_KEY;

		// Create the giphy fetcher object
		this.giphyFetcher = new GiphyFetch(webSDKKey);

		await this.resetComponent();
	}

	checkloadMoreEdge = event => {
		if (!this.loadMoreEdge) {
			return;
		}

		const leftBorder = this.loadMoreEdge.getBoundingClientRect().left;

		if (event.target.scrollLeft >= leftBorder) {
			this.loadMoreDebounced();
		}
	};

	async resetComponent() {
		await this.update({ gifs: [], offset: 0 });
	}

	async fetchGifs(loadMore = false) {
		try {
			let { offset, gifs } = this.state;
			let { searchKey } = this.props;
			let giphyFetcher = this.giphyFetcher;

			if (loadMore) {
				offset += 10;
			}

			let response = null;

			// Use the trending endpoint when nothing to search
			if (searchKey === "") {
				response = await giphyFetcher.trending({ rating: "pg", offset, limit: 10 });
			}
			// Search giphy with search key
			else {
				response = await giphyFetcher.search(searchKey, { rating: "pg", offset, limit: 10 });
			}

			if (loadMore) {
				const appendedCopy = gifs.concat(response.data);
				await this.update({ offset, gifs: appendedCopy });
			} else {
				await this.update({ gifs: response.data });
			}
		} catch (error) {
			console.log(error);
		}
	}

	onGifSelected(theGif) {
		let { lowResolution } = this.props;

		let url = theGif.images.original.url;

		if (lowResolution) {
			url = theGif.images.fixed_height_downsampled.url;
		}

		let title = theGif.title;
		let fileType = ".gif";

		if (this.props.onGifSelected) {
			this.props.onGifSelected({ url, title, fileType });
		}
	}

	render() {
		let { gifs } = this.state;
		let { show } = this.props;

		if (!gifs || !show) {
			return null;
		}

		return (
			<div className="gif-carousel__container" onScroll={this.checkloadMoreEdge}>
				{gifs.map((gif, index) => {
					let title = gif.title;
					let url = gif.images.original.url;
					let width = gif.images.original.width;
					let height = gif.images.original.height;

					return <GifImage key={index} url={url} title={title} width={width} height={height} gifSelected={() => this.onGifSelected(gif)} />;
				})}
				<div className="gif-carousel__load-more-edge" ref={ref => (this.loadMoreEdge = ref)}>
					<ContentLoader height={200} width={"100%"}>
						<rect x="0" y="0" rx="5" ry="5" width="200" height="200" />
					</ContentLoader>
				</div>
			</div>
		);
	}
}

export default GifCarousel;

import React, { useEffect } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { $getRoot, $getSelection, $isRangeSelection, FOCUS_COMMAND, CLEAR_EDITOR_COMMAND, FORMAT_TEXT_COMMAND } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ListNode, ListItemNode } from "@lexical/list";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";

import ToolbarPlugin from "./ToolbarPlugin";
import PressEnterPlugin from "./PressEnterPlugin";
// import TreeViewPlugin from "./TreeViewPlugin"; In case we need to debug, keep this

// Import the Lexical CSS
import "./DHLexical.css";

// Example theme object
const theme = {
	ltr: "dh-lexical-editor-ltr",
	rtl: "dh-lexical-editor-rtl",
	paragraph: "dh-lexical-editor-paragraph",
	quote: "dh-lexical-editor-quote",
	heading: {
		h1: "dh-lexical-editor-heading-h1",
		h2: "dh-lexical-editor-heading-h2",
		h3: "dh-lexical-editor-heading-h3",
		h4: "dh-lexical-editor-heading-h4",
		h5: "dh-lexical-editor-heading-h5",
		h6: "dh-lexical-editor-heading-h6"
	},
	list: {
		nested: {
			listitem: "dh-lexical-editor-nested-listitem"
		},
		ol: "dh-lexical-editor-list-ol",
		ul: "dh-lexical-editor-list-ul",
		listitem: "dh-lexical-editor-listItem",
		listitemChecked: "dh-lexical-editor-listItemChecked",
		listitemUnchecked: "dh-lexical-editor-listItemUnchecked"
	},
	hashtag: "dh-lexical-editor-hashtag",
	image: "dh-lexical-editor-image",
	link: "dh-lexical-editor-link",
	text: {
		bold: "dh-lexical-editor-textBold",
		code: "dh-lexical-editor-textCode",
		italic: "dh-lexical-editor-textItalic",
		strikethrough: "dh-lexical-editor-textStrikethrough",
		subscript: "dh-lexical-editor-textSubscript",
		superscript: "dh-lexical-editor-textSuperscript",
		underline: "dh-lexical-editor-textUnderline",
		underlineStrikethrough: "dh-lexical-editor-textUnderlineStrikethrough"
	},
	code: "dh-lexical-editor-code",
	codeHighlight: {
		atrule: "dh-lexical-editor-tokenAttr",
		attr: "dh-lexical-editor-tokenAttr",
		boolean: "dh-lexical-editor-tokenProperty",
		builtin: "dh-lexical-editor-tokenSelector",
		cdata: "dh-lexical-editor-tokenComment",
		char: "dh-lexical-editor-tokenSelector",
		class: "dh-lexical-editor-tokenFunction",
		"class-name": "dh-lexical-editor-tokenFunction",
		comment: "dh-lexical-editor-tokenComment",
		constant: "dh-lexical-editor-tokenProperty",
		deleted: "dh-lexical-editor-tokenProperty",
		doctype: "dh-lexical-editor-tokenComment",
		entity: "dh-lexical-editor-tokenOperator",
		function: "dh-lexical-editor-tokenFunction",
		important: "dh-lexical-editor-tokenVariable",
		inserted: "dh-lexical-editor-tokenSelector",
		keyword: "dh-lexical-editor-tokenAttr",
		namespace: "dh-lexical-editor-tokenVariable",
		number: "dh-lexical-editor-tokenProperty",
		operator: "dh-lexical-editor-tokenOperator",
		prolog: "dh-lexical-editor-tokenComment",
		property: "dh-lexical-editor-tokenProperty",
		punctuation: "dh-lexical-editor-tokenPunctuation",
		regex: "dh-lexical-editor-tokenVariable",
		selector: "dh-lexical-editor-tokenSelector",
		string: "dh-lexical-editor-tokenSelector",
		symbol: "dh-lexical-editor-tokenProperty",
		tag: "dh-lexical-editor-tokenProperty",
		url: "dh-lexical-editor-tokenOperator",
		variable: "dh-lexical-editor-tokenVariable"
	}
};

const initialConfig = {
	theme: theme,
	nodes: [ListNode, ListItemNode],
	onError(error) {
		console.error(error);
	}
};

// This functional component will use the hook and pass the editor back to the class component
function EditorContextProvider({ setEditorInstance }) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (editor) {
			// Pass the editor instance back to the class component
			setEditorInstance(editor);
		}
	}, [editor, setEditorInstance]);

	// This component does not render anything
	return null;
}

class DHLexical extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.editor = null;
		this.loadJsonState = this.loadJsonState.bind(this);
		this.clearInput = this.clearInput.bind(this);
	}

	setEditorInstance = editor => {
		this.editor = editor;
	};

	handleEditorStateChange = editorState => {
		editorState.read(() => {
			const root = $getRoot(); // Get the root node of the document
			const textContent = root.getTextContent(); // Get the text content of the root node
			const jsonContent = editorState.toJSON(); // Get the JSON representation of the editor state
			const htmlContent = $generateHtmlFromNodes(this.editor); // Make sure this is awaited

			// If you want to pass both the plain text and its JSON representation up to a parent component
			if (this.props.onChange) {
				this.props.onChange(textContent, jsonContent, htmlContent);
			}
		});
	};

	handleEnterKey = event => {
		if (this.props.onEnter) {
			// Call the parent component's onEnter function
			this.props.onEnter();
		}
	};

	loadJsonState = jsonState => {
		// Ensure there's an editor instance and the JSON state is provided
		if (this.editor && jsonState) {
			this.editor.update(() => {
				// Create a new editor state from the JSON state
				const newState = this.editor.parseEditorState(jsonState);

				// Replace the current editor state with the new one
				this.editor.setEditorState(newState);

				// Since the editor's state has changed, manually trigger the handleEditorStateChange
				// This ensures that any parent components listening for changes are notified
				this.handleEditorStateChange(newState);
			});
		}
	};

	clearInput = () => {
		if (this.editor) {
			this.editor.update(() => {
				// Clear the editor content
				this.editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

				// Reset formatting
				const selection = $getSelection();
				if ($isRangeSelection(selection)) {
					// Check and remove active formats
					if (selection.hasFormat("bold")) {
						this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
					}
					if (selection.hasFormat("italic")) {
						this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
					}
					if (selection.hasFormat("underline")) {
						this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
					}
					if (selection.hasFormat("strikethrough")) {
						this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
					}
				}
			});
		}
	};

	insertString = insertionString => {
		this.editor.update(() => {
			const selection = $getSelection();

			if (selection) {
				selection.insertText(insertionString);
			}
		});
	};

	focus = () => {
		if (this.editor) {
			this.editor.dispatchCommand(FOCUS_COMMAND);
		}
	};

	render() {
		const { disabled, placeholder } = this.props;

		return (
			<LexicalComposer initialConfig={initialConfig}>
				<EditorContextProvider setEditorInstance={this.setEditorInstance} />
				<div className="dh-lexical__editor-container">
					<ToolbarPlugin />
					<div className={`dh-lexical__compose ${disabled ? "dh-lexical--disabled" : ""}`}>
						<RichTextPlugin
							contentEditable={<ContentEditable style={{ outline: "none" }} className="dh-lexical__content-editable" />}
							placeholder={<div className="dh-lexical__placeholder">{placeholder}</div>}
							ErrorBoundary={LexicalErrorBoundary}
							className="dh-lexical__input"
						/>
						<HistoryPlugin />
						<AutoFocusPlugin />
						<ListPlugin />
						<ClearEditorPlugin />
						<OnChangePlugin onChange={this.handleEditorStateChange} />
						<PressEnterPlugin onEnter={this.handleEnterKey} />
						{/* <TreeViewPlugin /> */}
					</div>
				</div>
			</LexicalComposer>
		);
	}
}

export default DHLexical;

import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Page from "../../../components/common/Page";
import Action from "../../../components/common/Action";
import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";
import A2PService from "../../../services/A2PService";

import { A2P_WEBHOOK_EVENT_COLUMNS } from "../../../constants/A2P";

class A2PWebhooks extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			webhookEvents: [],
			loading: false,

			showWebhookMetadataPreviewModal: false,
			webhookMetadataPreview: ""
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	onLocationChanged = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			webhookEvents: [],
			loading: false,

			showWebhookMetadataPreviewModal: false,
			webhookMetadataPreview: ""
		});

		await this.fetchWebhookEvents();
	};

	fetchWebhookEvents = async () => {
		let companyId = UserService.getActiveCompany().id;

		await this.update({ loading: true });

		let webhookEvents = await A2PService.fetchWebhookEvents({ companyId });

		await this.update({ webhookEvents, loading: false });
	};

	getHeaders() {
		let headers = {
			items: A2P_WEBHOOK_EVENT_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		let { t } = this.props;

		if (!recordData) {
			return null;
		}

		let eventData = {};

		// Try to full out a description preview
		if (recordData.event_data) {
			try {
				eventData = JSON.parse(recordData.event_data);
			} catch (error) {
				console.log(error);
			}
		}

		let description = eventData.description || "";

		return [
			recordData.id,
			recordData.company_id,
			recordData.location_id,
			recordData.tcr_brand_id,
			recordData.tcr_campaign_id,
			recordData.event_type,
			description,
			moment(recordData.created_at).format("MMM Do YYYY hh:mm a"),
			<div className="dh-list__actions">
				<Action id={`view-${recordData.id}`} label={t("View")} icon={Icon.Eye} onClick={() => this.onViewA2PWebhookMetaData(eventData)} />
			</div>
		];
	};

	onViewA2PWebhookMetaData = async eventData => {
		await this.update({
			showWebhookMetadataPreviewModal: true,
			webhookMetadataPreview: JSON.stringify(eventData, null, 2)
		});
	};

	onCloseMetaDataModal = async () => {
		await this.update({
			showWebhookMetadataPreviewModal: false,
			webhookMetadataPreview: ""
		});
	};

	render() {
		let { webhookEvents, loading, showWebhookMetadataPreviewModal, webhookMetadataPreview } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<List
					items={webhookEvents}
					loading={loading}
					loadedAll={true}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No webhook events found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<Alert type="info" show={showWebhookMetadataPreviewModal} title={t("Event Data")} confirm={t("Okay")} onClose={this.onCloseMetaDataModal}>
					<pre>{webhookMetadataPreview}</pre>
				</Alert>
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(A2PWebhooks)));

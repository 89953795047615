import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as Icon from "react-feather";

import PreferenceService from "../../../services/PreferenceService";
import UtilityService from "../../../services/UtilityService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import ToastService from "../../../services/ToastService";
import GAService from "../../../services/GAService";

import StatusLights from "../../../components/common/StatusLights";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Action from "../../../components/common/Action";
import SearchInput from "../../../components/common/SearchInput";
import Alert from "../../../components/common/Alert";
import LaggingLocationDetailsModal from "../../../components/common/LaggingLocationDetailsModal";

import { LAGGING_LOCATIONS_COLUMNS, LAGGING_LOCATIONS_FILTERS } from "../../../constants/LaggingLocationsConstants";
import { SORT_ORDER } from "../../../constants/CommonConstants";

import "../../../styles/css/components/commons/react-table.css";
import "../../../styles/css/scenes/lagging-locations.css";
import Filters from "../../../components/common/Filters";

class LaggingLocations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			loadedAll: true,
			laggingLocationsData: [],
			cacheTime: null,
			searchTerm: "",
			sortField: LAGGING_LOCATIONS_COLUMNS.company_name.sortField,
			sortOrder: SORT_ORDER.asc,
			limit: 1000,
			pageSize: 50,
			// Filter states
			selectedFavouritesFilter: PreferenceService.get("show_favorites") ? LAGGING_LOCATIONS_FILTERS.favourites.id : LAGGING_LOCATIONS_FILTERS.all.id,
			// Show details modal
			showDetailsModal: false,
			selectedLocationId: 0,
			selectedLocationName: "",
			// Last review invite state counts
			safeCount: 0,
			warningCount: 0,
			dangerCount: 0,
			showRefreshWarning: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchLaggingLocations();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async fetchLaggingLocations(clearCache = false) {
		await this.update({ loading: true, laggingLocationsData: [], cacheTime: null });
		try {
			let { selectedFavouritesFilter, searchTerm, sortField, sortOrder, limit } = this.state;

			let laggingLocationsData = await AnalyticsService.fetchCSLaggingLocations({
				favoritesOnly: selectedFavouritesFilter === LAGGING_LOCATIONS_FILTERS.favourites.id,
				searchTerm,
				sortField,
				sortOrder,
				limit,
				clearCache
			});

			if (!laggingLocationsData) {
				ToastService.info(`No data found. Please try again.`);
				throw new Error("No data found");
			}

			let laggingLocations = laggingLocationsData.laggingLocations;

			let safeCount = 0;
			let warningCount = 0;
			let dangerCount = 0;

			for (const data of laggingLocations) {
				if (
					(typeof data.last_review_request === "undefined" || data.last_review_request === -1) &&
					(typeof data.last_auto_review_request === "undefined" || data.last_auto_review_request === -1)
				) {
					continue;
				}

				// By default, a location should be considered as 'lagging'
				let days = -1;

				if (data.last_review_request !== null && data.last_review_request !== -1) {
					const { days: count } = UtilityService.getTimeDifference(data.last_review_request);
					days = count;
				}

				if (data.last_auto_review_request !== null && data.last_auto_review_request !== -1) {
					const { days: autoDays } = UtilityService.getTimeDifference(data.last_auto_review_request);
					if (days === -1 || autoDays < days) {
						days = autoDays;
					}
				}

				if (days >= 0 && days < 4) {
					safeCount++;
				} else if (days >= 4 && days <= 6) {
					warningCount++;
				} else if (days > 6) {
					dangerCount++;
				}
			}

			await this.update({ laggingLocationsData: laggingLocations, cacheTime: laggingLocationsData.cacheTime, safeCount, warningCount, dangerCount });
		} catch (error) {
			console.log(error);
		}
		await this.update({ loading: false });
	}

	handleGeneralSearchOnChange = async value => {
		const searchTerm = value;
		const filteredLaggingLocationsData = [{ id: "all", value: searchTerm }];

		// NOTE: this completely clears any COLUMN filters
		await this.update({ searchTerm, filteredLaggingLocationsData });
		await this.fetchLaggingLocations();
	};

	async getLaggingLocationDetails(id, name) {
		this.setState({
			selectedLocationId: id,
			selectedLocationName: name,
			showDetailsModal: true
		});
	}

	closeDetailsModal = () => {
		this.setState({
			showDetailsModal: false
		});
	};

	onFilterSelected = async item => {
		await this.update({ selectedFavouritesFilter: item.id });
		PreferenceService.set("show_favorites", item.id === LAGGING_LOCATIONS_FILTERS.all.id ? false : true);
		await this.fetchLaggingLocations();
	};

	isFilterSelected = item => {
		return item === this.state.selectedFavouritesFilter;
	};

	getFilters() {
		let favouritesFilters = Object.keys(LAGGING_LOCATIONS_FILTERS)
			.map(item => {
				return { id: item, value: LAGGING_LOCATIONS_FILTERS[item].display, order: LAGGING_LOCATIONS_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			filters: {
				title: "Show",
				items: favouritesFilters,
				onClick: this.onFilterSelected,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			loading: true,
			limit: limit + pageSize
		});

		await this.fetchLaggingLocations();
		await this.update({ loading: false });
	};

	onRecordClicked = async item => {
		// nothing for now
	};

	getHeaders = () => {
		let headers = {
			items: LAGGING_LOCATIONS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchLaggingLocations();
		await this.update({ loading: false });
	};

	renderRecord = recordData => {
		if (!recordData) {
			return null;
		}

		let record = [
			recordData.company_name,
			recordData.name,
			this.renderLastReviewRequestData(recordData, "last_review_request"),
			this.renderLastReviewRequestData(recordData, "last_auto_review_request"),
			this.renderOldestUnreadDate(recordData),
			this.renderLastMessageDate(recordData),
			this.renderWebchatStatusColumn(recordData),
			<div>
				<Action
					id={`More-${recordData.id}`}
					label="See more details"
					onClick={() => this.getLaggingLocationDetails(recordData.id, recordData.name)}
					icon={Icon.MoreHorizontal}
				/>
			</div>
		];

		return record;
	};

	renderLastReviewRequestData(recordData, field) {
		if (typeof recordData[field] === "undefined" || recordData[field] === -1) {
			return <span className="DHReactTable__row">N/A</span>;
		}

		const { days, hours, minutes } = UtilityService.getTimeDifference(recordData[field]);

		let value = "";
		if (days === 0) {
			value = (
				<StatusLights size={17} status="safe">
					<span style={{ paddingLeft: "22px" }}>
						{hours}h {minutes}m
					</span>
				</StatusLights>
			);
		}
		if (days > 0 && days < 4) {
			value = (
				<StatusLights size={17} status="safe">
					<span style={{ paddingLeft: "22px" }}>{days} days</span>
				</StatusLights>
			);
		} else if (days >= 4 && days <= 6) {
			value = (
				<StatusLights size={17} status="warning">
					<span style={{ paddingLeft: "22px" }}>{days} days</span>
				</StatusLights>
			);
		} else if (days > 6) {
			value = (
				<StatusLights size={17} status="danger">
					<span style={{ paddingLeft: "22px" }}>{days} days</span>
				</StatusLights>
			);
		}

		return (
			<div className="dh-tip" tip={moment(recordData[field]).format("YYYY-MM-DD hh:mm A")}>
				{value}
			</div>
		);
	}

	renderOldestUnreadDate(recordData) {
		if (typeof recordData.oldest_unread_date === "undefined" || recordData.oldest_unread_date === -1) {
			return null;
		}

		const { days, hours, minutes } = UtilityService.getTimeDifference(recordData.oldest_unread_date);

		let value = null;
		if (days === 0) {
			value = (
				<StatusLights size={17} status="safe">
					<span style={{ paddingLeft: "22px" }}>
						{hours}h {minutes}m
					</span>
				</StatusLights>
			);
		} else if (days === 1) {
			value = (
				<StatusLights size={17} status="safe">
					<span style={{ paddingLeft: "22px" }}>{days} day</span>
				</StatusLights>
			);
		} else if (days > 1 && days <= 2) {
			value = (
				<StatusLights size={17} status="warning">
					<span style={{ paddingLeft: "22px" }}>{days} days</span>
				</StatusLights>
			);
		} else if (days >= 3) {
			value = (
				<StatusLights size={17} status="danger">
					<span style={{ paddingLeft: "22px" }}>{days} days</span>
				</StatusLights>
			);
		}

		return (
			<div className="dh-tip" tip={moment(recordData.oldest_unread_date).format("YYYY-MM-DD hh:mm A")}>
				{value}
			</div>
		);
	}

	renderLastMessageDate(recordData) {
		if (typeof recordData.last_message_date === "undefined" || recordData.last_message_date === -1 || recordData.last_message_date === null) {
			return <span className="DHReactTable__row">N/A</span>;
		}
		const { days, hours, minutes } = UtilityService.getTimeDifference(recordData.last_message_date);
		let value = "";
		if (days === 0) {
			value = (
				<span>
					{hours}h {minutes}m
				</span>
			);
		} else {
			value = <span>{days} days</span>;
		}
		return (
			<div className="dh-tip" tip={moment(recordData.last_message_date).format("YYYY-MM-DD hh:mm A")}>
				{value}
			</div>
		);
	}

	renderWebchatStatusColumn(recordData) {
		if (recordData.webchat_status === "live") {
			return (
				<div className="dh-tip" tip="Live">
					<Icon.Check data-tip data-for="webchat-status-tooltip" size="30" color="#1ab394" />
				</div>
			);
		}

		if (recordData.webchat_status === "failed") {
			return (
				<div className="dh-tip" tip="Failed">
					<Icon.X data-tip data-for="webchat-status-tooltip" size="30" color="#dc3545" />
				</div>
			);
		}

		return (
			<div className="dh-tip" tip="Inactive">
				<Icon.Minus data-tip data-for="webchat-status-tooltip" size="30" color="gray" />
			</div>
		);
	}

	render() {
		const { laggingLocationsData, cacheTime, loading, loadedAll, sortField, sortOrder, safeCount, warningCount, dangerCount, showRefreshWarning } = this.state;

		return (
			<Page>
				<Header title="Lagging Locations">
					<Action id="refresh" label={`Refresh Data`} onClick={() => this.setState({ showRefreshWarning: true })} icon={Icon.RefreshCcw} />
				</Header>

				<div className="companies__search">
					<SearchInput placeholder="Search ..." onChange={this.handleGeneralSearchOnChange} />
				</div>

				<Filters filters={this.getFilters()} />

				{laggingLocationsData && (
					<div className="lagging-locations__last-review-request">
						<div>Last Ran At: {cacheTime ? `(${moment(cacheTime).format("MMM D - h:mm a")})` : null}</div>
						<h4>Last Review Request State Counts</h4>
						<div className="lagging-locations__last-review-request__counts">
							<div className="lagging-locations__last-review-request__counts__count">
								<StatusLights size={17} status="safe" />
								{safeCount}
							</div>
							<div className="lagging-locations__last-review-request__counts__count">
								<StatusLights size={17} status="warning" />
								{warningCount}
							</div>
							<div className="lagging-locations__last-review-request__counts__count">
								<StatusLights size={17} status="danger" />
								{dangerCount}
							</div>
						</div>
					</div>
				)}

				<List
					items={laggingLocationsData}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle="Locations not found"
					noDataIcon={<Icon.AlertCircle />}
				/>

				<Alert
					type="Info"
					show={showRefreshWarning}
					title="Warning"
					cancel="Cancel"
					confirm="Refresh"
					onClose={confirmed => {
						this.setState({ showRefreshWarning: false });
						if (confirmed) {
							this.fetchLaggingLocations(true);
						}
					}}
				>
					<div>This will refresh the data and clear any cached data. This is a heavier query. Would you like to proceed?</div>
				</Alert>
				<LaggingLocationDetailsModal
					showModal={this.state.showDetailsModal}
					locationId={this.state.selectedLocationId}
					locationName={this.state.selectedLocationName}
					close={this.closeDetailsModal}
				/>
			</Page>
		);
	}
}

export default withRouter(LaggingLocations);

import React, { Component } from "react";
import * as Icon from "react-feather";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import { withTranslation } from "react-i18next";

import "../../styles/css/components/commons/showcase-list.css";
import Action from "./Action";

import UtilityService from "../../services/UtilityService";

/*
	This is what it looks like lol

	--------------------------------
	| Title (Subtitle)             |
	| Description.....      ___    |
	| ...............      |_+_|   |
	|                              |
	|                              |
	| Footer                       |
	--------------------------------
	subtitle, title, description, footer
*/

const ShowcaseRow = ({
	/* Already exist on the row*/
	id,
	title,
	titleToTitleCase = true,
	selectedTitle,
	disableTitles,
	subtitle,
	description,
	footer,
	/* Given from parent ShowcaseList */
	label,
	orderable,
	onEditClicked,
	onDeleteClicked,
	onItemClicked,
	allowUpdate,
	/* Misc things needed to make this work */
	rowIndex,
	/* Translation */
	t
}) => {
	let artificialProps = {
		label,
		orderable,
		onEditClicked,
		onDeleteClicked,
		onItemClicked,
		allowUpdate,
		id,
		title,
		titleToTitleCase,
		selectedTitle,
		disableTitles,
		subtitle,
		description,
		footer,
		t
	};

	const allowOrderToChange = allowUpdate && orderable;

	// A <ShowcaseRow> is really just a SortableItem
	return <SortableItem key={`item-${rowIndex}`} props={artificialProps} rowIndex={rowIndex} disabled={!allowOrderToChange} />;
};

const SortableGrid = SortableContainer(({ props }) => {
	let { children } = props;
	let { label, orderable, onEditClicked, onDeleteClicked, onItemClicked, allowUpdate } = props;

	// Copy all the <ShowcaseList> props to all the <ShowcaseRow> elements
	const childrenWithProps = React.Children.map(children, (child, index) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				t: props.t,
				label,
				orderable,
				allowUpdate,
				onEditClicked,
				onDeleteClicked,
				onItemClicked
			});
		}
		return child;
	});

	return <div>{childrenWithProps}</div>;
});

// Note: rowIndex is a neccesary evil because index is consumed by the component
const SortableItem = SortableElement(({ props, rowIndex }) => {
	let { allowUpdate } = props;
	let { t } = props;
	let { id, label, orderable } = props;
	let { onEditClicked, onDeleteClicked, onItemClicked } = props;
	let { title, titleToTitleCase, selectedTitle, disableTitles, subtitle, description, footer } = props;

	let isSelected = title === selectedTitle;
	let disableActions = disableTitles && disableTitles.includes(title) ? true : false;

	return (
		<div
			id={id}
			key={`option-field-${label}-${rowIndex}`}
			className={`scl__options__field ${isSelected ? "scl__options__field--selected" : ""} ${orderable ? "scl__options__field--orderable" : ""}`}
			onClick={() => (onItemClicked ? onItemClicked(rowIndex, title) : undefined)}
		>
			<div className="scl__options__field__data">
				{title && (
					<div className="scl__options__field__data__title">
						{orderable ? `${rowIndex + 1}.` : ``} {titleToTitleCase ? UtilityService.toTitleCase(title) : title}
					</div>
				)}
				{subtitle && <div className="scl__options__field__data__subtitle">{subtitle}</div>}
				{description && <div className="scl__options__field__data__description">{description}</div>}
				{footer && <div className="scl__options__field__data__footer">{footer}</div>}
			</div>
			{!disableActions && (
				<div className="scl__options__field__actions">
					{onEditClicked && allowUpdate && (
						<Action
							id={`${label}-${rowIndex}-edit`}
							label={`${t("Edit")} ${label}`}
							icon={Icon.Edit3}
							onClick={() => {
								onEditClicked(rowIndex, title);
							}}
						/>
					)}
					{onDeleteClicked && allowUpdate && (
						<Action
							id={`${label}-${rowIndex}-delete`}
							label={`${t("Delete")} ${label}`}
							icon={Icon.Trash2}
							onClick={clickEvent => {
								onDeleteClicked(rowIndex, clickEvent.event, title);
							}}
						/>
					)}
				</div>
			)}
		</div>
	);
});

class ShowcaseList extends Component {
	onSortEnd({ oldIndex, newIndex }) {
		if (oldIndex !== newIndex && this.props.orderable && this.props.onOrderChanged) {
			this.props.onOrderChanged(oldIndex, newIndex);
		}
	}

	shouldCancelStart = e => {
		// Don't enable drag and drop if we're an input, or a DH Action which can be a svg or path
		if (["input", "textarea", "select", "option", "svg", "path"].indexOf(e.target.tagName.toLowerCase()) !== -1) {
			return true;
		} else if (e.target.classList.contains("dh-action") || e.target.classList.contains("dh-tip")) {
			return true;
		}
	};

	render() {
		let { title, onAddClicked, children, allowUpdate, t, noBorder } = this.props;

		const childrenWithProps = React.Children.map(children, child => {
			if (React.isValidElement(child)) {
				return true;
			}
		});

		if (!childrenWithProps || childrenWithProps.length === 0) {
			return (
				<div className={`scl ${noBorder ? "scl--no-border" : ""}`}>
					<div className="scl__header">
						<div className="scl__header__title">{title}</div>
						{onAddClicked && allowUpdate && <Action id="createShowcase" label={t("Create")} icon={Icon.Plus} onClick={onAddClicked} />}
					</div>
				</div>
			);
		}

		// A <ShowcaseList> is really just a <SortableGrid>
		return (
			<div className={`scl ${noBorder ? "scl--no-border" : ""}`}>
				<div className="scl__header">
					<div className="scl__header__title">{title}</div>
					{onAddClicked && allowUpdate && <Action id="createShowcase" label={t("Create")} icon={Icon.Plus} onClick={onAddClicked} />}
				</div>
				<div className="scl__body">
					<div className="scl__body__options">
						<SortableGrid
							props={{ ...this.props, t }}
							onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd({ oldIndex, newIndex })}
							shouldCancelStart={this.shouldCancelStart}
							transitionDuration={300}
							lockAxis="y"
						/>
					</div>
				</div>
			</div>
		);
	}
}

const TranslatedShowcaseList = withTranslation(null, { withRef: true })(ShowcaseList);
export { TranslatedShowcaseList as ShowcaseList, ShowcaseRow };

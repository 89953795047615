import React, { Component } from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";

import List from "../../../../components/common/List";
import withLocation from "../../../../components/common/WithLocation";

import UserService from "../../../../services/UserService";
import WorkflowService from "../../../../services/WorkflowService";
import ToastService from "../../../../services/ToastService";

import { SORT_ORDER } from "../../../../constants/CommonConstants";
import { WORKFLOW_RUNS_COLUMNS, WORKFLOW_RUN_STATUSES } from "../../../../constants/Workflows";

class WorkflowRuns extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			loadedAll: true,
			data: [],
			sortField: WORKFLOW_RUNS_COLUMNS.updated_at.id,
			sortOrder: SORT_ORDER.desc,
			limitDefault: 50,
			limit: 50,
			pageSize: 50,

			workflowsConfig: null
		};
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({ loading: true });
		await this.fetchWorkflowsConfig();
		await this.fetchData();
		await this.update({ loading: false });
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = () => {
		this.resetComponent();
	};

	fetchData = async () => {
		let { limit, sortField, sortOrder } = this.state;

		await this.update({ loading: true });

		const locationId = UserService.getActiveLocation().id;

		const actionStatuses = [WORKFLOW_RUN_STATUSES.errored, WORKFLOW_RUN_STATUSES.performed];

		let data = await WorkflowService.fetchWorkflowRuns({
			locationId,
			actionStatuses,
			sortField,
			sortOrder,
			limit
		});

		await this.update({
			data,
			loading: false,
			loadedAll: data && data.length < limit
		});
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	fetchWorkflowsConfig = async () => {
		let { t } = this.props;

		let workflowsConfig = await WorkflowService.getCachedWorkflowsConfig();

		if (!workflowsConfig) {
			ToastService.error(t("An error occurred trying to get workflow configuration data."));
			return;
		}

		await this.update({
			workflowsConfig
		});
	};

	getHeaders = () => {
		let headers = {
			items: WORKFLOW_RUNS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	renderRecord = recordData => {
		let { workflowsConfig } = this.state;

		let runID = recordData.id;
		let triggerData = recordData.trigger_data;
		let actionStatus = recordData.action_status;
		let actionResultMessage = recordData.action_result_message;
		let updatedAt = recordData.updated_at ? moment(recordData.updated_at).format("MMM Do, h:mm A") : "";

		let workflowID = recordData.workflow_id;
		let workflowName = recordData.Workflow.name;
		let workflowTrigger = recordData.Workflow.trigger_type;
		let workFlowAction = recordData.Workflow.action_type;

		let workflowTriggerFriendlyName = workflowsConfig.triggerInfo[workflowTrigger].name;
		let workflowActionFriendlyName = workflowsConfig.actionInfo[workFlowAction].name;

		return [
			runID,
			workflowID,
			workflowName,
			workflowTriggerFriendlyName,
			workflowActionFriendlyName,
			triggerData,
			actionStatus,
			actionResultMessage,
			updatedAt
		];
	};

	render() {
		let { data, loading, loadedAll, sortField, sortOrder } = this.state;
		let { t } = this.props;

		return (
			<List
				items={data}
				loading={loading}
				loadedAll={loadedAll}
				sortField={sortField}
				sortOrder={sortOrder}
				headers={this.getHeaders()}
				renderRecord={this.renderRecord}
				onRecordClicked={() => {}}
				onLoadMore={this.onLoadMore}
				noDataTitle={t("Workflow runs not found...")}
				noDataIcon={<Icon.AlertCircle />}
			/>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(WorkflowRuns)));

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactSwitch from "react-switch";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import ToastService from "../../services/ToastService";
import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";
import { LOCATION_FEATURES } from "../../constants/LocationConstants";
import GROUP_PERMISSIONS from "../../constants/GroupPermissions";
import NotificationService from "../../services/NotificationService";
import { GAPageView } from "../../services/GAService";

import Spinners from "../../components/common/Spinners";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import GAService from "../../services/GAService";
import UnsavedChanges from "../../components/common/UnsavedChanges";
import withLocation from "../../components/common/WithLocation";
import Alert from "../../components/common/Alert";

import "../../styles/css/scenes/location-features.css";

class LocationFeatures extends Component {
	constructor(props) {
		super(props);

		this.state = {
			locationName: null,
			locationError: false,
			locationFeatures: null,
			featureGroups: null,
			showLocationFeatures: [],
			featuresConfig: {},
			showWarningAlert: false,
			conflictingFeatures: [],
			requiredFeatures: [],
			changesObject: {},
			loading: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchLocation();
	}

	onLocationChanged = () => {
		this.fetchLocation();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async fetchLocation() {
		try {
			this.update({ loading: true });
			let locationId = UserService.getActiveLocation().id;

			let location = await LocationService.fetchLocation(locationId);
			if (!location) {
				this.update({ locationError: true, loading: true });
				return;
			}
			let response = await LocationService.getLocationFeatures(locationId);

			if (!response) {
				this.update({ locationName: location.name, locationFeatures: null, showLocationFeatures: [], loading: false });
				return;
			}

			let config = await LocationService.getLocationFeaturesConfig();
			let featureGroups = Object.values(config.featureGroups);

			// Turn the location features config into an array and sort them
			let showLocationFeatures = Object.values(config.features)
				.sort((a, b) => {
					let fa = a.name.toLowerCase(),
						fb = b.name.toLowerCase();
					return fa < fb ? -1 : fa > fb ? 1 : 0;
				})
				.map(f => f);

			await this.update({
				locationName: location.name,
				locationFeatures: response.locationFeature,
				showLocationFeatures,
				featuresConfig: config.features,
				loading: false,
				changesObject: {},
				featureGroups
			});
		} catch (error) {
			console.log(error);
			this.update({ locationError: true, loading: false });
		}
	}

	handleChangeField(field, value) {
		let changesObject = { ...this.state.changesObject };
		let { featuresConfig } = this.state;
		let location = UserService.getActiveLocation();
		let currentLocationFeatures = location.LocationFeature;

		let featureConfig = featuresConfig[field];

		if (value && featureConfig) {
			// Only start an alert if there are any conflicting features that are enabled or required features that are not yet enabled
			let conflictingFeatures = featureConfig.conflicts && featureConfig.conflicts.length > 0 ? featureConfig.conflicts : [];
			let requiresFeatures = featureConfig.requires && featureConfig.requires.length > 0 ? featureConfig.requires : [];

			/**
			 * Ensure we are making the right changes. eg if voicmail and transcription are on, when turning on call forwarding => voicemail features should be turned off
			 */
			if (conflictingFeatures && Object.keys(conflictingFeatures).length > 0) {
				for (let i = 0; i < conflictingFeatures.length; i++) {
					const conflict = conflictingFeatures[i];
					if ((changesObject && changesObject[conflict]) || (currentLocationFeatures && currentLocationFeatures[conflict])) {
						changesObject[conflict] = false;
					}
				}
			}
			if (requiresFeatures && Object.keys(requiresFeatures).length > 0) {
				for (let i = 0; i < requiresFeatures.length; i++) {
					const required = requiresFeatures[i];
					changesObject[required] = true;
				}
			}
		} else if (!value && featureConfig) {
			let dependantFeatures = this.getAllDependantFeatures(field);

			for (let i = 0; i < dependantFeatures.length; i++) {
				const dependant = dependantFeatures[i];
				if ((changesObject && changesObject[dependant]) || (currentLocationFeatures && currentLocationFeatures[dependant])) {
					changesObject[dependant] = false;
				}
			}
		}

		changesObject[field] = value;

		this.update({ changesObject });
	}

	getAllDependantFeatures(field) {
		let { featuresConfig } = this.state;

		let dependantFeatures = [];
		let features = Object.keys(featuresConfig);

		for (let i = 0; i < features.length; i++) {
			const feature = features[i];
			if (
				featuresConfig[feature] &&
				featuresConfig[feature].requires &&
				featuresConfig[feature].requires.includes(field) &&
				!dependantFeatures.includes(field)
			) {
				dependantFeatures.push(feature);
			}
		}

		return dependantFeatures;
	}

	handleOnSaveLocation = async () => {
		let { changesObject } = this.state;

		let locationId = UserService.getActiveLocation().id;
		let response = await LocationService.updateLocationFeature(locationId, changesObject);
		if (!response) {
			ToastService.error("Error saving features. Please try again.");
			return;
		}

		let location = UserService.getActiveLocation();

		Object.assign(location.LocationFeature, changesObject);

		await this.update({ changesObject: {} });

		ToastService.info("Saved feature settings.");

		UserService.setActiveLocation(location);

		// Change this
		NotificationService.publish("locationChanged", UserService.getActiveLocation());
	};

	onCancelChanges = async () => {
		await this.update({ changesObject: {} });
	};

	async handleOnCreate() {
		let locationId = UserService.getActiveLocation().id;
		let response = await LocationService.updateLocationFeature(locationId, {});
		if (!response) {
			ToastService.error("Error creating features. Please try again.");
			return;
		}
		ToastService.info("Features created.");
		this.fetchLocation();
	}

	renderCreateLocationFeatures() {
		return (
			<div>
				<h2 className="text-center">Location Features Not Found</h2>
				<div className="text-center">
					<button className="btn btn-primary" onClick={() => this.handleOnCreate()}>
						Create
					</button>
				</div>
			</div>
		);
	}

	isFeatureDisabled(feature) {
		let user = UserService.get();

		// XXX - Should probably remove.
		if (feature.id === LOCATION_FEATURES.reviews_auto_reminders.id) {
			return true;
		}

		if (UserService.isSuperAdminOrCustomerSuccessOrReseller()) {
			return false;
		}

		return !user.GroupPermission.update_locations || feature.readOnly;
	}

	renderFeatureGroup(group) {
		const { locationFeatures, changesObject, showLocationFeatures, showWarningAlert, conflictingFeatures, requiredFeatures } = this.state;

		if (!locationFeatures || !showLocationFeatures || showLocationFeatures.length < 1) {
			return <div className="location-features-list-header">{group.name}</div>;
		}

		return (
			<>
				<div className="location-features-list-header">{group.name}</div>
				<div className="location-features-list">
					{showLocationFeatures.map(feature => {
						let field = feature.id;
						if (feature.featureGroup !== group.id) {
							return null;
						}
						let hasChanged = changesObject && typeof changesObject[field] !== "undefined";
						let featureName = LOCATION_FEATURES[field] ? LOCATION_FEATURES[field].name : field;
						return (
							typeof locationFeatures[field] !== "undefined" && (
								<div key={field} className={`location-features-item ${hasChanged ? "location-features-item--changed" : ""}`}>
									<div>
										{featureName}
										<Icon.Info className="location-features-item__icon" size={14} data-tip data-for={`${field}-description-rtt`} />
										<ReactTooltip id={`${field}-description-rtt`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
											{feature.description && (
												<>
													<div>{feature.description}</div>
													<hr />
												</>
											)}
											{feature.requires && (
												<>
													Requires: {feature.requires.map(req => (LOCATION_FEATURES[req] ? LOCATION_FEATURES[req].name : req)).join(", ") || []} <hr />
												</>
											)}
											{feature.conflicts && (
												<>Conflicts: {feature.conflicts.map(con => (LOCATION_FEATURES[con] ? LOCATION_FEATURES[con].name : con)).join(", ") || []}</>
											)}
										</ReactTooltip>
									</div>
									<div>
										<ReactSwitch
											id={field}
											name={field}
											type="text"
											onChange={value => this.handleChangeField(field, value)}
											checked={typeof changesObject[field] !== "undefined" ? changesObject[field] : locationFeatures[field]}
											onColor="#60A9FF"
											disabled={this.isFeatureDisabled(feature)}
										/>
									</div>
								</div>
							)
						);
					})}
				</div>
				<Alert type="warning" show={showWarningAlert} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onConfirmContactDeleteClose}>
					{conflictingFeatures && conflictingFeatures.length > 0 ? { conflictingFeatures } : null}
					{requiredFeatures && requiredFeatures.length > 0 ? { requiredFeatures } : null}
				</Alert>
			</>
		);
	}

	renderFeatures() {
		const { changesObject, featureGroups } = this.state;

		let unsavedChanges = changesObject && Object.keys(changesObject).length > 0;
		return (
			<div>
				{featureGroups && featureGroups.length > 0 && featureGroups.map(fg => this.renderFeatureGroup(fg))}
				{unsavedChanges && (
					<UnsavedChanges>
						<div className="unsaved-changes__buttons">
							<div id="save-location-features" className="mb-button" onClick={this.handleOnSaveLocation}>
								Save
							</div>
							<div className="mb-button mb-button--cancel" onClick={this.onCancelChanges}>
								Cancel
							</div>
						</div>
					</UnsavedChanges>
				)}
			</div>
		);
	}

	render() {
		let { locationFeatures, locationError, loading } = this.state;

		const user = UserService.get();
		if (
			!(
				user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type ||
				user.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type ||
				GROUP_PERMISSIONS.restricted_customer_success.type
			)
		) {
			return <Redirect to="/settings/locations" />;
		}

		return (
			<Page>
				<Header title="Features" />
				{locationError && <div className="location-features-container">Error. Unable to get location</div>}

				{!locationError && (
					<div className="location-features-container">
						{loading && (
							<div className="Common__spinnerdiv--center">
								<Spinners loading={true} type="tail-fade" size="120px" />
							</div>
						)}
						{!loading && <>{locationFeatures ? <div>{this.renderFeatures()}</div> : <div>{this.renderCreateLocationFeatures()}</div>}</>}
					</div>
				)}
			</Page>
		);
	}
}

export default withLocation(LocationFeatures);

import Kichiri from "./KichiriService";
import UserService from "./UserService";
import LocationService from "./LocationService";
import UtilityService from "./UtilityService";

import { LOCATION_FEATURES } from "../constants/LocationConstants";

let workflowConstant = null;
const WorkflowService = {
	/**
	 * Fetch workflows
	 * @param {Number} locationId
	 * @param {Array/String} status
	 * @param {Array/String} triggerTypes
	 * @param {Array/String} conditions
	 * @param {String} feature
	 * @param {Number} actionInboxId
	 * @param {String} sortField
	 * @param {String} sortOrder
	 *
	 */
	async fetchWorkflows({ locationId, status, triggerTypes, conditions, feature, actionInboxId, sortField, sortOrder }) {
		try {
			let response = await Kichiri.workflow.fetchWorkflows(
				{},
				{
					location_id: locationId,
					status,
					triggerTypes,
					conditions,
					feature,
					actionInboxId,
					sortField,
					sortOrder
				},
				UserService.getAuthToken()
			);

			// Since there is json data stored as strings, we process them into objects here
			response.data = response.data.map(w => {
				try {
					// Convert the conditions to an object
					w.conditions = JSON.parse(w.conditions);

					// Convert the action configuration to an object
					if (w.action_config) {
						w.action_config = JSON.parse(w.action_config);
					}
				} catch (error) {
					console.log(error);
				}
				return w;
			});

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a signle workflow
	 * @param {Number} workflowId
	 * @param {Boolean} includeMessageTemplate If you want Message Template data
	 */
	async fetchWorkFlow(workflowId, includeMessageTemplate) {
		try {
			let query = {};

			if (includeMessageTemplate) {
				query.includeMessageTemplate = includeMessageTemplate;
			}

			let response = await Kichiri.workflow.fetchWorkflow(
				{
					workflowId
				},
				query,
				UserService.getAuthToken()
			);

			// Since there is json data stored as strings, we process them into objects here
			response.data.conditions = JSON.parse(response.data.conditions);
			if (response.data.action_config) {
				response.data.action_config = JSON.parse(response.data.action_config);
			}
			if (response.data.action_users) {
				response.data.action_users = JSON.parse(response.data.action_users);
			}

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Delete Workflow
	 * @param {Number} workflowId
	 * @returns true on success, null on failure
	 */
	async delete(workflowId) {
		try {
			await Kichiri.workflow.delete(
				{
					workflowId
				},
				{},
				UserService.getAuthToken()
			);
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch keyword workflows
	 * @param {Number} locationId
	 * @param {String} status
	 * @returns {Object} Workflow
	 */
	async fetchKeywords({ locationId, status }) {
		try {
			let response = await Kichiri.workflow.fetchKeywords(
				{},
				{
					locationId,
					status
				},
				UserService.getAuthToken()
			);

			response.data = response.data.map(w => {
				try {
					w.conditions = JSON.parse(w.conditions);
				} catch (error) {
					console.log(error);
				}
				return w;
			});

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Fetch a single keyword workflow
	 * @param {Number} locationId
	 * @returns {Object} Workflow
	 */
	async fetchKeyword({ workflowId }) {
		try {
			let response = await Kichiri.workflow.fetchKeyword({ workflowId }, {}, UserService.getAuthToken());

			response.data.conditions = JSON.parse(response.data.conditions);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a "keyword" workflow
	 * @param {Number} locationId
	 * @param {Array/Stirng} words
	 * @param {String} responseText
	 * @param {Array} mediaIds
	 * @returns true on success, null on failure
	 */
	async createKeyword({ locationId, words, responseText, mediaIds }) {
		try {
			await Kichiri.workflow.createKeyword(
				{
					locationId,
					words,
					response: responseText,
					mediaIds
				},
				{},
				UserService.getAuthToken()
			);
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a Keyword Workflow
	 * @param {Number} workflowId
	 * @param {Array/String} words
	 * @param {String} responseText
	 * @param {String} status
	 * @param {Array} mediaIds
	 * @returns true on success, null on failure
	 */
	async updateKeyword({ workflowId, words, responseText, status, mediaIds }) {
		try {
			await Kichiri.workflow.updateKeyword(
				{
					workflowId,
					words,
					response: responseText,
					status,
					mediaIds
				},
				{},
				UserService.getAuthToken()
			);
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Delete an Keyword Workflow
	 * @param {Number} workflowId
	 * @returns true on success, null on failure
	 */
	async deleteKeyword(workflowId) {
		try {
			await Kichiri.workflow.deleteKeyword(
				{
					workflowId
				},
				{},
				UserService.getAuthToken()
			);
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the cached workflow config
	 */
	async getCachedWorkflowsConfig() {
		if (!workflowConstant) {
			await this.updateCachedWorkflowsConfig();
		}
		return workflowConstant;
	},

	/**
	 * Update the cached workflow config
	 */
	async updateCachedWorkflowsConfig() {
		workflowConstant = await this.fetchWorkflowsConfig();
	},

	/**
	 * Get worlfow constants data
	 */
	async fetchWorkflowsConfig() {
		try {
			let response = await Kichiri.workflow.fetchWorkflowsConfig({}, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check if workflows is enabled for the user
	 * @returns {Boolean}
	 */
	isWorkflowsEnabled() {
		try {
			return LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.workflows.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if Keywords is enabled for this user
	 * @returns {Boolean}
	 */
	isKeywordsEnabled() {
		try {
			const user = UserService.get();
			return user.GroupPermission.view_keywords && this.isWorkflowsEnabled();
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if Auto Replies is enabled for this user
	 * @returns {Boolean}
	 */
	isAutoRepliesEnabled() {
		try {
			const user = UserService.get();
			return user.GroupPermission.view_auto_replies && this.isWorkflowsEnabled();
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if this user can view workflows
	 * @returns {Boolean}
	 */
	canViewWorkflows() {
		try {
			const user = UserService.get();
			return user.GroupPermission.view_workflows && this.isWorkflowsEnabled();
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Create a workflow
	 * @param {Number} locationId
	 * @param {String} name
	 * @param {Number} priority
	 * @param {String} trigger_type
	 * @param {Object} conditions
	 * @param {String} action_type
	 * @param {Number} action_template_id
	 * @param {Number} action_crm_id
	 * @param {Number} action_bot_id
	 * @param {Number} action_inbox_id
	 * @param {Number} action_relative_years
	 * @param {Number} action_relative_months
	 * @param {Number} action_relative_days
	 * @param {String} action_absolute_time
	 * @param {String} action_hours
	 * @param {String} action_minutes,
	 * @param {String} action_tag_id
	 * @param {String} action_group_id
	 * @param {String} action_conversation_id
	 * @param {Object} action_config
	 * @param {String} feature
	 * @param {String} status
	 * @param {Boolean} logging
	 *
	 * @returns Workflow on success
	 */
	async create({
		locationId,
		name,
		priority,
		trigger_type,
		conditions,
		action_type,
		action_template_id,
		action_crm_id,
		action_bot_id,
		action_inbox_id,
		action_relative_years,
		action_relative_months,
		action_relative_days,
		action_absolute_time,
		action_hours,
		action_minutes,
		action_tag_id,
		action_group_id,
		action_conversation_id,
		action_config,
		feature,
		status,
		logging = false
	}) {
		try {
			let params = {
				locationId,
				name,
				priority: typeof priority !== "undefined" && priority !== "" ? priority : 1,
				trigger_type,
				conditions: conditions ? JSON.stringify(conditions) : null,
				action_type,
				action_template_id,
				action_crm_id,
				action_bot_id,
				action_inbox_id,
				action_relative_years,
				action_relative_months,
				action_relative_days,
				action_absolute_time,
				action_hours,
				action_minutes,
				action_tag_id,
				action_group_id,
				action_conversation_id,
				action_config: action_config ? JSON.stringify(action_config) : null,
				feature,
				status
			};

			let result = await Kichiri.workflow.create(params, {}, UserService.getAuthToken());

			let workflow = result.data;
			if (workflow.conditions && workflow.conditions !== "") {
				workflow.conditions = JSON.parse(workflow.conditions);
			}

			if (workflow.action_config && workflow.action_config !== "") {
				workflow.action_config = JSON.parse(workflow.action_config);
			}

			return result.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a workflow
	 * @param {String} id
	 * @param {String} name
	 * @param {Number} priority
	 * @param {String} trigger_type
	 * @param {Object} conditions
	 * @param {String} action_type
	 * @param {Number} action_template_id
	 * @param {Number} action_crm_id
	 * @param {Number} action_bot_id
	 * @param {Number} action_inbox_id
	 * @param {Number} action_relative_years
	 * @param {Number} action_relative_months
	 * @param {Number} action_relative_days
	 * @param {String} action_absolute_time
	 * @param {String} action_hours
	 * @param {String} action_minutes
	 * @param {String} action_seconds
	 * @param {String} action_users
	 * @param {String} action_tag_id
	 * @param {String} action_group_id
	 * @param {String} action_conversation_id
	 * @param {Object} action_config
	 * @param {String} feature
	 * @param {String} status
	 * @param {Boolean} logging
	 *
	 * @returns Workflow on success
	 */
	async update({
		id: workflowId,
		name,
		priority,
		trigger_type,
		conditions,
		action_type,
		action_template_id,
		action_crm_id,
		action_bot_id,
		action_inbox_id,
		action_relative_years,
		action_relative_months,
		action_relative_days,
		action_absolute_time,
		action_hours,
		action_minutes,
		action_seconds,
		action_users,
		action_tag_id,
		action_group_id,
		action_conversation_id,
		action_config,
		feature,
		status,
		logging
	}) {
		try {
			let params = {
				workflowId,
				name,
				priority: typeof priority !== "undefined" && priority !== "" ? priority : undefined,
				trigger_type,
				conditions: conditions ? JSON.stringify(conditions) : null,
				action_type,
				action_template_id,
				action_crm_id,
				action_bot_id,
				action_inbox_id,
				action_relative_years,
				action_relative_months,
				action_relative_days,
				action_absolute_time,
				action_hours,
				action_minutes,
				action_seconds,
				action_users: action_users ? JSON.stringify(action_users) : null,
				action_tag_id,
				action_group_id,
				action_conversation_id,
				action_config: action_config ? JSON.stringify(action_config) : null,
				feature,
				status,
				logging
			};

			let result = await Kichiri.workflow.update(params, {}, UserService.getAuthToken());
			let workflow = result.data;

			if (workflow.conditions && workflow.conditions !== "") {
				workflow.conditions = JSON.parse(workflow.conditions);
			}

			if (workflow.action_config && workflow.action_config !== "") {
				workflow.action_config = JSON.parse(workflow.action_config);
			}

			return workflow;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch auto reply workflows
	 * @param {Number} locationId
	 * @param {String} locationId
	 * @returns
	 */
	async fetchAutoReplies({ locationId, status }) {
		try {
			let response = await Kichiri.workflow.fetchAutoReplies(
				{},
				{
					locationId,
					status
				},
				UserService.getAuthToken()
			);
			response.data = response.data.map(w => {
				try {
					w.conditions = JSON.parse(w.conditions);
				} catch (error) {
					console.log(error);
				}
				return w;
			});
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Create an auto response
	 * @param {Number} locationId
	 * @param {String} type is_after_hours, is_business_hours, is_custom_hours
	 * @param {String} name
	 * @param {String} response
	 * @param {Array/String} days
	 * @param {String} startTime "HH:mm" format
	 * @param {String} endTime "HH:mm" format
	 * @param {Array/Number} mediaIds An array of of media ids
	 * @returns {Object}
	 */
	async createCustomAutoReply({ locationId, type, name, response, days, startTime, endTime, mediaIds }) {
		try {
			let params = {
				locationId,
				type,
				name,
				response,
				days,
				startTime,
				endTime,
				mediaIds
			};

			let result = await Kichiri.workflow.createAutoReply(params, {}, UserService.getAuthToken());
			return result.data;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Update an auto response
	 * @param {Number} workflowId
	 * @param {String} name
	 * @param {String} response
	 * @param {Array/String} days
	 * @param {String} startTime "HH:mm" format
	 * @param {String} endTime "HH:mm" format
	 * @param {Array/Number} mediaIds An array of of media ids
	 * @returns {Object}
	 */
	async updateAutoReply({ workflowId, name, response, status, days, startTime, endTime, mediaIds }) {
		try {
			let params = {
				workflowId
			};

			if (response) {
				params.response = response;
			}

			if (status) {
				params.status = status;
			}

			if (name) {
				params.name = name;
			}

			if (days) {
				params.days = days;
			}

			if (startTime) {
				params.startTime = startTime;
			}

			if (endTime) {
				params.endTime = endTime;
			}

			if (mediaIds) {
				params.mediaIds = mediaIds;
			}

			await Kichiri.workflow.updateAutoReply(params, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Delete an Auto Reply Workflow
	 * @param {Number} workflowId
	 * @returns true on success, null on failure
	 */
	async deleteAutoReply(workflowId) {
		try {
			await Kichiri.workflow.deleteAutoReply(
				{
					workflowId
				},
				{},
				UserService.getAuthToken()
			);
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Properly format condition param values
	 * @param {Object} params
	 * @returns
	 */
	formatParamValues(params) {
		if (!params) {
			return params;
		}

		Object.keys(params).map(key => (params[key] = this.getParamValue(params[key])));

		return params;
	},

	/**
	 * Properly format a single param value
	 * @param {String/Object} value
	 * @returns
	 */
	getParamValue(value) {
		if (Array.isArray(value)) {
			return value;
		}

		// An object
		if (typeof value === "string" && value.indexOf("{") >= 0) {
			return JSON.parse(value);
		}

		// a number
		if (typeof value === "number" || (UtilityService.isStringNumeric(value) !== null && parseInt(value, 10))) {
			return parseInt(value, 10);
		}

		// a boolean
		if (
			typeof value === "boolean" ||
			(typeof value === "string" && (value.indexOf("'") < 0 || value.indexOf('"') < 0) && (value === "true" || value === "false"))
		) {
			return value === "true" || value === true ? true : false;
		}

		// an array in string format
		if (typeof value === "string" && (value.indexOf(",") >= 0 || value.indexOf("[") >= 0)) {
			return value
				.replace(/\[|\]|'|"/g, "")
				.split(",")
				.filter(item => item !== "")
				.map(item => item.trim());
		}

		return value;
	},

	/**
	 * Fetch all workflow runs for a location
	 *
	 * @param {Number} locationId
	 * @param {Array} actionStatuses
	 * @param {Number} offset
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Number} limit
	 * @returns {Promise} - An array of workflow runs
	 */
	async fetchWorkflowRuns({ locationId, actionStatuses, offset, sortField, sortOrder, limit }) {
		try {
			let response = await Kichiri.workflow.fetchWorkflowRuns(
				{},
				{
					locationId,
					actionStatuses,
					offset,
					sortField,
					sortOrder,
					limit
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Fetch workflow recipes
	 * @param {Number} companyId
	 * @param {String} searchTerm
	 * @param {String} status
	 */
	async fetchWorkflowRecipes({ companyId, searchTerm, status }) {
		try {
			let response = await Kichiri.workflow.fetchWorkflowRecipes(
				{},
				{
					companyId,
					searchTerm,
					status
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			throw error;
		}
	}
};

export default WorkflowService;

import React, { Component } from "react";

import ConfirmBlockAppointment from "../../../components/common/ConfirmBlockAppointment";

import "../../MessengerBeta/Thread/Message/message.css";
import "../../../styles/css/components/commons/bulk-actions.css";

import { CONFIRM_BLOCK_COLUMNS_APPOINTMENTS } from "../../../constants/ConfirmBlock";

const UPLOAD_LIMIT = 2000;

class UploadAppointmentsConfirmBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let {
			bulkLoading,
			bulkActionSuccess,
			bulkActionError,
			bulkActionPartialSuccess,
			errorFeedback,
			successFeedback,
			onConfirm,
			onNext,
			back,
			csvAppointments
		} = this.props;

		return (
			<>
				<ConfirmBlockAppointment
					header={"Are you sure you want to upload appointments?"}
					loading={bulkLoading}
					submitSuccess={bulkActionSuccess || bulkActionPartialSuccess}
					submitError={bulkActionError}
					successMessage="Appointments uploaded"
					errorMessage="Error uploading appointments"
					confirm={() => onConfirm()}
					next={onNext}
					back={() => {
						if (back) {
							back();
						}
					}}
					showFeedback={true}
					successFeedback={successFeedback}
					errorFeedback={errorFeedback}
					backLabel="Map Csv"
					successFeedbackLabel="Successfully processed"
					errorFeedbackLabel="Failed to create"
					successBtnLabel="Done"
					showNextButton={!csvAppointments || csvAppointments.length > UPLOAD_LIMIT ? false : true}
				>
					<div
						className="mb-message-box mb-message-box--out"
						style={{ textAlign: "center", paddingTop: "20px", maxWidth: "400px", margin: "Auto", marginBottom: "170px" }}
					>
						<p>Up to {csvAppointments && `${csvAppointments.length} appointments will be created.`}</p>
						{csvAppointments && csvAppointments.length > UPLOAD_LIMIT && (
							<p className="text-danger">
								A maximum of {UPLOAD_LIMIT} appointments can be uploaded at a time. Please change the csv to have a maximum of {UPLOAD_LIMIT} rows.
							</p>
						)}
					</div>
				</ConfirmBlockAppointment>
			</>
		);
	}
}

export default UploadAppointmentsConfirmBlock;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import ApiKeysService from "../../services/ApiKeysService";
import GAService from "../../services/GAService";

import Alert from "../../components/common/Alert";
import UpdateAPIKeysModal from "./UpdateAPIKeysModal";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import List from "../../components/common/List";
import SearchInput from "../../components/common/SearchInput";
import withLocation from "../../components/common/WithLocation";

import GROUP_PERMISSIONS from "../../constants/GroupPermissions";
import { SORT_ORDER } from "../../constants/CommonConstants";
import { API_KEY_COLUMNS } from "../../constants/ApiKeys";

import "../../styles/css/components/commons/react-table.css";

class APIKeys extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loadedAll: false,
			data: [],
			searchTerm: "",
			limitDefault: 25,
			limit: 25,
			pageSize: 50,
			sortField: API_KEY_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			hasError: false,
			success: false,
			infoText: "",
			errorText: "",
			showUpdateModal: false,
			currentToUpdateRow: null,
			createMode: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async location => {
		this.fetchData();
	};

	async fetchData() {
		try {
			let { searchTerm, sortField, sortOrder, limit } = this.state;

			this.setState({ loading: true });
			const location = UserService.getActiveLocation();
			const data = await ApiKeysService.fetch(location.id, { searchTerm, sortField, sortOrder, limit });
			this.setState({ data: data, loading: false, loadedAll: data.length < limit });
		} catch (error) {
			console.log(error);
			this.setState({ loading: false });
		}
	}

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.fetchData();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let columns = API_KEY_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	onRecordClicked = async item => {
		// nothing for now
	};

	renderRecord = recordData => {
		try {
			return [recordData.name, recordData.username, recordData.token, recordData.status, this.renderActions(recordData)];
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	renderActions(record) {
		return (
			<div>
				<Action
					key={`updateApiKey-${record.id}`}
					id={`updateApiKey-${record.id}`}
					label="Edit"
					icon={Icon.Edit}
					onClick={() => this.setState({ currentToUpdateRow: record }, () => this.setState({ showUpdateModal: true, createMode: false }))}
				/>
			</div>
		);
	}

	handleUpdateApiKeyClick(row) {
		this.setState({ currentToUpdateRow: row }, () => this.setState({ showUpdateModal: true, createMode: false }));
	}

	onUpdateModalHide() {
		this.setState({ showUpdateModal: false });
	}

	async handleCreateApiKey(name, username, password, knownHosts) {
		try {
			const location = UserService.getActiveLocation();

			let success = await ApiKeysService.create({ locationId: location.id, name, username, password, knownHosts });

			if (!success) {
				throw new Error("Error creating Api Key.");
			}
		} catch (error) {
			throw error;
		}
	}

	async onUpdateModalSubmit(createMode, id, name, username, password, token, status, knownHosts) {
		try {
			if (createMode) {
				await this.handleCreateApiKey(name, username, password, knownHosts);
			} else {
				let success = await ApiKeysService.update({ apiKeyId: id, name: name || "", username, password, token, status, knownHosts });

				if (!success) {
					throw new Error("Error updating Api Key.");
				}
			}
			this.setState({ showUpdateModal: false, success: true, infoText: "API Key Saved" });
			await this.fetchData();
		} catch (error) {
			this.setState({ hasError: true, errorText: "Error Saving API Key" });
			console.log(error);
		}
	}

	render() {
		const user = UserService.get();
		const { hasError, success, infoText, errorText, currentToUpdateRow, showUpdateModal, createMode } = this.state;
		let { data, loading, loadedAll, sortField, sortOrder } = this.state;

		if (user.GroupPermission.type !== GROUP_PERMISSIONS.super_administrator.type) {
			return <Redirect to="/settings" />;
		}

		return (
			<Page>
				<Header title="Api Keys">
					<Action id="refresh" label="Refresh" icon={Icon.RefreshCcw} onClick={() => this.fetchData()} />
					<Action
						id="create-new"
						label="Create New"
						icon={Icon.Plus}
						onClick={() => {
							this.setState({ createMode: true, currentToUpdateRow: undefined }, () => this.setState({ showUpdateModal: true }));
						}}
					/>
				</Header>
				<div className="Common__search">
					<SearchInput placeholder="Search ..." onChange={this.onSearchChange} />
				</div>
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={"Looks like there are no api keys yet..."}
					noDataIcon={<Icon.AlertCircle />}
				/>
				{showUpdateModal && (
					<UpdateAPIKeysModal
						data={currentToUpdateRow}
						show={showUpdateModal}
						onHide={() => this.onUpdateModalHide()}
						createMode={createMode}
						onSubmit={(createMode, id, name, username, password, token, status, knownHosts) =>
							this.onUpdateModalSubmit(createMode, id, name, username, password, token, status, knownHosts)
						}
					/>
				)}
				<Alert type="error" show={hasError} title="Error" confirm="OK" onClose={() => this.setState({ hasError: false, errorText: "" })}>
					<div>{errorText}</div>
				</Alert>
				<Alert type="success" show={success} title="Success" confirm="OK" onClose={() => this.setState({ success: false, infoText: "" })}>
					<div>{infoText}</div>
				</Alert>
			</Page>
		);
	}
}

export default withRouter(withLocation(APIKeys));

import React from "react";
import * as Icon from "react-feather";
import "../../styles/css/components/commons/dh-search-input.css";

const DHSearchInput = ({ onChange, value, onSearchClear, placeholder, width }) => {
	width = width ? width : "100%";
	return (
		<div className="DHSearchInput__container" style={{ width }}>
			<div className="DHSearchInput__sub-container" style={{ width }}>
				<input
					autoFocus
					type="text"
					className="DHSearchInput__input"
					onChange={event => {
						onChange(event);
					}}
					placeholder={placeholder}
					value={value}
				/>
			</div>
			<div
				className="DHSearchInput__clear"
				onClick={() => {
					onSearchClear();
				}}
			>
				{value ? <Icon.X size={18} /> : <Icon.Search size={18} />}
			</div>
		</div>
	);
};

export default DHSearchInput;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Alert from "./Alert";
import LogService from "../../services/logService";
import StatusService from "../../services/StatusService";
import UserService from "../../services/UserService";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			internetOffline: null,
			hasAuthTokenFailure: false,
			goTo: this.props.goTo,
			goToOnAuthFailure: this.props.goToOnAuthFailure
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidCatch(error, info) {
		await this.handleError(error, info);
	}

	async handleError(error, info) {
		let { checkAuthToken, name } = this.props;

		let onlineStatus = await this.checkOnlineStatus();

		if (checkAuthToken) {
			let hasAuthTokenFailure = await this.checkAuthTokenFailure();

			if (hasAuthTokenFailure) {
				await this.update({ hasError: true, hasAuthTokenFailure: true });
			}
		}

		if (onlineStatus) {
			await this.update({ hasError: true, internetOffline: false });
		} else {
			await this.update({ hasError: true, internetOffline: true });
		}
		LogService.log(error, info);
	}

	componentReset() {
		this.update({ hasError: false, internetOffline: false, hasAuthTokenFailure: false });
	}

	async checkAuthTokenFailure() {
		try {
			await UserService.checkAuthToken();
		} catch (errorResponse) {
			if (typeof errorResponse.response !== "undefined") {
				if (errorResponse.response.status === 401) {
					return true;
				}
			}
		}
		return false;
	}

	checkOnlineStatus = async () => {
		try {
			// If we are local, don't check anything
			if (process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "stage") {
				return true;
			}

			return await StatusService.checkOnlineStatus();
		} catch (error) {
			return false;
		}
	};

	render() {
		let { t } = this.props;
		let { hasError, internetOffline, hasAuthTokenFailure, goTo, goToOnAuthFailure } = this.state;

		// Declare the modal title and message
		let errorTitle = "";
		let errorMessage = "";

		// Decide the error message and title
		if (hasAuthTokenFailure) {
			errorTitle = t("Session Expired");
			errorMessage = t("Your session has expired. Please login again.");
		} else if (internetOffline) {
			errorTitle = t("Network Error");
			errorMessage = t("Please check your internet connection and try again.");
		} else {
			errorTitle = t("We're sorry. An error occurred.");
			errorMessage = t("Something went wrong. Please try again later.");
		}

		// Decide the redirection url
		let redirectionUrl = goTo;
		if (hasAuthTokenFailure) {
			redirectionUrl = goToOnAuthFailure;
		}

		if (hasError) {
			return (
				<Alert
					type="error"
					show={true}
					title={errorTitle}
					confirm="Okay"
					onClose={() => {
						if (hasAuthTokenFailure) {
							UserService.clear();
						}

						this.props.history.push(redirectionUrl);
						this.componentReset();
					}}
				>
					<div>{errorMessage}</div>
				</Alert>
			);
		}

		return this.props.children;
	}
}

export default withRouter(withTranslation(null, { withRef: true })(ErrorBoundary));

export const APPOINTMENT_TABS = {
	appointments: {
		id: "appointments",
		display: "Appointments",
		value: "appointments",
		label: "Appointments",
		route: "/appointments"
	},
	appointmentNotifications: {
		id: "appointmentNotifications",
		display: "Appointment Notifications",
		value: "appointmentNotifications",
		label: "Appointment Notifications",
		route: "/appointment-notifications"
	}
};

export const APPOINTMENT_DETAILS_TABS = {
	appointmentDetails: {
		id: "appointmentDetails",
		display: "Appointment Details",
		value: "appointmentDetails",
		label: "Appointment Details"
	},
	appointmentNotifications: {
		id: "appointmentNotifications",
		display: "Appointment Notifications",
		value: "appointmentNotifications",
		label: "Appointment Notifications"
	}
};

export const APPOINTMENT_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2,
		widthMd: 3
	},
	state: {
		id: "state",
		value: "Status",
		sortable: true,
		sortField: "state",
		width: 2,
		widthMd: 3
	},
	confirmed: {
		id: "confirmed",
		value: "Confirmed",
		sortable: true,
		sortField: "confirmed",
		width: 1,
		widthMd: 0
	},
	type: {
		id: "type",
		value: "Type",
		sortable: false,
		sortField: "type",
		width: 1,
		widthMd: 0
	},
	provider_name: {
		id: "provider_name",
		value: "Provider",
		sortable: false,
		sortField: "provider_name",
		width: 1,
		widthMd: 1
	},
	pendingNotifications: {
		id: "pendingNotifications",
		value: "Pending Notifications",
		sortable: false,
		width: 1,
		widthMd: 0
	},
	booking_at: {
		id: "booking_at",
		value: "Booking At",
		sortable: true,
		sortField: "booking_at",
		width: 2,
		widthMd: 3
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: true,
		sortField: "created_at",
		width: 2,
		widthMd: 0
	}
};

export const SORT_ORDER = {
	desc: "desc",
	asc: "asc"
};

export const STATUS = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	unconfirmed: {
		id: "unconfirmed",
		display: "Unconfirmed",
		order: 10
	},
	preconfirmed: {
		id: "preconfirmed",
		display: "Preconfirmed",
		order: 7
	},
	confirmed: {
		id: "confirmed",
		display: "Confirmed",
		order: 5
	},
	arrived: {
		id: "arrived",
		display: "Arrived",
		order: 2
	},
	waiting: {
		id: "waiting",
		display: "Waiting",
		order: 11
	},
	seated: {
		id: "seated",
		display: "Seated",
		order: 8
	},
	billed: {
		id: "billed",
		display: "Billed",
		order: 3
	},
	cancelled: {
		id: "cancelled",
		display: "Cancelled",
		order: 4
	},
	departed: {
		id: "departed",
		display: "Departed",
		order: 6
	},
	missed: {
		id: "missed",
		display: "Missed",
		order: 12
	},
	unknown: {
		id: "unknown",
		display: "Unknown",
		order: 13
	}
};

export const CONFIRM_STATES = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	confirmed: {
		id: "1",
		display: "Confirmed",
		order: 2
	},
	unconfirmed: {
		id: "0",
		display: "Unconfirmed",
		order: 3
	}
};

export const APPOINTMENT_NOTIFICATION_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 1
	},
	type: {
		id: "type",
		value: "Type",
		sortable: true,
		sortField: "type",
		width: 1,
		hideMd: true
	},
	status: {
		id: "status",
		value: "Status",
		sortable: true,
		sortField: "status",
		width: 1,
		hideXs: true
	},
	send_after: {
		id: "send_after",
		value: "Send After",
		sortable: true,
		sortField: "send_after",
		width: 1
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: true,
		sortField: "created_at",
		width: 1,
		hideMd: true
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1,
		notClickable: true
	}
};

export const NOTIFICATION_STATUS = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	pending: {
		id: "pending",
		display: "Pending",
		order: 5
	},
	sent: {
		id: "sent",
		display: "Sent",
		order: 6
	},
	cancelled: {
		id: "cancelled",
		display: "Cancelled",
		order: 2
	},

	deleted: {
		id: "deleted",
		display: "Deleted",
		order: 3
	},
	error: {
		id: "error",
		display: "Error",
		order: 4
	}
};

export const NOTIFICATION_TYPES = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	reminder: {
		id: "reminder",
		display: "Reminder",
		order: 3
	},
	confirmation_request: {
		id: "confirmation_request",
		display: "Confirmation Request",
		order: 2
	}
};

export const CONVERSION_APPOINTMENTS_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: false,
		sortField: "name",
		width: 2
	},
	state: {
		id: "state",
		value: "State",
		sortable: false,
		sortField: "state",
		width: 1
	},
	booking_at: {
		id: "booking_at",
		value: "Booking At",
		sortable: false,
		sortField: "booking_at",
		width: 2
	},
	lead_source: {
		id: "lead_source",
		value: "Source",
		sortable: false,
		sortField: "lead_source",
		width: 1
	},
	more: {
		id: "more",
		value: "More",
		sortable: false,
		sortField: "more",
		width: 1
	}
};

export const WIDGET_NAME = {
	webchat: "webchat",
	reviewsWidget: "reviews_widget",
	embeddedReviewsWidget: "embedded_reviews_widget",
	embeddedBookingWidget: "embedded_booking_widget"
};

export const WIDGET_FRIENDLY_NAME = {
	webchat: "Webchat",
	reviews_widget: "Reviews Widget",
	embedded_reviews_widget: "Embedded Reviews Widget",
	embedded_booking_widget: "Embedded Booking Widget"
};

export const WIDGET_REVIEW_CONFIG = {
	forceMode: [
		{ label: "Auto", value: "auto" },
		{ label: "Mobile", value: "mobile" },
		{ label: "Desktop", value: "desktop" }
	],
	position: [
		{ label: "Bottom Left", value: "bottom-left" },
		{ label: "Bottom Right", value: "bottom-right" },
		{ label: "Mid Left", value: "mid-left" },
		{ label: "Mid Right", value: "mid-right" }
	]
};

export const WIDGET_CONFIG = {
	positioningModes: [
		{ value: "desktop", label: "Desktop" },
		{ value: "mobile", label: "Mobile" }
	],
	offsetModes: {
		default: {
			value: "default",
			label: "Default"
		},
		custom: {
			value: "custom",
			label: "Custom Position In Url"
		}
	}
};

export const DEFAULT_WEBCHAT_POSITION = { label: "Bottom Right", value: "bottom-right" };

export const WEBCHAT_POSITIONS = [
	{ label: "Bottom Right", value: "bottom-right" },
	{ label: "Bottom", value: "bottom" },
	{ label: "Bottom Left", value: "bottom-left" },
	{ label: "Left", value: "left" },
	{ label: "Top Left", value: "top-left" },
	{ label: "Top", value: "top" },
	{ label: "Top Right", value: "top-right" },
	{ label: "Right", value: "right" }
];

export const WIDGET_TABS = {
	all: {
		id: "all",
		value: "All"
	},
	microSite: {
		id: "microSite",
		value: "Micro Site"
	},
	webChat: {
		id: "webChat",
		value: "Web Chat"
	},
	bookingWidget: {
		id: "bookingWidget",
		value: "Booking"
	},
	reviewsWidget: {
		id: "reviewsWidget",
		value: "Reviews"
	},
	embeddedReviewsWidget: {
		id: "embeddedReviewsWidget",
		value: "Embedded Reviews"
	}
};

export const BOOKING_TABS = {
	general: {
		id: "general",
		value: "General"
	},
	bookingSlots: {
		id: "bookingSlots",
		value: "Booking Slots"
	},
	services: {
		id: "services",
		value: "Services"
	}
};

export const IFRAME_STYLES = {
	modal: {
		background: "rgba(0, 0, 0, 0)",
		width: "100vw",
		height: "100vh",
		border: "none",
		position: "fixed",
		left: "0px",
		top: "0px"
	},
	tooltip: {
		width: "350px",
		height: "200px",
		border: "none",
		position: "absolute",
		bottom: "85px"
	},
	bubble: {
		width: "300px",
		height: "100px",
		border: "none",
		position: "absolute",
		bottom: "0px",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		justifyContent: "center",
		overflow: "none"
	},
	positions: {
		bottomRight: "bottom-right",
		bottom: "bottom",
		bottomLeft: "bottom-left",
		left: "left",
		topLeft: "top-left",
		top: "top",
		topRight: "top-right",
		right: "right"
	},
	hubSpotChat: {
		background: "rgba(0, 0, 0, 0)",
		width: "100%",
		height: "100%",
		border: "none"
	}
};

import React from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import ReactSwitch from "react-switch";
import { QRCodeCanvas } from "qrcode.react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import ShortLinkService from "../../../services/ShortLinkService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import UtilityService from "../../../services/UtilityService";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";
import PasswordInput from "../../../components/common/PasswordInput";

import { STATUS } from "../../../constants/CommonConstants";
import { SHORT_LINK_TABS, SHORT_LINK_TYPES } from "../../../constants/ShortLinksConstants";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

import "./manage-short-link-modal.css";

class ManageShortLinkModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: SHORT_LINK_TABS.custom.id,
			loading: false,
			saving: false,

			// Editable fields
			longUrl: "",
			type: "",
			name: "",
			expireAt: null,
			status: STATUS.active,
			password: null,
			shortLinkHasPassword: false,

			// Toggles to show or hide certain input fields
			showExpireAt: false,
			showPassword: false,

			// Short Link Model
			shortLink: null
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate = prevProps => {
		if (prevProps.show !== this.props.show) {
			this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { id, t } = this.props;

		if (!id) {
			await this.update({
				selectedTab: SHORT_LINK_TABS.custom.id,
				loading: false,
				saving: false,

				longUrl: "",
				type: "",
				name: "",
				expireAt: null,
				status: STATUS.active,
				password: null,
				shortLinkHasPassword: false,

				showExpireAt: false,
				showPassword: false,

				shortLink: null
			});
			return;
		}

		await this.update({ loading: true });

		let shortLink = await ShortLinkService.fetchShortLink({ id, locationId: UserService.getActiveLocation().id });

		if (!shortLink) {
			ToastService.error(t("Error fetching short link. Please try again."));
			return;
		}

		await this.update({
			loading: false,
			longUrl: shortLink.long_url,
			type: shortLink.type,
			name: shortLink.name,
			expireAt: shortLink.expire_at,
			password: null,
			shortLinkHasPassword: shortLink.password ? true : false,
			showExpireAt: shortLink.expire_at ? true : false,
			showPassword: shortLink.password ? true : false,
			status: shortLink.status,
			shortLink
		});
	};

	onSave = async () => {
		let { t } = this.props;
		let { shortLink, longUrl, name, expireAt, password, status } = this.state;

		if (!this.isValid()) {
			return;
		}

		await this.update({ saving: true });

		let response = null;

		if (this.isCreateMode()) {
			response = await ShortLinkService.create({
				locationId: UserService.getActiveLocation().id,
				longUrl,
				name,
				type: SHORT_LINK_TYPES.custom,
				expireAt: expireAt ? moment(expireAt).toISOString() : undefined,
				password: password !== null ? password : undefined
			});
		} else {
			let longUrlChanged = shortLink.long_url !== longUrl;

			response = await ShortLinkService.update({
				id: shortLink.id,
				locationId: UserService.getActiveLocation().id,
				longUrl: longUrlChanged ? longUrl : undefined,
				name,
				expireAt: expireAt ? moment(expireAt).toISOString() : null,
				password: password !== null ? password : undefined,
				status
			});
		}

		if (!response) {
			ToastService.error(t("Error saving short link. Please try again."));
			await this.update({ saving: false });
			return;
		}

		this.onHide(response);
		await this.update({ saving: false });
	};

	onHide = (update = false) => {
		const { onHide } = this.props;

		if (onHide) {
			onHide(update);
		}
	};

	handleGenericEventHandler = (event, name) => {
		if (!name) {
			name = event.target.name;
		}

		let value = event.target ? event.target.value : "";

		this.update({ [name]: value, edited: true });
	};

	isCreateMode = () => {
		const { id } = this.props;
		return !id;
	};

	isLongUrlValidUrl = () => {
		const { longUrl } = this.state;
		return UtilityService.isValidHttpsUrl(longUrl);
	};

	longUrlHasHttp = () => {
		const { longUrl } = this.state;
		return longUrl.includes("http://") || longUrl.includes("https://");
	};

	isLongUrlInvalid = () => {
		const { longUrl } = this.state;

		if (longUrl && longUrl.length > 6) {
			if (!this.isLongUrlValidUrl()) {
				return true;
			}

			if (!this.longUrlHasHttp()) {
				return true;
			}
		}

		return false;
	};

	isValid = () => {
		const { longUrl } = this.state;

		if (!longUrl || !this.isLongUrlValidUrl()) {
			return false;
		}

		if (!this.longUrlHasHttp()) {
			return false;
		}

		return true;
	};

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.shortLinks,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	handlePasswordChange = ({ value, passed }) => {
		this.setState({
			password: value
		});
	};

	Switch = ({ field, checked, onChange, disabled = false }) => {
		return (
			<ReactSwitch
				id={field}
				height={22}
				width={38}
				checked={checked}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor="#60A9FF"
				offColor="#c5c5c5"
				disabled={disabled}
				onChange={value => {
					if (onChange) {
						onChange(value);
					}
				}}
			/>
		);
	};

	onDownloadQrCode = () => {
		UtilityService.downloadQrCode({ id: "short-link-qr-code" });
	};

	renderQrCode = () => {
		const { shortLink } = this.state;
		let { t } = this.props;

		return (
			<div className="mslm__short-link">
				<QRCodeCanvas id="short-link-qr-code" value={shortLink.short_url} includeMargin={false} size={256} />
				<br></br>
				<a onClick={this.onDownloadQrCode} className="text-center">
					<Icon.Download size={16} /> {t("Download QR Code")}
				</a>
			</div>
		);
	};

	renderDetails = () => {
		const { shortLink } = this.state;
		let { t } = this.props;

		if (!shortLink) {
			return null;
		}

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		return (
			<>
				<Input id="shortUrl" name="Short Url" label={t("Short Url")} type="input" value={shortLink.short_url} disabled={true} />
				{isSuperOrCs && <Input id="type" name="type" label={t("Type")} type="input" value={shortLink.type} disabled={true} />}
				<Input id="openCount" name="openCount" label={t("Open Count")} type="input" value={shortLink.open_count} disabled={true} />
				<Input
					id="expireAt"
					name="expireAt"
					label={t("Expire At")}
					type="input"
					value={shortLink.expire_at ? moment(shortLink.expire_at).format("MMM Do YYYY hh:mm a") : ""}
					disabled={true}
				/>
			</>
		);
	};

	renderForm = () => {
		const { name, longUrl, expireAt, password, shortLinkHasPassword, saving, showExpireAt, showPassword } = this.state;
		const { t } = this.props;

		return (
			<>
				<Input
					id="shortlinkName"
					name="name"
					label={t("Name")}
					type="input"
					onChange={e => this.handleGenericEventHandler(e)}
					value={name}
					autoComplete="off"
					maxLength={255}
				/>

				<Input
					id="longUrl"
					name="longUrl"
					label={t("Destination Url")}
					type="input"
					onChange={e => this.handleGenericEventHandler(e)}
					value={longUrl}
					autoComplete="off"
					maxLength={1000}
					required={true}
					invalid={this.isLongUrlInvalid()}
				/>

				{this.isLongUrlInvalid() && (
					<div className="mslm__long-url-invalid">{t("Url is invalid. Ensure you have the right url. Url requires 'https://' or 'http://'")} </div>
				)}

				<div>{t("Show Expiry fields")}</div>
				<this.Switch field="showExpireAt" checked={showExpireAt} onChange={value => this.update({ showExpireAt: value })}></this.Switch>

				{showExpireAt && (
					<Input
						id="expireAt"
						name="expireAt"
						label={t("Expire At")}
						type="datetime-local"
						onChange={e => this.handleGenericEventHandler(e)}
						value={expireAt ? moment(expireAt).format("YYYY-MM-DDTHH:mm") : ""}
					/>
				)}

				<div>{t("Show Password fields")}</div>
				<this.Switch field="showPassword" checked={showPassword} onChange={value => this.update({ showPassword: value })}></this.Switch>

				{showPassword && (
					<>
						<div>{t("Is Secure (Password Protected)")}</div>
						<this.Switch
							field="hasPassword"
							checked={shortLinkHasPassword}
							disabled={!shortLinkHasPassword}
							onChange={value => {
								let newPassword = password;
								if (!value) {
									newPassword = "";
								}
								this.update({
									shortLinkHasPassword: value,
									password: newPassword
								});
							}}
						></this.Switch>

						<div>
							{t("Password (Optional)")} <Icon.Info data-tip data-for={`password-info-tooltip`} size={16} />
							<ReactTooltip id={`password-info-tooltip`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
								<div>{t("Be sure to remember your password or keep it in a safe place.")}</div>
							</ReactTooltip>
						</div>
						<PasswordInput
							id="password"
							name="password"
							placeholder={t("Password...")}
							required={false}
							onChange={this.handlePasswordChange}
							value={password || ""}
							maxLength={64}
						/>
						<div className="manage-user__label__description">{t("Leave this field blank if you are not creating or updating your password.")}</div>
						<br></br>
					</>
				)}
				<div className="modal__actions">
					<div className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onSave}>
						{saving ? t("Saving...") : t("Save")}
					</div>
				</div>
			</>
		);
	};

	renderLoading = () => {
		return (
			<>
				<ContentLoader height={234} width={"100%"}>
					<rect x="0" y="10" rx="5" ry="5" width="100%" height="40" />

					<rect x="0" y="70" rx="5" ry="5" width="100%" height="40" />

					<rect x="0" y="130" rx="5" ry="5" width="100%" height="40" />

					<rect x="400" y="190" rx="5" ry="5" width="70" height="40" />
				</ContentLoader>
			</>
		);
	};

	render = () => {
		const { show, t } = this.props;
		const { loading, selectedTab, shortLink } = this.state;

		return (
			<Modal show={show} title={this.isCreateMode() ? t("Create Short Link") : t("Update Short Link")} onHide={() => this.onHide(false)}>
				<div className="modal__body">
					<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
						<Tab id={SHORT_LINK_TABS.custom.id} value={SHORT_LINK_TABS.custom.value} />
						{shortLink && <Tab id={SHORT_LINK_TABS.details.id} value={SHORT_LINK_TABS.details.value} />}
						{shortLink && <Tab id={SHORT_LINK_TABS.qrCode.id} value={SHORT_LINK_TABS.qrCode.value} />}
					</Tabs>
					{loading && this.renderLoading()}
					{!loading && (
						<>
							{SHORT_LINK_TABS.custom.id === selectedTab && this.renderForm()}
							{SHORT_LINK_TABS.details.id === selectedTab && this.renderDetails()}
							{SHORT_LINK_TABS.qrCode.id === selectedTab && this.renderQrCode()}
						</>
					)}
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(ManageShortLinkModal);

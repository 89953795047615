import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import NotificationList from "./NotificationList";
import Appointment from "./Appointment";
import Modal from "../../components/common/DHModal";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";

import { APPOINTMENT_DETAILS_TABS } from "../../constants/Appointments";

import "../../styles/css/components/commons/dh-details.css";

class AppointmentDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: APPOINTMENT_DETAILS_TABS.appointmentDetails.id
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = () => {
		this.update({
			selectedTab: APPOINTMENT_DETAILS_TABS.appointmentDetails.id
		});
	};

	onTabSelect = async tab => {
		this.update({ selectedTab: tab.id });
	};

	onHide = () => {
		let { onHide } = this.props;

		if (!onHide) {
			return;
		}

		onHide();

		this.resetComponent();
	};

	render() {
		let { show, appointmentId, t } = this.props;
		let { selectedTab } = this.state;

		return (
			<Modal show={show} onHide={this.onHide} title={t("Appointment")}>
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={APPOINTMENT_DETAILS_TABS.appointmentDetails.id} value={APPOINTMENT_DETAILS_TABS.appointmentDetails.label} />
					<Tab id={APPOINTMENT_DETAILS_TABS.appointmentNotifications.id} value={APPOINTMENT_DETAILS_TABS.appointmentNotifications.label} />
				</Tabs>
				<div className="dh-details__container">
					{appointmentId && APPOINTMENT_DETAILS_TABS.appointmentDetails.id === selectedTab && <Appointment appointmentId={appointmentId} />}
					{appointmentId && APPOINTMENT_DETAILS_TABS.appointmentNotifications.id === selectedTab && <NotificationList appointmentId={appointmentId} />}
				</div>
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(AppointmentDetails);

import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";
import { getServices } from "service-fetch";

import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";

import DHModal from "../../../components/common/DHModal";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";
import Alert from "../../../components/common/Alert";
import { STATUS } from "../../../constants/CommonConstants";
import { SIGN_UP_STATES } from "../../../constants/SignUpConstants";
import DHCard from "../../../components/common/DHCard";
import DHCardHeader from "../../../components/common/DHCardHeader";
import Input from "../../../components/common/Input";

import "./sign-ups.css";
import AppConfig from "../../../config/app/web-app.config.js";

const TABS = {
	general: {
		value: "general",
		label: "General"
	},
	timeline: {
		value: "timeline",
		label: "Timeline"
	}
};

const { Sign_upsService: SignUpService } = getServices();

class SignUpDetailsModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			showConfirmDeleteModal: false,
			showConfirmUpdateStateModal: false,
			setStateAs: null,

			selectedTab: TABS.general.value,
			signUp: null,
			events: null
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		await this.fetchData();
	};

	fetchData = async () => {
		let { signUpId } = this.props;

		if (!signUpId) {
			return;
		}

		await this.update({
			loading: true
		});

		let { data: signUp } = await SignUpService.fetchSignUp({ params: { signUpId } });

		if (!signUp) {
			ToastService.error("Error getting sign up. Please try again.");
			await this.update({
				loading: false
			});
			return null;
		}

		let events = signUp.events;

		if (!events) {
			events = [];
		}

		events.unshift({ event: "Created", timestamp: moment(signUp.created_at) });
		events.map(e => (e.event = e.event.replaceAll("_", " ")));

		await this.update({
			loading: false,
			signUp,
			events
		});
	};

	onClose = async (shouldRefreshData = false) => {
		this.update({ signUp: null, selectedTab: TABS.general.value, events: null });

		if (this.props.onClose) {
			await this.props.onClose(shouldRefreshData);
		}
	};

	onTabSelect = async tab => {
		this.update({ selectedTab: tab.id });
	};

	onConfirmDelete = () => {
		this.update({
			showConfirmDeleteModal: true
		});
	};

	onConfirmDeleteClose = async confirm => {
		let { signUp } = this.state;

		if (confirm && signUp) {
			let name = signUp.legal_business_name;
			await SignUpService.updateSignUp({
				params: {
					signUpId: signUp.id
				},
				body: {
					status: STATUS.deleted
				}
			});

			ToastService.info(`Deleted Sign Up for ${name}`);
			this.onClose(true);
		}

		this.update({
			showConfirmDeleteModal: false
		});
	};

	onConfirmUpdateState = (setStateAs = null) => {
		this.update({
			setStateAs,
			showConfirmUpdateStateModal: true
		});
	};

	onConfirmUpdateStateClose = async confirm => {
		let { signUp, setStateAs } = this.state;

		if (!confirm || !signUp) {
			this.update({
				showConfirmUpdateStateModal: false,
				setStateAs: null
			});

			return;
		}

		let state = signUp.state;

		// Determine the new state depending on the current state
		if (setStateAs) {
			state = setStateAs;
		} else if (state === SIGN_UP_STATES.draft) {
			state = SIGN_UP_STATES.sent;
		} else if (state === SIGN_UP_STATES.signed) {
			state = SIGN_UP_STATES.submitted;
		} else {
			return;
		}

		let name = signUp.legal_business_name;
		await SignUpService.updateSignUp({
			params: {
				signUpId: signUp.id
			},
			body: {
				state
			}
		});

		ToastService.info(`Updated Sign Up for ${name}`);

		this.update({
			showConfirmUpdateStateModal: false,
			setStateAs: null
		});
		this.onClose(true);
	};

	onPreviewSignUp = () => {
		let { signUp } = this.state;

		window.open(AppConfig.SIGN_UP_URL + `order/${signUp.public_id}`, "_blank").focus();
	};

	renderLoading = () => {
		return (
			<div className="msu-modal__body">
				<ContentLoader viewBox="0 0 100% 390" height={390} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="300" />

					<rect x="40%" y="350" rx="5" ry="5" width="100%" height="40" />
				</ContentLoader>
			</div>
		);
	};

	renderGeneral = () => {
		let { signUp, loading } = this.state;

		if (loading) {
			return this.renderLoading();
		}

		if (!signUp) {
			return null;
		}

		let state = signUp.state;
		let canManage = UserService.isSuperAdminOrCustomerSuccessOrAccountOwner();

		return (
			<>
				<div className="msu-modal__body">
					<DHCard>
						<DHCardHeader>Contact Details</DHCardHeader>
						<Input id="contact-name" name="contactName" label={"Contact Full Name"} type="text" value={signUp.contact_name} disabled={true} />

						<Input id="contact-phone" name="contactPhone" label={"Contact Phone"} type="text" value={signUp.contact_phone} disabled={true} />
						<Input id="contact-email" name="contactEmail" label={"Contact Email"} type="text" value={signUp.contact_email} disabled={true} />
					</DHCard>
				</div>

				{canManage && (
					<div className="modal__actions">
						<div className="mb-button mb-button--cancel" onClick={() => this.onConfirmDelete()}>
							<Icon.Trash2 size={14} /> Delete
						</div>
						<div className="mb-button" onClick={() => this.onPreviewSignUp()}>
							<Icon.Globe size={14} /> Preview
						</div>
						{state === SIGN_UP_STATES.sent && (
							<div className="mb-button" onClick={() => this.onConfirmUpdateState(SIGN_UP_STATES.draft)}>
								<Icon.PenTool size={14} /> Set as Draft
							</div>
						)}
						{state === SIGN_UP_STATES.draft ? (
							<div className="mb-button" onClick={() => this.onConfirmUpdateState()}>
								<Icon.Send size={14} /> Send Sign Up
							</div>
						) : state === SIGN_UP_STATES.sent ? (
							<div className="mb-button" onClick={() => this.onConfirmUpdateState(SIGN_UP_STATES.sent)}>
								<Icon.Send size={14} /> Re-Send Sign Up
							</div>
						) : state === SIGN_UP_STATES.signed ? (
							<div className="mb-button" onClick={() => this.onConfirmUpdateState()}>
								<Icon.LogIn size={14} /> Submit Sign Up
							</div>
						) : null}
					</div>
				)}
			</>
		);
	};

	renderTimeline = () => {
		let { events, signUp, loading } = this.state;

		if (loading) {
			return this.renderLoading();
		}

		if (!events) {
			return null;
		}

		if (!signUp) {
			return null;
		}

		return (
			<div className="msu-modal__body">
				<div className="prd-timeline">
					<div className="prd-timeline-title"></div>
					<div className="prd-timeline-details">
						{events.map((anEvent, index) => {
							return (
								<React.Fragment key={`event-${signUp.id}-${index}`}>
									<div className="prd-timeline-details-line" />
									<div className="prd-timeline-details-event">
										<div className="prd-timeline-details-event-name">
											{anEvent.event} <br />
											{moment(anEvent.timestamp).format("ddd, MMM Do YYYY, h:mm a")}
										</div>
									</div>
								</React.Fragment>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	renderTabs = () => {
		let { selectedTab } = this.state;

		return (
			<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
				<Tab id={TABS.general.value} value={TABS.general.label} />
				<Tab id={TABS.timeline.value} value={TABS.timeline.label} />
			</Tabs>
		);
	};

	render = () => {
		let { selectedTab, signUp, showConfirmDeleteModal, showConfirmUpdateStateModal, setStateAs } = this.state;
		let { show } = this.props;

		let title = "Sign Up Details";

		if (signUp) {
			title = signUp.legal_business_name + " Details";
		}

		return (
			<>
				<DHModal show={show} onHide={this.onClose} title={title}>
					{this.renderTabs()}

					{selectedTab === TABS.general.value && this.renderGeneral()}
					{selectedTab === TABS.timeline.value && this.renderTimeline()}
				</DHModal>
				<Alert type="info" show={showConfirmDeleteModal} title={"Delete Sign Up"} confirm={"Yes"} cancel={"No"} onClose={this.onConfirmDeleteClose}>
					Are you sure you want to delete the Sign Up for {signUp ? signUp.legal_business_name : "this"}?
				</Alert>
				<Alert
					type="info"
					show={showConfirmUpdateStateModal}
					title={"Update State Sign Up"}
					confirm={"Yes"}
					cancel={"No"}
					onClose={this.onConfirmUpdateStateClose}
				>
					{signUp && setStateAs && setStateAs === SIGN_UP_STATES.sent ? (
						<div>Are you sure you want to re-send {signUp ? signUp.legal_business_name : "this business"}'s Sign Up?</div>
					) : signUp && setStateAs ? (
						<div>
							Are you sure you want to set {signUp ? signUp.legal_business_name : "this business"}'s Sign Up to '{setStateAs}'?
						</div>
					) : signUp && signUp.state === SIGN_UP_STATES.draft ? (
						<div>Are you sure you want to send the Sign Up to {signUp ? signUp.legal_business_name : "this business"}?</div>
					) : signUp && signUp.state === SIGN_UP_STATES.signed ? (
						<div>Are you sure you want to submit {signUp ? signUp.legal_business_name : "this business"}'s Sign Up?</div>
					) : null}
				</Alert>
			</>
		);
	};
}

export default withRouter(SignUpDetailsModal);

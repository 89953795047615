import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

class SelectConversations extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	toggleShowBulkControls = async e => {
		if (this.props.onToggleBulkControls) {
			this.props.onToggleBulkControls();
		}
	};

	render() {
		let { showBulkControls } = this.props;

		return (
			<>
				<div
					id="fnctst-list-conversations-select"
					ref={ref => (this.contextContainer = ref)}
					data-tip
					data-for="conversation-select-tooltip"
					onClick={this.toggleShowBulkControls}
				>
					<Icon.List size="22" color={showBulkControls ? "#60A9FF" : "#333"} />
				</div>
				<ReactTooltip id="conversation-select-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="bottom">
					Bulk Actions
				</ReactTooltip>
			</>
		);
	}
}

export default SelectConversations;

import React from "react";
import Spinners from "./Spinners";
import PropTypes from "prop-types";
import "../../styles/css/components/commons/loading-button.css";

const LoadingButton = ({ buttonId, type, loading, onClick, componentClass, children, disabled = false, size = "7px" }) => {
	return (
		<>
			{!loading ? (
				<button id={buttonId} className={componentClass} type={type || "button"} onClick={e => onClick(e)} disabled={disabled}>
					{children}
				</button>
			) : (
				<button id={buttonId} className={componentClass} disabled>
					<div className="loading-button__spinner--center">
						<Spinners loading={true} type="3circle-fade" size={size} color={componentClass.includes("btn-primary") ? "white" : null} />
					</div>
				</button>
			)}
		</>
	);
};

LoadingButton.propTypes = {
	type: PropTypes.oneOf(["button", "submit"]),
	onClick: PropTypes.func,
	componentClass: PropTypes.string,
	loading: PropTypes.bool.isRequired
};

export default LoadingButton;

import React from "react";
import Select from "react-select";
import ContentLoader from "react-content-loader";
import { withTranslation } from "react-i18next";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import DHSelect from "../../../components/common/DHSelect";
import TextArea from "../../../components/common/TextArea";
import Checkbox from "../../../components/common/Checkbox";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import A2PService from "../../../services/A2PService";
import UtilityService from "../../../services/UtilityService";

import { EDIT_BRAND_TABS } from "../../../constants/A2P";

import "./a2p.css";

class EditBrandModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// Modal Data
			selectedTab: EDIT_BRAND_TABS.contact.id,
			loading: false,
			config: null,

			// Selectors
			selectableEntityTypes: [],
			selectableEinIssuingCountries: [],
			selectableCountries: [],
			selectableBrandRelationships: [],
			selectableVerticals: [],
			selectableStockExchanges: [],
			selectableAltBusinessIdTypes: [],

			// Brand Data
			entityType: "",
			firstName: "",
			lastName: "",
			displayName: "",
			companyName: "",
			ein: "",
			einIssuingCountry: "",
			phone: "",
			mobilePhone: "",
			street: "",
			city: "",
			state: "",
			postalCode: "",
			country: "",
			email: "",
			stockSymbol: "",
			stockExchange: "",
			ipAddress: "",
			website: "",
			brandRelationship: "",
			vertical: "",
			altBusinessId: "",
			altBusinessIdType: "",
			referenceId: "",
			tag: ""
		};
	}

	componentDidUpdate = async prevProps => {
		if (prevProps.show !== this.props.show) {
			await this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({ loading: true });

		await this.update({
			// Modal Data
			selectedTab: EDIT_BRAND_TABS.contact.id,
			config: null,

			// Selectors
			selectableEntityTypes: [],
			selectableEinIssuingCountries: [],
			selectableCountries: [],
			selectableBrandRelationships: [],
			selectableVerticals: [],
			selectableStockExchanges: [],
			selectableAltBusinessIdTypes: [],

			// Brand Data
			entityType: "",
			firstName: "",
			lastName: "",
			displayName: "",
			companyName: "",
			ein: "",
			einIssuingCountry: "",
			phone: "",
			mobilePhone: "",
			street: "",
			city: "",
			state: "",
			postalCode: "",
			country: "",
			email: "",
			stockSymbol: "",
			stockExchange: "",
			ipAddress: "",
			website: "",
			brandRelationship: "",
			vertical: "",
			altBusinessId: "",
			altBusinessIdType: "",
			referenceId: "",
			tag: ""
		});

		await this.fetchConfig();
		await this.buildFormOptions();
		await this.fetchBrand();
		await this.prefillWithDefaultData();

		await this.update({ loading: false });
	};

	onTabSelect = async tab => {
		await this.update({ selectedTab: tab.id });
	};

	// For <input> changes
	onChangeInput = ({ fieldName, value }) => {
		this.update({
			[fieldName]: value
		});
	};

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	buildFormOptions = async () => {
		let { config } = this.state;

		let entityTypes = config.enums.entityTypes;
		let countries = config.enums.countries;
		let brandRelationships = config.enums.brandRelationships;
		let verticals = config.enums.verticles;
		let stockExchanges = config.enums.stockExchanges;
		let altBusinessIdTypes = config.enums.alternativeBusinessIdTypes;

		let selectableEntityTypes = [];
		let selectableEinIssuingCountries = [];
		let selectableCountries = [];
		let selectableBrandRelationships = [];
		let selectableVerticals = [];
		let selectableStockExchanges = [];
		let selectableAltBusinessIdTypes = [];

		for (const [key, value] of Object.entries(entityTypes)) {
			var friendlyLabel = value.replace(/_/g, " "); // remove underscores
			friendlyLabel = UtilityService.toTitleCase(friendlyLabel);

			selectableEntityTypes.push({
				label: friendlyLabel,
				value: value
			});
		}

		for (const [key, value] of Object.entries(countries)) {
			let countryName = value.name;
			let countryId = value.id;

			selectableEinIssuingCountries.push({
				label: countryName,
				value: countryId
			});

			selectableCountries.push({
				label: countryName,
				value: countryId
			});
		}

		for (const [key, value] of Object.entries(brandRelationships)) {
			var friendlyLabel = value.replace(/_/g, " "); // remove underscores
			friendlyLabel = UtilityService.toTitleCase(friendlyLabel);

			selectableBrandRelationships.push({
				label: friendlyLabel,
				value: value
			});
		}

		for (const [key, value] of Object.entries(verticals)) {
			let verticalName = value.displayName;
			let verticalId = value.id;

			selectableVerticals.push({
				label: verticalName,
				value: verticalId
			});
		}

		for (const [key, value] of Object.entries(stockExchanges)) {
			selectableStockExchanges.push({
				label: value,
				value: value
			});
		}

		for (const [key, value] of Object.entries(altBusinessIdTypes)) {
			selectableAltBusinessIdTypes.push({
				label: value,
				value: value
			});
		}

		await this.update({
			selectableEntityTypes,
			selectableEinIssuingCountries,
			selectableCountries,
			selectableBrandRelationships,
			selectableVerticals,
			selectableStockExchanges,
			selectableAltBusinessIdTypes
		});
	};

	prefillWithDefaultData = async () => {
		let { config, selectableCountries } = this.state;

		if (this.props.brandId) {
			return;
		}

		let location = UserService.getActiveLocation();

		// Find the country and set the iso value
		let isoCountryObject = selectableCountries.find(theCountry => theCountry.label.toLowerCase() === location.country.toLowerCase());
		let isoCountry = typeof isoCountryObject === "undefined" ? "CA" : isoCountryObject.value;

		let ipAddress = (await UtilityService.getIP()) || "";

		await this.update({
			entityType: config.enums.brandRequestSchema.entityType.dhDefault,
			firstName: config.enums.brandRequestSchema.firstName.dhDefault,
			lastName: config.enums.brandRequestSchema.lastName.dhDefault,
			displayName: location.name,
			companyName: location.name,
			ein: config.enums.brandRequestSchema.ein.dhDefault,
			einIssuingCountry: isoCountry,
			phone: location.phone,
			mobilePhone: location.phone,
			street: location.address_1,
			city: location.city,
			state: location.state_prov,
			postalCode: location.zip_postal,
			country: isoCountry,
			email: location.email,
			stockSymbol: config.enums.brandRequestSchema.stockSymbol.dhDefault,
			stockExchange: config.enums.brandRequestSchema.stockExchange.dhDefault,
			ipAddress: ipAddress,
			website: location.website,
			brandRelationship: config.enums.brandRequestSchema.brandRelationship.dhDefault,
			vertical: config.enums.brandRequestSchema.vertical.dhDefault,
			altBusinessId: config.enums.brandRequestSchema.altBusinessId.dhDefault,
			altBusinessIdType: config.enums.brandRequestSchema.altBusinessIdType.dhDefault,
			tag: config.enums.brandRequestSchema.tag.dhDefault
		});
	};

	fetchConfig = async () => {
		let config = await A2PService.fetchConfig();
		await this.update({ config });
	};

	fetchBrand = async () => {
		let { t } = this.props;

		if (!this.props.brandId) {
			return;
		}

		try {
			let brand = await A2PService.fetchBrand({ brandId: this.props.brandId });

			if (!brand) {
				ToastService.error(t("Could not fetch brand."));
				return;
			}

			await this.update({
				entityType: brand.entity_type,
				firstName: brand.first_name,
				lastName: brand.last_name,
				displayName: brand.display_name,
				companyName: brand.company_name,
				ein: brand.ein,
				einIssuingCountry: brand.ein_issuing_country,
				phone: brand.phone,
				mobilePhone: brand.mobile_phone,
				street: brand.street,
				city: brand.city,
				state: brand.state,
				postalCode: brand.postal_code,
				country: brand.country,
				email: brand.email,
				stockSymbol: brand.stock_symbol,
				stockExchange: brand.stock_exchange,
				ipAddress: brand.ip_address,
				website: brand.website,
				brandRelationship: brand.brand_relationship,
				vertical: brand.vertical,
				altBusinessId: brand.alt_business_id,
				altBusinessIdType: brand.alt_business_id_type,
				referenceId: brand.reference_id,
				tag: brand.tag
			});
		} catch (error) {
			console.log(error);
		}
	};

	onSave = async () => {
		let { brandId, t } = this.props;

		let {
			entityType,
			firstName,
			lastName,
			displayName,
			companyName,
			ein,
			einIssuingCountry,
			phone,
			mobilePhone,
			street,
			city,
			state,
			postalCode,
			country,
			email,
			stockSymbol,
			stockExchange,
			ipAddress,
			website,
			brandRelationship,
			vertical,
			altBusinessId,
			altBusinessIdType,
			referenceId,
			tag
		} = this.state;

		let brandData = {
			entityType,
			firstName,
			lastName,
			displayName,
			companyName,
			ein,
			einIssuingCountry,
			phone,
			mobilePhone,
			street,
			city,
			state,
			postalCode,
			country,
			email,
			stockSymbol,
			stockExchange,
			ipAddress,
			website,
			brandRelationship,
			vertical,
			altBusinessId,
			altBusinessIdType,
			referenceId,
			tag
		};

		let companyId = UserService.getActiveCompany().id;

		let response = null;
		if (brandId) {
			response = await A2PService.updateBrand({ brandId, brandData });
		} else {
			response = await A2PService.createBrand({ companyId, brandData });
		}

		if (response) {
			ToastService.info(brandId ? t("Brand updated.") : t("Successfully created."));
		} else {
			ToastService.error(t("Failed to save. Please try again."));
			return;
		}

		if (this.props.onSave) {
			await this.props.onSave();
		}
	};

	renderFieldError({ minChar, maxChar, value }) {
		let { t } = this.props;

		if (typeof minChar !== "undefined" && value.length < minChar) {
			return <div className="a2p-modal__body__field_error_text">{t("Needs {{number}} more characters", { number: minChar - value.length })}</div>;
		} else if (typeof maxChar !== "undefined" && value.length > maxChar) {
			return <div className="a2p-modal__body__field_error_text">{t("Needs {{number}} less characters", { number: value.length - maxChar })}</div>;
		}

		return null;
	}

	renderContactInfo() {
		let { config, firstName, lastName, phone, email, mobilePhone } = this.state;
		let { t } = this.props;

		return (
			<>
				<Input
					label={t("First Name")}
					name="first_name"
					id="first_name"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "firstName", value: e.target.value })}
					value={firstName}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.firstName.maxLength, value: firstName })}

				<Input
					label={t("Last Name")}
					name="last_name"
					id="last_name"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "lastName", value: e.target.value })}
					value={lastName}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.lastName.maxLength, value: lastName })}

				<Input
					label={t("Email")}
					name="email"
					id="email"
					type="email"
					onChange={e => this.onChangeInput({ fieldName: "email", value: e.target.value })}
					value={email}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.email.maxLength, value: email })}

				<Input
					label={t("Phone (With +1)")}
					name="phone"
					id="phone"
					type="phone"
					onChange={e => this.onChangeInput({ fieldName: "phone", value: e.target.value })}
					value={phone}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.phone.maxLength, value: phone })}

				<Input
					label={t("Mobile Phone (With +1)")}
					name="mobile_phone"
					id="mobile_phone"
					type="phone"
					onChange={e => this.onChangeInput({ fieldName: "mobilePhone", value: e.target.value })}
					value={mobilePhone}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.mobilePhone.maxLength, value: mobilePhone })}
			</>
		);
	}

	renderAddressInfo() {
		let { config, selectableCountries, street, city, state, postalCode, country } = this.state;
		let { t } = this.props;

		// Find the country
		let countryLabel = selectableCountries.find(theCountry => theCountry.value === country);
		countryLabel = typeof countryLabel === "undefined" ? t("Unknown Country") : countryLabel.label;

		return (
			<>
				<Input
					label={t("Street")}
					name="street"
					id="street"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "street", value: e.target.value })}
					value={street}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.street.maxLength, value: street })}

				<Input
					label={t("City")}
					name="city"
					id="city"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "city", value: e.target.value })}
					value={city}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.street.maxLength, value: city })}

				<Input
					label={t("State")}
					name="state"
					id="state"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "state", value: e.target.value })}
					value={state}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.state.maxLength, value: state })}

				<Input
					label={t("State")}
					name="state"
					id="state"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "postalCode", value: e.target.value })}
					value={state}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.postalCode.maxLength, value: postalCode })}

				<DHSelect
					label={t("Country")}
					name="country"
					id="country"
					onChange={option => {
						this.onChangeInput({ fieldName: "country", value: option.value });
					}}
					value={{ label: countryLabel, value: country }}
					options={selectableCountries}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.country.maxLength, value: country })}
			</>
		);
	}

	renderBusinessProfileInfo() {
		let {
			config,
			selectableCountries,
			selectableVerticals,
			selectableEntityTypes,
			selectableStockExchanges,
			selectableAltBusinessIdTypes,

			entityType,
			displayName,
			companyName,
			ein,
			einIssuingCountry,
			stockSymbol,
			stockExchange,
			website,
			vertical,
			altBusinessId,
			altBusinessIdType
		} = this.state;

		let { t } = this.props;

		// Find the country
		let einIssuingCountryLabel = selectableCountries.find(theCountry => theCountry.value === einIssuingCountry);
		einIssuingCountryLabel = typeof einIssuingCountryLabel === "undefined" ? t("Unknown Country") : einIssuingCountryLabel.label;

		// Find the verticle
		let verticalLabel = selectableVerticals.find(theVertical => theVertical.value === vertical);
		verticalLabel = typeof verticalLabel === "undefined" ? t("Unknown Vertical") : verticalLabel.label;

		// Find entity type
		let entityTypeLabel = selectableEntityTypes.find(theEntityType => theEntityType.value === entityType);
		entityTypeLabel = typeof entityTypeLabel === "undefined" ? t("Unknown Entity Type") : entityTypeLabel.label;

		// Find stock exchange type
		let stockExchangeLabel = selectableStockExchanges.find(exchange => exchange.value === stockExchange);
		stockExchangeLabel = typeof stockExchangeLabel === "undefined" ? t("Unknown Stock Exchange") : stockExchangeLabel.label;

		// Find alt business types
		let altBusinessIdTypeLabel = selectableAltBusinessIdTypes.find(currentAltBusinessIdType => currentAltBusinessIdType.value === altBusinessIdType);
		altBusinessIdTypeLabel = typeof altBusinessIdTypeLabel === "undefined" ? t("Unknown Alt Business Type") : altBusinessIdTypeLabel.label;

		return (
			<>
				<DHSelect
					label={t("Country")}
					name="entity_type"
					id="entity_type"
					onChange={option => {
						this.onChangeInput({ fieldName: "entityType", value: option.value });
					}}
					value={{ label: entityTypeLabel, value: entityType }}
					options={selectableEntityTypes}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.entityType.maxLength, value: entityType })}

				<Input
					label={t("Display Name")}
					name="display_name"
					id="display_name"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "displayName", value: e.target.value })}
					value={displayName}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.displayName.maxLength, value: displayName })}

				<Input
					label={t("Company Name")}
					name="company_name"
					id="company_name"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "companyName", value: e.target.value })}
					value={companyName}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.companyName.maxLength, value: companyName })}

				<DHSelect
					label={t("Vertical")}
					name="vertical"
					id="vertical"
					onChange={option => {
						this.onChangeInput({ fieldName: "vertical", value: option.value });
					}}
					value={{ label: verticalLabel, value: vertical }}
					options={selectableVerticals}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.vertical.maxLength, value: vertical })}

				<Input
					label={t("Business Number (US is 9 digit EIN, Canada is BN first 9 digits only)")}
					name="ein"
					id="ein"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "ein", value: e.target.value })}
					value={ein}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.ein.maxLength, value: ein })}

				<DHSelect
					label={t("Business Number Issuing Country")}
					name="ein_issuing_country"
					id="ein_issuing_country"
					onChange={option => {
						this.onChangeInput({ fieldName: "einIssuingCountry", value: option.value });
					}}
					value={{ label: einIssuingCountryLabel, value: einIssuingCountry }}
					options={selectableCountries}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.einIssuingCountry.maxLength, value: einIssuingCountry })}

				<Input
					label={t("Alt Business ID")}
					name="alt_business_id"
					id="alt_business_id"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "altBusinessId", value: e.target.value })}
					value={altBusinessId}
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.altBusinessId.maxLength, value: altBusinessId })}

				<DHSelect
					label={t("Alt Business Type (ex: DUNS)")}
					name="alt_business_type"
					id="alt_business_type"
					onChange={option => {
						this.onChangeInput({ fieldName: "altBusinessIdType", value: option.value });
					}}
					value={{ label: altBusinessIdTypeLabel, value: altBusinessIdType }}
					options={selectableAltBusinessIdTypes}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.altBusinessIdType.maxLength, value: altBusinessIdType })}

				<Input
					label={t("Stock Symbol")}
					name="stock_symbol"
					id="stock_symbol"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "stockSymbol", value: e.target.value })}
					value={stockSymbol}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.stockSymbol.maxLength, value: stockSymbol })}

				<DHSelect
					label={t("Stock Exchange (ex: NASDAQ)")}
					name="stock_exchange"
					id="stock_exchange"
					onChange={option => {
						this.onChangeInput({ fieldName: "stockExchange", value: option.value });
					}}
					value={{ label: stockExchangeLabel, value: stockExchange }}
					options={selectableStockExchanges}
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.stockExchange.maxLength, value: stockExchange })}

				<Input
					label={t("Website (Include www)")}
					name="website"
					id="website"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "website", value: e.target.value })}
					value={website}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.website.maxLength, value: website })}
			</>
		);
	}

	renderMiscInfo() {
		let { config, selectableBrandRelationships, ipAddress, brandRelationship, tag } = this.state;
		let { t } = this.props;

		// Find the brand relationship
		let brandRelationshipLabel = selectableBrandRelationships.find(theBrandRelationship => theBrandRelationship.value === brandRelationship);
		brandRelationshipLabel = typeof brandRelationshipLabel === "undefined" ? t("Unknown Brand Relationship") : brandRelationshipLabel.label;

		return (
			<>
				<Input
					label={t("IP Address")}
					name="ip_address"
					id="ip_address"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "ipAddress", value: e.target.value })}
					value={ipAddress}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.ipAddress.maxLength, value: ipAddress })}

				<DHSelect
					label={t("Brand Relationship")}
					name="brand_relationship"
					id="brand_relationship"
					onChange={option => {
						this.onChangeInput({ fieldName: "brandRelationship", value: option.value });
					}}
					value={{ label: brandRelationshipLabel, value: brandRelationship }}
					options={selectableBrandRelationships}
					required
				/>
				{this.renderFieldError({ maxChar: config.enums.brandRequestSchema.brandRelationship.maxLength, value: brandRelationship })}

				<Input
					label={t("Tag")}
					name="tag"
					id="tag"
					type="text"
					onChange={e => this.onChangeInput({ fieldName: "tag", value: e.target.value })}
					value={tag}
					required
				/>
			</>
		);
	}

	render = () => {
		let { show, brandId, onHide, t } = this.props;
		let { selectedTab, loading, config } = this.state;

		if (loading || !config) {
			return (
				<Modal show={show} onHide={onHide} title={brandId ? t("Update Brand") : t("Create Brand")}>
					<div className="a2p-modal">
						<ContentLoader height={650} width={"100%"}>
							{/* The tabs */}
							<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />

							{/* Rows */}
							<rect x="0" y="70" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="150" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="230" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="310" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="390" rx="5" ry="5" width="100%" height="50" />
						</ContentLoader>
					</div>
				</Modal>
			);
		}

		return (
			<Modal show={show} onHide={onHide} title={brandId ? t("Update Brand") : t("Create Brand")}>
				<div className="a2p-modal">
					<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
						<Tab id={EDIT_BRAND_TABS.contact.id} value={EDIT_BRAND_TABS.contact.value} />
						<Tab id={EDIT_BRAND_TABS.address.id} value={EDIT_BRAND_TABS.address.value} />
						<Tab id={EDIT_BRAND_TABS.businessProfile.id} value={EDIT_BRAND_TABS.businessProfile.value} />
						<Tab id={EDIT_BRAND_TABS.misc.id} value={EDIT_BRAND_TABS.misc.value} />
					</Tabs>

					<div className="a2p-modal__body">{EDIT_BRAND_TABS.contact.id === selectedTab && this.renderContactInfo()}</div>
					<div className="a2p-modal__body">{EDIT_BRAND_TABS.address.id === selectedTab && this.renderAddressInfo()}</div>
					<div className="a2p-modal__body">{EDIT_BRAND_TABS.businessProfile.id === selectedTab && this.renderBusinessProfileInfo()}</div>
					<div className="a2p-modal__body">{EDIT_BRAND_TABS.misc.id === selectedTab && this.renderMiscInfo()}</div>

					<div className="modal__actions">
						<div className={`mb-button mb-button--fit}`} onClick={this.onSave}>
							{t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(EditBrandModal);

import React from "react";
import * as Icon from "react-feather";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import Action from "../../../components/common/Action";
import Alert from "../../../components/common/Alert";

import UserService from "../../../services/UserService";

import "../../../styles/css/components/commons/condition.css";

class Condition extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showConfirmDeleteModal: false,
			condition: this.props.condition ? this.props.condition : []
		};

		this.debounceOnParamValueChange = _.debounce(
			(key, value) => {
				this.props.onParamValueChange(key, value);
			},
			1000,
			{
				leading: false,
				trailing: true
			}
		);

		this.debounceOnParamChange = _.debounce(
			params => {
				this.props.onParamChange(params);
			},
			1000,
			{
				leading: false,
				trailing: true
			}
		);
	}

	isReadOnly = () => {
		let user = UserService.get();
		return !user.GroupPermission.update_workflows;
	};

	onConfirmDeleteCondition = () => {
		this.setState({ showConfirmDeleteModal: true });
	};

	onRemoveCondition = confirm => {
		if (confirm && this.props.onRemoveCondition) {
			this.props.onRemoveCondition();
		}
		this.setState({
			showConfirmDeleteModal: false
		});
	};

	onConditionOperatorChange = option => {
		if (this.props.onConditionOperatorChange) {
			this.props.onConditionOperatorChange(option);
		}
	};

	onConditionTypeChange = event => {
		if (this.props.onConditionTypeChange) {
			this.props.onConditionTypeChange(event);
		}
	};

	onParamChange = (index, event) => {
		let { condition } = this.state;

		// We want to update the key, without changing the order (it's too jarring for the user)
		// A list of the parameters
		let paramsList = Object.keys(condition.params).map(key => {
			return { key: key, value: condition.params[key] };
		});

		let value = event.target.value;
		// The key that is changing
		paramsList[index].key = value;

		// Build the new params object
		let newParams = {};
		for (const param of paramsList) {
			newParams[param.key] = param.value;
		}
		condition.params = newParams;
		this.setState({
			condition
		});

		if (this.props.onParamChange) {
			this.debounceOnParamChange(newParams);
		}
	};

	onParamValueChange = (key, event) => {
		let { condition } = this.state;

		// Update the condition to show locally
		let value = event.target.value;
		condition.params[key] = value;
		this.setState({
			condition
		});

		// Update the condition on the parent component
		if (this.props.onParamValueChange) {
			this.debounceOnParamValueChange(key, value);
		}
	};

	onAddParameter = key => {
		if (this.props.onAddParameter) {
			this.props.onAddParameter(key);
		}
	};

	onRemoveParameter = key => {
		if (this.props.onRemoveParameter) {
			this.props.onRemoveParameter(key);
		}
	};

	renderParamValue = value => {
		if (typeof value === "object" && value !== null && value !== "") {
			return JSON.stringify(value);
		}
		return value;
	};

	renderParameters = () => {
		let { workflowsConfig, workflowId, id, t } = this.props;
		let { condition } = this.state;
		let conditionInfo = workflowsConfig.conditionInfo[condition.type];

		return (
			<>
				{conditionInfo.params && Object.keys(conditionInfo.params).length > 0 && (
					<>
						<div className="condition__param-info">
							{t("Parameter Info:")}
							<div className="condition__param-info__bubbles">
								{Object.values(conditionInfo.params)
									.sort((a, b) => b.required - a.required)
									.map((param, index) => (
										<div key={index}>
											<div
												className={`condition__param-info__bubble`}
												data-tip
												data-for={`rtt-${workflowId}-condition-${id}-param-${param.id}`}
												onClick={() => this.onAddParameter(param.id)}
											>
												{param.id}
												{param.required ? <span className="text-danger">*</span> : ""}
											</div>
											<ReactTooltip
												id={`rtt-${workflowId}-condition-${id}-param-${param.id}`}
												className="mb-react-tooltip condition__param-info__tooltip"
												type="info"
												effect="solid"
												place="top"
												arrowColor="#333"
											>
												<div>
													<strong>{param.id}</strong>
													<br />
													{param.description && (
														<>
															{param.description}
															<hr />
														</>
													)}
													{t("Default:")} '{param.default ? param.default : ""}' <br />
													{t("Example:")} '{param.example ? param.example : ""}' <br />
													{t("Type:")} {param.type ? param.type : ""} <br />
													{t("Required:")} {param.required ? t("Yes") : t("No")}
												</div>
											</ReactTooltip>
										</div>
									))}
							</div>
						</div>
					</>
				)}
				<div className="condition__parameters">{t("Parameters:")}</div>
				{condition.params &&
					Object.keys(condition.params).map((key, index) => (
						<div className="condition__parameter" key={index}>
							<div className="condition__parameter__name">
								{conditionInfo && conditionInfo.params && conditionInfo.params[key] && conditionInfo.params[key].required && (
									<span className="condition__parameter__name__required">*</span>
								)}
								<input
									className="Common__input fnctst-parameter-key"
									value={key}
									placeholder={t("Name")}
									onChange={event => this.onParamChange(index, event)}
									disabled={this.isReadOnly()}
								/>
							</div>
							<div className="condition__parameter__operator">=</div>
							<input
								name={key}
								className="Common__input fnctst-parameter-value"
								value={this.renderParamValue(condition.params[key])}
								placeholder={t("value")}
								onChange={event => this.onParamValueChange(key, event)}
								disabled={this.isReadOnly()}
							/>
							{!this.isReadOnly() && (
								<div className="condition__parameter__remove">
									<Action
										icon={Icon.MinusCircle}
										transparent={true}
										darkMode={true}
										label={t("Remove Parameter")}
										onClick={() => this.onRemoveParameter(key)}
									/>
								</div>
							)}
						</div>
					))}
				{!this.isReadOnly() && (!condition.params || (condition.params && Object.keys(condition.params).length < 5)) && (
					<div className="condition__parameters__add">
						<Action icon={Icon.PlusCircle} transparent={true} darkMode={true} label={t("Add Parameter")} onClick={this.onAddParameter} />
					</div>
				)}
			</>
		);
	};

	render() {
		let { id, workflowsConfig, t } = this.props;
		let { showConfirmDeleteModal, condition } = this.state;

		let conditionInfo = workflowsConfig.conditionInfo[condition.type];
		let conditionValue = { value: condition.type, label: conditionInfo.name };
		let operatorValue = condition.operators
			? condition.operators.map(item => {
					return { value: item, label: item };
			  })
			: null;

		return (
			<>
				<div className="condition">
					<div className="condition__type">
						<div className="condition__type__operator">
							<div>{t("Operator")}</div>
							<Select
								id={`condition-${id}-operator-select`}
								className="workflow__select fnctst-workflow-condition-operator-select"
								options={workflowsConfig.assignableOperators}
								isMulti
								value={operatorValue}
								onChange={this.onConditionOperatorChange}
								placeholder={t("Operator")}
								isDisabled={this.isReadOnly()}
							/>
						</div>
						<div className="condition__type__selector">
							<div>{t("Type")}</div>
							<Select
								id={`condition-${id}-type-select`}
								className="workflow__select fnctst-workflow-condition-select"
								options={workflowsConfig.assignableConditions}
								value={conditionValue}
								onChange={this.onConditionTypeChange}
								placeholder={t("Condition...")}
								isDisabled={this.isReadOnly()}
							/>
						</div>

						{!this.isReadOnly() && (
							<div className="condition__type__delete">
								<Action icon={Icon.X} transparent={true} darkMode={true} label={t("Remove Condition")} onClick={this.onConfirmDeleteCondition} />
							</div>
						)}
					</div>
					<div className="condition__type__description">{conditionInfo.description}</div>
					{this.renderParameters()}
				</div>
				<Alert type="warning" show={showConfirmDeleteModal} title="Are you sure?" confirm={t("Yes")} cancel={t("No")} onClose={this.onRemoveCondition}>
					<div className="text-center">{t("Are you sure you would like to remove the condition?")}</div>
				</Alert>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(Condition);

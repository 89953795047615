import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import Checkbox from "../../components/common/Checkbox";

class ManageUserPreferences extends Component {
	constructor(props) {
		super(props);

		let locationId = this.props.locationId;
		this.state = {
			locationId: locationId,
			notifications: this.props.notifications
		};
	}

	handleChangeLocation() {
		if (this.props.handleChangeLocation) {
			this.props.handleChangeLocation();
		}
	}

	onNotificationChange = event => {
		let { name, checked } = event.target;
		// eslint-disable-next-line
		this.state.notifications[name] = checked;
		this.setState(
			{
				notifications: this.state.notifications
			},
			() => {
				if (this.props.hasOwnProperty("updateNotifications")) {
					this.props.updateNotifications(this.state.locationId, this.state.notifications);
				}
			}
		);
	};

	render() {
		const { notifications } = this.state;
		const { isContactAssignedToOthersDisabled, isUnassignedPushDisabled, t } = this.props;
		const user = UserService.get();

		let allowedToUpdate = user.GroupPermission.update_notifications;

		return (
			<div className="">
				<div className="mup-notifications-container ">
					<div className="text-center" style={{ width: "410px", marginTop: "15px", marginBottom: "20px" }}>
						<strong>{t("Notifications")}</strong>
					</div>
					<div className="mup-notifications">
						<div className="mup-notifications-header">
							<div className="mup-notifications-title">
								<strong>{t("New Messages")}</strong>
							</div>
							<div data-tip data-for="desktop-tooltip" ref={ref => (this.desktopRef = ref)} className="mup-notifications-flag">
								<Icon.Monitor size={24} />
							</div>
							<ReactTooltip id="desktop-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
								{t("Desktop")}
							</ReactTooltip>
							<div data-tip data-for="phone-tooltip" ref={ref => (this.phoneRef = ref)} className="mup-notifications-flag">
								<Icon.Smartphone size={24} />
							</div>
							<ReactTooltip id="phone-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
								{t("Phone")}
							</ReactTooltip>
							<div data-tip data-for="email-tooltip" ref={ref => (this.emailRef = ref)} className="mup-notifications-flag">
								<Icon.Mail size={24} />
							</div>
							<ReactTooltip id="email-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
								{t("Email")}
							</ReactTooltip>
						</div>
						<div className="mup-notifications-item">
							<div className="mup-notifications-title">{t("Conversations I'm assigned to")}</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.desktopRef)} onMouseOut={() => ReactTooltip.hide(this.desktopRef)}>
									<Checkbox
										id="contact_assigned_to_me_desktop"
										name="contact_assigned_to_me_desktop"
										checked={notifications.contact_assigned_to_me_desktop}
										onChange={this.onNotificationChange}
										disabled={!allowedToUpdate}
									/>
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.phoneRef)} onMouseOut={() => ReactTooltip.hide(this.phoneRef)}>
									<Checkbox
										id="contact_assigned_to_me_push"
										name="contact_assigned_to_me_push"
										checked={notifications.contact_assigned_to_me_push}
										onChange={this.onNotificationChange}
										disabled={!allowedToUpdate}
									/>
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.emailRef)} onMouseOut={() => ReactTooltip.hide(this.emailRef)}>
									<Checkbox
										id="contact_assigned_to_me_email"
										name="contact_assigned_to_me_email"
										checked={notifications.contact_assigned_to_me_email}
										onChange={this.onNotificationChange}
										disabled={!allowedToUpdate}
									/>
								</div>
							</div>
						</div>

						<div className="mup-notifications-item">
							<div className={`mup-notifications-title ${isContactAssignedToOthersDisabled ? "mup-notifications-title-disabled" : ""}`}>
								{t("Conversations assigned to others")}
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.desktopRef)} onMouseOut={() => ReactTooltip.hide(this.desktopRef)}>
									{!isContactAssignedToOthersDisabled ? (
										<Checkbox
											id="contact_assigned_to_others_desktop"
											name="contact_assigned_to_others_desktop"
											checked={notifications.contact_assigned_to_others_desktop}
											onChange={this.onNotificationChange}
											disabled={!allowedToUpdate || isContactAssignedToOthersDisabled}
										/>
									) : (
										<Icon.Minus />
									)}
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.phoneRef)} onMouseOut={() => ReactTooltip.hide(this.phoneRef)}>
									{!isContactAssignedToOthersDisabled ? (
										<Checkbox
											id="contact_assigned_to_others_push"
											name="contact_assigned_to_others_push"
											checked={notifications.contact_assigned_to_others_push}
											onChange={this.onNotificationChange}
											disabled={!allowedToUpdate || isContactAssignedToOthersDisabled}
										/>
									) : (
										<Icon.Minus />
									)}
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.emailRef)} onMouseOut={() => ReactTooltip.hide(this.emailRef)}>
									{!isContactAssignedToOthersDisabled ? (
										<Checkbox
											id="contact_assigned_to_others_email"
											name="contact_assigned_to_others_email"
											checked={notifications.contact_assigned_to_others_email}
											onChange={this.onNotificationChange}
											disabled={!allowedToUpdate || isContactAssignedToOthersDisabled}
										/>
									) : (
										<Icon.Minus />
									)}
								</div>
							</div>
						</div>

						<div className="mup-notifications-item">
							<div className={`mup-notifications-title ${isUnassignedPushDisabled ? "mup-notifications-title-disabled" : ""}`}>
								{t("Unassigned conversations")}
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.desktopRef)} onMouseOut={() => ReactTooltip.hide(this.desktopRef)}>
									{!isUnassignedPushDisabled ? (
										<Checkbox
											id="contact_unassigned_desktop"
											name="contact_unassigned_desktop"
											checked={notifications.contact_unassigned_desktop}
											onChange={this.onNotificationChange}
											disabled={!allowedToUpdate || isUnassignedPushDisabled}
										/>
									) : (
										<Icon.Minus />
									)}
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.phoneRef)} onMouseOut={() => ReactTooltip.hide(this.phoneRef)}>
									{!isUnassignedPushDisabled ? (
										<Checkbox
											id="contact_unassigned_push"
											name="contact_unassigned_push"
											checked={notifications.contact_unassigned_push}
											onChange={this.onNotificationChange}
											disabled={!allowedToUpdate || isUnassignedPushDisabled}
										/>
									) : (
										<Icon.Minus />
									)}
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.emailRef)} onMouseOut={() => ReactTooltip.hide(this.emailRef)}>
									{!isUnassignedPushDisabled ? (
										<Checkbox
											id="contact_unassigned_email"
											name="contact_unassigned_email"
											checked={notifications.contact_unassigned_email}
											onChange={this.onNotificationChange}
											disabled={!allowedToUpdate || isUnassignedPushDisabled}
										/>
									) : (
										<Icon.Minus />
									)}
								</div>
							</div>
						</div>
						<div className="mup-notifications-item">
							<div className="mup-notifications-title">{t("Someone assigns you to a conversation")}</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.desktopRef)} onMouseOut={() => ReactTooltip.hide(this.desktopRef)}>
									<Checkbox
										id="contact_assigned_update_desktop"
										name="contact_assigned_update_desktop"
										checked={notifications.contact_assigned_update_desktop}
										onChange={this.onNotificationChange}
										disabled={!allowedToUpdate}
									/>
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.phoneRef)} onMouseOut={() => ReactTooltip.hide(this.phoneRef)}>
									<Checkbox
										id="contact_assigned_update_push"
										name="contact_assigned_update_push"
										checked={notifications.contact_assigned_update_push}
										onChange={this.onNotificationChange}
										disabled={!allowedToUpdate}
									/>
								</div>
							</div>
							<div className="mup-notifications-flag">
								<div onMouseOver={() => ReactTooltip.show(this.emailRef)} onMouseOut={() => ReactTooltip.hide(this.emailRef)}>
									<Checkbox
										id="contact_assigned_update_email"
										name="contact_assigned_update_email"
										checked={notifications.contact_assigned_update_email}
										onChange={this.onNotificationChange}
										disabled={!allowedToUpdate}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation(null, { withRef: true })(ManageUserPreferences);

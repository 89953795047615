import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as Icon from "react-feather";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../../services/UserService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { CsvService } from "../../../services/CsvService";
import UtilityService from "../../../services/UtilityService";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";

import { CHART_COLORS } from "../../../constants/CommonConstants";

import "../../../styles/css/scenes/leadSourceAnalytics.css";

class LeadsBarChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			totalData: [],
			totalLeadSourceCsv: null
		};
	}

	componentDidMount() {
		this.refreshData();
	}

	onLocationChanged = location => {
		this.refreshData();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	handleUpdateDate() {
		this.refreshData();
	}

	async refreshData() {
		const { start, end } = this.state;

		await this.update({ loading: true });
		let totalResult = await AnalyticsService.getLeadSourceTotalAnalytics(UserService.getActiveLocation().id, start, end);

		if (!totalResult) {
			await this.update({
				loading: false
			});
			return;
		}

		await this.update({
			loading: false,
			totalData: totalResult
		});
		this.updateCharts();
	}

	async updateCharts() {
		let { totalData } = this.state;

		if (!totalData) {
			return;
		}

		// Generate a color pattern for other charts
		// if there are more lead sources than colors, generate some random colors!
		let colors = [...CHART_COLORS];
		let length = totalData.length > colors.length ? totalData.length - colors.length : 0;
		colors = UtilityService.generateColorsArray({ colors, length });

		this.generateLeadsRotatedBarChart(colors);
	}

	generateLeadsRotatedBarChart = colors => {
		let { totalData } = this.state;

		let columns = [];
		for (const item in totalData) {
			const data = totalData[item];
			columns.push([data.lead_source, data.total]);
		}

		this.generateChart(
			"#lead-source-analytics-rotated-bar-chart",
			{
				columns: columns,
				type: "bar",
				labels: true
			},
			{
				x: {
					type: "category",
					categories: ["Number of Leads"]
				}
			},
			{
				pattern: colors
			}
		);
	};

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			c3.generate({
				bindto: id,
				data: data,
				axis: axis,
				color: color,
				legend: legend
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	render() {
		const { loading, start, end, totalLeadSourceCsv } = this.state;
		const { isCard, cardName, isEnabled, isPermissible } = this.props;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();

		if (isEnabled && !isEnabled()) {
			return (
				<div className="lead-source-analytics__chart-section">
					<div className="Common__chart-container lead-source-analytics__chart-section">
						<div className="dh-page__chart-title">
							<div>{cardName ? cardName : "Leads"}</div>
						</div>
					</div>
					<br />
					<div className="text-center">Contacts is not enabled. Contact support to get started.</div>
				</div>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<div className="lead-source-analytics__chart-section">
					<div className="Common__chart-container lead-source-analytics__chart-section">
						<div className="dh-page__chart-title">
							<div>{cardName ? cardName : "Leads"}</div>
						</div>
					</div>
					<br />
					<div className="text-center">Contact data restricted.</div>
				</div>
			);
		}

		if (loading) {
			return (
				<div className="lead-source-analytics__chart-section">
					<div className="Common__chart-container lead-source-analytics__chart-section">
						<div className="dh-page__chart-title">
							<div>{cardName ? cardName : "Leads"}</div>
						</div>
					</div>
					<div className="analytics__section__chart__spinner">
						<Spinners type="tail-fade" loading={true} size="60px" />
					</div>
				</div>
			);
		}

		return (
			<div className="lead-source-analytics__chart-section">
				{isCard && (
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{cardName ? cardName : "Leads"}</div>
						<div className="dashboard__card__header__actions">
							{totalLeadSourceCsv && (
								<CSVLink data={totalLeadSourceCsv} filename={`${locationName}-contact_total_lead_sources.csv`} target="_self">
									<Action label="Download" icon={Icon.Download} className="Common__csv-link">
										<Icon.Download />
									</Action>
								</CSVLink>
							)}
						</div>
					</div>
				)}
				<div className={`form-group dh-page__datepicker ${isCard ? "dh-page__datepicker--card" : ""}`}>
					<label>Date Range&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder=" From"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder=" To"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<div id="date-update" className="mb-button " onClick={e => this.handleUpdateDate(e)}>
						Update Date
					</div>
				</div>

				<div className="Common__chart-container lead-source-analytics__chart-section">
					<div className="dh-page__chart-title">
						<div>{cardName ? cardName : "Leads"}</div>
						{!isCard && totalLeadSourceCsv && (
							<CSVLink data={totalLeadSourceCsv} filename={`${locationName}-contact_total_lead_sources.csv`} target="_self">
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
					<div id="lead-source-analytics-rotated-bar-chart" />
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(LeadsBarChart));

import React from "react";
import * as Icon from "react-feather";
import Lightbox from "react-image-lightbox";
import { withTranslation } from "react-i18next";

import ToastService from "../../services/ToastService";

class DHLightbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			media: [],
			mediaIndex: 0
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	setMedia = ({ media, mediaIndex = 0 }) => {
		this.update({ media, mediaIndex });
	};

	show = async () => {
		await this.update({ show: true });
	};

	onClose = async () => {
		await this.update({ show: false, media: [], mediaIndex: 0 });

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onDownload = async (downloadUrl, fileName = "") => {
		let { t } = this.props;

		try {
			if (!downloadUrl) {
				return;
			}
			let response = await fetch(downloadUrl, {
				method: "GET"
			});

			let blob = await response.blob();

			// Tis a ghetto little thing
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.target = "_blank";
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			a.remove();

			ToastService.info(t("Download Complete!"));
		} catch (error) {
			console.log(error);
			ToastService.error(t("Error downloading image. Please try again."));
		}
	};

	render = () => {
		let { mediaIndex, media, show } = this.state;
		let { t } = this.props;

		if (!show) {
			return null;
		}

		if (!media || media.length < 1) {
			return null;
		}

		return (
			<Lightbox
				wrapperClassName="dh-lightbox"
				mainSrc={media[mediaIndex].src}
				nextSrc={media[(mediaIndex + 1) % media.length].src}
				prevSrc={media[(mediaIndex + media.length - 1) % media.length].src}
				onCloseRequest={this.onClose}
				onMovePrevRequest={() =>
					this.setState({
						mediaIndex: (mediaIndex + media.length - 1) % media.length
					})
				}
				onMoveNextRequest={() =>
					this.setState({
						mediaIndex: (mediaIndex + 1) % media.length
					})
				}
				imageTitle={media[mediaIndex].file_name || ""}
				// Custom buttons
				toolbarButtons={[
					<button
						title={t("Download")}
						aria-label={t("Download")}
						style={{ backgroundColor: "transparent", position: "relative", top: -3 }}
						className="ril__toolbarItemChild ril__builtinButton"
						onClick={() => this.onDownload(media[mediaIndex].src, media[mediaIndex].file_name)}
					>
						<Icon.Download size={24} />{" "}
					</button>
				]}
			/>
		);
	};
}

export default withTranslation(null, { withRef: true })(DHLightbox);

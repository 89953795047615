import Kichiri from "./KichiriService";
import UserService from "./UserService";
import LocationService from "./LocationService";

import { LOCATION_FEATURES } from "../constants/LocationConstants";

export const DashboardService = {
	/**
	 * Fetch dashboards for a location
	 * @param {Integer} locationId
	 * @param {Boolean} isDefault
	 * @param {Array/String} status
	 */
	async fetch({ locationId, isDefault, status }) {
		try {
			const response = await Kichiri.location.getDashboards({ locationId }, { isDefault, status }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a single dashboard
	 * @param {Integer} locationId
	 * @param {Integer} dashboardId
	 */
	async fetchDashboard({ locationId, dashboardId }) {
		try {
			const response = await Kichiri.location.getDashboard({ locationId, dashboardId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a dashboard
	 * @param {Integer} locationId
	 * @param {Integer} authorUserId
	 * @param {Boolean} isDefault
	 * @param {String} name
	 * @param {String} layout
	 *
	 * @returns {Object} Dashboard
	 */
	async create({ locationId, authorUserId, isDefault, name, layout }) {
		try {
			let response = await Kichiri.location.createDashboard(
				{
					locationId,
					authorUserId,
					isDefault,
					name,
					layout
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a dashboard
	 * @param {Integer} locationId
	 * @param {Integer} dashboardId
	 * @param {Integer} authorUserId
	 * @param {Boolean} isDefault
	 * @param {String} name
	 * @param {String} layout
	 * @param {String} status
	 *
	 * @returns {Object} Dashboard
	 */
	async update({ locationId, dashboardId, authorUserId, isDefault, name, layout, status }) {
		try {
			let response = await Kichiri.location.updateDashboard(
				{
					locationId,
					dashboardId,
					authorUserId,
					isDefault,
					name,
					layout,
					status
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check if the user is able to view reengagments
	 * @returns {Boolean}
	 */
	canViewDashboard() {
		try {
			const user = UserService.get();
			return user.GroupPermission.view_reports;
		} catch (error) {
			console.log(error);
			return false;
		}
	}
};

export default DashboardService;

import React, { PureComponent } from "react";
import moment from "moment";
import "whatwg-fetch";
import Highlighter from "react-highlight-words";

import { DOWNLOAD_STATUS } from "../../../../constants/Messenger";

import UtilityService from "../../../../services/UtilityService";

import ThreadMedia from "../Media/ThreadMedia";

import "./team-chat-message-result.css";

class TeamChatMessageResult extends PureComponent {
	constructor(props) {
		super(props);

		let { message, previousMessage, searchTerm } = props;

		this.context = null;

		this.state = {
			message,
			previousMessage,
			isMedia: false,
			media: [],
			searchTerm,

			downloadStatus: DOWNLOAD_STATUS.idle
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.buildMediaList();
	}

	buildMediaList = async () => {
		let { message } = this.state;

		let isMedia = message.media.length > 0;

		if (!isMedia) {
			return;
		}

		await this.update({
			media: message.media,
			isMedia
		});
	};

	renderName(message, previousMessage) {
		let date = moment(message.created_at).format("ddd, MMM Do YYYY @ h:mm a");

		return (
			<div key={`${message.id}-name`} className="mb-message-list-name">
				{message.sender_user_name}
				<span className="mb-message-list-date">{date}</span>
			</div>
		);
	}

	renderReply(message) {
		if (!message.reply_id) {
			return null;
		}
		return (
			<div className="mb-message-reply">
				<div className="mb-message-reply-name">{message.reply_name}</div>
				<div className="mb-message-reply-content">{this.renderContent(message.reply_id, message.reply_content)}</div>
			</div>
		);
	}

	renderMedia() {
		let { media } = this.state;

		return media.map(m => {
			return <ThreadMedia media={m} />;
		});
	}

	renderContent(id, content) {
		let { searchTerm } = this.state;

		return content.split("\n").map((blurb, index) => {
			let key = parseInt(`${id}${index}`, 10);

			if (blurb.length === 0) {
				return <br key={key} />;
			}

			let direction = UtilityService.detectLanguageDirection(blurb);

			return (
				<p className="mb-message-content-blurb" dir={direction} key={key}>
					<Highlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={blurb} />
				</p>
			);
		});
	}

	renderTeamMessage() {
		let { message, isMedia } = this.state;
		let { id, content } = message;

		let contentStyle = {};

		// If the content is simply one emoji, then increase the size of the emoji to font size 48
		if (content.length === 2 && UtilityService.isSingleEmoji(content)) {
			contentStyle = {
				fontSize: 48
			};
		}

		let date = moment(message.created_at).format("ddd, MMM Do YYYY @ h:mm a");

		return (
			<div className="mb-message-team-result">
				<div className="mb-message-team-result-set">
					{isMedia && this.renderMedia(message)}
					{this.renderReply(message)}
					<div className="mb-message-team-result-content" style={contentStyle}>
						{content && this.renderContent(id, content)}
					</div>
				</div>
				<div className="mb-message-date">{date}</div>
			</div>
		);
	}

	render() {
		let { message, previousMessage } = this.state;
		let { onSelect } = this.props;

		return (
			<div className="mb-message-team-result-item" onClick={onSelect}>
				{this.renderName(message, previousMessage)}
				{this.renderTeamMessage()}
			</div>
		);
	}
}

export default TeamChatMessageResult;

import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import NumberService from "../../services/NumberService";

class NumbersDropdown extends Component {
	state = {
		numbers: []
	};

	async componentDidMount() {
		let data = await NumberService.fetchAssignableNumbers({ locationId: UserService.getActiveLocation().id });

		if (!data) {
			return;
		}

		let numbers = data.map(n => ({
			number: n.number,
			company_id: n.company_id,
			location_id: n.location_id,
			api: n.api,
			safe: n.safe,
			burned: n.burned,
			status: n.status,
			value: n.number,
			label: `${n.number} (${n.api}) ${n.burned ? "- burned" : ""}`,
			notes: n.notes !== null ? JSON.parse(n.notes) : []
		}));

		this.setState({
			numbers: numbers
		});
	}

	render() {
		const { value, onChange, required, id, assignedNumbers, t } = this.props;

		let numbers = this.state.numbers;
		assignedNumbers.forEach(n => {
			numbers = numbers.filter(num => num.number !== n.number); // if an unassigned number has been assigned in the GUI
			if (n.location_id === null) {
				numbers.push(n);
			}
		});
		return (
			<Select
				id={id}
				options={numbers}
				className="locations_assignable-numbers"
				onChange={e => onChange(e)}
				value={value}
				focusedOption={value}
				isSearchable={true}
				isClearable={true}
				placeholder={t("Please select a number")}
				required={required || false}
			/>
		);
	}
}
export default withTranslation(null, { withRef: true })(NumbersDropdown);

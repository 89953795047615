import React, { PureComponent } from "react";

import "./GifImage.css";

class GifImage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		let { url, title, width, height } = this.props;

		return (
			<div key={url} className="gif-image__image-container" style={{ width, height }}>
				<img
					className="gif-image__image"
					src={url}
					alt={title}
					onClick={() => {
						this.props.gifSelected();
					}}
				/>
			</div>
		);
	}
}

export default GifImage;
